import { AutomationTemplateViewModel, UserSessionContext } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { baseStyleSheet } from '../../../../styles/styles';
import { Dropdown } from '../../../Dropdown';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

interface IMeetingRemindersProps {
	automationChoices?: AutomationTemplateViewModel[];
	selectedAutomation: AutomationTemplateViewModel | null;
	onSetTemplate?(template: AutomationTemplateViewModel): void;
}
export const MeetingReminders: React.FC<IMeetingRemindersProps> = observer(
	({ automationChoices, selectedAutomation, onSetTemplate }) => {
		const [isOpen, setIsOpen] = React.useState(false);

		const onOpenChanged = (open: boolean) => {
			setIsOpen(open);
		};

		const handleMeetingReminderAutomationClick = (item: AutomationTemplateViewModel<UserSessionContext>) => () => {
			setIsOpen(false);

			onSetTemplate(item);
		};

		return (
			<Dropdown
				anchor={
					<button className={css(styleSheet.dropdownAnchor)}>
						<span className={css(baseStyleSheet.truncateText)}>
							{selectedAutomation ? (
								<>
									{selectedAutomation?.name} (Created by {selectedAutomation.creator?.firstName}{' '}
									{selectedAutomation.creator?.lastName} )
								</>
							) : (
								<>None selected</>
							)}
						</span>
						<DisclosureIcon className={css(styleSheet.dropdownIcon)} />
					</button>
				}
				className={`${css(styleSheet.dropdownInline)}`}
				contentClassName={css(styleSheet.dropdownMenu)}
				contentPositionY='top'
				isOpen={isOpen}
				onOpenChanged={onOpenChanged}
				openOnClick={true}
			>
				<menu className={`${css(styleSheet.dropdownMenuInner)}`}>
					<button
						className={css(styleSheet.dropdownItem, baseStyleSheet.truncateText)}
						key='null-option'
						onClick={handleMeetingReminderAutomationClick(null)}
					>
						<span className={css(baseStyleSheet.truncateText)}>None Selected</span>
					</button>
					{automationChoices?.map((x, idx) => {
						return (
							<button
								className={`${css(styleSheet.dropdownItem, baseStyleSheet.truncateText)} ${
									x.id === selectedAutomation?.id ? css(styleSheet.dropdownItemActive) : ''
								}`}
								key={`${x.id}-${idx}`}
								onClick={handleMeetingReminderAutomationClick(x)}
							>
								<span className={css(baseStyleSheet.truncateText)}>
									{x.name} (Created by {x.creator?.firstName} {x.creator?.lastName} )
								</span>
							</button>
						);
					})}
				</menu>
			</Dropdown>
		);
	}
);
