import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages, useToaster } from '../../../../../models/hooks/appStateHooks';
import ContinueWithFacebookImageUrl from '../../../../../web/assets/continuewithfacebook.png';
import FacebookFullLogoImage from '../../../../../web/assets/facebook_full_logo.png';
import LinkedInFullLogoImage from '../../../../../web/assets/linked_in_full_logo.png';
import InstagramFullLogoImage from '../../../../../web/assets/loginwithinstagram.png';
import { baseStyleSheet } from '../../../../styles/styles';
import { Button } from '../../../Button';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../ConfirmationDialog';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { IModalProps, asModalComponent } from '../../../Modal';
import { RadioButton } from '../../../RadioButton';
import { StepBubbleGraphic, StepBubbleGraphicType } from '../../../svgs/graphics/StepBubbleGraphic';
import { CancelIconFill } from '../../../svgs/icons/CancelIconFill';
import { SuccessCheckIcon } from '../../../svgs/icons/SuccessCheckIcon';
import { WarningIcon } from '../../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';

const DeleteConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		representedObject: true,
		title: 'Delete Now',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

const DisconnectConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		representedObject: true,
		title: 'Disconnect Now',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

const ReconnectConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		representedObject: true,
		title: <img src={ContinueWithFacebookImageUrl} width='auto' height={42} />,
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

const ReconnectConfirmationLinkedInOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		representedObject: true,
		title: 'Continue with LinkedIn',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

const AddAccountConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		representedObject: true,
		title: <img src={ContinueWithFacebookImageUrl} width='auto' height={42} />,
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

const AddAccountLinkedInConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		representedObject: true,
		title: 'Continue with LinkedIn',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

export interface ISocialMediaSettingsServiceConnectionsStatusProps {
	viewModel: Api.SocialMediaViewModel;
	readOnly: boolean;
}

export interface IDisconnectProps extends IModalProps<boolean> {
	conn: Api.ISocialMediaConnection;
}

export interface IAddOrReconnectProps extends IModalProps<boolean> {
	connType: Api.SocialMediaType;
}

export const SocialMediaSettingsServiceConnectionStatus: React.FC<ISocialMediaSettingsServiceConnectionsStatusProps> =
	observer(({ viewModel, readOnly }) => {
		const errorMessages = useErrorMessages();
		const { logApiError } = useEventLogging('SocialMediaSettingsServiceConnectionStatus');
		const toaster = useToaster();

		const [addAccountConfirmationDialogProps, setAddAccountConfirmationDialogProps] =
			React.useState<IAddOrReconnectProps>({
				connType: null,
				isOpen: false,
				onRequestClose: Api.VmUtils.Noop,
			});
		const [disconnectConfirmationDialogProps, setDisconnectConfirmationDialogProps] = React.useState<IDisconnectProps>({
			conn: null,
			isOpen: false,
			onRequestClose: Api.VmUtils.Noop,
		});

		const [connToDelete, setConnToDelete] = React.useState<Api.ISocialMediaConnection | null>(null);

		const [reconnectConfirmationDialogProps, setReconnectConfirmationDialogProps] =
			React.useState<IAddOrReconnectProps>({
				connType: null,
				isOpen: false,
				onRequestClose: Api.VmUtils.Noop,
			});

		const connections = viewModel.connections;

		const getAuthUrl = React.useCallback(async () => {
			try {
				const opResult = await viewModel.getAuthUrl();

				return opResult.value;
			} catch (err) {
				logApiError('GetAuthUrl-Error', err);

				errorMessages.pushApiError(err);
			}
		}, [errorMessages, logApiError, viewModel]);

		const renderConnectionStatus = (conn: Api.ISocialMediaConnection) => {
			switch (conn?.state) {
				case Api.SocialMediaConnectionState.Connected: {
					return (
						<div className={css(styleSheet.serviceConnectionStatus)}>
							<div className={css(styleSheet.serviceDisconnectionStatus)}>
								<span>{conn.userName || 'Unknown'}</span>
								<SuccessCheckIcon />
							</div>
							<span>@{conn.postTargetDisplayName || 'Unknown'} </span>
						</div>
					);
				}
				case Api.SocialMediaConnectionState.Disconnected: {
					return (
						<div className={css(styleSheet.serviceConnectionStatus)}>
							<div className={css(styleSheet.serviceDisconnectionStatus)}>
								<span>{conn?.userName || 'Unknown'}</span>
								<CancelIconFill />
							</div>
							<span>@{conn?.postTargetDisplayName || 'Unknown'}</span>
							<p className={css(styleSheet.disconnectedDetails)}>
								{' '}
								{`Disconnected: ${
									moment(conn.expiresAt) > moment()
										? moment(conn.lastModifiedDate)?.format('L') || 'Unknown'
										: moment(conn.expiresAt)?.format('L') || 'Unknown'
								}`}{' '}
							</p>
						</div>
					);
				}
				case Api.SocialMediaConnectionState.NoTarget: {
					return (
						<div className={css(styleSheet.serviceConnectionStatus)}>
							<div className={css(styleSheet.serviceDisconnectionStatus)}>
								<span>{conn?.userName || 'Unknown'}</span>
								<CancelIconFill />
							</div>
							<span>@{conn?.postTargetDisplayName || 'Unknown'}</span>
							<p className={css(styleSheet.disconnectedDetails)}>
								{' '}
								{`No Target: ${
									moment(conn.expiresAt) > moment()
										? moment(conn.lastModifiedDate)?.format('L') || 'Unknown'
										: moment(conn.expiresAt)?.format('L') || 'Unknown'
								}`}{' '}
							</p>
						</div>
					);
				}
				default: {
					return null;
				}
			}
		};

		const disconnect = React.useCallback(
			async (conn?: Api.ISocialMediaConnection) => {
				try {
					await viewModel.disconnect(conn);

					toaster.push({
						message: `${conn.type} account successfully disconnected.`,
						type: 'successMessage',
					});
				} catch (err) {
					logApiError('Disconnect-Error', err);

					errorMessages.pushApiError(err);
				}
			},
			[errorMessages, logApiError, toaster, viewModel]
		);

		const reconnect = React.useCallback(async () => {
			window.location.href = await getAuthUrl();
		}, [getAuthUrl]);

		const onDeleted = React.useCallback(
			async (result, cancel) => {
				if (!connToDelete) {
					return;
				}

				if (result && !cancel) {
					await disconnect(connToDelete);
				}

				setConnToDelete(null);
			},
			[connToDelete, disconnect]
		);

		const onCtaClicked = React.useCallback(
			(conn?: Api.ISocialMediaConnection) => async () => {
				switch (conn?.state) {
					case Api.SocialMediaConnectionState.NoTarget:
					case Api.SocialMediaConnectionState.Connected: {
						setDisconnectConfirmationDialogProps(value => {
							return {
								...value,
								conn,
								isOpen: true,
								onRequestClose: (result, cancel) => {
									setDisconnectConfirmationDialogProps(currVal => {
										return { ...currVal, conn, isOpen: false };
									});
									if (result && !cancel) {
										disconnect(conn);
									}
								},
							};
						});
						break;
					}
					default: {
						setReconnectConfirmationDialogProps(value => {
							return {
								...value,
								connType: conn?.type,
								isOpen: true,
								onRequestClose: (result, cancel) => {
									setReconnectConfirmationDialogProps(currVal => {
										return { ...currVal, connType: conn?.type, isOpen: false };
									});
									if (result && !cancel) {
										reconnect();
									}
								},
							};
						});
						break;
					}
				}
			},
			[disconnect, reconnect]
		);

		const renderButton = (conn: Api.ISocialMediaConnection) => {
			return (
				<button
					id={conn.postTargetId}
					className={
						conn.state === Api.SocialMediaConnectionState.Connected ||
						conn.state === Api.SocialMediaConnectionState.NoTarget
							? css(
									baseStyleSheet.ctaButtonDestructiveSmall,
									styleSheet.disconnectButton,
									viewModel.isBusy ? styleSheet.serviceConnectionCtaLoading : null
								)
							: css(
									baseStyleSheet.ctaButtonReverseSmall,
									styleSheet.connectButton,
									viewModel.isBusy ? styleSheet.serviceConnectionCtaLoading : null
								)
					}
					disabled={viewModel.isBusy}
					onClick={onCtaClicked(conn)}
				>
					<span>
						{conn.state === Api.SocialMediaConnectionState.Connected ||
						conn.state === Api.SocialMediaConnectionState.NoTarget
							? 'Disconnect'
							: 'Reconnect'}
					</span>
				</button>
			);
		};

		const renderDeleteButton = (conn: Api.ISocialMediaConnection) => {
			if (conn.state !== Api.SocialMediaConnectionState.Disconnected) {
				return null;
			}

			return (
				<Button
					kind='destructive'
					size='small'
					onClick={() => setConnToDelete(conn)}
					isLoading={viewModel.isBusy}
					className={css(styleSheet.disconnectButton)}
				>
					<span>Delete</span>
				</Button>
			);
		};

		const onContinueCtaClicked = React.useCallback(
			(connType: Api.SocialMediaType) => async () => {
				setAddAccountConfirmationDialogProps(value => {
					return {
						...value,
						connType,
						isOpen: true,
						onRequestClose: (result, cancel) => {
							setAddAccountConfirmationDialogProps(currVal => {
								return { ...currVal, connType, isOpen: false };
							});
							if (result && !cancel) {
								if (result && !cancel) {
									reconnect();
								}
							}
						},
					};
				});
			},
			[reconnect]
		);

		const getMediaLogo = () => {
			switch (viewModel.getConnectionType()) {
				case Api.SocialMediaType.Facebook: {
					return FacebookFullLogoImage;
				}
				case Api.SocialMediaType.Instagram: {
					return InstagramFullLogoImage;
				}
				case Api.SocialMediaType.LinkedIn: {
					return LinkedInFullLogoImage;
				}
				default: {
					return;
				}
			}
		};

		return (
			<div>
				<img src={getMediaLogo()} height={54} />

				<div className={css(styleSheet.serviceConnection)}>
					{connections?.length > 0
						? connections.map((conn, i) => {
								return (
									<div key={i} className={css(styleSheet.socialContainer)}>
										{renderConnectionStatus(conn)}
										<div className={css(styleSheet.socialSettingsContainer)}>
											{renderButton(conn)}
											{renderDeleteButton(conn)}
										</div>
										{disconnectConfirmationDialogProps?.isOpen && (
											<ConfirmationDialog
												icon={<WarningIcon />}
												modalProps={{ ...disconnectConfirmationDialogProps, conn } as IDisconnectProps}
												options={DisconnectConfirmationOptions}
												title='Disconnect Account'
											>
												Are you sure you want to disconnect this account?
											</ConfirmationDialog>
										)}
										{Boolean(connToDelete) && (
											<ConfirmationDialog
												icon={<WarningIcon />}
												modalProps={
													{
														conn: connToDelete,
														isOpen: Boolean(connToDelete),
														onRequestClose: onDeleted,
													} as IDisconnectProps
												}
												options={DeleteConfirmationOptions}
												title='Delete Account'
											>
												Are you sure you want to delete this account?
											</ConfirmationDialog>
										)}
										{reconnectConfirmationDialogProps?.isOpen &&
										reconnectConfirmationDialogProps?.connType === Api.SocialMediaType.LinkedIn ? (
											<ConfirmationDialog
												modalProps={
													{ ...reconnectConfirmationDialogProps, connType: conn.type } as IAddOrReconnectProps
												}
												options={ReconnectConfirmationLinkedInOptions}
												title='Reconnect Account'
											>
												<p className={css(styleSheet.modalText)}>Continue to reconnect this account?</p>
											</ConfirmationDialog>
										) : reconnectConfirmationDialogProps?.isOpen ? (
											<ConfirmationDialog
												className={css(styleSheet.addAccountConfirmation)}
												modalProps={
													{ ...reconnectConfirmationDialogProps, connType: conn.type } as IAddOrReconnectProps
												}
												options={ReconnectConfirmationOptions}
												title='Reconnect Account'
											>
												<p className={css(styleSheet.modalText)}>Continue to reconnect this account?</p>
											</ConfirmationDialog>
										) : null}
									</div>
								);
							})
						: null}
					{readOnly ? null : (
						<div className={css(styleSheet.addAccounts)}>
							{viewModel.isBusy ? (
								<LoadingSpinner
									className={css(baseStyleSheet.absoluteCenter, styleSheet.serviceConnectionCtaLoading)}
									type='tiny'
								/>
							) : (
								<button
									className={css(baseStyleSheet.ctaButtonSmall, styleSheet.loginButton)}
									disabled={viewModel.isBusy}
									onClick={onContinueCtaClicked(viewModel.getConnectionType())}
								>
									<p>+ Add account</p>
								</button>
							)}
						</div>
					)}
				</div>
				{addAccountConfirmationDialogProps?.isOpen &&
				addAccountConfirmationDialogProps?.connType === Api.SocialMediaType.LinkedIn ? (
					<ConfirmationDialog
						className={css(styleSheet.addAccountConfirmationLinkedIn)}
						modalProps={
							{ ...addAccountConfirmationDialogProps, connType: Api.SocialMediaType.LinkedIn } as IAddOrReconnectProps
						}
						options={AddAccountLinkedInConfirmationOptions}
						title='Add an Account'
					>
						<p className={css(styleSheet.modalText)}>Continue to connect a new account?</p>
					</ConfirmationDialog>
				) : addAccountConfirmationDialogProps?.isOpen ? (
					<ConfirmationDialog
						className={css(styleSheet.addAccountConfirmation)}
						modalProps={
							{ ...addAccountConfirmationDialogProps, connType: viewModel.getConnectionType() } as IAddOrReconnectProps
						}
						options={AddAccountConfirmationOptions}
						title='Add an Account'
					>
						<p className={css(styleSheet.modalText)}>Continue to connect a new account?</p>
					</ConfirmationDialog>
				) : null}
			</div>
		);
	});

interface ISocialMediaPostTargetSelectorBaseProps extends IModalContext<Api.ISocialMediaPage> {
	viewModel: Api.SocialMediaViewModel;
}

const SocialMediaPostTargetSelector: React.FC<ISocialMediaPostTargetSelectorBaseProps> = ({
	viewModel,
	parentModal,
}) => {
	const onCancel = React.useCallback(() => {
		parentModal?.onRequestClose(null, true);
	}, [parentModal]);

	const [selectedPostTarget, setSelectedPostTarget] = React.useState<Api.ISocialMediaPage>(null);
	const onRadioButtonCheckChanged = React.useCallback(
		(page: Api.ISocialMediaPage) => (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked) {
				setSelectedPostTarget(page);
			}
		},
		[]
	);

	const onSaveClicked = React.useCallback(() => {
		if (selectedPostTarget) {
			parentModal?.onRequestClose(selectedPostTarget, false);
		}
	}, [selectedPostTarget, parentModal]);

	const postHeading =
		viewModel.getConnectionType() === Api.SocialMediaType.Facebook
			? 'Choose page to post to:'
			: 'Choose account to post to:';

	return (
		<div className={css(styleSheet.facebookPostTargetSelector)}>
			<h3 className={css(styleSheet.facebookPostTargetSelectorTitle)}>{postHeading}</h3>
			<div className={css(baseStyleSheet.verticalStack, styleSheet.facebookPostTargetSelectorFooterOptions)}>
				{viewModel.pages?.map(x => {
					return (
						<RadioButton
							checked={
								selectedPostTarget === x ||
								viewModel?.connections?.filter(
									y => y.postTargetId === x.id && y.state === Api.SocialMediaConnectionState.Connected
								)?.length > 0
							}
							disabled={
								!x?.canPost ||
								viewModel?.connections?.filter(
									y => y.postTargetId === x.id && y.state === Api.SocialMediaConnectionState.Connected
								)?.length > 0
							}
							id={`page-id-${x.id}`}
							key={x.id}
							labelClassName={!x?.canPost ? css(baseStyleSheet.disabled) : css(styleSheet.radioButtonLabel)}
							onChange={x?.canPost ? onRadioButtonCheckChanged(x) : undefined}
						>
							<span>{x.name}</span>
							{!x?.canPost && (
								<span className={css(baseStyleSheet.italicText)}>
									{` (You do not have the rights to post to this ${
										viewModel.getConnectionType() === Api.SocialMediaType.Facebook ? 'page.' : 'account.'
									} Please check with the account admin.)`}
								</span>
							)}
							{viewModel?.connections?.filter(
								y => y.postTargetId === x.id && y.state === Api.SocialMediaConnectionState.Connected
							)?.length > 0 && <SuccessCheckIcon />}
						</RadioButton>
					);
				})}
			</div>
			<div className={css(baseStyleSheet.horizontalStack)}>
				<button className={css(baseStyleSheet.ctaButton)} disabled={!selectedPostTarget} onClick={onSaveClicked}>
					<span>Save</span>
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCancel}>
					<span>Cancel</span>
				</button>
			</div>
		</div>
	);
};

export const SocialMediaPostTargetSelectorModal = asModalComponent(
	inject(ModalChildComponentContextKey)(SocialMediaPostTargetSelector),
	{ shouldCloseOnOverlayClick: false, useDefaultHeader: true }
);

const SocialMedialConnectionSuccessBase: React.FC<IModalContext> = ({ parentModal }) => {
	const onCloseClicked = React.useCallback(() => {
		parentModal?.onRequestClose();
	}, [parentModal]);

	return (
		<div className={css(styleSheet.serviceConnectionSuccess)}>
			<StepBubbleGraphic type={StepBubbleGraphicType.AnimatedSuccess} />
			<div className={css(styleSheet.serviceConnectionSuccessMessage)}>
				<span>Your account has been</span>
				<br />
				<span>successfully connected.</span>
			</div>
			<button className={css(baseStyleSheet.ctaButton)} onClick={onCloseClicked}>
				<span>Close</span>
			</button>
		</div>
	);
};

export const SocialMedialConnectionSuccessModal = asModalComponent(
	inject(ModalChildComponentContextKey)(SocialMedialConnectionSuccessBase),
	{
		shouldCloseOnOverlayClick: false,
		useDefaultHeader: true,
	}
);
