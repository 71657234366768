import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { DefaultEditableActionItemContentCSS, IModal } from '../../../../../models';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultDateStringValue,
	getDisplayName,
	numberToCurrencyStringValue,
	sanitizeCurrencyStringValue,
} from '../../../../../models/UiUtils';
import { useEnvironment } from '../../../../../models/hooks/appStateHooks';
import {
	invalidateAllGetBoardItems,
	useCreateOpportunityMutation,
	useUpdateOpportunityMutation,
} from '../../../../../queries';
import CompanyThumbUrl from '../../../../assets/companyThumb.svg';
import CalendarIconUrl from '../../../../assets/icon_calendar.svg';
import { white } from '../../../../styles/colors';
import { Avatar } from '../../../Avatar';
import { Button } from '../../../Button';
import { CloseButton } from '../../../CloseButton';
import { DayPicker } from '../../../DayPicker';
import { DeprecatedPopover, PopoverType } from '../../../DeprecatedPopover';
import { DeprecatedSelect, ISelectOption } from '../../../DeprecatedSelect';
import { InputFieldError } from '../../../InputFieldError';
import { Modal } from '../../../Modal';
import { TextInput } from '../../../TextInput';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../../richContent/RichContentDocumentEditor';
import { ClearFieldIcon } from '../../../svgs/icons/ClearFieldIcon';
import { NavIcon } from '../../../svgs/icons/NavIcon';
import { EditOpportunitySearchField } from '../EditOpportunitySearchField';
import { styleSheet } from './styles';

const DefaultDetailsEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentRawCss: DefaultEditableActionItemContentCSS,
	plugins: [], // none
	toolbar: false,
};

const _EditOpportunityModal = ({
	opportunity,
	boards,
	parentModal,
	onSave,
	onCancel,
	initialStage,
	initialBoard,
}: {
	opportunity: Api.IOpportunity;
	boards?: Api.IBoard[];
	parentModal?: IModal;
	onSave: (opportunity: Api.IOpportunity) => void;
	onCancel: () => void;
	initialStage?: Api.IBoardStage;
	initialBoard?: Api.IBoard;
}) => {
	const environment = useEnvironment();
	const contactInputRef = React.useRef<HTMLInputElement>(null);

	const [amountErrorMessage, setAmountErrorMessage] = React.useState<string>('');
	const [amountStringValue, setAmountStringValue] = React.useState<string>(opportunity.dealSize?.toString() ?? '');
	const [board, setBoard] = React.useState<Api.IBoard>(initialBoard ?? opportunity.boardStage?.board ?? boards?.[0]);
	const [closeDate, setCloseDate] = React.useState<Date>(
		opportunity.closeDate ? new Date(opportunity.closeDate) : null
	);
	const [closingDateErrorMessage, setClosingDateErrorMessage] = React.useState<string>('');
	const [company, setCompany] = React.useState<Api.ICompany>(opportunity.company ?? null);
	const [companyErrorMessage, setCompanyErrorMessage] = React.useState<string>('');
	const [detailsEditorState, setDetailsEditorState] = React.useState<Api.IRichContentEditorState>(null);
	const [name, setName] = React.useState<string>(opportunity.name ?? '');
	const [nameErrorMessage, setNameErrorMessage] = React.useState<string>('');
	const [owner, setOwner] = React.useState<Api.IUser>(opportunity.assignees?.[0] ?? null);
	const [ownerErrorMessage, setOwnerErrorMessage] = React.useState<string>('');
	const [primaryContact, setPrimaryContact] = React.useState<Api.IContact>(opportunity.primaryContact ?? null);
	const [primaryContactErrorMessage, setPrimaryContactErrorMessage] = React.useState<string>('');
	const [showingCloseDatePopover, setShowingCloseDatePopover] = React.useState<boolean>(false);
	const [stage, setStage] = React.useState<Api.IBoardStage>(null);
	const [stageErrorMessage, setStageErrorMessage] = React.useState<string>('');

	const updateOpportunityMutation = useUpdateOpportunityMutation({
		onSuccess: () => invalidateAllGetBoardItems(),
	});

	const createOpportunityMutation = useCreateOpportunityMutation({
		onSuccess: () => invalidateAllGetBoardItems(),
	});

	React.useEffect(() => {
		setDetailsEditorState(convertRawRichTextContentStateToRichContentEditorState(opportunity.details));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		contactInputRef?.current?.focus();
	}, []);

	const onInputFocus = () => {
		clearErrorMessages();
	};

	const onClosingDaySelected = (day: Date) => {
		setCloseDate(day);
		setShowingCloseDatePopover(false);
	};

	const onChangeClosingDateClicked = () => {
		setShowingCloseDatePopover(!showingCloseDatePopover);
		clearErrorMessages();
	};

	const onAmountInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const stringValue = (e.target as HTMLInputElement).value || '0.00';
		const currencyStringValue = sanitizeCurrencyStringValue(stringValue);
		if (currencyStringValue) {
			setAmountStringValue(numberToCurrencyStringValue(parseFloat(currencyStringValue)));
		}
	};

	const clearErrorMessages = () => {
		setAmountErrorMessage(null);
		setClosingDateErrorMessage(null);
		setCompanyErrorMessage(null);
		setNameErrorMessage(null);
		setOwnerErrorMessage(null);
		setPrimaryContactErrorMessage(null);
		setStageErrorMessage(null);
	};

	const validateInputs = () => {
		if (!primaryContact) setPrimaryContactErrorMessage('A primary contact is required.');
		if (!owner) setOwnerErrorMessage('An owner is required.');
		if (!name) setNameErrorMessage('An opportunity name is required.');
		if (!stage) setStageErrorMessage('An opportunity stage is required.');

		if (!primaryContact || !owner || !name || !stage) return false;

		return true;
	};

	const onSaveButtonClicked = async () => {
		if (!validateInputs()) return;

		const companyId = company ? company.id : primaryContact.companyId;
		const stageId = (stage || {}).id;

		const opportunityModel: Api.IOpportunity = {
			assignees: [{ id: owner.id }],
			boardStage: { id: stageId },
			closeDate: closeDate ? closeDate.toISOString() : null,
			company: { id: companyId },
			dealSize: parseFloat(sanitizeCurrencyStringValue(amountStringValue)),
			details: detailsEditorState ? detailsEditorState.getRawRichTextContent() : null,
			name,
			primaryContact: { id: primaryContact.id },
		};

		if (!!opportunity && !!opportunity.id) {
			opportunityModel.id = opportunity.id;
			await updateOpportunityMutation.mutateAsync(opportunityModel);
		} else {
			await createOpportunityMutation.mutateAsync(opportunityModel);
		}
		onSave(opportunityModel);
	};

	const onClearCompanySearchFieldClicked = (e: React.MouseEvent<HTMLElement>) => {
		setCompany(null);
		e.stopPropagation();
	};

	const onClearPrimaryContactSearchFieldClicked = (e: React.MouseEvent<HTMLElement>) => {
		setPrimaryContact(null);
		e.stopPropagation();
	};

	const onClearOwnerSearchFieldClicked = (e: React.MouseEvent<HTMLElement>) => {
		setOwner(null);
		e.stopPropagation();
	};

	const renderFieldLabel = (title: string, required = true, className?: string, styles?: StyleDeclarationValue[]) => {
		return (
			<div className={`${css(styleSheet.fieldLabel, ...(styles || []))} ${className || ''}`}>
				{title + ': '}
				{required ? <span>*</span> : null}
			</div>
		);
	};

	const onCompanySelected = (newCompany: Api.ICompany) => {
		setCompany(newCompany);
	};

	const onOwnerSelected = (newOwner: Api.IUser) => {
		setOwner(newOwner);
	};

	const onPrimaryContactSelected = (newContact: Api.IContact) => {
		setCompany({
			companyName: newContact.companyName,
			id: newContact.companyId,
		});
		setName(newContact.firstName + ' ' + newContact.lastName);
		setPrimaryContact(newContact);
	};

	const onStageSelected = (newStage: ISelectOption<Api.OpportunitiesBoardStageViewModel>) => {
		setStage(newStage.dataContext);
	};

	const onBoardSelected = (boardOption: ISelectOption<Api.IBoard>) => {
		const newBoard = boardOption.dataContext;
		setBoard(newBoard);
	};

	const onRequestClose = (result?: Api.OpportunityViewModel, canceled?: boolean) => () => {
		if (parentModal) {
			parentModal.onRequestClose(result, canceled);
		}
		if (canceled) {
			onCancel();
		}
	};

	const onInputRef = (ref: HTMLInputElement) => {
		contactInputRef.current = ref;
	};

	const loadedBoard = boards.find(loadedBoards => loadedBoards.id === board.id);
	const stageOptions: ISelectOption<Api.IBoardStage>[] = loadedBoard.stages.map(s => ({
		dataContext: s,
		id: s.id,
		text: s.name,
	}));

	React.useEffect(() => {
		if (!loadedBoard) return;

		// If the current stage is not found in the board, set the first stage and return
		if (stage && !loadedBoard.stages.find(s => s.id === stage.id)) {
			setStage(loadedBoard.stages[0]);
			return;
		}

		// If the initial stage is not set, try to come up with the best default stage
		const initialStageObj = initialStage ?? opportunity.boardStage ?? initialBoard?.stages[0] ?? boards?.[0].stages[0];
		const matchingStage = loadedBoard.stages.find(s => s.id === initialStageObj.id);
		setStage(matchingStage);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadedBoard]);

	if (!loadedBoard) return null;

	const selectedStageOption = stage
		? {
				dataContext: stage,
				id: stage.id,
				text: stage.name,
			}
		: stageOptions[0];

	const defaultBoardOptions: ISelectOption<Api.IBoard>[] = [{ dataContext: board, id: board.id, text: board.name }];
	const boardOptions: ISelectOption<Api.IBoard>[] = boards
		? boards.map(boardOption => {
				return { dataContext: boardOption, id: boardOption.id, text: boardOption.name };
			})
		: defaultBoardOptions;

	const selectedBoard = boardOptions.find(boardOption => boardOption.id === board.id);

	const isSaving = updateOpportunityMutation.isLoading || createOpportunityMutation.isLoading;

	return (
		<Modal
			isOpen
			overlayPresentationStyle={{
				className: 'modal-overlay-no-padding modal-overlay',
				transitionInDuration: 450,
				transitionOutDuration: 450,
			}}
			useDefaultHeader={false}
		>
			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.header)}>
					<NavIcon iconName='Opportunities' fillColor={white} />
					<CloseButton fillColor={white} onClick={onRequestClose(null, true)} />
				</div>
				<div className={css(styleSheet.contentContainer)}>
					<div className={css(styleSheet.contentContainerLeft)}>
						<div className={css(styleSheet.title)}>{`${opportunity.id ? 'Edit' : 'Add New'} Opportunity`}</div>
						<div className={css(styleSheet.body)}>
							<div className={css(styleSheet.bodyContent)}>
								{renderFieldLabel('Primary Contact')}
								<InputFieldError errorMessage={primaryContactErrorMessage}>
									<EditOpportunitySearchField
										className={css(styleSheet.primary, styleSheet.focus)}
										hideResultsFooter={false}
										inputId='edit-opportunity-contact-input'
										leftAccessory={
											primaryContact ? (
												<Avatar className={css(styleSheet.bodyAvatar)} entity={primaryContact} />
											) : undefined
										}
										onClearButtonClicked={onClearPrimaryContactSearchFieldClicked}
										onFocus={onInputFocus}
										onInputRef={onInputRef}
										onItemSelected={onPrimaryContactSelected}
										placeholder='Search contacts'
										showClearButton={!!primaryContact}
										showSearchLeftAccessory
										type={Api.ResourceAutoCompleteViewModelType.Contact}
										value={(primaryContact ? getDisplayName(primaryContact) : null) || ''}
									/>
								</InputFieldError>

								{renderFieldLabel('Opportunity Name')}
								<InputFieldError errorMessage={nameErrorMessage}>
									<TextInput
										className={css(styleSheet.field, styleSheet.focus)}
										inputId='edit-opportunity-name-input'
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
										onFocus={onInputFocus}
										type='text'
										value={name || ''}
									/>
								</InputFieldError>

								<div className={css(styleSheet.subtitle)}>
									This is the main title. It could be a project name or property name.{' '}
								</div>

								{renderFieldLabel('Opportunity Owner')}
								<InputFieldError errorMessage={ownerErrorMessage}>
									<EditOpportunitySearchField
										className={css(styleSheet.field, styleSheet.focus)}
										inputId='edit-opportunity-owner-input'
										leftAccessory={owner ? null : undefined}
										onClearButtonClicked={onClearOwnerSearchFieldClicked}
										onFocus={onInputFocus}
										onItemSelected={onOwnerSelected}
										placeholder='Search users'
										showClearButton={!!owner}
										showSearchLeftAccessory
										type={Api.ResourceAutoCompleteViewModelType.User}
										value={(owner ? getDisplayName(owner) : null) || ''}
									/>
								</InputFieldError>

								{renderFieldLabel('Opportunity Board', true)}
								{boardOptions.length ? (
									<DeprecatedSelect
										styles={[styleSheet.selectStyles]}
										options={boardOptions}
										onOptionClick={onBoardSelected}
										selectedOption={selectedBoard}
									/>
								) : (
									<TextInput
										className={css(styleSheet.field)}
										inputId='edit-opportunity-disabled-board'
										type='text'
										disabled
										value={selectedBoard.text}
									/>
								)}

								{renderFieldLabel('Opportunity Stage', true)}
								{stageOptions ? (
									<DeprecatedSelect
										styles={[styleSheet.selectStyles]}
										options={stageOptions}
										onOptionClick={onStageSelected}
										selectedOption={selectedStageOption}
									/>
								) : (
									<InputFieldError errorMessage={stageErrorMessage}>
										<TextInput
											className={css(styleSheet.field)}
											inputId='edit-opportunity-disabled-stage'
											type='text'
											disabled
											value={selectedStageOption.text}
										/>
									</InputFieldError>
								)}

								<div className={css(styleSheet.buttonGroup)}>
									<Button kind='primary' onClick={onSaveButtonClicked} isLoading={isSaving} label='Save' />
									<Button kind='reverse' onClick={onRequestClose(null, true)} disabled={isSaving} label='Cancel' />
								</div>
							</div>
						</div>
					</div>
					<div className={css(styleSheet.contentContainerRight)}>
						<div className={css(styleSheet.optional)}>optional fields</div>
						{renderFieldLabel('Company', false)}
						<InputFieldError errorMessage={companyErrorMessage}>
							<EditOpportunitySearchField
								className={css(styleSheet.company, styleSheet.focus)}
								disabled={!!company}
								inputId='edit-opportunity-company-input'
								leftAccessory={company ? <img src={CompanyThumbUrl} /> : undefined}
								onClearButtonClicked={onClearCompanySearchFieldClicked}
								onFocus={onInputFocus}
								onItemSelected={onCompanySelected}
								placeholder='Search companies'
								showClearButton={!!company}
								showSearchLeftAccessory
								type={Api.ResourceAutoCompleteViewModelType.Company}
								value={(company ? company.companyName : null) || ''}
							/>
						</InputFieldError>

						{renderFieldLabel('Amount', false, null, [styleSheet.amountTitle])}
						<InputFieldError errorMessage={amountErrorMessage} className={css(styleSheet.inputFieldError)}>
							<TextInput
								className={css(styleSheet.field, styleSheet.focus)}
								inputId='edit-opportunity-amount-input'
								leftAccessory={<span className={css(styleSheet.dollar)}>$</span>}
								onBlur={onAmountInputBlur}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmountStringValue(e.target.value)}
								onFocus={onInputFocus}
								placeholder='0.00'
								type='text'
								value={amountStringValue || ''}
							/>
						</InputFieldError>
						{renderFieldLabel('Closing Date', false, css(styleSheet.closingDateTitle))}
						<InputFieldError errorMessage={closingDateErrorMessage} className={css(styleSheet.inputFieldError)}>
							<div className={css(styleSheet.closingDate)}>
								<button className={css(styleSheet.closingDateFieldButton)} onClick={onChangeClosingDateClicked}>
									<DeprecatedPopover
										anchor={<img src={CalendarIconUrl} />}
										dismissOnClickOutside
										isOpen={showingCloseDatePopover}
										onRequestClose={() => setShowingCloseDatePopover(false)}
										preferredPlacement='above'
										type={PopoverType.white}
									>
										<div className={css(styleSheet.dayPicker)}>
											<DayPicker
												allowPastDates
												environment={environment}
												onDayClick={onClosingDaySelected}
												selectedDays={closeDate}
											/>
										</div>
									</DeprecatedPopover>
									{!!closeDate && <span>{getDefaultDateStringValue(closeDate)}</span>}
								</button>
								<button className={css(styleSheet.clearButton)} onClick={() => setCloseDate(null)}>
									<ClearFieldIcon />
								</button>
							</div>
						</InputFieldError>
						{renderFieldLabel('Details', false)}
						<div className={css(styleSheet.bodyDetailsField)}>
							<RichContentDocumentEditor
								contentClassName={css(styleSheet.details)}
								config={DefaultDetailsEditorConfig}
								contentState={detailsEditorState}
								onContentStateChanged={(newState: Api.IRichContentEditorState) => setDetailsEditorState(newState)}
								onFocus={onInputFocus}
							/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export const EditOpportunityModal = observer(_EditOpportunityModal);
