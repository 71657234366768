import { StyleSheet } from 'aphrodite';
import {
	background,
	borderColor,
	brandPrimaryText,
	connectionStatusText,
	inputBorderColor,
	label,
	urgentDate,
} from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	addAccountConfirmation: {
		...CssSelectors.allDescendants(
			{
				':first-child': {
					background: 'white',
					border: 'none',
					boxShadow: 'none',
					padding: 0,
				},
			},
			'button'
		),
	},
	addAccountConfirmationLinkedIn: {},
	addAccounts: {
		...CssSelectors.allChildren(
			{
				fontSize: 12,
				marginBottom: 4,
			},
			'p'
		),
		display: 'inline-flex',
		flexDirection: 'column',
		margin: '20px 0 0 0',
	},
	connectButton: {
		fontSize: 12,
		whiteSpace: 'nowrap',
	},
	container: {},
	disconnectButton: {
		':hover': {
			background: '#d0021b0d',
		},
		fontSize: '12px !important',
		whiteSpace: 'nowrap',
	},
	disconnectedDetails: {
		color: urgentDate,
		margin: 0,
		paddingTop: 2,
		whiteSpace: 'nowrap',
	},
	facebookPostTargetSelector: {},
	facebookPostTargetSelectorFooterOptions: {
		color: label,
		fontSize: 14,
		marginBottom: 24,
	},
	facebookPostTargetSelectorTitle: {
		color: brandPrimaryText,
		fontSize: 16,
		marginBottom: 16,
	},
	loginButton: {
		...CssSelectors.allChildren(
			{
				fontSize: 12,
				margin: 0,
			},
			'p'
		),
	},
	modalText: {
		margin: 0,
		paddingTop: 10,
		textAlign: 'center',
	},
	providerContainer: {
		paddingLeft: 10,
	},
	radioButtonLabel: {
		...CssSelectors.allChildren(
			{
				marginLeft: 4,
			},
			'svg'
		),
		display: 'flex',
	},
	serviceConnection: {
		...CssSelectors.allChildren(
			{
				marginTop: 14,
			},
			':not(:first-child)'
		),
		paddingLeft: 10,
		paddingTop: 10,
	},
	serviceConnectionCta: {
		alignItems: 'center',
		background,
		border: `1px solid ${inputBorderColor}`,
		borderRadius: 2,
		boxSizing: 'border-box',
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		padding: '10px 12px',
		position: 'relative',
	},
	serviceConnectionCtaHiddenText: {
		opacity: 0,
	},
	serviceConnectionCtaLoading: {
		zIndex: 1,
	},
	serviceConnectionName: {
		color: brandPrimaryText,
		fontSize: 12,
	},
	serviceConnections: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		maxWidth: 1400,
		...CssSelectors.allChildren({
			border: `1px solid ${borderColor}`,
			boxSizing: 'border-box',
			flex: 1,
			maxWidth: 'calc(100%/2)',
			minWidth: 320,
			padding: 20,
		}),
	},
	serviceConnectionStatus: {
		alignItems: 'flex-start',
		color: connectionStatusText,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 12,
	},
	serviceConnectionSuccess: {
		textAlign: 'center',
		...CssSelectors.allChildren(
			{
				marginLeft: 'auto',
				marginRight: 'auto',
			},
			'*'
		),
	},
	serviceConnectionSuccessMessage: {
		color: brandPrimaryText,
		fontSize: 16,
		marginBottom: 24,
		marginTop: 20,
	},
	serviceDisconnectionStatus: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 12,
		...CssSelectors.allChildren(
			{
				height: 16,
				marginLeft: 4,
				padding: '2px 0',
				width: 'auto',
			},
			'svg'
		),
	},
	socialContainer: {
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'space-between',
	},
	socialSettingsContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
});
