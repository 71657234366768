import { css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../styles/styles';
import { EmptySearchIcon } from '../svgs/icons/EmptySearchIcon';
import { styleSheet } from './styles';

interface DataboardNoResultsProps {
	onClearSearch: () => void;
	styles?: any[];
}

export const DataboardNoResults: React.FC<DataboardNoResultsProps> = ({ onClearSearch, styles = [] }) => {
	return (
		<div className={css(styleSheet.noResults, ...styles)}>
			<EmptySearchIcon />
			<span>We did not find anyone matching your search criteria.</span>
			<span>Please clear your filters and try again.</span>
			<button className={css(baseStyleSheet.ctaButton)} onClick={onClearSearch}>
				<span>Clear Search</span>
			</button>
		</div>
	);
};
