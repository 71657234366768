import * as Api from '@ViewModels';
import { Badge } from '@WebComponents/dataBoards/Badge';
import { DataGrid, DataGridRow, DataGridRowItem, DataGridRows } from '@WebComponents/DataGrid';
import { DetailsFlyout } from '@WebComponents/DetailsFlyout';
import { styleSheet as flyoutStyleSheet } from '@WebComponents/DetailsFlyout/styles';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { DonationIcon } from '@WebComponents/svgs/icons/DonationIcon';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { numberToCurrencyStringValue } from '../../../../../models/UiUtils';
import { useDonationFilterQuery, useDonorFilterQuery } from '../../../../../queries';
import { styleSheet as dbStyleSheet } from '../../../../containers/dataBoards/styles';
import { styleSheet } from './styles';

export const DonorDetailsFlyout = ({
	contactId,
	isOpen,
	setIsOpen,
}: {
	contactId: string;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const donorFilterQuery = useDonorFilterQuery({
		enabled: isOpen && !!contactId,
		filterRequest: {
			criteria: [
				{
					property: Api.DonorFilterProperty.ContactId,
					value: contactId,
				},
			],
		},
	});

	const donationFilterQuery = useDonationFilterQuery({
		enabled: isOpen && !!contactId,
		filterRequest: {
			criteria: [
				{
					property: Api.DonationFilterProperty.ContactId,
					value: contactId,
				},
			],
		},
	});

	const historyData = React.useMemo(
		() => donationFilterQuery.data?.pages.map(page => page.values).flat() ?? [],
		[donationFilterQuery.data?.pages]
	);
	const totalCount = React.useMemo(
		() => donationFilterQuery.data?.pages?.[0]?.totalCount ?? 0,
		[donationFilterQuery.data?.pages]
	);

	const donor = React.useMemo(() => {
		return donorFilterQuery.data?.pages.map(page => page.values).flat()[0] ?? null;
	}, [donorFilterQuery.data?.pages]);

	return (
		<DetailsFlyout isOpen={isOpen} setIsOpen={setIsOpen} title='Donor Details'>
			{donorFilterQuery.isLoading ? <LoadingSpinner type='large' /> : null}
			{!donorFilterQuery.isLoading ? (
				<div className={css(styleSheet.container)}>
					<section className={css(styleSheet.section)}>
						<h4 className={css(flyoutStyleSheet.subTitle)}>{donor.donorName}</h4>
						<small>{donor.contact?.primaryEmail?.value}</small>
					</section>

					<div className={css(styleSheet.section)}>
						<label className={css(styleSheet.label)}>Most Recent Donation</label>
						<DataGrid styles={[styleSheet.tableOverrides]}>
							<DataGridRows>
								<DataGridRow>
									<DataGridRowItem noTopBorder>
										<span className={css(dbStyleSheet.amountText)}>
											${numberToCurrencyStringValue(donor.mostRecentDonation.amount)}
										</span>
									</DataGridRowItem>
									<DataGridRowItem noTopBorder>
										{donor.mostRecentDonation.campaign ? (
											<Badge
												text={donor.mostRecentDonation.campaign.name}
												foregroundColor={donor.mostRecentDonation.campaign.foregroundColor}
											/>
										) : null}
									</DataGridRowItem>
									<DataGridRowItem noTopBorder>
										<span>{moment(donor.mostRecentDonation.date).format('MM/DD/YYYY')}</span>
									</DataGridRowItem>
								</DataGridRow>
							</DataGridRows>
						</DataGrid>
					</div>

					<div className={css(flyoutStyleSheet.separator)} />

					<section className={css(styleSheet.lifetimeSection)}>
						<DonationIcon />
						<div className={css(styleSheet.stackedText)}>
							<h6 className={css(styleSheet.sectionHeader)}>Life Time Donations</h6>
							<span className={css(dbStyleSheet.amountText)}>
								${numberToCurrencyStringValue(donor.lifetimeDonations)}
							</span>
						</div>
					</section>

					{donor.contact ? (
						<section className={css(styleSheet.section)}>
							<h6 className={css(styleSheet.sectionHeader)}>Donation History ({totalCount})</h6>

							{donationFilterQuery.isLoading ? <LoadingSpinner type='large' /> : null}

							{!donationFilterQuery.isLoading ? (
								<DataGrid styles={[styleSheet.tableOverrides]}>
									<DataGridRows>
										{historyData.map((datum, index) => (
											<DataGridRow key={`donation-${datum.id}`}>
												<DataGridRowItem noTopBorder={index === 0}>
													<span className={css(dbStyleSheet.amountText)}>
														${numberToCurrencyStringValue(datum.amount)}
													</span>
												</DataGridRowItem>
												<DataGridRowItem noTopBorder={index === 0}>
													{datum.campaign ? (
														<Badge text={datum.campaign.name} foregroundColor={datum.campaign.foregroundColor} />
													) : null}
												</DataGridRowItem>
												<DataGridRowItem noTopBorder={index === 0}>
													<span>{moment(datum.date).format('MM/DD/YYYY')}</span>
												</DataGridRowItem>
											</DataGridRow>
										))}
										{donationFilterQuery.hasNextPage ? (
											<DataGridRow>
												<DataGridRowItem fullColSpan>
													{donationFilterQuery.isLoading ? (
														<LoadingSpinner type='large' />
													) : (
														<Waypoint bottomOffset='-100px' onEnter={() => donationFilterQuery.fetchNextPage()} />
													)}
												</DataGridRowItem>
											</DataGridRow>
										) : null}
									</DataGridRows>
								</DataGrid>
							) : null}
						</section>
					) : null}
				</div>
			) : null}
		</DetailsFlyout>
	);
};
