import { IRichContentEditorState, IUser, UnsubscribeTemplateViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../../../models/UiUtils';
import { baseStyleSheet } from '../../../../styles/styles';
import { UnsubscribeSignature } from '../../../UnsubscribeSignature';
import { SettingsGroup } from '../../SettingsGroup';
import { SettingsGroupIcon } from '../../SettingsGroupIcon';
import { EmailSignatureComposer } from './presentation';
import { styleSheet } from './styles';

interface IProps
	extends IToasterComponentProps,
		IErrorMessageComponentProps,
		IUserSessionComponentProps,
		IEventLoggingComponentProps {
	className?: string;
	expanded?: boolean;
	forUser?: IUser;
}

interface IState {
	unsubscribeContentEditorState?: IRichContentEditorState;
}

class _EmailSignatureSettings extends React.Component<IProps, IState> {
	@observable.ref private mUnsubscribeTemplate: UnsubscribeTemplateViewModel;
	constructor(props: IProps) {
		super(props);

		this.mUnsubscribeTemplate = new UnsubscribeTemplateViewModel(props.userSession);
		this.state = {
			unsubscribeContentEditorState: convertRawRichTextContentStateToRichContentEditorState({
				document: '',
			}),
		};
	}

	public async componentDidMount() {
		const { logApiError, logEvent } = this.props;

		logEvent('LoadUnsubscribeTemplate');
		try {
			await this.mUnsubscribeTemplate.loadAccountTemplate();
			if (this.mUnsubscribeTemplate.accountTemplate) {
				await this.mUnsubscribeTemplate.loadUserTemplate();
				const content = this.mUnsubscribeTemplate.userTemplate
					? this.mUnsubscribeTemplate.userTemplate.content
					: this.mUnsubscribeTemplate.accountTemplate?.content;
				this.setState({
					unsubscribeContentEditorState: convertRawRichTextContentStateToRichContentEditorState(content),
				});
			}
		} catch (error) {
			logApiError('LoadUnsubscribeTemplate-Error', error);
		}
	}

	public render() {
		const { className, expanded, forUser } = this.props;
		const { unsubscribeContentEditorState } = this.state;

		const shouldRenderUnsubscribeSignature =
			(!!this.mUnsubscribeTemplate.accountTemplate || !!this.mUnsubscribeTemplate.userTemplate) && !forUser;

		return (
			<SettingsGroup
				className={`email-signature-settings ${className || ''}`}
				description='Manage your email signature.'
				expanded={expanded}
				icon={<SettingsGroupIcon type='EmailSignature' />}
				name='Email Signature'
				showHeader={!forUser}
			>
				<EmailSignatureComposer forUser={forUser} />
				{shouldRenderUnsubscribeSignature ? (
					<div>
						<UnsubscribeSignature
							contentEditorState={unsubscribeContentEditorState}
							onContentEditorStateChanged={this.onContentEditorStateChanged}
						/>
						<div className={css(styleSheet.buttonContainer)}>
							<button
								className={css(baseStyleSheet.ctaButton)}
								disabled={this.mUnsubscribeTemplate.isBusy}
								onClick={this.onSaveUnsubscribeSignatureClicked}
							>
								Save
							</button>
							<button
								className={css(baseStyleSheet.ctaButtonReverse)}
								disabled={this.mUnsubscribeTemplate.isBusy}
								onClick={this.onCancelUnsubscribeSignatureClicked}
							>
								Cancel
							</button>
						</div>
					</div>
				) : null}
			</SettingsGroup>
		);
	}

	private onContentEditorStateChanged = (unsubscribeContentEditorState: IRichContentEditorState) => {
		this.setState({ unsubscribeContentEditorState });
	};

	private onSaveUnsubscribeSignatureClicked = () => {
		const { logApiError, logEvent, toaster } = this.props;
		const { unsubscribeContentEditorState } = this.state;

		const content = unsubscribeContentEditorState.getRawRichTextContent();

		logEvent('SaveUnsubscribeTemplate');
		this.mUnsubscribeTemplate
			.saveTemplate(content)
			?.then(() => {
				toaster.push({
					message: 'Unsubscribe Signature saved',
					type: 'successMessage',
				});
			})
			?.catch(error => {
				logApiError('SaveUnsubscribeTemplate-Error', error);

				toaster.push({
					message: `Error saving Unsubscribe Signature`,
					type: 'errorMessage',
				});
			});
	};

	private onCancelUnsubscribeSignatureClicked = () => {
		const content = this.mUnsubscribeTemplate.userTemplate
			? this.mUnsubscribeTemplate.userTemplate.content
			: this.mUnsubscribeTemplate.accountTemplate.content;
		this.setState({
			unsubscribeContentEditorState: convertRawRichTextContentStateToRichContentEditorState(content),
		});
	};
}

const EmailSignatureSettingsAsObserver = observer(_EmailSignatureSettings);
const EmailSignatureSettingsWithContext = inject(
	ToasterViewModelKey,
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(EmailSignatureSettingsAsObserver);
export const EmailSignatureSettings = withEventLogging(EmailSignatureSettingsWithContext, 'EmailSignatureSettings');
