import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Waypoint from 'react-waypoint';
import { EventLogger } from '../../../models/Logging';
import { getDisplayName } from '../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	invalidateAccountLoad,
	invalidateAccountUserCount,
	invalidateInfiniteUsers,
	useAccountLoad,
	useAccountUserCount,
	useDeactivateUserMutation,
	useDeleteUserMutation,
	useInfiniteUsers,
	useReactivateUserMutation,
	useUpdateUserCardSettingsMutation,
	useUpdateUserEmailMutation,
	useUpdateUserRoleMutation,
} from '../../../queries';
import { ITemplate } from '../../../viewmodels/AppViewModels';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { DeactivateUserModal } from '../../components/DeactivateUserModal';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { FabContext } from '../../components/FabContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../components/Portal';
import { ScrollView } from '../../components/ScrollView';
import { ISelectBoxOption } from '../../components/SelectBox';
import { EditEmailSignatureModal } from '../../components/email/EditEmailSignature';
import { InviteEmployeesModal } from '../../components/settings/InviteEmployees';
import { INavigationItemProps } from '../MainContainer';
import {
	ManageUsersEditCardSettings,
	ManageUsersEditEmail,
	ManageUsersEditMeetingLinks,
	ManageUsersEditSignature,
	ManageUsersFormattedUser,
	ManageUsersListViewHeader,
} from './presentation';
import { styleSheet } from './styles';

interface IProps extends RouteComponentProps<any>, INavigationItemProps {}

interface IState {
	editingEmailSignature?: ITemplate;
	editingUserEmail?: Api.IUser;
	forUserCardSettings?: Api.IUser;
	forUserMeetingLinks?: Api.IUser;
	forUserSignature?: Api.IUser;
	selectedEmailSignature?: ITemplate;
	showInviteEmployees?: boolean;
	sortAscending?: boolean;
	sortColumn?: string;
	userIdToDeactivate?: string;
	userLoading?: Api.IUser;
	users?: Api.IUser[];
	userToAddSignatureFor?: Api.IUser;
}

const _ManageUsers = ({ routeContainerClassName }: IProps) => {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const [state, setState] = React.useState<IState>({
		editingEmailSignature: null,
		editingUserEmail: null,
		forUserCardSettings: null,
		forUserMeetingLinks: null,
		forUserSignature: null,
		selectedEmailSignature: {},
		showInviteEmployees: false,
		sortAscending: true,
		sortColumn: 'firstName',
		userIdToDeactivate: null,
		userLoading: null,
		userToAddSignatureFor: null,
	});

	const [isUserCardSettingsModalOpen, setIsUserCardSettingsModalOpen] = React.useState(false);
	const [isUserMeetingLinksModalOpen, setIsUserMeetingLinksModalOpen] = React.useState(false);
	const [isUserSignatureModalOpen, setIsUserSignatureModalOpen] = React.useState(false);

	const accountId = React.useMemo(() => userSession.user.accountId, [userSession.user.accountId]);

	const countData = useAccountUserCount({
		accountId,
		onError: () => displayErrorMessage('Could not get the number of users on this account'),
	});

	const accountData = useAccountLoad({
		accountId,
		onError: () => displayErrorMessage('Could not load the account information'),
	});

	const usersData = useInfiniteUsers({ pageSize: 100 });

	const userDeactivate = useDeactivateUserMutation({
		onError: (result: Api.IOperationResultNoValue) => {
			errorMessage(result.systemMessage, 'Could not deactivate this user');
		},

		onSuccess: () => invalidateUsers({ userIdToDeactivate: null }),
	});

	const userReactivate = useReactivateUserMutation({
		onError: (result: Api.IOperationResultNoValue) => {
			errorMessage(result.systemMessage, 'Could not reactivate this user');
		},
		onSuccess: () => invalidateUsers(),
	});

	const userDelete = useDeleteUserMutation({
		onError: (result: Api.IOperationResultNoValue) => {
			errorMessage(result.systemMessage, 'Could not delete this user');
		},
		onSuccess: () => invalidateUsers(),
	});

	const userRoleUpdate = useUpdateUserRoleMutation({
		onError: () => displayErrorMessage('Unable to edit the role for this user'),
		onSuccess: () => {
			invalidateInfiniteUsers();

			setState({ ...state, userLoading: null });
		},
	});

	const userSettings = useUpdateUserCardSettingsMutation({
		onError: () => {
			displayErrorMessage('Could not edit user settings');

			setState({ ...state, forUserCardSettings: null, userLoading: null });
		},

		onSuccess: () => invalidateUsers({ forUserCardSettings: null }),
	});

	const changeUserEmailMutation = useUpdateUserEmailMutation({
		onError: () => {
			displayErrorMessage('Could not edit users email');
			setState({ ...state, editingUserEmail: null });
		},
		onSuccess: () => {
			setState({ ...state, editingUserEmail: null });
			invalidateInfiniteUsers();
		},
	});

	const inviteClick = () => {
		setState({ ...state, showInviteEmployees: true });
	};

	const onInviteEmployeesClose = () => {
		invalidateUsers({ showInviteEmployees: false });
	};

	const onCloseDeactivateUser = (deactivate?: boolean) => {
		if (deactivate) {
			// deactivateUser(state.userIdToDeactivate);

			userDeactivate.mutate({ userId: state.userIdToDeactivate });
		}

		setState({ ...state, userIdToDeactivate: null, userLoading: null });
	};

	const onUserRoleChanged = (option: ISelectBoxOption<string>, user: Api.IUser) => {
		// Check to make sure the value has changed and that this is not a cancel operation
		if (!!option && !!option.value) {
			const isAdminSelection = option.value === 'admin';

			const userIsAdmin = user.groups.findIndex(x => x === 'admin') >= 0;
			const valueChanged = isAdminSelection !== userIsAdmin;

			if (valueChanged) {
				// Call a web service to update the user

				userRoleUpdate.mutate({ isAdminSelection, userId: user.id });
				setState({ ...state, userLoading: user });
			}
		}
	};

	const deleteClick = (user: Api.IUser) => {
		const userId = user.id;

		userDelete.mutate({ userId });
		setState({ ...state, userLoading: user });
	};

	const deactivateClick = (user: Api.IUser) => {
		setState({ ...state, userIdToDeactivate: user.id, userLoading: user });
	};

	const reactivateClick = (user: Api.IUser) => {
		const userId = user.id;

		userReactivate.mutate({ userId });
		setState({ ...state, userLoading: user });
	};

	const editUserCardSettingsOption = (user: Api.IUser) => {
		setState({ ...state, forUserCardSettings: user, userLoading: user });
		setIsUserCardSettingsModalOpen(true);
	};

	const editMeetingLinksOption = (user: Api.IUser) => {
		setState({ ...state, forUserMeetingLinks: user, userLoading: user });
		setIsUserMeetingLinksModalOpen(true);
	};

	const editUserSignatureOption = (user: Api.IUser) => {
		setState({ ...state, forUserSignature: user, userLoading: user });
		setIsUserSignatureModalOpen(true);
	};

	const handleUserCardSettingsModalRequestClose = () => {
		setState({ ...state, forUserCardSettings: null, userLoading: null });
		setIsUserCardSettingsModalOpen(false);
	};

	const handleUserMeetingLinksModalRequestClose = () => {
		setIsUserMeetingLinksModalOpen(false);

		setState({ ...state, forUserMeetingLinks: null, userLoading: null });
	};

	const handleUserSignatureModalRequestClose = () => {
		setState({ ...state, forUserSignature: null, userLoading: null });
		setIsUserSignatureModalOpen(false);
	};

	const handleEditUserEmail = (newEmail?: string, reason?: string, canceled = false) => {
		if (canceled) {
			setState({ ...state, editingUserEmail: null });
			return;
		}
		changeUserEmailMutation.mutate({
			newEmail,
			reason,
			forUserId: state.editingUserEmail.id,
		});
	};

	const handleEditCardSettingsModalRequestClose = (result?: Api.IUserPreferences, canceled?: boolean) => {
		if (canceled) {
			handleUserCardSettingsModalRequestClose();
			return;
		}

		userSettings.mutate({ forUser: state.forUserCardSettings.id, settings: result });
	};

	function displayErrorMessage(errorMsg: string) {
		if (errorMessages) {
			errorMessages.push({
				messages: [errorMsg],
			});
		}
	}

	function errorMessage(_: string, message: string) {
		displayErrorMessage(message);
	}

	const editUserEmailClick = (user: Api.IUser) => {
		setState({
			...state,
			editingUserEmail: user,
		});
	};

	const editEmailSignature = (emailSignature: ITemplate, forUser: Api.IUser) => {
		const nextState: IState = {
			editingEmailSignature: emailSignature || {},
		};
		setState({
			...state,
			editingEmailSignature: emailSignature || {},
			userLoading: forUser,
			userToAddSignatureFor: forUser,
		});

		const create = !nextState.editingEmailSignature.id;
		EventLogger.logInput('SignatureButton', `${create ? 'Create' : 'Edit'}Signature`, 'Click', {
			id: nextState.editingEmailSignature.id,
		});
	};

	const onEditEmailSignatureRequestClose = (editedEmailTemplate?: ITemplate, cancel?: boolean) => {
		const nextState: IState = {
			editingEmailSignature: null,

			userToAddSignatureFor: null,
		};

		if (!!editedEmailTemplate && !cancel) {
			onEmailSignatureSelected(null);

			invalidateUsers({ ...nextState, selectedEmailSignature: null });
			// update consumers
			return;
		}

		setState({ ...state, ...nextState, userLoading: null });
	};

	function onEmailSignatureSelected(emailSignature?: ITemplate) {
		const nextState: IState = {
			selectedEmailSignature: emailSignature,
		};

		setState({
			...state,
			...nextState,
		});

		EventLogger.logInput('SignatureButton', `Signature${emailSignature ? 'Selected' : 'Cleared'}`, 'Click', {
			id: emailSignature ? emailSignature.id : null,
		});
	}

	function invalidateUsers(nextState: IState = {}) {
		invalidateInfiniteUsers();
		invalidateAccountLoad();

		invalidateAccountUserCount(userSession.user.accountId);

		setState({ ...state, userLoading: null, ...nextState });
	}

	return (
		<div className='manageUsers-container'>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<NavigationBreadcrumbsBar currentLocationName='Manage Users' />
			</Portal>
			{usersData.isLoading || accountData.isLoading ? <LoadingSpinner type='large' /> : null}
			{usersData.status === 'success' ? (
				<ScrollView
					className={css(styleSheet.manageUsersContainerListView)}
					contentClassName={routeContainerClassName || undefined}
					hasFixedHeader={true}
					header={
						<ManageUsersListViewHeader
							loading={
								countData.isLoading ||
								accountData.isLoading ||
								usersData.isLoading ||
								userReactivate.isLoading ||
								userDeactivate.isLoading ||
								userDelete.isLoading ||
								userRoleUpdate.isLoading
							}
							consumedUsers={countData.data}
							totalLicenses={accountData?.data?.planDetails?.users}
							inviteClick={inviteClick}
						/>
					}
				>
					<div className={css(styleSheet.managedUsersEmployeeList)}>
						{usersData.data.pages.map(group => {
							if (group.totalCount === 0) {
								return (
									<p className={css(styleSheet.noneFound)} key='no-results'>
										No results
									</p>
								);
							}

							return group.values.map(user => {
								return (
									<ManageUsersFormattedUser
										key={user?.id}
										deactivateClick={() => deactivateClick(user)}
										deleteClick={() => deleteClick(user)}
										isInUpdatingList={state?.userLoading?.id === user?.id}
										onEditCardSettings={() => editUserCardSettingsOption(user)}
										onEditEmailSignature={() => editEmailSignature(null, user)}
										onEditMeetingLinks={() => editMeetingLinksOption(user)}
										onEditSignatureClick={() => editUserSignatureOption(user)}
										onEditUserEmailClick={() => editUserEmailClick(user)}
										onUserRoleChanged={(role: ISelectBoxOption<string>) => onUserRoleChanged(role, user)}
										reactivateClick={() => reactivateClick(user)}
										user={user}
										userSession={userSession}
									/>
								);
							});
						})}
						{usersData.hasNextPage && !usersData.isFetchingNextPage ? (
							<Waypoint bottomOffset='-200px' onEnter={() => usersData.fetchNextPage()} />
						) : null}
						{usersData.isFetchingNextPage || usersData.isLoading ? <LoadingSpinner type='large' /> : null}
					</div>
				</ScrollView>
			) : null}
			<InviteEmployeesModal
				account={accountData.data}
				modalProps={{
					isOpen: state.showInviteEmployees,
					onRequestClose: onInviteEmployeesClose,
				}}
				userSession={userSession}
			/>
			{Boolean(state.userIdToDeactivate) && (
				<DeactivateUserModal userId={state.userIdToDeactivate} onClose={onCloseDeactivateUser} />
			)}
			{state.userToAddSignatureFor ? (
				<EditEmailSignatureModal
					emailSignature={state.editingEmailSignature}
					forUser={state.userToAddSignatureFor}
					modalProps={{
						isOpen: Boolean(state.editingEmailSignature),
						onRequestClose: onEditEmailSignatureRequestClose,
					}}
				/>
			) : null}
			<ManageUsersEditCardSettings
				onRequestClose={handleEditCardSettingsModalRequestClose}
				forUser={state.forUserCardSettings}
				modalProps={{
					isOpen: isUserCardSettingsModalOpen,
					onRequestClose: () => handleEditCardSettingsModalRequestClose(undefined, true),
				}}
			/>
			<ManageUsersEditSignature
				forUser={state.forUserSignature}
				modalProps={{
					headerAccessory: (
						<div className={css(styleSheet.manageUsersModalHeader)}>
							<div className={css(styleSheet.manageUsersModalTitle)}>
								Manage {getDisplayName(state.forUserSignature)}&lsquo;s Signature
							</div>
							<DeprecatedCloseButton onClick={() => handleUserSignatureModalRequestClose()} />
						</div>
					),
					isOpen: isUserSignatureModalOpen,
					onRequestClose: handleUserSignatureModalRequestClose,
				}}
			/>

			<ManageUsersEditEmail
				onRequestClose={handleEditUserEmail}
				modalProps={{
					headerAccessory: (
						<header className={css(styleSheet.header)}>
							<h2 className={css(styleSheet.title)}>Change User Email</h2>
							<DeprecatedCloseButton onClick={() => handleEditUserEmail(null, null, true)} />
						</header>
					),
					isOpen: !!state.editingUserEmail,
					onRequestClose: () => handleEditUserEmail(null, null, true),
				}}
			/>

			{isUserMeetingLinksModalOpen ? (
				<Modal
					isOpen={true}
					headerAccessory={
						<div className={css(styleSheet.manageUsersModalHeader)}>
							<div className={css(styleSheet.manageUsersModalTitle)}>
								Manage {getDisplayName(state.forUserMeetingLinks)}&lsquo;s Meeting Links
							</div>
							<DeprecatedCloseButton onClick={() => handleUserMeetingLinksModalRequestClose()} />
						</div>
					}
					onRequestClose={handleUserMeetingLinksModalRequestClose}
				>
					<ManageUsersEditMeetingLinks
						forUser={state.forUserMeetingLinks}
						onEditMeetingClicked={handleUserMeetingLinksModalRequestClose}
					/>
				</Modal>
			) : null}
			<FabContext appearance={{ hidden: true }} />
		</div>
	);
};

const ObservableManageUsers = observer(_ManageUsers);
export const ManageUsers = withRouter(ObservableManageUsers);
