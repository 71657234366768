import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { InsurancePolicyFilterProperty } from '../../../../../extViewmodels';
import { bs } from '../../../../styles/styles';
import { Button } from '../../../Button';
import { CustomDateRange } from '../../../CustomDateRange';
import { Dropdown } from '../../../Dropdown';
import { RadioButton } from '../../../RadioButton';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet as dropdownStyles } from '../../DropdownComponent/styles';
import { DateRangeOption, IDayOption } from '../../models';
import { dayOptions } from '../../utils';
import { styleSheet } from './styles';

export const RenewalDateFilterDropdown = ({
	selectedPolicyType,
	setSelectedPolicyType,
	selectedMinimumDate,
	setSelectedMinimumDate,
	selectedMaximumDate,
	setSelectedMaximumDate,
}: {
	selectedPolicyType: InsurancePolicyFilterProperty;
	setSelectedPolicyType: React.Dispatch<React.SetStateAction<InsurancePolicyFilterProperty>>;
	selectedMinimumDate: string;
	setSelectedMinimumDate: React.Dispatch<React.SetStateAction<string>>;
	selectedMaximumDate: string;
	setSelectedMaximumDate: React.Dispatch<React.SetStateAction<string>>;
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [selectedDayOption, setSelectedDayOption] = React.useState<IDayOption>(null);

	const isCustomSelected = React.useMemo(() => {
		return selectedDayOption?.value === DateRangeOption.Custom;
	}, [selectedDayOption?.value]);

	React.useEffect(() => {
		if (selectedMinimumDate && selectedMaximumDate) {
			const customDayOption = dayOptions.find(option => option.value === DateRangeOption.Custom);
			setSelectedDayOption(customDayOption);
			return;
		}
		const match = dayOptions.find((option: IDayOption) => {
			return option.value === selectedMinimumDate;
		});
		setSelectedDayOption(match);
	}, [selectedMaximumDate, selectedMinimumDate]);

	const getDateRangeMessage = () => {
		if (!selectedMinimumDate || !selectedDayOption) {
			return 'Date Range';
		}
		let title = 'Effective Date';
		if (selectedPolicyType === InsurancePolicyFilterProperty.RenewalDate) {
			title = 'Renewal Date';
		} else if (selectedPolicyType === InsurancePolicyFilterProperty.OriginationDate) {
			title = 'Origination Date';
		}

		if (selectedDayOption.value === DateRangeOption.Custom && selectedMinimumDate && selectedMaximumDate) {
			const selectedMinimumDateString = moment(selectedMinimumDate).format('MM/DD');
			const selectedMaximumDateString = moment(selectedMaximumDate).format('MM/DD');
			return `${title} ${selectedMinimumDateString} - ${selectedMaximumDateString}`;
		}
		const key = selectedDayOption.key;
		return `${title}/ ${DateRangeOption[key]}`;
	};

	const dropdownMessage = getDateRangeMessage();

	const filteredDayOptions = dayOptions.filter(option => {
		if (option.value === DateRangeOption.Custom && selectedDayOption?.value === DateRangeOption.Custom) {
			return false;
		}
		return true;
	});

	const selectDateRangeOption = (newDayOption: IDayOption, ev: React.MouseEvent<HTMLButtonElement>) => {
		if (newDayOption.value === DateRangeOption.Custom) {
			setSelectedDayOption(newDayOption);
			ev.stopPropagation();
			ev.preventDefault();
		} else {
			setSelectedMinimumDate(newDayOption.value);
			setIsOpen(false);
		}
	};

	const resetFilter = () => {
		setSelectedMinimumDate(null);
		setSelectedMaximumDate(null);
		setSelectedDayOption(null);
	};

	return (
		<Dropdown
			anchor={
				<div className={css(dropdownStyles.dropdownAnchor)}>
					<div className={css(bs.truncateText)}>{dropdownMessage}</div>
					<DisclosureIcon className={css(dropdownStyles.dropdownIcon)} />
				</div>
			}
			disabled={false}
			className={css(dropdownStyles.dropdownInline, isOpen ? dropdownStyles.dropdownInlineOpen : null)}
			contentClassName={css(dropdownStyles.dropdownMenu, styleSheet.dropdownMenuOverrides)}
			contentPositionY='bottom'
			contentInnerClassName={css(styleSheet.dropdownContentInnerOverrides)}
			isOpen={isOpen}
			onOpenChanged={() => setIsOpen(!isOpen)}
			openOnClick={true}
		>
			<div className={css(dropdownStyles.dropdown)}>
				<div className={css(styleSheet.policyTypeRadioButtons)}>
					<RadioButton
						checked={selectedPolicyType === InsurancePolicyFilterProperty.OriginationDate}
						id={InsurancePolicyFilterProperty.OriginationDate}
						onChange={() => setSelectedPolicyType(InsurancePolicyFilterProperty.OriginationDate)}
					>
						<span>Origination Date</span>
					</RadioButton>
					<RadioButton
						checked={selectedPolicyType === InsurancePolicyFilterProperty.EffectiveDate}
						id={InsurancePolicyFilterProperty.EffectiveDate}
						onChange={() => setSelectedPolicyType(InsurancePolicyFilterProperty.EffectiveDate)}
					>
						<span>Effective Date</span>
					</RadioButton>
					<RadioButton
						checked={selectedPolicyType === InsurancePolicyFilterProperty.RenewalDate}
						id={InsurancePolicyFilterProperty.RenewalDate}
						onChange={() => setSelectedPolicyType(InsurancePolicyFilterProperty.RenewalDate)}
					>
						<span>Renewal Date</span>
					</RadioButton>
				</div>
				<div className={css(dropdownStyles.dropdownSeparator)} />

				<div>
					{isCustomSelected ? (
						<CustomDateRange
							endDateRequired
							defaultOpen={!selectedMaximumDate}
							from={selectedMinimumDate ? moment(selectedMinimumDate).toDate() : null}
							to={selectedMaximumDate ? moment(selectedMaximumDate).toDate() : null}
							onChange={(from, to) => {
								if (to === from) {
									return;
								}
								setSelectedMinimumDate(moment(from).format('YYYY-MM-DD'));
								setSelectedMaximumDate(moment(to).format('YYYY-MM-DD'));
							}}
							showTextInput
							disableScrollTo
						/>
					) : null}
				</div>

				<div>
					{!isCustomSelected &&
						filteredDayOptions.map(dayOption => {
							return (
								<Button
									label={dayOption.label}
									key={`donation-date-range-option-${dayOption.key}`}
									onClick={(e: React.MouseEvent<HTMLButtonElement>) => selectDateRangeOption(dayOption, e)}
									kind='custom'
									className={css(
										dropdownStyles.option,
										selectedDayOption?.key === dayOption.key ? dropdownStyles.selected : null
									)}
								/>
							);
						})}
				</div>

				<Button label='Reset Filter' kind='custom' className={css(bs.brandLink)} type='button' onClick={resetFilter} />
			</div>
		</Dropdown>
	);
};
