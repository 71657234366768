import { css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ICreateCampaignRequest, ILocationState, TemplateOrTemplateFilter } from '../../../../models';

import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { withEventLogging } from '../../../../models/Logging';
import { HiddenDefaultMessageTemplateKey, hideDefaultMessageTemplate } from '../../../../models/MessageTemplates';
import {
	EmailCampaignBrowserViewModel,
	ITemplateCard,
	TemplateScope,
	TemplatesViewModel,
} from '../../../../viewmodels/AppViewModels';
import { IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { DarkMainContainerBackground } from '../../../components/MainContainerBackground';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { CampaignTemplateCard } from '../../../components/templateCards/CampaignTemplateCard';
import { TemplateSharingConfirmation } from '../../../components/templates/TemplateSharingConfirmation';
import { INavigationItemProps } from '../../../containers/MainContainer';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps
	extends IUserSessionComponentProps,
		IFullscreenModalComponentProps,
		RouteComponentProps,
		IErrorMessageComponentProps,
		IToasterComponentProps,
		INavigationItemProps {}

interface IState {
	shareTemplate?: boolean;
	showDeleteConfirmation?: boolean;
	showShareConfirmation?: boolean;
	templateToBeManaged?: ITemplateCard;
}
class _CoffeeMyTemplates extends Component<IProps, IState> {
	@observable.ref private Browser: EmailCampaignBrowserViewModel;

	constructor(props: IProps) {
		super(props);

		this.Browser = new EmailCampaignBrowserViewModel(this.props.userSession);
		this.state = {
			shareTemplate: false,
			showDeleteConfirmation: false,
			showShareConfirmation: false,

			templateToBeManaged: null,
		};
	}

	public componentDidMount() {
		this.loadSections();
	}

	public render() {
		const { routeContainerClassName = '' } = this.props;
		return (
			<div className={`${routeContainerClassName} ${css(styleSheet.cardGroupContainer)}`}>
				<MultiContainerHeader appBarHeader='Templates' />
				<DarkMainContainerBackground />
				{this.onRenderButtons()}
				{this.onRenderMyTemplate()}
			</div>
		);
	}

	private async loadSections() {
		const { userSession } = this.props;

		await this.Browser.loadMe(userSession.account?.additionalInfo?.industry);
	}

	private onRenderButtons() {
		return (
			<div className={css(styleSheet.button)}>
				<button className={`${css(baseStyleSheet.ctaButton)} create`} onClick={this.onCreate}>
					<span>Create New Template</span>
				</button>
			</div>
		);
	}
	private rowizeCards(cards: ITemplateCard[]): ITemplateCard[][] {
		if (!cards?.length) {
			return null;
		}
		const hideDefaultMessages = localStorage.getItem(HiddenDefaultMessageTemplateKey);
		const copy = JSON.parse(JSON.stringify(cards.filter(x => !hideDefaultMessages?.includes(x.id))));
		const rows: ITemplateCard[][] = new Array(Math.ceil(copy.length / 3)).fill(undefined)?.map(() => copy.splice(0, 3));
		return rows;
	}
	private onCreate = () => {
		this.props.fullscreenModal.history.push('/email/campaigns/create/new-campaign');
	};

	/** Only admins and owners of a template should be able to Delete templates. everyone else can only Hide them. */
	private canDelete = (cardOwnerId: string) => {
		const { userSession } = this.props;

		return cardOwnerId === userSession.user?.id || userSession.user?.role?.toLocaleLowerCase().includes('admin');
	};

	private onDeleteClick = (templateCard: ITemplateCard) => () => {
		this.setState({
			showDeleteConfirmation: true,
			templateToBeManaged: templateCard,
		});
	};

	private onShareClick = (templateCard: ITemplateCard) => (isShared: boolean) => {
		this.setState({
			shareTemplate: isShared,
			showShareConfirmation: true,
			templateToBeManaged: templateCard,
		});
	};

	private onDeleteTemplate = async (shouldDelete: boolean) => {
		const { templateToBeManaged } = this.state;
		this.setState({ showDeleteConfirmation: false });

		if (shouldDelete) {
			const { errorMessages, toaster } = this.props;
			try {
				if (this.canDelete(templateToBeManaged?.creator?.id)) {
					await this.Browser.deleteTemplate(templateToBeManaged);
				} else {
					hideDefaultMessageTemplate(templateToBeManaged.id);
				}

				toaster.push({
					message: 'Template deleted successfully',
					type: 'successMessage',
				});
				await this.loadSections();
			} catch (error) {
				// const { logApiError } = this.props;

				errorMessages.pushApiError(error);
				// logApiError('TemplateShare-Error', error);

				toaster.push({
					message: 'Error deleting template',
					type: 'errorMessage',
				});
			}
		}
	};

	private onShare = async (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		const { templateToBeManaged, shareTemplate } = this.state;
		this.setState({
			shareTemplate: null,
			showShareConfirmation: false,

			templateToBeManaged: null,
		});

		if (result?.representedObject && !canceled) {
			const { errorMessages, toaster } = this.props;
			try {
				await this.Browser.shareTemplate(
					templateToBeManaged,
					shareTemplate ? TemplateScope.Account : TemplateScope.User
				);
				await this.loadSections();

				toaster.push({
					message: `Template ${shareTemplate ? 'shared' : 'unshared'} successfully`,
					type: 'successMessage',
				});
			} catch (error) {
				// const { logApiError } = this.props;

				errorMessages.pushApiError(error);
				// logApiError('TemplateShare-Error', error);

				toaster.push({
					message: `Error ${shareTemplate ? 'sharing' : 'unsharing'} template`,
					type: 'errorMessage',
				});
			}
		}
	};

	private onViewCampaign = (templateId: string) => async () => {
		const { fullscreenModal, userSession } = this.props;

		try {
			const templates = new TemplatesViewModel(userSession);
			const template = await templates.getById(templateId);

			const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: '/email/campaigns/create/from-template',
				state: locationState,
			});
		} catch {
			// errorMessages.pushApiError(error);
			// logApiError('LoadCategories-Error', error);
		}
	};

	private onRenderMyTemplate() {
		const { showDeleteConfirmation, showShareConfirmation, templateToBeManaged } = this.state;
		return (
			<div>
				<div className={css(styleSheet.categoryTitle)}>My Templates</div>
				{!!this.Browser.isBusy && <LoadingSpinner type='large' />}
				{this.rowizeCards(this.Browser.categorySection?.toArray())?.map(r => {
					const key = r.map(x => x.id).join('-');
					return (
						<div className={css(styleSheet.cardSectionRow)} key={key}>
							{r.map(c => (
								<CampaignTemplateCard
									card={c}
									isAccountTemplate={true}
									key={c.id}
									onCtaClicked={this.onViewCampaign(c.id)}
									onDelete={this.onDeleteClick(c)}
									onShare={this.onShareClick(c)}
									styles={[styleSheet.templateCard]}
								/>
							))}
						</div>
					);
				})}
				<DeleteConfirmation
					bodyText='Are you sure you want to delete this template?'
					destructiveButtonText='Yes, Delete'
					isOpen={showDeleteConfirmation}
					onFinish={this.onDeleteTemplate}
				/>
				<TemplateSharingConfirmation
					onRequestClose={this.onShare}
					show={showShareConfirmation}
					template={templateToBeManaged}
				/>
			</div>
		);
	}
}
const CoffeeMyTemplatesAsObserver = observer(_CoffeeMyTemplates);
const CoffeeMyTemplatesWithContext = inject(
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey,
	FullScreenModalViewModelKey,
	ToasterViewModelKey
)(CoffeeMyTemplatesAsObserver);
export const CoffeeMyTemplates = withRouter(withEventLogging(CoffeeMyTemplatesWithContext, 'CoffeeMyTemplates'));
