import { borderColor, brandPrimaryHoverTint, brandPrimaryText, navigation, success, white } from '@Styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		background: white,
	},

	filtersContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '1rem',
		borderBottom: '1px solid var(--border-color)',
	},

	actionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: '1px solid var(--border-color)',
	},

	bodyContainer: {
		flex: 1,
		overflow: 'auto',
		position: 'relative',
	},

	summaryContainer: {
		padding: '1rem',
		borderTop: '1px solid var(--border-color)',
	},

	noResults: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2rem',
		textAlign: 'center',
		gap: '1rem',
	},

	statsContainer: {
		position: 'sticky',
		bottom: 0,
		backgroundColor: brandPrimaryHoverTint,
		display: 'flex',
		gap: 36,
		justifyContent: 'space-between',
		padding: 16,
		border: `1px solid ${borderColor}`,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		boxSizing: 'border-box',
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
	},

	statHeader: {
		fontSize: '1.1rem',
		fontWeight: 600,
	},

	stat: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	filterGroup: {
		display: 'flex',
		alignItems: 'center',
		gap: '0.5rem',
	},
	breadcrumbsBar: {
		display: 'flex',
		alignItems: 'center',
		gap: 24,
		paddingRight: 16,
	},

	statLabel: {
		color: navigation,
		fontSize: 12,
		fontWeight: 600,
		margin: 0,
		textTransform: 'uppercase',
	},
	statValue: {
		color: brandPrimaryText,
		fontWeight: 600,
		fontSize: 18,
	},
	totalValue: {
		color: success,
		fontWeight: 600,
		fontSize: 18,
	},
	statTitle: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 600,
	},
});
