import { css } from 'aphrodite';
import { useHistory } from 'react-router';
import { ITemplate } from '../../../../extViewmodels';
import { useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import { useUpdateCount } from '../../../containers/hooks';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { GhostHeading } from '../../GhostHeading';
import { GhostImage } from '../../GhostImage';
import { GhostCard } from '../../GhostsCard';
import { ArrowIcon } from '../../svgs/icons/ArrowIcon';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { CircularProgress } from '../CircularProgress';
import { DashboardCard, DashboardCardStat, DashboardCardTag } from '../DashboardCard';
import { DashboardCarousel } from '../DashboardCarousel';
import { commCardGreenColor, styleSheet } from './styles';

export function OpenReplyStats({ openRate, replyRate }: { openRate: number; replyRate?: number }) {
	const OPEN_RATE = useUpdateCount(openRate);
	const REPLY_RATE = useUpdateCount(replyRate);
	const history = useHistory();
	return (
		<>
			<div className={css(styleSheet.cardBodyColumnInner)}>
				<div className={css(styleSheet.statsContainer)}>
					<DashboardCardTag styleDeclaration={styleSheet.tag}>Overall Stats</DashboardCardTag>
					<p>
						{openRate > 18 ? (
							<>
								Awesome! The industry avg. for open rates is only{' '}
								<span className={css(styleSheet.textEngagementCount)}>18%</span>{' '}
							</>
						) : (
							<>
								Boost your rate even more! Check out our{' '}
								<button
									type='button'
									className={css(styleSheet.campaignLibraryBtn, styleSheet.linkButton)}
									onClick={() => history.push(`/campaigns`)}
								>
									Campaign Library{' '}
									<ArrowIcon className={css(styleSheet.campaignLibraryBtnArrow)} fillColor={brandPrimary} />
								</button>
							</>
						)}
					</p>
				</div>
			</div>
			<div className={css(styleSheet.cardBodyColumnInner)}>
				<div className={css(styleSheet.textProgressContainer)}>
					{(OPEN_RATE ?? 0) > 0 ? (
						<div className={css(styleSheet.textProgressRow)}>
							<CircularProgress width={100} progress={OPEN_RATE} />
							<span className={css(styleSheet.textProgressRowLabel)}>Average open rate</span>
						</div>
					) : null}
					{(REPLY_RATE ?? 0) > 0 ? (
						<div className={css(styleSheet.textProgressRow)}>
							<CircularProgress width={100} progress={REPLY_RATE} color='green' />
							<span className={css(styleSheet.textProgressRowLabel)}>Average reply rate</span>
						</div>
					) : null}
				</div>
			</div>
		</>
	);
}

export function CommStatHeader({
	totalEmailsSent,
	totalTextsSent,
}: {
	totalEmailsSent: number;
	totalTextsSent: number;
}) {
	const history = useHistory();
	return (
		<>
			<button className={css(styleSheet.emailIconButton)} onClick={() => history.push('reporting/group-email')}>
				<EmailIcon fillColor='white' width={24} height={24} />
			</button>
			<div className={css(styleSheet.messageSentTexts)}>
				{totalEmailsSent > 0 ? (
					<section className={css(styleSheet.messageSentHGroup)}>
						<h3 className={css(styleSheet.messageSentHeaderLabel)}>Total Emails Sent</h3>
						<DashboardCardStat styleDeclaration={styleSheet.messageSentHeaderNumber}>
							{totalEmailsSent.toLocaleString()}
						</DashboardCardStat>
					</section>
				) : null}
				{totalTextsSent > 0 ? (
					<section className={css(styleSheet.messageSentHGroup)}>
						<h3 className={css(styleSheet.messageSentHeaderLabel)}>Total Texts Sent</h3>
						<DashboardCardStat styleDeclaration={styleSheet.messageSentHeaderNumber}>
							{totalTextsSent.toLocaleString()}
						</DashboardCardStat>
					</section>
				) : null}
			</div>
			{totalEmailsSent === 0 && totalTextsSent === 0 ? (
				<h3 className={css(styleSheet.messageSentHeaderLabel)}>Email Campaigns</h3>
			) : null}
		</>
	);
}

export function CommFeaturedTemplates({ data }: { data: ITemplate[] }) {
	const fullscreenModal = useFullscreenModal();
	const handleSendFirstCampaignClick = (template: ITemplate) => {
		fullscreenModal.history.push({
			pathname: '/email/campaigns/create/from-template',
			state: {
				model: {
					context: { ...template },
					type: 'Template',
				},
			},
		});
	};
	if (data.length === 0) {
		return null;
	}

	return (
		<div className={css(styleSheet.cardColumn, styleSheet.cardColumnInset)}>
			<div className={css(styleSheet.messageSentHeader)}>
				<DashboardCardTag styleDeclaration={styleSheet.tag}>Suggested First Campaigns</DashboardCardTag>
			</div>
			<DashboardCarousel
				sliderLeftProps={{ fillColor: commCardGreenColor }}
				sliderRightProps={{ fillColor: commCardGreenColor }}
				buttonColumnStyleDeclaration={styleSheet.carouselBtnColumn}
			>
				{data.map((featuredTemplate, i) => (
					<div className={css(styleSheet.firstCampaignCard)} key={i}>
						<h3 className={css(styleSheet.firstCampaignTitle)}>{featuredTemplate.name}</h3>
						<p className={css(styleSheet.firstCampaignContent)}>{featuredTemplate.summary}</p>
						<button
							className={css(baseStyleSheet.ctaButtonReverse)}
							onClick={() => handleSendFirstCampaignClick(featuredTemplate)}
						>
							Send First Campaign
						</button>
					</div>
				))}
			</DashboardCarousel>
		</div>
	);
}

export function EmptyCommStat({
	featuredTemplates,
	totalEmailsSent,
	totalTextsSent,
}: {
	featuredTemplates: ITemplate[];
	totalEmailsSent: number;
	totalTextsSent: number;
}) {
	return (
		<DashboardCard styleDeclaration={styleSheet.root}>
			<div className={css(styleSheet.cardColumn)}>
				<div className={css(styleSheet.messageSentHeader, styleSheet.messageSentHeaderSeparator)}>
					<CommStatHeader totalEmailsSent={totalEmailsSent} totalTextsSent={totalTextsSent} />
				</div>
				<div className={css(styleSheet.cardBodyColumn)}>
					<div className={css(styleSheet.cardBodyColumnInner)}>
						<div className={css(styleSheet.emailOpenRateProgressContainer)}>
							<CircularProgress width={150} progress={21} label='Open rate' color='purple' />
							<p>The average open rate for marketing email sent with traditional tools</p>
						</div>
					</div>
					<div className={css(styleSheet.cardBodyColumnInner)}>
						<div className={css(styleSheet.emailOpenRateProgressContainer)}>
							<CircularProgress width={150} progress={66.5} label='Open rate' />
							<p>Levitate&apos;s average open rate</p>
						</div>
					</div>
				</div>
			</div>
			<CommFeaturedTemplates data={featuredTemplates} />
		</DashboardCard>
	);
}

export const CommGhostCard = () => {
	return (
		<GhostCard style={{ padding: '1rem 1.25rem', width: '100%' }}>
			<div style={{ alignItems: 'flex-start', display: 'flex', marginBottom: 14 }}>
				<GhostImage style={{ marginRight: '1rem' }} />{' '}
				<div style={{ alignItems: 'center', marginRight: 30 }}>
					<GhostHeading style={{ height: 7, marginBottom: 10, marginTop: 5, width: 99 }} />
					<GhostImage style={{ height: 23, width: 90 }} />
				</div>
				<div>
					<GhostHeading style={{ height: 7, marginBottom: 10, marginTop: 5, width: 80 }} />
					<GhostImage style={{ height: 23, width: 50 }} />
				</div>
				<div style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column', marginLeft: 'auto' }}>
					<GhostHeading style={{ height: 7, marginBottom: 10, marginTop: 5, width: 80 }} />
					<div style={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}>
						<GhostImage style={{ height: 23, marginRight: 5, width: 50 }} />
						<GhostHeading style={{ height: 10, width: 25 }} />
					</div>
				</div>
			</div>
			<div style={{ alignItems: 'flex-start', marginBottom: 14 }}>
				<hr />
			</div>
			<div style={{ alignItems: 'flex-start', display: 'flex', marginBottom: 14 }}>
				<div style={{ flex: '1 1 30%', marginRight: '2%' }}>
					<GhostHeading style={{ height: 15, marginBottom: 20, marginTop: 5, width: '31%' }} />
					<GhostHeading style={{ height: 10, marginBottom: 10, width: '95%' }} />
					<GhostHeading style={{ height: 10, marginBottom: 10, width: '60%' }} />
				</div>
				<div style={{ alignItems: 'center', display: 'flex', flex: '1 1 30%', margin: '1.5rem 2% 0 0' }}>
					<GhostImage
						style={{ borderRadius: '50%', height: 60, marginRight: '1rem', width: 60 }}
						stylesDeclarations={[styleSheet.circle]}
					/>
					<div>
						<GhostHeading style={{ height: 10, marginBottom: 10, width: 102 }} />
					</div>
				</div>
				<div style={{ display: 'flex', flex: '1 1 30%', flexDirection: 'column', paddingRight: '1rem' }}>
					<GhostHeading style={{ height: 15, marginBottom: 20, marginTop: 5, width: 91 }} />
					<div style={{ border: '1px solid #ccc', padding: '1rem', width: '93%' }}>
						<GhostHeading style={{ height: 14, marginBottom: 20, width: 100 }} />
						<GhostHeading style={{ height: 14, marginBottom: 20, width: 100 }} />
						<GhostImage style={{ height: 41, marginRight: 5, width: '100%' }} />
					</div>
				</div>
			</div>
		</GhostCard>
	);
};
