import { css } from 'aphrodite';
import * as React from 'react';
import { useHistory } from 'react-router';
import registrationSuccess from '../../assets/registrationSuccess.png';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { Portal } from '../../components/Portal';
import { TextArea } from '../../components/TextArea';
import { DisclosureIcon } from '../../components/svgs/icons/DisclosureIcon';
import { brandSecondary } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

export const TextingRegistrationLayout = ({ children }: { children: React.ReactNode }) => {
	return (
		<div className={css(styleSheet.container)}>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<h1 className={css(baseStyleSheet.breadcrumbTitle)}>Texting Campaign Registration</h1>
			</Portal>
			{children}
		</div>
	);
};

export const TextingRegistrationFormLayout = ({
	children,
	showBackButton = false,
}: {
	children: React.ReactNode;
	showBackButton?: boolean;
}) => {
	const history = useHistory();
	return (
		<TextingRegistrationLayout>
			<div className={css(styleSheet.outerContainer)}>
				{showBackButton ? (
					<div style={{ width: '100%', marginBottom: 8 }}>
						<button className={css(styleSheet.back)} onClick={() => history.goBack()}>
							<DisclosureIcon className={css(styleSheet.backIcon)} fillColor={brandSecondary} type='chevron' />
							Back
						</button>
					</div>
				) : null}
				<div className={css(styleSheet.innerContainer)}>
					<figure className={css(styleSheet.headerImage)}>
						<img src={registrationSuccess} alt='Texting Campaign Registration' />
					</figure>
					{children}
				</div>
			</div>
		</TextingRegistrationLayout>
	);
};

export function TextingOptInFlowContent({
	disabled,
	inputId,
	onChange,
	value,
}: {
	disabled?: boolean;
	inputId?: string;
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
	value?: string;
}) {
	return (
		<div className={css(styleSheet.optInContainer)}>
			<TextArea
				className={css(styleSheet.textAreaInput)}
				disabled={disabled}
				inputId={inputId}
				onChange={onChange}
				value={value}
			/>

			<span className={css(styleSheet.textAreaLength)}>{value.length > 0 ? `Length: ${value.length}` : null}</span>
		</div>
	);
}

export function TextingOptInFlowFile({
	disabled,
	inputId,
	onChange,
	fileName,
}: {
	disabled?: boolean;
	inputId?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	fileName?: string;
}) {
	return (
		<label htmlFor={inputId} className={css(styleSheet.labelFileInput)}>
			<p className={css(styleSheet.label)}>
				You may also upload an image or pdf of the method for which the consent is gathered.
			</p>
			<div className={css(styleSheet.labelInnerDiv)}>
				<p className={css(styleSheet.uploadImageLink)}>{fileName ? '↻ Update file' : '+ Upload a file'}</p>
				{fileName ? <p className={css(styleSheet.imagePlaceholder, baseStyleSheet.truncateText)}>{fileName}</p> : null}
			</div>
			<input
				accept='image/*, .pdf'
				className={css(styleSheet.input)}
				disabled={disabled}
				id={inputId}
				onChange={onChange}
				type='file'
			/>
		</label>
	);
}
