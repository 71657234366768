import * as Api from '@ViewModels';
import * as React from 'react';
import { ContactSortKey } from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContactApproximateQuery, useContactFilterQuery, useContactsQuery } from '../../../../queries';

type Actions =
	| { type: 'setFilterList'; value: Api.IContactFilterCriteria[] }
	| { type: 'setShowingAdvancedTagFiltersModal'; value: boolean }
	| { type: 'removeRecipient'; value: Api.IContact }
	| { type: 'addRecipient'; value: Api.IContact }
	| { type: 'addRecipients'; value: Api.IContact[] }
	| { type: 'toggleConsentChecked' };

export function hasFilters(filterList: Api.IContactFilterCriteria[]): boolean {
	for (const filter of filterList) {
		if (filter.criteria?.length) {
			if (hasFilters(filter.criteria)) {
				return true;
			}
		} else if (filter.property !== Api.ContactFilterCriteriaProperty.All) {
			return true;
		}
	}
	return false;
}

interface ITextingCampaignPageState {
	filterCriteriaList: Api.IContactFilterCriteria[];
	contactIdsToOmit: Set<string>;
	contactIdsToAdd: string[];
	showingAdvancedTagFiltersModal: boolean;
	consentChecked: boolean;
}

const reducer = (state: ITextingCampaignPageState, action: Actions): ITextingCampaignPageState => {
	switch (action.type) {
		case 'setFilterList': {
			return {
				...state,
				filterCriteriaList: action.value,
			};
		}
		case 'setShowingAdvancedTagFiltersModal': {
			return {
				...state,
				showingAdvancedTagFiltersModal: action.value,
			};
		}
		// Controlling logic for removing contact from the recipient list
		case 'removeRecipient': {
			const contact = action.value;
			if (!contact.id) {
				return state; // or throw?
			}
			const contactIdsToAdd = state.contactIdsToAdd.filter(x => x !== contact.id);
			const contactIdsToOmit = new Set(state.contactIdsToOmit);
			contactIdsToOmit.add(contact.id);
			return {
				...state,
				contactIdsToAdd,
				contactIdsToOmit,
			};
		}
		// Controlling logic for adding contact to the recipient list
		case 'addRecipient': {
			const contact = action.value;
			if (!contact.id || state.contactIdsToAdd.includes(contact.id)) {
				return state; // or throw?
			}
			const contactIdsToOmit = new Set(state.contactIdsToOmit);
			contactIdsToOmit.delete(contact.id);
			return {
				...state,
				contactIdsToOmit,
				contactIdsToAdd: [...state.contactIdsToAdd, contact.id],
			};
		}
		case 'addRecipients': {
			const contacts = action.value;
			const contactIdsToOmit = new Set(state.contactIdsToOmit);
			const contactIdsToAdd: string[] = [];
			for (const contact of contacts) {
				if (state.contactIdsToAdd.includes(contact.id!)) {
					continue;
				}
				contactIdsToAdd.push(contact.id!);
				contactIdsToOmit.delete(contact.id!);
			}
			return {
				...state,
				contactIdsToOmit,
				contactIdsToAdd: [...state.contactIdsToAdd, ...contactIdsToAdd],
			};
		}
		case 'toggleConsentChecked': {
			return {
				...state,
				consentChecked: !state.consentChecked,
			};
		}
		default:
			throw new Error('Invalid action type');
	}
};

export const getInitialSendFrom = ({
	userSession,
	initialSendOnBehalf,
	initialOwnershipFilter,
}: {
	userSession: Api.UserSessionContext;
	initialSendOnBehalf?: Api.ISendOnBehalfPermission;
	initialOwnershipFilter: Api.IOwnershipFilter;
}): Api.ISendOnBehalfPermission => {
	const defaultOption = userSession.defaultSendOnBehalfPermissions.find(
		x => x.sender.mode === Api.SendEmailFrom.CurrentUser
	);
	if (initialSendOnBehalf) {
		return initialSendOnBehalf;
	}
	if (initialOwnershipFilter?.criteria) {
		if (initialOwnershipFilter.criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy) {
			return defaultOption;
		} else if (initialOwnershipFilter.criteria.value === userSession.user.id) {
			return (
				userSession.defaultSendOnBehalfPermissions.find(x => x.sender.mode === Api.SendEmailFrom.SelectedUser) ??
				defaultOption
			);
		}
	}

	return defaultOption;
};

export const useTextingCampaignRecipientFilter = ({
	initialContactIdsToOmit = [],
	initialFilterCriterias = [],
	initialContactIdsToInclude = [],
	onAllFiltersRemoved,
	initialSendOnBehalf,
	initialSendOnBehalfUserId,
	initialOwnershipFilter,
	onSendFromChanged,
}: {
	initialContactIdsToOmit?: string[];
	initialContactIdsToInclude?: string[];
	initialFilterCriterias?: Api.IContactFilterCriteria[];
	onAllFiltersRemoved?: () => void;
	initialSendOnBehalf?: Api.ISendOnBehalfPermission;
	initialSendOnBehalfUserId?: string;
	initialOwnershipFilter?: Api.IOwnershipFilter;
	onSendFromChanged?: (option: Api.ISendFromOptions) => void;
}) => {
	const userSession = useUserSession();
	const [state, dispatch] = React.useReducer(reducer, {
		filterCriteriaList: Api.VmUtils.concatContactFilterCriteria(
			initialFilterCriterias.filter(
				criteria => !(criteria.property && [Api.ContactFilterCriteriaProperty.All].includes(criteria.property))
			),
			[{ property: Api.ContactFilterCriteriaProperty.All }]
		)!,
		contactIdsToOmit: new Set(initialContactIdsToOmit),
		contactIdsToAdd: initialContactIdsToInclude,
		showingAdvancedTagFiltersModal: false,
		consentChecked: false,
	});
	const [sendEmailFrom, setSendEmailFrom] = React.useState<Api.ISendOnBehalfPermission>(
		getInitialSendFrom({ userSession, initialSendOnBehalf, initialOwnershipFilter })
	);
	const getInitialOwnershipFilter = (overridenFilter: Api.IOwnershipFilter | null) => {
		if (overridenFilter) {
			return overridenFilter;
		}
		const firstFilter = userSession.user.ownershipFilters?.length > 0 ? userSession.user.ownershipFilters[0] : null;
		return firstFilter;
	};

	const [selectedOwnershipFilter, setSelectedOwnershipFilter] = React.useState<Api.IOwnershipFilter | null>(
		getInitialOwnershipFilter(initialOwnershipFilter)
	);
	const [sendEmailFromUser, setSendEmailFromUser] = React.useState<Api.IUser>(
		initialSendOnBehalfUserId ? { id: initialSendOnBehalfUserId } : null
	);
	const onSendOnBehalf = (option: Api.ISendOnBehalfPermission, user?: Api.IUser) => {
		setSendEmailFrom(option);
		setSendEmailFromUser(user);
		setSelectedOwnershipFilter(getInitialOwnershipFilter(option?.requiredFilter));

		onSendFromChanged?.(option?.sender);
	};
	// Only apply filter in the approximation if filters are applied or no contacts were manually added
	const contactIdsToOmitArray = React.useMemo(() => Array.from(state.contactIdsToOmit), [state.contactIdsToOmit]);
	const contactsToAdd = useContactsQuery({
		contactIds: state.contactIdsToAdd,
	});
	const contactApproximateQuery = useContactApproximateQuery({
		filterRequest: {
			groupByHousehold: false,
			excludeContactIds: contactIdsToOmitArray,
			includeContactIds: state.contactIdsToAdd,
			filter: {
				criteria: state.filterCriteriaList,
			},
			ownershipFilter: selectedOwnershipFilter,
		},
		capabilities: [Api.ContactCapability.ReceiveText],
	});
	const totalRecipientsWithTextingCapability = contactApproximateQuery.data?.hasRequestedCapabilities;
	const totalRecipients = contactApproximateQuery.data?.total;
	const contactFilterQuery = useContactFilterQuery({
		filterRequest: {
			filter: {
				criteria: state.filterCriteriaList,
			},
			ownershipFilter: selectedOwnershipFilter,
		},
		sortBy: ContactSortKey.Handle,
	});
	const onChangeFilterList = (filterList: Api.IContactFilterCriteria[], ownershipFilter?: Api.IOwnershipFilter) => {
		if (!hasFilters(filterList)) {
			onAllFiltersRemoved?.();
		}
		setSelectedOwnershipFilter(ownershipFilter);
		dispatch({ type: 'setFilterList', value: filterList });
	};
	return {
		state,
		dispatch,
		contactFilterQuery,
		contactIdsToOmit: contactIdsToOmitArray,
		contactsToAdd,
		contactApproximateQuery,
		onChangeFilterList,
		selectedOwnershipFilter,
		setSelectedOwnershipFilter,
		sendEmailFrom,
		sendEmailFromUser,
		onSendOnBehalf,
		totalRecipients,
		totalRecipientsWithTextingCapability,
	};
};
