import { css } from 'aphrodite';
import { useHistory, useLocation, useParams } from 'react-router';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../components/Portal';
import { ITabbarItem, TabView } from '../../components/TabView';
import { AdminSettings } from '../../components/settings/AdminSettings';
import { PersonalSettings } from '../../components/settings/PersonalSettings';
import { AdminSettingsSection } from '../../components/settings/models';
import { styleSheet } from './styles';

type ICategoryMatch = 'admin' | 'personal';

export function Settings() {
	const userSession = useUserSession();
	const history = useHistory();
	const location = useLocation();
	const params = useParams<{ category: ICategoryMatch; action: string }>();

	const isAdmin = Boolean(userSession.user.groups?.find(group => group.toLowerCase() === 'admin'));
	const activeTab = params.category ?? 'personal';
	const onSelectedTabIndexChanged = (_: number, item: ITabbarItem<ICategoryMatch>) => {
		if (item?.dataContext) {
			history.replace(`/settings/${item.dataContext}`);
		}
	};
	const sectionToExpand = params.action;
	return (
		<>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<NavigationBreadcrumbsBar
					currentLocationName={location.pathname.includes('schedule-meeting') ? 'Schedule a Meeting' : null}
				/>
			</Portal>
			{isAdmin ? (
				<TabView
					className={css(styleSheet.settingsContainerTabview)}
					onSelectedTabIndexChanged={onSelectedTabIndexChanged}
					selectedTabIndex={activeTab === 'admin' ? 1 : 0}
				>
					{() => {
						return [
							{
								content: <PersonalSettings sectionToExpand={sectionToExpand} />,
								tabbarItem: {
									content: 'Personal',
									dataContext: 'personal',
									onClickRedirectionPath: `/settings`,
								},
							},
							{
								content: <AdminSettings sectionToExpand={(sectionToExpand as AdminSettingsSection) ?? undefined} />,
								tabbarItem: {
									content: 'Admin',
									dataContext: 'admin',
									onClickRedirectionPath: `/settings/admin`,
								},
							},
						];
					}}
				</TabView>
			) : (
				<PersonalSettings sectionToExpand={sectionToExpand} />
			)}
		</>
	);
}
