import { navigation } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const CardLayoutIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	const width = 18;
	const height = 18;
	return (
		<svg
			className={`card-layout-icon ${className || ''}`}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			width={width}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g fill={fillColor || navigation} fillRule='evenodd'>
				<path d='M1.8 1.8L1.8 6.3 16.1973 6.3 16.2 1.8 1.8 1.8zM16.2 8.1L1.8 8.1C.8073 8.1 0 7.2927 0 6.3L0 1.8C0 .8073.8073 0 1.8 0L16.2 0C17.1927 0 18 .8073 18 1.8L18 6.3C18 7.2927 17.1927 8.1 16.2 8.1L16.2 8.1zM1.8 11.7L1.8 16.2 16.1973 16.2 16.2 11.7 1.8 11.7zM16.2 18L1.8 18C.8073 18 0 17.1927 0 16.2L0 11.7C0 10.7073.8073 9.9 1.8 9.9L16.2 9.9C17.1927 9.9 18 10.7073 18 11.7L18 16.2C18 17.1927 17.1927 18 16.2 18L16.2 18z' />
			</g>
		</svg>
	);
};
