import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useDisconnectUserFromIntegrationMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (type: Api.ExternalIntegrationType) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `integrations/${encodeURIComponent(type)}/connectionState?isConnected=false`,
				}),
				'POST'
			);
		},
		onError,
		onSuccess,
	});
}
