import * as Api from '@ViewModels';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { useTextMessaging } from '../../../../models/hooks/appStateHooks';
import { useConversationsInfiniteQuery } from '../../../../queries';

export function useRefreshConversations() {
	const textMessagingVM = useTextMessaging();
	const logger = useEventLogging('useRefreshConversations');

	const query = useConversationsInfiniteQuery({
		enabled: Boolean(textMessagingVM?.id),
		onError: (error: Api.IOperationResultNoValue) => {
			logger.logApiError('ConversationsRefresh-Error', error);
		},

		phoneNumberId: textMessagingVM?.id,
	});

	React.useEffect(() => {
		if (textMessagingVM?.id && !query.isFetching && query.data?.pages?.length) {
			textMessagingVM.addConversations(query.data.pages[0].values);
		}
	}, [query.data?.pages, query.isFetching, textMessagingVM]);
}
