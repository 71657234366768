import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import {
	DataGrid,
	DataGridColumn,
	DataGridColumns,
	DataGridRow,
	DataGridRowItem,
	DataGridRows,
} from '../../../DataGrid';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { ISelectBoxOption, SelectBox } from '../../../SelectBox';
import { SvgIcon } from '../../../svgs/icons/SvgIcon';
import { styleSheet } from './styles';

const nullOption: ISelectBoxOption<string> = {
	id: 'null',
	title: '--',
	value: null,
};

export const MapStep = <T extends Api.IImportFieldConfig & { required?: boolean }>({
	isLoading,
	fields,
	headers,
	samples,
	mappings,
	setMappings,
	importError,
	mappingErrors,
	setMappingErrors,
}: {
	isLoading: boolean;
	fields: T[];
	headers: string[];
	samples: string[][];
	mappings: Api.IDictionary<string>;
	setMappings: React.Dispatch<React.SetStateAction<Api.IDictionary<string>>>;
	importError?: string;
	mappingErrors: string[];
	setMappingErrors: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
	const fieldOptions = React.useMemo(() => {
		if (!fields || !fields.length) {
			return [];
		}
		const fieldConfigs = fields.map<ISelectBoxOption<string>>(field => {
			return {
				id: `field-option-${field.name}`,
				title: field.name,
				value: field.propertyName,
			};
		});
		return [nullOption, ...fieldConfigs];
	}, [fields]);

	React.useEffect(() => {
		if (!fields || !fields.length) {
			return;
		}

		const newMappings = { ...mappings };

		headers.forEach(header => {
			const trimmedHeader = header.toLowerCase().trim();
			const match = fields.find(field => {
				return (
					[
						field.propertyName.toLowerCase().trim(),
						field.name.toLowerCase().trim(),
						...field.aliases.map(alias => alias.toLowerCase().trim()),
					].indexOf(trimmedHeader) > -1
				);
			});
			if (match) {
				newMappings[header] = match.propertyName;
			}
		});
		setMappings(newMappings);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields, headers]);

	const onSelectionChanged = (header: string, option: ISelectBoxOption<string>) => {
		const newMappings = { ...mappings };
		newMappings[header] = option.value;
		setMappings(newMappings);
	};

	const getSelectedOption = (header: string) => {
		const match = fieldOptions.find(option => {
			return option.value === mappings[header];
		});
		return match || fieldOptions[0];
	};

	React.useEffect(() => {
		const errors = [];
		const mappingValues = Object.values(mappings);

		fields.forEach(field => {
			if (!field.required) {
				return;
			}
			const match = mappingValues.find(value => {
				return field.propertyName === value;
			});
			if (!match) {
				errors.push(`${field.name} is required to be mapped.`);
			}
		});

		const selectedMappings = mappingValues.filter(value => value !== null);
		const uniqueMappingValues = selectedMappings.filter((value, index, array) => {
			return array.indexOf(value) === index;
		});

		if (selectedMappings.length !== uniqueMappingValues.length) {
			errors.push('Cannot map two columns to the same field.');
		}
		setMappingErrors(errors);
	}, [fields, headers, mappings, setMappingErrors]);

	if (!fields) {
		return <div />;
	}
	return (
		<div className={css(styleSheet.container)}>
			<p className={css(styleSheet.headerTitle)}>Start out by mapping the columns to what Levitate can support:</p>
			<div className={css(styleSheet.headerSubtitle)}>
				<SvgIcon className={css(styleSheet.headerArrow)} height={42} width={16}>
					<g fill='none' fillRule='evenodd' stroke='#89C947' strokeWidth='2' transform='matrix(1 0 0 -1 1 40)'>
						<path strokeDasharray='4' d='M5,5 C5,22.3394495 8.33333333,34.0061162 15,40' />
						<polyline points='0 4 5 0 10 4' />
					</g>
				</SvgIcon>
				<p className={css(styleSheet.tableSampleMessage)}>This is just a sample of your spreadsheet.</p>
			</div>

			<DataGrid styles={[styleSheet.tableOverrides]}>
				<DataGridColumns>
					{headers.map((header, index) => (
						<DataGridColumn key={`header-${index}`}>
							<SelectBox
								options={fieldOptions}
								onSelectionChanged={(option: ISelectBoxOption<string>) => onSelectionChanged(header, option)}
								selectedOption={getSelectedOption(header)}
							/>
						</DataGridColumn>
					))}
				</DataGridColumns>

				<DataGridRows>
					{(samples || []).map((row, k) => (
						<DataGridRow key={`${headers[k]}-[${k}]`}>
							{row.map((col, j) => (
								<DataGridRowItem key={`${headers[k]}-[${k}][${j}]`}>{col}</DataGridRowItem>
							))}
						</DataGridRow>
					))}
				</DataGridRows>
			</DataGrid>

			{importError ? <p className={css(styleSheet.errorMessage)}>{importError}</p> : null}
			{mappingErrors.map(localError => (
				<p key={localError} className={css(styleSheet.errorMessage)}>
					{localError}
				</p>
			))}

			{!!isLoading && <LoadingSpinner className='absolute-center' type='large' />}
		</div>
	);
};
