import { observer } from 'mobx-react';
import * as React from 'react';
import { useErrorMessages, useSingleEmailComposer, useUserSession } from '../../../../models/hooks/appStateHooks';
import { ActionItemViewModel, EmailMessageViewModel, IFileAttachment } from '../../../../viewmodels/AppViewModels';
import { KitEmailComposer } from '../KitEmailComposer';
import { SendMessageComposerDialog } from '../SendMessageComposerDialog';

function _SingleEmailComposerModal() {
	const singleEmailComposer = useSingleEmailComposer();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const [isShowingModal, setIsShowingModal] = React.useState(false);
	const [actionItemForKitEmailComposer, setActionItemForKitEmailComposer] = React.useState<ActionItemViewModel>();
	const [emailMessageForSendMessageComposer, setEmailMessageForSendMessageComposer] =
		React.useState<EmailMessageViewModel<File>>();

	const onRequestClose = (email?: EmailMessageViewModel<File>, canceled?: boolean) => {
		singleEmailComposer.finish(!!email && !canceled);
	};

	const onRequestRemoveSavedEmailAttachment = async (savedAttachment: IFileAttachment) => {
		const currentAttachments = emailMessageForSendMessageComposer?.savedAttachments;
		const filteredAttachments = currentAttachments?.filter(x => x.id !== savedAttachment.id);

		emailMessageForSendMessageComposer?.setSavedAttachments(filteredAttachments);
	};

	const onWillSend = (emailMessage: EmailMessageViewModel<File>) => {
		const hasEmailAddressToSendTo =
			emailMessage?.contactsToAdd
				?.map(x => {
					return emailMessage.getPreferredEmailAddressForContact(x);
				})
				.filter(x => !!x).length > 0;
		if (!hasEmailAddressToSendTo) {
			errorMessages.push({
				messages: ['To send, please add at least one valid email address.'],
			});
		}
		return hasEmailAddressToSendTo;
	};

	React.useEffect(() => {
		if (singleEmailComposer.isShowingModal) {
			const isSuggested =
				!!singleEmailComposer.actionItem && !!singleEmailComposer.actionItem.isSuggestedKeepInTouchActionItem;
			const emailMessage = new EmailMessageViewModel<File>(userSession, isSuggested);

			emailMessage.contactsToAdd.addAll(singleEmailComposer.recipients);
			emailMessage.options.sendSingleEmail = true;

			if (singleEmailComposer.actionItem) {
				if (singleEmailComposer.actionItem.isKeepInTouchActionItem) {
					setActionItemForKitEmailComposer(singleEmailComposer.actionItem);
				}
				emailMessage.setContext({
					ref: {
						id: singleEmailComposer.actionItem.id,
						type: 'ActionItem',
					},
				});
			}
			setEmailMessageForSendMessageComposer(emailMessage);
		} else {
			setActionItemForKitEmailComposer(null);

			setEmailMessageForSendMessageComposer(null);
		}

		setIsShowingModal(singleEmailComposer.isShowingModal);
	}, [singleEmailComposer.isShowingModal, singleEmailComposer.actionItem, singleEmailComposer.recipients, userSession]);

	if (!isShowingModal) {
		return null;
	}
	if (actionItemForKitEmailComposer) {
		return (
			<KitEmailComposer
				actionItem={actionItemForKitEmailComposer}
				emailMessage={emailMessageForSendMessageComposer}
				onDismissedActionItem={singleEmailComposer.dismissActionItem}
				toastOnSendSuccess={true}
				onRequestClose={onRequestClose}
			/>
		);
	}
	return (
		<SendMessageComposerDialog
			canScheduleSend={true}
			shouldStartWithAIPrompt={singleEmailComposer.shouldShowAISuggestion}
			emailType={singleEmailComposer.emailType}
			emailMessage={emailMessageForSendMessageComposer}
			onRequestRemoveSavedEmailAttachment={onRequestRemoveSavedEmailAttachment}
			onWillSend={onWillSend}
			toastOnSendSuccess={true}
			onRequestClose={onRequestClose}
		/>
	);
}

export const SingleEmailComposerModal = observer(_SingleEmailComposerModal);
