import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import React from 'react';
import { useFinishImportOpportunitiesMutation } from '../../../../../queries/Boards/useFinishImportOpportunities';
import { useImportOpportunitiesMutation } from '../../../../../queries/Boards/useImportOpportunities';
import { baseStyleSheet } from '../../../../styles/styles';
import { MultiContainerHeader } from '../../../MultiContainerHeader';
import { MapStep } from '../../Steps/Map';
import { ProcessStep } from '../../Steps/Process';
import { UploadStep } from '../../Steps/Upload';
import { styleSheet } from './styles';

enum Step {
	Uploading,
	Mapping,
	Processing,
}

export const OpportunitiesImport = ({ boardId, onCancel }: { boardId: string; onCancel(): void }) => {
	const [file, setFile] = React.useState(null);
	const [systemJob, setSystemJob] = React.useState<Api.ISystemJob>(null);
	const [mappings, setMappings] = React.useState<Api.IDictionary<string>>({});
	const [mappingErrors, setMappingErrors] = React.useState<string[]>([]);
	const importOpportunitiesMutation = useImportOpportunitiesMutation({
		onSuccess: (result: Api.IImportOpportunityPreview) => {
			setSystemJob({ id: result.id });
		},
	});
	const finishImportOpportunitiesMutation = useFinishImportOpportunitiesMutation({
		onSuccess: (newSystemJob: Api.ISystemJob) => {
			setSystemJob(newSystemJob);
		},
	});
	let currentStep: Step = Step.Uploading;
	if (importOpportunitiesMutation.status === 'success') {
		currentStep = Step.Mapping;
	}
	if (finishImportOpportunitiesMutation.status === 'success') {
		currentStep = Step.Processing;
	}

	const next = async () => {
		switch (currentStep) {
			case Step.Uploading:
				importOpportunitiesMutation.mutate({ file });
				break;
			case Step.Mapping:
				finishImportOpportunitiesMutation.mutate({
					systemJobId: systemJob.id,
					options: { fieldMapping: mappings, boardId },
				});
				break;
			default:
				throw new Error('Impossible scenario');
		}
	};

	const getHeader = () => {
		switch (currentStep) {
			case Step.Uploading:
				return 'Import Opportunities from Spreadsheet';
			case Step.Mapping:
				return 'Please map the correct columns using the drop downs';
			case Step.Processing:
				return 'Importing...';
			default:
				throw new Error('Impossible scenario');
		}
	};

	const isUploading = currentStep === Step.Uploading;
	const isMapping = currentStep === Step.Mapping;
	const isProcessing = currentStep === Step.Processing;

	const canGoNext = React.useMemo(() => {
		if (isUploading) {
			return !!file;
		} else if (isMapping) {
			return mappingErrors.length === 0;
		} else {
			return false;
		}
	}, [isUploading, isMapping, file, mappingErrors]);

	const importError = finishImportOpportunitiesMutation.error?.systemMessage;

	return (
		<div className={css(styleSheet.container)}>
			<MultiContainerHeader fullscreenHeader={getHeader()} />

			{isUploading ? (
				<UploadStep isLoading={importOpportunitiesMutation.isLoading} file={file} setFile={setFile} />
			) : null}

			{isMapping ? (
				<MapStep<Api.IImportOpportunityFieldConfig>
					isLoading={importOpportunitiesMutation.isLoading}
					headers={importOpportunitiesMutation.data?.header}
					samples={importOpportunitiesMutation.data?.sample}
					fields={importOpportunitiesMutation.data?.fields}
					mappings={mappings}
					setMappings={setMappings}
					importError={importError}
					mappingErrors={mappingErrors}
					setMappingErrors={setMappingErrors}
				/>
			) : null}

			{isProcessing && !!systemJob ? (
				<ProcessStep
					systemJob={systemJob}
					setSystemJob={setSystemJob}
					singularEntity='opportunity'
					pluralEntity='opportunities'
				/>
			) : null}

			{!isProcessing ? (
				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={next} disabled={!canGoNext}>
						<span>Next</span>
					</button>
					<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCancel}>
						<span>Cancel</span>
					</button>
				</div>
			) : null}

			{isProcessing ? (
				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={onCancel}>
						<span>Close Window</span>
					</button>
				</div>
			) : null}
		</div>
	);
};
