import { css } from 'aphrodite';
import * as React from 'react';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { DeprecatedPopover, PopoverType } from '../../DeprecatedPopover';
import { InfoIcon } from '../../svgs/icons/InfoIcon';
import { StageIndicator } from '../StageIndicator';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

interface IState {
	isOpen?: boolean;
}

export class EditBoardStageIndicatorInfo extends React.PureComponent<IProps, IState> {
	public state: IState = {};

	public render() {
		const { className } = this.props;
		const { isOpen } = this.state;
		return (
			<DeprecatedPopover
				anchor={
					<button className={`${css(styleSheet.trigger)} ${className || ''}`} onClick={this.onToggle}>
						<InfoIcon />
					</button>
				}
				className={css(styleSheet.container)}
				dismissOnClickOutside={true}
				isOpen={isOpen}
				onRequestClose={this.onRequestClose}
				type={PopoverType.white}
			>
				<div>
					<div className={css(styleSheet.header)}>
						<DeprecatedCloseButton onClick={this.onRequestClose} />
					</div>
					<div>What is a stage indicator?</div>
					<StageIndicator className={css(styleSheet.indicator)} currentIndex={5} total={12} type='compact' />
					<div>
						When you check this box, this column will become a stage in the sequence and get a progress indicator shown
						above.
					</div>
				</div>
			</DeprecatedPopover>
		);
	}

	private onRequestClose = () => {
		this.setState({
			isOpen: false,
		});
	};

	private onToggle = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};
}
