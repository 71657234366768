import { useQuery } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

interface GetIndustryTemplateArgs {
	enabled?: boolean;
	industry?: string;
	types?: Api.TemplateType[];
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
}

const BASE_QUERY_KEY = 'template/system';

export const useGetTemplatesByIndustry = ({
	enabled,
	industry,
	types,
	impersonationContext,
	onError,
}: GetIndustryTemplateArgs) => {
	const userSession = useUserSession();
	const queryParams: Record<string, any> = {
		industry: industry || userSession.account?.toJs().additionalInfo?.industry || Api.Industry.Miscellaneous,
	};

	return useQuery({
		queryFn: async () => {
			if (types?.length > 0) {
				queryParams.types = types;
			}

			return userSession.webServiceHelper.callAsync<Api.ITemplate[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `template/system`,
					impersonationContext,
					queryParams,
				}),
				'GET',
				null
			);
		},
		onError,
		enabled,
		queryKey: [BASE_QUERY_KEY, queryParams.industry, types, impersonationContext],
	});
};

export const invalidateGetTemplatesByIndustryQuery = ({
	impersonationContext,
	industry,
	types,
}: GetIndustryTemplateArgs) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, impersonationContext, industry, types]);
};
