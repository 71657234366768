export enum AmountFilterOption {
	Lifetime = 'Lifetime',
	OneTime = 'One-Time',
}

export enum DonationPropertyOption {
	DonorName = 'Donor Name',
	Campaign = 'Campaign',
}

export enum DonationTypeOption {
	HasDonated = 'Has Donated',
	NotDonated = 'Not Donated',
}
