import { useEventLogging } from '@AppModels/Logging';
import { css } from 'aphrodite';
import FlipMove from 'react-flip-move';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { getFeedItemViewModel, invalidateDashboardFeed, useDashboardFeed } from '../../../queries';
import { ActionItemViewModel } from '../../../viewmodels/AppViewModels';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { EmptyState } from '../../components/EmptyState';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DarkMainContainerBackground } from '../../components/MainContainerBackground';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../components/Portal';
import { KitCard } from '../../components/cards/KitCard';
import { ContactInCircleGraphic } from '../../components/svgs/graphics/ContactInCircleGraphic';
import { TargetIcon } from '../../components/svgs/icons/TargetIcon';
import { styleSheet } from './styles';

export const KIT_FEED_PAGE_SIZE = 100;
export const KIT_FEED_TYPE = 'Reminder';

export const KeepInTouches = () => {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const fullscreenModal = useFullscreenModal();
	const { logApiError } = useEventLogging('KeepInTouches');
	const dashboardFeedQuery = useDashboardFeed({
		handleError: err => {
			errorMessages.pushApiError(err);
			logApiError('LoadKeepInTouches-Error', err);
		},
		pageSize: KIT_FEED_PAGE_SIZE,
		typeOf: KIT_FEED_TYPE,
	});

	const focus = () => {
		fullscreenModal.history?.push({
			pathname: '/people/focused/',
			state: {
				mode: 'kit',
			},
		});
	};

	const onRequestRemoveItem = () => {
		invalidateDashboardFeed({ pageSize: KIT_FEED_PAGE_SIZE, typeOf: KIT_FEED_TYPE });
	};
	const feedItems = dashboardFeedQuery.data?.values ?? [];
	return (
		<div className={`${css(styleSheet.container)} main-container-child`}>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<NavigationBreadcrumbsBar currentLocationName='Keep In Touches' />
			</Portal>
			<DarkMainContainerBackground />
			{dashboardFeedQuery.status !== 'success' ? (
				<LoadingSpinner type='large' />
			) : !feedItems.length ? (
				<EmptyState
					image={<ContactInCircleGraphic />}
					text={"You don't have any keep in touch reminders"}
					subtext='More reminders will appear on your dashboard as they approach.'
				/>
			) : (
				<div>
					<div className={css(styleSheet.focusButtonContainer)}>
						<button className={css(styleSheet.button, styleSheet.focus)} onClick={focus}>
							<span>
								<TargetIcon />
								<span className={css(styleSheet.buttonText)}>Focused View</span>
							</span>
						</button>
					</div>

					<FlipMove
						enterAnimation={true}
						leaveAnimation={true}
						staggerDelayBy={50}
						staggerDurationBy={10}
						appearAnimation={false}
					>
						{feedItems.map(x => {
							const itemVM = getFeedItemViewModel({ feedItem: x, userSession });
							if (itemVM instanceof ActionItemViewModel) {
								if (itemVM.isKeepInTouchActionItem) {
									return (
										<KitCard
											actionItem={itemVM}
											className={css(styleSheet.item)}
											key={itemVM.id}
											onRequestRemove={onRequestRemoveItem}
										/>
									);
								}
							}
						})}
					</FlipMove>
				</div>
			)}
		</div>
	);
};
