import { IPushNotificationMessage } from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IPushNotificationsComponentProps,
	IUserSessionComponentProps,
	PushNotificationsViewModelKey,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { VmUtils } from '@ViewModels';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { BrowserPushNotificationsViewModel } from '../../../viewmodels/PushNotifications';

interface IProps extends IUserSessionComponentProps, IErrorMessageComponentProps, IPushNotificationsComponentProps {
	onMessage?(message: IPushNotificationMessage): void;
}

class _PushNotifications extends React.Component<IProps> {
	private mViewModel: BrowserPushNotificationsViewModel;

	private mMessageHandlerDisposer: () => void;

	constructor(props: IProps) {
		super(props);
		this.mViewModel =
			props.pushNotifications ||
			new BrowserPushNotificationsViewModel(props.userSession, {
				addLogger: VmUtils.Noop,
				logEvent: (args, context) => console.log(args, context),
				removeLogger: VmUtils.Noop,
			});
	}

	public componentDidMount() {
		this.mMessageHandlerDisposer = this.mViewModel.addNotificationMessageHandler(this.onMessage);
		this.mViewModel.init();
	}

	public componentWillUnmount() {
		if (this.mMessageHandlerDisposer) {
			this.mMessageHandlerDisposer();

			this.mMessageHandlerDisposer = null;
		}
	}

	public render() {
		return null as JSX.Element;
	}

	private onMessage = (message: MessageEvent) => {
		const { onMessage } = this.props;
		if (!!onMessage && !!message && !!message.data) {
			const pushMessage: IPushNotificationMessage = message.data['firebase-messaging-msg-data'];
			if (pushMessage) {
				onMessage(pushMessage);
			}
		}
	};
}

const PushNotificationsAsObserver = observer(_PushNotifications);
export const PushNotifications = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	PushNotificationsViewModelKey
)(PushNotificationsAsObserver);
