import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { EventLogger } from '../../../models/Logging';
import { DeactivateAccountViewModel } from '../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../styles/styles';
import { Checkbox } from '../Checkbox';
import { TextArea } from '../TextArea';
import './styles.less';

interface IProps
	extends RouteComponentProps<any>,
		IToasterComponentProps,
		IErrorMessageComponentProps,
		IUserSessionComponentProps {
	onCancel?: () => void;
	onDeactivated?: () => void;
}

interface IState {
	deactivateVM?: DeactivateAccountViewModel;
}

class _DeactivateAccount extends React.Component<IProps, IState> {
	public UNSAFE_componentWillMount() {
		this.setState({
			deactivateVM: new DeactivateAccountViewModel(this.props.userSession),
		});
	}

	private onChecked = () => {
		const { deactivateVM } = this.state;

		deactivateVM.cancelConfirmed = !this.state.deactivateVM.cancelConfirmed;
	};

	private onCancelClicked = () => {
		if (this.props.onCancel) {
			this.props.onCancel();
		}
	};

	private onCancelReasonChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { deactivateVM } = this.state;

		deactivateVM.cancelReason = e.target.value;
	};

	private onDeactivateSuccess = () => {
		if (this.props.onDeactivated) {
			this.props.onDeactivated();
		}
	};

	private onDeactivateError = (error: any) => {
		EventLogger.logEvent(
			{
				action: 'Deactivate-Error',
				category: 'DeactivateAccount',
			},
			{ ...error }
		);
	};

	private onDeactivateClicked = () => {
		const promise = this.state.deactivateVM.deactivate();

		if (promise) {
			promise.then(this.onDeactivateSuccess).catch(this.onDeactivateError);
		}
	};

	public render() {
		const { deactivateVM } = this.state;
		return (
			<div className='deactivate-container'>
				<div className='deactivate-container-header'>Deactivate Account</div>
				<div className='deactivate-container-content'>
					By deactivating, Levitate will erase all of your data after a 30 day grace period, and you will no longer be
					able to log in. This action cannot be undone.
				</div>
				<div className='deactivate-container-feedback'>
					<div>Feedback:</div>
					<div>
						<TextArea
							inputId='deactivate-container-feedback-input'
							onChange={this.onCancelReasonChanged}
							placeholder='We are always looking for ways to improve Levitate. Please share your main reason for deactivating.'
							value={deactivateVM.cancelReason || ''}
						/>
					</div>
				</div>
				<div className='deactivate-container-checkbox'>
					<Checkbox
						id='deactivate-container-checkbox'
						onChange={this.onChecked}
						checked={!!deactivateVM.cancelConfirmed}
					>
						<span>Deactivate my account</span>
					</Checkbox>
				</div>
				<div className='deactivate-container-buttons'>
					<button
						className={`${css(baseStyleSheet.ctaButton)} deactivate-container-buttons-deactivate`}
						disabled={!deactivateVM.canDeactivate}
						onClick={this.onDeactivateClicked}
					>
						Deactivate
					</button>
					<button className='cta-button-reverse deactivate-container-buttons-cancel' onClick={this.onCancelClicked}>
						Cancel
					</button>
				</div>
			</div>
		);
	}
}

const DeactivateAccountAsObserver = observer(_DeactivateAccount);
const DeactivateAccountWithRouter = withRouter(DeactivateAccountAsObserver);
export const DeactivateAccount = inject(
	ToasterViewModelKey,
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(DeactivateAccountWithRouter);
