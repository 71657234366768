import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		boxShadow: 'none',
		color: '#0337CC',
		marginTop: 20,
	},
	integrationTitle: {
		color: '#0337CC',
	},
});
