import {
	ITemplate,
	Industry,
	PageCollectionControllerViewModel,
	TemplateScope,
	TemplateType,
	asApiError,
	selectKeysOf,
} from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ICreateCampaignRequest, ILocationState, TemplateOrTemplateFilter } from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { useEventLogging } from '../../../../models/Logging';
import { useAutorun } from '../../../../models/hooks/mobxHooks';
import { invalidateGetNewsletterTemplatesByKeyword, invalidateTemplatesByTypeQuery } from '../../../../queries';
import { invalidateGetTemplatesByIndustryQuery } from '../../../../queries/Templates/useGetTemplatesByIndustry';
import { HtmlNewsletterViewModel } from '../../../../viewmodels/HtmlNewletterViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import {
	ConfirmationDialog,
	DefaultDeleteConfirmationOptions,
	IConfirmationDialogOption,
} from '../../ConfirmationDialog';
import { DeprecatedMoreMenu } from '../../DeprecatedMoreMenu';
import { LoadingSpinner } from '../../LoadingSpinner';
import { IModalProps } from '../../Modal';
import { IMoreMenuItem } from '../../MoreMenu';
import { TextInputModal } from '../../TextInputModal';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { TemplateSharingConfirmation } from '../../templates/TemplateSharingConfirmation';
import { TemplateKeywords } from '../TemplateKeywords';
import { styleSheet } from './styles';

interface IProps
	extends RouteComponentProps<any>,
		IErrorMessageComponentProps,
		IUserSessionComponentProps,
		IFullscreenModalComponentProps {
	industry: Industry;
	template: ITemplate;
	templates?: PageCollectionControllerViewModel<ITemplate, ITemplate>;
}

const getMoreMenuItems = (newsletter: HtmlNewsletterViewModel) => {
	const items: IMoreMenuItem<string>[] = [
		newsletter.canEdit
			? {
					name: 'Edit',
					representedObject: 'edit',
				}
			: {
					name: 'View',
					representedObject: 'view',
				},
		{
			name: 'Duplicate',
			representedObject: 'copy',
		},
		{
			name: 'Send',
			representedObject: 'send',
		},
		newsletter.canShare
			? {
					name: 'Delete',
					representedObject: 'delete',
				}
			: null,
		newsletter.canShare
			? {
					name: newsletter.isShared ? 'Unshare' : 'Share',
					representedObject: newsletter.isShared ? 'unshare' : 'share',
				}
			: null,
	].filter(Boolean);
	return items;
};

const HtmlNewsletterTemplateCardSfc: React.FC<IProps> = ({
	history,
	industry,
	template,
	templates,
	userSession,
	errorMessages,
	fullscreenModal,
}) => {
	const logger = useEventLogging('HtmlNewsletterTemplateCard');
	const templatesVmRef = React.useRef(
		templates ||
			new PageCollectionControllerViewModel<ITemplate, ITemplate>(userSession, 'template', {
				apiParams: {
					includeNonUserTemplates: true,
					type: TemplateType.HtmlNewsletter,
				},
				apiPath: 'template/byType',

				client: userSession.webServiceHelper,
				itemUniqueIdentifierPropertyPath: 'id',
			})
	);

	const [newsletter, setNewsletter] = React.useState<HtmlNewsletterViewModel>(
		new HtmlNewsletterViewModel(userSession, template)
	);
	const [moreMenuItems, setMoreMenuItems] = React.useState<IMoreMenuItem<string>[]>(getMoreMenuItems(newsletter));
	const [isBusy, setIsBusy] = React.useState(false);

	React.useEffect(() => {
		if (template !== newsletter?.template) {
			const nextNewsLetter = new HtmlNewsletterViewModel(userSession, template);
			setNewsletter(nextNewsLetter);
			setMoreMenuItems(getMoreMenuItems(nextNewsLetter));
		}
	}, [template, userSession, newsletter]);

	// duplicate modal props
	const [showingSaveAsNewModal, setShowingSaveAsNewModal] = React.useState(false);
	const saveAsNewTemplateModalProps = React.useMemo<IModalProps<string>>(() => {
		return {
			isOpen: showingSaveAsNewModal,
			onRequestClose: async (name, cancel) => {
				const trimmedName = (name || '').trim();
				if (trimmedName !== newsletter.name && !!trimmedName && !cancel) {
					try {
						newsletter.template.subject = name;

						const createdNewsletter = await HtmlNewsletterViewModel.create(userSession, {
							...(selectKeysOf(newsletter.toJs(), ['content', 'templateType', 'subject', 'templateType']) as ITemplate),
							name,
							scope: TemplateScope.User,
						});
						templatesVmRef.current.items.add(createdNewsletter.toJs());
					} catch (err) {
						logger.logApiError('SaveAsNewTemplate-Error', asApiError(err));
					}
				}
				setShowingSaveAsNewModal(false);
			},
			useDefaultHeader: true,
		};
	}, [showingSaveAsNewModal, newsletter, logger, userSession]);

	// delete confirmation modal
	const [showingDeleteConfirmation, setShowingDeleteConfirmation] = React.useState(false);
	const deleteConfirmationModalProps = React.useMemo<IModalProps<IConfirmationDialogOption<boolean>>>(() => {
		return {
			isOpen: showingDeleteConfirmation,
			onRequestClose: async (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
				if (!canceled && result?.isDestructive) {
					try {
						setIsBusy(true);
						await templatesVmRef.current.delete(template);
					} catch (err) {
						const error = asApiError(err);
						logger.logApiError(`DeleteTemplate-Error`, error);

						errorMessages.pushApiError(error);
						setIsBusy(false);
						setShowingDeleteConfirmation(false);
					}
				} else {
					setShowingDeleteConfirmation(false);
				}
			},
		};
	}, [showingDeleteConfirmation, errorMessages, logger, template]);

	// share/unshare confirmation modal
	const [showingShareConfirmationModal, setShowingShareConfirmationModal] = React.useState(false);
	const templateSharingConfirmationRequestClose = React.useCallback(
		async (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
			if (!canceled && !!result) {
				try {
					setIsBusy(true);
					await newsletter.setScope(newsletter.isShared ? TemplateScope.User : TemplateScope.Account);
					setIsBusy(false);
					setShowingShareConfirmationModal(false);
				} catch (err) {
					const error = asApiError(err);
					logger.logApiError(`ShareTemplate-Error`, error);

					errorMessages.pushApiError(error);
					setIsBusy(false);
					setShowingShareConfirmationModal(false);
				}
			} else {
				setShowingShareConfirmationModal(false);
			}
		},
		[errorMessages, logger, newsletter]
	);

	const renderEditTemplateNameInputLabel = () => {
		return <div className={css(styleSheet.editNameLabel)}>Template Name:</div>;
	};

	const nextDuplicateTemplateNameRef = React.useRef(newsletter.name);
	useAutorun(() => {
		// create a suggested name for a duplicate template
		const trimmed = newsletter?.name?.replace(/-([0-9]+)$/i, '');
		const regExp = new RegExp(`${trimmed}-([0-9]+)$`, 'igm');

		const matches = templates?.items.filter(x => regExp.test(x.name));

		if (matches?.length > 0) {
			const numbers = matches
				.map(x => {
					const comps = x.name.split('-').filter(y => !!y);
					return parseInt(comps[comps.length - 1], 10);
				})
				.sort((x, y) => {
					return x > y ? 1 : y > x ? -1 : 0;
				});
			nextDuplicateTemplateNameRef.current = `${trimmed}-${numbers[numbers.length - 1] + 1}`;
		} else {
			nextDuplicateTemplateNameRef.current = `${trimmed}-1`;
		}
	}, [templates]);

	const getElementProps = () => {
		return {};
	};

	const onClick = () => {
		const locationState: ILocationState<any, ITemplate> = {
			model: newsletter.template,
		};
		history?.push({
			pathname: `/campaigns/htmlnewsletter/${template.id}`,
			state: locationState,
		});
	};

	const onMenuItemClicked = async (menuItem: IMoreMenuItem<string>, e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();

		let eventName = '';
		try {
			switch (menuItem.representedObject) {
				case 'copy': {
					setShowingSaveAsNewModal(true);
					break;
				}
				case 'delete': {
					eventName = 'DeleteMenuItem';
					logger.logInput('DeleteMenuItem', 'Click');
					setShowingDeleteConfirmation(true);
					break;
				}
				case 'send': {
					const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
						model: {
							context: { ...(newsletter.template || {}) },
							type: 'Template',
						},
					};
					fullscreenModal?.history?.push({
						pathname: '/email/campaigns/create/from-template',
						state: locationState,
					});
					break;
				}
				case 'unshare':
				case 'share': {
					setShowingShareConfirmationModal(true);
					break;
				}
				case 'edit':
				case 'view': {
					onClick();
					break;
				}
				default: {
					break;
				}
			}
		} catch (err) {
			const error = asApiError(err);
			logger.logApiError(`${eventName}-Error`, error);

			errorMessages.pushApiError(error);
			setIsBusy(false);
		}
	};
	const handleInvalidateTemplates = () => {
		invalidateTemplatesByTypeQuery({
			impersonationContext: userSession.impersonationContext,
			type: TemplateType.HtmlNewsletter,
		});
		invalidateGetNewsletterTemplatesByKeyword({
			impersonationContext: userSession.impersonationContext,
			industry,
			templateType: TemplateType.HtmlNewsletter,
		});
		invalidateGetTemplatesByIndustryQuery({
			impersonationContext: userSession.impersonationContext,
			industry,
			types: [TemplateType.HtmlNewsletter],
		});
	};

	return (
		<div {...getElementProps()} className={css(styleSheet.templateCard)}>
			<div className={css(styleSheet.templateCardHeader)}>
				{moreMenuItems.length > 0 && (
					<DeprecatedMoreMenu
						menuButtonClassName={css(styleSheet.templateCardMoreMenu)}
						menuItems={moreMenuItems}
						onMenuItemClicked={onMenuItemClicked}
					/>
				)}
				<div>{newsletter.name}</div>
			</div>
			{!!newsletter.summary && <div>{newsletter.summary}</div>}
			<div className={css(styleSheet.templateCardFooter)}>
				<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={onClick}>
					<span>View Campaign</span>
				</button>
				<TemplateKeywords
					creatorId={template?.creator?.id}
					keywords={template?.keywords}
					templateId={template?.id}
					industry={industry}
					invalidateTemplates={handleInvalidateTemplates}
				/>
			</div>
			{isBusy && <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />}
			{!!showingSaveAsNewModal && (
				<TextInputModal
					cta='Duplicate'
					initialValue={nextDuplicateTemplateNameRef.current || newsletter.name}
					inputLabel={renderEditTemplateNameInputLabel()}
					modalProps={saveAsNewTemplateModalProps}
					title='Save as New Template'
				/>
			)}
			{!!showingDeleteConfirmation && (
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={deleteConfirmationModalProps}
					options={DefaultDeleteConfirmationOptions}
					title={`Are you sure you want to delete the "${newsletter.name || ''}" newsletter template?`}
				/>
			)}
			<TemplateSharingConfirmation
				onRequestClose={templateSharingConfirmationRequestClose}
				show={showingShareConfirmationModal}
				template={newsletter.template}
			/>
		</div>
	);
};

export const HtmlNewsletterTemplateCard = inject(
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey,
	FullScreenModalViewModelKey
)(withRouter(HtmlNewsletterTemplateCardSfc));
