import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		boxShadow: 'none',
		color: '#40C4F5',
		marginTop: 20,
		border: '1px solid #40C4F5',
	},
	integrationTitle: {
		color: '#40C4F5',
	},
});
