import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	AutomationOnHoldViewModel,
	AutomationSelectionType,
	ContactViewModel,
	IAutomationOnHold,
	IOperationResult,
	IOperationResultNoValue,
	IUser,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { Dropdown } from '../../Dropdown';
import { LoadingSpinner } from '../../LoadingSpinner';
import { ContactsListItem } from '../../contacts/ContactsListItem';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { AutomationSteps } from '../AutomationSteps';
import { SearchEmployeeModal } from './SearchEmployeeModal';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IErrorMessageComponentProps, IUserSessionComponentProps {
	automation: AutomationOnHoldViewModel;
	className?: string;
	includeAll?: boolean;
	onApproveSelected?(approvePromise: Promise<IOperationResult<IAutomationOnHold[]>>): void;
	styles?: StyleDeclarationValue[];
}

interface IState {
	showActionMenu: boolean;
	showSearchModal: boolean;
}

class _AutomationOnHold extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			showActionMenu: false,
			showSearchModal: false,
		};
	}
	public componentDidMount() {
		this.loadContacts();
	}

	public render() {
		const { className, styles, automation, userSession } = this.props;
		const { showActionMenu, showSearchModal } = this.state;

		const IS_ADMIN = userSession.account.isAdmin && userSession.account.features.automation.allowAdminToStartOnBehalf;

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} automation-on-hold ${className || ''}`}>
				<article className={css(styleSheet.automationContainer)}>
					<h3 className={css(styleSheet.automationName, baseStyleSheet.truncateText)}>
						{automation?.automation?.template?.name}
					</h3>
					<p className={css(baseStyleSheet.tableColumnHeader, styleSheet.stepsLabel)}>Steps</p>
					<AutomationSteps automationTemplate={automation?.automation} maxStepsToDisplay={6} />
					{IS_ADMIN ? (
						<div className={css(styleSheet.sendFromFieldWrap)}>
							<p className={css(styleSheet.sendFromLabel)}>Send from: </p>
							<Dropdown
								anchor={
									<button type='button' className={css(styleSheet.dropdownMenuButton)} onClick={this.toggleActionMenu}>
										<span className={css(styleSheet.nameWrap, baseStyleSheet.truncateText)}>
											{automation.sendAutomationAsType === AutomationSelectionType.Employee &&
											automation.sendAutomationAsUser
												? this.getFullName(automation.sendAutomationAsUser)
												: automation.sendAutomationAsType}
										</span>
										<DisclosureIcon className={css(styleSheet.triggerCaret)} fillColor='#858585' />
									</button>
								}
								isOpen={showActionMenu}
								contentClassName={css(styleSheet.menu)}
								contentPositionX='left'
								contentPositionY='bottom'
								openOnClick={true}
							>
								<div className={css(styleSheet.menuInnerContainer)}>
									{Object.values(AutomationSelectionType).map(x => {
										return (
											<button
												key={x}
												type='button'
												className={css(styleSheet.resetButton)}
												onClick={this.sendAutomationAs(x)}
											>
												{x !== AutomationSelectionType.Employee ? x : 'Search Employees'}
											</button>
										);
									})}
								</div>
							</Dropdown>
						</div>
					) : null}
					{IS_ADMIN ? (
						<SearchEmployeeModal
							modalProps={{
								isOpen: showSearchModal,
								onRequestClose: this.onModalRequestClose,
							}}
							onSelectionMade={this.onSelectionMade}
						/>
					) : null}
				</article>
				<div className={css(styleSheet.contactListContainer)}>
					<div className={css(styleSheet.actionContainer)}>
						<Checkbox
							checked={automation.selectAllChecked}
							className={css(styleSheet.checkbox)}
							disabled={automation.isBusy || this.allContactsApproved}
							id={`contacts-list-select-all-checkbox-${automation.automation.template.id}`}
							onChange={this.toggleSelectAll}
							partial={automation.selectAllChecked && automation.excludedContacts.length > 0}
						>
							<span>Select all {automation?.contacts.length}</span>
						</Checkbox>
						<button
							className={css(baseStyleSheet.ctaButtonSmall)}
							disabled={automation.isBusy || automation.selectedContacts.length === 0 || this.allContactsApproved}
							onClick={this.approveSelected}
						>
							<span>Approve selected</span>
						</button>
					</div>
					<div className={css(styleSheet.contactList, this.allContactsApproved && styleSheet.disableScroll)}>
						{this.allContactsApproved && !automation.isBusy && (
							<div className={css(styleSheet.successCover)}>
								<SuccessCheckIcon size={60} />
							</div>
						)}
						{automation.contacts.map(x => {
							const unique = `${automation.automation.template.id}-${x.id}`;
							const showSelected = automation.selectAllChecked
								? !automation.excludedContacts.has(x) && !automation.approvedContacts.has(x)
								: automation.isContactSelected(x);

							return (
								<ContactsListItem
									className={css(styleSheet.contactListItem)}
									contact={x.toJs()}
									key={unique}
									selected={showSelected}
									checkboxId={unique}
									success={automation.approvedContacts?.has(x)}
									onCheckChanged={this.toggleContact(x)}
									onRemoveClicked={this.onRemoveClicked(x)}
								/>
							);
						})}
						{!!automation.isBusy && (
							<div className={css(styleSheet.contactListItem)}>
								<LoadingSpinner type='small' />
							</div>
						)}
						{automation.contacts.length > 0 && <Waypoint bottomOffset='-200px' onEnter={this.loadContacts} />}
					</div>
				</div>
			</div>
		);
	}

	private onSelectionMade = (selection: IUser) => {
		const { automation } = this.props;
		automation.sendAutomationAsUser = selection;
		this.setState({ showSearchModal: false });
	};

	private onModalRequestClose = () => {
		const { automation } = this.props;
		automation.sendAutomationAsType = AutomationSelectionType.Myself;
		automation.sendAutomationAsUser = null;
		this.setState({
			showSearchModal: false,
		});
	};

	private sendAutomationAs = (type: AutomationSelectionType) => () => {
		const { automation } = this.props;
		automation.sendAutomationAsType = type;
		automation.sendAutomationAsUser = null;
		this.setState({
			showActionMenu: false,
			showSearchModal: type === AutomationSelectionType.Employee,
		});
	};

	private getFullName = (user: IUser) => {
		return user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : user?.email;
	};

	private toggleActionMenu = () => {
		this.setState({ showActionMenu: !this.state.showActionMenu });
	};

	private loadContacts = () => {
		const { automation, errorMessages, logApiError, includeAll, userSession } = this.props;

		automation.loadContacts(includeAll && userSession.canSendOnBehalf)?.catch((err: IOperationResultNoValue) => {
			errorMessages.pushApiError(err);

			logApiError('LoadContacts-Error', err);
		});
	};

	@computed
	private get allContactsApproved() {
		const { automation } = this.props;
		return automation.approvedContacts.length === automation.contacts?.length;
	}

	private onRemoveClicked = (contact: ContactViewModel) => () => {
		const { automation, logApiError, errorMessages, includeAll } = this.props;
		automation.rejectContact(contact, includeAll)?.catch((err: IOperationResultNoValue) => {
			errorMessages.pushApiError(err);

			logApiError('RejectContact-Error', err);
		});
	};

	private approveSelected = () => {
		const { automation, logApiError, errorMessages, includeAll, onApproveSelected } = this.props;

		const promise = automation.selectAllChecked
			? automation.approveAllButExcludedContacts(includeAll)
			: automation.approveSelectedContacts(includeAll);

		promise?.catch((err: IOperationResultNoValue) => {
			errorMessages.pushApiError(err);

			logApiError('ApproveContacts-Error', err);
		});

		onApproveSelected?.(promise);
	};

	private toggleSelectAll = () => {
		const { automation } = this.props;
		automation.setSelectAllChecked(!automation.selectAllChecked);
	};

	private toggleContact = (contact: ContactViewModel) => () => {
		const { automation } = this.props;
		if (automation.selectAllChecked) {
			if (automation.excludedContacts.has(contact)) {
				automation.excludedContacts.removeItems([contact]);
			} else {
				automation.excludedContacts.add(contact);
			}
		} else {
			if (automation.selectedContacts.indexOf(contact) > -1) {
				automation.selectedContacts.removeItems([contact]);
			} else {
				automation.selectedContacts.add(contact);
			}
		}
	};
}

const AutomationOnHoldAsObserver = observer(_AutomationOnHold);
const AutomationOnHoldWithContext = inject(
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(AutomationOnHoldAsObserver);
export const AutomationOnHold = withEventLogging(AutomationOnHoldWithContext, 'AutomationOnHold');
