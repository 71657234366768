import { brandPrimary, mention, success } from '@Styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addCardButton: {
		whiteSpace: 'nowrap',
	},
	personDetails: {
		display: 'flex',
		gap: 4,
	},
	personDetailsLabel: {
		fontSize: '12px',
		color: '#6B778C',
	},
	personDetailsValue: {
		fontSize: '12px',
		color: brandPrimary,
	},
	footer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
	ownerInfo: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
	},
	header: {
		display: 'flex',
		justifyContent: 'flex-start',
		padding: 8,
	},
	addButton: {
		border: `2px dashed ${brandPrimary}`,
		borderRadius: 8,
		color: brandPrimary,
		cursor: 'pointer',
		padding: '4px 8px',
		textAlign: 'left',
		background: mention,
		':hover': {
			background: '#F4F5F7',
			borderColor: '#C1C7D0',
		},
	},
	contactDetails: {
		display: 'flex',
		gap: 8,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	cardDetails: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: 8,
		fontSize: 12,
		color: '#6B778C',
		marginTop: 12,
	},
	ownerDetails: {
		display: 'flex',
		gap: 4,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	contactName: {
		color: brandPrimary,
		fontSize: '13px',
		fontWeight: 500,
		textDecoration: 'none',
		':hover': {
			textDecoration: 'underline',
		},
	},
	closeDate: {
		color: '#6B778C',
		fontSize: '11px',
	},
	dealSize: {
		height: '100%',
		color: success,
		fontSize: '13px',
		fontWeight: 600,
	},
	totalValue: {
		fontSize: '13px',
		fontWeight: 600,
	},
	columnFooter: {
		borderTop: '1px solid #DFE1E6',
		color: '#6B778C',
		display: 'flex',
		fontSize: '12px',
		justifyContent: 'space-between',
		padding: '8px 4px',
		marginTop: '4px',
	},
});
