import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import { parse as fromQuery } from 'query-string';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import { ISocialMediaConnectResult } from '../../../../../models/SocialMedia';
import { useErrorMessages, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { IModalProps } from '../../../Modal';
import { SettingsGroup } from '../../SettingsGroup';
import { SettingsGroupIcon } from '../../SettingsGroupIcon';
import { SettingsSubGroup } from '../../SettingsSubGroup';
import { useSocialMediaAuthHandler } from './hooks';
import {
	SocialMediaPostTargetSelectorModal,
	SocialMediaSettingsServiceConnectionStatus,
	SocialMedialConnectionSuccessModal,
} from './presentation';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	className?: string;
	expanded?: boolean;
	styles?: StyleDeclarationValue[];
}

const SocialMediaSettingsBase: React.FC<IProps> = observer(props => {
	const { className, styles = [], expanded } = props;
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('SocialMediaSettings');

	const h = useHistory();
	const loc = useLocation<ISocialMediaConnectResult>();
	const connectionsContainerRef = React.useRef<HTMLDivElement>(null);

	const [connectionSuccessModalProps, setConnectionSuccessModalProps] = React.useState<IModalProps>({
		isOpen: false,
		onRequestClose: Api.VmUtils.Noop,
	});

	// create a dictionary of vms for the supported services
	const vms = React.useRef<Record<string, Api.SocialMediaViewModel>>(
		Api.SupportedSocialMediaTypes.reduce<Record<string, Api.SocialMediaViewModel>>((result, x) => {
			switch (x) {
				case Api.SocialMediaType.Facebook: {
					result[x] = new Api.FacebookViewModel(userSession);
					break;
				}
				case Api.SocialMediaType.Instagram: {
					result[x] = new Api.InstagramViewModel(userSession);
					break;
				}
				case Api.SocialMediaType.LinkedIn: {
					result[x] = new Api.LinkedInViewModel(userSession);
					break;
				}
				default: {
					break;
				}
			}
			return result;
		}, {})
	).current;

	// #region Setting Social Platform auth
	const facebookVm = vms[Api.SocialMediaType.Facebook] as Api.FacebookViewModel;
	const instagramVm = vms[Api.SocialMediaType.Instagram] as Api.InstagramViewModel;
	const linkedInVm = vms[Api.SocialMediaType.LinkedIn] as Api.LinkedInViewModel;

	const [connectionResult, setConnectionResult] = React.useState<ISocialMediaConnectResult>(null);

	const onFinish = (connected: boolean, err?: Api.IOperationResultNoValue) => {
		setConnectionResult(null);
		if (err) {
			errorMessages.pushApiError(err);
			logApiError('Facebook-Auth-Error', err);
			return;
		}

		if (connected) {
			setConnectionSuccessModalProps(value => {
				return {
					...value,
					isOpen: true,
				};
			});
		}
	};

	const facebookAuthHandler = useSocialMediaAuthHandler(facebookVm, connectionResult, (_, connected, error) =>
		onFinish(connected, error)
	);
	const instagramAuthHandler = useSocialMediaAuthHandler(instagramVm, connectionResult, (_, connected, error) =>
		onFinish(connected, error)
	);
	const linkedinAuthHandler = useSocialMediaAuthHandler(linkedInVm, connectionResult, (_, connected, error) =>
		onFinish(connected, error)
	);

	// #endregion

	React.useEffect(() => {
		setConnectionSuccessModalProps(value => {
			return {
				...value,
				onRequestClose: () => {
					setConnectionSuccessModalProps(x => {
						return {
							...x,
							isOpen: false,
						};
					});
				},
			};
		});
		// look for the connection result in location state and allow auth handlers to take over
		const splitPath = loc.pathname.split('?');
		const query = fromQuery(splitPath[1]);
		if (query.op === 'connect-result') {
			setConnectionResult(loc.state);
			h.replace({
				pathname: splitPath[0],
				state: null,
			});
		}

		if (expanded) {
			connectionsContainerRef.current?.scrollIntoView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SettingsGroup
			className={`${css(styleSheet.container, ...styles)} social-media-settings ${className || ''}`}
			description='Add or remove your social media accounts.'
			expanded={expanded}
			icon={<SettingsGroupIcon type='SocialMedia' />}
			name='Social Media'
		>
			<SettingsSubGroup title='Connect Social Media Accounts' titleClassName='Social'>
				<div className={css(styleSheet.serviceConnections)} ref={connectionsContainerRef}>
					{Api.SupportedSocialMediaTypes.map(x => {
						const vm = vms[x];
						return vm ? (
							<SocialMediaSettingsServiceConnectionStatus
								key={x}
								viewModel={vm}
								readOnly={userSession?.account?.features?.socialMedia?.readOnly}
							/>
						) : null;
					}).filter(Boolean)}
				</div>
			</SettingsSubGroup>
			{!!connectionResult?.type && (
				<SocialMediaPostTargetSelectorModal
					modalProps={
						connectionResult.type === Api.SocialMediaType.Facebook
							? facebookAuthHandler.socialMediaPostTargetModalProps
							: connectionResult.type === Api.SocialMediaType.Instagram
								? instagramAuthHandler.socialMediaPostTargetModalProps
								: linkedinAuthHandler.socialMediaPostTargetModalProps
					}
					viewModel={
						connectionResult.type === Api.SocialMediaType.Facebook
							? facebookVm
							: connectionResult.type === Api.SocialMediaType.Instagram
								? instagramVm
								: linkedInVm
					}
				/>
			)}
			<SocialMedialConnectionSuccessModal modalProps={connectionSuccessModalProps} />
		</SettingsGroup>
	);
});

export const SocialMediaSettings = inject(ImpersonationContextKey)(SocialMediaSettingsBase);
