export enum DateRangeOption {
	SevenDays = '7 days',
	OneMonth = '1 month',
	ThreeMonths = '3 months',
	SixMonths = '6 months',
	OneYear = '1 year',
	ThreeYears = '3 years',
	Custom = 'Custom Range',
}

export interface IDayOption {
	key: keyof typeof DateRangeOption;
	label: string;
	value: string;
}
