import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import type { Modifier, Modifiers } from 'react-day-picker/types/common';
import {
	calcScheduleCampaignCalendarDateRange,
	convertDurationFromString,
	getScheduleTimeOptions,
	getSendOnBehalfDisabledDays,
	getSendOnBehalfWaitTime,
} from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useInfiniteReportsTextingQuery } from '../../../queries';
import { CampaignDayPicker } from '../../components/CampaignDayPicker';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DefaultSelectBox, ISelectBoxOption } from '../../components/SelectBox';
import { bs } from '../../styles/styles';
import { TextCampaignCompletedListItem } from './TextCampaignCompletedListItem';
import { TextCampaignPendingListItem } from './TextCampaignPendingListItem';
import { styleSheet } from './styles';

const ScheduleSelectBoxOptions: ISelectBoxOption<'now' | 'schedule'>[] = [
	{
		title: 'Send right away',
		value: 'now',
	},
	{
		title: 'Schedule for later',
		value: 'schedule',
	},
];

type DateRange = {
	startDate: Date;
	endDate: Date;
};

type State = {
	dateRange: DateRange;
	selectedDate: Date;
	sendNow: boolean;
};

type Actions =
	| {
			type: 'setDateRange';
			payload: DateRange;
	  }
	| {
			type: 'calendarDaySelected';
			payload: Date;
	  }
	| {
			type: 'scheduleHourChange';
			payload: number;
	  }
	| {
			type: 'setSendNow';
			payload: boolean;
	  };

const reducer = (state: State, action: Actions): State => {
	switch (action.type) {
		case 'setDateRange': {
			return {
				...state,
				dateRange: action.payload,
			};
		}
		case 'calendarDaySelected': {
			const newDate = new Date(action.payload);
			newDate.setHours(state.selectedDate.getHours(), 0, 0);
			return {
				...state,
				selectedDate: newDate,
				sendNow: moment(newDate).isSame(moment(), 'day'), // If the new selectedDate is today, set sendNow to true
			};
		}
		case 'scheduleHourChange': {
			const newDate = new Date(state.selectedDate);
			newDate.setHours(action.payload, 0, 0);
			return {
				...state,
				selectedDate: newDate,
			};
		}
		case 'setSendNow': {
			return {
				...state,
				sendNow: action.payload,
			};
		}
		default: {
			throw new Error('Invalid action type');
		}
	}
};

const NUM_WEEKS_SHOWN_IN_CAL: 4 | 6 = 6;

export function TextingCampaignScheduler({
	initialSelectedDate,
	sendFrom,
	onSendNowClick,
	onScheduleClick,
	isSubmitting,
}: {
	sendFrom: Api.ISendEmailFrom;
	initialSelectedDate?: Date;
	onSendNowClick: () => void;
	onScheduleClick: (date: Date) => void;
	isSubmitting: boolean;
}) {
	const userSession = useUserSession();
	const [state, dispatch] = React.useReducer(reducer, { initialSelectedDate }, init => {
		const selectedDate = init.initialSelectedDate ?? new Date();
		return {
			selectedDate,
			dateRange: calcScheduleCampaignCalendarDateRange({ selectedDate, numWeeks: NUM_WEEKS_SHOWN_IN_CAL }),
			sendNow: moment(selectedDate).isSame(moment(), 'day'),
		};
	});
	const scheduledCampaignsFilter = React.useMemo(() => {
		return {
			criteria: [
				{
					criteria: [
						{
							op: 'Gt',
							property: 'DueDate',
							value: state.dateRange.startDate.toISOString(),
						},
						{
							op: 'Lt',
							property: 'DueDate',
							value: state.dateRange.endDate.toISOString(),
						},
					],
					op: 'And',
				},
			],
			op: 'And',
		} as Api.TextCampaignFilter;
	}, [state.dateRange]);
	const reportsTextingQuery = useInfiniteReportsTextingQuery({
		filter: scheduledCampaignsFilter,
	});
	const completedAndStartedCampaigns = (reportsTextingQuery.data?.pages?.[0].values ?? []).filter(
		campaign => (campaign.status?.status === 'Completed' || campaign.status?.status === 'Started') && campaign.dueDate
	);
	const completedCampaignDates = completedAndStartedCampaigns.map(campaign => new Date(campaign.dueDate!));
	const queuedCampaigns = (reportsTextingQuery.data?.pages?.[0].values ?? []).filter(
		campaign => campaign.status?.status === 'Queued' && campaign.dueDate
	);
	const queuedCampaignDates = queuedCampaigns.map(campaign => new Date(campaign.dueDate!));
	const modifiers = {
		hasCampaign: [...completedCampaignDates, ...queuedCampaignDates],
		hasCompletedCampaign: completedCampaignDates,
		hasQueuedCampaign: queuedCampaignDates,
	} as Omit<Modifiers, 'today' | 'outside'>;
	const minStartDate = sendFrom !== 'CurrentUser' ? getSendOnBehalfDisabledDays(userSession) : null;
	const disabledDays: Modifier[] = [{ before: minStartDate ? minStartDate : new Date() }];
	const businessHours = userSession?.account?.features?.sendEmail;
	if (businessHours?.observeSendIntervals) {
		if (!businessHours?.saturdayInterval?.startMinutes && !businessHours?.saturdayInterval?.endMinutes) {
			disabledDays.push({ daysOfWeek: [6] });
		}
		if (!businessHours?.sundayInterval?.startMinutes && !businessHours?.sundayInterval?.endMinutes) {
			disabledDays.push({ daysOfWeek: [0] });
		}
		if (!businessHours?.fridayInterval?.startMinutes && !businessHours?.fridayInterval?.endMinutes) {
			disabledDays.push({ daysOfWeek: [5] });
		}
	}
	// Compute timeOptions
	// Don't know how this works. Taken from EditSendOptions/timeOptions
	/* ---------- */
	const allOptions = getScheduleTimeOptions(0, userSession, undefined, moment(state.selectedDate));
	let timeOptions = allOptions;
	const nowMoment = moment();
	const days = convertDurationFromString(userSession?.account?.preferences?.sendOnBehalfWaitTime)?.days ?? 2;
	if (
		(sendFrom === 'ContactOwner' || sendFrom === 'SelectedUser') &&
		moment(state.selectedDate).isSame(getSendOnBehalfWaitTime(userSession), 'day') &&
		days !== 2
	) {
		timeOptions = allOptions.filter(({ value }) => {
			const thenMoment = getSendOnBehalfWaitTime(userSession).set(
				'hours',

				value
			);

			return thenMoment.isAfter(getSendOnBehalfWaitTime(userSession));
		});
	} else if (moment(state.selectedDate).isSame(nowMoment, 'day')) {
		timeOptions = allOptions.filter(({ value }) => {
			const thenMoment = moment(nowMoment).set('hours', value).set('minutes', 0);
			return thenMoment.isAfter(nowMoment);
		});
	}
	const validSelectedHour = timeOptions.find(x => x.value === moment(state.selectedDate).get('hours')) ?? {
		isDefault: true,
		title: 'Select a time',
		value: -1,
	};
	/* ---------- */
	const shouldShowScheduleTypeSelector = moment(state.selectedDate).isSame(moment(), 'day') && timeOptions.length > 0;
	const disableSendButton = !state.sendNow && validSelectedHour.isDefault;
	return (
		<>
			<div
				className={css(
					bs.flex,
					bs.itemsCenter,
					bs.gap8,
					bs.borderGray300,
					bs.border0,
					bs.borderB,
					bs.borderSolid,
					bs.h80,
					bs.pt5,
					bs.pb2
				)}
			>
				<CampaignDayPicker
					disabledDays={disabledDays}
					modifiers={modifiers}
					onDayClick={day => {
						dispatch({ type: 'calendarDaySelected', payload: day });
					}}
					onDateRangeChange={dateRange => {
						dispatch({
							type: 'setDateRange',
							payload: dateRange,
						});
					}}
					numWeeksShown={NUM_WEEKS_SHOWN_IN_CAL}
					selectedDay={state.selectedDate}
				/>
				<div className={css(bs.flexGrow, bs.hFull, bs.relative)}>
					<div className={css(bs.hFull, bs.overflowAuto, bs.flex, bs.flexCol, bs.gap2)}>
						{queuedCampaigns.length > 0 ? (
							<div>
								<div className={css(bs.textHeader, bs.textXs, bs.uppercase, bs.mb2)}>Planned Campaigns</div>
								<div className={css(bs.flex, bs.flexCol, bs.gap5)}>
									{queuedCampaigns.map((campaign, i) => (
										<TextCampaignPendingListItem key={campaign.bulkAutomationId} report={campaign} index={i} />
									))}
								</div>
							</div>
						) : null}
						{completedAndStartedCampaigns.length > 0 ? (
							<div>
								<div className={css(bs.textHeader, bs.textXs, bs.uppercase, bs.mb2)}>Completed Campaigns</div>
								<div className={css(bs.flex, bs.flexCol, bs.gap5)}>
									{completedAndStartedCampaigns.map((campaign, i) => (
										<TextCampaignCompletedListItem key={campaign.bulkAutomationId} report={campaign} index={i} />
									))}
								</div>
							</div>
						) : null}
						<div
							className={css(
								bs['top-1-2'],
								bs.absolute,
								bs.transform,
								bs['left-1-2'],
								bs['-translate-x-1-2'],
								bs['-translate-y-1-2']
							)}
						>
							{!reportsTextingQuery.isSuccess ? <LoadingSpinner type='large' /> : null}
							{reportsTextingQuery.isSuccess &&
							queuedCampaigns.length === 0 &&
							completedAndStartedCampaigns.length === 0
								? 'No scheduled texting campaigns'
								: null}
						</div>
					</div>
				</div>
			</div>
			<footer className={css(bs.flex, bs.pt5, bs.px4, bs.pb9)}>
				<time dateTime={state.selectedDate.toLocaleString()} className={css(bs.textLabel, bs.mt2, bs.w40)}>
					{moment(state.selectedDate).format(`ddd[,] MMMM Do `)}
				</time>
				<div className={css(bs.flex, bs.flex1)}>
					<div className={css(bs.flex, bs.itemsCenter, bs.gap2)}>
						{shouldShowScheduleTypeSelector ? (
							<DefaultSelectBox
								className={css(styleSheet.scheduleTypeDropDown)}
								menuPopupPosition='top'
								onSelectionChanged={option => {
									dispatch({ type: 'setSendNow', payload: option.value === 'now' });
								}}
								options={ScheduleSelectBoxOptions}
								optionStyles={[bs.textSm, bs.textLeft]}
								selectedOption={ScheduleSelectBoxOptions.find(x => x.value === (state.sendNow ? 'now' : 'schedule'))}
								triggerStyles={[bs.textSm, bs.hAuto, bs.w40]}
							/>
						) : null}
						{!state.sendNow && timeOptions.length > 0 ? (
							<DefaultSelectBox
								className={css(styleSheet.timeDropdown)}
								menuPopupPosition='top'
								onSelectionChanged={option => {
									if (state.selectedDate && option.value != null) {
										dispatch({ type: 'scheduleHourChange', payload: option.value });
									}
								}}
								options={timeOptions}
								optionStyles={[bs.textSm]}
								placeholder={<span className={css(bs.textGray400)}>Select a time...</span>}
								selectedOption={validSelectedHour}
								triggerStyles={[bs.textSm, bs.hAuto, bs.w36]}
							/>
						) : null}
						<button
							className={css(bs.ctaButton, bs.gap2, state.sendNow && bs.flexGrow)}
							disabled={disableSendButton || isSubmitting}
							onClick={() => {
								if (state.sendNow) {
									onSendNowClick();
									return;
								}
								onScheduleClick(state.selectedDate);
							}}
						>
							{state.sendNow ? 'Send Now' : 'Schedule'}
							{isSubmitting ? <LoadingSpinner type='tiny' /> : null}
						</button>
					</div>
				</div>
			</footer>
		</>
	);
}
