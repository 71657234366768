import { StyleSheet } from 'aphrodite';
import { destructive } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		maxWidth: '100%',
		padding: 24,
	},
	tableHeaderSelectBoxTrigger: {
		border: '1px solid #979797',
		borderRadius: 8,
		height: 30,
		padding: '0 10px',
	},
	tableHeaderSelectBox: {
		marginBottom: 8,
		width: 200,
	},
	tableOverrides: {
		maxWidth: '100%',
		overflow: 'scroll',
		display: 'inline-block',
		minHeight: 400,
	},
	headerTitle: {},
	headerSubtitle: {},
	headerArrow: {},
	tableSampleMessage: {},
	tableHeaderSelectFilter: {},
	errorMessage: {
		color: destructive,
	},
});
