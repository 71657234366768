import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import automationsNotPendingImg from '../../../assets/automations-not-pending.svg';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedSelect, ISelectOption } from '../../DeprecatedSelect';
import { FabContext } from '../../FabContext';
import { MultiContainerHeader } from '../../MultiContainerHeader';
import { AutomationPendingIcon } from '../../svgs/icons/AutomationPendingIcon';
import { AutomationOnHold } from '../AutomationOnHold';
import { AutomationStartedNotification } from '../AutomationStartedNotification';
import { styleSheet } from './styles';

const PendingAutomationOptions: ISelectOption<string>[] = [
	{
		dataContext: 'me',
		id: 'pending-automation-option-mine',
		text: `My Pending Automations`,
	},
	{
		dataContext: 'all',
		id: 'pending-automation-option-all',
		text: `All Pending Automations`,
	},
];

interface IProps
	extends IEventLoggingComponentProps,
		IFullscreenModalComponentProps,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IToasterComponentProps,
		RouteComponentProps<{ resourceSelectorId?: string }> {
	automationsOnHold: Api.AutomationsOnHoldViewModel;
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	selectedShow?: ISelectOption<string>;
	showAutomationStartedNotification?: boolean;
}

class _AutomationsOnHold extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			selectedShow: PendingAutomationOptions.find(x => x.dataContext === 'me'),
		};
	}

	public componentDidMount() {
		this.loadAutomations();
	}

	public render() {
		const { className, styles, automationsOnHold } = this.props;
		const { selectedShow, showAutomationStartedNotification } = this.state;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} bulk-email-edit-body ${className || ''}`}>
				<MultiContainerHeader fullscreenHeader={this.title} />
				<FabContext appearance={{ hidden: true }} />
				{showAutomationStartedNotification ? (
					<AutomationStartedNotification className={css(styleSheet.complete)} />
				) : (
					<>
						<div className={css(styleSheet.headerContainer)}>
							<div className={css(styleSheet.flexBetween)}>
								<div>
									<div className={css(styleSheet.titleContainer)}>
										<AutomationPendingIcon />
										<div className={css(styleSheet.title)}>Pending Automations</div>
									</div>
								</div>
								{this.resourceSelectorId === Api.ResourceSelectorId.AutomationsOnHold && (
									<div className={css(styleSheet.flex)}>
										{this.canSendOnBehalf && (
											<DeprecatedSelect
												onOptionClick={this.onShowChanged}
												options={PendingAutomationOptions}
												optionStyles={[styleSheet.selectOption]}
												styles={[styleSheet.select]}
												selectedOptionTitle={selectedShow.text}
											/>
										)}
										<button
											className={css(baseStyleSheet.ctaButton)}
											disabled={this.disableApproveAll}
											onClick={this.approveAllSelected}
										>
											<span>Approve all selected</span>
										</button>
									</div>
								)}
							</div>
							{this.canSendOnBehalf ? (
								<div className={css(styleSheet.message)}>
									As an admin, you can approve automations for other contact owners on the account. Once approved, the
									automation starts, and the owner will be notified.
								</div>
							) : (
								<div className={css(styleSheet.message)}>
									This account only allows automation approval from the contact owner.
								</div>
							)}
						</div>
						{this.renderAutomationsOnHold()}
					</>
				)}
				{/* trick this component (without an observer) into checking */}
				{/* mobx assumes a component only cares about a property if it is used in render */}
				{automationsOnHold?.noMyContactsLeft && ''}
				{automationsOnHold?.noAllContactsLeft && ''}
			</div>
		);
	}

	@computed
	private get resourceSelectorId() {
		return this.props.match.params.resourceSelectorId;
	}

	@computed
	private get canSendOnBehalf() {
		return (
			this.props.userSession?.account?.features?.automation?.allowAdminToStartOnBehalf &&
			this.resourceSelectorId === Api.ResourceSelectorId.AutomationsOnHold
		);
	}

	@computed
	private get title() {
		const { automationsOnHold } = this.props;

		switch (this.resourceSelectorId) {
			case Api.ResourceSelectorId.NewLeadAutomationsOnHold: {
				return 'New Lead Pending Automations';
			}
			case Api.ResourceSelectorId.NewDonorAutomationsOnHold: {
				return 'New Donor Pending Automations';
			}
			case Api.ResourceSelectorId.NewClientAutomationsOnHold: {
				const automations = automationsOnHold as Api.AutomationsOnHoldViewModel<Api.NewClientType>;
				return `New${
					automations?.context === Api.NewClientType.Any ? '' : ` ${automations?.context}`
				} Client Pending Automations`;
			}
			default: {
				break;
			}
		}
		return 'Pending Automations';
	}

	private renderAutomationsOnHold() {
		const { automationsOnHold } = this.props;
		const { selectedShow } = this.state;
		switch (this.resourceSelectorId) {
			case Api.ResourceSelectorId.AutomationsOnHold: {
				if (this.isShowAllSelected() && this.canSendOnBehalf && automationsOnHold?.allAutomationsPending.length) {
					return (
						<>
							{automationsOnHold?.allAutomationsPending?.map(x => {
								return (
									<AutomationOnHold
										key={x?.automation?.template?.id}
										automation={x}
										includeAll={true}
										onApproveSelected={this.onApproveSelectedClicked}
									/>
								);
							})}
						</>
					);
				} else if (selectedShow.dataContext === 'me' && automationsOnHold?.myAutomationsPending.length) {
					return (
						<>
							{automationsOnHold?.myAutomationsPending?.map(x => {
								return (
									<AutomationOnHold
										key={x?.automation?.template?.id}
										automation={x}
										includeAll={false}
										onApproveSelected={this.onApproveSelectedClicked}
									/>
								);
							})}
						</>
					);
				} else {
					return (
						<div className={css(styleSheet.nonePending)}>
							<div className={css(styleSheet.nonePendingGraphic)}>
								<img src={automationsNotPendingImg} />
							</div>
							<div className={css(styleSheet.nonePendingText)}>You don&apos;t have any pending automations.</div>
						</div>
					);
				}
			}
			case Api.ResourceSelectorId.NewClientAutomationsOnHold:
			case Api.ResourceSelectorId.NewDonorAutomationsOnHold:
			case Api.ResourceSelectorId.NewLeadAutomationsOnHold: {
				return (
					<>
						{automationsOnHold?.allAutomationsPending.map(x => {
							return (
								<AutomationOnHold
									key={x?.automation?.template?.id}
									automation={x}
									includeAll={true}
									onApproveSelected={this.onApproveSelectedClicked}
								/>
							);
						})}
					</>
				);
			}
			default: {
				break;
			}
		}
	}

	private loadAutomations = async () => {
		const { automationsOnHold, errorMessages, logApiError } = this.props;
		const { selectedShow } = this.state;

		switch (this.resourceSelectorId) {
			case Api.ResourceSelectorId.AutomationsOnHold: {
				const includeAll = this.isShowAllSelected();
				const resetAndLoad = (automationOnHold: Api.AutomationOnHoldViewModel) => {
					automationOnHold.resetContactController();
					return automationOnHold.loadContacts(includeAll);
				};

				try {
					await automationsOnHold.loadAutomationsOnHold(includeAll);
					const promises =
						this.canSendOnBehalf && includeAll
							? automationsOnHold.allAutomationsPending.map(x => resetAndLoad(x))
							: automationsOnHold.myAutomationsPending.map(x => resetAndLoad(x));
					await Promise.all(promises);

					// switch to all if 'me' returns an empty array
					if (
						selectedShow?.dataContext === 'me' &&
						this.canSendOnBehalf &&
						automationsOnHold.myAutomationsPending.length === 0
					) {
						this.setState(
							{
								selectedShow: PendingAutomationOptions.find(x => x.dataContext === 'all'),
							},
							this.loadAutomations
						);
					}
				} catch (err) {
					errorMessages.pushApiError(err);

					logApiError('LoadContactsForAutomationPending', err);
				}
				break;
			}
			default: {
				break;
			}
		}
	};

	private onApproveSelectedClicked = (promise: Promise<Api.IOperationResult<Api.IAutomationOnHold[]>>) => {
		promise?.then(() => this.checkShowingStartedNotification());
	};

	private checkShowingStartedNotification = () => {
		const { automationsOnHold } = this.props;
		const { selectedShow } = this.state;
		if (
			(this.isShowAllSelected() && automationsOnHold?.allAutomationsComplete) ||
			(selectedShow.dataContext === 'me' && automationsOnHold?.myAutomationsComplete)
		) {
			this.setState({
				showAutomationStartedNotification: true,
			});
		}
	};

	private onShowChanged = (option: ISelectOption<string>) => {
		this.setState({ selectedShow: option }, this.loadAutomations);
	};

	private approveAllSelected = () => {
		const { automationsOnHold } = this.props;

		const promiseArray = this.isShowAllSelected()
			? automationsOnHold.allAutomationsPending.map(x => x.approveAllButExcludedContacts(true))
			: automationsOnHold.myAutomationsPending.map(x => x.approveAllButExcludedContacts());

		Promise.all(promiseArray)
			?.then(() => this.checkShowingStartedNotification())
			?.catch((err: Api.IOperationResultNoValue) => {
				const { logApiError, errorMessages } = this.props;

				errorMessages.pushApiError(err);

				logApiError('ApproveAllPendingAutomations', err);
			});
	};

	private isShowAllSelected = () => {
		const { selectedShow } = this.state;

		return selectedShow.dataContext === 'all';
	};

	@computed
	private get disableApproveAll() {
		const { automationsOnHold } = this.props;
		const includeAll = this.isShowAllSelected();
		return includeAll
			? automationsOnHold?.allAutomationsPending?.every(x => x.selectedContacts.length === 0)
			: automationsOnHold?.myAutomationsPending?.every(x => x.selectedContacts.length === 0);
	}
}

const AutomationsOnHoldAsObserver = observer(_AutomationsOnHold);
const AutomationsOnHoldWithContext = inject(
	FullScreenModalViewModelKey,
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ToasterViewModelKey
)(AutomationsOnHoldAsObserver);
export const AutomationsOnHold = withEventLogging(AutomationsOnHoldWithContext, 'AutomationsOnHold');
