import { IOperationResultNoValue, TagOwnerReportSystemJobViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { OwnerTagReportImageIcon } from '../../../components/svgs/icons/OwnerTagReportImageIcon';
import { baseStyleSheet } from '../../../styles/styles';
import './styles.less';

interface IProps
	extends RouteComponentProps<any>,
		IErrorMessageComponentProps,
		IEventLoggingComponentProps,
		IUserSessionComponentProps {
	className?: string;
	reportId: string;
}

interface IState {
	reportUrl?: string;
	viewModel?: TagOwnerReportSystemJobViewModel;
}

class _OwnerTagReport extends React.Component<IProps, IState> {
	public UNSAFE_componentWillMount() {
		const { userSession, reportId } = this.props;

		const viewModel = TagOwnerReportSystemJobViewModel.createWithOwnerReportId(userSession, reportId);
		this.setState({
			viewModel,
		});

		viewModel.getReportPdfDownloadUrl()?.catch((error: IOperationResultNoValue) => {
			this.handleError(error, 'Load');
		});
	}

	public render() {
		const { className } = this.props;
		const { viewModel } = this.state;

		return (
			<div className={`owner-tag-report ${className || ''}`}>
				{!!viewModel.isBusy && <LoadingSpinner type='large' className='owner-tag-report-loader' />}

				{!!viewModel.ownerReportPdfDownloadUrl && (
					<>
						<OwnerTagReportImageIcon className='owner-tag-report-graphic' />
						<a
							className={`${css(baseStyleSheet.ctaButton)} owner-tag-report-download`}
							target='_blank'
							href={viewModel.ownerReportPdfDownloadUrl}
							onClick={this.onDownloadClick}
							rel='noreferrer'
						>
							Download Monthly Owner&apos;s Report
						</a>
					</>
				)}
			</div>
		);
	}

	private handleError = (error: IOperationResultNoValue, action: string) => {
		const { logEvent, errorMessages } = this.props;

		logEvent(`${action}-Error`, { ...error });

		errorMessages.push({
			messages: [error.systemMessage],
		});
	};

	private onDownloadClick = () => {
		this.props.logEvent('Download');
	};
}

const OwnerTagAsObserver = observer(_OwnerTagReport);
const OwnerTagReportWithLogging = withEventLogging(OwnerTagAsObserver, 'OwnerTagReport');
const OwnerTagReportWithRouter = withRouter(OwnerTagReportWithLogging);
const OwnerTagReportInjected = inject(UserSessionViewModelKey, ErrorMessagesViewModelKey)(OwnerTagReportWithRouter);
export const OwnerTagReport = OwnerTagReportInjected;
