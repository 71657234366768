import { StyleSheet } from 'aphrodite';
import { label, white } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	contentSection: {
		padding: '12px 20px',
	},
	contentAddButton: {
		marginLeft: 'auto',
	},
	contentHeader: {
		display: 'flex',
		gap: 8,
	},
	opportuntitiesIcon: {
		fill: white,
	},
	contentListTitle: {
		marginTop: 8,
		marginBottom: 24,
	},
	cardList: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
	},
	item: {
		flexBasis: '32%',
		minWidth: '32%',
	},
	placeholder: {
		marginTop: '10%',
	},
	boardIconContainer: {
		height: 40,
		width: 40,
		minWidth: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
	},
	emptyText: {
		color: label,
		fontSize: 14,
	},
});
