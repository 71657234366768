import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { IMoveBoardItem, IOpportunity } from '../../../../extViewmodels/sdk/models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { getDisplayName, openUrlInNewTab } from '../../../../models/UiUtils';
import {
	invalidateArchivedOpportunities,
	useArchivedOpportunities,
} from '../../../../queries/Boards/useArchivedOpportunities';
import { useArchiveOpportunity } from '../../../../queries/Boards/useArchiveOpportunity';
import { invalidateAllBoardStageItems } from '../../../../queries/Boards/useBoardStageItems';
import { useDeleteOpportunity } from '../../../../queries/Boards/useDeleteOpportunity';
import { invalidateAllGetBoardItems } from '../../../../queries/Boards/useGetBoardItems';
import { useRestoreArchivedOpportunity } from '../../../../queries/Boards/useRestoreArchivedOpportunity';
import { Button } from '../../../components/Button';
import { PlusIcon } from '../../../components/svgs/icons/PlusIcon';
import { useOpportunitiesContext } from '../../../containers/dataBoards/OpportunitiesBoard/context';
import { baseStyleSheet } from '../../../styles/styles';
import {
	KanbanBoard,
	KanbanBoardCard,
	KanbanBoardCardContent,
	KanbanBoardCardTitle,
	KanbanBoardColumn,
	KanbanBoardColumnFooter,
	KanbanBoardColumnFooterContent,
	KanbanBoardColumnHeader,
	KanbanBoardColumnTitle,
} from '../../KanbanBoard';
import { MoreMenu, MoreMenuItem } from '../../MoreMenu';
import { styleSheet } from './styles';

interface OpportunityKanbanBoardProps {
	onAddCardClicked: (stage: Api.IBoardStage, force: boolean) => void;
	onCardDrop: (opportunity: Api.IOpportunity, moveBoardItem: IMoveBoardItem) => Promise<void>;
	onColumnDrop: (sourceIndex: number, targetIndex: number) => void;
}

const formatCurrency = (value: number) => {
	return value.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

export const OpportunityKanbanBoard: React.FC<OpportunityKanbanBoardProps> = ({
	onAddCardClicked,
	onCardDrop,
	onColumnDrop,
}) => {
	const { board, opportunities, isColumnDraggingEnabled, showArchived, selectedStage } = useOpportunitiesContext();
	const userSession = useUserSession();
	const [localOpportunities, setLocalOpportunities] = React.useState<IOpportunity[]>(opportunities ?? []);

	const archivedOpportunitiesQuery = useArchivedOpportunities({
		enabled: showArchived && !!board?.id,
		boardId: board?.id,
		refetchInterval: 5000,
	});
	const archivedOpportunities = React.useMemo(
		() => archivedOpportunitiesQuery.data?.pages.flatMap(page => page.values) ?? [],
		[archivedOpportunitiesQuery.data]
	);
	const restoreMutation = useRestoreArchivedOpportunity({
		onSuccess: () => {
			invalidateArchivedOpportunities();
			invalidateAllBoardStageItems();
		},
	});

	React.useEffect(() => {
		const tempSortedOpportunities = [...(opportunities ?? []), ...archivedOpportunities];
		const sortedOpportunities = tempSortedOpportunities.sort((a, b) => {
			const dateA = a.creationDate ? new Date(a.creationDate).getTime() : 0;
			const dateB = b.creationDate ? new Date(b.creationDate).getTime() : 0;
			return dateB - dateA;
		});
		setLocalOpportunities(sortedOpportunities);
	}, [opportunities, archivedOpportunities]);

	const handleCardDrop = async (opportunity: Api.IOpportunity, moveBoardItem: IMoveBoardItem) => {
		await onCardDrop(opportunity, moveBoardItem);

		invalidateAllBoardStageItems();
	};

	const columns = React.useMemo(() => {
		let tempStages = board?.stages.map(stage => ({
			stage,
			opportunities: localOpportunities
				.filter(opp => opp.boardStage?.id === stage.id && !opp.isArchived)
				.sort((a, b) => a.position - b.position),
		}));
		if (showArchived) {
			tempStages.push({
				stage: { id: 'archived', name: 'Archived' },
				opportunities: localOpportunities.filter(opp => opp.isArchived),
			});
		}
		if (selectedStage) {
			tempStages = tempStages.filter(column => column.stage.id === selectedStage.id);
		}

		return tempStages;
	}, [board?.stages, localOpportunities, showArchived, selectedStage]);

	const calculateColumnTotal = (columnOpportunities: IOpportunity[]) => {
		return columnOpportunities.reduce((sum, opp) => sum + (opp.dealSize || 0), 0);
	};

	const handleColumnDrop = (sourceIndex: number, targetIndex: number) => {
		onColumnDrop(sourceIndex, targetIndex);
	};

	const onEntityClicked = (contactId: string, e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
		const path = `${window.location.origin}/#/people/${contactId}`;
		if (e.ctrlKey || e.metaKey) {
			openUrlInNewTab(path);
			e.preventDefault();
		} else {
			window.location.href = path;
		}
	};
	const { setOpportunityToEdit, setOpportunityToMove } = useOpportunitiesContext();

	const archiveMutation = useArchiveOpportunity({
		onSuccess: () => {
			invalidateAllGetBoardItems();
			invalidateArchivedOpportunities();
		},
	});

	const deleteMutation = useDeleteOpportunity({
		onSuccess: () => invalidateAllGetBoardItems(),
	});

	const handleClickToArchive = (opportunity: Api.IOpportunity) => {
		archiveMutation.mutate({ itemId: opportunity.id });
	};

	const handleClickToRestore = (opportunity: Api.IOpportunity) => {
		restoreMutation.mutate({ itemId: opportunity.id, stageId: opportunity.boardStage?.id });
	};

	const handleClickToDelete = (opportunity: Api.IOpportunity) => {
		deleteMutation.mutate({ itemId: opportunity.id });
	};

	const handleClickToEdit = (opportunity: Api.IOpportunity) => {
		if (opportunity.isArchived) {
			handleClickToRestore(opportunity);
		} else {
			setOpportunityToEdit(opportunity);
		}
	};

	const handleClickToMove = (opportunity: Api.IOpportunity) => {
		setOpportunityToMove(opportunity);
	};

	return (
		<KanbanBoard>
			{columns.map((column, columnIndex) => (
				<KanbanBoardColumn
					key={column.stage.id}
					id={column.stage.id}
					index={columnIndex}
					enableDragging={isColumnDraggingEnabled}
					onColumnDrop={handleColumnDrop}
					data={column}
				>
					<KanbanBoardColumnHeader>
						<KanbanBoardColumnTitle>{column.stage.name}</KanbanBoardColumnTitle>
						<Button
							kind='reverse'
							size='small'
							className={css(styleSheet.addCardButton)}
							onClick={() => onAddCardClicked(column.stage, false)}
						>
							<PlusIcon /> Add
						</Button>
					</KanbanBoardColumnHeader>

					{column.opportunities.map((opportunity, cardIndex) => {
						const owner = opportunity.assignees[0];
						const contactVm = new Api.ContactViewModel(userSession, opportunity.primaryContact);

						return (
							<KanbanBoardCard
								key={opportunity.id}
								id={opportunity.id}
								index={cardIndex}
								onCardDrop={handleCardDrop}
								data={opportunity}
							>
								<KanbanBoardCardContent>
									<KanbanBoardCardTitle
										name={opportunity.name}
										link={`/dataBoards/opportunities/${board.id}/${opportunity.id}/`}
									>
										<MoreMenu>
											<>
												{!opportunity.isArchived ? (
													<MoreMenuItem onClick={() => handleClickToArchive(opportunity)}>Archive</MoreMenuItem>
												) : null}

												{handleClickToMove ? (
													<MoreMenuItem onClick={() => handleClickToMove(opportunity)}>Move to Column</MoreMenuItem>
												) : null}

												{handleClickToEdit ? (
													<MoreMenuItem onClick={() => handleClickToEdit(opportunity)}>
														{opportunity.isArchived ? 'Restore' : 'Edit'}
													</MoreMenuItem>
												) : null}

												<MoreMenuItem onClick={() => handleClickToDelete(opportunity)}>Permanently Delete</MoreMenuItem>
											</>
										</MoreMenu>
									</KanbanBoardCardTitle>

									<div className={css(styleSheet.cardDetails)}>
										<div className={css(styleSheet.personDetails)}>
											<label className={css(styleSheet.personDetailsLabel)}>Contact:</label>
											{contactVm ? (
												<a
													href={`#/people/${opportunity.primaryContact.id}`}
													className={css(styleSheet.contactName, baseStyleSheet.truncateText)}
													onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
														onEntityClicked(opportunity.primaryContact.id, e)
													}
												>
													{getDisplayName(opportunity.primaryContact)}
												</a>
											) : (
												'No Contact'
											)}
										</div>
										<div className={css(styleSheet.personDetails)}>
											<label className={css(styleSheet.personDetailsLabel)}>Owner:</label>
											<span className={css(styleSheet.personDetailsValue, baseStyleSheet.truncateText)}>
												{getDisplayName(owner) || 'No owner'}
											</span>
										</div>

										<footer className={css(styleSheet.footer)}>
											<div className={css(styleSheet.closeDate)}>
												Close date: {opportunity.closeDate ? new Date(opportunity.closeDate).toLocaleDateString() : '-'}
											</div>

											{opportunity.dealSize ? (
												<div className={css(styleSheet.dealSize)}>{formatCurrency(opportunity.dealSize)}</div>
											) : null}
										</footer>
									</div>
								</KanbanBoardCardContent>
							</KanbanBoardCard>
						);
					})}

					<KanbanBoardColumnFooter>
						<KanbanBoardColumnFooterContent>
							<div className={css(styleSheet.columnFooter)}>
								<div>
									<label>Amount:</label>
									<span className={css(styleSheet.dealSize)}>
										{formatCurrency(calculateColumnTotal(column.opportunities))}
									</span>
								</div>
								<div>
									<label>Showing:</label>
									<span className={css(styleSheet.totalValue)}>{column.opportunities.length}</span>
								</div>
							</div>
						</KanbanBoardColumnFooterContent>
					</KanbanBoardColumnFooter>
				</KanbanBoardColumn>
			))}
		</KanbanBoard>
	);
};
