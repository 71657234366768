import * as Api from '@ViewModels';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../../models/AppState';
import { useToaster } from '../../../../../models/hooks/appStateHooks';
import { sortEmailSignaturesByDefault } from '../../../../../models/UiUtils';
import { SignatureToolbarItem } from '../../../email/SignatureToolbarItem';

interface EmailSignatureComposerProps extends IImpersonationContextComponentProps, IUserSessionComponentProps {
	forUser?: Api.IUser;
	initialSignatureTemplate?: Api.ITemplate | null | undefined;
}

export const EmailSignatureComposer = inject(
	ImpersonationContextKey,
	UserSessionViewModelKey
)(
	observer(function EmailSignatureComposer({
		impersonationContext,
		forUser,
		initialSignatureTemplate,
		userSession,
	}: EmailSignatureComposerProps) {
		const toaster = useToaster();

		const [selectedTemplate, setSelectedTemplate] = React.useState<Api.ITemplate>(initialSignatureTemplate);
		const [emailSignatures, setEmailSignatures] = React.useState<Api.ITemplate[]>();
		const selectedTemplateRef = React.useRef(selectedTemplate);
		selectedTemplateRef.current = selectedTemplate;

		const templatesRef = React.useRef(
			new Api.TemplatesViewModel(userSession, {
				// cannot use impersonationContext and `forUserId` at the same time
				forUserId: !impersonationContext?.isValid ? forUser?.id : undefined,
			}).impersonate(impersonationContext)
		);

		const reloadTemplates = React.useCallback(() => {
			templatesRef.current?.reset();
			templatesRef.current?.signatureTemplates.getNext()?.then(() => {
				const signatures = sortEmailSignaturesByDefault(
					templatesRef.current.signatureTemplates.fetchResults?.toArray()
				);

				const defaultTemplate = signatures?.find(x => x.defaultTemplate);
				const currentSelectedTemplate = selectedTemplateRef.current;

				let nextSelectedEmailSignature = signatures
					? currentSelectedTemplate?.id
						? signatures.find(x => x.id === currentSelectedTemplate.id)
						: undefined
					: currentSelectedTemplate;

				if (!nextSelectedEmailSignature) {
					nextSelectedEmailSignature = defaultTemplate ?? signatures[0];
				}
				setSelectedTemplate(nextSelectedEmailSignature);
				setEmailSignatures(signatures);
			});
		}, []);

		React.useEffect(() => {
			reloadTemplates();
		}, [reloadTemplates]);

		const onEmailSignatureSaved = (emailTemplate: Api.ITemplate) => {
			toaster.push({
				message: `${emailTemplate.name} saved`,
				type: 'successMessage',
			});
			reloadTemplates();
		};

		return (
			<SignatureToolbarItem
				forUser={forUser}
				hideAddNew={true}
				onEmailSignatureSaved={onEmailSignatureSaved}
				shouldEditInline={true}
				templates={templatesRef.current}
				selectedEmailSignature={selectedTemplate}
				onEmailSignatureSelected={setSelectedTemplate}
				emailSignatures={emailSignatures}
			/>
		);
	})
);
