import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import produce from 'immer';
import { action } from 'mobx';
import { Observer, inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import {
	ICampaignsCreatedNotificationInfo,
	IContactSelection,
	ICreateCampaignRequest,
	IImpersonationContextComponentProps,
	ILocationState,
	IModalContext,
	ModalChildComponentContextKey,
	TemplateOrTemplateFilter,
} from '../../../../models';
import * as AppState from '../../../../models/AppState';
import { EmailComposerContext, IEmailComposerContext, RenewalEmailContextProvider } from '../../../../models/Email';
import { Topics } from '../../../../models/LocalNotificationTopics';
import {
	getDefaultBulkMessagingBodyEditorState,
	getKeyDateKindFromResourceSelectorId,
	getResourceSelectorIdDisplayName,
} from '../../../../models/UiUtils';
import { invalidateAllInfiniteCampaignReportQueries } from '../../../../queries';
import {
	ComposeEmailViewModel,
	ComposeEventRegistrationSurveyFollowUpEmailViewModel,
	ComposeFollowUpEmailViewModel,
	ComposeResourceEmailViewModel,
	ComposeSatisfactionSurveyFollowUpEmailViewModel,
	ComposeSurveyFollowUpEmailViewModel,
	ContactFilterCriteriaProperty,
	ContactViewModel,
	ContentCalenderSuggestionViewModel,
	FilterOperator,
	IAppToastMessage,
	IDashboardReachOutInfo,
	IReachOutInfo,
	KeyDateKind,
	ResourceSelectorId,
	ScheduleCriteria,
	SendEmailFrom,
	TagAlertViewModel,
	TagViewModel,
	TemplatesViewModel,
} from '../../../../viewmodels/AppViewModels';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { DeprecatedCloseButton } from '../../../components/DeprecatedCloseButton';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../../components/LocalNotificationObserver/WithNotificationService';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { PrivateRoute } from '../../../components/PrivateRoute';
import { EmailSuccessToast } from '../../../components/Toaster/EmailSuccessToast';
import { ComplianceApprovalPrompt } from '../../../components/campaigns/ComplianceApprovalPrompt';
import { ComplianceConfirmation } from '../../../components/campaigns/ComplianceConfirmation';
import { KeyDateSendOptions } from '../../../components/email/KeyDateSendOptions';
import { SendSchedulerCompleteOverlay } from '../../../components/email/SendSchedulerCompleteOverlay';
import { ScheduledEmailErrorModal } from '../../../components/errorMessages/ScheduledEmailErrorModal';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { SuggestedCampaignComposer } from '../../SuggestedCampaignComposer';
import { EditCampaign, IEditCampaignComponent } from '../EditCampaign';
import { EditSendOptions } from '../EditSendOptions';
import { ManageEmailTemplates } from '../ManageEmailTemplates';
import { styleSheet } from './styles';

interface IProps
	extends AppState.IToasterComponentProps,
		IEventLoggingComponentProps,
		RouteComponentProps<any>,
		AppState.IUserSessionComponentProps,
		AppState.IFullscreenModalComponentProps,
		IImpersonationContextComponentProps,
		IModalContext<any>,
		INotificationServiceComponentProps<Api.EmailMessageViewModel<File> | ICampaignsCreatedNotificationInfo>,
		AppState.IErrorMessageComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	resourceEmailComposer?: ComposeResourceEmailViewModel;
	onAutomationsCreated?(result: Api.ICreateAutomationsResult): void;
	emailComposer?: ComposeEmailViewModel<any, any>;
}

interface IState {
	composerContext?: IEmailComposerContext;
	createByContentCalendar?: boolean;
	createdByBrowseAndSendCTA?: boolean;
	emailBodyTemplate?: TemplateOrTemplateFilter;
	emailComposer?: ComposeEmailViewModel<any, any>;
	estimate?: Partial<Api.IEmailSendEstimate>;
	hasChanges?: boolean;
	initialStartMoment?: moment.Moment;
	loading?: boolean;
	minStartDate?: Date;
	replacingHistoryDontRender?: boolean;
	scheduleError?: Api.IOperationResultNoValue;
	selectedAutomationTemplate?: Api.AutomationTemplateViewModel;
	showingCloseConfirmation?: boolean;
	showingOmitConfirmation?: boolean;
	showingOmitConfirmationBeforeSend?: boolean;
	showingOmitConfirmationBeforeEditSend?: boolean;
	showTemplateSelectDuringEdit?: boolean;
	selectedMoment?: moment.Moment;
	sendNow?: boolean;
}

class _BrowseAndSendCampaigns extends React.Component<IProps, IState> {
	private static MinSendSystemJobRecordCountForOverlay = 5;
	private mInitialContactSelection: IContactSelection;
	private mCampaignCreateRequest: ICreateCampaignRequest;
	private mEditCampaignComponent: IEditCampaignComponent;

	constructor(props: IProps) {
		super(props);
		this.state = {
			composerContext: {
				sendTestEmail: this.sendTestEmail,
			},
			createByContentCalendar: false,
			createdByBrowseAndSendCTA: false,
			loading: true,
			replacingHistoryDontRender: false,
		};
	}

	public componentDidMount = async () => {
		const { match, location, userSession, history } = this.props;
		const { composerContext } = this.state;

		switch (location.pathname) {
			case `${match.url}/new-campaign`: {
				// if the user clicks back, show the manage templates screen
				this.setState({ replacingHistoryDontRender: true });
				history.replace(`${match.url}/browse`);
				this.setState({ createdByBrowseAndSendCTA: true }, () => this.onTemplateSelected());
				break;
			}
			case `${match.url}/survey-follow-up/from-report`: {
				const locationState: ILocationState<Api.SurveyReportViewModel, any> = location.state;
				let emailComposer: ComposeSurveyFollowUpEmailViewModel = null;
				if (locationState.viewModel instanceof Api.SatisfactionSurveyReportViewModel) {
					// TODO: This is not an allowed type to pass in

					// @ts-ignore
					emailComposer = this.configureEmailComposer(
						new ComposeSatisfactionSurveyFollowUpEmailViewModel(userSession, locationState.viewModel),
						null,
						false
					);
					emailComposer.emailMessage.options.followUp = {
						_type: 'SurveyFollowUpOptions',
						surveyResponseFilter: {
							surveyId: locationState.viewModel.survey.id,
							_type: 'SatisfactionSurveyResponseFilterRequest',
						},
					};
				} else if (locationState.viewModel instanceof Api.EventSurveyReportViewModel) {
					// TODO: This is not an allowed type to pass in

					// @ts-ignore
					emailComposer = this.configureEmailComposer(
						new ComposeEventRegistrationSurveyFollowUpEmailViewModel(userSession, locationState.viewModel),
						null,
						false
					);
					emailComposer.emailMessage.options.followUp = {
						_type: 'SurveyFollowUpOptions',
						surveyResponseFilter: {
							surveyId: locationState.viewModel.survey.id,
							_type: 'EventRegistrationSurveyResponseFilterRequest',
						},
					};
				}
				emailComposer.emailMessage.content = getDefaultBulkMessagingBodyEditorState().getRawRichTextContent();
				this.setState({
					composerContext: {
						...composerContext,
						emailComposer,
					},
					emailComposer,
				});
				break;
			}
			case `${match.url}/campaign-follow-up`: {
				const locationState: ILocationState<Api.CampaignViewModel, any> = location.state;
				const emailComposer = this.configureEmailComposer(
					new ComposeFollowUpEmailViewModel<Api.IEmailMessageFollowUpOptions, Api.CampaignViewModel>(
						userSession,
						locationState.viewModel
					),
					null,
					false
				);
				if (locationState.viewModel.sendToHousehold) {
					emailComposer.emailMessage.groupByHousehold = true;
				}
				emailComposer.emailMessage.content = getDefaultBulkMessagingBodyEditorState().getRawRichTextContent();
				if (locationState.viewModel?.id) {
					emailComposer.emailMessage.options.followUp = {
						_type: 'EmailFollowUpOptions',
						bulkEmailMessageId: locationState.viewModel.id,
					};
				}
				this.setState({
					composerContext: {
						...composerContext,
						emailComposer,
					},
					emailComposer,
				});
				break;
			}
			case `${match.url}/from-scratch`: {
				const locationState: ILocationState<any, ICreateCampaignRequest<IContactSelection>> = location.state;
				this.mCampaignCreateRequest = locationState?.model;
				this.mInitialContactSelection = locationState?.model?.context;
				const emailComposer = this.configureEmailComposer(new ComposeEmailViewModel(userSession), null, false);
				emailComposer.emailMessage.content = getDefaultBulkMessagingBodyEditorState().getRawRichTextContent();
				this.setState({
					composerContext: {
						...composerContext,
						emailComposer,
					},
					emailComposer,
					showTemplateSelectDuringEdit: true,
				});
				break;
			}
			case `${match.url}/from-selection`: {
				const locationState: ILocationState<any, ICreateCampaignRequest<IContactSelection>> = location.state;
				this.mCampaignCreateRequest = locationState?.model;
				this.mInitialContactSelection = locationState?.model?.context;
				break;
			}
			case `${match.url}/from-filter`: {
				const locationState: ILocationState<any, ICreateCampaignRequest<IContactSelection>> = location.state;
				this.mCampaignCreateRequest = locationState?.model;
				this.mInitialContactSelection = locationState?.model?.context;
				break;
			}
			case `${match.url}/from-template`: {
				const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = location.state;
				const canSendOnBehalf =
					userSession?.user?.role?.toLocaleLowerCase()?.includes('admin') &&
					userSession?.account?.preferences?.sendOnBehalfEnabled;
				const queryTags = locationState?.model?.tags;
				const tagsToCheck = typeof queryTags === 'string' ? [queryTags] : Array.isArray(queryTags) ? queryTags : [];
				const tagPromises = tagsToCheck.map(x => {
					const tagVM = new TagViewModel(userSession, { tag: x });
					return tagVM.load()?.catch(Api.VmUtils.Noop);
				});

				const result = await Promise.all(tagPromises);
				const foundTags = result
					?.filter(x => !!x)
					?.map((x: any) => {
						if ('tag' in x) {
							return x.tag;
						}
					});
				this.mCampaignCreateRequest = locationState?.model;
				this.mInitialContactSelection = {
					allowSenderSelection: canSendOnBehalf,
					bulkFilterRequest: {
						contactFilterRequest: {
							criteria: [
								{
									criteria: [
										...(foundTags?.map(
											x =>
												({
													op: FilterOperator.Or,
													property: ContactFilterCriteriaProperty.Tag,
													value: x,
												}) as Api.IContactFilterCriteria
										) || []),
									],
									op: FilterOperator.Or,
								},
							].filter(Boolean) as Api.IContactFilterCriteria[], // Update the type here
						},
						ownershipFilter: !userSession!.account.preferences?.showAllContactsByDefault
							? {
									criteria: {
										property: ContactFilterCriteriaProperty.OwnedBy,
										value: userSession.user.id,
									},
								}
							: null,
					},
				};

				// Default to 11am as it is known to be a successful send time
				const initialStartMoment = locationState.model.schedule?.startDate
					? moment(locationState.model.schedule.startDate).set('hours', 11)
					: null;
				const nextState: IState = {
					createByContentCalendar: locationState?.referrer === 'contentCalendar',
					initialStartMoment,
				};

				if (typeof locationState?.model?.context === 'object') {
					const emailBodyTemplate: Api.ITemplate = locationState.model.context;
					const emailComposer = this.configureEmailComposer(
						new ComposeEmailViewModel(userSession),
						emailBodyTemplate,
						true
					);
					emailComposer.emailMessage.setSendEmailFromUser(userSession.user);
					this.setState({
						...nextState,
						composerContext: {
							...composerContext,
							emailComposer,
						},
						emailBodyTemplate,
						emailComposer,
					});
				} else {
					this.setState({
						...nextState,
						emailBodyTemplate: locationState?.model?.context,
					});
				}

				if (foundTags.length) {
					history.push(`${match.url}/edit/recipients`);
				}
				break;
			}
			case `${match.url}/from-resource-selector`: {
				const locationState: ILocationState<Api.ITemplate, Api.IUpcomingKeyDateDashboardCard> = location.state;
				const selector = locationState?.model?.type;
				const qualifier = locationState?.model?.qualifier;
				const template = locationState?.viewModel;
				const emailBodyTemplate: Api.ITemplate = typeof template === 'object' ? template : null;
				const emailComposer = ComposeResourceEmailViewModel.instanceForResourceSelector(
					userSession,
					selector,
					qualifier,
					emailBodyTemplate
				);

				if (emailComposer.canSendOnBehalf) {
					emailComposer.setSendEmailFrom(Api.SendEmailFrom.ContactOwner);
				}

				emailComposer.updateApproximationForFilter()?.catch((err: Api.IOperationResultNoValue) => {
					const { logApiError } = this.props;
					logApiError('updateApproximationForFilter-Error', err);
				});

				emailComposer.loadAlternateResourceSelectorTemplates()?.catch((err: Api.IOperationResultNoValue) => {
					const { logApiError } = this.props;
					logApiError('LoadAlternateResourceSelectorTemplates-Error', err);
				});

				this.setState({
					composerContext: {
						...composerContext,
						emailComposer,
						flow: selector === Api.ResourceSelectorId.PolicyRenew ? Api.ResourceSelectorId.PolicyRenew : null,
					},
					emailBodyTemplate,
					emailComposer,
				});
				break;
			}
			case `${match.url}/from-actionable-resource-selector`: {
				const locationState: ILocationState<Api.ITemplate, Api.IActionableDashboardCard> = location.state;
				const selector = locationState?.model?.type;
				const qualifier = locationState?.model?.qualifier;
				const template = locationState?.viewModel;
				const emailBodyTemplate: Api.ITemplate = typeof template === 'object' ? template : null;
				const emailComposer = ComposeResourceEmailViewModel.instanceForResourceSelector(
					userSession,
					selector,
					qualifier,
					emailBodyTemplate
				);

				if (emailComposer.canSendOnBehalf) {
					emailComposer.setSendEmailFrom(Api.SendEmailFrom.ContactOwner);
				}

				emailComposer.updateApproximationForFilter()?.catch((err: Api.IOperationResultNoValue) => {
					const { logApiError } = this.props;
					logApiError('updateApproximationForFilter-Error', err);
				});

				emailComposer.loadAlternateResourceSelectorTemplates()?.catch((err: Api.IOperationResultNoValue) => {
					const { logApiError } = this.props;
					logApiError('LoadAlternateResourceSelectorTemplates-Error', err);
				});

				this.setState({
					composerContext: {
						...composerContext,
						emailComposer,
						flow: selector === Api.ResourceSelectorId.PolicyRenew ? Api.ResourceSelectorId.PolicyRenew : null,
					},
					emailBodyTemplate,
					emailComposer,
				});
				break;
			}
			case `${match.url}/from-reach-out-info`: {
				const locationState: ILocationState<any, ICreateCampaignRequest<IReachOutInfo>> = location.state;
				this.mCampaignCreateRequest = locationState?.model;
				const emailComposer = this.configureEmailComposer(new ComposeEmailViewModel(userSession), null, false);
				emailComposer.emailMessage.content = getDefaultBulkMessagingBodyEditorState().getRawRichTextContent();
				const context = locationState?.model?.context;
				const reachOut: IDashboardReachOutInfo = {
					contactsWithKeepInTouchesDue: context?.keepInTouchContacts?.map(
						x => new ContactViewModel(this.props?.userSession, x)
					),
					tagAlerts: context?.countByTag?.map(
						tag =>
							new TagAlertViewModel(this.props?.userSession, {
								tag: tag.tag,
							})
					),
					totalCount: context?.keepInTouchCount + context?.tagAlertCount,
				};
				emailComposer.reachOutInfo = reachOut;
				emailComposer.emailMessage.contactsToAdd.addAll(reachOut.contactsWithKeepInTouchesDue);
				this.setState({
					composerContext: {
						...composerContext,
						emailComposer,
					},
					emailComposer,
					showTemplateSelectDuringEdit: true,
				});
				break;
			}
			case `${match.url}/from-browse-and-send`: {
				this.setState({ createdByBrowseAndSendCTA: true });
				break;
			}
			case `${match.url}/from-suggested-campaign`: {
				const locationState: ILocationState<ContentCalenderSuggestionViewModel, ContentCalenderSuggestionViewModel> =
					this.props.history.location.state;
				const emailComposer = ComposeEmailViewModel.instanceForSuggestion(locationState?.viewModel);
				this.setState({
					composerContext: {
						...composerContext,
						emailComposer,
					},
					emailBodyTemplate: locationState?.viewModel?.template,
					emailComposer,
				});
				break;
			}

			case `${match.url}/from-queued-campaign`: {
				// TODO: {groupId:string} is not valid, ILocationState takes in 2 props

				// @ts-ignore
				const locationState: ILocationState<{ groupId: string }> = location.state;
				const emailComposer = await ComposeEmailViewModel.loadInstanceForCampaign(
					userSession,
					undefined,
					undefined,
					undefined,
					locationState?.groupId
				);

				this.setState({
					composerContext: {
						...composerContext,
						emailComposer,
					},
					emailComposer,
				});
				break;
			}
			default: {
				break;
			}
		}
		this.setState({
			loading: false,
		});
	};

	public render() {
		const { className, styles, match, location, loggingCategory, userSession } = this.props;
		const {
			showingCloseConfirmation,
			showingOmitConfirmation,
			showingOmitConfirmationBeforeSend,
			showingOmitConfirmationBeforeEditSend,
			emailComposer,
			scheduleError,
			loading,
			composerContext,
		} = this.state;

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} browse-and-send-campaigns ${className || ''}`}>
				<EmailComposerContext.Provider value={composerContext}>
					<RenewalEmailContextProvider
						eventLoggingCategory={loggingCategory}
						emailComposer={this.resourceSelectorEmailComposer}
					>
						{this.renderFullscreenHeader()}
						{loading ? (
							<div className={css(styleSheet.loaderContainer)}>
								<LoadingSpinner type='large' />
							</div>
						) : (
							<>
								<Switch>
									<Redirect
										from={`${match.url}/from-selection`}
										to={{
											pathname: `${match.url}/browse`,
											state: location.state,
										}}
									/>
									<Redirect from={`${match.url}/from-key-date`} to={`${match.url}/edit/recipients`} />
									<Redirect from={`${match.url}/from-resource-selector`} to={`${match.url}/edit/recipients`} />
									<Redirect
										from={`${match.url}/from-actionable-resource-selector`}
										to={`${match.url}/edit/recipients`}
									/>
									<Redirect from={`${match.url}/from-filter`} to={`${match.url}/browse`} />
									{this.renderFromTemplateRedirect()}
									<Redirect from={`${match.url}/from-reach-out-info`} to={`${match.url}/edit/tags`} />
									<Redirect from={`${match.url}/from-scratch`} to={`${match.url}/edit/recipients`} />
									<Redirect from={`${match.url}/from-browse-and-send`} to={`${match.url}/browse`} />
									<Redirect from={`${match.url}/from-suggested-campaign`} to={`${match.url}/edit/send-suggestion`} />
									<Redirect from={`${match.url}/from-queued-campaign`} to={`${match.url}/edit/recipients`} />
									<Redirect from={`${match.url}/campaign-follow-up`} to={`${match.url}/edit/recipients/follow-up`} />
									<Redirect
										from={`${match.url}/survey-follow-up/from-report`}
										to={`${match.url}/edit/recipients/survey-follow-up/from-report`}
									/>
									<PrivateRoute
										exact={true}
										location={location}
										path={`${match.url}/browse`}
										render={this.onRenderManageTemplates}
										userSession={userSession}
									/>
									<PrivateRoute
										exact={true}
										location={location}
										path={`${match.url}/edit/send-options`}
										render={this.onRenderEditSendOptions}
										userSession={userSession}
									/>
									<PrivateRoute
										exact={true}
										location={location}
										path={`${match.url}/edit/compliance-options`}
										render={this.onRenderComplianceOptions}
										userSession={userSession}
									/>
									<PrivateRoute
										exact={true}
										location={location}
										path={`${match.url}/edit/send-complete`}
										render={this.onRenderSendComplete}
										userSession={userSession}
									/>
									<PrivateRoute
										exact={true}
										location={location}
										path={`${match.url}/edit/compliance-complete`}
										userSession={userSession}
									>
										<ComplianceConfirmation onDismiss={this.dismissModal} styles={[styleSheet.childRoute]} />
									</PrivateRoute>
									<PrivateRoute
										exact={true}
										location={location}
										path={`${match.url}/edit/send-suggestion/schedule`}
										render={this.onRenderEditSuggestionSchedule}
										userSession={userSession}
									/>
									<PrivateRoute
										exact={false}
										location={location}
										path={`${match.url}/edit/send-suggestion`}
										render={this.onRenderEditSuggestion}
										userSession={userSession}
									/>
									<PrivateRoute
										location={location}
										path={`${match.url}/edit`}
										render={this.onRenderEditCampaign}
										userSession={userSession}
									/>
								</Switch>
								<FabContext appearance={{ hidden: true }} />
								{emailComposer && (
									<ConfirmationDialog
										icon={<WarningIcon />}
										modalProps={{
											isOpen: showingCloseConfirmation,
											onRequestClose: this.onCloseConfirmationDialogRequestClose,
										}}
										options={[
											{
												isDestructive: true,
												representedObject: true,
												title: 'Discard',
											},
											{
												isCancel: true,
												representedObject: false,
												title: 'Cancel',
											},
										]}
										title='Are you sure you want to discard this email?'
									/>
								)}
								{emailComposer &&
									!userSession.account.preferences?.suppressContactsRemovedFromResourceSends &&
									this.onRenderConfirmationOmittedContacts(
										showingOmitConfirmationBeforeSend,
										this.onCloseOmitConfirmationDialogRequestCloseSend,
										'Remove Recipients and Send',
										'Send Without Removing'
									)}
								{emailComposer &&
									!userSession.account.preferences?.suppressContactsRemovedFromResourceSends &&
									this.onRenderConfirmationOmittedContacts(
										showingOmitConfirmationBeforeEditSend,
										this.onCloseOmitConfirmationDialogRequestCloseEditSend,
										'Remove Recipients and Send',
										'Send Without Removing'
									)}
								{emailComposer &&
									this.onRenderConfirmationOmittedContacts(
										showingOmitConfirmation,
										this.onCloseOmitConfirmationDialogRequestClose,
										'Remove Recipients and Exit',
										'Exit Without Removing'
									)}
								<ScheduledEmailErrorModal
									onNavigateToReportingGroupEmail={this.dismissModal}
									onRequestClose={this.onCloseScheduleErrorModal}
									scheduleError={scheduleError}
								/>
							</>
						)}
					</RenewalEmailContextProvider>
				</EmailComposerContext.Provider>
			</div>
		);
	}

	private renderFromTemplateRedirect = () => {
		const { match, location } = this.props;
		const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = location?.state;

		return (
			<Redirect
				from={`${match.url}/from-template`}
				to={{
					pathname: `${match.url}/${typeof locationState?.model?.context === 'object' ? 'edit/tags' : 'browse'}`,
				}}
			/>
		);
	};

	private renderFullscreenHeader() {
		const { location, match } = this.props;
		const { emailComposer } = this.state;

		if (emailComposer?.isUserEditingMessage) {
			// Allows children to set custom events for the header buttons
			return null;
		}

		let title = '';
		if (location.pathname === `${match.url}/edit/send-options`) {
			title = 'Send Options';
		}
		if (location.pathname === `${match.url}/edit/compliance-options`) {
			title = 'Compliance Approval';
		}
		if (location.pathname === `${match.url}/edit/compliance-complete`) {
			title = 'Confirmation';
		}
		if (!title) {
			if (new RegExp(`^${match.url}/edit`, 'i').test(location.pathname)) {
				title = 'View Campaign';
			}
		}
		if (this.isFromHtmlNewsletterTemplate) {
			title = 'Send HTML Email';
		}

		if (title) {
			return (
				<MultiContainerHeader
					fullscreenHeader={title}
					onFullscreenRequestBack={this.onBackButtonClicked}
					onFullscreenRequestClose={this.onCloseButtonClicked}
				/>
			);
		}
	}

	private onRenderManageTemplates = (props: RouteComponentProps<any>) => {
		if (this.state.replacingHistoryDontRender) {
			return null;
		}
		return (
			<ManageEmailTemplates
				{...props}
				onRenderFullscreenHeader={this.onRenderBrowseFullscreenHeader}
				onTemplateSelected={this.onTemplateSelected}
				selectOnly={true}
			/>
		);
	};

	private onRenderBrowseFullscreenHeader = (onCloseClicked: (e?: React.MouseEvent<HTMLElement>) => void) => {
		return (
			<MultiContainerHeader fullscreenHeader='Browse & Send Campaigns' onFullscreenRequestClose={onCloseClicked} />
		);
	};

	private onRenderEditCampaign = (props: RouteComponentProps<any>) => {
		const {
			emailBodyTemplate,
			emailComposer,
			showingOmitConfirmation,
			showTemplateSelectDuringEdit,
			createByContentCalendar,
		} = this.state;
		return (
			<>
				<Observer>
					{() => (
						<EditCampaign
							{...props}
							canShowTemplateSelect={showTemplateSelectDuringEdit}
							canImpersonateOnTemplateSave={createByContentCalendar}
							emailComposer={emailComposer}
							initialEmailBodyTemplate={
								typeof emailBodyTemplate === 'object' ? (emailBodyTemplate as Api.ITemplate) : undefined
							}
							onAutomationsCreated={this.onAutomationsCreated}
							onShowOmitConfirmation={
								this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length > 0
									? this.onShowOmitConfirmation
									: null
							}
							onInnerRef={this.onEditCampaignRef}
							styles={[styleSheet.childRoute]}
						/>
					)}
				</Observer>
				{emailComposer &&
					this.onRenderConfirmationOmittedContacts(
						showingOmitConfirmation,
						this.onCloseOmitConfirmationDialogRequestClose,
						'Remove Recipients and Exit',
						'Exit Without Removing'
					)}
			</>
		);
	};

	private onAutomationsCreated = (result: Api.ICreateAutomationsResult) => {
		const { toaster } = this.props;
		const count = result.success?.length || 0;
		toaster.push({
			message: `${count} automation${count > 1 ? 's' : ''} created`,
			type: 'successMessage',
		});
		this.dismissModal();
	};

	private onRenderEditSendOptions = (props: RouteComponentProps<any>) => {
		const { userSession } = this.props;
		if (this.resourceSelectorEmailComposer) {
			if (this.resourceSelectorEmailComposer.selectedAutomationTemplate) {
				const SELECTOR = this.resourceSelectorEmailComposer.resourceSelector;
				const IS_CUSTOM = this.resourceSelectorEmailComposer.resourceSelector.startsWith('Custom');
				const IS_TURNING_XX = this.resourceSelectorEmailComposer.resourceSelector.toLocaleLowerCase().includes('xx');
				const DISPLAY_NAME = userSession?.account?.preferences?.resourceSelectorSettings?.[SELECTOR]?.displayName;
				const SELECTOR_DISPLAY_NAME = getResourceSelectorIdDisplayName(SELECTOR);
				const SHOW_DISPLAY_NAME_OPTION = IS_CUSTOM || IS_TURNING_XX;
				const displayNameSingular = SHOW_DISPLAY_NAME_OPTION ? DISPLAY_NAME : SELECTOR_DISPLAY_NAME;
				const displayNamePlural = SHOW_DISPLAY_NAME_OPTION ? DISPLAY_NAME : SELECTOR_DISPLAY_NAME;
				const trigger: Api.IResourceSelectorAutomationTrigger = {
					_type: Api.AutomationTriggerType.ResourceSelector,
					resourceSelector: SELECTOR,
				};
				const isRelativeDate = Api.VmUtils.Automations.triggers.triggerUsesRelativeToAnchorScheduleCriteria(trigger);
				return (
					<Observer>
						{() => (
							<KeyDateSendOptions
								header={`Schedule Send for ${displayNamePlural}`}
								message={`${displayNameSingular} email${
									SHOW_DISPLAY_NAME_OPTION ? '(s)' : 's'
								} will be sent individually. ${
									isRelativeDate
										? `If you are using an automation, emails will be sent in relation to the ${displayNameSingular.toLocaleLowerCase()} dates.`
										: ''
								}`}
								onShowOmitConfirmation={
									!userSession.account.preferences?.suppressContactsRemovedFromResourceSends &&
									this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length > 0
										? this.onShowOmitConfirmationSend
										: null
								}
								onScheduleClicked={this.onScheduleAutomationClicked}
								styles={[styleSheet.keyDateSendOptions]}
							/>
						)}
					</Observer>
				);
			} else if (
				this.resourceSelectorEmailComposer.resourceSelector === ResourceSelectorId.HappyBirthday ||
				this.resourceSelectorEmailComposer.resourceSelector === ResourceSelectorId.HouseAnniversaries
			) {
				return (
					<Observer>
						{() => (
							<KeyDateSendOptions
								className='keydate-resource-send'
								keyDateKind={
									this.resourceSelectorEmailComposer.resourceSelector === ResourceSelectorId.HappyBirthday
										? KeyDateKind.Birthday
										: KeyDateKind.Anniversary
								}
								onShowOmitConfirmation={
									!userSession.account.preferences?.suppressContactsRemovedFromResourceSends &&
									this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length > 0
										? this.onShowOmitConfirmationSend
										: null
								}
								onScheduleClicked={this.scheduleKeyDateEmail}
								styles={[styleSheet.keyDateSendOptions]}
							/>
						)}
					</Observer>
				);
			}
		}

		const { initialStartMoment } = this.state;
		return (
			<Observer>
				{() => (
					<EditSendOptions
						{...props}
						disableSendNow={!this.state.emailComposer.emailMessage.isSendFromOption(SendEmailFrom.CurrentUser)}
						emailComposer={this.state.emailComposer}
						initialStartDate={initialStartMoment}
						onScheduleCtaClicked={this.send}
						onShowOmitConfirmation={
							!userSession.account.preferences?.suppressContactsRemovedFromResourceSends &&
							this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length > 0
								? this.onShowOmitConfirmationEditSend
								: null
						}
						styles={[styleSheet.childRoute]}
					/>
				)}
			</Observer>
		);
	};

	private onRenderConfirmationOmittedContacts = (
		show: boolean,
		onClose: () => void,
		titleDestruct: string,
		titleCancel: string
	) => {
		return (
			<Observer>
				{() => (
					<ConfirmationDialog
						className={css(styleSheet.omittedDialog)}
						icon={<WarningIcon />}
						modalProps={{
							isOpen: show,
							onRequestClose: onClose,
						}}
						options={[
							{
								isDestructive: true,
								representedObject: true,
								title: titleDestruct,
							},
							{
								isCta: true,
								representedObject: false,
								title: titleCancel,
							},
						]}
						title={this.onRenderOmittedTitle()}
					>
						{this.onRenderOmittedContacts()}
					</ConfirmationDialog>
				)}
			</Observer>
		);
	};

	private onRemoveAction = (items: Api.ContactViewModel) => () => {
		const { showingOmitConfirmation, showingOmitConfirmationBeforeSend, showingOmitConfirmationBeforeEditSend } =
			this.state;
		this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.removeItems([items]);

		this.resourceSelectorEmailComposer.updateApproximationForFilter()?.catch((err: Api.IOperationResultNoValue) => {
			const { logApiError } = this.props;
			logApiError('updateApproximationForFilter-Error', err);
		});

		if (this.resourceSelectorEmailComposer.emailMessage.contactsToOmit.length === 0) {
			return showingOmitConfirmation
				? this.onCloseOmitConfirmationDialogRequestClose()
				: showingOmitConfirmationBeforeSend
					? this.onCloseOmitConfirmationDialogRequestCloseSend()
					: showingOmitConfirmationBeforeEditSend
						? this.onCloseOmitConfirmationDialogRequestCloseEditSend()
						: null;
		}
	};

	private onRenderOmittedTitle = () => {
		const numberOmitted = this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length || 0;

		return numberOmitted === 1 ? (
			<div className={css(styleSheet.confirmationTitle)}>
				Remove Recipients
				<div className={css(styleSheet.confirmationSubTitle)}>
					The following contact will be removed from this dashboard send
				</div>
			</div>
		) : (
			<div className={css(styleSheet.confirmationTitle)}>
				Remove Recipients
				<div className={css(styleSheet.confirmationSubTitle)}>
					{'The following '}
					<span className={css(baseStyleSheet.fontBold)}>{numberOmitted}</span>
					{' contacts will be removed from this send'}
				</div>
			</div>
		);
	};

	private onRenderOmittedContacts = () => {
		return (
			<Observer>
				{() => {
					return this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length === 0 ? null : (
						<div className={css(styleSheet.omittedContainer)}>
							<div className={css(styleSheet.omittedGroup)}>
								{this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit.map((c, i) => (
									<div className={css(styleSheet.omittedList)} key={i}>
										<DeprecatedCloseButton
											fillColor='#A7ABAD'
											widthAndHeight={10}
											className={css(styleSheet.closeButton)}
											onClick={this.onRemoveAction(c)}
										/>
										<div className={css(styleSheet.omittedItem)}>{c.name}</div>
									</div>
								))}
							</div>
						</div>
					);
				}}
			</Observer>
		);
	};

	private onRenderComplianceOptions = (props: RouteComponentProps<any>) => {
		return (
			<ComplianceApprovalPrompt
				{...props}
				onScheduleCompliance={(e: React.MouseEvent<HTMLElement>, sendWithComplianceEmail: string) => {
					e.preventDefault();
					this.onSendWithCompliance(sendWithComplianceEmail);
				}}
				onScheduleWithoutCompliance={this.onSendWithOutCompliance}
			/>
		);
	};

	@action
	private onSendWithOutCompliance = () => {
		const { history, match } = this.props;
		const { emailComposer } = this.state;
		const nextEmailComposer = produce(emailComposer, draftEmailComposer => {
			draftEmailComposer.emailMessage.options = draftEmailComposer.emailMessage.options || {};
			draftEmailComposer.emailMessage.options.sendWithCompliance = false;
		});
		this.setState(
			{
				emailComposer: nextEmailComposer,
			},
			() => history?.push(`${match.url}/edit/send-options`)
		);
	};
	@action
	private onSendWithCompliance = (sendWithComplianceEmail: string) => {
		const { history, match } = this.props;
		const { emailComposer } = this.state;
		const nextEmailComposer = produce(emailComposer, draftEmailComposer => {
			draftEmailComposer.emailMessage.options = draftEmailComposer.emailMessage.options || {};
			draftEmailComposer.emailMessage.options.sendWithCompliance = true;
			draftEmailComposer.emailMessage.options.sendWithComplianceEmail = sendWithComplianceEmail;
		});

		this.setState(
			{
				emailComposer: nextEmailComposer,
			},
			() => history?.push(`${match.url}/edit/send-options`)
		);
	};

	private onRenderSendComplete = (props: RouteComponentProps<any>) => {
		const { emailComposer } = this.state;
		return (
			<SendSchedulerCompleteOverlay
				{...props}
				emailComposer={emailComposer}
				emailCount={emailComposer.estimatedRecipientsTotal}
				estimate={emailComposer.sendEstimate}
				onCloseButtonClicked={this.dismissModal}
				styles={[styleSheet.childRoute]}
			/>
		);
	};

	private onRenderEditSuggestion = (props: RouteComponentProps<any>) => {
		return (
			<SuggestedCampaignComposer
				{...props}
				emailComposer={this.state.emailComposer}
				onFinish={this.dismissModal}
				styles={[styleSheet.childRoute]}
				suggestion={this.state.emailComposer?.suggestion}
			/>
		);
	};

	private onRenderEditSuggestionSchedule = (props: RouteComponentProps<any>) => {
		return (
			<EditSendOptions
				{...props}
				emailComposer={this.state.emailComposer}
				onScheduleCtaClicked={this.updateSuggestionSchedule}
				submitButtonText='Okay'
			/>
		);
	};

	private updateSuggestionSchedule = (e: React.MouseEvent<HTMLElement>) => {
		const { history } = this.props;
		const { emailComposer } = this.state;
		e.preventDefault();
		this.setState({
			emailComposer,
		});
		history.goBack();
	};

	private get resourceSelectorEmailComposer() {
		const { emailComposer } = this.state;
		return (emailComposer as ComposeResourceEmailViewModel)?.resourceSelector
			? (emailComposer as ComposeResourceEmailViewModel)
			: null;
	}

	private get isFromHtmlNewsletterTemplate() {
		const { emailBodyTemplate } = this.state;
		return (
			typeof emailBodyTemplate === 'object' &&
			emailBodyTemplate?.content?.sourceFormat === Api.ContentSourceFormat.UnlayerHtmlNewsletter
		);
	}

	private onEditCampaignRef = (ref?: IEditCampaignComponent) => {
		this.mEditCampaignComponent = ref;
	};

	private onCloseConfirmationDialogRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		this.setState(
			{
				showingCloseConfirmation: false,
			},
			() => {
				if (result && result.isDestructive && !canceled) {
					this.props.fullscreenModal?.dismissModal();
				}
			}
		);
	};

	private onCloseOmitConfirmationDialogRequestClose = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		const { emailComposer, hasChanges } = this.state;
		const { logEvent, logApiError, errorMessages } = this.props;
		const resourceEmailComposer = emailComposer as ComposeResourceEmailViewModel;
		const contactsToSuppress = resourceEmailComposer.emailMessage.contactsToOmit?.map(x => x.id);
		this.setState(
			{
				showingOmitConfirmation: false,
			},
			async () => {
				if (result && result.isDestructive && !canceled) {
					try {
						logEvent('OmitContacts', contactsToSuppress);
						await resourceEmailComposer.suppressContactsFromSend(contactsToSuppress || []);
						this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.clear();
					} catch (err) {
						logApiError('OmitContacts-Error', err);
						errorMessages.pushApiError(err);
					}
					if (hasChanges || emailComposer?.emailMessage?.templateReference?.isCustomized) {
						this.setState({ showingCloseConfirmation: true });
					} else {
						this.dismissModal();
					}
				} else if (result && result.isCta && !canceled) {
					this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.clear();
					if (hasChanges || emailComposer?.emailMessage?.templateReference?.isCustomized) {
						this.setState({ showingCloseConfirmation: true });
					} else {
						this.dismissModal();
					}
				}
			}
		);
	};

	private onCloseOmitConfirmationDialogRequestCloseSend = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		const { logEvent, logApiError, logInput, errorMessages } = this.props;
		const { emailComposer } = this.state;
		const resourceEmailComposer = emailComposer as ComposeResourceEmailViewModel;
		const contactsToSuppress = resourceEmailComposer.emailMessage.contactsToOmit?.map(x => x.id);

		this.setState(
			{
				loading: true,
				showingOmitConfirmationBeforeSend: false,
			},
			async () => {
				if (result && result.isDestructive && !canceled) {
					try {
						logEvent('OmitContactsOnSend', contactsToSuppress);
						await resourceEmailComposer.suppressContactsFromSend(contactsToSuppress || []);
						const date = moment().utc().startOf('day').add(9, 'hours').toDate();
						logInput('Schedule', 'Click');
						if (resourceEmailComposer.selectedAutomationTemplate) {
							this.onScheduleAutomationClicked();
						} else {
							this.scheduleKeyDateEmail(ScheduleCriteria.OnDayOf, date);
						}
					} catch (err) {
						logApiError('CreateAutomations-Error', err);
						errorMessages.pushApiError(err);
					}
				} else if (result && result.isCta && !canceled) {
					try {
						const date = moment().utc().startOf('day').add(9, 'hours').toDate();
						logInput('Schedule', 'Click');
						if (resourceEmailComposer.selectedAutomationTemplate) {
							this.onScheduleAutomationClicked();
						} else {
							this.scheduleKeyDateEmail(ScheduleCriteria.OnDayOf, date);
						}
					} catch (err) {
						logApiError('CreateAutomations-Error', err);
						errorMessages.pushApiError(err);
					}
				}
				this.setState({ loading: false });
			}
		);
	};

	private onCloseOmitConfirmationDialogRequestCloseEditSend = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		const { logEvent, logApiError, errorMessages } = this.props;
		const { emailComposer, initialStartMoment, estimate, selectedMoment, sendNow, minStartDate } = this.state;
		const resourceEmailComposer = emailComposer as ComposeResourceEmailViewModel;
		const contactsToSuppress = resourceEmailComposer.emailMessage.contactsToOmit?.map(x => x.id);

		const startDate = resourceEmailComposer?.emailMessage?.options?.scheduledSend?.startDate;

		let startMoment = initialStartMoment ? moment(initialStartMoment) : startDate ? moment(startDate) : null;

		const minStartMoment = minStartDate ? moment(minStartDate) : moment().set('minutes', 0);
		if (minStartMoment && (!startMoment || minStartMoment.isAfter(startMoment))) {
			startMoment = minStartMoment;
		}

		this.setState(
			{
				loading: true,
				showingOmitConfirmationBeforeEditSend: false,
			},
			async () => {
				if (result && result.isDestructive && !canceled) {
					try {
						logEvent('OmitContacts', contactsToSuppress);
						await resourceEmailComposer.suppressContactsFromSend(contactsToSuppress || []);
					} catch (err) {
						logApiError('CreateAutomations-Error', err);
						errorMessages.pushApiError(err);
					}
				}
				if (result && !canceled) {
					resourceEmailComposer.sendEstimate = estimate;
					resourceEmailComposer.emailMessage.options.scheduledSend = {
						...(resourceEmailComposer.emailMessage.options.scheduledSend || {}),
						criteria: sendNow ? Api.ScheduleCriteria.Immediately : Api.ScheduleCriteria.StartAfter,
						startDate: selectedMoment?.toISOString(),
					};
					await this.send?.();
				}

				this.setState({ loading: false });
			}
		);
	};

	private onBackButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { location } = this.props;
		const pathComps = new Set((location.pathname || '').split('/'));
		if (pathComps.has('edit') && pathComps.has('send-complete')) {
			e.preventDefault();
			this.dismissModal();
		}
		this.mEditCampaignComponent?.finishEditingContent();
	};

	private onCloseButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { logInput, location } = this.props;
		logInput('Close', 'Click');
		const pathComps = new Set((location.pathname || '').split('/'));
		if (pathComps.has('edit') && !pathComps.has('send-complete')) {
			e.preventDefault();
			if (
				this.resourceSelectorEmailComposer &&
				this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length > 0
			) {
				this.setState({
					showingOmitConfirmation: true,
				});
			} else {
				this.setState({
					showingCloseConfirmation: true,
				});
			}
		}
	};

	private onCloseScheduleErrorModal = () => {
		this.setState({
			scheduleError: null,
		});
	};

	private dismissModal = () => {
		this.props?.parentModal?.onRequestClose(null, false);
	};

	private onTemplateSelected = (template?: Api.ITemplate) => {
		const { history, location, match, userSession } = this.props;
		const { composerContext = {} } = this.state;
		const emailComposer = this.configureEmailComposer(
			new ComposeEmailViewModel(userSession),
			template,
			!!this.mInitialContactSelection
		);
		this.setState(
			{
				composerContext: {
					...composerContext,
					emailComposer,
				},
				emailBodyTemplate: template,
				emailComposer,
			},
			() => {
				this.setState({ replacingHistoryDontRender: false });
				history?.push(`${match.url}/edit/${this.mInitialContactSelection ? 'recipients' : 'tags'}`, location.state);
			}
		);
	};

	private configureEmailComposer = <
		TFollowUpOptions extends Api.IFollowUpOptions = Api.IFollowUpOptions,
		TEmailComposer extends ComposeEmailViewModel<TFollowUpOptions> = ComposeEmailViewModel<TFollowUpOptions>,
	>(
		emailComposer: TEmailComposer,
		template?: Api.ITemplate,
		hasInitialContactSelection?: boolean
	): TEmailComposer => {
		const { userSession } = this.props;
		const { createdByBrowseAndSendCTA } = this.state;
		emailComposer.emailMessage = new Api.EmailMessageViewModel<File, Api.ISendEmailResponse, TFollowUpOptions>(
			userSession
		);
		emailComposer.allowSenderSelection =
			this.mInitialContactSelection?.allowSenderSelection || createdByBrowseAndSendCTA;
		if (this.mInitialContactSelection?.bulkFilterRequestOptions?.readOnlySearchCriteria) {
			emailComposer.setAllowContactFilterTagSearchChangesOverride(false);
		}
		if (template) {
			emailComposer.emailMessage.setSavedAttachments(template.attachments);
			emailComposer.emailMessage.templateReference = {
				isCustomized: false,
				isSystemTemplate: template.scope === Api.TemplateScope.Industry,
				templateId: template.id,
				name: template.name,
			};
			emailComposer.emailMessage.subject = template.subject;
			emailComposer.emailMessage.content = { ...template.content };
			if (template.templateType === Api.TemplateType.HtmlNewsletter) {
				emailComposer.canCustomizeIndividualEmails = false;
			}
			if (template.scope === Api.TemplateScope.Industry || template.scope === Api.TemplateScope.System) {
				if (template.schedule?.expirationDate) {
					emailComposer.emailMessage.options.scheduledSend = {
						...(emailComposer.emailMessage.options.scheduledSend || {}),
						expirationDate: template.schedule.expirationDate,
					};
				}
			}
		} else {
			emailComposer.emailMessage.content = getDefaultBulkMessagingBodyEditorState().getRawRichTextContent();
		}
		if (hasInitialContactSelection) {
			if (this.mInitialContactSelection.bulkFilterRequest) {
				const filterRequestWithoutContactsWithoutEmails: Api.IEmailMessageContactsFilterRequest = {
					...this.mInitialContactSelection.bulkFilterRequest,
					contactFilterRequest: {
						...(this.mInitialContactSelection.bulkFilterRequest.contactFilterRequest || {}),
						criteria: [
							...((this.mInitialContactSelection.bulkFilterRequest.contactFilterRequest || {}).criteria || []),
							{
								op: Api.FilterOperator.Not,
								property: Api.ContactFilterCriteriaProperty.WithoutEmailAddresses,
							},
							...Array.from(Api.DefaultBulkSendExcludedTags).map(
								x =>
									({
										op: Api.FilterOperator.Not,
										property: Api.ContactFilterCriteriaProperty.Tag,
										value: x,
									}) as Api.IContactFilterCriteria
							),
						],
					},
				};
				emailComposer.emailMessage.contactsFilterRequest = filterRequestWithoutContactsWithoutEmails;
			}
			emailComposer.emailMessage.contactsToAdd.addAll(this.mInitialContactSelection.contacts || []);
			emailComposer.emailMessage.contactsToOmit.addAll(this.mInitialContactSelection.contactsToOmit || []);
		}
		return emailComposer;
	};

	private onShowOmitConfirmation = (e?: React.MouseEvent<HTMLElement>, hasChanged?: boolean) => {
		e?.preventDefault();
		if (this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length > 0) {
			this.setState({
				hasChanges: hasChanged ? true : false,
				showingOmitConfirmation: true,
			});
		} else {
			this.onCloseButtonClicked(e);
		}
	};

	private onShowOmitConfirmationSend = () => {
		if (this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length > 0) {
			this.setState({
				showingOmitConfirmationBeforeSend: true,
			});
		}
	};

	private onShowOmitConfirmationEditSend = (
		e?: React.MouseEvent<HTMLElement>,
		estimate?: Partial<Api.IEmailSendEstimate>,
		selectedMoment?: moment.Moment,
		sendNow?: boolean,
		minStartDate?: Date
	) => {
		e?.preventDefault();
		if (this.resourceSelectorEmailComposer?.emailMessage?.contactsToOmit?.length > 0) {
			this.setState({
				estimate,
				minStartDate,
				selectedMoment,
				sendNow,
				showingOmitConfirmationBeforeEditSend: true,
			});
		}
	};

	private scheduleKeyDateEmail = async (criteria?: ScheduleCriteria, startDate?: Date) => {
		const { emailComposer } = this.state;
		if (criteria) {
			emailComposer.emailMessage.options.scheduledSend = {
				criteria,
				startDate: startDate ? startDate.toISOString() : null,
			};
			await this.send();
		}
	};

	private onScheduleAutomationClicked = async () => {
		const { logApiError, logEvent, errorMessages, onAutomationsCreated, history, match } = this.props;
		const { emailComposer } = this.state;
		const resourceEmailComposer = emailComposer as ComposeResourceEmailViewModel;
		if (emailComposer.selectedAutomationTemplate) {
			const request: Api.ICreateAutomationForResourceSelectorRequest = {
				automationTemplateId: emailComposer.selectedAutomationTemplate.id,
				selectorRequest: {
					excludeIds: resourceEmailComposer.emailMessage.contactsToOmit?.map(x => x.id) || [],
					filter: resourceEmailComposer.emailMessage.contactsFilterRequest?.contactFilterRequest,
					qualifier: resourceEmailComposer.qualifier,
					ownershipFilter: resourceEmailComposer.emailMessage.contactsFilterRequest?.ownershipFilter,
					groupByHousehold: resourceEmailComposer.emailMessage.groupByHousehold,
				},
				sendFromOptions: {
					selectedUser: resourceEmailComposer.sendAsEmployee,
					mode: resourceEmailComposer.emailMessage.options.sendEmailFrom,
				},
			};
			if (resourceEmailComposer.sendAsConnectionType) {
				request.sendFromOptions = {
					mode: Api.SendEmailFrom.ConnectionType,
					connectionType: resourceEmailComposer.sendAsConnectionType,
				};
			}

			try {
				logEvent('CreateAutomations', request);
				const opResult = await emailComposer.selectedAutomationTemplate.createAutomationForResouceSelector(
					resourceEmailComposer.resourceSelector,
					request
				);
				onAutomationsCreated?.(opResult.value);
			} catch (err) {
				logApiError('CreateAutomations-Error', err);
				errorMessages.pushApiError(err);
			}
		}
		history?.push(`${match.url}/edit/send-complete`);
	};

	private send = async () => {
		const {
			userSession,
			logApiError,
			history,
			toaster,
			logEvent,
			postNotification,
			errorMessages,
			impersonationContext,
		} = this.props;
		const { emailComposer, emailBodyTemplate } = this.state;

		const composerWithResource = emailComposer as ComposeResourceEmailViewModel;

		try {
			const op = await (composerWithResource?.resourceSelector
				? composerWithResource.sendWithResource()
				: emailComposer.emailMessage.send());

			// save content as preferred key date template, if needed
			this.createTemplateForKeyDateKind(emailComposer, emailBodyTemplate);

			const campaign = new Api.CampaignViewModel(userSession, {
				id: op.value.id,
			});
			try {
				logEvent('LoadCampaign', { id: op.value.id });
				await campaign.load();
			} catch (err) {
				logApiError('LoadCampaign-Error', err);
			}

			const info: ICampaignsCreatedNotificationInfo = {
				accountId: (impersonationContext?.account || userSession.account).id,
				campaigns: [campaign.toJs()],
			};

			postNotification({
				info,
				topic: Topics.CREATE_CAMPAIGNS_ITEM,
			});

			if (op.value.schedule.criteria === Api.ScheduleCriteria.Immediately) {
				if (campaign.totalCount < _BrowseAndSendCampaigns.MinSendSystemJobRecordCountForOverlay) {
					// tell listeners emails were sent
					postNotification({
						info: emailComposer.emailMessage,
						topic: Topics.SEND_EMAIL,
					});
					const toastMessage: IAppToastMessage = {
						message: `Messages sent successfully.`,
					};
					toaster.push({
						customContent: <EmailSuccessToast toastMessage={toastMessage} />,
						type: 'custom',
					});
					this.dismissModal();
				} else {
					// take user directly to reporting
					const toastMessage: IAppToastMessage = {
						message: `Success. Your email is ready to send.`,
					};
					toaster.push({
						customContent: <EmailSuccessToast toastMessage={toastMessage} />,
						type: 'custom',
					});
					history.push(`/reporting/group-email/${op.value.id}`);
				}
			} else {
				this.showScheduleComplete(op.value.schedule);
			}
			this.mCampaignCreateRequest?.onFinish?.(true);
		} catch (error: any) {
			logApiError('SendBulk-Error', error);
			const sendNow = emailComposer.emailMessage.options?.scheduledSend?.criteria === Api.ScheduleCriteria.Immediately;
			if (!sendNow && error?.systemCode === -1) {
				// connection error, show special error message
				this.setState({
					scheduleError: error,
				});
				return;
			}
			errorMessages.pushApiError(error);
		}
	};

	@action
	private showScheduleComplete = (schedule: Api.IScheduledSend) => {
		const { emailComposer } = this.state;
		const { history, match } = this.props;
		emailComposer.sendEstimate = {
			...(emailComposer.sendEstimate || {}),
			estimate: schedule,
		};
		if (emailComposer?.emailMessage?.groupId) {
			// reset list
			invalidateAllInfiniteCampaignReportQueries();
		}
		history?.push(
			`${match.url}/${
				emailComposer.emailMessage.options?.sendWithCompliance ? 'edit/compliance-complete' : 'edit/send-complete'
			}`
		);
	};

	private sendTestEmail = async () => {
		const { errorMessages, logApiError, logEvent } = this.props;
		const { emailComposer } = this.state;
		try {
			if (this.mEditCampaignComponent) {
				this.mEditCampaignComponent?.finishEditingContent();
			}
			logEvent('SendEmailTest');
			return await emailComposer?.emailMessage?.sendTestEmail();
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('SendEmailTest-Error', error);
		}
	};

	private createTemplateForKeyDateKind(
		emailComposer: ComposeEmailViewModel,
		emailBodyTemplate: TemplateOrTemplateFilter
	) {
		const { userSession, logApiError, logEvent } = this.props;
		const kind = getKeyDateKindFromResourceSelectorId(
			(emailComposer as ComposeResourceEmailViewModel).resourceSelector
		);

		if (
			kind &&
			(!(emailBodyTemplate as Api.ITemplate)?.id ||
				((emailBodyTemplate as Api.ITemplate)?.id === emailComposer?.emailMessage?.templateReference?.templateId &&
					emailComposer?.emailMessage?.templateReference.isCustomized))
		) {
			let prop: keyof Api.ITemplatePreferences | '' = '';
			switch (kind) {
				case Api.KeyDateKind.Birthday:
					prop = 'birthdayTemplateId';
					break;
				case Api.KeyDateKind.Anniversary:
					prop = 'anniversaryTemplateId';
					break;
				case Api.KeyDateKind.Renewal:
					prop = 'renewalTemplateId';
					break;
				case Api.KeyDateKind.FinancialReview:
					prop = 'reviewTemplateId';
					break;
				case Api.KeyDateKind.Turning65:
					prop = 'turning65TemplateId';
					break;
				case Api.KeyDateKind.Turning72:
					prop = 'turning72TemplateId';
					break;
				case Api.KeyDateKind.Turning73:
					prop = 'turning73TemplateId';
					break;
				case Api.KeyDateKind.TurningXX:
					prop = 'turningXXTemplateId';
					break;
				case Api.KeyDateKind.Custom1:
					prop = 'custom1TemplateId';
					break;
				case Api.KeyDateKind.Custom2:
					prop = 'custom2TemplateId';
					break;
				case Api.KeyDateKind.Custom3:
					prop = 'custom3TemplateId';
					break;
				case Api.KeyDateKind.Custom4:
					prop = 'custom4TemplateId';
					break;
				case Api.KeyDateKind.Custom5:
					prop = 'custom5TemplateId';
					break;
				case Api.KeyDateKind.Custom6:
					prop = 'custom6TemplateId';
					break;
				case Api.KeyDateKind.Custom7:
					prop = 'custom7TemplateId';
					break;
				case Api.KeyDateKind.Custom8:
					prop = 'custom8TemplateId';
					break;
				case Api.KeyDateKind.Custom9:
					prop = 'custom9TemplateId';
					break;
				case Api.KeyDateKind.Custom10:
					prop = 'custom10TemplateId';
					break;
				default:
					break;
			}
			if (prop) {
				const templates = new TemplatesViewModel(userSession);
				const createTemplate = () => {
					const preferredTemplate: Api.ITemplate = {
						content: emailComposer.emailMessage.content,
						name: emailComposer.emailMessage.subject,
						scope: Api.TemplateScope.User,
						subject: emailComposer.emailMessage.subject,
						templateType: Api.TemplateType.Email,
					};
					logEvent('CreatePreferredTemplate', { kind });
					templates
						.create(preferredTemplate)
						?.then(result => {
							const userVM = new Api.UserViewModel(userSession, userSession?.user);
							const newPrefs: Api.IUserPreferences = {
								...userSession?.user?.userPreferences,
								templatePreferences: {
									...userSession?.user?.userPreferences?.templatePreferences,
									[prop]: result?.id,
								},
							};

							logEvent('UpdateUserPreferencesWithPreferredTemplate', { kind });
							userVM.updateUserPreferences(newPrefs)?.catch((err: Api.IOperationResultNoValue) => {
								logApiError('UpdateUserPreferencesWithPreferredTemplate-Error', err);
							});
						})
						?.catch((err: Api.IOperationResultNoValue) => {
							logApiError('CreatePreferredTemplate-Error', err);
						});
				};
				if (TemplatesViewModel.userHasTemplateForKeyDateKind(userSession, kind)) {
					const fetchPromise = templates.getById(userSession.user.userPreferences.templatePreferences[prop]);
					fetchPromise.catch((error: Api.IOperationResultNoValue) => {
						if (error.systemCode === 404) {
							// template does not exist...
							createTemplate();
						}
					});
				} else {
					createTemplate();
				}
			}
		}
	}
}

const BrowseAndSendCampaignsAsObserver = observer(_BrowseAndSendCampaigns);
const BrowseAndSendCampaignsWithNotificationService = withNotificationService(BrowseAndSendCampaignsAsObserver);
const BrowseAndSendCampaignsWithContext = inject(
	AppState.UserSessionViewModelKey,
	AppState.FullScreenModalViewModelKey,
	AppState.ToasterViewModelKey,
	ModalChildComponentContextKey,
	AppState.ErrorMessagesViewModelKey
)(BrowseAndSendCampaignsWithNotificationService);
export const BrowseAndSendCampaigns = withEventLogging(BrowseAndSendCampaignsWithContext, 'BrowseAndSendCampaigns');
