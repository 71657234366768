import * as Api from '@ViewModels';
import * as React from 'react';
import { PersistentStorageManager } from '../../../../models/Storage';
import { getMergeLinkProviderDisplayName } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { invalidateIntegrationStatus, useDisableIntegrationMutation, useIntegrationStatus } from '../../../../queries';
import { useMergeAuth } from './useMergeAuth';

type TMergeProviderType = Api.IntegrationSources.MergeCrm | Api.IntegrationSources.MergeAccounting;

export function useMergeIntegration({
	pendingAction,
	provider,
	impersonationContext,
	onAuthError,
	onStatusError,
}: {
	pendingAction?: Api.PendingActions;
	provider: TMergeProviderType;
	impersonationContext?: Api.IImpersonationContext;
	onAuthError?: (error: Api.IOperationResultNoValue) => void;
	onStatusError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	const accountIntegrations = (impersonationContext?.account || userSession?.account)?.integrations;

	let providerName: string = null;
	switch (provider) {
		case Api.IntegrationSources.MergeCrm: {
			providerName = accountIntegrations?.mergeCrm?.mergeCrmProvider;
			break;
		}
		case Api.IntegrationSources.MergeAccounting: {
			providerName = accountIntegrations?.mergeAccounting?.mergeAccountingProvider;
			break;
		}
		default: {
			break;
		}
	}

	const { isLoading: isLoadingStatus, data: status } = useIntegrationStatus({
		onError: onStatusError,
		provider,
		refetchOnWindowFocus: false,

		userSession,
	});

	const { isLoading: isDisablingIntegration, mutateAsync: disableAsync } = useDisableIntegrationMutation({
		impersonationContext,
	});

	const { isReady: isMergeReady, openMergeAuthFlow } = useMergeAuth({
		integrationName: providerName,
		integrationSource: provider,
		onError: onAuthError,
		onSuccess: () => {
			invalidateIntegrationStatus(provider);
			if (pendingAction) {
				PersistentStorageManager.local.set(pendingAction, 'true');
			}
		},
		userSession,
	});

	const disableIntegrationAsync = React.useCallback(async () => {
		await disableAsync(provider);
		invalidateIntegrationStatus(provider);
	}, [disableAsync, provider]);

	const providerDisplayName = getMergeLinkProviderDisplayName(accountIntegrations);

	return {
		disableIntegrationAsync,
		isDisablingIntegration,
		isLoadingStatus,
		isMergeReady,
		openMergeAuthFlow,
		providerDisplayName,
		providerName,
		status,
	} as const;
}
