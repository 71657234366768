import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useAutomationTemplateIdQuery } from '../../../../queries';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { SendFromDropdown } from '../../../components/SendFromDropdown';
import {
	CampaignRecipientsSearch,
	CampaignRecipientsSearchResult,
} from '../../../components/campaigns/CampaignRecipientsSearch';
import { bs } from '../../../styles/styles';
import { CampaignComposerBaseLayout } from '../../BulkHwcPages/presentation';
import { TextCampaignTemplateBody } from './TextCampaignTemplateBody';

export function TextCampaignTemplateSearchPage() {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const location = useLocation<{
		initialSendOnBehalf?: Api.ISendOnBehalfPermission;
		initialOwnershipFilter?: Api.IOwnershipFilter;
		initialSendFromUserId?: string;
	}>();
	const { automationTemplateId } = useParams<{ automationTemplateId: string }>();
	const automationTemplateIdQuery = useAutomationTemplateIdQuery({
		id: automationTemplateId,
	});
	const [sendFrom, setSendFrom] = React.useState<Api.ISendOnBehalfPermission>(
		location.state?.initialSendOnBehalf ?? userSession.defaultSendOnBehalfPermissions[0]
	);
	const [ownershipFilter, setOwnershipFilter] = React.useState(location.state?.initialOwnershipFilter);
	const [sendFromUserId, setSendFromUserId] = React.useState<string | null>(
		location.state?.initialSendFromUserId ?? null
	);
	const onSendOnBehalf = (option: Api.ISendOnBehalfPermission, user?: Api.IUser) => {
		setSendFrom(option);
		setSendFromUserId(user?.id);
		setOwnershipFilter(option?.requiredFilter);
	};
	const handleNextClick = (value: CampaignRecipientsSearchResult) => {
		const initialContactIdsToInclude = value.selectedContacts.map(c => c.id);
		if (
			initialContactIdsToInclude.length > 0 &&
			!value.selectedContactSearch?.filter?.criteria &&
			value.selectedTags.length === 0
		) {
			fullscreenModal.history.push({
				pathname: `/texting-campaign/template-contacts/${automationTemplateId}`,
				state: {
					contactIds: initialContactIdsToInclude,
					initialSendOnBehalf: sendFrom,
					initialSendFromUserId: sendFromUserId,
					initialOwnershipFilter: ownershipFilter,
				},
			});
			return;
		}
		const selectedCriteria = value.selectedContactSearch;
		let initialContactFilterCriteriaList: Api.IContactFilterCriteria[];
		if (selectedCriteria?.filter?.criteria) {
			initialContactFilterCriteriaList = selectedCriteria.filter.criteria;
		} else if (value.selectedTags.length > 0) {
			initialContactFilterCriteriaList = [
				{
					op: 'Or',
					criteria: value.selectedTags.map(tag => ({
						property: Api.ContactFilterCriteriaProperty.Tag,
						value: tag.tag,
					})),
				},
			];
		}
		fullscreenModal.history.push({
			pathname: `/texting-campaign/template-filter/${automationTemplateId}`,
			state: {
				initialContactIdsToInclude,
				initialFilterCriteriaList: initialContactFilterCriteriaList,
				initialSendOnBehalf: sendFrom,
				initialSendFromUserId: sendFromUserId,
				initialOwnershipFilter: selectedCriteria?.ownershipFilter ?? ownershipFilter,
			},
		});
	};
	return (
		<>
			<MultiContainerHeader
				fullscreenHeader='New Texting Campaign'
				onFullscreenRequestBack={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
				onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
			/>
			<CampaignComposerBaseLayout
				mainHeader={
					<div className={css(bs.px3, bs.py5, bs.flex, bs.gap2, bs.flexCol)}>
						<div className={css(bs.mb2)}>
							{automationTemplateIdQuery.status === 'success' ? (
								<h2 className={css(bs.m0)}>{automationTemplateIdQuery.data?.name}</h2>
							) : (
								<LoadingSpinner type='small' />
							)}
						</div>
						<SendFromDropdown
							disabled={!userSession.canSendOnBehalf}
							sendFromOption={sendFrom?.sender}
							canSendOnBehalf={userSession.canSendOnBehalf}
							allowContactBasedOptions
							allowContactOwnerOption
							sendOnBehalfOf={onSendOnBehalf}
						/>
					</div>
				}
				sideBar={<CampaignRecipientsSearch onSubmit={handleNextClick} />}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<TextCampaignTemplateBody
						automationTemplateId={automationTemplateId}
						onDuplicateClick={({ title, contentEditorState, attachments }) => {
							fullscreenModal.history.push({
								pathname: `/texting-campaign/search`,
								state: {
									initialSendOnBehalf: sendFrom,
									initialSendFromUserId: sendFromUserId,
									initialOwnershipFilter: ownershipFilter,
									initialCampaignTitle: title,
									initialContentEditorState: contentEditorState,
									initialStoredAttachments: attachments,
								},
							});
						}}
					/>
				</>
			</CampaignComposerBaseLayout>
		</>
	);
}
