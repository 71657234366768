import { UserConfigurableIntegrationType, UserViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps } from '../../../../models/Logging';
import { INavigationItemProps } from '../../../containers/MainContainer';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TextInput } from '../../TextInput';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		RouteComponentProps<any>,
		INavigationItemProps,
		IErrorMessageComponentProps,
		IToasterComponentProps {
	className?: string;
	inputProps: {
		id: string;
		placeholder?: string;
		type: string;
		defaultValue?: string;
	};
	integration: UserConfigurableIntegrationType;
	loading?: boolean;
	logo?: JSX.Element;
	mainCopy?: string;
	onCancel?(integration: UserConfigurableIntegrationType): void;
	onSave(integration: UserConfigurableIntegrationType, value: string): void;
	secondaryCopy?: string;
	styles?: StyleDeclarationValue[];
}

const UserConfigurableIntegrationBase: React.FC<IProps> = ({
	className,
	inputProps,
	integration,
	loading,
	logo,
	mainCopy,
	onCancel,
	onSave,
	routeContainerClassName,
	secondaryCopy,
	styles,
	userSession,
}) => {
	const userViewModel = useRef(new UserViewModel(userSession, userSession.user));
	const [inputValue, setInputValue] = useState(inputProps?.defaultValue || '');

	const onBccEmailTextChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value || '');
	}, []);

	const onCancelClicked = useCallback(() => {
		setInputValue(inputProps?.defaultValue ?? '');
		onCancel?.(integration);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [integration]);

	const onSaveClicked = useCallback(() => {
		onSave?.(integration, inputValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [integration, inputValue]);

	return (
		<div
			className={`${css(styleSheet.container, ...(styles || []))} integrations-container ${
				routeContainerClassName || ''
			} ${className || ''}`}
		>
			{logo && <div className={css(styleSheet.logo)}>{logo}</div>}
			{mainCopy && <div className={css(styleSheet.text)}>{mainCopy}</div>}
			<div className={css(styleSheet.inputContainer)}>
				<TextInput
					className={css(styleSheet.input)}
					inputId={inputProps.id}
					onChange={onBccEmailTextChanged}
					placeholder={inputProps?.placeholder || ''}
					type={inputProps.type}
					value={inputValue}
				/>
			</div>
			{secondaryCopy && <div className={css(styleSheet.text)}>{secondaryCopy}</div>}
			<div className={css(baseStyleSheet.horizontalStack, styleSheet.buttonContainer)}>
				<button
					className={css(baseStyleSheet.ctaButton)}
					disabled={userViewModel.current.isBusy || inputValue === (inputProps?.defaultValue || '')}
					onClick={onSaveClicked}
				>
					<span>Save</span>
				</button>
				<button
					className={css(baseStyleSheet.ctaButtonReverse)}
					disabled={userViewModel.current.isBusy || inputValue === (inputProps?.defaultValue || '')}
					onClick={onCancelClicked}
				>
					<span>Cancel</span>
				</button>
			</div>
			{loading && <LoadingSpinner type='large' />}
		</div>
	);
};

export const UserConfigurableIntegration = observer(UserConfigurableIntegrationBase);
