import * as Api from '@ViewModels';
import { TemplateType } from '@ViewModels';
import * as React from 'react';
import { ICreateCampaignRequest, ILocationState, TemplateOrTemplateFilter } from '../../../../../models';
import { BlogPostRequest } from '../../../../../models/Blogs';
import { useEventLogging } from '../../../../../models/Logging';
import { useFullscreenModal } from '../../../../../models/hooks/appStateHooks';

export function useLaunchCampaignFromTemplate({
	queryCommand: templateCommand,
	eventLoggingCategory,
}: {
	queryCommand: Api.ITemplateCommand;
	eventLoggingCategory: string;
}) {
	const fullscreenModal = useFullscreenModal();
	const { logEvent } = useEventLogging(eventLoggingCategory);
	React.useEffect(() => {
		if (templateCommand?.command === 'templates') {
			const isSocial = templateCommand.template.templateType === TemplateType.SocialMediaPost;
			const isBlog = templateCommand.template.templateType === TemplateType.Blog;

			if (templateCommand) {
				const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter> | BlogPostRequest> = {
					model: {
						context: templateCommand.template,
						schedule: {
							criteria:
								templateCommand.sendOption === 'now'
									? Api.ScheduleCriteria.Immediately
									: Api.ScheduleCriteria.StartAfter,
							startDate: templateCommand.date,
						},
						tags: templateCommand.tags,
						type: 'Template',
					},
				};
				if (isBlog) {
					Object.assign(locationState.model, {
						template: templateCommand.template,
					});
				}
				fullscreenModal?.history.push({
					pathname: isSocial
						? `/social-media/post/create/from-template`
						: isBlog
							? `/blog/post/create-from-template/${templateCommand.id}`
							: '/email/campaigns/create/from-template',
					state: locationState,
				});
				logEvent('AppLink-Template', {
					id: templateCommand.id,
					op: templateCommand.op,
				});
			}
		}
	}, [templateCommand, logEvent, fullscreenModal]);
}
