import { styleSheet as templateCardStyleSheet } from '@WebComponents/templateCards/HtmlNewsletterTemplateCard/styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { LightningBulbIcon } from '../../components/svgs/icons/LightBulbIcon';
import { baseStyleSheet, bs } from '../../styles/styles';
import { SearchKeywords } from '../Campaigns/presentation';
import { styleSheet } from './styles';

export const CreateHtmlNewsletterTemplateCard: React.FC<{
	disabled?: boolean;
	isCreatingNewTemplate?: boolean;
	onCreateNewClicked?(e: React.MouseEvent): void;
}> = ({ onCreateNewClicked, disabled }) => {
	return (
		<div className={css(templateCardStyleSheet.templateCard, styleSheet.createTemplateCard)}>
			<div>Custom Template</div>
			<div>Want to build a new HTML template from scratch?</div>
			<div className={css(templateCardStyleSheet.templateCardFooter)}>
				<button className={css(baseStyleSheet.ctaButton)} disabled={disabled} onClick={onCreateNewClicked}>
					<span>Build HTML Template</span>
				</button>
			</div>
		</div>
	);
};

export const HtmlNewsletterTemplatesHeader: React.FC<{
	keywordSearch?: string;
	onKeywordChange: (keyword: string) => void;
}> = ({ keywordSearch, onKeywordChange }) => {
	const infoGraphicStyles = React.useRef([styleSheet.infoHeaderGraphic]);
	return (
		<>
			<div className={css(styleSheet.infoHeader)}>
				<LightningBulbIcon styles={infoGraphicStyles.current} />
				<div className={css(styleSheet.infoHeaderContent)}>
					<div className={css(styleSheet.infoHeaderContentBold)}>
						When should you use HTML Newsletters instead of plain-text campaigns?
					</div>
					<div>
						Plain-text campaigns are great for one-on-one keep-in-touches since it has 42% higher response rates.
						<br />
						HTML Newsletters are great for market updates that aren’t meant to sound personal.
					</div>
				</div>
			</div>
			<div className={css(bs.flex, bs.flexRow, bs.gap8, bs.itemsCenter, styleSheet.header)}>
				<h2>HTML Newsletters</h2>
				<SearchKeywords onChange={onKeywordChange} value={keywordSearch} />
			</div>
		</>
	);
};
