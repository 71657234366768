import { css, StyleSheet } from 'aphrodite';
import { FC } from 'react';
import { ButtonBase } from '../../../admin/components/blogs';
import { background, black, brandPrimary, brandPrimaryActive, potionPink } from '../../styles/colors';
import { XIcon } from '../svgs/icons/XIcon';

interface PillProps {
	label: string;
	disabled?: boolean;
	onRemove?: (value: string) => void;
	onClick?: (value: string) => void;
	variant?: 'tag' | 'label' | 'keyword';
}

export const Pill: FC<PillProps> = ({ disabled, label, onClick, onRemove, variant = 'tag' }) => {
	const iconFill = variant === 'keyword' ? potionPink : brandPrimary;

	const handleClick = () => {
		onClick?.(label);
	};
	const handleRemove = () => {
		onRemove?.(label);
	};

	const clickable = !!onClick || !!onRemove;

	return (
		<div className={css(pillStyles.container, pillStyles[variant], clickable && pillStyles.editable)}>
			{onClick ? (
				<ButtonBase className={css(pillStyles.labelButton)} disabled={disabled} onClick={handleClick}>
					{label}
				</ButtonBase>
			) : (
				label
			)}
			{!!onRemove && (
				<ButtonBase disabled={disabled} onClick={handleRemove}>
					<span className={css(pillStyles.closeIcon)}>
						<XIcon fillColor={iconFill} height={12} width={12} />
					</span>
				</ButtonBase>
			)}
		</div>
	);
};

const pillStyles = StyleSheet.create({
	closeIcon: {
		marginLeft: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		fontFamily: 'Sora',
	},
	tag: {
		backgroundColor: `${brandPrimary}1A`,
		borderRadius: 18,
		padding: '6px 12px',
	},
	label: {
		backgroundColor: background,
		borderRadius: 13,
	},
	keyword: {
		backgroundColor: `${potionPink}26`,
		borderRadius: 6,
		color: potionPink,
		padding: '4px 8px',
		fontSize: 14,
		borderColor: potionPink,
	},
	editable: {
		':hover': {
			boxShadow: `1px 1px 2px 1px ${black}1A`,
		},
		':focus': {
			backgroundColor: 'lime',
		},
		':active': {
			backgroundColor: `${brandPrimaryActive}1A`,
		},
	},
	labelButton: {
		color: 'inherit',
		textWrap: 'none',
	},
});
