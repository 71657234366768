import { useEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import FlipMove from 'react-flip-move';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import { invalidateGenericKeyDates, useGenericKeyDates } from '../../../queries';
import {
	ComposeEmailToWithGuideViewModel,
	ContactViewModel,
	IContactWithKeyFact,
	IUpcomingKeyDateDashboardCard,
	ResourceSelectorId,
} from '../../../viewmodels/AppViewModels';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { EmptyState } from '../../components/EmptyState';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DarkMainContainerBackground } from '../../components/MainContainerBackground';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../components/Portal';
import { GenericKeyDatesCard } from '../../components/cards/GenericKeyDatesCard';
import { SingleEmailGuideModal } from '../../components/email/guide/SingleEmailGuideModal';
import { CalendarInCircleGraphic } from '../../components/svgs/graphics/CalendarInCircleGraphic';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const GenericKeyDates = ({ className, styles }: IProps) => {
	const userSession = useUserSession();
	const [singleEmailGuideViewModel] = React.useState(new ComposeEmailToWithGuideViewModel(userSession));
	const { logApiError } = useEventLogging('GenericKeyDates');
	const errorMessages = useErrorMessages();
	const genericKeyDates = useGenericKeyDates({
		handleError: err => {
			errorMessages.pushApiError(err);
			logApiError('LoadGenericKeyDates-Error', err);
		},
		userSession,
	});
	const onRequestRemoveItem = (contact: IContactWithKeyFact) => async () => {
		try {
			const contactVM = new ContactViewModel(userSession, contact.contact);

			await contactVM.suppressKeyFact(contact.keyFact.id);
			invalidateGenericKeyDates();
		} catch (err) {
			errorMessages.pushApiError(err);

			logApiError('SuppressGenericKeyDates-Error', err);
		}
	};
	const onEmailModalFinished = (didSend: boolean) => {
		if (didSend) {
			invalidateGenericKeyDates();
		}
	};
	const onSendMessageClicked = (contact: IContactWithKeyFact) => async () => {
		const fullContact = new ContactViewModel(userSession, {
			id: contact.contact.id,
		});
		await fullContact.load();

		const upcomingKeyDate: IUpcomingKeyDateDashboardCard = {
			contacts: [contact],
			total: 1,
			type: ResourceSelectorId.GenericKeyDates,
		};

		await singleEmailGuideViewModel.openForKeyDateSend(fullContact, upcomingKeyDate, onEmailModalFinished);
	};
	return (
		<div
			className={`${css(styleSheet.container, ...(styles || []))} recently-viewed-emails main-container-child ${
				className || ''
			}`}
		>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<NavigationBreadcrumbsBar currentLocationName='Upcoming Key Dates' />
			</Portal>
			<DarkMainContainerBackground />
			<SingleEmailGuideModal guideViewModel={singleEmailGuideViewModel} />
			{genericKeyDates.isLoading ? <LoadingSpinner type='large' /> : null}
			{genericKeyDates.status === 'success' ? (
				genericKeyDates.data.contacts.length > 0 ? (
					<FlipMove
						enterAnimation={true}
						leaveAnimation={true}
						staggerDelayBy={50}
						staggerDurationBy={10}
						appearAnimation={false}
					>
						{genericKeyDates.data.contacts.map(x => {
							return (
								<GenericKeyDatesCard
									contact={x}
									className={css(styleSheet.item)}
									key={x.keyFact.id}
									onRequestRemove={onRequestRemoveItem(x)}
									onSendEmailClicked={onSendMessageClicked(x)}
								/>
							);
						})}
					</FlipMove>
				) : (
					<EmptyState
						image={<CalendarInCircleGraphic />}
						text={"You don't have any upcoming key dates"}
						subtext='More dates will appear on your dashboard as they approach.'
					/>
				)
			) : null}
		</div>
	);
};
