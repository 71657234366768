import { StyleSheet } from 'aphrodite';
import { hexToRGBA } from '../../../models/UiUtils';
import { brandPrimary, brandSecondary, destructive, mention, mentionDark, titles } from '../../styles/colors';
import { CssSelectors, Layouts } from '../../styles/styles';

const sectionSpacing = 18;

export const styleSheet = StyleSheet.create({
	annotation: {
		fontStyle: 'italic',
	},
	archivedLabel: {
		color: destructive,
		textTransform: 'uppercase',
	},
	body: {
		display: 'flex',
		marginBottom: 22,
	},
	satisfactionSurveyContainer: {
		alignItems: 'center',
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 14,
		paddingBottom: 30,
		...CssSelectors.allChildren(
			{
				maxWidth: 922,
				minWidth: '75%',
			},
			'*'
		),
	},
	content: {
		boxSizing: 'border-box',
		padding: '0 12.5% 10% 12.5%',
	},
	copyLinkButton: {
		...Layouts.horizontalStack(10),
		alignItems: 'center',
		display: 'inline-flex',
	},
	checkboxLabel: {
		display: 'inline-flex',
		transform: 'translateY(-18px)',
	},
	deleteButton: {
		fontSize: 0,
		color: 'transparent',
		position: 'absolute',
		top: 35,
		right: 0,
	},
	disabled: {
		opacity: 0.5,
		pointerEvents: 'none',
	},
	divider: {
		border: `1px solid #D3DBDE`,
	},
	fieldGroup: {
		...Layouts.verticalStack(15),
	},
	bottomSection: {
		width: '100%',
		boxSizing: 'border-box',
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '20px 0px',
	},
	headerActions: {
		...Layouts.horizontalStack(30),
	},
	introTextArea: {
		height: 66,
	},
	thresholdInput: {
		width: 57,
		...CssSelectors.allDescendants(
			{
				width: 37,
			},
			'input'
		),
	},
	label: {
		color: '#858585',
		fontSize: 14,
	},
	layoutFlexRowSpaceBetween: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	questionWrap: {
		position: 'relative',
		paddingRight: 40,
		marginBottom: 18,
	},
	questionInput: {
		marginBottom: 8,
	},
	radioButtons: {
		...Layouts.verticalStack(8),
	},
	reviewRequestLinkTypeInput: {
		width: '100%',
	},
	reviewRequestMessage: {
		height: 140,
	},
	section: {
		':not(:first-child)': {
			marginLeft: sectionSpacing,
		},
		border: `1px solid ${mentionDark}`,
		boxShadow: `0 0 12px 0 ${mention}`,
		padding: '44px 52px',
		width: `calc(50% - ${sectionSpacing / 2}px)`,
		...CssSelectors.allChildren(
			{
				marginBottom: 20,
			},
			':not(:last-child)'
		),
	},
	sectionFullWidth: {
		width: '100%',
		boxSizing: 'border-box',
		marginBottom: 20,
		position: 'relative',
	},
	sectionTitle: {
		color: brandSecondary,
		fontSize: 18,
		margin: 0,
	},
	starRating: {
		...Layouts.horizontalStack(4),
		fontSize: 18,
	},
	checkboxText: {
		...Layouts.horizontalStack(4),
		fontSize: 14,
		display: 'inline-flex',
	},
	toggleText: {
		color: titles,
		fontSize: 14,
	},
	addQuestionButton: {
		color: brandPrimary,
		padding: 12,
		width: '100%',
		border: `2px dashed ${brandPrimary}`,
		borderRadius: 8,
		textAlign: 'center',
		background: hexToRGBA(brandPrimary, 0.1),
		transition: 'background 300ms ease-in-out',
		':hover': {
			background: hexToRGBA(brandPrimary, 0.2),
		},
		':focus': {
			background: hexToRGBA(brandPrimary, 0.2),
		},
	},
	fieldset: {
		border: 0,
	},
	errors: {
		color: destructive,
		fontSize: 12,
		margin: 0,
	},
	previewButton: {
		color: brandPrimary,
		fontSize: 14,
		...CssSelectors.allChildren(
			{
				display: 'flex',
				alignItems: 'center',
				gap: 8,
			},
			'span'
		),
	},
});
