import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { getDefaultDateStringValue, getDisplayName } from '../../../../../models/UiUtils';
import { useToaster } from '../../../../../models/hooks/appStateHooks';
import { useCreateDonationMutation, useUpdateDonationMutation } from '../../../../../queries';
import CalendarIconUrl from '../../../../assets/icon_calendar.svg';
import { CloseButton } from '../../../../components/CloseButton';
import { DayPicker } from '../../../../components/DayPicker';
import { DeprecatedPopover, PopoverType } from '../../../../components/DeprecatedPopover';
import { Modal } from '../../../../components/Modal';
import { TextInput } from '../../../../components/TextInput';
import { SimpleAutoCompleteSearchField } from '../../../../components/autocomplete/SimpleAutoCompleteSearchField';
import { DonationCampaignSearch } from '../../../../components/dataBoards/donations/DonationCampaignSearch';
import { ClearFieldIcon } from '../../../../components/svgs/icons/ClearFieldIcon';
import { DonationIcon } from '../../../../components/svgs/icons/DonationIcon';
import { white } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from '../../EditModal/styles';

export const EditDonationModal = ({
	initialDonation,
	isOpen,
	setIsOpen,
	onSave,
	onCancel,
}: {
	initialDonation: Api.IDonation;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSave: (donation: Api.IDonation) => void;
	onCancel: () => void;
}) => {
	const toaster = useToaster();
	const [donorName, setDonorName] = React.useState(initialDonation.donorName ?? '');
	const [contact, setContact] = React.useState<Api.IContact>(initialDonation.contact ?? null);
	const [amount, setAmount] = React.useState<number>(initialDonation.amount ?? 0);
	const [campaign, setCampaign] = React.useState<Api.IDonationCampaign>(initialDonation.campaign ?? null);
	const [donationDate, setDonationDate] = React.useState<Date>(
		initialDonation.date ? moment(initialDonation.date).toDate() : null
	);
	const [donationDatePickerOpen, setDonationDatePickerOpen] = React.useState(false);

	const isEditing = !!initialDonation.id;

	const createDonationMutation = useCreateDonationMutation({
		onSuccess: (donation: Api.IDonation) => {
			toaster.push({
				message: 'Donation successfully created',
				type: 'successMessage',
			});
			onSave(donation);
		},
	});

	const updateDonationMutation = useUpdateDonationMutation({
		onSuccess: (donation: Api.IDonation) => {
			toaster.push({
				message: 'Donation successfully updated',
				type: 'successMessage',
			});
			onSave(donation);
		},
	});

	const save = () => {
		const campaignId = campaign?.id;

		const updatedDonation = {
			...initialDonation,
			donorName,
			amount,
			campaignId,
			date: moment(donationDate).format('YYYY-MM-DD'),
			contactId: contact.id,
		};

		if (isEditing) {
			updateDonationMutation.mutateAsync({ donation: updatedDonation });
			return;
		}
		createDonationMutation.mutateAsync({
			donation: updatedDonation,
		});
	};

	const cancel = () => {
		onCancel();
		setIsOpen(false);
	};

	const onContactCleared = () => {
		if (donorName === getDisplayName(contact)) {
			setDonorName('');
		}

		setContact(null);
	};

	const onContactSelected = ({ selection }: { selection: Api.IEntity }) => {
		setContact(selection);

		if (!donorName) {
			setDonorName(getDisplayName(selection));
		}
	};

	const onDonationDateSelected = (value: Date) => {
		setDonationDate(value);
	};

	const toggleDonationDatePickerOpen = () => {
		setDonationDatePickerOpen(!donationDatePickerOpen);
	};

	const clearDonationDate = (e: React.MouseEvent<HTMLElement>) => {
		// The clear button is nested in a clickable control, do not open the calendar if clear is clicked
		e.stopPropagation();
		e.preventDefault();

		setDonationDate(null);
	};

	const selectDonationCampaign = (donationCampaign: Api.IDonationCampaign) => {
		setCampaign(donationCampaign);
	};

	const onCreateCampaign = (result: Api.IDonationCampaign) => {
		setCampaign(result);
	};

	const canSave = !!donorName && !!contact && !!amount && amount > 0 && !!donationDate;

	const onClose = () => {
		setIsOpen(false);
	};

	return (
		<Modal
			className={css(styleSheet.modalContainer)}
			isOpen={isOpen}
			useDefaultHeader={true}
			onRequestClose={cancel}
			headerAccessory={
				<div className={css(styleSheet.modalHeader)}>
					<DonationIcon height={18} width={20} />
					<CloseButton onClick={onClose} fillColor={white} />
				</div>
			}
		>
			<article className={css(styleSheet.container)}>
				<h3 className={css(styleSheet.header)}>{`${isEditing ? 'Edit' : 'Add New'} Donation`}</h3>

				<div className={css(styleSheet.formControl)}>
					<label className={css(styleSheet.label)}>Contact</label>

					<div className={css(styleSheet.formControlInputWrap)}>
						<SimpleAutoCompleteSearchField
							onClear={onContactCleared}
							// @ts-ignore
							onItemSelected={onContactSelected}
							resultsLimit={5}
							pageSize={5}
							placeholder='Search'
							style={styleSheet.contactSearchField}
							type={Api.ResourceAutoCompleteViewModelType.Contact}
							initialSearchQuery={initialDonation.contact ? getDisplayName(initialDonation.contact) : ''}
						/>
					</div>
				</div>

				<div className={css(styleSheet.formControl)}>
					<label className={css(styleSheet.label)}>Donor Name / Company</label>

					<div className={css(styleSheet.formControlInputWrap)}>
						<TextInput
							inputId='donation-modal-edit-name'
							onChange={e => setDonorName(e.target.value)}
							type='text'
							value={donorName}
							placeholder='Name'
						/>
					</div>
				</div>

				<div className={css(styleSheet.formControl)}>
					<label className={css(styleSheet.label)}>Donation Amount</label>

					<div className={css(styleSheet.formControlInputWrap)}>
						<TextInput
							inputId='donation-modal-edit-amount'
							onChange={e => setAmount(parseFloat(e.target.value))}
							type='number'
							value={amount}
							placeholder='$'
							min={0}
						/>
					</div>
				</div>

				<div className={css(styleSheet.formControl)}>
					<label className={css(styleSheet.label)}>Campaign</label>

					<DonationCampaignSearch
						onSelectDonationCampaign={selectDonationCampaign}
						initialSearchFragment={initialDonation.campaign?.name}
						onCreateCampaign={onCreateCampaign}
						className={css(baseStyleSheet.fullWidth)}
					/>
				</div>

				<div className={css(styleSheet.formControl)}>
					<label className={css(styleSheet.label)}>Donation Date</label>

					<div
						className={css(styleSheet.formControlInputWrap, styleSheet.clickable)}
						onClick={toggleDonationDatePickerOpen}
					>
						<div className={css(styleSheet.dateContainer)}>
							<div className={css(styleSheet.dateFieldButton)}>
								<DeprecatedPopover
									anchor={<img src={CalendarIconUrl} />}
									dismissOnClickOutside={true}
									isOpen={donationDatePickerOpen}
									onRequestClose={toggleDonationDatePickerOpen}
									preferredPlacement='above'
									type={PopoverType.white}
								>
									<DayPicker allowPastDates={true} onDayClick={onDonationDateSelected} selectedDays={donationDate} />
								</DeprecatedPopover>
								{!!donationDate && <span>{getDefaultDateStringValue(donationDate)}</span>}
							</div>
							<button onClick={(e: React.MouseEvent<HTMLElement>) => clearDonationDate(e)}>
								<ClearFieldIcon />
							</button>
						</div>
					</div>
				</div>

				<footer className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={save} disabled={!canSave}>
						Save
					</button>
					<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={cancel}>
						Cancel
					</button>
				</footer>
			</article>
		</Modal>
	);
};
