import { useEventLogging } from '@AppModels/Logging';
import {
	AllEmailScanners,
	ConfigurableIntegrationType,
	EmailScannerId,
	ExternalIntegrationType,
	IUserPreferences,
	IntegrationType,
	UserConfigurableIntegrationType,
} from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { getMergeLinkProviderDisplayName, isValidEmail } from '../../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useUserPreferencesMutation } from '../../../../queries';
import adwerxLogo from '../../../assets/logo_adwerx.png';
import { AppBarNavigationItemPortalDestinationId } from '../../../components/AppBar';
import { MainContainerPageShell } from '../../../components/AppShell';
import { NavigationBreadcrumbsBar } from '../../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../../components/Portal';
import { PrivateRoute } from '../../../components/PrivateRoute';
import { EmailScannerSettings } from '../../../components/integrations/EmailScannerSettings';
import { ExternalIntegrations } from '../../../components/integrations/ExternalIntegrations';
import { IntegrationCards } from '../../../components/integrations/IntegrationCards';
import { UserConfigurableIntegration } from '../../../components/integrations/UserConfigurableIntegration';
import { Clio } from '../Clio';
import { DonorPerfect } from '../DonorPerfect';
import { GoogleBusinessProfile } from '../GoogleBusinessProfile';
import { ManageAPIKeys } from '../ManageAPIKeys';
import { MergeAccountingIntegration } from '../MergeAccounting';
import { MergeCrmIntegration } from '../MergeCrm';
import { MicrosoftDynamics365Sales } from '../MicrosoftDynamics365Sales';
import { MyCase } from '../MyCase';
import { PracticePanther } from '../PracticePanther';
import { QQ } from '../QQ';
import { Redtail } from '../Redtail';
import { Wealthbox } from '../Wealthbox';
import { styleSheet } from './styles';
const breadcrumb: Record<string, string> = {
	crm: 'CRM BCC Integration',
	mycase: 'MyCase',
	googlebusiness: 'Google Business',
	publicapi: 'API Keys',
	practicepanther: 'Practice Panther',
	microsoftdynamics365sales: 'Microsoft Dynamics 365 Sales',
};

function _Integrations() {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const match = useRouteMatch();
	const { logPageView, logEvent } = useEventLogging('UserConfigurableIntegration');
	const toaster = useToaster();
	const history = useHistory();
	const mutation = useUserPreferencesMutation({
		onError: error => {
			logEvent('Save-Error', error);

			errorMessages.push({
				messages: [error.systemMessage],
			});
		},
		onSuccess: userPrefs => {
			userSession.user.userPreferences = userPrefs;

			toaster.push({
				message: 'Changes were saved successfully!',
				type: 'successMessage',
			});
		},
	});
	const onSave = (integration: UserConfigurableIntegrationType, value: string) => {
		if (value === '' || isValidEmail(value)) {
			let preferences: IUserPreferences;
			if (integration === UserConfigurableIntegrationType.AdWerx) {
				preferences = { adwerxBccEmail: value };
			} else if (integration === UserConfigurableIntegrationType.CRM) {
				preferences = { bccEmail: value };
			}

			if (preferences) {
				logEvent('Save');
				mutation.mutate({ preferences });
			}
		} else {
			errorMessages.push({
				messages: ['Please enter a valid email address'],
			});
		}
	};
	const pathComponentNameProvider = (pathComponent: string) => {
		if (pathComponent in breadcrumb) {
			return breadcrumb[pathComponent];
		}

		switch (pathComponent.toLocaleLowerCase()) {
			case 'qqcatalyst': {
				return 'QQCatalyst';
			}
			case ConfigurableIntegrationType.MergeCrm.toLocaleLowerCase():
			case ConfigurableIntegrationType.MergeAccounting.toLocaleLowerCase(): {
				return getMergeLinkProviderDisplayName(userSession?.account?.integrations);
			}
			default: {
				const emailScanner = AllEmailScanners.find(x => x.id === pathComponent);
				return (
					emailScanner?.name ||
					(pathComponent ? `${pathComponent.charAt(0).toUpperCase()}${pathComponent.substring(1)}` : pathComponent)
				);
			}
		}
	};
	const onRenderEmailScannerToggle = (emailScannerId: EmailScannerId) =>
		function renderEmailScannerToggle(props: RouteComponentProps<any>) {
			logPageView(props.location.pathname);

			return (
				<div className={css(styleSheet.emailToggleContainer)}>
					<EmailScannerSettings emailScannerId={emailScannerId} />
				</div>
			);
		};
	const onRenderConfigurableIntegration = (integration: ConfigurableIntegrationType) =>
		function renderConfigurableIntegration(props: RouteComponentProps<any>) {
			logPageView(props.location.pathname);

			switch (integration) {
				case ConfigurableIntegrationType.Redtail:
					return <Redtail />;
				case ConfigurableIntegrationType.Clio:
					return <Clio />;
				case ConfigurableIntegrationType.GoogleBusinessProfile:
					return <GoogleBusinessProfile />;
				case ConfigurableIntegrationType.MyCase:
					return <MyCase />;
				case ConfigurableIntegrationType.Wealthbox:
					return <Wealthbox />;
				case ConfigurableIntegrationType.QQ:
					return <QQ />;
				case ConfigurableIntegrationType.MergeCrm:
					return userSession?.account?.integrations?.mergeCrm?.enabled ? <MergeCrmIntegration /> : null;
				case ConfigurableIntegrationType.MergeAccounting:
					return userSession?.account?.integrations?.mergeAccounting?.enabled ? <MergeAccountingIntegration /> : null;
				case ConfigurableIntegrationType.PublicAPI:
					return <ManageAPIKeys />;
				case ConfigurableIntegrationType.DonorPerfect:
					return <DonorPerfect />;
				default:
					return null;
			}
		};

	const onRenderExternalIntegration = (integration: ExternalIntegrationType) =>
		function renderExternalIntegration(props: RouteComponentProps<any>) {
			logPageView(props.location.pathname);

			switch (integration) {
				case ExternalIntegrationType.PracticePanther:
					return <PracticePanther />;
				case ExternalIntegrationType.MicrosoftDynamics365Sales:
					return <MicrosoftDynamics365Sales />;
				default:
					return null;
			}
		};

	const onRenderUserConfigurableIntegration = (integration: UserConfigurableIntegrationType) =>
		function renderUserConfigurableIntegration(props: RouteComponentProps<any>) {
			logPageView(props.location.pathname);

			switch (integration) {
				case UserConfigurableIntegrationType.AdWerx:
					return (
						<UserConfigurableIntegration
							{...props}
							inputProps={{
								defaultValue: userSession.user?.userPreferences?.adwerxBccEmail,
								id: 'adwerx-integration-email-input',
								placeholder: 'Adwerx BCC Email',
								type: 'email',
							}}
							integration={UserConfigurableIntegrationType.AdWerx}
							loading={mutation.isLoading}
							logo={<img alt='Adwerx Logo' src={adwerxLogo} />}
							mainCopy='BCC an Adwerx email address with our Levitate group emails and the contacts would get added to an Adwerx campaign for ad targeting.'
							onSave={onSave}
							secondaryCopy={"This email will be BCC'd with every email you send with Levitate."}
							userSession={userSession}
						/>
					);
				case UserConfigurableIntegrationType.CRM:
					return (
						<UserConfigurableIntegration
							{...props}
							inputProps={{
								defaultValue: userSession.user?.userPreferences?.bccEmail,
								id: 'crm-integration-bcc-email-input',
								placeholder: 'BCC Email',
								type: 'email',
							}}
							integration={UserConfigurableIntegrationType.CRM}
							loading={mutation.isLoading}
							logo={<div>CRM BCC Integration</div>}
							mainCopy='When you are sending messages with Levitate, you can have all your emails forwarded to your CRM. Please enter your CRM’s BCC email below, and check the BCC box when sending messages.'
							onSave={onSave}
							userSession={userSession}
						/>
					);
				default:
					return null;
			}
		};

	const onManageClicked = (integration: IntegrationType) => {
		if (
			integration in UserConfigurableIntegrationType ||
			integration in EmailScannerId ||
			Object.values(ConfigurableIntegrationType).includes(integration as ConfigurableIntegrationType)
		) {
			history.push(`${match.path}/${integration.toLocaleLowerCase()}`);
		}
	};

	const onRenderBaseRoute = () => {
		return (
			<div className={css(styleSheet.integrationCardsContainer)}>
				<div className={css(styleSheet.integrationCardsContainerMessage)}>
					<div>Below are some integrations for the account.</div>
					<div>Admins or your account success manager can enable or disable integrations.</div>
				</div>
				<ExternalIntegrations />
				<IntegrationCards onManageClicked={onManageClicked} />
			</div>
		);
	};
	return (
		<MainContainerPageShell>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<NavigationBreadcrumbsBar pathComponentNameProvider={pathComponentNameProvider} />
			</Portal>
			<Switch>
				{AllEmailScanners.map(x => {
					const key = x.id.toLocaleLowerCase();
					return (
						<PrivateRoute
							key={key}
							path={`${match.path}/${key}`}
							render={onRenderEmailScannerToggle(x.id)}
							userSession={userSession}
						/>
					);
				})}
				{Object.values(ExternalIntegrationType).map(x => {
					return (
						<PrivateRoute
							key={x}
							path={`${match.path}/${x}/:tab?`}
							render={onRenderExternalIntegration(x)}
							userSession={userSession}
						/>
					);
				})}
				{Object.values(ConfigurableIntegrationType).map(x => {
					const hide =
						(x === ConfigurableIntegrationType.Wealthbox && !userSession?.account?.integrations?.wealthbox?.enabled) ||
						(x === ConfigurableIntegrationType.QQ && !userSession?.account?.integrations?.qqCatalyst?.enabled) ||
						(x === ConfigurableIntegrationType.MergeCrm && !userSession?.account?.integrations?.mergeCrm?.enabled) ||
						(x === ConfigurableIntegrationType.MergeAccounting &&
							!userSession?.account?.integrations?.mergeAccounting?.enabled);

					return hide ? null : (
						<PrivateRoute
							key={x}
							path={`${match.path}/${x}`}
							render={onRenderConfigurableIntegration(x)}
							userSession={userSession}
						/>
					);
				})}
				{Object.values(UserConfigurableIntegrationType).map(x => {
					return (
						<PrivateRoute
							key={x}
							path={`${match.path}/${x}`}
							render={onRenderUserConfigurableIntegration(x)}
							userSession={userSession}
						/>
					);
				})}
				<PrivateRoute exact={true} path={`${match.path}`} render={onRenderBaseRoute} userSession={userSession} />
				<Redirect to={`${match.path}`} push={false} />
			</Switch>
		</MainContainerPageShell>
	);
}

export const Integrations = observer(_Integrations);
