import { useMutation } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useImportOpportunitiesMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IImportOpportunityPreview) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			file,
			impersonationContext,
		}: {
			file: File;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			const data = new FormData();
			data.append('file', file);

			return userSession.webServiceHelper.callAsync<Api.IImportOpportunityPreview>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `board/items/importFromFile`,
				}),
				'POST',
				data
			);
		},
		onError,
		onSuccess,
	});
};
