import * as React from 'react';
import { PersonalSettingsSection } from '../../components/settings/PersonalSettings/models';
import { AdminSettingsSection } from '../../components/settings/models';

export interface ISettingsContext {
	supportedAdminSettingsSections?: AdminSettingsSection[];
	supportedPersonalSettingsSections?: PersonalSettingsSection[];
}

export interface ISettingsChildComponentProps {
	settingsContext?: ISettingsContext;
}

export const SettingsContext = React.createContext<ISettingsContext>(null);

export function withSettingsContext<T = any>(WrappedComponent: React.ComponentType<T>) {
	const sfc: React.FC<T & ISettingsChildComponentProps> = props => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const settingsContext = React.useContext(SettingsContext);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [childProps, setChildProps] = React.useState<ISettingsChildComponentProps>(
			settingsContext
				? {
						settingsContext,
					}
				: {}
		);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		React.useEffect(() => {
			setChildProps({ settingsContext });
		}, [settingsContext]);

		return <WrappedComponent {...childProps} {...props} />;
	};
	return sfc;
}
