import { ESelectionState, IBulkOperationResult, ISystemJob } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages, useToaster } from '../../../../../models/hooks/appStateHooks';
import { useModal } from '../../../../../models/hooks/useModal';
import { useToggle } from '../../../../../models/hooks/useToggle';
import { DeleteConfirmation } from '../../../../components/DeleteConfirmation';
import { ExportConfirmationModal } from '../../../../components/ExportConfirmation';
import { Modal } from '../../../../components/Modal';
import { TinyPopover } from '../../../../components/TinyPopover';
import { MergeCompaniesWizard } from '../../../../components/companies/MergeCompaniesWizard';
import { ExportIcon } from '../../../../components/svgs/icons/ExportIcon';
import { MergeIcon } from '../../../../components/svgs/icons/MergeIcon';
import { TrashIcon } from '../../../../components/svgs/icons/TrashIcon';
import { header } from '../../../../styles/colors';
import { useCompaniesSearch } from '../CompaniesContext';
import { styleSheet } from './styles';

const useActionBarStatus = () => {
	const { companies } = useCompaniesSearch();
	const isSelectingAll =
		companies.selectionState === ESelectionState.All ||
		(companies.selectionState === ESelectionState.Some && companies.selectedCompanies.length === 0);

	const canMerge = companies.selectedCompanies.length > 1;
	const canDelete = companies.selectedCompanies.length > 0;
	const canExport = companies.selectedCompanies.length > 0 || isSelectingAll;

	return { canDelete, canExport, canMerge } as const;
};

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const CompaniesActionsBar: React.FC<IProps> = observer(props => {
	const { className, styles } = props;
	const { companies } = useCompaniesSearch();
	const { logInput, logApiError } = useEventLogging();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { canMerge, canDelete, canExport } = useActionBarStatus();

	const [exportCompaniesPromise, setExportCompaniesPromise] = React.useState<Promise<ISystemJob>>(null);
	const [isMoreMenuOpen, , setMoreMenuOpen] = useToggle(false);

	const mergeModal = useModal(
		false,
		() => {
			companies.selectedCompanies.clear();
		},
		[companies]
	);

	const deleteModal = useModal(
		false,

		(shouldDelete: boolean) => {
			if (!shouldDelete) {
				return;
			}

			logInput('CompaniesTable', 'Click', {
				selectedCompanies: (companies.selectedCompanies.toArray() || []).map(x => x.id),
			});
			const count = (companies.selectedCompanies || []).length;
			companies
				.delete(companies.selectedCompanies.toArray())
				?.then(() => {
					toaster.push({
						message: `${count} compan${count > 1 ? 'ies' : 'y'} successfully deleted`,
						type: 'successMessage',
					});
				})
				?.catch((bulkError: IBulkOperationResult<string>) => {
					const failureCount = (bulkError.failed || []).length;
					logApiError('ContactsDelete-Error', bulkError);

					errorMessages.push({
						messages: [`${failureCount} compan${failureCount > 1 ? 'ies' : 'y'} failed to delete.`],
					});
				});
		},
		[companies]
	);

	const onDeleteClicked = React.useCallback(() => {
		deleteModal.setIsOpen(true)();
	}, [deleteModal]);

	const onExportCompaniesButtonClicked = React.useCallback(() => {
		if (companies.selectionState === ESelectionState.All) {
			companies.selectedCompanies.addAll(companies.companies.toArray());
		}

		setExportCompaniesPromise(companies.export(false));
		setMoreMenuOpen(false)();
	}, [companies, setMoreMenuOpen]);
	const onExportCompaniesAndContactsButtonClicked = React.useCallback(() => {
		setExportCompaniesPromise(companies.export(true));
		setMoreMenuOpen(false)();
	}, [companies, setMoreMenuOpen]);

	const onExportCompaniesClose = React.useCallback(() => {
		setExportCompaniesPromise(null);
	}, []);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<button
				className={css(styleSheet.button, styleSheet.merge, !canMerge && styleSheet.disabled)}
				onClick={mergeModal.setIsOpen(true)}
			>
				<span>
					<MergeIcon />
					<span className={css(styleSheet.buttonText)}>Merge</span>
				</span>
			</button>
			<button
				className={css(styleSheet.button, styleSheet.delete, !canDelete && styleSheet.disabled)}
				onClick={onDeleteClicked}
			>
				<span>
					<TrashIcon fillColor={header} />
					<span className={css(styleSheet.buttonText)}>Delete</span>
				</span>
			</button>
			<TinyPopover
				anchor={
					<button
						className={css(styleSheet.button, styleSheet.export, !canExport && styleSheet.disabled)}
						onClick={setMoreMenuOpen(true)}
					>
						<span>
							<ExportIcon />
							<span className={css(styleSheet.buttonText)}>Export</span>
						</span>
					</button>
				}
				anchorStyles={[styleSheet.anchor]}
				isOpen={isMoreMenuOpen}
				onRequestClose={setMoreMenuOpen(false)}
				dismissOnOutsideAction={true}
			>
				<ul className={css(styleSheet.moreDropdown)}>
					<li className={css(styleSheet.moreDropdownItem)}>
						<button onClick={onExportCompaniesButtonClicked}>
							<span>Export only companies</span>
						</button>
					</li>

					<li className={css(styleSheet.moreDropdownItem)}>
						<button onClick={onExportCompaniesAndContactsButtonClicked}>
							<span>Export both companies & all contacts</span>
						</button>
					</li>
				</ul>
			</TinyPopover>
			<ExportConfirmationModal
				promise={exportCompaniesPromise}
				modalProps={{
					isOpen: !!exportCompaniesPromise,
					onRequestClose: onExportCompaniesClose,
				}}
				subTitle={
					<>
						<p>The fields we&apos;ll export include: Company Name, Company, Job Title,</p>
						<p>Address, Phone Numbers, Email Address, OwnerID, and tags.</p>
					</>
				}
			/>
			<Modal isOpen={mergeModal.isOpen} onRequestClose={mergeModal.onRequestClose}>
				<MergeCompaniesWizard companies={companies} selectedCompanies={companies.selectedCompanies.toArray()} />
			</Modal>
			<DeleteConfirmation
				bodyText='Are you sure you want to delete these companies?'
				isOpen={deleteModal.isOpen}
				onFinish={deleteModal.onRequestClose}
			/>
		</div>
	);
});
