import * as Api from '@ViewModels';
import practicePantherLogo from '../../../../assets/practice-panther-logo-sm.png';
import { baseStyleSheet } from '../../../../styles/styles';
import { ClioIntegrationGraphic } from '../../../svgs/graphics/ClioIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

export const DashboardPracticePantherIntegrationCard = () => {
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: 'PracticePantherIntegrationClosed',
			enableUrlPath: '/integrations/PracticePanther',
			integrationEnableEventName: 'PracticePantherIntegrationEnableClick',
			pendingAction: Api.PendingActions.ConnectPracticePanther,
			checkApiPendingAction: true,
		});

	if (!showCard) {
		return null;
	}

	return (
		<IntegrationCard
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={ClioIntegrationGraphic}
			headerLabel='PracticePanther Integration'
			logoBgColor='#0337CC'
			logoImgProps={getLogoImgProps({ alt: 'PracticePanther Logo', src: practicePantherLogo })}
			ctaButtonProps={getCtaButtonProps({
				children: 'Connect with PracticePanther',
				styleDeclaration: [baseStyleSheet.ctaButtonReverse, styleSheet.ctaButton],
			})}
			integrationTitleProps={getIntegrationTitleProps({
				styleDeclaration: styleSheet.integrationTitle,
			})}
		>
			<p>
				We are excited to announce that you can integrate your PracticePanther account with Levitate. With this
				integration, we will have the ability to pull client information, including names, email, phone number,
				birthdays, anniversaries, and more &mdash; making staying in touch even easier. We can also push our notes into
				PracticePanther.
			</p>
		</IntegrationCard>
	);
};
