import * as Api from '@ViewModels';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import {
	IImpersonationContextComponentProps,
	IModalContext,
	ImpersonationContextKey,
	ModalChildComponentContextKey,
} from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { copyToClipboard } from '../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	invalidateInfiniteSurveys,
	useCreateSatisfactionSurveyMutation,
	useGetSurveyById,
	useUpdateSatisfactionSurveyCustomFormFieldOptionsMutation,
	useUpdateSatisfactionSurveyCustomFormMutation,
	useUpdateSatisfactionSurveyMutation,
} from '../../../queries';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from '../../components/Breadcrumb';
import { Button } from '../../components/Button';
import { FabContext } from '../../components/FabContext';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { EyeIcon } from '../../components/svgs/icons/EyeIcon';
import { SquareLinkIcon } from '../../components/svgs/icons/SquareLinkIcon';
import { brandPrimary } from '../../styles/colors';
import { bs } from '../../styles/styles';
import { AdditionalQuestions, BasicSurveyInfo, ReviewRequestOptions } from './presentation';
import { styleSheet } from './styles';

interface IProps extends IModalContext<Api.SatisfactionSurveyViewModel>, IImpersonationContextComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IEditSurveyComponent {
	BasicSurveyInfo: React.FC<{ survey: Api.ISatisfactionSurvey }>;
	ReviewRequestOptions: React.FC<IImpersonationContextComponentProps & { survey: Api.ISatisfactionSurvey }>;
	AdditionalQuestions: React.FC<{ isEdit: boolean }>;
}

const validationSchema = yup.object({
	name: yup.string().required('Survey name is required'),
	companyDisplayName: yup.string(),
	intro: yup
		.string()
		.required('Survey intro is required')
		.test('intro-length', 'Survey intro is required', value => Boolean(value.length)),
	reviewRequestSettings: yup.object({
		isEnabled: yup.boolean(),
		ratings: yup
			.array()
			.of(yup.number().required('Review rating is required'))
			.test('review-ratings-length', 'Review ratings must be between 5 and 1', function (value) {
				const { isEnabled } = this.parent;
				if (!isEnabled) {
					return true;
				}
				return value.length >= 1 && value.length <= 5;
			}),
		reviewRequest: yup.string().required('Review request message is required'),
		reviewType: yup.string().test('reviewRequestSettings.isEnabled-type', function (val) {
			const { isEnabled } = this.parent;
			if (!isEnabled || val) {
				return true;
			}

			return this.createError({ message: 'Review type is required' });
		}),
		reviewUrl: yup.string().test('reviewRequestSettings.isEnabled-url', function (val) {
			const { isEnabled } = this.parent;
			if (!isEnabled || val) {
				return true;
			}
			return this.createError({ message: 'Review URL is required' });
		}),
	}),
	indefinitely: yup.boolean(),
	startDate: yup.string(),
	expirationDate: yup.string().when('indefinitely', (indefinitely, schema) => {
		return !indefinitely ? schema.required('Expiration date is required') : schema.notRequired();
	}),
	customFormIsDisabled: yup.boolean(),
	customForm: yup.object({
		name: yup.string(),
		fields: yup.array().of(
			yup.object({
				fieldType: yup.mixed<Api.FormFieldType>().oneOf(Object.values(Api.FormFieldType)),
				label: yup.string().required('Question label is required'),
				id: yup.string(),
				isOptional: yup.boolean(),
				isHidden: yup.boolean(),
				options: yup
					.array()
					.of(yup.object({ label: yup.string(), fieldId: yup.string() }))
					.test('options-length-test', function (val) {
						// get parent values
						const { fieldType } = this.parent;
						if (
							val?.every(x => Boolean(x?.label)) &&
							val?.length >= 2 &&
							(fieldType === Api.FormFieldType.Option || fieldType === Api.FormFieldType.MultipleOptions)
						) {
							return true;
						}
						if (fieldType === Api.FormFieldType.String) {
							return true;
						}
						return this.createError({ message: 'This field and its options are required' });
					}),
			})
		),
	}),
	customFormMetaData: yup.array().of(
		yup.object({
			fieldId: yup.string(),
			isConditional: yup.boolean(),
			ratings: yup.array().of(yup.number()),
		})
	),
});

const SatisfactionSurveyBase: React.FC<IProps> & Partial<IEditSurveyComponent> = observer(props => {
	const { className, styles = [], impersonationContext } = props;
	const userSession = useUserSession();
	const history = useHistory();
	const params = useParams<{ id: string }>();
	const toaster = useToaster();
	const isEdit = Boolean(params.id);
	const surveyData = useGetSurveyById<Api.ISatisfactionSurvey>({
		enabled: Boolean(params.id),
		surveyId: params.id,
	});

	const survey = React.useMemo(() => {
		return surveyData.data;
	}, [surveyData.data]);

	const { logApiError } = useEventLogging('EditSurvey');
	const errorMessages = useErrorMessages();

	const surveyCustomFormMutation = useUpdateSatisfactionSurveyCustomFormMutation({
		onError: error => {
			logApiError('CustomSurveySave-Error', error);
		},
		onSuccess: async val => {
			const values = getValues();
			const fieldsWithOptions = values.customForm.fields.filter(field => field.options?.length > 0);
			if (fieldsWithOptions.length) {
				await updateSatisfactionSurveyCustomFormFieldOptionsMutation.mutateAsync({
					impersonationContext,
					eventId: val.id,
					fields: fieldsWithOptions,
				});
			}
			history.replace(`/survey/satisfaction/edit/${val.id}`);
			invalidateInfiniteSurveys();
		},
	});
	const updateSatisfactionSurveyCustomFormFieldOptionsMutation =
		useUpdateSatisfactionSurveyCustomFormFieldOptionsMutation({
			onError: error => {
				errorMessages.pushApiError(error);
			},
			onSuccess: ([_, errors]) => {
				if (errors?.length > 0) {
					errorMessages.pushApiError(errors[0]);
					return;
				}
				invalidateInfiniteSurveys();
			},
		});
	const createSurvey = useCreateSatisfactionSurveyMutation({
		onSuccess: (val: Api.ISatisfactionSurvey) => {
			toaster.push({
				message: 'Satisfaction survey created successfully',
				type: 'successMessage',
			});
			const values = getValues();
			const validCustomFormFields = values.customForm.fields.filter(x => Boolean(x.label));
			if (validCustomFormFields.length) {
				const payload: Api.SatisfactionSurveyCustomFormRequest = {
					customForm: {
						...values.customForm,
						name: values.name,
					},
					customFormMetadata: values.customFormMetaData,
				};
				surveyCustomFormMutation.mutate({ request: payload, id: val.id });
				return;
			}
			invalidateInfiniteSurveys();
			history.replace(`/survey/satisfaction/edit/${val.id}`);
		},
		onError: error => {
			logApiError('SurveySave-Error', error);
		},
	});
	const updateSurvey = useUpdateSatisfactionSurveyMutation({
		onSuccess: data => {
			toaster.push({
				message: 'Satisfaction survey updated successfully',
				type: 'successMessage',
			});
			const values = getValues();
			const validCustomFormFields = values.customForm.fields.filter(x => Boolean(x.label));
			if (validCustomFormFields.length) {
				const payload: Api.SatisfactionSurveyCustomFormRequest = {
					customForm: {
						...values.customForm,
						name: values.name,
					},
					customFormMetadata: values.customFormMetaData,
				};
				surveyCustomFormMutation.mutate({ request: payload, id: data.id });
				return;
			}
			invalidateInfiniteSurveys();
		},
		onError: error => {
			logApiError('SurveySave-Error', error);
		},
	});
	const defaultValues = React.useMemo(() => {
		return {
			name: '',
			companyDisplayName: userSession.account.companyName || '',
			intro: 'How would you rate your overall experience with us?',
			reviewRequestSettings: {
				isEnabled: false,
				ratings: [4, 5],
				reviewRequest:
					'Thank you so much for your kind words and rating. Would you be so generous as to leave us a review? It would really mean the world to us.',
				reviewType: '',
				reviewUrl: '',
			},
			indefinitely: true,
			startDate: new Date().toISOString(),
			expirationDate: '',
			customFormIsDisabled: false,
			customForm: {
				name: '',
				fields: [] as Api.IFormField<string>[],
			},
			customFormMetaData: [] as Api.CustomFormMetaData[],
		};
	}, [userSession.account.companyName]);
	const methods = useForm({
		defaultValues: {
			...defaultValues,
			customFormMetaData: [] as Api.CustomFormMetaData[],
		},
		resolver: yupResolver(validationSchema),
	});
	const { handleSubmit, getValues, setValue } = methods;

	const isArchived = Boolean(survey?.archivedDate);

	React.useEffect(() => {
		if (survey) {
			setValue('name', survey.name);
			setValue('companyDisplayName', survey.companyDisplayName);
			setValue('intro', survey.intro);
			setValue('reviewRequestSettings.isEnabled', survey.reviewRequestSettings.isEnabled);
			setValue('reviewRequestSettings.ratings', survey.reviewRequestSettings.ratings);
			setValue('reviewRequestSettings.reviewType', survey.reviewRequestSettings.reviewType);
			setValue('reviewRequestSettings.reviewUrl', survey.reviewRequestSettings.reviewUrl);
			setValue('reviewRequestSettings.reviewRequest', survey.reviewRequestSettings.reviewRequest);
			setValue('startDate', survey.startDate);
			if (survey.expirationDate) {
				setValue('expirationDate', survey.expirationDate);
				setValue('indefinitely', false);
			}
			setValue('customFormIsDisabled', false);
			setValue('customForm.name', survey.customForm?.name);
			if (survey.customForm?.fields) {
				setValue(
					'customForm.fields',
					survey.customForm?.fields?.map(field => {
						return {
							...field,
							id: field.id,
							isHidden: field.isHidden,
							isOptional: field.isOptional,
							label: field.label,
							fieldType: field.fieldType,
							options: field.options,
						};
					}) || []
				);
			}
			setValue(
				'customFormMetaData',
				survey.customFormMetaData?.map(meta => ({
					fieldId: meta.fieldId || '',
					isConditional: meta.isConditional,
					ratings: meta.ratings,
				})) || []
			);
		}
	}, [setValue, survey]);

	const handleSave = () => {
		const formValues = getValues();

		const payload: Api.ISatisfactionSurvey = {
			_type: 'SatisfactionSurvey',
			companyDisplayName: formValues.companyDisplayName,
			intro: formValues.intro,
			reviewRequestSettings: {
				isEnabled: formValues.reviewRequestSettings.isEnabled,
				ratings: formValues.reviewRequestSettings.ratings,
				reviewRequest: formValues.reviewRequestSettings.reviewRequest,
				reviewType: formValues.reviewRequestSettings.reviewType,
				reviewUrl: formValues.reviewRequestSettings.reviewUrl,
			},
			name: formValues.name,
			startDate: formValues.startDate,
			expirationDate: formValues.expirationDate,
			customFormIsDisabled: false,
			customForm: {
				name: formValues.name,
				fields: formValues.customForm.fields,
			},
			customFormMetaData: formValues.customFormMetaData,
		};
		if (
			payload.startDate &&
			payload.expirationDate &&
			moment(payload.startDate).isSame(new Date(payload.expirationDate), 'day')
		) {
			payload.startDate = moment(payload.startDate).startOf('day').toISOString();
			payload.expirationDate = moment(payload.expirationDate).endOf('day').toISOString();
		} else if (payload.expirationDate && !moment(payload.startDate).isSame(new Date(payload.expirationDate), 'day')) {
			payload.expirationDate = moment(payload.expirationDate).endOf('day').toISOString();
		}
		const validCustomFormFields = payload.customForm.fields.filter(x => Boolean(x.label));

		if (!validCustomFormFields.length) {
			payload.customForm = null;
		}

		try {
			if (survey?.id) {
				// update survey
				updateSurvey.mutate({
					survey: {
						...survey,
						...payload,
					},
				});
				return;
			}
			// create survey
			createSurvey.mutate({
				survey: payload,
			});
		} catch (error) {
			logApiError('SurveySave-Error', error);
		}
	};

	const onCopyLinkClicked = () => {
		if (copyToClipboard(survey?.anonymousLink)) {
			toaster.push({
				message: 'Link copied to clipboard',
				type: 'successMessage',
			});
		} else {
			toaster.push({
				message: 'Error copying link to clipboard',
				type: 'errorMessage',
			});
		}
	};

	const onPreviewLinkClicked = () => {
		window.open(survey?.anonymousLink, '_blank');
	};

	const isLoading = React.useMemo(
		() =>
			(surveyData.isLoading && isEdit) ||
			createSurvey.isLoading ||
			updateSurvey.isLoading ||
			surveyCustomFormMutation.isLoading ||
			updateSatisfactionSurveyCustomFormFieldOptionsMutation.isLoading,
		[
			surveyData.isLoading,
			isEdit,
			createSurvey.isLoading,
			updateSurvey.isLoading,
			surveyCustomFormMutation.isLoading,
			updateSatisfactionSurveyCustomFormFieldOptionsMutation.isLoading,
		]
	);
	let breadcrumbPageTitle = 'New Survey';
	if (params.id) {
		breadcrumbPageTitle = survey?.name ?? 'Edit Survey';
	}

	return (
		<FormProvider {...methods}>
			<MultiContainerHeader
				appBarHeader={
					<Breadcrumb>
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbLink asChild>
									<NavLink to='/surveys'>Surveys</NavLink>
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator />
							<BreadcrumbItem>
								<BreadcrumbPage>{breadcrumbPageTitle}</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				}
			/>
			<form
				className={`${css(styleSheet.satisfactionSurveyContainer, ...styles)} edit-survey ${className || ''}`}
				onSubmit={handleSubmit(handleSave)}
			>
				<div className={`${css(styleSheet.satisfactionSurveyContainer, ...styles)} edit-survey ${className || ''}`}>
					<MultiContainerHeader fullscreenHeader={`${survey?.id ? (isArchived ? 'View' : 'Edit') : 'New'} Survey`} />
					<FabContext appearance={{ hidden: true }} />
					<header className={css(styleSheet.header)}>
						<div className={css(styleSheet.headerActions)}>
							{isArchived && <span className={css(styleSheet.archivedLabel)}>ARCHIVED</span>}
							{survey?.id ? (
								<Button
									type='button'
									kind='link'
									disabled={!survey?.id}
									onClick={onPreviewLinkClicked}
									className={css(styleSheet.previewButton)}
									label={
										<>
											Preview Survey <EyeIcon />
										</>
									}
									title={!survey?.id ? 'Please save in order to generate this link' : undefined}
								/>
							) : null}
							<Button
								type='submit'
								kind='primary'
								size='small'
								className={css(bs.mr2)}
								disabled={isLoading || isArchived}
								isLoading={isLoading}
								label='Save'
							/>
							<Button
								type='button'
								kind='reverse'
								size='small'
								// className={css(baseStyleSheet.ctaButtonSmall, styleSheet.copyLinkButton)}
								disabled={!survey?.id}
								onClick={onCopyLinkClicked}
								title={!survey?.id ? 'Please save in order to generate this link' : undefined}
								label={
									<>
										<SquareLinkIcon fillColor={brandPrimary} />
										<span> Copy Survey Link</span>
									</>
								}
							/>
						</div>
					</header>
					<div className={css(styleSheet.body)}>
						<SatisfactionSurvey.BasicSurveyInfo survey={survey} />
						<SatisfactionSurvey.ReviewRequestOptions survey={survey} />
					</div>
					<SatisfactionSurvey.AdditionalQuestions isEdit={isEdit} />
				</div>
			</form>
		</FormProvider>
	);
});

export const SatisfactionSurvey = inject(
	ImpersonationContextKey,
	ModalChildComponentContextKey
)(SatisfactionSurveyBase);

SatisfactionSurvey.BasicSurveyInfo = BasicSurveyInfo;
SatisfactionSurvey.ReviewRequestOptions = ReviewRequestOptions;
SatisfactionSurvey.AdditionalQuestions = AdditionalQuestions;
