import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import Waypoint from 'react-waypoint';
import { useEventLogging } from '../../../models/Logging';
import {
	createContentStateWithHtmlStringValue,
	getDisplayName,
	getDisplayNameForBulkEmail,
	getPrincipalInitials,
	hasRequiredHwcRecipientAddressFields,
	isAddressComplete,
} from '../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { useContact, useHwcBulkCreateMutation, useTemplate, useUserHwcPreferences } from '../../../queries';
import { AdvancedFiltersFlyout } from '../../components/AdvancedFiltersFlyout';
import { Avatar2 } from '../../components/Avatar2';
import { CloseButton } from '../../components/CloseButton';
import { DayPicker } from '../../components/DayPicker';
import { DeprecatedPopover, PopoverType } from '../../components/DeprecatedPopover';
import { FabContext } from '../../components/FabContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { OverflowAccordion } from '../../components/OverflowAccordion';
import { OwnershipFilterDropdown } from '../../components/OwnershipFilterDropdown';
import { SendFromDropdown } from '../../components/SendFromDropdown';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { TinyPopover } from '../../components/TinyPopover';
import { AutoCompleteSearchField } from '../../components/autocomplete/AutoCompleteSearchField';
import { ContactsTableActiveSearchesList } from '../../components/contacts/ContactsTableActiveSearchesList';
import { ContactContextTabView } from '../../components/email/ContactContextTabView';
import { PurchaseCreditsModal } from '../../components/settings/PurchaseCreditsModal';
import { DisclosureIcon } from '../../components/svgs/icons/DisclosureIcon';
import { DropDownIcon } from '../../components/svgs/icons/DropDownIcon';
import { HomeIcon } from '../../components/svgs/icons/HomeIcon';
import { SearchIcon } from '../../components/svgs/icons/SearchIcon';
import { success } from '../../styles/colors';
import { baseStyleSheet, bs } from '../../styles/styles';
import { addressTextFromHwcRecipient, addressTextFromHwcReturnContact } from '../PostcardComposer';
import { AddressFormField } from '../PostcardComposer/AddressFormField';
import { HwcOrderSuccessPage } from '../PostcardComposer/HwcOrderSuccessPage';
import { HwcPreviewToggle, HwcTogglePreview, HwcTogglePreviewValues } from '../PostcardComposer/HwcPreviewToggle';
import { HwcTextPreview } from '../PostcardComposer/HwcTextPreview';
import { PostcardTextEditor } from '../PostcardComposer/PostcardTextEditor';
import { RecipientAddressModal, addresseeNameFromHwcRecipient } from '../PostcardComposer/RecipientAddressModal';
import { ReturnContactModal } from '../PostcardComposer/ReturnAddressModal';
import { useCurrentUserGiftingBalance } from '../PostcardComposer/hooks/useCurrentUserGiftingBalance';
import {
	HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS,
	usePostcardTextEditor,
} from '../PostcardComposer/hooks/usePostcardTextEditor';
import { AddressMissingPill } from './AddressMissingPill';
import { CustomRecipientAddressField } from './CustomRecipientAddressField';
import { useContactFilterRecipients } from './hooks/useContactFilterRecipients';
import { useHwcSaveTemplate } from './hooks/useHwcSaveTemplate';
import { BulkHwcCustomLayout, CampaignComposerBaseLayout, SaveTemplateModal } from './presentation';
import { styleSheet } from './styles';

interface IUserCustomCard {
	handwritingStyle?: Api.HandwritingStyle;
	postcardContent?: string;
	signature?: string;
	recipient?: Api.IHandwrittenCardRecipient;
}

export function BulkHwcFromContactFilterPage() {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('BulkHwcBirthdayComposerPage');
	const location = useLocation<{
		contactIdsToOmit?: string[];
		tagFilterCriterias?: Api.IContactFilterCriteria[];
		ownershipFilter?: Api.IOwnershipFilter;
	}>();
	const params = useParams<{ templateId: string }>();
	const fullscreenModal = useFullscreenModal();
	const [step, setStep] = React.useState<'recipients' | 'reviewOrder' | 'success'>('recipients');
	const [selectedHwcTemplateId, setSelectedHwcTemplateId] = React.useState<string>(params.templateId);
	const selectedTemplateQuery = useTemplate<Api.IHandwrittenCardTemplate>({ templateId: selectedHwcTemplateId });
	const [postcardContent, setPostcardContent] = React.useState('');
	const { handwritingStyle, postcardText, getPostcardTextEditorProps, touched } = usePostcardTextEditor({
		content: postcardContent,

		defaultHandwritingStyle: userSession.user.userPreferences?.handwrittenCardPreferences?.handwritingStyle,
		onContentChanged: setPostcardContent,
	});
	const [toggledHwcPreview, setToggledHwcPreview] = React.useState<HwcTogglePreviewValues>('image');
	const [isEditingPostcard, setIsEditingPostcard] = React.useState(false);
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);

	const [mailDate, setMailDate] = React.useState<Date>(null);
	const mailDateText = mailDate ? moment(mailDate).format('YYYY-MM-DD') : '';
	const {
		createTemplateMutation,
		handleSaveAsButtonClicked,
		handleSaveAsModalClose,
		handleSaveAsModalSave,
		handleSaveButtonClicked,
		isSaveAllowed,
		isSaveAsPopoverShowing,
		isShowingSaveAsModal,
		toggleSaveAsPopover,
		updatingTemplateStatus,
	} = useHwcSaveTemplate({
		onSavedNewTemplate: template => {
			setSelectedHwcTemplateId(template.id);
		},
		postcardContent,

		template: selectedTemplateQuery.data,
	});
	const [cardCustomizationActiveContactId, setCardCustomizationActiveContactId] = React.useState<string | null>(null);
	const [customRecipientsMap] = React.useState(new Map<string, IUserCustomCard>());
	const [customUserCardContent, setCustomUserCardContent] = React.useState(postcardContent);
	const [customUserHwStyle, setCustomUserHwStyle] = React.useState(handwritingStyle);
	const [showRecipientAddressModal, setShowRecipientAddressModal] = React.useState(false);

	const [customUserRecipient, setCustomUserRecipient] = React.useState<Api.IHandwrittenCardRecipient>(null);
	const [returnContact, setReturnContact] = React.useState<Api.IHandwrittenCardReturnContact>(
		userSession.user?.userPreferences?.handwrittenCardPreferences?.returnContact ??
			userSession.account?.preferences?.handwrittenCardPreferences?.returnContact
	);
	const returnAddressText =
		returnContact && isAddressComplete(returnContact.address) ? addressTextFromHwcReturnContact(returnContact) : '';
	const [isShowingReturnContactModal, setIsShowingReturnContactModal] = React.useState(false);

	const {
		allFilterCriterias,
		contactFilterQuery,
		contactIdsToAdd,
		contactIdsToOmit,
		contactsToAdd,
		householdGroupingOn,
		hwcEstimateCostQuery,
		selectedOwnershipFilter,
		setSelectedOwnershipFilter,
		disableShowingDropdown,
		onAddContact,
		onAdvancedFiltersFlyoutSave,
		onAdvancedFiltersFlyoutCancel,
		onRemoveRecipient,
		onSendOnBehalf,
		onTagListSearchesChanged,
		sendEmailFrom,
		sendEmailFromUser,
		setShowingAdvancedFiltersFlyout,
		showingAdvancedFiltersFlyout,
		tagSearchCriterias,
	} = useContactFilterRecipients({
		initialContactIdsToOmit: location.state.contactIdsToOmit,
		initialTagFilterCriterias: location.state.tagFilterCriterias,
		initialOwnershipFilter: location.state.ownershipFilter,
		onRemoveRecipient: contactId => {
			customRecipientsMap.delete(contactId);
		},
		onSendFromChanged: (option?: Api.ISendFromOptions) => {
			switch (option?.mode) {
				case Api.SendEmailFrom.CurrentUser:
					// Set signature to user's signature when current user is selected
					setSignature(userSession.user.userPreferences?.handwrittenCardPreferences?.signature ?? '');
					setReturnContact(
						userSession.user.userPreferences?.handwrittenCardPreferences?.returnContact ??
							userSession.account?.preferences?.handwrittenCardPreferences?.returnContact
					);
					customRecipientsMap.clear();
					break;
				case Api.SendEmailFrom.ContactOwner:
					// Clear signature when contact owner is selected
					setSignature('');
					customRecipientsMap.clear();
					break;
				case Api.SendEmailFrom.SelectedUser:
					break;
				default:
					customRecipientsMap.clear();
					break;
			}
		},
		onTagListSearchChange: () => {
			customRecipientsMap.clear();
		},
	});
	const customContactQuery = useContact({
		contactId: cardCustomizationActiveContactId,
		enabled: cardCustomizationActiveContactId != null,
		expandHouseholdMembers: householdGroupingOn,
	});
	const customContactHwcPreferencesQuery = useUserHwcPreferences({
		enabled: customContactQuery.data?.ownerId != null,

		forUserId: customContactQuery.data?.ownerId,
	});
	const customContactVm = React.useMemo(() => {
		return customContactQuery.data ? new Api.ContactViewModel(userSession, customContactQuery.data) : null;
	}, [customContactQuery.data, userSession]);
	// Set recipient data from query when customization is active, there's no recipient customization so far and queryData is available
	React.useEffect(() => {
		if (cardCustomizationActiveContactId != null && customUserRecipient == null && customContactQuery.data != null) {
			const contact = customContactQuery.data;
			const addresseeName = contact.address?.name ?? contact.household?.name;
			setCustomUserRecipient({
				address: {
					...contact.address,
					name: addresseeName,
				},
				addressName: addresseeName,
				contactId: contact.id,
				firstName: contact.household?.firstNames || contact.firstName,
				lastName: contact.lastName,
			});
		}
	}, [customUserRecipient, customContactQuery.data, cardCustomizationActiveContactId]);
	const customUserRecipientAddressText =
		customUserRecipient && hasRequiredHwcRecipientAddressFields(customUserRecipient.address)
			? addressTextFromHwcRecipient(customUserRecipient)
			: '';
	const customPostcardEditor = usePostcardTextEditor({
		content: customUserCardContent,
		handwritingStyle: customUserHwStyle,
		onContentChanged: content => setCustomUserCardContent(content),
		onHandwritingStyleChanged: hwStyle => setCustomUserHwStyle(hwStyle),
	});
	React.useEffect(() => {
		// If the template has content, but the postcard editor is untouched, set the postcard text to the template content
		// Only when editor is untouched do we want to set the postcard text to the template content
		// Otherwise we don't want to overwrite it with the template content
		if (selectedTemplateQuery.data?.content && !touched) {
			setPostcardContent(Api.getContentHtmlStringValueFromRawRichTextContentState(selectedTemplateQuery.data.content));
		}
	}, [selectedTemplateQuery.data?.content, touched]);
	const onEditClicked = () => {
		setIsEditingPostcard(true);
	};
	const onPreviewClicked = () => {
		setIsEditingPostcard(false);
	};
	const handleRecipientClick = (contactId: string) => {
		// Short circuit if send from is not current user
		if (sendEmailFrom?.sender && sendEmailFrom?.sender.mode !== Api.SendEmailFrom.CurrentUser) {
			return;
		}
		const customCard = customRecipientsMap.get(contactId);
		if (customCard) {
			setCustomUserCardContent(customCard.postcardContent || postcardContent);
			setCustomUserHwStyle(customCard.handwritingStyle || handwritingStyle);
			setCustomUserSignature(customCard.signature || signature);

			setCustomUserRecipient(customCard.recipient);
		} else {
			setCustomUserCardContent(postcardContent);
			setCustomUserHwStyle(handwritingStyle);
			setCustomUserSignature(signature);
		}
		setCardCustomizationActiveContactId(contactId);
	};
	const clearCustomUserCard = () => {
		setCustomUserHwStyle(handwritingStyle);
		setCustomUserCardContent(postcardContent);
		setCustomUserSignature(signature);

		setCustomUserRecipient(null);
		setCardCustomizationActiveContactId(null);
	};
	const handleSaveCustomUserCardClick = () => {
		const customCard: IUserCustomCard = {};
		if (customUserRecipient) {
			customCard.recipient = customUserRecipient;
		}
		if (
			customUserHwStyle !== handwritingStyle ||
			customUserCardContent !== postcardContent ||
			customUserSignature !== signature
		) {
			customCard.handwritingStyle = customUserHwStyle;
			customCard.signature = customUserSignature;
			customCard.postcardContent = customUserCardContent;
		}
		if (Object.keys(customCard).length > 0) {
			customRecipientsMap.set(cardCustomizationActiveContactId, customCard);
		}
		clearCustomUserCard();
	};
	const handleCancelCustomUserCardClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
		ev.preventDefault();
		clearCustomUserCard();
	};
	const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = React.useState(false);

	const [signature, setSignature] = React.useState(
		sendEmailFrom?.sender?.mode === Api.SendEmailFrom.CurrentUser
			? userSession.user!.userPreferences?.handwrittenCardPreferences?.signature ?? ''
			: ''
	);
	const [customUserSignature, setCustomUserSignature] = React.useState(signature);
	const sendFromUserPreferencesQuery = useUserHwcPreferences({
		enabled: sendEmailFromUser != null,

		forUserId: sendEmailFromUser?.id,
	});
	React.useEffect(() => {
		if (sendFromUserPreferencesQuery?.data?.returnContact) {
			setReturnContact(sendFromUserPreferencesQuery?.data?.returnContact);
		}
	}, [sendFromUserPreferencesQuery?.data?.returnContact]);
	// This forgoes counting contacts with added addresses who already had sendeable addresses. Only count contacts with added addresses who didn't have sendable addresses.
	const numberOfContactsWithAddedAddresses = Array.from(customRecipientsMap).filter(([contactId, cContact]) => {
		if (contactFilterQuery.data) {
			const contactFromFetchedRecipients = contactFilterQuery.data.pages

				.flatMap(page => page.values)
				.find(c => c.id === contactId);
			// If contact is already in the fetched recipients and has a sendable address, don't count it
			// else If contact is already in the fetched recipients but doesn't have a sendable address, count it if the customization has a valid address.
			if (
				contactFromFetchedRecipients?.address &&
				hasRequiredHwcRecipientAddressFields(contactFromFetchedRecipients.address)
			) {
				return false;
			}
		}
		return Boolean(cContact.recipient);
	}).length;
	const totalRecipientsWithAddress =
		numberOfContactsWithAddedAddresses +
		(hwcEstimateCostQuery.data?.totalRecipientsWithAddress ?? hwcEstimateCostQuery.data?.totalRecipients ?? 0);
	const totalRecipients = hwcEstimateCostQuery.data?.totalRecipients;
	const hasSendableAddress = (contact: Api.IContact) => {
		const contactCustomCard = customRecipientsMap.get(contact.id);
		if (contactCustomCard?.recipient?.address) {
			return hasRequiredHwcRecipientAddressFields(contactCustomCard.recipient.address);
		}
		return contact.address != null && hasRequiredHwcRecipientAddressFields(contact.address);
	};
	// When custom mode is active get the hwc preferences for the selected contact and set the signature but only if send from is contact owner.
	React.useEffect(() => {
		if (
			customContactHwcPreferencesQuery?.data?.signature &&
			sendEmailFrom?.sender?.mode === Api.SendEmailFrom.ContactOwner
		) {
			setCustomUserSignature(customContactHwcPreferencesQuery?.data?.signature);
		}
	}, [customContactHwcPreferencesQuery?.data?.signature, sendEmailFrom, userSession.canSendOnBehalf]);
	const sendEmailFromUserHwcPreferencesQuery = useUserHwcPreferences({
		enabled: sendEmailFromUser?.id != null,

		forUserId: sendEmailFromUser?.id,
	});
	// When send from user changes, update the signature to the selected user's signature preference
	React.useEffect(() => {
		if (sendEmailFromUserHwcPreferencesQuery?.data?.signature) {
			setSignature(sendEmailFromUserHwcPreferencesQuery.data.signature);
		}
	}, [sendEmailFromUserHwcPreferencesQuery?.data?.signature]);
	const { balanceAfterSend, currentBalance, giftingQuery, totalCost } = useCurrentUserGiftingBalance({
		totalRecipients: totalRecipientsWithAddress,
	});
	const canPlaceOrder =
		postcardText &&
		mailDate &&
		(returnContact || sendEmailFrom?.sender?.mode === Api.SendEmailFrom.ContactOwner) && // returnContact is not required if sendFrom is contactOwner
		!!totalRecipientsWithAddress &&
		balanceAfterSend >= 0;
	const bulkSendMutation = useHwcBulkCreateMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('useHwcResourceSelectorMutation', error);
		},
		onSuccess: () => {
			setStep('success');
		},
	});
	const onFilterOptionChanged = (selectedOption: Api.IOwnershipFilter) => {
		setSelectedOwnershipFilter(selectedOption);
		customRecipientsMap.clear();
	};
	const handlePlaceOrderClick = () => {
		bulkSendMutation.mutate({
			card: {
				content: createContentStateWithHtmlStringValue(postcardContent).getRawRichTextContent(),
				recipients: Array.from(customRecipientsMap)
					.map(
						([contactId, customCard]) =>
							({
								...customCard.recipient,
								contactId,
								content: customCard.postcardContent
									? createContentStateWithHtmlStringValue(customCard.postcardContent).getRawRichTextContent()
									: undefined,
								signature: customCard.signature ? customCard.signature : undefined,
								style: customCard.handwritingStyle ? customCard.handwritingStyle : undefined,
							}) as Api.IHandwrittenCardRecipient
					)
					.concat(
						contactsToAdd

							.filter(contactQ => contactQ.data != null && !customRecipientsMap.has(contactQ.data.id))
							.map(contactQ => ({
								address: contactQ.data.address,

								contactId: contactQ.data.id,

								firstName: contactQ.data.firstName,

								lastName: contactQ.data.lastName,
							}))
					),
				returnContact,
				scheduledSendDate: mailDate.toJSON(),
				sendFrom: sendEmailFrom?.sender?.mode,
				senderUserId: sendEmailFromUser?.id,
				sendFromConnectionType: sendEmailFrom?.sender?.connectionType,
				signature,
				templateId: selectedHwcTemplateId,
			},
			filter: {
				excludeContactIds: contactIdsToOmit,
				filter: {
					criteria: allFilterCriterias,
				},
				groupByHousehold: householdGroupingOn,
				ownershipFilter: selectedOwnershipFilter,
			},
		});
	};
	const renderRecipientItem = (contact: Api.IContact) => {
		const contactCustomCard = customRecipientsMap.get(contact.id);
		const isCustomized = !!contactCustomCard?.postcardContent;
		let companyLine: string;
		if (contact.companyName != null) {
			companyLine = contact.jobTitle != null ? `${contact.jobTitle} at ${contact.companyName}` : contact.companyName;
		}
		const hasValidAddress = hasSendableAddress(contact);
		const spouses = contact.householdMembers?.filter(i => i.householdRelationship?.title === Api.HouseholdTitle.Spouse);
		const multipleHouseholdMembers = spouses && spouses.length > 1;
		const nameLabel = multipleHouseholdMembers
			? contact.address?.name ?? contact.household?.name ?? getDisplayNameForBulkEmail(contact)
			: getDisplayNameForBulkEmail(contact);
		return (
			<div
				key={contact.id}
				className={css(bs.flex, bs.itemsCenter, bs.gap2, bs.border0, bs.borderB, bs.borderSolid, bs.borderGray200)}
			>
				<CloseButton
					onClick={ev => {
						ev.stopPropagation();
						onRemoveRecipient(contact);
					}}
				/>
				<div
					className={css(bs.flex, bs.itemsCenter, bs.cursorPointer, bs.py3, bs.relative, bs.flex1)}
					onClick={() => handleRecipientClick(contact.id)}
				>
					{multipleHouseholdMembers ? (
						<div className={css(styleSheet.recipientsListItemAvatarList)}>
							{spouses.slice(0, 2).map((householdMember, i) => {
								return (
									<Avatar2
										styleDeclaration={
											i === 0 ? styleSheet.recipientsListItemAvatarsFirst : styleSheet.recipientsListItemAvatarsSecond
										}
										key={householdMember.id}
										imgSrc={householdMember.profilePic}
										fallbackText={getPrincipalInitials(householdMember)}
									/>
								);
							})}
						</div>
					) : (
						<Avatar2
							styleDeclaration={styleSheet.recipientsListItemAvatar}
							imgSrc={contact.profilePic}
							fallbackText={getPrincipalInitials(contact)}
						/>
					)}
					<div className={css(styleSheet.recipientsListItemDetails)}>
						<div className={css(styleSheet.recipientsListItemDetailsName)}>{nameLabel}</div>
						<div className={css(styleSheet.recipientsListItemDetailsCompanyName, baseStyleSheet.truncateText)}>
							{companyLine}
						</div>
						{sendEmailFrom?.sender?.mode === Api.SendEmailFrom.CurrentUser ? (
							<div
								className={css(
									baseStyleSheet.truncateText,
									styleSheet.recipientsListItemDetailsInfo,
									isCustomized && styleSheet.recipientsListItemDetailsInfoCustomized
								)}
							>
								{isCustomized ? 'See customized card' : 'Customize card'}
							</div>
						) : null}
					</div>
					{!hasValidAddress ? <AddressMissingPill styleDeclaration={styleSheet.addressMissingPillPlacement} /> : null}
				</div>
			</div>
		);
	};

	if (step === 'success') {
		return (
			<>
				<MultiContainerHeader
					fullscreenHeader='Order Confirmation'
					onFullscreenRequestBack={ev => {
						ev.preventDefault();

						fullscreenModal.dismissModal();
					}}
					onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
				/>
				<HwcOrderSuccessPage />
			</>
		);
	}
	if (cardCustomizationActiveContactId != null) {
		return (
			<BulkHwcCustomLayout
				mainHeader={
					<>
						{selectedTemplateQuery.data ? (
							<div className={css(baseStyleSheet.truncateText, styleSheet.customCardHeaderTitle)}>
								{selectedTemplateQuery.data.name}
							</div>
						) : (
							<LoadingSpinner type='small' />
						)}
						{customContactQuery?.data ? (
							<div className={css(styleSheet.customCardHeaderUser)}>
								<Avatar2
									styleDeclaration={styleSheet.customCardUserAvatar}
									imgSrc={customContactQuery.data.profilePic}
									fallbackText={getPrincipalInitials(customContactQuery.data)}
								/>
								<div>
									<div className={css(styleSheet.customCardHeaderUserName)}>
										{getDisplayName(customContactQuery.data)}
									</div>
									<div className={css(styleSheet.customCardHeaderUserInfo)}>
										You are currently customizing the card for just this user
									</div>
								</div>
							</div>
						) : (
							<LoadingSpinner type='small' />
						)}
					</>
				}
				sideBar={
					<>
						{customContactVm ? (
							<ContactContextTabView
								contact={customContactVm}
								initialEdit={false}
								styles={[styleSheet.customCardContactContextTabView]}
							/>
						) : (
							<LoadingSpinner type='large' />
						)}
					</>
				}
				footer={
					<button
						className={css(baseStyleSheet.ctaButton)}
						onClick={handleSaveCustomUserCardClick}
						disabled={!customUserCardContent}
					>
						Save and go back to all recipients
					</button>
				}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<MultiContainerHeader
						fullscreenHeader="Customize Recipient's Card"
						onFullscreenRequestBack={handleCancelCustomUserCardClick}
						onFullscreenRequestClose={handleCancelCustomUserCardClick}
					/>
					<div className={css(styleSheet.mainPreviewContainer)}>
						<div className={css(styleSheet.previewWrapper)}>
							<div className={css(styleSheet.configDrawerSection)}>
								<h3 className={css(styleSheet.configDrawerMessage)}>Message</h3>
								<span
									className={css(
										styleSheet.configDrawerSubtitle,
										styleSheet.configDrawerCharCount,

										customPostcardEditor.postcardText.length > HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS &&
											styleSheet.configDrawerCharCountOver
									)}
								>
									{customPostcardEditor.postcardText.length}/{HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS}
								</span>
								<PostcardTextEditor {...customPostcardEditor.getPostcardTextEditorProps()} />
							</div>
							<div className={css(styleSheet.configDrawerSection)}>
								<h4 className={css(styleSheet.configDrawerSelectSig)}>Signature</h4>
								<TextArea
									inputId='postcard-signature'
									value={customUserSignature}
									onChange={ev => setCustomUserSignature(ev.target.value)}
								/>
							</div>
							<CustomRecipientAddressField
								address={customUserRecipientAddressText}
								id='recipient-address'
								onBtnClick={() => setShowRecipientAddressModal(true)}
								onEditBtnClick={() => setShowRecipientAddressModal(true)}
								required={true}
								styleDeclaration={styleSheet.configDrawerSection}
							/>
							{showRecipientAddressModal ? (
								<RecipientAddressModal
									isOpen={true}
									initialData={{
										addresseeName: addresseeNameFromHwcRecipient(customUserRecipient),
										address1: customUserRecipient.address?.address1 ?? '',
										address2: customUserRecipient.address?.address2 ?? '',
										city: customUserRecipient.address?.city ?? '',
										salutationName: customUserRecipient.firstName ?? '',
										postalCode: customUserRecipient.address?.postalCode ?? '',
										stateProvince: customUserRecipient.address?.stateProvince ?? '',
									}}
									onSave={data => {
										setCustomUserRecipient({
											address: {
												name: data.addresseeName,
												address1: data.address1,
												address2: data.address2,
												city: data.city,
												postalCode: data.postalCode,
												stateProvince: data.stateProvince,
											},
											addressName: data.addresseeName,
											contactId: cardCustomizationActiveContactId,
											firstName: data.salutationName,
										});
										setShowRecipientAddressModal(false);
									}}
									onCancel={() => setShowRecipientAddressModal(false)}
								/>
							) : null}
						</div>
					</div>
				</>
			</BulkHwcCustomLayout>
		);
	}

	return (
		<CampaignComposerBaseLayout
			mainHeader={
				<>
					{selectedTemplateQuery.isLoading ? (
						<LoadingSpinner type='small' />
					) : selectedTemplateQuery.data != null ? (
						<div className={css(baseStyleSheet.truncateText, styleSheet.customCardHeaderTitle)}>
							{selectedTemplateQuery.data.name}
						</div>
					) : null}
					<SendFromDropdown
						disabled={!userSession.canSendOnBehalf || customRecipientsMap.size > 0}
						sendFromOption={sendEmailFrom?.sender}
						canSendOnBehalf={userSession.canSendOnBehalf}
						allowContactOwnerOption
						allowContactBasedOptions
						sendOnBehalfOf={onSendOnBehalf}
					/>
				</>
			}
			sideBar={
				step === 'recipients' ? (
					<>
						<MultiContainerHeader
							fullscreenHeader='View Card'
							onFullscreenRequestBack={ev => {
								ev.preventDefault();

								fullscreenModal.dismissModal();
							}}
							onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
						/>
						<div className={css(styleSheet.recipientsHeader)}>
							<div className={css(styleSheet.recipientsHeaderTitle)}>
								<div>Recipients</div>
								<button className={css(baseStyleSheet.brandLink)} onClick={() => setShowingAdvancedFiltersFlyout(true)}>
									<div>Advanced Filters</div>
								</button>

								<AdvancedFiltersFlyout
									initialFilterCriteria={allFilterCriterias}
									initialOwnershipFilter={selectedOwnershipFilter}
									initialSelectedUserId={sendEmailFromUser?.id}
									onSave={onAdvancedFiltersFlyoutSave}
									isOpen={showingAdvancedFiltersFlyout}
									initialHouseholdGroupingOn={householdGroupingOn}
									onDismissFlyout={onAdvancedFiltersFlyoutCancel}
									showHousehold={userSession.account.features?.households?.enabled}
									showOwnershipFilters
									disableOwnershipFilters={sendEmailFrom?.sender?.mode === Api.SendEmailFrom.ContactOwner}
									showStatusFilters
								/>
							</div>
							<div className={css(baseStyleSheet.horizontalStack)}>
								{tagSearchCriterias.length > 0 ? (
									<OverflowAccordion
										threshold={65}
										onRenderTrigger={isExpanded => {
											return (
												<>
													<span className={css(styleSheet.tagTriggerText)}>{`${
														isExpanded ? 'Hide' : 'Show all'
													} tags`}</span>
													<DisclosureIcon
														className={css(isExpanded ? styleSheet.tagPointUp : styleSheet.tagPointDown)}
													/>
												</>
											);
										}}
									>
										<ContactsTableActiveSearchesList
											hideAdvancedSearch={true}
											hideLabel={true}
											hideRemoveButtons={false}
											onSearchesChanged={onTagListSearchesChanged}
											// Have to remove the {property: 0} for this to work
											searches={Api.VmUtils.sortContactFilterCriteria(tagSearchCriterias).searches.filter(x =>
												Api.VmUtils.isTagSearchContactFilterCriteria(x)
											)}
											tokenContainerStyles={[styleSheet.tagTokenContainer]}
											styles={[styleSheet.tagSearches]}
										/>
									</OverflowAccordion>
								) : null}
							</div>
							{householdGroupingOn ? (
								<div className={css(baseStyleSheet.horizontalStack, styleSheet.groupingLabel)}>
									<HomeIcon stroke={success} width={12} height={14} />
									<span>Grouping recipients by households</span>
								</div>
							) : null}
							<div className={css(baseStyleSheet.verticalStack, styleSheet.showingHeaderOptions)}>
								<div>Showing:</div>
								<OwnershipFilterDropdown
									initialOwnershipFilter={selectedOwnershipFilter}
									onOwnershipFilterChanged={onFilterOptionChanged}
									disabled={disableShowingDropdown}
									allowSelectAUserOption={false}
									selectedUserId={sendEmailFromUser?.id}
								/>
							</div>
							<div className={css(styleSheet.recipientsCountHeader)}>
								<div className={css(styleSheet.recipientsCountContainer)}>
									{hwcEstimateCostQuery.isLoading ? <LoadingSpinner type='tiny' /> : null}
									{totalRecipientsWithAddress != null && totalRecipients != null
										? `${totalRecipientsWithAddress} out of ${totalRecipients} contact${
												totalRecipients !== 1 ? 's' : ''
											} with addresses`
										: null}
								</div>
							</div>
							<AutoCompleteSearchField
								anchorClassName='email-recipients-list-autocomplete-search-field-anchor'
								className='email-recipients-list-autocomplete-search-field'
								clearSearchFieldAfterSelectingItem={true}
								dropdownContentClassName={css(styleSheet.autocompleteDropdownContent)}
								inputId='email-recipients-list-search-input'
								inputProps={{
									placeholder: 'Add additional contacts',
								}}
								leftAccessory={<SearchIcon className='email-recipients-list-autocomplete-search-field-icon' />}
								onItemSelected={onAddContact}
								resultsLimit={6}
								type={Api.ResourceAutoCompleteViewModelType.Contact}
							/>
						</div>
						<div className={css(styleSheet.recipientsList)}>
							<div>
								{contactFilterQuery.status === 'success' ? (
									<>
										{contactsToAdd.map((contactQuery, i) => {
											if (!contactQuery.data) {
												return <LoadingSpinner key={i} type='small' />;
											}
											return renderRecipientItem(contactQuery.data);
										})}
										{contactFilterQuery.data.pages.map(group => {
											return group.values
												.filter(
													contact =>
														!!contact.id &&
														contactIdsToOmit.indexOf(contact.id) === -1 &&
														contactIdsToAdd.indexOf(contact.id) === -1
												)

												.map(contact => {
													return renderRecipientItem(contact);
												});
										})}
										{contactFilterQuery.hasNextPage && !contactFilterQuery.isFetchingNextPage ? (
											<Waypoint onEnter={() => contactFilterQuery.fetchNextPage()} bottomOffset='-200px' />
										) : null}
									</>
								) : null}
							</div>
						</div>
						<div className={css(styleSheet.recipientsFooter)}>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.recipientsFooterCta)}
								onClick={() => setStep('reviewOrder')}
								disabled={!totalRecipientsWithAddress}
							>
								<span>Next: Review Order</span>
							</button>
						</div>
					</>
				) : (
					<>
						<MultiContainerHeader
							fullscreenHeader='View Card'
							onFullscreenRequestBack={ev => {
								ev.preventDefault();
								setStep('recipients');
							}}
							onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
						/>
						<h2 className={css(styleSheet.configDrawerHeader)}>Review Order</h2>
						<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
						<div className={css(styleSheet.reviewOrderForm)}>
							<div className={css(baseStyleSheet.mb4)}>
								<strong className={css(styleSheet.configDrawerThirdHeader)}>Mail Date</strong>
								<span className={css(styleSheet.configDrawerSubtitle, baseStyleSheet.mb4)}>
									Please allow up to 10 days for delivery
								</span>
								<DeprecatedPopover
									anchor={
										<span>
											<TextInput
												inputId='postcard-mail-date'
												type='date'
												onChange={ev => {
													const parsed = moment(ev.target.value);
													if (parsed.isValid()) {
														setMailDate(parsed.toDate());
													}
												}}
												onClick={() => setDatePickerOpen(true)}
												value={mailDateText}
											/>
										</span>
									}
									dismissOnClickOutside={true}
									isOpen={datePickerOpen}
									onRequestClose={() => setDatePickerOpen(false)}
									type={PopoverType.white}
									preferredPlacement='below'
								>
									<DayPicker month={mailDate} onDayClick={setMailDate} selectedDays={mailDate} />
								</DeprecatedPopover>
							</div>
							{sendEmailFrom?.sender?.mode !== Api.SendEmailFrom.ContactOwner ? (
								<AddressFormField
									address={returnAddressText}
									btnLabel='Add Return Address'
									id='return-address'
									label='Return Address'
									onBtnClick={() => setIsShowingReturnContactModal(true)}
									onEditBtnClick={() => setIsShowingReturnContactModal(true)}
									required={true}
								/>
							) : null}
							<div className={css(styleSheet.reviewOrderRecipientsCount)}>
								{hwcEstimateCostQuery.isLoading ? <LoadingSpinner type='small' /> : null}
								{totalRecipientsWithAddress != null
									? `${totalRecipientsWithAddress} recipient${totalRecipientsWithAddress !== 1 ? 's' : ''}`
									: null}
								<button className={css(baseStyleSheet.brandLink)} onClick={() => setStep('recipients')}>
									Edit
								</button>
							</div>
							<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
							{giftingQuery.status === 'loading' ? <LoadingSpinner /> : null}
							{giftingQuery.status === 'error' ? (
								<p role='error' className={css(styleSheet.errorContactLoad)}>
									Failed to load balance data
								</p>
							) : null}
							{giftingQuery.status === 'success' ? (
								<>
									<p className={css(styleSheet.balanceContainer)}>
										<strong className={css(styleSheet.balanceText)}>Current Balance:</strong>
										{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(currentBalance)}
									</p>

									{balanceAfterSend >= 0 || !Api.isAdmin(userSession.user) ? (
										<p className={css(styleSheet.balanceContainer)}>
											<strong className={css(styleSheet.balanceText)}>Balance After Send: </strong>
											{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(balanceAfterSend)}
										</p>
									) : (
										<button
											className={css(baseStyleSheet.ctaButtonSecondary)}
											onClick={() => setShowPurchaseCreditsModal(true)}
										>
											+ Add credits to continue
										</button>
									)}
								</>
							) : null}
							<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
							{totalCost != null ? (
								<p className={css(styleSheet.balanceContainer)}>
									<strong className={css(styleSheet.total)}>Total:</strong>
									{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(totalCost)}
								</p>
							) : null}
						</div>
						<div className={css(styleSheet.recipientsFooter)}>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.recipientsFooterCta)}
								disabled={bulkSendMutation.isLoading || !canPlaceOrder}
								onClick={handlePlaceOrderClick}
							>
								<span>Place Order</span>
							</button>
						</div>
						{isShowingReturnContactModal ? (
							<ReturnContactModal
								isOpen={isShowingReturnContactModal}
								initialRecipient={returnContact}
								onSave={data => {
									setReturnContact({
										...data,
										address: {
											address1: data.address1,
											address2: data.address2,
											city: data.city,
											country: data.country,
											postalCode: data.postalCode,
											stateProvince: data.stateProvince,
										},
										firstName: data.firstName,
										lastName: data.lastName,
									});
									setIsShowingReturnContactModal(false);
								}}
								onCancel={() => setIsShowingReturnContactModal(false)}
							/>
						) : null}
					</>
				)
			}
		>
			<>
				<FabContext appearance={{ hidden: true }} />
				<div className={css(styleSheet.hwcActionsToolbar)}>
					{!isEditingPostcard ? (
						<button
							className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}
							disabled={!postcardText && selectedTemplateQuery.isLoading}
							onClick={onEditClicked}
						>
							Edit
						</button>
					) : (
						<button
							className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}
							disabled={!postcardText && selectedTemplateQuery.isLoading}
							onClick={onPreviewClicked}
						>
							Preview
						</button>
					)}
					{isSaveAllowed ? (
						<TinyPopover
							anchor={
								<div className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}>
									<button
										className={css(baseStyleSheet.outlineButton)}
										onClick={handleSaveButtonClicked}
										disabled={updatingTemplateStatus === 'loading' || !postcardContent}
									>
										<span>Save</span>
									</button>
									<button className={css(styleSheet.saveAsDropdownCaret)} onClick={toggleSaveAsPopover}>
										<DropDownIcon />
									</button>
								</div>
							}
							isOpen={isSaveAsPopoverShowing}
							dismissOnOutsideAction={true}
							align='center'
							placement={['bottom']}
							onRequestClose={toggleSaveAsPopover}
						>
							<button
								className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}
								disabled={false}
								onClick={handleSaveAsButtonClicked}
							>
								Save as
							</button>
						</TinyPopover>
					) : (
						<button
							className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}
							disabled={false}
							onClick={handleSaveAsButtonClicked}
						>
							Save as
						</button>
					)}
				</div>
				<div className={css(styleSheet.mainPreviewContainer)}>
					<div className={css(styleSheet.previewWrapper)}>
						{!isEditingPostcard ? (
							<>
								<p className={css(styleSheet.cardPreviewText)}>Card Preview</p>
								{toggledHwcPreview === HwcTogglePreview.image ? (
									<div className={css(styleSheet.mainPreview, styleSheet.mainPreviewModeImage)}>
										{selectedTemplateQuery.data ? (
											<img
												src={selectedTemplateQuery.data.image.publicUrl}
												alt='Template image preview'
												className={css(styleSheet.mainPreviewImage)}
											/>
										) : (
											<LoadingSpinner />
										)}
									</div>
								) : (
									<HwcTextPreview
										handwritingStyle={handwritingStyle}
										postcardText={postcardText}
										signature={signature}
									/>
								)}
								<HwcPreviewToggle value={toggledHwcPreview} onValueChange={value => setToggledHwcPreview(value)} />
							</>
						) : null}
						{isEditingPostcard ? (
							<>
								<div className={css(styleSheet.configDrawerSection)}>
									<h3 className={css(styleSheet.configDrawerMessage)}>Message</h3>
									<span
										className={css(
											styleSheet.configDrawerSubtitle,
											styleSheet.configDrawerCharCount,

											postcardText.length > HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS &&
												styleSheet.configDrawerCharCountOver
										)}
									>
										{postcardText.length}/{HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS}
									</span>
									<PostcardTextEditor {...getPostcardTextEditorProps()} />
								</div>
								{
									// Hide signature if sendFrom is ContactOwner
									sendEmailFrom?.sender?.mode !== Api.SendEmailFrom.ContactOwner ? (
										<div className={css(styleSheet.configDrawerSection)}>
											<h4 className={css(styleSheet.configDrawerSelectSig)}>Signature</h4>
											<TextArea
												inputId='postcard-signature'
												value={signature}
												onChange={ev => setSignature(ev.target.value)}
											/>
										</div>
									) : null
								}
							</>
						) : null}
					</div>
				</div>
				{/* <div className={css(styleSheet.emailFallbackInfo)}>
					<Checkbox
						checked={emailFallbackActive}
						id={`bulk-hwc-email-fallback`}
						childrenPosition='left'
						checkmarkFillColor='white'
						checkedBackgroundFillColor={brandPrimary}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
							setEmailFallbackActive(ev.target.checked);
						}}
					/>
					<span>If the recipient lacks an address, send a backup email (of the same message on the card) instead.</span>
				</div> */}
				{isShowingSaveAsModal ? (
					<Modal
						useDefaultHeader
						className={css(styleSheet.templateSaveModal)}
						isOpen={true}
						shouldCloseOnOverlayClick={false}
						onRequestClose={handleSaveAsModalClose}
					>
						<SaveTemplateModal
							initialTemplateScope={
								selectedTemplateQuery.data?.scope === Api.TemplateScope.Account
									? Api.TemplateScope.Account
									: Api.TemplateScope.User
							}
							actionButtonsDisabled={createTemplateMutation.isLoading}
							onSaveButtonClicked={({ templateName, scope }) => {
								handleSaveAsModalSave({ name: templateName, scope });
							}}
							onCancelButtonClicked={handleSaveAsModalClose}
						/>
					</Modal>
				) : null}
				<PurchaseCreditsModal
					isOpen={showPurchaseCreditsModal}
					onRequestClose={() => setShowPurchaseCreditsModal(false)}
				/>
			</>
		</CampaignComposerBaseLayout>
	);
}
