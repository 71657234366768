import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useLocation } from 'react-router';
import { getDisplayNameForBulkEmail, getPrincipalInitials } from '../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { Avatar2 } from '../../components/Avatar2';
import { Checkbox } from '../../components/Checkbox';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { FabContext } from '../../components/FabContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { SendFromDropdown } from '../../components/SendFromDropdown';
import { TextInput } from '../../components/TextInput';
import { AutoCompleteSearchField } from '../../components/autocomplete/AutoCompleteSearchField';
import { SearchIcon } from '../../components/svgs/icons/SearchIcon';
import { baseStyleSheet as bs } from '../../styles/styles';
import { CampaignComposerBaseLayout } from '../BulkHwcPages/presentation';
import { PhoneMissingPill } from './PhoneMissingPill';
import { TextCampaignPageBody } from './TextCampaignPageBody';
import { useTextingCampaignContacts } from './hooks/useTextingCampaignContacts';
import { useTextingCampaignEditor } from './hooks/useTextingCampaignEditor';
import { styleSheet } from './styles';

export function TextingCampaignContactsPage() {
	const userSession = useUserSession();
	const location = useLocation<{
		contactIds?: string[];
		initialSendOnBehalf?: Api.ISendOnBehalfPermission;
		initialOwnershipFilter?: Api.IOwnershipFilter;
		initialSendFromUserId?: string;
		initialCampaignTitle?: string;
		initialContentEditorState?: Api.IRichContentEditorState;
		initialAttachmentFiles?: File[];
		initialStoredAttachments?: Api.IFileAttachment[];
	}>();
	const fullscreenModal = useFullscreenModal();
	const { state: editorState, dispatch: editorDispatch } = useTextingCampaignEditor({
		initialCampaignTitle: location.state?.initialCampaignTitle,
		initialContentEditorState: location.state?.initialContentEditorState,
		initialAttachmentFiles: location.state?.initialAttachmentFiles,
		initialStoredAttachments: location.state?.initialStoredAttachments,
	});
	const { state, dispatch, contactQueries, numberOfRecipients } = useTextingCampaignContacts({
		initialContactIds: location.state?.contactIds,
	});
	const [sendFrom, setSendFrom] = React.useState<Api.ISendOnBehalfPermission>(
		location.state?.initialSendOnBehalf ?? userSession.defaultSendOnBehalfPermissions[0]
	);
	const [ownershipFilter, setOwnershipFilter] = React.useState(location.state?.initialOwnershipFilter);
	const [sendFromUserId, setSendFromUserId] = React.useState<string | null>(location.state?.initialSendFromUserId);
	const handleRemoveRecipient = (contact: Api.IContact) => {
		if (state.contactIds.length === 1) {
			fullscreenModal.history.push({
				pathname: `/texting-campaign/search`,
				state: {
					initialSendOnBehalf: sendFrom,
					initialSendFromUserId: sendFromUserId,
					initialOwnershipFilter: ownershipFilter,
					initialCampaignTitle: editorState.campaignTitle,
					initialContentEditorState: editorState.contentEditorState,
					initialAttachmentFiles: editorState.attachmentFiles,
					initialStoredAttachments: editorState.storedAttachments,
				},
			});
		}
		dispatch({ type: 'removeRecipient', value: contact.id! });
	};
	const onSendOnBehalf = (option?: Api.ISendOnBehalfPermission, user?: Api.IUser) => {
		setSendFrom(option);
		setSendFromUserId(user?.id);
		setOwnershipFilter(option?.requiredFilter);
	};
	const onNextButtonClick = () => {
		fullscreenModal.history.push('/texting-campaign/schedule-send', {
			request: {
				includeContactIds: state.contactIds,
				sendFromOptions: {
					mode: sendFrom?.sender?.mode,
					selectedUser: sendFromUserId ?? undefined,
					connectionType: sendFrom?.sender?.connectionType,
				},
				campaignName: editorState.campaignTitle,
				content: editorState.contentEditorState.getRawRichTextContent(),
				attachments: editorState.storedAttachments.length > 0 ? editorState.storedAttachments : undefined,
				autoStart: false,
				putOnHold: false,
				skipIfInProgress: false,
				skipIfInPrevious: false,
				groupByHousehold: false,
			},
			files: editorState.attachmentFiles,
		});
	};
	const renderRecipientItem = (contact: Api.IContact) => {
		let companyLine = '';
		if (contact.companyName != null) {
			companyLine = contact.jobTitle != null ? `${contact.jobTitle} at ${contact.companyName}` : contact.companyName;
		}
		const nameLabel = getDisplayNameForBulkEmail(contact);
		return (
			<div
				className={css(
					bs.flex,
					bs.itemsCenter,
					bs.border0,
					bs.borderB,
					bs.borderSolid,
					bs.borderGray200,
					bs.cursorPointer,
					bs.py5,
					bs.px0,
					bs.relative
				)}
				key={contact.id}
			>
				<DeprecatedCloseButton
					onClick={ev => {
						ev.stopPropagation();
						handleRemoveRecipient(contact);
					}}
				/>
				<Avatar2
					styleDeclaration={styleSheet.recipientsListItemAvatar}
					imgSrc={contact.profilePic}
					fallbackText={getPrincipalInitials(contact)}
				/>

				<div className={css(bs.flex1, bs.textSm, bs.ml4, bs.overflowHidden)}>
					<div className={css(styleSheet.recipientsListItemDetailsName)}>{nameLabel}</div>
					<div className={css(styleSheet.recipientsListItemDetailsCompanyName, bs.truncateText)}>{companyLine}</div>
				</div>
				{!contact.canReceiveText ? <PhoneMissingPill styleDeclarations={[bs.absolute, bs.bottom2, bs.right2]} /> : null}
			</div>
		);
	};
	return (
		<>
			<MultiContainerHeader
				fullscreenHeader='New Texting Campaign'
				onFullscreenRequestBack={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
				onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
			/>
			<CampaignComposerBaseLayout
				mainHeader={
					<div className={css(bs.px3, bs.py5, bs.flex, bs.gap2, bs.flexCol)}>
						<div>
							<div className={css(bs.fontLexend, bs.mb1, bs.flex, bs.itemsCenter, bs.justifyBetween)}>
								<label
									htmlFor='textingCampaignName-input'
									className={css(bs.textSm, bs.textGray600, styleSheet.labelRequired)}
								>
									Campaign Title
								</label>
								<span className={css(bs.fontLight, bs.textDestructive, bs.textXs)}>Required Field</span>
							</div>
							<TextInput
								inputId='textingCampaignName-input'
								type='text'
								placeholder='Add title...'
								className={css(bs.roundedLg)}
								value={editorState.campaignTitle}
								onChange={ev => editorDispatch({ type: 'setCampaignTitle', value: ev.target.value })}
							/>
						</div>
						<SendFromDropdown
							disabled={!userSession.canSendOnBehalf}
							hideOptionsWithFilters
							canSendOnBehalf
							sendFromOption={sendFrom?.sender}
							sendOnBehalfOf={onSendOnBehalf}
							allowContactBasedOptions
							allowContactOwnerOption
						/>
					</div>
				}
				sideBar={
					<>
						<div className={css(styleSheet.recipientsHeader)}>
							<div className={css(styleSheet.recipientsHeaderTitle)}>
								<div>Recipients</div>
							</div>
							<div className={css(bs.flex, bs.itemsCenter, bs.flexWrap, bs.textSm, bs.justifyStart)}>
								<div className={css(bs.flex, bs.mr2)}>
									{numberOfRecipients != null
										? `${numberOfRecipients} out of ${state.contactIds.length} contact${
												state.contactIds.length !== 1 ? 's' : ''
											} with phone numbers`
										: null}
								</div>
							</div>
							<AutoCompleteSearchField
								anchorClassName='email-recipients-list-autocomplete-search-field-anchor'
								className='email-recipients-list-autocomplete-search-field'
								clearSearchFieldAfterSelectingItem={true}
								dropdownContentClassName={css(bs.wFull)}
								inputId='email-recipients-list-search-input'
								inputProps={{
									placeholder: 'Add additional contacts',
								}}
								leftAccessory={<SearchIcon className='email-recipients-list-autocomplete-search-field-icon' />}
								onItemSelected={(contact: Api.IContact) => {
									if (contact.id) {
										dispatch({ type: 'addRecipient', value: contact.id });
									}
								}}
								resultsLimit={6}
								type={Api.ResourceAutoCompleteViewModelType.Contact}
							/>
						</div>
						<div className={css(bs.flex, bs.flex1, bs.flexCol, bs.overflowXHidden, bs.overflowYAuto)}>
							<div>
								{contactQueries.map((contactQuery, i) => {
									if (!contactQuery.data) {
										return <LoadingSpinner key={i} type='small' />;
									}
									return renderRecipientItem(contactQuery.data);
								})}
							</div>
						</div>
						<div className={css(bs.py2, bs.px4, bs.border0, bs.borderT, bs.borderSolid, bs.borderGray200)}>
							<Checkbox
								id='textingCampaignAgreement-checkbox'
								className={css(bs.mb6)}
								checked={state.consentChecked}
								onChange={() => dispatch({ type: 'toggleConsentChecked' })}
							>
								<span className={css(bs.ml2)}>
									I agree that the recipients have opted in to receive texts and this is not a marketing campaign.
								</span>
							</Checkbox>
							<button
								className={css(bs.ctaButton, bs.wFull)}
								onClick={onNextButtonClick}
								disabled={!numberOfRecipients || !state.consentChecked || !editorState.campaignTitle}
							>
								<span>Next: Send Options</span>
							</button>
						</div>
					</>
				}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<div
						className={css(
							bs.itemsCenter,
							bs.boxBorder,
							bs.flex,
							bs.flex1,
							bs.flexCol,
							bs.overflowAuto,
							bs.py8,
							bs.px6,
							bs.bgGray50
						)}
					>
						<div className={css(bs.flex, bs.flexCol, bs.maxWXl, bs.wFull, bs.mxAuto)}>
							<TextCampaignPageBody
								contentEditorState={editorState.contentEditorState}
								attachmentFiles={editorState.attachmentFiles}
								storedAttachments={editorState.storedAttachments}
								onAttachFiles={files => editorDispatch({ type: 'addAttachmentFiles', value: files })}
								onRemoveAttachmentFile={file => editorDispatch({ type: 'removeAttachmentFile', value: file })}
								onRemoveStoredAttachment={attachmentId =>
									editorDispatch({ type: 'removeStoredAttachment', value: attachmentId })
								}
								onContentStateChanged={content =>
									editorDispatch({ type: 'setTextingContentEditorState', value: content })
								}
							/>
						</div>
					</div>
				</>
			</CampaignComposerBaseLayout>
		</>
	);
}
