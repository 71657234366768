import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Industry, isDevOrTestEnvironment } from '../../../../extViewmodels';
import { ILocationState } from '../../../../models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { ILocalNotification } from '../../../../models/LocalNotifications';
import { useEventLogging } from '../../../../models/Logging';
import { useFullscreenModal, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { EMAILS_TO_SEND_QUERY_KEY, useEmailsToSend } from '../../../../queries';
import * as AppViewModels from '../../../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../../ReactQueryProvider';
import { DashboardCommStatsCard } from '../../../components/Happiness/DashboardCommStatsCard';
import { DashboardDailyUpdatesGroup } from '../../../components/Happiness/DashboardDailyUpdatesGroup';
import { DashboardGoogleBusinessProfileCard } from '../../../components/Happiness/DashboardGoogleBusinessProfileCard';
import {
	DashboardClioIntegrationCard,
	DashboardGoogleBusinessProfileIntegrationCard,
	DashboardMicrosoftDynamics365SalesIntegrationCard,
	DashboardPracticePantherIntegrationCard,
	DashboardQQCatalystIntegrationCard,
	DashboardRedtailIntegrationCard,
	DashboardWealthboxIntegrationCard,
} from '../../../components/Happiness/DashboardIntegrationsCard';
import { DashboardDonorPerfectIntegrationCard } from '../../../components/Happiness/DashboardIntegrationsCard/DonorPerfect';
import { DashboardMergeLinkIntegrationCard } from '../../../components/Happiness/DashboardIntegrationsCard/MergeLink';
import { DashboardMyCaseIntegrationCard } from '../../../components/Happiness/DashboardIntegrationsCard/MyCase';
import { DashboardSatisfactionCard } from '../../../components/Happiness/DashboardSatisfactionCard';
import { DashboardSocialMediaCard, SocialMediaGhostCard } from '../../../components/Happiness/DashboardSocialMediaCard';
import { DashboardSuggestedForYouCard } from '../../../components/Happiness/DashboardSuggestedForYouCard';
import { EmailsToSend } from '../../../components/Happiness/EmailsToSend';
import { PhysicalGiftsCard } from '../../../components/Happiness/PhysicalGiftsCard';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { LocalNotificationObserver } from '../../../components/LocalNotificationObserver';
import { Modal } from '../../../components/Modal';
import { KitEmailComposer } from '../../../components/email/KitEmailComposer';
import { SingleEmailGuideModal } from '../../../components/email/guide/SingleEmailGuideModal';
import { OwnerTagReport } from '../../Dashboard/OwnerTagReport';
import { useHasUsedWebApp } from './hooks/useHasUsedWebApp';
import { useLaunchCampaignApproval } from './hooks/useLaunchCampaignApproval';
import { useLaunchCampaignFromTemplate } from './hooks/useLaunchCampaignFromTemplate';
import { useLaunchFeedFilter } from './hooks/useLaunchFeedFilter';
import { useLaunchIntegrationOwnership } from './hooks/useLaunchIntegrationOwnership';
import { useLaunchKitActionItems } from './hooks/useLaunchKitActionItem';
import { useLaunchKitReminderCampaigns } from './hooks/useLaunchKitReminderCampaigns';
import { useLaunchOwnerReport } from './hooks/useLaunchOwnerReport';
import { useLaunchSingleEmail } from './hooks/useLaunchSingleEmail';
import { styleSheet } from './styles';

const EVENT_LOGGING_CATEGORY = 'Dashboard';

const _Dashboard = () => {
	const location = useLocation();
	let queryCommand = AppViewModels.QueryCommands.Dashboard.parse(location.search);
	if (!queryCommand && !!location.state && Object.prototype.hasOwnProperty.call(location.state, 'command')) {
		queryCommand = location.state as AppViewModels.IQueryStringCommand;
	}
	location.state = null;
	const userSession = useUserSession();
	const history = useHistory();
	const { logEvent, logApiError } = useEventLogging(EVENT_LOGGING_CATEGORY);
	const fullscreenModal = useFullscreenModal();
	const toaster = useToaster();
	const onFullScreenModalClosed = (event: ILocalNotification<string>) => {
		if (event.info === 'onAfterClose') {
			reactQueryClient.invalidateQueries(EMAILS_TO_SEND_QUERY_KEY);
		}
	};
	const [singleEmailGuideViewModel] = React.useState(new AppViewModels.ComposeEmailToWithGuideViewModel(userSession));
	const showSingleEmailModal = () => {
		singleEmailGuideViewModel.show();
	};
	const { data, isLoading, status } = useEmailsToSend({
		handleError: error => {
			logApiError('LoadDashboardEmailsToSend', error);
		},
		userSession,
	});
	const launchCampaignApproval = (campaignId: string) => {
		if (campaignId) {
			const campaign = new Api.CampaignViewModel(userSession, {
				id: campaignId,
			});
			const promise = campaign.load();
			if (promise) {
				logEvent('CampaignLoad', { id: campaignId });
				promise
					.then(() => {
						switch (campaign.status) {
							case Api.EmailSendStatus.WaitingForApproval: {
								const locationState: ILocationState<AppViewModels.CampaignsApprovalViewModel, any> = {
									viewModel: new AppViewModels.CampaignsApprovalViewModel(userSession, [campaign]),
								};
								fullscreenModal?.history?.push({
									pathname: '/email/campaigns/edit',
									state: locationState,
								});
								break;
							}
							case Api.EmailSendStatus.Rejected: {
								toaster.push({
									message: 'Campaign was previously rejected.',
									type: 'errorMessage',
								});
								history?.push(`/reporting/group-email`);
								break;
							}
							case Api.EmailSendStatus.Cancelled: {
								const locationState: ILocationState<AppViewModels.CampaignsApprovalViewModel, any> = {
									viewModel: new AppViewModels.CampaignsApprovalViewModel(userSession, [campaign]),
								};
								fullscreenModal?.history?.push({
									pathname: '/email/bulk-send-cancelled',
									state: locationState,
								});
								break;
							}
							default: {
								const locationState: ILocationState<AppViewModels.CampaignViewModel, any> = { viewModel: campaign };
								history?.push({
									pathname: `/reporting/group-email/${campaign.id}`,
									state: locationState,
								});
								break;
							}
						}
					})
					.catch((error: Api.IOperationResultNoValue) => {
						logApiError('CampaignLoad-Error', error);

						toaster.push({
							message: 'Unable to load campaign',
							type: 'errorMessage',
						});
					});
			}
		}
	};
	const { selectedKitActionItem } = useLaunchKitActionItems({
		eventLoggingCategory: EVENT_LOGGING_CATEGORY,
		queryCommand,
	});
	const { ownerReportId } = useLaunchOwnerReport({ eventLoggingCategory: EVENT_LOGGING_CATEGORY, queryCommand });
	useLaunchFeedFilter({ eventLoggingCategory: EVENT_LOGGING_CATEGORY, queryCommand });
	useLaunchCampaignFromTemplate({ eventLoggingCategory: EVENT_LOGGING_CATEGORY, queryCommand });
	useHasUsedWebApp({ eventLoggingCategory: EVENT_LOGGING_CATEGORY });
	useLaunchSingleEmail({ eventLoggingCategory: EVENT_LOGGING_CATEGORY, queryCommand, showSingleEmailModal });
	useLaunchCampaignApproval({ eventLoggingCategory: EVENT_LOGGING_CATEGORY, launchCampaignApproval, queryCommand });
	const { onCloseIntegrationOwnershipModal, showIntegrationOwnershipModal } = useLaunchIntegrationOwnership({
		eventLoggingCategory: EVENT_LOGGING_CATEGORY,
		queryCommand,
	});
	const { isLoadingKitReminders } = useLaunchKitReminderCampaigns({
		eventLoggingCategory: EVENT_LOGGING_CATEGORY,
		queryCommand,
	});
	const onComposeEmailMessageModalRequestClose = () => {
		history.replace('/dashboard');
	};

	if (isLoadingKitReminders) {
		return (
			<div className={css(styleSheet.root)}>
				<LoadingSpinner type='large' />
			</div>
		);
	}
	if (ownerReportId) {
		return (
			<div className={css(styleSheet.root)}>
				<OwnerTagReport reportId={ownerReportId} />
			</div>
		);
	}
	return (
		<>
			<div className={css(styleSheet.root)}>
				<div className={css(styleSheet.column, styleSheet.leftColumn)}>
					<DashboardSatisfactionCard data={data?.satisfactionSurveyStats} status={status} />
					<DashboardMergeLinkIntegrationCard integrationType={Api.ConfigurableIntegrationType.MergeAccounting} />
					<DashboardMergeLinkIntegrationCard integrationType={Api.ConfigurableIntegrationType.MergeCrm} />
					<DashboardRedtailIntegrationCard />
					<DashboardGoogleBusinessProfileIntegrationCard />
					<DashboardMyCaseIntegrationCard />
					<DashboardClioIntegrationCard />
					<DashboardPracticePantherIntegrationCard />
					<DashboardQQCatalystIntegrationCard />
					<DashboardDonorPerfectIntegrationCard />
					<DashboardMicrosoftDynamics365SalesIntegrationCard />
					{userSession?.account?.additionalInfo?.industry === Industry.Financial ? (
						<DashboardWealthboxIntegrationCard />
					) : null}
					<DashboardGoogleBusinessProfileCard queryCommand={queryCommand} />
					<DashboardDailyUpdatesGroup />

					<DashboardCommStatsCard data={data} status={status} />

					{isLoading ? <SocialMediaGhostCard /> : <DashboardSocialMediaCard data={data} />}
				</div>
				<div className={css(styleSheet.column, styleSheet.rightColumn)}>
					<LocalNotificationObserver
						topic={Topics.APP_FULLSCREEN_MODAL_APPEARANCE}
						onNotificationReceived={onFullScreenModalClosed}
					/>

					<EmailsToSend data={data} isLoading={isLoading} launchCampaignApproval={launchCampaignApproval} />
					{isDevOrTestEnvironment ? <PhysicalGiftsCard /> : null}
					<DashboardSuggestedForYouCard />
				</div>
			</div>
			<SingleEmailGuideModal guideViewModel={singleEmailGuideViewModel} />
			{selectedKitActionItem?.keepInTouchReference?.contact?.isLoaded ? (
				<KitEmailComposer
					actionItem={selectedKitActionItem}
					onDismissedActionItem={onComposeEmailMessageModalRequestClose}
					onRequestClose={onComposeEmailMessageModalRequestClose}
				/>
			) : null}
			<Modal
				isOpen={showIntegrationOwnershipModal}
				shouldCloseOnOverlayClick={true}
				onRequestClose={onCloseIntegrationOwnershipModal}
				useDefaultHeader={true}
			>
				<div className={css(styleSheet.integrationOwnershipModal)}>
					<b>Initials already mapped</b>
					<p>All initials from your agency management system have already been mapped. You&apos;re good to go!</p>
				</div>
			</Modal>
		</>
	);
};

const Dashboard = observer(_Dashboard);
export { Dashboard };
