import * as Api from '@ViewModels';
import * as React from 'react';
import { IContactSelection, ICreateCampaignRequest, ILocationState } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import { remindersToKeepInTouchWithContacts } from '../../../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { getFeedItemViewModel, useDashboardFeed } from '../../../../../queries';
import { KIT_FEED_PAGE_SIZE, KIT_FEED_TYPE } from '../../../KeepInTouches';

export function useLaunchKitReminderCampaigns({
	queryCommand,
	eventLoggingCategory,
}: {
	queryCommand: Api.IQueryStringCommand;
	eventLoggingCategory: string;
}) {
	const isCommandKitReminder = queryCommand?.command === 'kit-reminders';
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const fullscreenModal = useFullscreenModal();
	const { logApiError, logEvent } = useEventLogging(eventLoggingCategory);
	const { isLoading, isFetching, status, data } = useDashboardFeed({
		enabled: isCommandKitReminder,
		handleError: err => {
			errorMessages.pushApiError(err);
			logApiError('LoadKeepInTouches-Error', err);
		},
		pageSize: KIT_FEED_PAGE_SIZE,
		typeOf: KIT_FEED_TYPE,
	});
	React.useEffect(() => {
		if (isCommandKitReminder && status === 'success') {
			const actionItemVms = data.values
				.map(item => getFeedItemViewModel({ feedItem: item, userSession }))
				.filter(itemVm => itemVm instanceof Api.ActionItemViewModel) as Api.ActionItemViewModel[];
			const { contacts } = remindersToKeepInTouchWithContacts(actionItemVms);
			if (contacts && contacts.length > 0) {
				logEvent('AppLink-KeepInTouchReminders');
				const locationState: ILocationState<any, ICreateCampaignRequest<IContactSelection>> = {
					model: {
						context: {
							bulkFilterRequestOptions: {
								readOnlySearchCriteria: true,
							},
							contacts,
						},
						type: 'Contacts',
					},
				};
				fullscreenModal?.history?.push({
					pathname: '/email/campaigns/create/from-selection',
					state: locationState,
				});
			}
		}
	}, [isCommandKitReminder, status, data, fullscreenModal, logEvent, userSession]);

	return {
		isLoadingKitReminders: isLoading && isFetching,
	};
}
