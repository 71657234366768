import * as Api from '@ViewModels';
import produce from 'immer';
import { useMemo } from 'react';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';

export enum EOwnershipOption {
	All,
	Me,
	Owned,
	Connections,
}

const allCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.All,
};
const meCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.OwnedBy,
};
const ownedCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.OwnedBy,
};
const connectionsCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.Connections,
};
export const EOwnershipOptionsMap = {
	[EOwnershipOption.All]: {
		criteria: allCriteria,
		text: 'All contacts',
	},
	[EOwnershipOption.Me]: {
		criteria: meCriteria,
		text: 'Contacts I own',
	},
	[EOwnershipOption.Owned]: {
		criteria: ownedCriteria,
		text: 'Contacts owned by',
	},
	[EOwnershipOption.Connections]: {
		criteria: connectionsCriteria,
		text: 'My connections',
	},
};

export const useOwnershipFilters = ({
	filter,
	onChangeFilter,
}: {
	filter: Api.IBulkContactsRequest;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}) => {
	const userSession = useUserSession();

	const getOwnershipCriteria = (ownershipOption: EOwnershipOption, selectedUser?: Api.IUser | null) => {
		const criteria = JSON.parse(JSON.stringify(EOwnershipOptionsMap[ownershipOption].criteria));

		const userId =
			ownershipOption === EOwnershipOption.Me || ownershipOption === EOwnershipOption.Connections
				? userSession.user.id
				: ownershipOption === EOwnershipOption.Owned
					? selectedUser?.id ?? ''
					: null;

		if (!!userId && criteria.value !== userId) {
			criteria.value = userId;
		}

		return { criteria };
	};

	const changeOwnershipFilter = (ownershipOption: EOwnershipOption, selectedUser?: Api.IUser) => {
		const nextFilter = produce(filter, draftFilter => {
			draftFilter.ownershipFilter = getOwnershipCriteria(ownershipOption, selectedUser);
		});
		onChangeFilter(nextFilter);
	};

	const selectedOwnership = useMemo(() => {
		const ownershipCriterion = filter.ownershipFilter?.criteria;

		const isCurrentUserOwner = !!ownershipCriterion && ownershipCriterion.value === userSession.user.id;

		if (!ownershipCriterion || ownershipCriterion.property === Api.ContactFilterCriteriaProperty.All) {
			return EOwnershipOption.All;
		} else if (isCurrentUserOwner && ownershipCriterion.property === Api.ContactFilterCriteriaProperty.OwnedBy) {
			return EOwnershipOption.Me;
		} else if (
			!!ownershipCriterion.value &&
			ownershipCriterion.property === Api.ContactFilterCriteriaProperty.OwnedBy
		) {
			return EOwnershipOption.Owned;
		} else if (ownershipCriterion.property === Api.ContactFilterCriteriaProperty.Connections) {
			return EOwnershipOption.Connections;
		}
	}, [filter, userSession]);

	return {
		changeOwnershipFilter,
		getOwnershipCriteria,
		selectedOwnership,
	};
};
