import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import produce from 'immer';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { IUserSessionComponentProps } from '../../../../../models/AppState';
import { useEventLogging } from '../../../../../models/Logging';
import {
	getDisplayName,
	isTagSearchContactFilterCriteria,
	numberToFormattedCount,
} from '../../../../../models/UiUtils';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import {
	EntityPropertySearchTag,
	IEntityPropertySearch,
} from '../../../../components/entities/EntityPropertySearchTag';
import { ContactFilterRequestTagList } from '../../../../components/reporting/emails/GroupEmailTableRow/ContactFilterRequestTagList';
import { baseStyleSheet } from '../../../../styles/styles';
import { EOwnershipOption, useOwnershipFilters } from '../hooks/useOwnershipFilter';
import { EStatusOption, useStatusFilters } from '../hooks/useStatusFilter';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	filter: Api.IBulkContactsRequest;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
	contactsVM: Api.ContactsViewModel;
}

export const PeopleSearchDescription: React.FC<IProps> = observer(
	({ filter, className, styles, onChangeFilter, contactsVM }) => {
		const userSession = useUserSession();
		const { logApiError } = useEventLogging();
		const { selectedOwnership } = useOwnershipFilters({
			filter,
			onChangeFilter,
		});
		const { selectedStatuses } = useStatusFilters({ filter, onChangeFilter });
		const [selectedUser, setSelectedUser] = React.useState('');
		const isTablet = useMediaQuery({ maxWidth: 1200 });
		const isWideScreen = useMediaQuery({ minWidth: 1400 });
		const maxTagsToShow = isWideScreen ? 4 : isTablet ? 2 : 3;

		React.useEffect(() => {
			const ownedBy = filter.ownershipFilter?.criteria;

			if (!ownedBy?.value || ownedBy.value === userSession.user.id) {
				setSelectedUser('');
				return;
			}

			const user = new Api.UserViewModel(userSession, { id: ownedBy.value });
			user
				.load()
				?.then(() => {
					setSelectedUser(getDisplayName(user));
				})
				?.catch((err: Api.IOperationResultNoValue) => {
					logApiError('ContactsFetchUser-Error', err);
				});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [filter]);

		const removeTag = (tag: IEntityPropertySearch) => {
			const nextFilter = produce(filter, draftFilter => {
				const criteriaWithNoTags = draftFilter.filter.criteria.filter(x => !isTagSearchContactFilterCriteria(x));

				const orTagCriteria = draftFilter.filter.criteria.find(x => x.op === Api.FilterOperator.Or);
				if (orTagCriteria?.criteria) {
					orTagCriteria.criteria = orTagCriteria?.criteria?.filter(x => x.value !== tag.value);
				}

				const andTagCriteria = draftFilter.filter.criteria.find(x => x.op === Api.FilterOperator.And);
				if (andTagCriteria?.criteria) {
					andTagCriteria.criteria = andTagCriteria.criteria.filter(x => x.value !== tag.value);
				}

				const notTagCriteria = draftFilter.filter.criteria
					.filter(x => x.op === Api.FilterOperator.Not)
					.filter(x => x.value !== tag.value);

				draftFilter.filter.criteria = [...criteriaWithNoTags];
				if (orTagCriteria) {
					draftFilter.filter.criteria.push(orTagCriteria);
				}
				if (andTagCriteria) {
					draftFilter.filter.criteria.push(andTagCriteria);
				}
				notTagCriteria.forEach(criterion => {
					draftFilter.filter.criteria.push(criterion);
				});
			});
			onChangeFilter(nextFilter);
		};

		if (!contactsVM.fetchResults) {
			return null;
		}

		const search =
			filter.filter?.criteria?.find(x => x.property === Api.ContactFilterCriteriaProperty.All)?.value ??
			filter.filter?.criteria?.find(x => x.property === Api.ContactFilterCriteriaProperty.Name)?.value ??
			filter.filter?.criteria?.find(x => x.property === Api.ContactFilterCriteriaProperty.Company)?.value ??
			'';

		const SelectedStatusToDescriptionMap = {
			[EStatusOption.WithEmail]: 'with an email',
			[EStatusOption.WithPhoneNumber]: 'with a phone number',
			[EStatusOption.WithAddress]: 'with an address',
			[EStatusOption.HaveAlertsDue]: 'with alerts due',
			[EStatusOption.PrivateContacts]: 'marked private',
			[EStatusOption.KeepInTouch]: 'due to keep in touch',
			[EStatusOption.WithoutTags]: 'without tags',
			[EStatusOption.NoEmail]: 'without email',
			[EStatusOption.WithoutPhoneNumber]: 'without phone number',
			[EStatusOption.NoAddress]: 'without address',
			[EStatusOption.Archived]: 'Archived',
		};

		const statusesDescription = selectedStatuses
			.map(status => {
				return SelectedStatusToDescriptionMap[status];
			})
			.join(', ');

		const creationDateDescription = React.useMemo(() => {
			const creationDateCriteria = filter.filter?.criteria?.find(criterion => {
				return criterion.criteria?.find(subCriterion => {
					return subCriterion.property === Api.ContactFilterCriteriaProperty.CreationDate;
				});
			});

			if (!creationDateCriteria) {
				return '';
			}

			const startDate = moment(
				creationDateCriteria?.criteria.find(criterion => {
					return criterion.op === Api.FilterOperator.Gte;
				}).value
			);
			const endDateCriteria = creationDateCriteria?.criteria.find(criterion => {
				return criterion.op === Api.FilterOperator.Lte;
			});

			let endDate;
			if (endDateCriteria?.value) {
				endDate = moment(endDateCriteria?.value);
			}

			if (!startDate && !endDate) {
				return '';
			} else if (!endDate) {
				return `with a Creation Date after ${startDate.format('MMMM Do, YYYY')}`;
			}

			return `with a Creation Date between ${startDate.format('MMMM Do, YYYY')} and ${endDate.format('MMMM Do, YYYY')}`;
		}, [filter]);

		const keyFactDescription = React.useMemo(() => {
			const keyFactCriteria = filter.filter?.criteria?.find(criterion => {
				return criterion.criteria?.find(subCriterion => {
					return subCriterion.property === Api.ContactFilterCriteriaProperty.KeyDateKind;
				});
			});
			if (!keyFactCriteria?.criteria) {
				return '';
			}

			const startDate = moment(
				keyFactCriteria.criteria.find(criterion => {
					return criterion.op === Api.FilterOperator.Gte;
				}).value
			);
			const endDate = moment(
				keyFactCriteria.criteria.find(criterion => {
					return criterion.op === Api.FilterOperator.Lte;
				}).value
			);
			const keyDateCriteria = keyFactCriteria.criteria.find(criterion => {
				return criterion.property === Api.ContactFilterCriteriaProperty.KeyDateKind;
			});
			if (keyDateCriteria?.value) {
				const isAYearOrMoreApart = endDate.diff(startDate, 'days') > 364;
				if (keyDateCriteria.value === Api.KeyDateKind.Birthday && isAYearOrMoreApart) {
					const guessAge = (date: moment.Moment) => {
						return moment().diff(date, 'years');
					};
					const estimatedDateOfFilterCreation = endDate.format('MMMM Do');
					const today = moment().format('MMMM Do');

					const asOfMessage =
						today === estimatedDateOfFilterCreation
							? 'as of today'
							: ` as of ${estimatedDateOfFilterCreation} (when filter was created)`;

					return `between the ages of ${guessAge(endDate)} and ${guessAge(startDate)} ${asOfMessage}`;
				}
				return `with a ${keyDateCriteria.value} between ${startDate.format('MMMM Do, YYYY')} and ${endDate.format(
					'MMMM Do, YYYY'
				)}`;
			}
			return '';
		}, [filter]);

		return (
			<div
				className={`${css(styleSheet.container, baseStyleSheet.truncateText, ...(styles || []))} ${className || ''}`}
			>
				<span className={css(baseStyleSheet.fontBold)}>{numberToFormattedCount(contactsVM.totalNumberOfResults)}</span>
				&nbsp;
				{contactsVM.totalNumberOfResults === 1 ? 'contact' : 'contacts'}
				&nbsp;
				{selectedOwnership === EOwnershipOption.Me && <span>I own&nbsp;</span>}
				{selectedOwnership === EOwnershipOption.Owned && selectedUser && (
					<span>
						{' '}
						owned by <span className={css(baseStyleSheet.fontBold)}>{selectedUser}</span>
						&nbsp;
					</span>
				)}
				{selectedOwnership === EOwnershipOption.Connections && <span>with connections&nbsp;</span>}
				{!!search && (
					<span>
						{' for "'}
						<span className={css(baseStyleSheet.fontBold)}>{search + '"'}</span>
						&nbsp;
					</span>
				)}
				{statusesDescription}
				{!!statusesDescription && <span>&nbsp;</span>}
				{creationDateDescription}
				{!!creationDateDescription && <span>&nbsp;</span>}
				{keyFactDescription}
				{!!keyFactDescription && <span>&nbsp;</span>}
				<ContactFilterRequestTagList
					filterRequest={{ criteria: filter.filter?.criteria, op: filter.filter?.op }}
					maxTagsToDisplayInline={maxTagsToShow}
					renderTag={(tag, op, index) => {
						const s: IEntityPropertySearch = { value: tag };
						return (
							<>
								{index === 0 ? 'with ' : ``}
								<span className={css(styleSheet.tag)} key={`psd-tag-${tag}`}>
									{index > 0 && <span className={css(styleSheet.tagTokenOpPrefix)}>{op.toLowerCase()}</span>}
									<EntityPropertySearchTag
										className={css(styleSheet.filterPill)}
										op={op}
										search={s}
										onRemoveSearch={() => removeTag(s)}
									/>
								</span>
							</>
						);
					}}
					renderOverflowTag={(tag, op) => {
						const s: IEntityPropertySearch = { value: tag };
						return (
							<span className={css(styleSheet.extraTagsItem)} key={`psd-extra-tag-${tag}`}>
								<span className={css(styleSheet.tagTokenOpPrefix)}>{op.toLowerCase()}</span>
								<EntityPropertySearchTag
									className={css(styleSheet.filterPill)}
									op={op}
									search={s}
									onRemoveSearch={() => removeTag(s)}
								/>
							</span>
						);
					}}
				/>
			</div>
		);
	}
);
