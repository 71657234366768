import { css } from 'aphrodite';
import * as React from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { useToaster, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useUserProfilePicMutation, useUserUpdateMutation } from '../../../../../queries';
import ProfilePicPlaceholderImageUrl from '../../../../assets/profile_pic_default.svg';
import { baseStyleSheet } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { styleSheet } from './styles';

export const PersonalSettingsProfilePictureSection: React.FC = () => {
	const toaster = useToaster();
	const { logApiError } = useEventLogging('Settings-Personal');

	const userSession = useUserSession();

	const userProfilePicMutation = useUserProfilePicMutation({
		onError: error => {
			logApiError('UploadProfilePicture-Error', error);

			toaster.push({
				message: 'There was an error uploading your image please try again later',
				type: 'errorMessage',
			});
		},
		onSuccess: updatedUser => {
			userSession.updateUser({ ...userSession.user, profilePic: updatedUser?.profilePic });

			toaster.push({
				message: 'Image uploaded and saved successfully!',
				type: 'successMessage',
			});
		},
	});

	const userUpdateMutation = useUserUpdateMutation({
		onError: error => {
			logApiError('DeleteProfilePicture-Error', error);

			toaster.push({
				message: 'There was an error deleting your image please try again later',
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			userSession.updateUser({ ...userSession.user, profilePic: null });

			toaster.push({
				message: 'Image was deleted successfully!',
				type: 'successMessage',
			});
		},
	});

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File = e.target.files?.[0];
		if (!file) {
			return; // Cancelled the file picker
		}
		if (file.size > 2097152) {
			e.target.value = null;

			toaster.push({
				message: 'file size exceeded 2MB',
				type: 'errorMessage',
			});
			return;
		}

		e.target.value = null;
		userProfilePicMutation.mutate({ file });
	};

	const deleteProfileImage = () => {
		userUpdateMutation.mutate({ user: { ...userSession.user, profilePic: '' } });
	};

	const profilePic = userSession.user?.profilePic;

	return (
		<section>
			<div className={css(baseStyleSheet.mb2, styleSheet.profilePlaceHolderContainer)}>
				<span className={css(styleSheet.formFieldLabel)}>Profile Picture</span>
				<figure
					className={css(styleSheet.profilePlaceHolder)}
					style={!profilePic ? { backgroundImage: `url(${ProfilePicPlaceholderImageUrl})` } : undefined}
				>
					{profilePic && <img src={profilePic} alt='profile placeholder' />}
				</figure>
				<div>
					{!profilePic ? (
						<label htmlFor='profileUploadInput'>
							<input
								id='profileUploadInput'
								accept='image/png, image/jpeg, image/jpg'
								disabled={userProfilePicMutation.isLoading}
								onChange={onFileChange}
								style={{ position: 'absolute', visibility: 'hidden', zIndex: -1 }}
								type='file'
							/>
							<p className={css(styleSheet.profilePlaceHolderBlurb)}>JPG or PNG, max size 2MB</p>
							<div className={css(baseStyleSheet.ctaButtonSmall, styleSheet.profileUploadCta)}>
								{userProfilePicMutation.isLoading ? <LoadingSpinner type='tiny' /> : <span>Upload</span>}
							</div>
						</label>
					) : (
						<button
							className={css(styleSheet.deleteProfilePicture)}
							disabled={userUpdateMutation.isLoading}
							onClick={deleteProfileImage}
						>
							{userUpdateMutation.isLoading ? 'Deleting...' : 'Delete'}
						</button>
					)}
				</div>
			</div>
		</section>
	);
};
