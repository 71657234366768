import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { FormFieldType, IEventRegistrationSurvey, IFormField } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { ErrorMessage } from '../../../surveys/components/surveys/EventSurvey/EventSurveyForm';
import { Button } from '../../components/Button';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { FormFieldWithOptions } from '../../components/FormfieldWithOptions';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { ISelectBoxOption } from '../../components/SelectBox';
import { baseStyleSheet, bs } from '../../styles/styles';
import { styleSheet } from './styles';

export const ImagePreviewSmall = ({
	onRemoveClick,
	isLoading,
	name,
	url,
}: {
	onRemoveClick: () => void;
	isLoading?: boolean;
	name?: string;
	url: string;
}) => {
	return (
		<>
			{!isLoading ? (
				<div className={css(styleSheet.logoWrap)}>
					<img className={css(styleSheet.logoImage)} src={url} alt={name} />{' '}
					<p className={css(baseStyleSheet.truncateText, styleSheet.noMargin, styleSheet.logoName)}>{name}</p>
					<DeprecatedCloseButton type='button' onClick={onRemoveClick} className={css(styleSheet.logoRemove)} />
				</div>
			) : (
				<LoadingSpinner type='tiny' />
			)}
		</>
	);
};

export const AdditionalQuestionsBase = ({
	fields,
	onUpdateFields,
	isAdditionalQuestionsDisabled,
	isEdit,
}: IImpersonationContextComponentProps & {
	fields: IFormField<string>[];
	onUpdateFields: (fields: IFormField<string>[]) => void;
	isAdditionalQuestionsDisabled: boolean;
	isEdit: boolean;
}) => {
	const { formState } = useFormContext<IEventRegistrationSurvey>();
	const handleRemoveQuestion = React.useCallback(
		(index: number) => {
			const newFields = [...fields];
			newFields.splice(index, 1);
			onUpdateFields(newFields);
		},
		[fields, onUpdateFields]
	);
	const onChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, index: number) => {
			const newFields = [...fields];
			newFields[index].label = e.target.value;
			onUpdateFields(newFields);
		},
		[fields, onUpdateFields]
	);

	const handleAddingQuestion = () => {
		const newFields = [...fields];
		newFields.push({ id: uuid(), label: '', fieldType: FormFieldType.String, isOptional: true, options: [] });
		onUpdateFields(newFields);
	};

	const onToggleCheckChanged = React.useCallback(
		(index: number) => {
			const newFields = [...fields];
			newFields[index].isOptional = !newFields[index].isOptional;
			onUpdateFields(newFields);
		},
		[fields, onUpdateFields]
	);

	const onUpdateField = React.useCallback(
		(field: IFormField<string>, index) => {
			const newFields = [...fields];
			newFields[index].options = field.options;
			onUpdateFields(newFields);
		},
		[fields, onUpdateFields]
	);

	const onRenderTriggerTitle = (selectedOption: ISelectBoxOption<FormFieldType>): React.ReactNode => {
		return <span>{selectedOption.title}</span>;
	};

	const handleFormFieldTypeChange = React.useCallback(
		(selectedType: ISelectBoxOption<FormFieldType>, questionIndex: number) => {
			const newFields = [...fields];
			newFields[questionIndex].fieldType = selectedType.value;
			onUpdateFields(newFields);
		},
		[fields, onUpdateFields]
	);

	const renderField = React.useCallback(
		(field: IFormField<string>, questionIndex: number) => {
			const value = fields[questionIndex]?.label || '';

			return (
				<FormFieldWithOptions
					key={`${field.id}-question-${questionIndex}`}
					field={field}
					questionIndex={questionIndex}
					value={value}
					onToggleCheckChanged={onToggleCheckChanged}
					onChange={onChange}
					onRenderTriggerTitle={onRenderTriggerTitle}
					handleFormFieldTypeChange={handleFormFieldTypeChange}
					handleRemoveQuestion={handleRemoveQuestion}
					showRemove
					onUpdateField={onUpdateField}
					isEdit={isEdit}
				/>
			);
		},
		[fields, handleFormFieldTypeChange, handleRemoveQuestion, onChange, onToggleCheckChanged, onUpdateField, isEdit]
	);

	return (
		<div className={css(bs.flex, bs.flexCol, bs.fullWidth)}>
			{fields?.length ? (
				<section className={css(styleSheet.bottomSection)}>
					<div className={css(styleSheet.section, styleSheet.bottomSectionPadding, styleSheet.sectionFullWidth)}>
						<section className={css(bs.flex, bs.flexCol)}>
							<h3 className={css(styleSheet.sectionTitle)}>Additional Questions</h3>
							<p className={css(styleSheet.blurb)}>Once the survey is saved, the question type cannot be changed.</p>
						</section>
						<div className={css(styleSheet.formCustomControlWrap)}>
							{fields.map((field, questionIndex) => {
								if (questionIndex !== 0) {
									return null;
								}

								return (
									<Fragment key={field.id}>
										{renderField(field, questionIndex)}
										<ErrorMessage message={formState?.errors?.customForm?.fields?.[questionIndex]?.message} />
									</Fragment>
								);
							})}
						</div>
					</div>
				</section>
			) : null}
			{fields?.length > 1 ? (
				<>
					{fields.map((field, questionIndex) => {
						if (questionIndex === 0) {
							return null;
						}

						return (
							<section className={css(styleSheet.bottomSection)} key={`${field.id}-question-${questionIndex}`}>
								<div className={css(styleSheet.section, styleSheet.bottomSectionPadding, styleSheet.sectionFullWidth)}>
									{renderField(field, questionIndex)}
								</div>
							</section>
						);
					})}
				</>
			) : null}
			<footer className={css(bs.flex, styleSheet.addQuestionFooter)}>
				<Button
					type='button'
					kind='custom'
					className={css(styleSheet.addQuestionButton)}
					disabled={isAdditionalQuestionsDisabled}
					onClick={handleAddingQuestion}
					label={`+ Add ${fields?.length ? 'Another' : 'Custom'} Question`}
				/>
			</footer>
		</div>
	);
};

export const AdditionalQuestions = inject(ImpersonationContextKey)(AdditionalQuestionsBase);
