import { ICompany, IOperationResultNoValue, IPhoneNumber } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { CompanyViewModel } from '../../../../viewmodels/AppViewModels';
import { CompanyInfoKeyFacts } from '../../../containers/Company/components/CompanyInfoKeyFacts';
import { CompanyInfoTags } from '../../../containers/Company/components/CompanyInfoTags';
import { bs } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { EntityAddress } from '../../entities/EntityAddress';
import { EntityInfoField } from '../../entities/EntityInfoField';
import { EntityInfoFieldLabel } from '../../entities/EntityInfoFieldLabel';
import { EntityInfoPhoneNumbers } from '../../entities/EntityInfoPhoneNumbers';
import { SocialProfiles } from '../../entities/SocialProfiles';
import { styleSheet } from './styles';

interface IProps extends IErrorMessageComponentProps, IEventLoggingComponentProps {
	className?: string;
	compactLayout?: boolean;
	company: CompanyViewModel;
	onEditButtonClick?: () => void;
}

interface IState {
	phoneNumbers?: IPhoneNumber[];
}

class _CompanyInfo extends React.Component<IProps, IState> {
	public readonly state: IState = {};
	public render() {
		const { company } = this.props;
		return (
			<div className={`${css(styleSheet.container)} company-info ${this.props.className || ''}`}>
				{!!company && !company.isLoading ? (
					this.renderContent()
				) : (
					<LoadingSpinner className={css(styleSheet.loading)} type='large' />
				)}
			</div>
		);
	}

	private renderContent() {
		const { company, compactLayout, onEditButtonClick } = this.props;
		const { phoneNumbers } = this.state;
		const emailDomain = !!company.emailDomains && company.emailDomains.length > 0 ? company.emailDomains[0] : null;
		return (
			<>
				<div className={css(styleSheet.header)}>
					<div className={`${css(styleSheet.headerTitle)} truncate-text`}>{company.companyName}</div>
					<div className={css(styleSheet.socialPrifles)}>
						<SocialProfiles socialProfiles={company ? company.socialProfiles : []} />
					</div>
					{!!company.bio && (
						<EntityInfoField value={company.bio} valueClassName={css(styleSheet.headerBio)} enableLinkify={true} />
					)}
				</div>
				<div className={css(styleSheet.body)}>
					<div className={css(bs.mt5)}>
						<CompanyInfoKeyFacts compactLayout={compactLayout} companyVm={company} />
						<CompanyInfoTags companyVm={company} />
					</div>
					<div className={css(styleSheet.contactInfo)}>
						<div>
							{!company.phoneNumbers ||
								(company.phoneNumbers.length === 0 && <EntityInfoFieldLabel title='Phone Number' />)}
							<EntityInfoPhoneNumbers
								disabled={!!company.isBusy}
								onRequestAddPhoneNumber={this.onAddPhoneNumber}
								onRequestRemovePhoneNumber={this.onRemovePhoneNumber}
								onRequestUpdatePhoneNumber={this.onUpdatePhoneNumber}
								phoneNumbers={phoneNumbers || company.phoneNumbers || []}
							/>
						</div>
						{!!emailDomain && (
							<div>
								<EntityInfoFieldLabel title='Email Domain' />
								{emailDomain}
							</div>
						)}
						<EntityAddress address={company.address} />
						{!!company.webSite && <EntityInfoField label='Website' value={company.webSite} enableLinkify={true} />}
						<button className={`${css(styleSheet.editButton)}`} onClick={onEditButtonClick}>
							<span>Edit</span>
						</button>
					</div>
				</div>
			</>
		);
	}

	private onUpdatePhoneNumber = (phoneNumber: IPhoneNumber, index: number) => {
		if (index >= 0) {
			const { company } = this.props;
			const phoneNumbers = [...(company.phoneNumbers || [])];
			phoneNumbers.splice(index, 1, phoneNumber);
			this.updatePhoneNumbers(phoneNumbers, 'UpdatePhoneNumber');
		}
	};

	private onRemovePhoneNumber = (_: IPhoneNumber, index: number) => {
		if (index >= 0) {
			const { company } = this.props;
			const phoneNumbers = [...(company.phoneNumbers || [])];
			phoneNumbers.splice(index, 1);
			this.updatePhoneNumbers(phoneNumbers, 'RemovePhoneNumber');
		}
	};

	private onAddPhoneNumber = (phoneNumber: IPhoneNumber) => {
		const { company } = this.props;
		const phoneNumbers = [...(company.phoneNumbers || []), phoneNumber];
		this.updatePhoneNumbers(phoneNumbers, 'AddPhoneNumber');
	};

	private updatePhoneNumbers = (phoneNumbers: IPhoneNumber[], eventName: string) => {
		const { company, logEvent, errorMessages } = this.props;
		const contactModel: ICompany = {
			...company.toJs(),
			phoneNumbers,
		};
		const promise = company.update(contactModel);
		if (promise) {
			logEvent(eventName, { count: phoneNumbers.length });
			// set override
			this.setState({
				phoneNumbers,
			});
			promise
				.then(() => {
					this.setState({
						phoneNumbers: null,
					});
				})
				.catch((error: IOperationResultNoValue) => {
					this.setState({
						phoneNumbers: null,
					});

					logEvent(`${eventName}-Error`, { ...error });

					errorMessages.pushApiError(error);
				});
		}
	};
}

const CompanyInfoAsObserver = observer(_CompanyInfo);
const CompanyInfoWithContext = inject(ErrorMessagesViewModelKey)(CompanyInfoAsObserver);
export const CompanyInfo = withEventLogging(CompanyInfoWithContext, 'CompanyInfo');
