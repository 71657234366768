import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useLocation } from 'react-router';
import { ConditionalAccess } from '../../../../admin/components/ConditionalAccess';
import { TemplateActions } from '../../../../admin/containers/templates/TemplateCategory';
import { CardSize, IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { isTemplateNew } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { invalidateMyTemplates } from '../../../../queries';
import { AdminPermission } from '../../../../viewmodels/AdminViewModels';
import { ITemplateCard, KnownCategories } from '../../../../viewmodels/AppViewModels';
import FinraImage from '../../../assets/finra.png';
import { bs } from '../../../styles/styles';
import { DeprecatedMoreMenu } from '../../DeprecatedMoreMenu';
import { IMoreMenuItem } from '../../MoreMenu';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { CampaignExpiryHint } from '../../campaigns/CampaignExpiryHint';
import { OpenLetter } from '../../svgs/icons/OpenLetter';
import { SendNow } from '../../svgs/icons/SendNow';
import { SocialMediaIndicatorIcon } from '../../svgs/icons/SocialMediaIndicatorIcon';
import { TemplateKeywords } from '../TemplateKeywords';
import { CampaignSummaryFromContent, CampaignTemplateCardCta, CampaignTemplateCardMenu } from './presentation';
import { styleSheet } from './styles';

export interface ICampaignTemplateCardProps extends IImpersonationContextComponentProps {
	card: ITemplateCard;
	cardSize?: CardSize;
	category?: KnownCategories;
	className?: string;
	copyMenu?: IMoreMenuItem<string>[];
	ctaText?: string;
	industry?: Api.Industry;
	isAccountTemplate?: boolean;
	isFeatured?: boolean;
	isFinraReviewed?: boolean;
	onCtaClicked?(e?: React.MouseEvent<Element>): void;
	onDelete?(): void;
	onMenuItemClicked?(menuItem: IMoreMenuItem<TemplateActions>, e: React.MouseEvent<HTMLElement>): void;
	onShare?(isShared: boolean): void;
	onSocialClicked?(e: React.MouseEvent<Element>): void;
	rowIndex?: number;
	styles?: StyleDeclarationValue[];
	templateIndex?: number;
	useWholeCardAsCTA?: boolean;
}

const CampaignTemplateCardBase = ({
	card,
	cardSize,
	category,
	copyMenu,
	ctaText,
	impersonationContext,
	industry,
	isAccountTemplate,
	isFeatured,
	isFinraReviewed,
	onCtaClicked,
	onDelete,
	onMenuItemClicked,
	onShare,
	onSocialClicked,
	rowIndex,
	styles,
	templateIndex,
	useWholeCardAsCTA,
}: ICampaignTemplateCardProps) => {
	const userSession = useUserSession();
	const loc = useLocation();
	const [showSocialPopover, , setShowSocialPopover] = useLambda(false);
	const expirationDate = card?.schedule?.expirationDate ? moment(card?.schedule?.expirationDate) : undefined;
	// intentionally disabling social media indicator while under impersonation context until we have compliance figured out
	const containsSocialMedia =
		userSession?.account?.features?.socialMedia?.enabled && card?.associatedTemplates?.length > 0;
	const showingSocialMedia = !impersonationContext?.isValid && !loc.pathname?.includes('ghost') && containsSocialMedia;
	const isMyTemplate = category === KnownCategories.MyTemplates;

	const onSocialMediaIndicatorClicked = (e: React.MouseEvent<SVGSVGElement>) => {
		if (useWholeCardAsCTA) {
			return;
		}
		setShowSocialPopover(false)();
		onSocialClicked?.(e);
	};

	const isNew = isTemplateNew(card);

	const handleInvalidateTemplates = () => {
		invalidateMyTemplates({ templateType: Api.TemplateType.Email });
	};

	return (
		<div
			className={css(styleSheet.container, isFeatured && styleSheet.featured, ...(styles || []))}
			onClick={useWholeCardAsCTA ? onCtaClicked : undefined}
		>
			<ConditionalAccess permission={AdminPermission.CreateCampaigns}>
				{copyMenu?.length && (
					<DeprecatedMoreMenu
						menuButtonClassName={css(styleSheet.actionsColMoreButton)}
						menuItems={copyMenu}
						onMenuItemClicked={onMenuItemClicked}
					/>
				)}
			</ConditionalAccess>
			{rowIndex === 0 && isFeatured && templateIndex === 0 && <SendNow className={css(styleSheet.sendNow)} />}
			{isAccountTemplate ? (
				<CampaignTemplateCardMenu card={card} userSession={userSession} onDelete={onDelete} onShare={onShare} />
			) : null}
			{rowIndex === 0 && isFeatured && templateIndex === 2 && <OpenLetter className={css(styleSheet.openLetter)} />}
			<div className={css(styleSheet.headerContainer)}>
				<div
					className={css(
						styleSheet.nameContainer,
						cardSize === CardSize.Small ? styleSheet.nameContainerSmall : undefined,
						cardSize === CardSize.Medium ? styleSheet.nameContainerMedium : undefined,
						isFeatured && rowIndex === 0 ? styleSheet.flagHighlight : undefined
					)}
				>
					<div className={css(bs.truncateText)}>{card.name || 'Custom Post'} </div>
					{expirationDate ? <CampaignExpiryHint expirationDate={expirationDate} /> : null}
					{isNew ? <span className={css(styleSheet.newItem)}>(New!)</span> : null}
				</div>
				{showingSocialMedia ? (
					<TinyPopover
						align='end'
						anchor={
							<SocialMediaIndicatorIcon
								className={css(bs.cursorPointer, styleSheet.socialMediaTag)}
								onClick={onSocialMediaIndicatorClicked}
								onMouseEnter={setShowSocialPopover(true)}
								onMouseLeave={setShowSocialPopover(false)}
							/>
						}
						anchorStyles={[styleSheet.popoverAnchor]}
						dismissOnOutsideAction={true}
						isOpen={showSocialPopover}
						placement={['top', 'left', 'bottom']}
						type={PopoverType.lightBlue}
					>
						<div className={css(styleSheet.popover)}>Also available for social media. </div>
					</TinyPopover>
				) : (
					<div className={css(styleSheet.popoverContainer)} />
				)}
			</div>
			<CampaignSummaryFromContent card={card} cardSize={cardSize} isAccountTemplate={isAccountTemplate} />
			<CampaignTemplateCardCta
				ctaText={ctaText}
				finraImage={FinraImage}
				isFinraReviewed={isFinraReviewed}
				onCtaClicked={onCtaClicked}
				useWholeCardAsCTA={useWholeCardAsCTA}
			/>
			{isMyTemplate && card ? (
				<TemplateKeywords
					templateId={card.id}
					creatorId={card?.creator?.id}
					industry={industry}
					keywords={card?.keywords}
					invalidateTemplates={handleInvalidateTemplates}
				/>
			) : null}
		</div>
	);
};

export const CampaignTemplateCard = inject(ImpersonationContextKey)(CampaignTemplateCardBase);
