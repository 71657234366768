import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAutomationContactPreviewQuery({
	impersonationContext,
	id,
	createAutomationRequest,
	enabled,
}: {
	impersonationContext?: Api.IImpersonationContext;
	id: string;
	createAutomationRequest: Api.ICreateAutomationRequest;
	enabled?: boolean;
}) {
	const userSession = useUserSession();

	return useQuery({
		queryKey: [`automation/<id>/contact/preview`, id, createAutomationRequest, impersonationContext],
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IPreviewCreateAutomationResult>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `Automation/${id}/Contact/preview`,
				}),
				'POST',
				createAutomationRequest
			);
		},
		enabled,
	});
}
