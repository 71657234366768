import { brandPrimaryHover } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	splitView: {
		minHeight: '100%',
	},
	profile: {
		padding: '24px 30px 0 0',
		position: 'relative',
	},
	loading: {
		marginTop: 30,
	},
	profileHeader: {
		textAlign: 'right',
	},
	editButton: {
		fontSize: 14,
		color: brandPrimaryHover,
		lineHeight: '20px',
	},
});
