import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, brandSecondary, hotPink, titles } from '../../../styles/colors';
import { baseStyleSheet, CssSelectors, Layouts, truncateTextStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	actionsColMoreButton: {
		position: 'absolute',
		right: 5,
		top: 5,
	},
	body: {
		background: 'transparent !important',
		color: '#3F3F3F !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
	},
	bodySmall: {
		paddingTop: 0,
	},
	button: {
		background: 'none',
		color: brandPrimaryHover,
		marginTop: 20,
		maxWidth: 145,
		width: 145,
	},
	buttonStack: {
		justifyContent: 'space-between',
	},
	cardText: {
		background: 'transparent !important',
		color: 'titles !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
		paddingTop: 12,
		...CssSelectors.allDescendants({
			background: 'transparent !important',
			color: 'black !important',
			fontFamily: 'Sora !important',
			fontSize: '14px !important',
			height: 28,
			margin: 0,
		}),
	},
	container: {
		alignItems: 'stretch',
		background: '#fff',
		border: '1px solid #f2f2f2',
		borderRadius: 6,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.100034)',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingBottom: 30,
		paddingLeft: 30,
		position: 'relative',
		...CssSelectors.allDescendants(
			{
				border: `1px dashed ${brandSecondary}`,
				borderRadius: 3,
				boxSizing: 'border-box',
				fontSize: 14,
				padding: '1px 5px',
			},
			'span[data-placeholder="true"]'
		),
	},
	expContainer: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			'div'
		),
	},
	featured: {
		background: 'linear-gradient(156.83deg, #F5FAFD 14.57%, #E8F3F9 42%, #E8F3F9 85.02%)',
	},
	flagHighlight: {
		marginTop: 50,
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: 2,
	},
	keywords: {
		':hover': {
			background: `rgb(199 93 166 / 15%)`,
			zIndex: 10,
		},
		':hover > button': {
			display: 'flex',
		},
		color: hotPink,
		display: 'block',
		fontSize: 14,
		margin: '5px 0',
		position: 'relative',
		zIndex: 1,
	},
	keywordsAddCTA: {
		color: hotPink,
		...baseStyleSheet.fontBold,
		fontSize: 14,
		fontWeight: 'bold',
		height: 29,
		marginLeft: 5,
	},
	keywordsContainer: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 10,
	},
	keywordsIcon: {
		marginRight: 5,
	},
	keywordsRemoveButton: {
		alignItems: 'center',
		display: 'none',
		height: 30,
		justifyContent: 'center',
		position: 'absolute',
		right: -15,
		top: -15,
		transform: 'scale(0.8)',
		width: 30,
	},
	keywordsSelectorInputField: {
		borderColor: hotPink,
		marginLeft: 5,
	},
	keywordsSelectorSaveButton: {
		':hover': {
			background: hotPink,
		},
		background: hotPink,
	},

	menu: {
		position: 'absolute',
		right: 5,
		top: 5,
		width: 30,
	},
	name: {
		...truncateTextStyle,
	},
	nameContainer: {
		borderTopLeftRadius: 9,
		borderTopRightRadius: 9,
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',
		fontSize: 16,
		overflow: 'hidden',
		paddingTop: 30,
		textOverflow: 'ellipsis',
		width: '100%',
		...CssSelectors.allChildren(
			{
				maxWidth: '100%',
			},
			'div'
		),
	},
	nameContainerMedium: {
		color: brandPrimaryHover,
	},
	nameContainerSmall: {
		color: brandPrimaryHover,
		fontSize: 14,
	},
	nameExp: {
		width: 'calc(100% - 5px)',
	},

	openLetter: {
		height: 82,
		position: 'absolute',
		right: -10,
		top: -10,
		transform: 'translate3d(0px, -10px, 0)',
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		marginLeft: 14,
		maxWidth: 240,
		padding: '6px 6px 6px 6px',
	},
	popoverAnchor: {
		zIndex: 5,
	},
	popoverContainer: {
		marginRight: 25,
	},

	reviewed: {
		color: '#89C947',
		marginRight: 20,
	},
	reviewedStack: {
		marginTop: 20,
		...Layouts.horizontalStack(1),
	},
	sendNow: {
		height: 82,
		left: 45,
		position: 'absolute',
		top: -5,
		transform: 'translate3d(-52px, -15px, 0px)',
	},
	sharedBy: {
		fontSize: 11,
		fontStyle: 'italic',
	},
	textContainer: {
		minHeight: 64,
	},
	textContent: {
		color: '#3F3F3F !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
		marginBottom: 0,
		marginTop: 0,
		paddingBottom: 0,
		paddingRight: 20,
		paddingTop: 12,
	},
	textContentSmall: {
		fontSize: '12px !important',
	},
});
