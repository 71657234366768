import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const INTEGRATIONS_QUERY_KEY = 'integrations';

export function useGetExternalIntegrationOAuthClientDisplay({
	integrationProvider,
	impersonationContext,
	onError,
}: {
	integrationProvider: Api.IntegrationProvider;
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.OAuthClientDisplayConfiguration>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `integrations/${integrationProvider}/oAuthClientDisplay`,
				}),
				'GET'
			);
		},
		queryKey: [INTEGRATIONS_QUERY_KEY, integrationProvider, impersonationContext],
	});
}

export const invalidateExternalIntegrationOAuthClientDisplay = ({
	impersonationContext,
}: {
	impersonationContext: Api.IImpersonationContext;
}) => reactQueryClient.invalidateQueries({ queryKey: [INTEGRATIONS_QUERY_KEY, impersonationContext] });
