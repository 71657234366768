import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ILocationState } from '../../../../../models';
import {
	getDefaultDateStringValue,
	getEntityDisplayName,
	numberToCurrencyStringValue,
	openUrlInNewTab,
} from '../../../../../models/UiUtils';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useGetOpportunity } from '../../../../../queries';
import { MoreMenu, MoreMenuItem } from '../../../MoreMenu';
import { StageIndicator } from '../../../boards/StageIndicator';
import { EntityChip } from '../../../chips/EntityChip';
import { FullscreenModalNavLink } from '../../../fullscreen/FullscreenModalNavLink';
import { styleSheet } from './styles';
import './styles.less';

const _OpportunityCard = ({
	className,
	onArchived,
	onClickToEdit,
	onClickToMove,
	onDeleted,
	opportunity,
	showStageIndicator,
}: {
	className?: string;
	onArchived?(): void;
	onClickToEdit?(): void;
	onClickToMove?(): void;
	onDeleted?(): void;
	opportunity: Api.IOpportunity;
	showStageIndicator?: boolean;
}) => {
	const userSession = useUserSession();
	const opportunityQuery = useGetOpportunity({
		opportunityId: opportunity.id,
	});

	const [isHovering, setIsHovering] = React.useState(false);
	const opportunityDetails = opportunityQuery.data ?? null;

	const redirection = React.useMemo(() => {
		if (!opportunityDetails) return;

		const board = opportunityDetails.boardStage.board;
		const redirectLocationState: ILocationState<Api.IOpportunity, any> = {
			viewModel: opportunity,
		};
		return {
			pathname: `/dataBoards/opportunities/${board.id}/${opportunityDetails.id}`,
			state: redirectLocationState,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [opportunityDetails]);

	const toggleMouseHover = (hovering: boolean) => () => {
		setIsHovering(hovering);
	};

	const onEntityClicked = (contactId: string, e: React.MouseEvent<HTMLDivElement>) => {
		const path = `${window.location.origin}/#/people/${contactId}`;
		if (e.ctrlKey || e.metaKey) {
			openUrlInNewTab(path);
			e.preventDefault();
		}
		// Else let the EntityChip handle the click
	};

	const name = opportunity.name || '';
	const opportunityOwner = opportunity.assignees[0];
	const isBusy = false;

	const contactVm = React.useMemo(
		() => new Api.ContactViewModel(userSession, opportunity.primaryContact),
		[userSession, opportunity.primaryContact]
	);

	if (!opportunityDetails) return null;

	return (
		<div
			className={`opportunity-card ${isBusy ? 'opportunity-card-busy' : ''} ${className || ''}`}
			onMouseEnter={toggleMouseHover(true)}
			onMouseLeave={toggleMouseHover(false)}
		>
			{!!isHovering && (
				<MoreMenu menuButtonClassName='opportunity-card-more-menu'>
					<>
						{!opportunity.isArchived ? <MoreMenuItem onClick={onArchived}>Archive</MoreMenuItem> : null}

						{onClickToMove ? <MoreMenuItem onClick={onClickToMove}>Move to Column</MoreMenuItem> : null}

						{onClickToEdit ? (
							<>
								{!opportunity.isArchived ? <MoreMenuItem onClick={onClickToEdit}>Edit</MoreMenuItem> : null}

								{opportunity.isArchived ? <MoreMenuItem onClick={onClickToEdit}>Restore</MoreMenuItem> : null}
							</>
						) : null}

						<MoreMenuItem onClick={onDeleted}>Permanently Delete</MoreMenuItem>
					</>
				</MoreMenu>
			)}

			<FullscreenModalNavLink
				className='opportunity-card-title truncate-text'
				title={`Name: ${name}${
					opportunity.company ? '\nCompany:' + getEntityDisplayName(opportunity.company) : ''
				}\nPrimary Contact: ${getEntityDisplayName(opportunity.primaryContact)}`}
				to={redirection}
			>
				<span className='truncate-text opportunity-card-title-content'>{name}</span>
			</FullscreenModalNavLink>
			{!!showStageIndicator && !!opportunity.boardStage && (
				<div className='opportunity-card-stage-indicator-container'>
					<StageIndicator
						className='opportunity-card-stage-indicator'
						currentIndex={opportunity.boardStage.boardIndex}
						hideFutureStages={true}
						total={opportunity.boardStage.boardTotalStageCount}
						type='compact'
					/>
					<span className='opportunity-card-stage-indicator-name'>{opportunity.boardStage.name}</span>
				</div>
			)}
			<div>
				{opportunity.primaryContact ? (
					<EntityChip
						sizeConstraint='compact'
						className={css(styleSheet.contact)}
						key={opportunity.primaryContact.id}
						entity={contactVm}
						readOnly={true}
						onClick={(e: React.MouseEvent<HTMLDivElement>) => onEntityClicked(opportunity.primaryContact.id, e)}
					/>
				) : null}

				<div>
					<span className='opportunity-card-field-label'>{`Close date: `}</span>
					<span className='opportunity-card-field-text'>
						{opportunity.closeDate ? getDefaultDateStringValue(opportunity.closeDate) : '-'}
					</span>
				</div>
				<div>
					<span className='opportunity-card-field-label'>{`Owner: `}</span>
					<span className='opportunity-card-field-text'>{getEntityDisplayName(opportunityOwner)}</span>
				</div>
			</div>
			<div className='opportunity-card-amount'>{`$${numberToCurrencyStringValue(opportunity.dealSize)}`}</div>
		</div>
	);
};

export const OpportunityCard = observer(_OpportunityCard);
