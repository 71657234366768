import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateIntegrationStatusQuery,
	useDisableIntegrationMutation,
	useIntegrationStatusQuery,
} from '../../../../queries';
import { IntegrationOAuthStep, RedtailSignInViewModel } from '../../../../viewmodels/AppViewModels';
import redtailLogo from '../../../assets/logo_redtail.png';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { DangerIcon } from '../../../components/svgs/icons/DangerIcon';
import { InProgressIcon } from '../../../components/svgs/icons/InProgressIcon';
import { bs } from '../../../styles/styles';
import { getOAuthStepFromIntegrationStatus } from '../hooks/useManageIntegrationConnection';
import { styleSheet as integrationsStyleSheet } from '../styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

function _Redtail({ className, styles }: IProps) {
	const userSession = useUserSession();
	const { logEvent } = useEventLogging('RedtailIntegrations');
	const errorMessages = useErrorMessages();
	const [signInVM] = React.useState(new RedtailSignInViewModel(userSession));
	const toaster = useToaster();
	const history = useHistory();
	const [focusedField, setFocusedField] = React.useState<'username' | 'password' | null>(null);
	const [signingIn, setSigningIn] = React.useState(false);
	const usernameInput = React.useRef<HTMLInputElement>(null);
	const passwordInput = React.useRef<HTMLInputElement>(null);

	const disableIntegrationMutation = useDisableIntegrationMutation({
		onError: error => {
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			toaster.push({
				message: 'Integration disabled successfully',
				type: 'successMessage',
			});
			invalidateIntegrationStatusQuery(Api.ConfigurableIntegrationType.Redtail);
			history.push('/integrations');
		},
	});

	const [integrationStatusQueryEnabled, setIntegrationStatusQueryEnabled] = React.useState(
		!userSession.pendingActions.includes(Api.PendingActions.ConnectRedtail)
	);

	const integrationStatusQuery = useIntegrationStatusQuery({
		enabled: integrationStatusQueryEnabled,
		provider: Api.ConfigurableIntegrationType.Redtail,
	});

	const oauthStep: IntegrationOAuthStep | null = integrationStatusQuery.data
		? getOAuthStepFromIntegrationStatus(integrationStatusQuery.data)
		: null;

	const connect = async () => {
		setSigningIn(true);
		try {
			await signInVM.submit();
			logEvent('RedtailSignIn', {});
			setIntegrationStatusQueryEnabled(true);
		} catch (error) {
			errorMessages.pushApiError(error);
			logEvent('RedtailSignInError', {
				validationErrorMessage: signInVM.validationErrorMessage,
			});
		} finally {
			setSigningIn(false);
		}
	};

	const onDisable = () => {
		disableIntegrationMutation.mutate(Api.ConfigurableIntegrationType.Redtail);
	};

	if ((integrationStatusQueryEnabled && integrationStatusQuery.status !== 'success') || signingIn) {
		return <LoadingSpinner type='large' className={css(bs.absoluteCenter)} />;
	}

	if (userSession.isBusy) {
		return <LoadingSpinner type='large' className={css(bs.absoluteCenter)} />;
	}

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} integrations-container ${className || ''}`}>
			<div className={css(integrationsStyleSheet.logo, integrationsStyleSheet.headerLogo)}>
				<img src={redtailLogo} alt='Redtail Logo' />
			</div>
			<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
				Levitate can sync with Redtail, and pull client information, including names, email, phone number, birthdays,
				anniversaries, and more. We can also push our notes into Redtail.
			</p>

			{(oauthStep === IntegrationOAuthStep.SignIn ||
				userSession.pendingActions.includes(Api.PendingActions.ConnectRedtail)) && (
				<div>
					<p className={css(integrationsStyleSheet.bodyText)}>To get started, please sign into your Redtail account.</p>
					<div className={css(integrationsStyleSheet.signInContainer)}>
						<figure className={css(integrationsStyleSheet.logo, styleSheet.logoCenter)}>
							<img src={redtailLogo} alt='Redtail Logo' />
						</figure>
						{signInVM.isBusy || signInVM.isLoading ? (
							<LoadingSpinner type='large' className={css(bs.absoluteCenter)} />
						) : (
							<>
								{signInVM.getErrorMessage('global') && (
									<div className={css(styleSheet.signInFormError)}>{signInVM.getErrorMessage('global')}</div>
								)}
								<div>
									{/* Username Field */}
									<div className={css(styleSheet.inputWrapper)}>
										<span className={css(styleSheet.signInFormFieldLabel)}>Username</span>
										<div
											className={css(
												styleSheet.signInFormField,
												(focusedField === 'username' || signInVM.username) && styleSheet.editingSignInFormField
											)}
											onClick={() => usernameInput.current?.focus()}
										>
											<input
												autoComplete='off'
												autoFocus={true}
												id='login-username'
												onBlur={() => setFocusedField(null)}
												onChange={ev => (signInVM.username = ev.target.value)}
												onFocus={() => setFocusedField('username')}
												ref={usernameInput}
												type='text'
												value={signInVM.username || ''}
											/>
											{signInVM.hasErrorMessage('username') && <DangerIcon backgroundColor='#fff' />}
										</div>
										<Popover open={signInVM.hasErrorMessage('username')}>
											<PopoverTrigger asChild>
												<div />
											</PopoverTrigger>
											<PopoverContent side='right'>
												<span>{signInVM.getErrorMessage('username')}</span>
											</PopoverContent>
										</Popover>
									</div>

									{/* Password Field */}
									<div className={css(styleSheet.inputWrapper)}>
										<span className={css(styleSheet.signInFormFieldLabel)}>Password</span>
										<div
											className={css(
												styleSheet.signInFormField,
												(focusedField === 'password' || signInVM.password) && styleSheet.editingSignInFormField
											)}
											onClick={() => passwordInput.current?.focus()}
										>
											<input
												autoComplete='password'
												id='login-password'
												onBlur={() => setFocusedField(null)}
												onChange={ev => (signInVM.password = ev.target.value)}
												onFocus={() => setFocusedField('password')}
												onKeyDown={ev => {
													if (ev.keyCode === 13) {
														connect();
													}
												}}
												ref={passwordInput}
												type='password'
												value={signInVM.password || ''}
											/>
											{signInVM.hasErrorMessage('password') && <DangerIcon backgroundColor='#fff' />}
										</div>
										<Popover open={signInVM.hasErrorMessage('password')}>
											<PopoverTrigger asChild>
												<div />
											</PopoverTrigger>
											<PopoverContent side='right'>
												<span>{signInVM.getErrorMessage('password')}</span>
											</PopoverContent>
										</Popover>
									</div>
								</div>
								<div>
									<button className={css(bs.ctaButton, styleSheet.connectCta)} onClick={connect}>
										Connect
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			)}

			{oauthStep === IntegrationOAuthStep.InProgress && (
				<div className={css(styleSheet.inProgressContainer)}>
					<div className={css(styleSheet.inProgressIconContainer)}>
						<InProgressIcon />
					</div>
					<p className={css(styleSheet.inProgressText)}>
						Sync in progress... this could take a while. Feel free to navigate away from this page.
					</p>
					<div>
						<Link to='/people' className={css(bs.ctaButton, styleSheet.ctaButton)}>
							Go to Contacts
						</Link>
					</div>
				</div>
			)}

			{oauthStep === IntegrationOAuthStep.Connected && (
				<div className={css(styleSheet.synced)}>
					<p>
						We&apos;ve synced your data. Your Redtail data will now show up in the{' '}
						<Link to='/people' className={css(styleSheet.link)}>
							Contacts
						</Link>{' '}
						section.
					</p>
					<p>
						If you wish to disable this integration for you user,{' '}
						<span onClick={onDisable} className={css(styleSheet.link)}>
							click here to disable.
						</span>
					</p>
				</div>
			)}
		</div>
	);
}

const Redtail = observer(_Redtail);
export { Redtail };
