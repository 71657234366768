import {
	ActivationStatus,
	ContactConnectionsViewModel,
	ContactViewModel,
	IContactConnection,
	IOperationResultNoValue,
} from '@ViewModels';
import { css } from 'aphrodite';
import { Lambda, observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import EmptyContactsPlaceholderUrl from '../../../assets/emptyContactsPlaceholder.svg';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedPopover, PopoverType } from '../../DeprecatedPopover';
import { MoreMenu, MoreMenuItem } from '../../MoreMenu';
import { Placeholder } from '../../Placeholder';
import { PrincipalListItem } from '../../PrincipalListItem';
import { QuestionMarkIcon } from '../../svgs/icons/QuestionMarkIcon';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { EditContactConnectionModal } from '../EditContactConnection';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IErrorMessageComponentProps,
		IEventLoggingComponentProps,
		IUserSessionComponentProps {
	className?: string;
	contact: ContactViewModel;
	onConnectionRemoved?(connection: IContactConnection): void;
}

interface IState {
	connectionToEdit?: IContactConnection;
	showingTooltip?: boolean;
}

class _ContactConnections extends React.Component<IProps, IState> {
	private mConnectionsKeyPathObserverDisposer: Lambda;

	private mMounted: boolean;
	public state: IState = {};

	public componentDidMount() {
		this.mMounted = true;
		this.loadConnectionsWithProps(this.props);
	}

	public componentWillUnmount() {
		this.mMounted = false;
		this.clearConnectionsViewModelObserver();
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		this.loadConnectionsWithProps(nextProps);
	}

	public render() {
		const { contact, className } = this.props;
		const { connectionToEdit } = this.state;
		const { connections: connectionsVM } = contact;
		const loading = !!connectionsVM && !!connectionsVM.fetchingConnections;
		const isEmpty = !connectionsVM || (!!connectionsVM.connections && connectionsVM.connections.length === 0);
		return (
			<div className={`${css(styleSheet.container)} contact-connections ${className || ''}`}>
				{!isEmpty && !loading && (
					<div className={`${css(styleSheet.header)} truncate-text`}>
						<span>{`Your co-workers who also know `}</span>
						<span className={`${css(styleSheet.headerName)} truncate-text`}>{contact.name}</span>
					</div>
				)}
				{this.renderConnectionRows()}
				<Placeholder
					isEmpty={isEmpty}
					isLoading={loading}
					message='Does not have any known connections.'
					placeholderUrl={EmptyContactsPlaceholderUrl}
				/>
				<EditContactConnectionModal
					contact={contact}
					initialConnection={connectionToEdit}
					modalProps={{
						isOpen: !!connectionToEdit,

						onRequestClose: this.toggleEditContactConnection(null),
					}}
				/>
			</div>
		);
	}

	private renderConnectionRows() {
		const { contact } = this.props;
		const { connections } = contact;
		const markedForKeepInTouchText = this.renderMarkedForKeepInTouchText();

		if (!(connections?.connections?.length > 0)) {
			return;
		}

		const userConnections = connections.connections.filter(user => {
			if (!user.user) {
				return false;
			}
			return user.user.activationStatus !== ActivationStatus.DEACTIVATED;
		});

		return (
			<ul className={css(styleSheet.list)}>
				<li
					className={css(styleSheet.listItem, baseStyleSheet.brandLink, styleSheet.addConnectionItem)}
					onClick={this.toggleEditContactConnection({})}
				>
					<SvgIcon height={55} width={60}>
						<defs>
							<linearGradient id='b-a' x1='50%' x2='50%' y1='100%' y2='0%'>
								<stop offset='0%' stopColor='gray' stopOpacity='.25' />
								<stop offset='54%' stopColor='gray' stopOpacity='.12' />
								<stop offset='100%' stopColor='gray' stopOpacity='.1' />
							</linearGradient>
							<linearGradient id='b-b' x1='-.035%' x2='99.965%' y1='49.985%' y2='49.985%'>
								<stop offset='0%' stopColor='gray' stopOpacity='.25' />
								<stop offset='54%' stopColor='gray' stopOpacity='.12' />
								<stop offset='100%' stopColor='gray' stopOpacity='.1' />
							</linearGradient>
							<linearGradient id='b-c' x1='50%' x2='50%' y1='100%' y2='-.013%'>
								<stop offset='0%' stopColor='gray' stopOpacity='.25' />
								<stop offset='54%' stopColor='gray' stopOpacity='.12' />
								<stop offset='100%' stopColor='gray' stopOpacity='.1' />
							</linearGradient>
						</defs>
						<g fill='none'>
							<circle cx='27.553' cy='27.395' r='27.39' fill='url(#b-a)' opacity='.5' />
							<circle cx='27.553' cy='27.395' r='25.637' fill='#F5F5F5' />
							<path
								fill='url(#b-b)'
								d='M16.0069721,14.0129482 L15.5139442,13.8376494 L15.4876494,13.8814741 L15.4591633,13.8814741 L15.4591633,12.5448207 C15.4593943,12.3834024 15.4469389,12.2222156 15.4219124,12.062749 C16.2175653,11.4770335 16.8264479,10.6733085 17.1749004,9.74880478 C19.085826,7.2707428 18.7828204,3.74253365 16.4773567,1.62657877 C14.171893,-0.48937611 10.6306966,-0.48937611 8.32523292,1.62657877 C6.01976923,3.74253365 5.71676363,7.2707428 7.62768924,9.74880478 C7.97614178,10.6733085 8.58502437,11.4770335 9.38067729,12.062749 C9.35565071,12.2222156 9.34319536,12.3834024 9.34342629,12.5448207 L9.34342629,14.0085657 C3.92119954,15.5024442 0.166232817,20.4361081 0.170916335,26.0603586 L0.170911958,37.0165339 L25.1510038,37.0165339 L25.150996,26.0603586 C25.1572453,20.4457825 21.4163295,15.517061 16.0069721,14.0129482 Z'
								opacity='.5'
								transform='translate(15.12 7.231)'
							/>
							<path
								fill='#00528C'
								d='M27.7717131,20.9723108 C30.9610735,20.9723108 34.019806,22.2392792 36.2750243,24.4944976 C38.5302427,26.7497159 39.7972112,29.8084484 39.7972112,32.9978088 L39.7972112,43.5507968 L15.7462151,43.5507968 L15.7462151,32.9978088 C15.7462151,26.3563096 21.130214,20.9723108 27.7717131,20.9723108 Z'
							/>
							<rect width='5.89' height='7.363' x='24.581' y='17.048' fill='#FDA57D' rx='2.945' />
							<polygon fill='#000' points='22.539 22.276 24.513 26.51 26.477 24.546 24.513 21.54' opacity='.05' />
							<path
								fill='#FFF'
								d='M22.6069721,22.0701195 L24.5790837,26.3035857 L26.5511952,24.3314741 L24.5790837,21.3250996 L23.6762948,21.6011952 C23.5882256,21.6280448 23.5018766,21.6602427 23.4177291,21.6976096 L22.6069721,22.0701195 Z'
							/>
							<polygon fill='#000' points='32.522 22.07 30.539 26.51 28.576 24.546 30.594 21.38' opacity='.05' />
							<path
								fill='#FFF'
								d='M32.4543825,21.8641434 L30.4822709,26.3057769 L28.5101594,24.3336653 L30.5282869,21.1673307 L30.8986056,21.27251 C31.2978128,21.3871507 31.6893655,21.5269387 32.0709163,21.6910359 L32.4543825,21.8641434 Z'
							/>
							<polygon fill='#FDA57D' points='26.398 24.185 27.496 25.786 28.655 24.185' />
							<path
								fill='#000'
								d='M20.2667331 29.9870518C20.2667331 29.9870518 19.0966135 41.62251 19.8525896 42.3806773M34.724502 29.9870518C34.724502 29.9870518 35.8946215 41.62251 35.1386454 42.3806773'
								opacity='.1'
							/>
							<path
								fill='#000'
								d='M27.5262948,20.6195219 C28.5703916,20.6224209 29.5879405,20.2906615 30.4296813,19.6729084 C30.2014316,18.2440067 28.9689292,17.1926181 27.5219124,17.1924303 C26.0765551,17.194769 24.8465122,18.2456434 24.6185259,19.6729084 C25.4614729,20.2915531 26.480701,20.6233591 27.5262948,20.6195219 Z'
								opacity='.05'
							/>
							<circle cx='27.526' cy='13.757' r='5.802' fill='#000' />
							<circle cx='27.526' cy='15.575' r='4.908' fill='#FDA57D' />
							<path
								fill='#000'
								d='M23.5711155,10.7085657 L24.8113546,12.2227092 C24.8113546,12.2227092 30.25,12.7047809 30.8701195,11.4645418 C31.490239,10.2243028 27.2896414,9.33027888 27.2896414,9.33027888 L23.5711155,10.7085657 Z'
								opacity='.05'
							/>
							<path
								fill='#000'
								d='M23.5711155,10.5705179 L24.8113546,12.0846614 C24.8113546,12.0846614 30.25,12.5667331 30.8701195,11.326494 C31.490239,10.086255 27.2896414,9.19223108 27.2896414,9.19223108 L23.5711155,10.5705179 Z'
							/>
							<ellipse cx='22.607' cy='15.253' fill='#FDA57D' rx='1' ry='1' />
							<ellipse cx='32.452' cy='15.253' fill='#FDA57D' rx='1' ry='1' />
							<polygon
								fill='url(#b-c)'
								points='17.692 6.813 10.884 6.813 10.884 .007 6.97 .007 6.97 6.813 .162 6.813 .162 10.728 6.97 10.728 6.97 17.534 10.884 17.534 10.884 10.728 17.692 10.728'
								opacity='.5'
								transform='translate(42.29 5.916)'
							/>
							<rect width='3.326' height='14.9' x='49.555' y='7.235' fill='#00AAE8' />
							<polygon
								fill='#00AAE8'
								points='49.555 7.238 52.881 7.238 52.881 22.138 49.555 22.138'
								transform='rotate(90 51.218 14.688)'
							/>
						</g>
					</SvgIcon>
					<div>Add a connection</div>
				</li>
				{userConnections.map((connection, i) => {
					return (
						<PrincipalListItem
							className={css(styleSheet.listItem)}
							key={connection.user.id || i}
							principal={connection.user}
							rightAccessory={this.onRenderItemRightAccessory(connection)}
						>
							{!!connection.userHasKeepInTouch && <span>{markedForKeepInTouchText}</span>}
							{connection.notes ? (
								<div className={css(baseStyleSheet.truncateText, styleSheet.connectionNotes)}>
									<span className={css(baseStyleSheet.truncateText)} title={connection.notes}>
										{connection.notes}
									</span>
									{!!connection.user && contact.ownerId === connection.user.id && (
										<>
											&nbsp; &nbsp; &nbsp;
											<span
												className={css(baseStyleSheet.brandLink, styleSheet.editNotesButton)}
												onClick={this.toggleEditContactConnection(connection)}
											>
												Edit
											</span>
										</>
									)}
								</div>
							) : (
								<button onClick={this.toggleEditContactConnection(connection)}>
									<span className={css(baseStyleSheet.brandLink)}>Add details about this connection</span>
								</button>
							)}
						</PrincipalListItem>
					);
				})}
			</ul>
		);
	}

	private onRenderItemRightAccessory = (connection: IContactConnection) => {
		const { contact } = this.props;
		const { showingTooltip } = this.state;
		if (connection.user) {
			const connectionTypesListDisplay = connection.connectionTypes
				? connection.connectionTypes.sort((a, b) => a.localeCompare(b))
				: null;
			const connectionTypesList = connectionTypesListDisplay ? (
				<span className={css(styleSheet.contactOwner)}>
					<span>&nbsp;{connectionTypesListDisplay.join(', ')}</span>
				</span>
			) : null;

			if (!!contact.ownerId && contact.ownerId === connection.user.id) {
				const anchor = (
					<QuestionMarkIcon
						className={css(styleSheet.tooltipPopoverAnchor)}
						onMouseEnter={this.toggleShowingTooltip(true)}
						onMouseLeave={this.toggleShowingTooltip(false)}
					/>
				);
				return (
					<span className={css(styleSheet.contactOwner)}>
						<span>Contact Owner</span>
						<DeprecatedPopover
							anchor={anchor}
							className={css(styleSheet.tooltipPopover)}
							dismissOnClickOutside={true}
							isOpen={showingTooltip}
							onRequestClose={this.onRequestTooltipPopoverClose}
							place='below'
							preferredPlacement='right'
							type={PopoverType.white}
						>
							<p className={css(styleSheet.tooltipPopoverContent)}>
								The contact owner is typically the person who created this contact, or is in charge of maintaining
								relationship with this contact.
							</p>
						</DeprecatedPopover>
						{connectionTypesList ? ',' : ''}
						{connectionTypesList}
					</span>
				);
			}

			if (connection.connectionTypes) {
				return connectionTypesList;
			}

			return (
				<MoreMenu menuButtonClassName={css(styleSheet.moreMenu)}>
					<MoreMenuItem onClick={() => this.onMenuItemClicked(connection)('assignAsContactOwner')}>
						Assign as Contact Owner
					</MoreMenuItem>
					<MoreMenuItem onClick={() => this.onMenuItemClicked(connection)('edit')}>Edit</MoreMenuItem>
				</MoreMenu>
			);
		}
	};

	private renderMarkedForKeepInTouchText() {
		const { contact } = this.props;
		return contact.firstName ? `Marked ${contact.firstName} for Keep in Touch` : 'Marked for Keep in Touch';
	}

	private toggleEditContactConnection = (connectionToEdit?: IContactConnection) => () => {
		this.setState({
			connectionToEdit,
		});
	};

	private onMenuItemClicked = (connection: IContactConnection) => (menuItem: string) => {
		const { contact, logEvent, errorMessages, logInput } = this.props;
		switch (menuItem) {
			case 'assignAsContactOwner': {
				const promise = contact.setOwner(connection.user);
				if (promise) {
					logInput('AssignContactOwner', 'Click', {
						contactId: contact.id,

						userId: connection.user.id,
					});
					promise.catch((error: IOperationResultNoValue) => {
						logEvent('AssignContactOwner-Error', error);

						errorMessages.pushApiError(error);
					});
				}
				break;
			}
			case 'edit': {
				this.toggleEditContactConnection(connection)();
				break;
			}
			default: {
				break;
			}
		}
	};

	private toggleShowingTooltip = (show: boolean) => () => {
		if (this.mMounted) {
			this.setState({
				showingTooltip: show,
			});
		}
	};

	private onRequestTooltipPopoverClose = () => {
		if (this.mMounted) {
			this.setState({
				showingTooltip: false,
			});
		}
	};

	private clearConnectionsViewModelObserver = () => {
		if (this.mConnectionsKeyPathObserverDisposer) {
			this.mConnectionsKeyPathObserverDisposer();

			this.mConnectionsKeyPathObserverDisposer = null;
		}
	};

	private loadConnectionsWithProps = (nextProps: IProps) => {
		if (nextProps === this.props || nextProps.contact !== this.props.contact) {
			this.clearConnectionsViewModelObserver();
			this.mConnectionsKeyPathObserverDisposer = observe(nextProps.contact, 'connections', change => {
				// user the new vm to reload the connections
				this.loadConnections(change.newValue);
			});
			this.loadConnections(nextProps.contact.connections);
		}
	};

	private loadConnections = (connections: ContactConnectionsViewModel, nextProps = this.props) => {
		if (connections) {
			const promise = connections.getConnections();
			const { logEvent, logApiError } = nextProps;
			if (promise) {
				logEvent('ConnectionsLoad');
				promise.catch((error: IOperationResultNoValue) => {
					logApiError('ConnectionsLoad-Error', error);
				});
			}
		}
	};
}

const ContactConnectionsAsObserver = observer(_ContactConnections);
const ContactConnectionsWithContext = inject(
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(ContactConnectionsAsObserver);
export const ContactConnections = withEventLogging(ContactConnectionsWithContext, 'ContactConnections');
