import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IMoveBoardItem, IOpportunity } from '../../../../extViewmodels/sdk/models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../../models/LocalNotifications';
import { useFullscreenModal, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateAllGetBoardItems,
	invalidateArchivedOpportunities,
	useAutomationTemplateIdQuery,
	useExportOpportunitiesMutation,
	useInfiniteBoardsQuery,
	useRestoreArchivedOpportunity,
	useStartAutomationForContactMutation,
} from '../../../../queries';
import { Button } from '../../../components/Button';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { ExportConfirmationModalV2 } from '../../../components/ExportConfirmation';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Modal } from '../../../components/Modal';
import { OpportunityKanbanBoard } from '../../../components/boards/OpportunityKanbanBoard';
import { EditOpportunityModal } from '../../../components/dataBoards/opportunities/EditOpportunity';
import {
	OpportunitiesBoardHeader,
	OpportunitiesBoardLayoutType,
} from '../../../components/dataBoards/opportunities/OpportunitiesBoardHeader';
import { OpportunitiesTable } from '../../../components/dataBoards/opportunities/OpportunitiesTable';
import { UpdateStageModal } from '../../../components/dataBoards/opportunities/UpdateStageModal';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { OpportunitiesContextProvider, useOpportunitiesContext } from './context';
import { styleSheet } from './styles';

const SearchFilterResetWarningOptions: IConfirmationDialogOption<string>[] = [
	{
		isCta: true,
		title: 'Continue',
	},
	{
		isCancel: true,
		title: 'Cancel',
	},
];

const OpportunitiesContentBody = ({
	boardId,
	setBreadcrumbLocationName,
}: {
	boardId: string;
	setBreadcrumbLocationName: React.Dispatch<React.SetStateAction<string>>;
}) => {
	const userSession = useUserSession();
	const toaster = useToaster();

	const [layoutType, setLayoutType] = React.useState<OpportunitiesBoardLayoutType>('cards');
	const [showingExportModal, setShowingExportModal] = React.useState(false);
	const [stagesWithAutomations, setStagesWithAutomations] = React.useState([]);

	const [showingSearchFilterResetWarning, setShowingSearchFilterResetWarning] = React.useState(false);
	const [targetStage, setTargetStage] = React.useState<Api.IBoardStage>(null);

	const [triggeringOpportunity, setTriggeringOpportunity] = React.useState<Api.IOpportunity>(null);
	const [pendingAutomationTemplateId, setPendingAutomationTemplateId] = React.useState<string>(null);

	const getAutomationTemplateByIdQuery = useAutomationTemplateIdQuery({
		enabled: !!pendingAutomationTemplateId,
		id: pendingAutomationTemplateId,
	});
	const startAutomationForContactMutation = useStartAutomationForContactMutation({
		onSuccess: () => {
			toaster.push({
				message: `Automation "${automationTemplate.name}" started for ${Api.VmUtils.getDisplayName(
					triggeringOpportunity.primaryContact
				)}`,
				type: 'successMessage',
			});
			setTriggeringOpportunity(null);
			setPendingAutomationTemplateId(null);
		},
		onError: () => {
			toaster.push({
				message: `Failed to start automation`,
				type: 'errorMessage',
			});
		},
	});
	const automationTemplate = getAutomationTemplateByIdQuery.data ?? null;

	const startAutomation = () => {
		if (!automationTemplate) return;

		startAutomationForContactMutation.mutate({
			template: automationTemplate,
			contactId: triggeringOpportunity.primaryContact.id,
			start: true,
		});
	};

	const ignoreAutomation = () => {
		setTriggeringOpportunity(null);
		setPendingAutomationTemplateId(null);
	};

	const {
		setSearchRequest,
		board,
		moveItemToStage,
		setBoardId,
		opportunityToMove,
		setOpportunityToMove,
		opportunityToEdit,
		setOpportunityToEdit,
		searchRequest,
		isSearching,
	} = useOpportunitiesContext();

	React.useEffect(() => {
		setBoardId(boardId);
	}, [setBoardId, boardId]);

	const { postNotification } = useLocalNotificationService();
	const fullScreenModal = useFullscreenModal();

	const restoreArchiveMutation = useRestoreArchivedOpportunity({
		onSuccess: () => {
			invalidateArchivedOpportunities();
			invalidateAllGetBoardItems();
			setOpportunityToMove(null);
		},
	});

	const boardsQuery = useInfiniteBoardsQuery({
		type: 'Opportunity',
	});
	const boards = boardsQuery.data?.pages.map(page => page.values).flat() ?? [];
	const isLoading = boardsQuery.isLoading;

	const onImportButtonClicked = () => {
		fullScreenModal.history.push(`/dataBoards/opportunities/${board.id}/import`);
	};

	const onAddCardClicked = (stage: Api.IBoardStage, force: boolean) => {
		if (!!showingSearchResultsInStages && !force) {
			setShowingSearchFilterResetWarning(true);
			setTargetStage(stage);

			return;
		}
		setTargetStage(stage);

		stage.board = board;

		const newOpportunity: Api.IOpportunity = {
			boardStage: stage,
		};
		setOpportunityToEdit(newOpportunity);
	};
	const exportOpportunitiesMutation = useExportOpportunitiesMutation({});
	const onExportButtonClicked = async (newSearchRequest: Api.IOpportunitySearchRequest, includeActivity: boolean) => {
		await exportOpportunitiesMutation.mutateAsync({
			filterRequest: newSearchRequest,
			includeActivity,
			boardId,
		});
		setShowingExportModal(true);
	};

	const onExportModalRequestClose = () => {
		setShowingExportModal(false);
	};

	const showingSearchResultsInStages = !!board && !!board.stages && isSearching;

	const onLayoutTypeChanged = (newLayoutType: OpportunitiesBoardLayoutType) => {
		setLayoutType(newLayoutType);
	};

	const onSearchRequestChanged = (newSearchRequest: Api.IOpportunitySearchRequest) => {
		setSearchRequest(newSearchRequest);
	};

	React.useEffect(() => {
		if (!board) return;

		board.stages.forEach(stage => {
			new Api.TagViewModel(userSession, {
				tag: `${board.name}: ${stage.name}`,
			})
				.load()
				.then(accountTag => {
					if (accountTag?.automationTemplateId) {
						setStagesWithAutomations(prevValue => [...prevValue, accountTag]);
					}
				});
		});
	}, [board, userSession]);

	const checkAutomationsAndMoveItem = async (opportunity: Api.IOpportunity, moveBoardItem: IMoveBoardItem) => {
		const stageName = board.stages.find(x => x.id === moveBoardItem.stageId)?.name;
		const toStageName = `${board.name}: ${stageName}`;
		const pendingAutomationForStage = stagesWithAutomations.find(
			x => x.tag === toStageName && !!x.automationTemplateId
		);

		await moveItemToStage({
			opportunityId: opportunity.id,
			moveBoardItem,
		});

		setTriggeringOpportunity(opportunity);
		setPendingAutomationTemplateId(pendingAutomationForStage?.automationTemplateId);
	};

	const onSearchFilterResetWarningRequestClose = (
		selectedOption: IConfirmationDialogOption<string>,
		cancel?: boolean
	) => {
		setShowingSearchFilterResetWarning(false);

		if (!!cancel || !!selectedOption.isCancel || !targetStage || !board) {
			setTargetStage(null);
			setOpportunityToEdit(null);
		} else {
			onAddCardClicked(targetStage, true);
		}
	};

	const onOpportunitySave = (opportunity: IOpportunity) => {
		setOpportunityToEdit(null);
		setTargetStage(null);

		postNotification({
			info: opportunity,
			topic: Topics.CREATE_BOARD_ITEM,
		});
	};

	React.useEffect(() => {
		if (!board) return;
		setBreadcrumbLocationName(board.name);

		const matchingStages = board.stages.filter(x => x.id === 'blah');

		setStagesWithAutomations(matchingStages);
	}, [board, setBreadcrumbLocationName]);

	const onClickToMove = (selectedItem: Api.IOpportunity, selectedStage: Api.IBoardStage) => {
		const moveBoardItem: IMoveBoardItem = {
			stageId: selectedStage.id,
			fallbackIndex: 0,
		};

		moveOpportunity(selectedItem, moveBoardItem);
	};

	const moveOpportunity = async (opportunity: Api.IOpportunity, moveBoardItem: IMoveBoardItem) => {
		const toStage = board.stages.find(x => x.id === moveBoardItem.stageId);

		if (opportunity.isArchived) {
			restoreArchiveMutation.mutate({ itemId: opportunity.id, stageId: toStage.id });
		} else if (toStage) {
			await checkAutomationsAndMoveItem(opportunity, moveBoardItem);
		}
	};

	const onCardDrop = async (opportunity: Api.IOpportunity, moveBoardItem: IMoveBoardItem) => {
		await moveOpportunity(opportunity, moveBoardItem);
	};

	const onColumnDrop = (sourceIndex: number, targetIndex: number) => {
		// TODO: Implement column drop
		console.log('Column dropped:', { sourceIndex, targetIndex });
	};

	if (!board) return null;

	return (
		<>
			<OpportunitiesBoardHeader
				board={board}
				onLayoutTypeChanged={onLayoutTypeChanged}
				onSearchRequestChanged={onSearchRequestChanged}
				onExportButtonClicked={onExportButtonClicked}
				onImportButtonClicked={onImportButtonClicked}
				isSearching={isSearching}
			/>

			<div className={css(styleSheet.boardContainer)}>
				{board ? (
					<React.Fragment>
						<>
							{layoutType === 'cards' ? (
								<OpportunityKanbanBoard
									onAddCardClicked={onAddCardClicked}
									onCardDrop={onCardDrop}
									onColumnDrop={onColumnDrop}
								/>
							) : (
								<OpportunitiesTable board={board} opportunityFilterCriteria={searchRequest} />
							)}
						</>
						{opportunityToEdit ? (
							<EditOpportunityModal
								opportunity={opportunityToEdit}
								onSave={onOpportunitySave}
								onCancel={() => setOpportunityToEdit(null)}
								boards={boards}
								initialStage={opportunityToEdit.boardStage}
								initialBoard={board}
							/>
						) : null}
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: !!showingSearchFilterResetWarning,
								onRequestClose: onSearchFilterResetWarningRequestClose,
							}}
							options={SearchFilterResetWarningOptions}
							title='Reset Search'
						>
							<span>Adding an opportunity will reset your search query.</span>
						</ConfirmationDialog>
					</React.Fragment>
				) : (
					<React.Fragment>
						{isLoading ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' /> : null}
					</React.Fragment>
				)}
			</div>

			{showingExportModal && (
				<ExportConfirmationModalV2
					mutationResult={exportOpportunitiesMutation}
					modalProps={{
						isOpen: true,
						onRequestClose: onExportModalRequestClose,
					}}
					subTitle={
						<>
							<div>The fields we&apos;ll export include: Name, Company, Contact,</div>
							<div>Email, Stage, Owner, Deal Size, Details, Close Date,</div>
							<div>Creation Date and Last Modfied Date.</div>
						</>
					}
				/>
			)}

			{opportunityToMove ? (
				<UpdateStageModal
					isOpen
					stages={board.stages}
					item={opportunityToMove}
					onSave={onClickToMove}
					onRequestClose={() => setOpportunityToMove(null)}
				/>
			) : null}

			{userSession?.account?.features?.automation?.enabled && (
				<Modal isOpen={!!pendingAutomationTemplateId} onRequestClose={ignoreAutomation} useDefaultHeader={true}>
					<div className={css(styleSheet.automationPopover)}>
						<div className={`automation-popover-message ${css(styleSheet.automationPopoverMessage)}`}>
							This tag can trigger an automation flow:
						</div>
						{getAutomationTemplateByIdQuery.isSuccess ? (
							<>
								<div className={css(styleSheet.automationPopoverName)}>{`${automationTemplate?.name || ''}`}</div>
								<Button
									kind='primary'
									size='small'
									className={css(styleSheet.automationStartButton)}
									onClick={startAutomation}
								>
									<span>Start Automation</span>
								</Button>
							</>
						) : (
							<LoadingSpinner type='tiny' />
						)}
					</div>
				</Modal>
			)}
		</>
	);
};

const _OpportunitiesContent = ({
	boardId,
	setBreadcrumbLocationName,
}: {
	boardId: string;
	setBreadcrumbLocationName: React.Dispatch<React.SetStateAction<string>>;
}) => {
	return (
		<OpportunitiesContextProvider>
			<OpportunitiesContentBody boardId={boardId} setBreadcrumbLocationName={setBreadcrumbLocationName} />
		</OpportunitiesContextProvider>
	);
};

export const OpportunitiesContent = observer(_OpportunitiesContent);
