import { css } from 'aphrodite';
import * as React from 'react';
import { MultiContainerHeader } from '../MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../NavigationBreadcrumbsBar';
import { styleSheet } from './styles';

interface DataboardHeaderProps {
	title: string;
	pathComponentNameProvider?: () => string;
	children?: React.ReactNode;
}

export const DataboardHeader: React.FC<DataboardHeaderProps> = ({ title, pathComponentNameProvider, children }) => {
	return (
		<MultiContainerHeader
			appBarHeader={
				<div className={css(styleSheet.breadcrumbsBar)}>
					<NavigationBreadcrumbsBar currentLocationName={title} pathComponentNameProvider={pathComponentNameProvider} />
					{children}
				</div>
			}
		/>
	);
};
