import * as Api from '@ViewModels';
import { IField } from '../../../QuickAddEntity';

export const verifyIsNotAlreadyAdded = (
	recipient: Api.TextRecipientViewModel | Api.IPhoneNumber,
	recipients: Api.TextRecipientViewModel[],
	onAlreadyAdded: () => void
): boolean => {
	let alreadyAdded = false;
	if (recipient instanceof Api.TextRecipientViewModel) {
		if (recipient.phoneNumbers?.length === 1 || recipient.selectedPhoneNumber) {
			alreadyAdded = !!recipients.find(r => {
				if (r) {
					if (r.selectedPhoneNumber) {
						if (recipient.selectedPhoneNumber) {
							return (
								(r.selectedPhoneNumber.metadata &&
									recipient.selectedPhoneNumber.metadata &&
									r.selectedPhoneNumber.metadata.standard === recipient.selectedPhoneNumber.metadata.standard) ||
								(r.selectedPhoneNumber.value &&
									recipient.selectedPhoneNumber.value &&
									r.selectedPhoneNumber.value === recipient.selectedPhoneNumber.value)
							);
						}

						if (recipient.phoneNumbers.length === 1) {
							return (
								(r.selectedPhoneNumber.metadata &&
									recipient.phoneNumbers[0].metadata &&
									r.selectedPhoneNumber.metadata.standard === recipient.phoneNumbers[0].metadata.standard) ||
								(r.selectedPhoneNumber.value &&
									recipient.phoneNumbers[0].value &&
									r.selectedPhoneNumber.value === recipient.phoneNumbers[0].value)
							);
						}
					}

					if (r.phoneNumbers?.length === 1) {
						if (recipient.selectedPhoneNumber) {
							return (
								(r.phoneNumbers[0].metadata &&
									recipient.selectedPhoneNumber.metadata &&
									r.phoneNumbers[0].metadata.standard === recipient.selectedPhoneNumber.metadata.standard) ||
								(r.phoneNumbers[0].value &&
									recipient.selectedPhoneNumber.value &&
									r.phoneNumbers[0].value === recipient.selectedPhoneNumber.value)
							);
						}

						if (recipient.phoneNumbers.length === 1) {
							return (
								(r.phoneNumbers[0].metadata &&
									recipient.phoneNumbers[0].metadata &&
									r.phoneNumbers[0].metadata.standard === recipient.phoneNumbers[0].metadata.standard) ||
								(r.phoneNumbers[0].value &&
									recipient.phoneNumbers[0].value &&
									r.phoneNumbers[0].value === recipient.phoneNumbers[0].value)
							);
						}
					}
				} else {
					if (recipient.selectedPhoneNumber) {
						return (
							(r.metadata &&
								recipient.selectedPhoneNumber.metadata &&
								r.metadata.standard === recipient.selectedPhoneNumber.metadata.standard) ||
							(r.value && recipient.selectedPhoneNumber.value && r.value === recipient.selectedPhoneNumber.value)
						);
					}

					if (recipient.phoneNumbers.length === 1) {
						return (
							(r.metadata &&
								recipient.phoneNumbers[0].metadata &&
								r.metadata?.standard === recipient.phoneNumbers[0].metadata?.standard) ||
							(r.value && recipient.phoneNumbers[0].value && r.value === recipient.phoneNumbers[0].value)
						);
					}
				}
			});
		}
	} else {
		alreadyAdded = !!recipients.find(r => {
			if (r) {
				if (r.selectedPhoneNumber) {
					return (
						(r.selectedPhoneNumber.metadata &&
							recipient.metadata &&
							r.selectedPhoneNumber.metadata.standard === recipient.metadata.standard) ||
						(r.selectedPhoneNumber.value && recipient.value && r.selectedPhoneNumber.value === recipient.value)
					);
				}

				if (r.phoneNumbers?.length === 1) {
					return (
						(r.phoneNumbers[0].metadata &&
							recipient.metadata &&
							r.phoneNumbers[0].metadata.standard === recipient.metadata.standard) ||
						(r.phoneNumbers[0].value && recipient.value && r.phoneNumbers[0].value === recipient.value)
					);
				}
			} else {
				return (
					(r.metadata && recipient.metadata && r.metadata.standard === recipient.metadata.standard) ||
					(r.value && recipient.value && r.value === recipient.value)
				);
			}
		});
	}

	if (alreadyAdded) {
		onAlreadyAdded?.();
	}

	return alreadyAdded;
};

export const getContactFields = (quickAddEntityVM: Api.QuickAddEntityViewModel): IField[] => [
	{
		autoFocus: false,
		labelText: 'First Name',
		optional: false,
		propertyName: 'firstName',
		type: 'text',
	},
	{
		labelText: 'Last Name',
		optional: false,
		propertyName: 'lastName',
		type: 'text',
	},
	{
		defaultValue: quickAddEntityVM?.phoneNumbers?.find(p => p.label === 'Mobile')?.value,
		labelText: 'Mobile Phone',
		optional: true,
		propertyName: 'mobilePhone',
		type: 'text',
	},
	{
		labelText: 'Email',
		optional: true,
		propertyName: 'emailAddress',
		type: 'email',
	},
];
