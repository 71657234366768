import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { useMemo } from 'react';
import FlipMove from 'react-flip-move';
import { useHistory } from 'react-router-dom';
import Waypoint from 'react-waypoint';
import { useDebounceValue } from '../../../hooks/useDebounceValue';
import { ILocationState } from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import { useGetNewsletterTemplatesByKeyword, useTemplatesByType } from '../../../queries';
import {
	invalidateGetTemplatesByIndustryQuery,
	useGetTemplatesByIndustry,
} from '../../../queries/Templates/useGetTemplatesByIndustry';
import { ITemplate, Industry, TemplateType, asApiError } from '../../../viewmodels/AppViewModels';
import { HtmlNewsletterViewModel } from '../../../viewmodels/HtmlNewletterViewModels';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { HtmlNewsletterTemplateCard } from '../../components/templateCards/HtmlNewsletterTemplateCard';
import { CreateHtmlNewsletterTemplateCard, HtmlNewsletterTemplatesHeader } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	industry?: Industry;
	styles?: StyleDeclarationValue[];
}

export const HtmlNewsletterTemplates: React.FC<IProps> = props => {
	const { className, styles, industry } = props;
	const history = useHistory();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const logger = useEventLogging('HtmlNewsletterTemplates');
	const [isCreatingNewTemplate, setIsCreatingNewTemplate] = React.useState(false);
	const [searchValue, setSearchValue] = React.useState<string>('');

	const templates = useTemplatesByType({
		impersonationContext: userSession.impersonationContext,
		includeNonUserTemplates: true,
		type: TemplateType.HtmlNewsletter,
	});
	const templatesMemo = useMemo(() => templates?.data?.pages.flatMap(page => page?.values), [templates?.data?.pages]);

	const getNext = () => templates.fetchNextPage();

	const industryTemplates = useGetTemplatesByIndustry({
		impersonationContext: userSession.impersonationContext,
		industry,
		types: [TemplateType.HtmlNewsletter],
		onError: error => {
			logger.logApiError('GetIndustryTemplates-Error', error);
		},
	});

	const onCreateNewClicked = React.useCallback(async () => {
		logger.logInput('CreateNewTemplate', 'Click');
		try {
			setIsCreatingNewTemplate(true);
			const template = await HtmlNewsletterViewModel.createDefaultBlankTemplate(userSession, {
				name: 'Untitled Newsletter',
			});
			const locationState: ILocationState<any, ITemplate> = { model: template };
			setIsCreatingNewTemplate(false);
			history?.push({
				pathname: '/campaigns/htmlnewsletter/new',
				state: locationState,
			});
		} catch (err) {
			const error = asApiError(err);
			logger.logApiError('CreateTemplate-Error', error);
			errorMessages?.pushApiError(error);
			setIsCreatingNewTemplate(false);
		}
		invalidateGetTemplatesByIndustryQuery({
			impersonationContext: userSession.impersonationContext,
			industry,
			types: [TemplateType.HtmlNewsletter],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const debouncedKeywordsSearchValue = useDebounceValue(searchValue, 500);
	const filteredNewslettersQuery = useGetNewsletterTemplatesByKeyword({
		enabled: !!debouncedKeywordsSearchValue,
		industry,
		impersonationContext: userSession.impersonationContext,
		onError: error => {
			logger.logApiError('FilterNewsletterByKeyword-Error', error);
		},
		search: debouncedKeywordsSearchValue,
	});

	let gridView: 'showCards' | 'showFilteredCards' | 'showLoading' = 'showLoading';
	if (!searchValue && !templates.isLoading && templates && !!industryTemplates && !industryTemplates.isLoading) {
		gridView = 'showCards';
	} else if (!!searchValue && !filteredNewslettersQuery.isLoading) {
		gridView = 'showFilteredCards';
	}

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} html-newsletter-templates ${className || ''}`}>
			<HtmlNewsletterTemplatesHeader keywordSearch={searchValue} onKeywordChange={setSearchValue} />
			<div className={css(styleSheet.templatesContainer)}>
				<FlipMove
					appearAnimation={false}
					className={css(styleSheet.templates)}
					enterAnimation={true}
					leaveAnimation={true}
					staggerDelayBy={50}
					staggerDurationBy={10}
					typeName='ul'
				>
					<li key='create'>
						<CreateHtmlNewsletterTemplateCard
							disabled={isCreatingNewTemplate || templates.isFetching}
							onCreateNewClicked={onCreateNewClicked}
						/>
					</li>
					{gridView === 'showCards' &&
						industryTemplates?.data?.map(template => {
							return (
								<li key={template.id}>
									<HtmlNewsletterTemplateCard industry={industry} key={template.id} template={template} />
								</li>
							);
						})}
					{gridView === 'showCards' &&
						templatesMemo.map(template => {
							return (
								<li key={template.id}>
									<HtmlNewsletterTemplateCard industry={industry} key={template.id} template={template} />
								</li>
							);
						})}
					{gridView === 'showFilteredCards' &&
						filteredNewslettersQuery?.data?.map(template => (
							<li key={template.id}>
								<HtmlNewsletterTemplateCard industry={industry} key={template.id} template={template} />
							</li>
						))}
					{gridView === 'showLoading' ? (
						<li className={css(styleSheet.loadingContainer)} key='loading'>
							<LoadingSpinner type='large' />
						</li>
					) : (
						<>
							{templates.hasNextPage && !templates.isFetchingNextPage && (
								<li key='waypoint'>
									<Waypoint bottomOffset='-200px' onEnter={getNext} />
								</li>
							)}
						</>
					)}
				</FlipMove>
			</div>
		</div>
	);
};
