import { IModalContext, ModalChildComponentContextKey } from '@AppModels/.';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../../models/AppState';
import { EventLogger } from '../../../../models/Logging';
import { BoardStageViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { asModalComponent } from '../../Modal';
import { ISelectBoxOption, SelectBox } from '../../SelectBox';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import './styles.less';

interface IProps extends IModalContext<BoardStageViewModel>, IErrorMessageComponentProps {
	className?: string;
	options: BoardStageViewModel[];
	stage: BoardStageViewModel;
}

interface IState {
	selectedStage?: BoardStageViewModel;
}

class _DeleteStageConfirmation extends React.Component<IProps, IState> {
	public state: IState = {};

	@computed
	private get options() {
		const { options } = this.props;
		if (options) {
			return options.map(x => {
				const option: ISelectBoxOption<BoardStageViewModel> = {
					title: x.name,
					value: x,
				};
				return option;
			});
		}
		return [];
	}

	public render() {
		const { className } = this.props;
		const { selectedStage } = this.state;
		return (
			<div className={`kanban-delete-column-confirmation ${className || ''}`}>
				<div className='kanban-delete-column-confirmation-header'>
					<DeprecatedCloseButton onClick={this.onCancel} />
				</div>
				<div className='kanban-delete-column-confirmation-body'>
					<div className='kanban-delete-column-confirmation-body-info'>
						<WarningIcon className='kanban-delete-column-confirmation-body-info-icon' />
						<div className='kanban-delete-column-confirmation-title'>Delete Column</div>
						<div className='kanban-delete-column-confirmation-body-info-text'>
							Please move the opportunities that are in this column to another column before deleting.
						</div>
						<SelectBox
							className='kanban-delete-column-confirmation-column-selector'
							onSelectionChanged={this.onSelectionChange}
							options={this.options}
							placeholder='Select a column...'
							selectedOption={selectedStage ? this.options.find(x => x.value === selectedStage) : undefined}
						/>
					</div>
				</div>
				<div className='kanban-delete-column-confirmation-footer'>
					<button
						className={css(baseStyleSheet.ctaButtonDestructive)}
						disabled={!selectedStage}
						onClick={this.onDeleteButtonClicked}
					>
						<span>Move & Delete</span>
					</button>
				</div>
			</div>
		);
	}

	private onSelectionChange = (option: ISelectBoxOption<BoardStageViewModel>) => {
		this.setState({
			selectedStage: option.value,
		});
	};

	private onCancel = () => {
		if (this.props.parentModal) {
			this.props.parentModal.onRequestClose(null, true);
		}
	};

	private onDeleteButtonClicked = () => {
		const { parentModal } = this.props;
		const { selectedStage } = this.state;

		EventLogger.logInput('DeleteStageConfirmation', 'DeleteAndMove', 'Click', {
			fromStageId: this.props.stage.id,
			itemCount: (this.props.stage.items || []).length,

			toStageId: selectedStage.id,
		});

		if (parentModal) {
			parentModal.onRequestClose(selectedStage, false);
		}
	};
}

const DeleteStageConfirmationAsObserver = observer(_DeleteStageConfirmation);
export const DeleteStageConfirmation = inject(
	ErrorMessagesViewModelKey,
	ModalChildComponentContextKey
)(DeleteStageConfirmationAsObserver);
export const DeleteStageConfirmationModal = asModalComponent(DeleteStageConfirmation);
