import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	ConfigurableIntegrationType,
	ConfigurableIntegrationUserViewModel,
	ConfigurableIntegrationViewModel,
	IOperationResultNoValue,
	UserViewModel,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ClientIntegrationsManageUsersTableRow } from '../../../../admin/components/integrations/ClientIntegrationsManageUsersTableRow';
import {
	FullScreenModalViewModelKey,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import Ams360IconUrl from '../../../assets/ams360.png';
import HawksoftIconUrl from '../../../assets/hawksoft_logo.png';
import { baseStyleSheet } from '../../../styles/styles';
import { FabContext } from '../../FabContext';
import { LoadingSpinner } from '../../LoadingSpinner';
import { MultiContainerHeader } from '../../MultiContainerHeader';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IUserSessionComponentProps,
		IFullscreenModalComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	isShowingAddEmployeeModal?: boolean;
}

class _IntegrationOwnerships extends React.Component<IProps, IState> {
	@observable.ref private integration: ConfigurableIntegrationViewModel;
	public constructor(props: IProps) {
		super(props);
		const accountIntegrations = props.userSession?.account?.toJs().integrations;
		const ams = accountIntegrations?.ams360;
		const hawk = accountIntegrations?.hawkSoft;
		if (ams?.enabled && ams?.configured && ams?.invalidOwnerships?.length) {
			this.integration = new ConfigurableIntegrationViewModel(
				props.userSession,
				props.userSession?.account?.toJs(),
				ConfigurableIntegrationType.Ams360
			);
		} else if (hawk?.enabled && hawk?.configured && hawk?.invalidOwnerships?.length) {
			this.integration = new ConfigurableIntegrationViewModel(
				props.userSession,
				props.userSession?.account?.toJs(),
				ConfigurableIntegrationType.HawkSoft
			);
		}
	}

	public async componentDidMount() {
		await this.integration.load();
	}

	public render() {
		const { className, styles } = this.props;

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} integration-ownerships ${className || ''}`}>
				<MultiContainerHeader fullscreenHeader='Map Levitate User for Initials' />
				<FabContext appearance={{ hidden: true }} />
				{!this.integration
					? this.renderNone()
					: this.integration.isLoading
						? this.renderLoading()
						: this.renderUserMapper()}
				{!this.integration.isLoading && (
					<div className={css(styleSheet.buttonContainer)}>
						<button className={css(baseStyleSheet.ctaButton)} onClick={this.onDismiss}>
							<span>Go to Levitate Dashboard</span>
						</button>
					</div>
				)}
			</div>
		);
	}

	private renderLoading() {
		return (
			<div className={css(styleSheet.loader)}>
				<LoadingSpinner type='large' />
			</div>
		);
	}

	private renderNone() {
		return (
			<div>
				<div className={css(styleSheet.instructionsText)}>All owners have been setup as Levitate users.</div>
			</div>
		);
	}

	private renderUserMapper() {
		return (
			<div>
				<img className={css(styleSheet.icon)} src={this.getIcon()} />
				<div className={css(styleSheet.instructionsText)}>
					We found some initials from your agency management system that have not been set up as Levitate users.
					<br />
					Please map a Levitate user for each of the initials listed.
				</div>
				<table className={css(styleSheet.tableContainer)}>
					<thead className={css(styleSheet.tableHeader)}>
						<tr className={css(styleSheet.tableHeaderRow)}>
							<th className={css(styleSheet.tableHeaderCell, styleSheet.tableHeaderInitialsHeader)}>Initials</th>
							<th className={css(styleSheet.tableHeaderCell, styleSheet.tableHeaderEmailHeader)}>Email</th>
							<th className={css(styleSheet.tableHeaderCell, styleSheet.tableHeaderStatusIndicatorHeader)} />
							<th className={css(styleSheet.tableHeaderCell, styleSheet.tableHeaderStatusHeader)}>Status</th>
						</tr>
					</thead>
					<tbody>
						{this.integration.invalidOwnerUsers.map(x => {
							return (
								<ClientIntegrationsManageUsersTableRow
									integration={this.integration}
									key={x.id}
									onAssignUser={this.assignUser}
									user={x}
								/>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}

	private assignUser = (user: ConfigurableIntegrationUserViewModel, levitateUser?: UserViewModel) => {
		const { logEvent, logApiError } = this.props;
		const userMap = this.integration.getUserMap();
		const mapping = userMap.find(x => x.integrationId === user.id);
		if (mapping) {
			mapping.levitateUser = levitateUser ? { id: levitateUser.id } : null;
			this.integration
				.setUserMap(userMap)
				?.then(() => {
					user.levitateUser = levitateUser;
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('AssignMapping-Error', error);
				})
				?.finally(() => {
					logEvent('AssignMapping', mapping);
				});
		}
	};

	private getIcon() {
		switch (this.integration.type) {
			case ConfigurableIntegrationType.Ams360:
				return Ams360IconUrl;
			case ConfigurableIntegrationType.HawkSoft:
				return HawksoftIconUrl;
			default:
				return null;
		}
	}

	private onDismiss = () => {
		const { fullscreenModal } = this.props;

		fullscreenModal.dismissModal();
	};
}

const IntegrationOwnershipsAsObserver = observer(_IntegrationOwnerships);
const IntegrationOwnershipsWithContext = inject(
	UserSessionViewModelKey,
	FullScreenModalViewModelKey
)(IntegrationOwnershipsAsObserver);
export const IntegrationOwnerships = withEventLogging(IntegrationOwnershipsWithContext, 'IntegrationOwnerships');
