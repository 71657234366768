import * as Api from '@ViewModels';
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import { IEventRegistrationSurvey } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { IEnvironmentComponentProps } from '../../../models/AppState';
import { useEventLogging } from '../../../models/Logging';
import { copyToClipboard, getDefaultDateStringValue } from '../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	invalidateInfiniteSurveys,
	useEventRegistrationMutation,
	useGetSurveyById,
	useImageAttachmentMutation,
	useUpdateEventRegistrationSurveyCustomFormMutation,
	useUpdateEventSurveyMutation,
} from '../../../queries';
import { useUpdateEventRegistrationSurveyCustomFormFieldOptionsMutation } from '../../../queries/Surveys/useUpdateEventRegistrationSurveyCustomFormFieldOptionsMutation';
import { IFileAttachment } from '../../../view/models/Api';
import CalendarIconUrl from '../../assets/icon_calendar.svg';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from '../../components/Breadcrumb';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { CustomDateRange } from '../../components/CustomDateRange';
import { DayPicker } from '../../components/DayPicker';
import { DeprecatedSelect, ISelectOption } from '../../components/DeprecatedSelect';
import { FabContext } from '../../components/FabContext';
import { ImageFileChooserModal } from '../../components/ImageFileChooserModal';
import { MediaChooserModal } from '../../components/MediaChooser';
import { IModalProps } from '../../components/Modal';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { Popover, PopoverContent, PopoverTrigger } from '../../components/Popover';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { TimezonePicker } from '../../components/TimezonePicker';
import { Toggle } from '../../components/Toggle';
import { CameraIcon } from '../../components/svgs/icons/CameraIcon';
import { EyeIcon } from '../../components/svgs/icons/EyeIcon';
import { SquareLinkIcon } from '../../components/svgs/icons/SquareLinkIcon';
import { alternateTitleColor, grayIconFill, success } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { AdditionalQuestions, ImagePreviewSmall } from './presentation';
import { styleSheet } from './styles';
import { EVENT_TIME_OPTIONS } from './utils';

interface IProps extends IEnvironmentComponentProps, IImpersonationContextComponentProps {
	className?: string;
}

interface IState {
	enableRequestAttendeesToRSVP: boolean;
	fileInputModalOpen: boolean;
	now: Date;
	selectedImage: IFileAttachment | null;
	customFormIsDisabled: boolean;
}

const validationSchema = yup.object({
	details: yup.string().required('Event details are required'),
	startDate: yup.string().required('Event date is required'),
	startTime: yup.string().required('Event time is required'),
	endDate: yup.string(),
	endTime: yup.string(),
	eventName: yup.string().required('Event name is required'),
	guestLimit: yup
		.number()
		.typeError('Guest limit must be a number')
		.when('setGuestLimit', {
			is: true,
			then: schema => {
				return schema.min(1).required('Guest limit is required');
			},
		}),
	location: yup.string().required('Event location is required'),
	maximumCapacity: yup
		.number()
		.min(1)
		.typeError('Maximum capacity must be a number')
		.when('requireMaximumCapacity', {
			is: true,
			then: schema => {
				return schema.required('Maximum capacity is required');
			},
		}),
	registrationDeadline: yup
		.date()
		.nullable()
		.typeError('Deadline date is required')
		.when('requireDeadline', {
			is: true,
			then: schema => {
				return schema.required('Deadline date is required');
			},
		}),
	requireDeadline: yup.boolean(),
	requireMaximumCapacity: yup.boolean(),
	setGuestLimit: yup.boolean(),
	requirePhoneNumber: yup.boolean(),
	customForm: yup.object({
		name: yup.string(),
		fields: yup.array().of(
			yup.object({
				label: yup.string().required('Question label is required'),
				id: yup.string(),
				isOptional: yup.boolean(),
				isHidden: yup.boolean(),
				fieldType: yup.mixed<Api.FormFieldType>().oneOf(Object.values(Api.FormFieldType)),
				options: yup
					.array()
					.of(yup.object({ label: yup.string(), fieldId: yup.string() }))
					.test('options-length-test', function (val) {
						// get parent values
						const { fieldType } = this.parent;
						if (
							val?.every(x => Boolean(x?.label)) &&
							val?.length >= 2 &&
							(fieldType === Api.FormFieldType.Option || fieldType === Api.FormFieldType.MultipleOptions)
						) {
							return true;
						}
						if (fieldType === Api.FormFieldType.String) {
							return true;
						}
						return this.createError({ message: 'This field and its options are required' });
					}),
			})
		),
	}),
});

function isValidEventDate(
	dateValues: Pick<yup.InferType<typeof validationSchema>, 'startDate' | 'startTime' | 'endDate' | 'endTime'>
) {
	const { startDate, startTime, endDate, endTime } = dateValues;
	if (!startDate || !startTime) {
		return false;
	}
	if (endDate && endDate !== startDate && (!startTime || !endTime)) {
		return false;
	}
	if (
		(endDate === startDate || !endDate) &&
		endTime &&
		moment(startTime, 'HH:mm:ss').isSameOrAfter(moment(endTime, 'HH:mm:ss'))
	) {
		return false;
	}
	return true;
}

const EventRegistrationSurveyBase = ({ className, impersonationContext }: IProps) => {
	const userSession = useUserSession();
	const [state, setState] = React.useState<IState>({
		enableRequestAttendeesToRSVP: false,
		fileInputModalOpen: false,
		now: new Date(),
		selectedImage: null,
		customFormIsDisabled: false,
	});
	const history = useHistory();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('EventRegistrationSurvey');
	const params = useParams<{ id?: string }>();
	const eventId = params?.id;
	const surveyQuery = useGetSurveyById<Api.IEventRegistrationSurvey>({
		impersonationContext,
		enabled: Boolean(eventId),
		surveyId: eventId,
	});
	const eventData = surveyQuery.data;

	const isEdit = Boolean(eventId);
	const pastEvent = React.useMemo(() => {
		const dueDateString = eventData?.eventInformation?.startTime;
		return dueDateString ? moment(dueDateString).isBefore(new Date(), 'day') : false;
	}, [eventData]);

	const methods = useForm({
		defaultValues: {
			details: '',
			startDate: undefined,
			startTime: undefined,
			endDate: undefined,
			endTime: undefined,
			eventName: '',
			guestLimit: 1,
			location: '',
			maximumCapacity: 1,
			registrationDeadline: null,
			requireDeadline: false,
			requireMaximumCapacity: false,
			setGuestLimit: false,
			requirePhoneNumber: false,
			customForm: {
				name: '',
				fields: [] as Api.IFormField<string>[],
			},
			timezone: userSession.user.userPreferences?.timeZone || moment.tz.guess(),
		},
		resolver: yupResolver(validationSchema),
	});
	const { handleSubmit, control, formState, getValues, watch, setValue, reset } = methods;
	const formValues = watch();
	const hasImages = Boolean(state.selectedImage);

	const eventRegistrationSurveyCustomFormMutation = useUpdateEventRegistrationSurveyCustomFormMutation({
		onError: error => {
			errorMessages.pushApiError(error);
		},
		onSuccess: async val => {
			// PUT options
			const values = getValues();
			const fieldsWithOptions = values.customForm.fields.filter(field => field.options?.length > 0);
			if (fieldsWithOptions.length) {
				await updateEventRegistrationSurveyCustomFormFieldOptionsMutation.mutateAsync({
					impersonationContext,
					eventId: val.id,
					fields: fieldsWithOptions,
				});
				history.replace(`/survey/event/edit/${val.id}`);
				return;
			}
			invalidateInfiniteSurveys();
			history.replace(`/survey/event/edit/${val.id}`);
		},
	});

	const updateEventRegistrationSurveyCustomFormFieldOptionsMutation =
		useUpdateEventRegistrationSurveyCustomFormFieldOptionsMutation({
			onError: error => {
				errorMessages.pushApiError(error);
			},
			onSuccess: ([_, errors]) => {
				if (errors?.length > 0) {
					errorMessages.pushApiError(errors[0]);
					return;
				}
				invalidateInfiniteSurveys();
			},
		});

	let endTimeOptions = EVENT_TIME_OPTIONS;
	if (formValues.startDate != null && formValues.startDate === formValues.endDate && formValues.startTime) {
		const startTimeIndex = EVENT_TIME_OPTIONS.findIndex(o => o.dataContext === formValues.startTime);
		endTimeOptions = EVENT_TIME_OPTIONS.slice(startTimeIndex + 1);
	}

	React.useEffect(() => {
		if (eventData) {
			const image: Api.IFileAttachment = eventData.eventInformation?.image || null;
			const rsvpEnabled = eventData.attendeeOptions?.enabled;
			const startMoment = moment.tz(eventData.eventInformation?.startTime, eventData.eventInformation?.timeZone);
			const startTime = startMoment.format('HH:mm:ss');
			const startDate = startMoment.format('YYYY/MM/DD');
			setValue('startDate', startDate);
			setValue('startTime', startTime);
			/**
			 * @IF the event has an end time, we will use it
			 * we can check for form value because it was set in the default values or its null
			 */
			if (eventData?.eventInformation?.endTime) {
				const endMoment = moment.tz(eventData.eventInformation.endTime, eventData.eventInformation?.timeZone);
				const endDate = endMoment.format('YYYY/MM/DD');
				const endTime = endMoment.format('HH:mm:ss');
				setValue('endDate', endDate);
				setValue('endTime', endTime);
			}

			setValue('guestLimit', eventData?.attendeeOptions?.guestLimit || 1);
			setValue('details', eventData?.eventInformation?.details || '');
			setValue('eventName', eventData.name || '');
			setValue('location', eventData?.eventInformation?.location || '');
			setValue('maximumCapacity', eventData?.attendeeOptions?.maximumCapacity || 1);
			setValue(
				'registrationDeadline',
				eventData?.attendeeOptions?.registrationDeadline
					? new Date(eventData.attendeeOptions?.registrationDeadline)
					: null
			);
			setValue('requireDeadline', Boolean(eventData?.attendeeOptions?.registrationDeadline));
			setValue('requireMaximumCapacity', Boolean(eventData?.attendeeOptions?.maximumCapacity > 1));
			setValue('setGuestLimit', Boolean(eventData?.attendeeOptions?.guestLimit));
			setValue('requirePhoneNumber', eventData?.attendeeOptions?.requirePhoneNumber || false);
			setValue('customForm.fields', eventData?.customForm?.fields?.length > 0 ? eventData?.customForm.fields : []);
			if (eventData.eventInformation?.timeZone) {
				setValue('timezone', eventData.eventInformation.timeZone);
			}

			setState(prevState => ({
				...prevState,
				enableRequestAttendeesToRSVP: rsvpEnabled,
				selectedImage: image,
				customFormIsDisabled: eventData?.customFormIsDisabled || false,
			}));
		}
	}, [eventData, setValue]);

	const eventRegistrationMutation = useEventRegistrationMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			toaster.push({
				message: 'Failed to create event registration survey',
				type: 'errorMessage',
			});
			logApiError('EventRegistrationSurvey-Error', error);
		},
		onSuccess: async (val: IEventRegistrationSurvey) => {
			toaster.push({
				message: 'Event registration survey created',
				type: 'successMessage',
			});
			const values = getValues();
			const filterEmptyFields = values.customForm.fields.filter(field => field.label.trim().length > 0);
			if (filterEmptyFields.length) {
				eventRegistrationSurveyCustomFormMutation.mutate({
					id: val.id,
					request: {
						customForm: {
							name: val.name,
							fields: values.customForm.fields,
						},
					},
				});

				return;
			}
			history.replace(`/survey/event/edit/${val.id}`);
			invalidateInfiniteSurveys();
		},
	});

	const updateSurveyEventMutation = useUpdateEventSurveyMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			toaster.push({
				message: 'Failed to update the event',
				type: 'errorMessage',
			});
			logApiError('EventRegistrationSurvey-Error', error);
		},
		onSuccess: (val: IEventRegistrationSurvey) => {
			const values = getValues();
			const filterEmptyFields = values.customForm.fields.filter(field => field.label.trim().length > 0);
			if (filterEmptyFields.length) {
				eventRegistrationSurveyCustomFormMutation.mutate({
					id: val.id,
					request: {
						customForm: {
							name: val.name,
							fields: values.customForm.fields,
						},
					},
				});
				return;
			}
			toaster.push({
				message: 'Updates event success',
				type: 'successMessage',
			});
			invalidateInfiniteSurveys();
		},
	});

	const imageAttachmentMutation = useImageAttachmentMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			toaster.push({
				message: 'Failed to upload image',
				type: 'errorMessage',
			});
			logApiError('UploadImageEventRegistrationSurvey-Error', error);
		},
		onSuccess: result => {
			setState(prevState => ({
				...prevState,
				selectedImage: result[0],
			}));
		},
	});
	/**
	 * Pixabay selection setup
	 */
	const onFreeImageChooserRequestCloseRef = React.useRef<(img?: IFileAttachment, cancel?: boolean) => void>(null);
	const [freeImageModalProps, setFreeImageModalProps] = React.useState<IModalProps>({
		isOpen: false,
		onRequestClose: (result, cancel) => onFreeImageChooserRequestCloseRef.current?.(result, cancel),
	});

	const onFreeImageChooserRequestClose = React.useCallback((imageFile?: IFileAttachment, cancel?: boolean) => {
		setFreeImageModalProps(value => {
			return {
				...value,
				isOpen: false,
			};
		});
		if (cancel) {
			return;
		}
		setState(prevState => ({
			...prevState,
			selectedImage: imageFile,
		}));
	}, []);
	onFreeImageChooserRequestCloseRef.current = onFreeImageChooserRequestClose;
	/**
	 * End Pixabay selection setup
	 */

	const handleSave = () => {
		if (pastEvent) {
			toaster.push({
				message: 'Cannot edit a past event',
				type: 'errorMessage',
			});
			return;
		}

		const formData = getValues();

		let endTimeMoment = null;
		if (!isValidEventDate(formData)) {
			return;
		}
		if (formData.endTime && formData.endDate) {
			endTimeMoment = moment.tz(formData.endDate + ' ' + formData.endTime, 'YYYY/MM/DD HH:mm:ss', formData.timezone);
		} else if (formData.endTime) {
			endTimeMoment = moment.tz(formData.startDate + ' ' + formData.endTime, 'YYYY/MM/DD HH:mm:ss', formData.timezone);
		}
		const endTime = endTimeMoment ? endTimeMoment.toDate() : null;
		const startTime = moment
			.tz(formData.startDate + ' ' + formData.startTime, 'YYYY/MM/DD HH:mm:ss', formData.timezone)
			.toDate();
		const eventRegistration: IEventRegistrationSurvey = {
			_type: 'EventRegistrationSurvey',
			attendeeOptions: {
				enabled: state.enableRequestAttendeesToRSVP,
				guestLimit: state.enableRequestAttendeesToRSVP && formData.setGuestLimit ? formData.guestLimit : 0,
				maximumCapacity:
					state.enableRequestAttendeesToRSVP && formData.maximumCapacity > 1 ? formData.maximumCapacity : null,
				registrationDeadline: state.enableRequestAttendeesToRSVP
					? formData.registrationDeadline
						? new Date(formData.registrationDeadline)
						: null
					: null,
				requirePhoneNumber: state.enableRequestAttendeesToRSVP ? formData.requirePhoneNumber : false,
			},
			eventInformation: {
				details: formData.details,
				endTime,
				location: formData.location,
				startTime,
				timeZone: formData.timezone,
			},
			name: formData.eventName,
			customFormIsDisabled: state.customFormIsDisabled,
		};
		if (state.selectedImage) {
			eventRegistration.eventInformation.image = state.selectedImage;
		}

		const needsOptions = formData.customForm.fields?.some((field: Api.IFormField<string>) => {
			return (
				(field.fieldType === Api.FormFieldType.Option || field.fieldType === Api.FormFieldType.MultipleOptions) &&
				field.options?.length > 0 &&
				field.options.find(option => option.label.trim().length === 0)
			);
		});
		const needsFieldsLabel = formData.customForm.fields.some(
			field => field.label.trim().length === 0 && field.fieldType !== Api.FormFieldType.String
		);

		if (needsFieldsLabel) {
			errorMessages.push({
				messages: ['Please add a label to all fields before saving.'],
			});
			return;
		}

		if (needsOptions) {
			errorMessages.push({
				messages: ['Please add options to all fields that are of type "Checkbox" or "Dropdown" before saving.'],
			});
			toaster.push({
				message: 'Event not saved',
				type: 'errorMessage',
			});
			return;
		}

		if (isEdit) {
			const payload = { ...eventData, ...eventRegistration };
			updateSurveyEventMutation.mutate({ eventRegistration: payload, id: eventId });
			return;
		}
		eventRegistrationMutation.mutate({
			eventRegistration,
		});
	};

	const onToggleCheckChanged = () => {
		setState(prevState => ({
			...prevState,
			enableRequestAttendeesToRSVP: !prevState.enableRequestAttendeesToRSVP,
		}));
	};

	const onClickMedia = () => {
		setState(prevState => ({
			...prevState,
			fileInputModalOpen: true,
		}));
	};

	const onRequestfileInputClose = (val?: File | null, cancel?: boolean) => {
		if (cancel) {
			setState(prevState => ({
				...prevState,
				fileInputModalOpen: false,
			}));
			return;
		}
		imageAttachmentMutation.mutate({ files: [val] });
		setState(prevState => ({
			...prevState,
			fileInputModalOpen: false,
		}));
	};

	const onClickFreeImage = () => {
		setFreeImageModalProps(value => {
			return {
				...value,
				isOpen: true,
			};
		});
	};
	const onEventTimeChange = (val: ISelectOption<string>) => {
		const startTime = val.dataContext;
		setValue('startTime', startTime);
		if (
			formValues.startDate != null &&
			formValues.startDate === formValues.endDate &&
			moment(startTime, 'HH:mm:ss').isSameOrAfter(moment(formValues.endTime, 'HH:mm:ss'))
		) {
			setValue('endTime', undefined);
		}
	};

	const onEventTimeEndChange = (val: ISelectOption<string>) => {
		setValue('endTime', val.dataContext);
	};

	const onCopyLinkClicked = React.useCallback(() => {
		if (copyToClipboard(eventData?.anonymousLink)) {
			toaster.push({
				message: 'Link copied to clipboard',
				type: 'successMessage',
			});
			return;
		}

		toaster.push({
			message: 'Error copying link to clipboard',
			type: 'errorMessage',
		});
	}, [eventData?.anonymousLink, toaster]);

	const previewClicked = React.useCallback(() => {
		const url = `${eventData?.anonymousLink}`;
		window.open(url, '_blank');
	}, [eventData?.anonymousLink]);

	const selectedStartTime = EVENT_TIME_OPTIONS.find(x => x.dataContext === formValues.startTime);
	const selectedEndTime = EVENT_TIME_OPTIONS.find(x => x.dataContext === formValues.endTime);

	let eventDateTimeText = '';
	const isEventDateValid = isValidEventDate(formValues);
	if (isEventDateValid) {
		if (formValues.endDate && formValues.endTime && formValues.endDate !== formValues.startDate) {
			eventDateTimeText = `${moment.tz(formValues.startDate + ' ' + formValues.startTime, 'YYYY/MM/DD HH:mm:ss', formValues.timezone).format('MMM Do, YYYY hh:mm a')} - ${moment.tz(formValues.endDate + ' ' + formValues.endTime, 'YYYY/MM/DD HH:mm:ss', formValues.timezone).format('MMM Do, YYYY hh:mm a')} / ${moment.tz(formValues.timezone).format('z')}`;
		} else if (formValues.endTime) {
			eventDateTimeText = `${moment.tz(formValues.startDate + ' ' + formValues.startTime, 'YYYY/MM/DD HH:mm:ss', formValues.timezone).format('MMM Do, YYYY hh:mm a')} - ${moment(formValues.endTime, 'HH:mm:ss').format('hh:mm a')} / ${moment.tz(formValues.timezone).format('z')}`;
		} else {
			eventDateTimeText = `${moment.tz(formValues.startDate + ' ' + formValues.startTime, 'YYYY/MM/DD HH:mm:ss', formValues.timezone).format('MMM Do, YYYY hh:mm a')} / ${moment.tz(formValues.timezone).format('z')}`;
		}
	}

	let breadcrumbPageTitle = 'New Event';
	if (params.id) {
		breadcrumbPageTitle = surveyQuery.data?.name ?? 'Edit Event';
	}
	return (
		<div className={`${className ?? ''}`}>
			<MultiContainerHeader
				appBarHeader={
					<Breadcrumb>
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbLink asChild>
									<NavLink to='/surveys'>Surveys</NavLink>
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator />
							<BreadcrumbItem>
								<BreadcrumbPage>{breadcrumbPageTitle}</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				}
			/>
			<FabContext appearance={{ hidden: true }} />
			{pastEvent ? (
				<h2 className={css(styleSheet.notEditableNotice)}>This event has passed and its no longer editable</h2>
			) : null}
			<FormProvider {...methods}>
				<form className='form-container' onSubmit={handleSubmit(handleSave)}>
					<fieldset disabled={pastEvent} className={css(styleSheet.fieldset, styleSheet.formStyles)}>
						<header className={css(styleSheet.footer)}>
							{isEdit && (
								<Button kind='link' type='button' className={css(styleSheet.previewEvent)} onClick={previewClicked}>
									Preview event <EyeIcon />
								</Button>
							)}
							<Button
								type='submit'
								size='small'
								className={css(baseStyleSheet.ctaButtonSmall, styleSheet.submitButton)}
								disabled={pastEvent}
								isLoading={eventRegistrationMutation?.isLoading || updateSurveyEventMutation?.isLoading}
							>
								Save
							</Button>
							{isEdit && (
								<Button
									type='button'
									kind='reverse'
									size='small'
									className={css(styleSheet.copyLinkButton)}
									onClick={onCopyLinkClicked}
								>
									<SquareLinkIcon fillColor={alternateTitleColor} className={css(styleSheet.linkIcon)} /> Copy invite
									link
								</Button>
							)}
						</header>
						<div className={css(styleSheet.mainContainer)}>
							<section className={css(styleSheet.section)}>
								<h3 className={css(styleSheet.sectionTitle)}>Event Information</h3>
								<div className={css(styleSheet.formControlWrap)}>
									<label htmlFor='event-name-input' className={css(styleSheet.eventlabel)}>
										Event name
									</label>
									<Controller
										name='eventName'
										control={control}
										render={({ field: { ref, ...fieldProps } }) => (
											<TextInput id='eventName' inputId='event-name-input' type='text' inputRef={ref} {...fieldProps} />
										)}
									/>
									{formState.isSubmitted && formState.errors.eventName && (
										<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
											{formState.errors.eventName.message}
										</p>
									)}
								</div>
								<div className={css(styleSheet.formControlWrap)}>
									<label htmlFor='event-location-input' className={css(styleSheet.eventlabel)}>
										Event location
									</label>
									<Controller
										name='location'
										control={control}
										render={({ field: { ref, ...fieldProps } }) => (
											<TextArea id='eventLocation' inputId='event-location-input' inputRef={ref} {...fieldProps} />
										)}
									/>
									{formState.isSubmitted && formState.errors.location && (
										<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
											{formState.errors.location.message}
										</p>
									)}
								</div>
								<div className={css(styleSheet.formControlWrap)}>
									<div style={{ width: '94%' }}>
										<label htmlFor='event-date-input' className={css(styleSheet.eventlabel)}>
											Event Date & Time
										</label>
										<Popover>
											<PopoverTrigger asChild>
												<div className={css(styleSheet.eventDateInput)}>
													<button
														type='button'
														className={`endDate-date-field-button ${css(styleSheet.eventDateButton)}`}
													>
														<span className={css(styleSheet.selectedDate)}>{eventDateTimeText}</span>
													</button>
												</div>
											</PopoverTrigger>
											<PopoverContent side='right'>
												<CustomDateRange
													minDate={state.now}
													from={formValues.startDate ? new Date(formValues.startDate) : undefined}
													to={formValues.endDate ? new Date(formValues.endDate) : undefined}
													resetDates={() => {
														reset(values => ({
															...values,
															endDate: undefined,
															startDate: undefined,
															endTime: undefined,
															startTime: undefined,
														}));
													}}
													onChange={(from, to) => {
														reset(values => {
															const fromDateStr = moment(from).format('YYYY/MM/DD');
															return {
																...values,
																startDate: fromDateStr,
																endDate: to ? moment(to).format('YYYY/MM/DD') : fromDateStr,
																startTime: undefined,
																endTime: undefined,
															};
														});
													}}
													styles={[styleSheet.dateRangePickerWrapper]}
													datePickerStyles={[styleSheet.dateRangePicker]}
												/>
												<div className={css(styleSheet.selectTimeWrap)}>
													<div>
														<label className={css(styleSheet.selectDateLabel)}>Start Time</label>
														<DeprecatedSelect
															triggerStyles={[styleSheet.selectDate]}
															onOptionClick={onEventTimeChange}
															options={EVENT_TIME_OPTIONS}
															selectedOption={selectedStartTime}
															selectedOptionTitle={selectedStartTime?.text || 'Select time'}
															disabled={!formValues.startDate}
														/>
													</div>
													<div>
														<label className={css(styleSheet.selectDateLabel)}>End Time </label>
														<DeprecatedSelect
															triggerStyles={[styleSheet.selectDate]}
															onOptionClick={onEventTimeEndChange}
															options={endTimeOptions}
															selectedOption={selectedEndTime}
															selectedOptionTitle={selectedEndTime?.text || 'Select time'}
															disabled={!formValues.endDate}
														/>
													</div>
													<Controller
														name='timezone'
														control={control}
														render={({ field: { ref, ...fieldProps } }) => {
															return (
																<div>
																	<label className={css(styleSheet.selectDateLabel)}>Timezone</label>
																	<TimezonePicker
																		initialTimezoneValue={formValues.timezone}
																		onTimezoneSelected={tz => fieldProps.onChange(tz.name)}
																		inputStyles={[styleSheet.timezonePicker]}
																	/>
																</div>
															);
														}}
													/>
												</div>
											</PopoverContent>
										</Popover>
										{formState.isSubmitted && !isEventDateValid ? (
											<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
												Event date and time is required
											</p>
										) : null}
									</div>
								</div>
								<div className={css(styleSheet.formControlWrap)}>
									<label htmlFor='event-details-input' className={css(styleSheet.eventlabel)}>
										Event Details
									</label>
									<Controller
										name='details'
										control={control}
										render={({ field: { ref, ...fieldProps } }) => (
											<TextArea id='details' inputId='event-details-input' inputRef={ref} {...fieldProps} />
										)}
									/>
									{formState.isSubmitted && formState.errors.details && (
										<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
											{formState.errors.details.message}
										</p>
									)}
								</div>
								<div className={css(styleSheet.noMargin)}>
									<label htmlFor='event-image-input' className={css(styleSheet.eventlabel)}>
										Upload an event image (optional)
									</label>
									{!hasImages ? (
										<div className={css(styleSheet.addMediaContainer, styleSheet.contentContainers)}>
											<div>
												<button
													type='button'
													className={css(styleSheet.iconButtons)}
													disabled={hasImages}
													onClick={onClickFreeImage}
													title={hasImages ? 'Only one image is supported at this time' : undefined}
												>
													<CameraIcon />
													<div className={css(styleSheet.freeImage)}> Free Images</div>
												</button>
												<div className={css(styleSheet.sourceMention)}>Royalty free image library</div>
											</div>
											<div>
												<button
													type='button'
													className={css(styleSheet.ownImage)}
													disabled={hasImages}
													onClick={onClickMedia}
													title={hasImages ? 'Only one image is supported at this time' : undefined}
												>
													+ Upload Media
												</button>
												<div className={css(styleSheet.sourceMention)}>Choose file from your computer</div>
											</div>
										</div>
									) : (
										<div>
											{state?.selectedImage ? (
												<ImagePreviewSmall
													name={state?.selectedImage?.fileName}
													onRemoveClick={() => {
														setState(prevState => ({
															...prevState,
															selectedImage: null,
														}));
													}}
													url={state?.selectedImage?.url}
												/>
											) : null}
										</div>
									)}
								</div>
							</section>
							<section className={css(styleSheet.section)}>
								<h3 className={css(styleSheet.sectionTitle)}>Request Attendees to RSVP</h3>
								<div className={css(styleSheet.formControlWrap)}>
									<Toggle
										checkedColor={success}
										id='request-attendees-rsvp-toggle'
										isOn={state.enableRequestAttendeesToRSVP}
										onToggleCheckChanged={onToggleCheckChanged}
										text={state.enableRequestAttendeesToRSVP ? 'Enabled' : 'Disabled'}
										textStyles={[styleSheet.toggleText]}
										uncheckedColor={grayIconFill}
									/>
								</div>
								<fieldset disabled={!state.enableRequestAttendeesToRSVP} className={css(styleSheet.fieldset)}>
									<div className={css(styleSheet.formControlWrap)}>
										<Controller
											name='requirePhoneNumber'
											control={control}
											render={({ field: { ref, ...fieldProps } }) => (
												<Checkbox
													id='require-attendees-phone-number-checkbox'
													checked={fieldProps.value}
													name='requirePhoneNumber'
													onChange={() => {
														if (!state.enableRequestAttendeesToRSVP) {
															return;
														}
														fieldProps.onChange(!fieldProps.value);
													}}
												>
													<p className={css(styleSheet.noMargin)}>Require attendees to provide phone number</p>
												</Checkbox>
											)}
										/>
									</div>
									<div className={css(styleSheet.formControlWrap)}>
										<Controller
											name='setGuestLimit'
											control={control}
											render={({ field: { ref, ...fieldProps } }) => (
												<>
													<Checkbox
														id='set-guest-limit-checkbox'
														checked={fieldProps.value}
														name='setGuestLimit'
														onChange={() => {
															if (!state.enableRequestAttendeesToRSVP) {
																return;
															}
															const toggle = !fieldProps.value;
															if (toggle !== true) {
																setValue('guestLimit', formState.defaultValues.guestLimit);
															}
															fieldProps.onChange(toggle);
														}}
													>
														<p className={css(styleSheet.noMargin)}>Set maximum guests allowed</p>
													</Checkbox>
												</>
											)}
										/>
										{formValues.setGuestLimit ? (
											<div className={css(styleSheet.maximumCapasityInput)}>
												<Controller
													name='guestLimit'
													control={control}
													render={({ field: { ref, ...fieldProps } }) => (
														<TextInput
															id='guestLimit'
															inputId='guest-limit-input'
															inputRef={ref}
															min={1}
															type='number'
															onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																fieldProps.onChange(e.target.value);
															}}
															value={fieldProps.value}
														/>
													)}
												/>
												{formState.isSubmitted && formState.errors.guestLimit && (
													<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
														{formState.errors.guestLimit.message}
													</p>
												)}
											</div>
										) : null}
									</div>
									<div className={css(styleSheet.formControlWrap)}>
										<Controller
											name='requireMaximumCapacity'
											control={control}
											render={({ field: { ref, ...fieldProps } }) => (
												<>
													<Checkbox
														id='set-maximum-capacity-checkbox'
														checked={fieldProps.value}
														name='requireMaximumCapacity'
														onChange={() => {
															if (!state.enableRequestAttendeesToRSVP) {
																return;
															}
															const toggle = !fieldProps.value;
															if (toggle !== true) {
																setValue('maximumCapacity', formState.defaultValues.maximumCapacity);
															}
															fieldProps.onChange(toggle);
														}}
													>
														<p className={css(styleSheet.noMargin)}>Set maximum capacity</p>
													</Checkbox>
												</>
											)}
										/>
										{formValues.requireMaximumCapacity ? (
											<div className={css(styleSheet.maximumCapasityInput)}>
												<Controller
													name='maximumCapacity'
													control={control}
													render={({ field: { ref, ...fieldProps } }) => (
														<TextInput
															id='maximumCapacity'
															inputId='maximum-capacity-input'
															inputRef={ref}
															min={1}
															type='number'
															onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																fieldProps.onChange(e.target.value);
															}}
															value={fieldProps.value}
														/>
													)}
												/>
												{formState.isSubmitted && formState.errors.maximumCapacity && (
													<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
														{formState.errors.maximumCapacity.message}
													</p>
												)}
											</div>
										) : null}
									</div>
									<div className={css(styleSheet.formControlWrap, styleSheet.formControlWrapLastChild)}>
										<Controller
											name='requireDeadline'
											control={control}
											render={({ field: { ref, ...fieldProps } }) => (
												<>
													<Checkbox
														id='rsvp-by-deadline-checkbox'
														checked={fieldProps.value}
														name='requireDeadline'
														onChange={() => {
															if (!state.enableRequestAttendeesToRSVP) {
																return;
															}
															if (!fieldProps.value) {
																setValue('registrationDeadline', null);
															}
															fieldProps.onChange(!fieldProps.value);
														}}
													>
														<p className={css(styleSheet.noMargin)}>Please RSVP by… (deadline)</p>
													</Checkbox>
												</>
											)}
										/>
										{formValues.requireDeadline ? (
											<div className={css(styleSheet.deadlineDateInput)}>
												<Controller
													name='registrationDeadline'
													control={control}
													render={({ field: { ref, ...fieldProps } }) => {
														return (
															<>
																<Popover>
																	<PopoverTrigger asChild>
																		<span className={css(styleSheet.eventDateInput, styleSheet.eventDateInputSmall)}>
																			<img src={CalendarIconUrl} className={css(styleSheet.calendarIcon)} />
																			<span className={css(styleSheet.selectedDate)}>
																				{fieldProps.value && getDefaultDateStringValue(fieldProps.value)}
																			</span>
																		</span>
																	</PopoverTrigger>
																	<PopoverContent>
																		<div style={{ padding: '20px 10px' }}>
																			<DayPicker
																				allowPastDates={false}
																				minDate={new Date()}
																				maxDate={new Date(formValues.endDate)}
																				onDayClick={val => {
																					fieldProps.onChange(val);
																				}}
																				selectedDays={
																					fieldProps.value
																						? new Date(fieldProps.value)
																						: formValues.startDate
																							? new Date(formValues.startDate)
																							: new Date()
																				}
																			/>
																		</div>
																	</PopoverContent>
																</Popover>

																{fieldProps.value && (
																	<button
																		type='button'
																		className={css(styleSheet.clearDeadline)}
																		onClick={(e: React.MouseEvent) => {
																			e.preventDefault();
																			fieldProps.onChange(null);
																		}}
																	>
																		Clear
																	</button>
																)}
															</>
														);
													}}
												/>
												{formState.isSubmitted && formState.errors.registrationDeadline && (
													<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
														{formState.errors.registrationDeadline.message}
													</p>
												)}
											</div>
										) : null}
									</div>
								</fieldset>
								<div>
									<p className={css(styleSheet.noMargin, styleSheet.infoText)}>
										Once attendees have RSVP&apos;d, you would be able to send emails to those who answered “attending”
										vs “not attending”.
									</p>
									<p className={css(styleSheet.noMargin, styleSheet.infoText)}>
										If the invite is sent through Levitate, we would also be able to track of those who did not respond.
									</p>
								</div>
							</section>
						</div>
						<div className={css(styleSheet.mainContainer, styleSheet.maxWidthContainer)}>
							<Controller
								name='customForm.fields'
								control={control}
								render={({ field: { ...fieldProps } }) => (
									<AdditionalQuestions
										isEdit={isEdit}
										fields={fieldProps.value}
										onUpdateFields={(fields: Api.IFormField<string>[]) => {
											fieldProps.onChange(fields);
										}}
										isAdditionalQuestionsDisabled={state.customFormIsDisabled}
									/>
								)}
							/>
						</div>
					</fieldset>
				</form>
			</FormProvider>
			<ImageFileChooserModal
				modalProps={{
					isOpen: state.fileInputModalOpen,
					onRequestClose: onRequestfileInputClose,
				}}
			/>
			<MediaChooserModal
				modalProps={freeImageModalProps}
				useBasicSearch={true}
				optionalImageName='External Image'
				imageOnly={true}
				styles={[styleSheet.mediaModalStyle]}
				onSave={onFreeImageChooserRequestClose}
			/>
		</div>
	);
};

export const EventRegistrationSurvey = inject(ImpersonationContextKey)(EventRegistrationSurveyBase);
