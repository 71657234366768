import * as Api from '@ViewModels';
import * as React from 'react';
import { ContactSortKey } from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { shouldDisableOwnershipDropdown } from '../../../../models/UiUtils';
import { useContactFilterQuery, useContactsQuery, useHwcEstimateCostQuery } from '../../../../queries';

export const useContactFilterRecipients = ({
	initialContactIdsToOmit = [],
	initialTagFilterCriterias = [],
	initialOwnershipFilter,
	onSendFromChanged,
	onRemoveRecipient: onRemoveRecipientCallback,
	onTagListSearchChange,
}: {
	initialContactIdsToOmit?: string[];
	initialTagFilterCriterias?: Api.IContactFilterCriteria[];
	initialOwnershipFilter?: Api.IOwnershipFilter;
	onSendFromChanged?: (option: Api.ISendFromOptions) => void;
	onRemoveRecipient?: (contactId: string) => void;
	onTagListSearchChange?: () => void;
} = {}) => {
	const userSession = useUserSession();
	const [filterCriterias, setFilterCriterias] = React.useState<Api.IContactFilterCriteria[]>([
		{
			property: Api.ContactFilterCriteriaProperty.All,
		},
	]);
	const [tagSearchCriterias, setTagSearchCriterias] =
		React.useState<Api.IContactFilterCriteria[]>(initialTagFilterCriterias);
	const allCriterias = React.useMemo(() => {
		return [...filterCriterias, ...tagSearchCriterias];
	}, [tagSearchCriterias, filterCriterias]);
	const [contactIdsToOmit, setContactIdsToOmit] = React.useState<Set<string>>(new Set(initialContactIdsToOmit));
	const contactIdsToOmitArray = React.useMemo(() => Array.from(contactIdsToOmit), [contactIdsToOmit]);
	const [contactIdsToAdd, setContactIdsToAdd] = React.useState<string[]>([]);
	const contactsToAdd = useContactsQuery({
		contactIds: contactIdsToAdd,
	});
	const [householdGroupingOn, setHouseholdGroupingOn] = React.useState(
		userSession?.account?.features?.households?.sendToHouseholdByDefault ?? false
	);

	const [sendEmailFrom, setSendEmailFrom] = React.useState<Api.ISendOnBehalfPermission>(
		userSession.defaultSendOnBehalfPermissions.find(x => x.sender.mode === Api.SendEmailFrom.ContactOwner) ??
			userSession.defaultSendOnBehalfPermissions.find(x => x.sender.mode === Api.SendEmailFrom.CurrentUser)
	);
	const getInitialOwnershipFilter = (overridenFilter: Api.IOwnershipFilter | null) => {
		if (overridenFilter) {
			return overridenFilter;
		}
		const firstFilter = userSession.user.ownershipFilters?.length > 0 ? userSession.user.ownershipFilters[0] : null;
		return firstFilter;
	};

	const [selectedOwnershipFilter, setSelectedOwnershipFilter] = React.useState<Api.IOwnershipFilter | null>(
		getInitialOwnershipFilter(initialOwnershipFilter)
	);

	const [disableShowingDropdown, setDisableShowingDropdown] = React.useState(
		shouldDisableOwnershipDropdown(!userSession.canSendOnBehalf, sendEmailFrom)
	);

	const hwcEstimateCostQuery = useHwcEstimateCostQuery({
		filterRequest: {
			excludeContactIds: contactIdsToOmitArray,
			filter: {
				criteria: allCriterias,
			},
			groupByHousehold: householdGroupingOn,
			includeContactIds: contactIdsToAdd,
			ownershipFilter: selectedOwnershipFilter,
		},
	});
	const contactFilterQuery = useContactFilterQuery({
		expand: householdGroupingOn ? 'HouseholdMembers' : undefined,
		filterRequest: {
			filter: {
				criteria: allCriterias,
			},
			groupByHousehold: householdGroupingOn,
			ownershipFilter: selectedOwnershipFilter,
		},
		sortBy: ContactSortKey.Handle,
	});

	React.useEffect(() => {
		setDisableShowingDropdown(shouldDisableOwnershipDropdown(!userSession.canSendOnBehalf, sendEmailFrom));
	}, [userSession.canSendOnBehalf, sendEmailFrom]);

	const [sendEmailFromUser, setSendEmailFromUser] = React.useState<Api.IUser>(null);
	const [showingAdvancedFiltersFlyout, setShowingAdvancedFiltersFlyout] = React.useState(false);
	const onSendOnBehalf = (option: Api.ISendOnBehalfPermission, user?: Api.IUser) => {
		setSendEmailFrom(option);
		setSendEmailFromUser(user);
		setSelectedOwnershipFilter(getInitialOwnershipFilter(option?.requiredFilter));

		onSendFromChanged?.(option?.sender);
	};

	const onTagListSearchesChanged = (searches: Api.IContactFilterCriteria[]) => {
		// Tag list is confined to filters
		setTagSearchCriterias(searches);
		onTagListSearchChange?.();
	};
	const onAdvancedFiltersFlyoutSave = (
		newFilterCriteria: Api.IContactFilterCriteria[],
		householdGrouping?: boolean,
		ownershipFilter?: Api.IOwnershipFilter
	) => {
		setShowingAdvancedFiltersFlyout(false);
		setFilterCriterias(newFilterCriteria);
		setSelectedOwnershipFilter(ownershipFilter);
		setHouseholdGroupingOn(householdGrouping);
	};
	const onAdvancedFiltersFlyoutCancel = () => {
		setShowingAdvancedFiltersFlyout(false);
	};
	const onRemoveRecipient = (contact: Api.IContact) => {
		setContactIdsToAdd(contactIdsToAdd.filter(x => x !== contact.id));
		const _contactIdsToOmit = new Set(contactIdsToOmit);

		_contactIdsToOmit.add(contact.id);
		if (householdGroupingOn) {
			const spouse = contact.householdMembers?.find(
				c => c.id !== contact.id && c.householdRelationship?.title === Api.HouseholdTitle.Spouse
			);
			if (spouse) {
				_contactIdsToOmit.add(spouse.id);
				setContactIdsToAdd(contactIdsToAdd.filter(x => x !== spouse.id));
			}
		}
		setContactIdsToOmit(_contactIdsToOmit);

		onRemoveRecipientCallback(contact.id);
	};
	const onAddContact = (contact: Api.IContact) => {
		const _contactIdsToOmit = new Set(contactIdsToOmit);

		_contactIdsToOmit.delete(contact.id);
		setContactIdsToOmit(_contactIdsToOmit);

		setContactIdsToAdd([...contactIdsToAdd, contact.id]);
	};
	return {
		allFilterCriterias: allCriterias,
		contactFilterQuery,
		contactIdsToAdd,
		contactIdsToOmit: contactIdsToOmitArray,
		contactsToAdd,
		householdGroupingOn,
		hwcEstimateCostQuery,
		selectedOwnershipFilter,
		setSelectedOwnershipFilter,
		disableShowingDropdown,
		onAddContact,
		onAdvancedFiltersFlyoutSave,
		onAdvancedFiltersFlyoutCancel,
		onRemoveRecipient,
		onSendOnBehalf,
		onTagListSearchesChanged,
		sendEmailFrom,
		sendEmailFromUser,
		setShowingAdvancedFiltersFlyout,
		showingAdvancedFiltersFlyout,
		tagSearchCriterias,
	};
};
