import { IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { getDisplayNameWithEmailFirst } from '../../../../models/UiUtils';
import { ContactViewModel, ContactsViewModel } from '../../../../viewmodels/AppViewModels';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IErrorMessageComponentProps {
	contacts: ContactsViewModel;
	onFinish?(target?: ContactViewModel, cancel?: boolean): void;
}

interface IState {
	contentContainerClass?: string;
	hasDataChanged?: boolean;
	loaderClass?: string;
	selectedContact?: ContactViewModel;
}

class _MergeContactsWizard extends React.Component<IProps, IState> {
	public state: IState = {
		contentContainerClass: 'mergeContacts-contentContainer',
		hasDataChanged: false,
		loaderClass: 'hide',
		selectedContact: this.props.contacts.selectedContacts.getByIndex(0),
	};

	public render() {
		const { contacts } = this.props;
		return (
			<div>
				<div className={css(styleSheet.modalWindow)} onClick={this.modalClick} />
				<div className={css(styleSheet.mergeContactsWizardOuterContainer)}>
					<div className={css(styleSheet.mergeContactsCloseButtonContainer)}>
						<button className={css(styleSheet.mergeContactsCloseButton)} onClick={this.closeButtonClick}>
							close button
						</button>
					</div>
					<div className={this.state.loaderClass} />
					<section className={this.state.contentContainerClass}>
						<h3 className={css(styleSheet.mergeContactsHeadline)}>Merge contacts</h3>
						<p className={css(styleSheet.mergeContactsSubHeadline)}>
							Please select the <strong>main</strong> email address for this contact
						</p>
						<ul className={css(styleSheet.mergeContactList)}>
							{contacts.selectedContacts.map(x => {
								return (
									<li key={x.id}>
										<button className={css(styleSheet.mergeContactEntryContainer)} onClick={this.setSelectedContact(x)}>
											<span className={this.getRadioButtonClass(x)} />
											<span className='emailAddress'>{getDisplayNameWithEmailFirst(x.toJs())}</span>
										</button>
									</li>
								);
							})}
						</ul>
						<footer className={css(styleSheet.mergeContactsButtonContainer)}>
							<button className='standardSaveButton' onClick={this.executeMerge}>
								Merge
							</button>
							<button className='standardCancelButton' onClick={this.closeButtonClick}>
								Cancel
							</button>
						</footer>
					</section>
				</div>
			</div>
		);
	}

	private executeMerge = () => {
		const { selectedContact } = this.state;
		if (selectedContact) {
			const { contacts, logEvent, onFinish, errorMessages } = this.props;
			const promise = contacts.merge(
				selectedContact,
				contacts.selectedContacts.filter(x => x.id !== selectedContact.id)
			);
			if (promise) {
				this.setState({
					contentContainerClass: 'hide',
					loaderClass: 'lev-loading-spinner',
				});
				promise
					.then(() => {
						if (onFinish) {
							onFinish(selectedContact, false);
						}
					})
					.catch((error: IOperationResultNoValue) => {
						this.setState({
							contentContainerClass: 'mergeContacts-contentContainer',
							loaderClass: 'hide',
						});

						logEvent('Merge-Error', {
							count: contacts.selectedContacts.length,
							error: { ...error },
						});

						errorMessages.push({
							messages: [error.systemMessage],
						});
					});
			}
		}
	};

	private getRadioButtonClass = (contact: ContactViewModel) => {
		return contact === this.state.selectedContact
			? css(styleSheet.fauxRadioButtonContainerSelected)
			: css(styleSheet.fauxRadioButtonContainer);
	};

	private setSelectedContact = (selectedContact: ContactViewModel) => () => {
		this.setState({ selectedContact });
	};

	private closeButtonClick = () => {
		const { onFinish } = this.props;
		if (onFinish) {
			onFinish(null, true);
		}
	};

	private modalClick = () => {
		// Do nothing
	};
}

const MergeContactsWizardAsObserver = observer(_MergeContactsWizard);
const MergeContactsWizardWithContext = inject(ErrorMessagesViewModelKey)(MergeContactsWizardAsObserver);
export const MergeContactsWizard = withEventLogging(MergeContactsWizardWithContext, 'MergeContactsWizard');
