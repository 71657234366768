import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimaryText, darkGrayFontColor, white } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

const headerHeight = 46;
const bottomBorder = `1px solid ${borderColor}`;

export const styleSheet = StyleSheet.create({
	container: {
		overflow: 'auto',
		height: '100%',
	},
	sortHeader: {
		color: 'red', // TODO: Figure out what styles this had before
	},
	nameColumn: {
		width: 320,
		...CssSelectors.allChildren(
			{
				textDecoration: 'none',
			},
			'a'
		),
	},
	stageColumn: {
		width: 160,
	},
	amountColumn: {
		width: 100,
	},
	ownerColumn: {
		width: 100,
	},
	dateColumn: {
		width: 160,
	},
	companyColumn: {
		width: 320,
	},
	column: {
		boxSizing: 'border-box',
		flexShrink: 0,
		padding: '0 4px',
	},
	headerDisclosureIconAsc: {
		transform: 'rotateZ(-90deg)',
	},
	header: {
		boxSizing: 'border-box',
		display: 'flex',
		height: headerHeight,
		borderBottom: bottomBorder,
	},
	row: {
		borderBottom: bottomBorder,
		boxSizing: 'border-box',
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 14,
		letterSpacing: 0,
		lineHeight: '20px',
		padding: 20,
	},
	loading: {
		marginTop: 30,
	},
	content: {
		height: '100%',
		position: 'relative',
	},
	items: {
		paddingBottom: 80,
	},
	tableOverrides: {
		background: white,
	},
	name: {
		margin: 0,
		color: brandPrimaryText,
	},
});
