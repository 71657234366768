import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { getFormattedPhoneNumber } from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { EventLogger } from '../../../../viewmodels/AppViewModels';
import { TextingCampaignRegistrationPrompt } from '../../../containers/Texting/TextingCampaignRegistrationPrompt';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { Button } from '../../Button';
import { Checkbox } from '../../Checkbox';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TextInput } from '../../TextInput';
import { TextingGraphic } from '../../svgs/graphics/TextingGraphic';
import { CallForwardingSetup } from '../CallForwardingSetup';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	onSetupComplete: () => Promise<void>;
}

const digitRegex = /^[0-9]*$/;

enum SetupSteps {
	ORDER_BY_ADDRESS = 'order-by-address',
	CONFIRM_ORDER = 'confirm-order',
	ORDER_BY_AREA_CODE = 'order-by-area-code',
	CALL_FORWARDING = 'call-forwarding',
	REQUESTABYAREACODEBUTTON = 'request-by-area-code-button',
	REGISTRATION_PROMPT = 'registration-prompt',
}

const accountHasAddress = ({ account }: Api.UserSessionContext) => {
	const { postalCode, city, stateProvince } = account?.planDetails?.address || {};
	return !!postalCode || !!city || !!stateProvince;
};

export const SetupBase: React.FC<IProps> = ({ className = '', onSetupComplete }) => {
	const userSession = useUserSession();
	const viewModel = useRef(new Api.PhoneNumberViewModel(userSession)).current;
	const logger = useEventLogging();
	const errorMessages = useErrorMessages();
	const hasAddress = useRef(accountHasAddress(userSession)).current;
	const [requestedNumber, setRequestedNumber] = useState('');
	const [step, setStep] = React.useState(hasAddress ? SetupSteps.ORDER_BY_ADDRESS : SetupSteps.ORDER_BY_AREA_CODE);
	const [requestedNumberError, setRequestedNumberError] = useState('');
	const [markShared, setMarkShared] = useState(Api.PhoneNumberScope.User);
	const showTextingCampaignRegistrationAction = userSession.pendingAccountActions.find(
		action =>
			action.type === Api.AccountActionType.ShowTextingCampaignRegistrationWelcomeDialog &&
			action.status === Api.AccountActionStatus.Pending
	);

	useEffect(() => {
		(async () => {
			try {
				await viewModel.init(EventLogger);
			} catch (error) {
				const apiError = Api.asApiError(error);
				logger.logApiError('InitPhoneNumber-Error', apiError);
				if (apiError.systemMessage) {
					errorMessages.pushApiError(apiError);
				}
			}
		})();
		return () => {
			viewModel.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onConfirmNumberClick = async () => {
		try {
			await viewModel.orderPhoneNumber(markShared);
			setStep(SetupSteps.CALL_FORWARDING);
		} catch (err) {
			logger.logApiError('OrderPhoneNumber-Error', err);

			errorMessages.pushApiError(err);
		}
	};

	const onCallForwardingComplete = async (callForwardingNumber?: string) => {
		try {
			await viewModel.onSetForwardingPhoneNumber(
				callForwardingNumber,
				viewModel?.phoneNumberOrder?.id ?? viewModel?.sharedNumber?.id
			);
			if (userSession.userRole !== 'limitedUser' && showTextingCampaignRegistrationAction?.id) {
				setStep(SetupSteps.REGISTRATION_PROMPT);
				return;
			}
			onSetupComplete();
		} catch (err) {
			logger.logApiError('Call Forwarding-Error', err);

			errorMessages.pushApiError(err);
		}
	};

	const setNextStepAfterSearchRequest = (errorMessage: string) => {
		if (!viewModel.hasSearchedNumber) {
			setRequestedNumberError(errorMessage);
			setRequestedNumber('');
			setStep(SetupSteps.REQUESTABYAREACODEBUTTON);
		} else {
			setStep(SetupSteps.CONFIRM_ORDER);
		}
	};

	const onGetNumberClick = async () => {
		try {
			await viewModel.searchForPhoneNumber();
			setNextStepAfterSearchRequest(`No numbers available your zip code. Please try requesting by area code.`);
		} catch (err) {
			logger.logApiError('SearchForPhoneNumber-Error', err);

			// @ts-ignore
			if (err?.systemCode === 404) {
				setStep(SetupSteps.REQUESTABYAREACODEBUTTON);
			} else {
				errorMessages.pushApiError(err);
			}
		}
	};

	const onRequestedNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length <= 3 && digitRegex.test(e.target.value)) {
			setRequestedNumberError('');
			setRequestedNumber(e.target.value);
		}
	};

	const onRequestNumberClick = async () => {
		if (requestedNumber.length !== 3) {
			setRequestedNumberError('Invalid area code. Must be 3 digits long.');
		} else {
			try {
				await viewModel.searchForPhoneNumber(requestedNumber);

				setNextStepAfterSearchRequest(
					`No numbers available for the ${requestedNumber} area code. Please try another area code.`
				);
			} catch (err) {
				logger.logApiError('SearchForPhoneNumber-Error', err);

				errorMessages.pushApiError(err);
			}
		}
	};

	const renderTextingGraphic = () => {
		return <TextingGraphic animate={true} className={css(bs.transform, bs.scale75)} />;
	};

	const renderConfirmNumber = () => (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<figure className={css(styleSheet.graphicContainer)}>{renderTextingGraphic()}</figure>
			<h2>Welcome to Texting with Levitate!</h2>
			<p>We&apos;ve created a local number you can text with.</p>
			<TextInput
				className={css(styleSheet.phoneNumber)}
				disabled={true}
				inputClassName={css(styleSheet.phoneNumberInput)}
				inputId='phone-number'
				type='text'
				value={getFormattedPhoneNumber(viewModel.searchedNumber ?? '')}
			/>
			<p className={css(styleSheet.requestANumber)}>
				Not a local area code?
				<button
					className={css(baseStyleSheet.brandLink, styleSheet.inlineLink)}
					onClick={() => setStep(SetupSteps.ORDER_BY_AREA_CODE)}
				>
					Request a different number.
				</button>
			</p>
			{userSession.account.isAdmin && (
				<div className={css(styleSheet.checkboxContainer)}>
					<Checkbox
						backgroundFillColor='#fff'
						checked={markShared === Api.PhoneNumberScope.Account}
						className={css(styleSheet.checkboxLabel)}
						checkedBackgroundFillColor='#fff'
						id='mark-as-shared-number-checkbox'
						onChange={toggleSharedPhoneNumber}
					>
						<span>Make this number shared so other employees can also use it</span>
					</Checkbox>
				</div>
			)}
			<Button
				label={
					viewModel.isBusy || viewModel.connectionState === Api.ConnectionState.Pending ? (
						<LoadingSpinner type='tiny' />
					) : (
						'Next: Set Up Call Forwarding'
					)
				}
				kind='primary'
				disabled={viewModel.isBusy || viewModel.connectionState === Api.ConnectionState.Pending}
				onClick={onConfirmNumberClick}
			/>
		</div>
	);

	const toggleSharedPhoneNumber = () => {
		setMarkShared(v => (v === Api.PhoneNumberScope.Account ? Api.PhoneNumberScope.User : Api.PhoneNumberScope.Account));
	};

	const addSharedNumber = async () => {
		try {
			await viewModel.addUserToSharedNumber(viewModel.sharedNumber?.id);
		} catch (err) {
			logger.logApiError('AddSharedNumber-Error', err);

			errorMessages.pushApiError(err);
		}
		if (viewModel.sharedNumber.callForwardingNumber == null && userSession.account.isAdmin) {
			setStep(SetupSteps.CALL_FORWARDING);
		} else {
			onCallForwardingComplete();
		}
	};

	const renderGetNumber = () => (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<figure className={css(styleSheet.graphicContainer)}>{renderTextingGraphic()}</figure>
			<h2>Welcome to Texting with Levitate</h2>
			<p>Get started by requesting a local number you can text with.</p>
			{hasAddress && (
				<Button
					label={viewModel.isBusy ? <LoadingSpinner type='tiny' /> : 'Request a number based on your zip code'}
					kind='primary'
					onClick={onGetNumberClick}
				/>
			)}
			<Button
				label='Request a specific area code'
				kind='secondary'
				onClick={() => setStep(SetupSteps.ORDER_BY_AREA_CODE)}
			/>
		</div>
	);

	const renderGetSharedNumber = () => (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<figure className={css(styleSheet.graphicContainer)}>{renderTextingGraphic()}</figure>
			<h2>Welcome to Texting with Levitate</h2>
			<p>Which number would you like to text with?</p>
			<div className={css(baseStyleSheet.horizontalStack)}>
				{!!viewModel.sharedNumber && (
					<button className={css(styleSheet.sharedNumberContainer)} onClick={addSharedNumber}>
						<span className={css(styleSheet.sharedNumber)}>{viewModel.sharedNumber.number}</span>
						<span className={css(styleSheet.label)}>Your admin has chosen a number that everyone can text with</span>
					</button>
				)}
				<span className={css(styleSheet.betweenLabel)}>OR</span>
				{hasAddress ? renderRequestByZipCodeButton() : renderRequestNumberByAreaCodeButton()}
			</div>
		</div>
	);

	const renderRequestByZipCodeButton = () => (
		<button className={css(styleSheet.buttonNoNumber)} onClick={onGetNumberClick}>
			<span className={css(styleSheet.noNumberLabel)}>
				{viewModel.isBusy ? <LoadingSpinner type='tiny' /> : 'Request your own number based on your zip code'}
			</span>
		</button>
	);

	const renderRequestNumberByAreaCodeButton = () => (
		<button className={css(styleSheet.buttonNoNumber)} onClick={() => setStep(SetupSteps.REQUESTABYAREACODEBUTTON)}>
			<span className={css(styleSheet.noNumberLabel)}>
				{viewModel.isBusy ? <LoadingSpinner type='tiny' /> : 'Request your own number based on your area code'}
			</span>
		</button>
	);

	const renderSearchByAreaCode = () => (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<figure className={css(styleSheet.graphicContainer)}>{renderTextingGraphic()}</figure>
			<h2>Request a number</h2>
			<p>Give us your desired area code and we&apos;ll do our best to match an available number.</p>
			<TextInput
				autoFocus={true}
				className={css(styleSheet.phoneNumber)}
				inputClassName={css(styleSheet.phoneNumberInputEditable)}
				inputId='phone-number'
				onChange={onRequestedNumberChange}
				type='text'
				value={requestedNumber}
			/>
			{requestedNumberError && <p className={css(styleSheet.error)}>{requestedNumberError}</p>}
			<Button
				label={viewModel.isBusy ? <LoadingSpinner type='tiny' /> : 'Request Number'}
				kind='primary'
				onClick={onRequestNumberClick}
			/>
			{hasAddress && (
				<button
					className={css(baseStyleSheet.brandLink)}
					disabled={viewModel.isBusy}
					onClick={() =>
						setStep(!viewModel.searchedNumber && hasAddress ? SetupSteps.ORDER_BY_ADDRESS : SetupSteps.CONFIRM_ORDER)
					}
				>
					Go back
				</button>
			)}
		</div>
	);

	const renderCallForwarding = () => {
		return (
			<div className={`${css(styleSheet.container)} ${className}`}>
				<CallForwardingSetup onStepComplete={onCallForwardingComplete} />
			</div>
		);
	};
	switch (step) {
		case SetupSteps.ORDER_BY_ADDRESS: {
			return viewModel.sharedNumber ? renderGetSharedNumber() : renderGetNumber();
		}
		case SetupSteps.CONFIRM_ORDER: {
			return renderConfirmNumber();
		}
		case SetupSteps.ORDER_BY_AREA_CODE: {
			return viewModel.sharedNumber ? renderGetSharedNumber() : renderSearchByAreaCode();
		}
		case SetupSteps.CALL_FORWARDING: {
			return renderCallForwarding();
		}
		case SetupSteps.REQUESTABYAREACODEBUTTON: {
			return renderSearchByAreaCode();
		}
		case SetupSteps.REGISTRATION_PROMPT: {
			return (
				<div className={css(bs.pt16, bs.px5, bs.pb5)}>
					<TextingCampaignRegistrationPrompt
						pendingActionId={showTextingCampaignRegistrationAction.id}
						onComplete={onSetupComplete}
					/>
				</div>
			);
		}
		default: {
			break;
		}
	}
};

export const Setup = observer(SetupBase);
