import { StyleSheet } from 'aphrodite';
import {
	boardHeaderCompactHeight,
	boardHeaderHeight,
	mediaQueryMaxWidth,
} from '../../../components/dataBoards/opportunities/OpportunitiesBoardHeader/styles';
import { brandPrimary, brandPrimaryText, tagBackgroundColor } from '../../../styles/colors';
import { BreakPoints, CssSelectors, baseStyleSheet } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	banner: {
		alignItems: 'center',
		background: tagBackgroundColor,
		display: 'flex',
		padding: '1rem 0.5rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.75rem',
			},
			':not(:first-child)'
		),
	},
	bannerLink: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'inline-flex',
		...baseStyleSheet.fontBold,
		fontSize: 14,
		textDecoration: 'none',
	},
	board: {
		background: '#f2f2f2',
		boxSizing: 'border-box',
		height: '100%',
	},
	boardCol: {
		background: '#f2f2f2',
		margin: 0,
		padding: 0,
		...CssSelectors.allDescendants(
			{
				boxSizing: 'border-box',
				margin: 0,
				padding: '0 10px',
			},
			'.kanban-board-column-header'
		),
		...CssSelectors.allDescendants(
			{
				height: '100%',
				margin: 0,
				padding: 0,
				...CssSelectors.allChildren(
					{
						boxSizing: 'border-box',
						flexGrow: 1,
						overflowY: 'auto',
						padding: '0 10px',
						...CssSelectors.allChildren(
							{
								opacity: 0.5,
							},
							'.kanban-board-card-disabled'
						),
					},
					'.kanban-board-cards'
				),
			},
			'.kanban-board-column-content'
		),
	},
	boardContainer: {
		boxSizing: 'border-box',
		height: `calc(100% - ${boardHeaderHeight}px - 30px)`,
		marginBottom: 30,
		maxHeight: `calc(100% - ${boardHeaderHeight}px - 30px)`,
		overflowX: 'auto',
		overflowY: 'hidden',
		position: 'relative',
		[BreakPoints.forMaxWidth(mediaQueryMaxWidth)]: {
			height: `calc(100% - ${boardHeaderCompactHeight}px - 30px)`,
			maxHeight: `calc(100% - ${boardHeaderCompactHeight}px - 30px)`,
		},
	},
	breadcrumbsBar: {
		alignItems: 'center',
		display: 'flex',
		paddingRight: '1rem',
		gap: 24,
	},
	container: {
		boxSizing: 'border-box',
		width: '100%',
	},
	loading: {
		marginTop: '10%',
	},
	placeholder: {
		height: '100%',
		width: '100%',
	},
	automationAnchor: {
		height: 0,
	},
	automationPopover: {
		fontSize: 14,
		letterSpacing: '0',
		padding: '5px 10px',
	},
	automationPopoverMessage: {
		color: '#4a4a4a',
		padding: '5px 12px',
	},
	automationPopoverName: {
		color: brandPrimary,
		padding: '5px 12px',
	},
	automationStartButton: {
		margin: '10px 10px',
		padding: '5px 12px',
	},
	automationXIcon: {
		cursor: 'pointer',
		marginRight: 5,
		textAlign: 'right',
	},
});
