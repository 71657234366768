import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { getDisplayName } from '@AppModels/UiUtils';
import {
	ContactViewModel,
	IContactConnection,
	IOperationResultNoValue,
	IUser,
	ResourceAutoCompleteViewModel,
	ResourceAutoCompleteViewModelType,
	VmUtils,
} from '@ViewModels';
import { Checkbox } from '@WebComponents/Checkbox';
import {
	ConfirmationDialog,
	DefaultDeleteConfirmationOptions,
	IConfirmationDialogOption,
} from '@WebComponents/ConfirmationDialog';
import { InputFieldError } from '@WebComponents/InputFieldError';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { asModalComponent } from '@WebComponents/Modal';
import { TextInput } from '@WebComponents/TextInput';
import {
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '@WebComponents/autocomplete/SimpleAutoCompleteSearchField';
import { TrashIcon } from '@WebComponents/svgs/icons/TrashIcon';
import { WarningIcon } from '@WebComponents/svgs/icons/WarningIcon';
import { css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IModalContext<IContactConnection>,
		IErrorMessageComponentProps,
		IUserSessionComponentProps {
	className?: string;
	contact: ContactViewModel;
	initialConnection?: IContactConnection;
}

interface IState {
	isOwner?: boolean;
	showDeleteConfirmation?: boolean;
	userError?: string;
}

class _EditContactConnection extends React.Component<IProps, IState> {
	@observable private mContactConnection: IContactConnection;
	constructor(props: IProps) {
		super(props);
		this.state = {
			isOwner: this.initialConnectionIsContactOwner,
		};
		this.mContactConnection = {
			id: null,

			notes: null,

			user: null,
			userHasKeepInTouch: false,
			...(props.initialConnection || {}),
		};
	}

	public render() {
		const { className, contact, userSession, initialConnection } = this.props;
		const { userError, isOwner, showDeleteConfirmation } = this.state;
		const deleteConfirmationMessage =
			!!this.isEditingExistingConnection &&
			contact.visibility !== 'all' &&
			initialConnection.user.id === userSession.user.id &&
			userSession.userRole !== 'superAdmin'
				? ['Are you sure you want to remove yourself as a connection?', 'You could lose access to this contact.'].map(
						(x, i) => <div key={i}>{x}</div>
					)
				: 'Are you sure you want to delete this contact connection?';
		return (
			<div className={`${css(styleSheet.container)} edit-contact-connection ${className || ''}`}>
				<div className={css(styleSheet.header)}>
					{`${this.isEditingExistingConnection ? 'Edit' : 'Add a'} Connection`}
				</div>
				<div className={css(styleSheet.body)}>
					<div>
						<div className={css(styleSheet.fieldLabel)}>Name</div>
						<InputFieldError errorMessage={userError}>
							<SimpleAutoCompleteSearchField
								disableAutocomplete={!!contact.isBusy || !!this.isEditingExistingConnection}
								disabled={!!this.isEditingExistingConnection}
								initialSearchQuery={this.mContactConnection.user ? getDisplayName(this.mContactConnection.user) : null}
								onClear={this.clearSelectedUser}
								onCreateAutoCompleteViewModel={this.onCreateAutoCompleteViewModel}
								// @ts-ignore
								onItemSelected={this.onUserSelected}
								onKeyDown={this.onUserSearchFieldKeyDown}
								placeholder='Search co-workers or add yourself'
								style={styleSheet.searchField}
								type={ResourceAutoCompleteViewModelType.User}
							/>
						</InputFieldError>
					</div>
					<div style={{ marginTop: 25 }}>
						<div className={css(styleSheet.fieldLabel, baseStyleSheet.truncateText)}>
							{`How does this person know ${contact.firstName || contact.name}?`}
						</div>
						<TextInput
							className={css(styleSheet.inputField)}
							disabled={!!contact.isBusy}
							inputId='edit-contact-connection-notes-input'
							onChange={this.onNoteChanged}
							placeholder='Optional'
							type='text'
							value={this.mContactConnection.notes || ''}
						/>
					</div>
					<Checkbox
						checked={!!isOwner}
						className={css(styleSheet.ownerCheckbox)}
						disabled={!!this.initialConnectionIsContactOwner || contact.isBusy}
						id='edit-contact-connection-owner-checkbox'
						onChange={this.onIsOWnerCheckboxChanged}
					>
						<span className={css(styleSheet.ownerCheckboxText)}>
							Make this person the &nbsp;
							<span style={{ fontWeight: 700 }}>contact owner</span>
						</span>
					</Checkbox>
				</div>
				<div className={css(styleSheet.footer)}>
					<div className={css(styleSheet.footerLeft)}>
						<button
							className={css(baseStyleSheet.ctaButton)}
							disabled={!!contact.isBusy}
							onClick={this.onAddButtonClicked}
						>
							<span>{this.isEditingExistingConnection ? 'Save' : 'Add'}</span>
						</button>
						<button
							className={css(baseStyleSheet.ctaButtonReverse)}
							disabled={!!contact.isBusy}
							onClick={this.onCancelButtonClicked}
						>
							<span>Cancel</span>
						</button>
					</div>
					{!!this.isEditingExistingConnection && (
						<button
							className={css(styleSheet.deleteButton)}
							disabled={!!contact.isBusy}
							onClick={this.onToggleDeleteConfirmation(true)}
						>
							<TrashIcon />
							<span>Delete</span>
						</button>
					)}
				</div>
				{!!contact.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: !!showDeleteConfirmation,
						onRequestClose: this.onDeleteConfirmationRequestClose,
					}}
					options={DefaultDeleteConfirmationOptions}
					title={deleteConfirmationMessage}
				/>
			</div>
		);
	}

	private get isEditingExistingConnection() {
		const { initialConnection } = this.props;
		return !!initialConnection && !!initialConnection.user;
	}

	private get initialConnectionIsContactOwner() {
		const { contact, initialConnection } = this.props;

		return !!this.isEditingExistingConnection && contact.ownerId === initialConnection.user.id;
	}

	private onCreateAutoCompleteViewModel = (
		_: ResourceAutoCompleteViewModelType,
		suggestedViewModel: ResourceAutoCompleteViewModel
	) => {
		suggestedViewModel.addParam({ includeDeactivated: false });
		return suggestedViewModel;
	};

	private onIsOWnerCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			isOwner: e.target.checked,
		});
	};

	private onNoteChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.mContactConnection.notes = e.target.value || null;
	};

	private clearSelectedUser = () => {
		if (this.mContactConnection.user) {
			this.mContactConnection.user = null;
		}
	};

	private onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<IUser>) => {
		if (e.target) {
			e.target.setSearchQuery(VmUtils.getDisplayName(e.selection));
		}
		this.mContactConnection.user = e.selection;
	};

	private onUserSearchFieldKeyDown = () => {
		const nextState: IState = {};
		const { userError } = this.state;

		if (userError) {
			nextState.userError = null;
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	};

	private onToggleDeleteConfirmation = (showDeleteConfirmation: boolean) => () => {
		this.setState({
			showDeleteConfirmation,
		});
	};

	private onDeleteConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!!result && result.isDestructive && !canceled) {
			const { contact, logApiError, errorMessages, logInput, parentModal } = this.props;

			const promise = contact.connections.removeConnectionToUser(this.mContactConnection.user);
			if (promise) {
				logInput('Delete', 'Click', {
					contactId: contact.id,

					userId: this.mContactConnection.user.id,
				});
				promise
					.then(() => {
						if (parentModal) {
							parentModal.onRequestClose(this.mContactConnection, false);
						}
					})
					.catch((error: IOperationResultNoValue) => {
						errorMessages.pushApiError(error);

						logApiError('SetConnection-Error', error);
					});
			}
		}
		this.onToggleDeleteConfirmation(false)();
	};

	private onAddButtonClicked = () => {
		if (!this.mContactConnection.user) {
			this.setState({
				userError: 'Please select a user',
			});
			return;
		}

		const { contact, logApiError, errorMessages, logInput, parentModal } = this.props;
		const { isOwner } = this.state;
		const promise = contact.setConnection(this.mContactConnection, !!isOwner);
		if (promise) {
			logInput('Add', 'Click', {
				contactId: contact.id,
				userId: this.mContactConnection.user.id,
			});
			promise
				.then(() => {
					if (parentModal) {
						parentModal.onRequestClose(this.mContactConnection, false);
					}
				})
				.catch((error: IOperationResultNoValue) => {
					errorMessages.pushApiError(error);

					logApiError('SetConnection-Error', error);
				});
		}
	};

	private onCancelButtonClicked = () => {
		const { parentModal } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(null, true);
		}
	};
}

const EditContactConnectionAsObserver = observer(_EditContactConnection);
const EditContactConnectionWithContext = inject(
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey,
	ModalChildComponentContextKey
)(EditContactConnectionAsObserver);
export const EditContactConnection = withEventLogging(EditContactConnectionWithContext, 'EditContactConnection');

export const EditContactConnectionModal = asModalComponent(EditContactConnection, {
	className: 'edit-contact-connection-modal',
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
