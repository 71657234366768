import { Dispatch, SetStateAction } from 'react';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { WarningIcon } from '../../svgs/icons/WarningIcon';

interface IProps {
	integrationDisplayName: string;
	isDisableModalOpen: boolean;
	onDisable: () => void;
	setIsDisableModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const DisconnectSyncUserModal = ({
	integrationDisplayName: integrationName,
	isDisableModalOpen,
	onDisable,
	setIsDisableModalOpen,
}: IProps) => {
	const disableSyncUserOptions: IConfirmationDialogOption<boolean>[] = [
		{
			isDestructive: true,
			representedObject: true,
			title: 'Disable',
		},
		{
			isCancel: true,
			representedObject: false,
			title: 'Cancel',
		},
	];

	const onDisableConfirmationDialogRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!!result?.isDestructive && !canceled) {
			onDisable();
		}

		setIsDisableModalOpen(false);
	};

	return (
		<ConfirmationDialog
			icon={<WarningIcon />}
			modalProps={{
				isOpen: isDisableModalOpen,
				onRequestClose: onDisableConfirmationDialogRequestClose,
			}}
			options={disableSyncUserOptions}
			title={`You are the ${integrationName} Sync User for this account. If you disable your user, syncs will not be able to complete until a new Sync User is selected.`}
		/>
	);
};
