import { StyleSheet } from 'aphrodite';
import { titles } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		color: titles,
		padding: 8,
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 20,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	importOptions: {
		marginBottom: 45,
	},
	importOptionsRadio: {
		':not(:first-child)': {
			marginTop: 10,
		},
	},
	options: {
		display: 'inline-block',
		marginBottom: 40,
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'left',
	},
	optionsSubtitle: {
		fontSize: 14,
		marginBottom: 4,
	},
	optionsTitle: {
		fontSize: 18,
		marginBottom: 20,
	},
	searchField: {
		marginBottom: 20,
		width: '100%',
	},
	tagOptions: {},
});
