import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useMemo } from 'react';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../../models/UiUtils';
import { useAutomationTemplateIdQuery } from '../../../../queries';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { TextCampaignEditor } from '../../../components/TextCampaignEditor';
import { OutboundTextMessageBubble } from '../../../components/texting/MessageBubble/OutboundTextMessageBubble';
import { brandPrimary } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { StoredAttachmentPreview } from '../StoredAttachmentPreview';

export function TextCampaignTemplateBody({
	automationTemplateId,
	onDuplicateClick,
}: {
	automationTemplateId: string;
	onDuplicateClick: (options: {
		title: string;
		contentEditorState: Api.IRichContentEditorState;
		attachments?: Api.IFileAttachment[];
	}) => void;
}) {
	const automationTemplateIdQuery = useAutomationTemplateIdQuery({
		id: automationTemplateId,
	});
	const campaignContent = useMemo(() => {
		return automationTemplateIdQuery.data
			? convertRawRichTextContentStateToRichContentEditorState(
					automationTemplateIdQuery.data.published?.steps?.[0]?.content
				)
			: null;
	}, [automationTemplateIdQuery.data]);
	const attachments = (automationTemplateIdQuery.data?.published?.steps?.[0] as Api.TextingAutomationStep)?.attachments;
	return (
		<>
			<div
				className={css(
					bs.flex,
					bs.itemsCenter,
					bs.justifyEnd,
					bs.py3,
					bs.px5,
					bs.gap2,
					bs.border0,
					bs.borderB,
					bs.borderSolid,
					bs.borderMentionDark
				)}
			>
				<button
					className={css(bs.outlineButtonContainer, bs.boxBorder, bs.textBrandPrimary, bs.h8, bs.w20)}
					disabled={automationTemplateIdQuery.status !== 'success'}
					onClick={() => {
						onDuplicateClick({
							title: automationTemplateIdQuery.data.name,
							contentEditorState: campaignContent,
							attachments,
						});
					}}
				>
					Duplicate
				</button>
			</div>
			<div
				className={css(
					bs.itemsCenter,
					bs.boxBorder,
					bs.flex,
					bs.flex1,
					bs.flexCol,
					bs.overflowAuto,
					bs.py8,
					bs.px6,
					bs.bgGray50
				)}
			>
				<div className={css(bs.flex, bs.flexCol, bs.maxWXl, bs.wFull, bs.mxAuto)}>
					{automationTemplateIdQuery.status === 'success' ? (
						<>
							{attachments?.length ? (
								<div
									className={css(
										bs.flex,
										bs.itemsEnd,
										bs.bgWhite,
										bs.borderGray300,
										bs.border,
										bs.borderSolid,
										bs.roundedTLg,
										bs.roundedBNone,
										bs.flexWrap,
										bs.gap2,
										bs.p2
									)}
								>
									{attachments.map(attachment => {
										return <StoredAttachmentPreview key={attachment.url} attachment={attachment} />;
									})}
								</div>
							) : null}
							<div className={css(bs.mb3)}>
								<TextCampaignEditor
									contentState={campaignContent}
									styles={[bs.roundedLg, bs.bgWhite, attachments?.length ? bs.roundedTNone : undefined]}
									config={{
										minHeight: 160,
									}}
									readOnly={true}
								/>
							</div>
							<OutboundTextMessageBubble backgroundColor={brandPrimary}>
								If you&apos;d prefer not to hear from me by text message, please reply back with “Stop”.
							</OutboundTextMessageBubble>
							<div className={css(bs.textRight, bs.textXs, bs.textHeader, bs.italic)}>*Required by CAN-SPAM</div>
						</>
					) : (
						<LoadingSpinner type='small' />
					)}
				</div>
			</div>
		</>
	);
}
