import {
	IOperationResultNoValue,
	ISystemJob,
	IUserMilestones,
	IUserPreferences,
	ResourceVisibility,
} from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import { getEmailProviderDisplayName, getNormalizedEmailProvider } from '../../../../../models/UiUtils';
import { UserViewModel, VmUtils } from '../../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { RadioButton } from '../../../RadioButton';
import { SettingsGroup } from '../../SettingsGroup';
import { SettingsGroupIcon } from '../../SettingsGroupIcon';
import { SettingsSubGroup } from '../../SettingsSubGroup';
import { styleSheet } from './styles';

interface IProps
	extends IErrorMessageComponentProps,
		IUserSessionComponentProps,
		IEventLoggingComponentProps,
		IToasterComponentProps {
	clasName?: string;
	userViewModel: UserViewModel;
}

interface IState {
	createContactsFromSentMessages?: boolean;
	disableOneWayContactSync?: boolean;
	hasImportedContacts?: boolean;
	showConfirmationContainer?: boolean;
	showLoading?: boolean;
	visibility?: ResourceVisibility;
}

class _ImportContactsSettings extends React.Component<IProps, IState> {
	private readonly mEmailProviderName = getEmailProviderDisplayName(this.props.userSession.user.emailProvider) || '';
	public state: IState = {
		showConfirmationContainer: false,
		showLoading: false,
		visibility: 'all',
	};

	public UNSAFE_componentWillMount() {
		const { userViewModel, userSession } = this.props;
		// Determine if user has already imported his/her contacts

		const milestones: IUserMilestones = userViewModel.userMilestones;
		const hasImportedContacts =
			!!milestones && !!milestones.hasImportedContacts && milestones.hasImportedContacts ? true : false;

		this.setState({
			createContactsFromSentMessages: userViewModel.preferences.createContactsFromSentMessages,

			disableOneWayContactSync: userViewModel.preferences.disableOneWayContactSync,
			hasImportedContacts,

			visibility: VmUtils.getDefaultVisibility(userSession.user),
		});
	}

	public render() {
		return (
			<SettingsGroup
				className={`${css(styleSheet.container)} import-contacts-settings`}
				description='Automatically build your contact list.'
				footer={this.renderFooter()}
				icon={<SettingsGroupIcon type='ImportContacts' />}
				name='Import Contacts'
			>
				<div className={css(styleSheet.sections)}>
					{this.renderImportContactsNow()}
					{this.renderAutoAddNewContacts()}
					{this.renderAutoAddFromSentEmail()}
				</div>
				{!!this.showLoading && <LoadingSpinner className='absolute-center' type='large' />}
			</SettingsGroup>
		);
	}

	private renderImportContactsNow() {
		const { showConfirmationContainer } = this.state;
		const { userSession } = this.props;

		const normalizedEmailProvider = getNormalizedEmailProvider(userSession.user.emailProvider);
		if (normalizedEmailProvider == null) {
			return '';
		}

		let emailProviderClass = '';
		if (normalizedEmailProvider === 'Google' || normalizedEmailProvider === 'Gmail') {
			emailProviderClass = css(styleSheet.googleLogo);
		} else if (normalizedEmailProvider === 'Office365' || normalizedEmailProvider === 'Outlook') {
			emailProviderClass = css(styleSheet.o365Logo);
		} else if (normalizedEmailProvider === 'Exchange') {
			emailProviderClass = css(styleSheet.exchangeLogo);
		} else if (normalizedEmailProvider === 'Misc' || normalizedEmailProvider === 'Imap') {
			emailProviderClass = css(styleSheet.genericEmailLogo);
		}

		return (
			<div className={css(styleSheet.importNow)}>
				{showConfirmationContainer ? (
					<div className={css(styleSheet.sectionSubHeader)}>
						We&apos;ve started the import process! We will send you an email once it&apos;s complete so you can review
						the imported contacts and decide which ones to delete, which ones to make shared or private, and which
						duplicate contacts to merge.
					</div>
				) : (
					<div>
						<div className={css(styleSheet.sectionSubHeader)}>
							{userSession.user.emailProviderFeatures.calendar.canViewHistoryAll ? (
								<span>
									Levitate can automatically build a contact list for you based on your {this.mEmailProviderName}{' '}
									address book, past meetings, and email history.
								</span>
							) : (
								<span>Levitate can automatically build a contact list for you based on your email history.</span>
							)}
						</div>
						<div className={`${css(styleSheet.importNowRadioButtons)} settings-group-radio-button-group`}>
							<RadioButton
								id='visibilityAll'
								name='visibilityRadio'
								value='all'
								onChange={this.updateFormFieldValue}
								checked={this.state.visibility !== null && this.state.visibility === 'all'}
							>
								Yes, share my contacts (recommended)
							</RadioButton>
							<RadioButton
								id='visibilityPrivate'
								name='visibilityRadio'
								value='admin'
								onChange={this.updateFormFieldValue}
								checked={this.state.visibility !== null && this.state.visibility === 'admin'}
							>
								No, keep them private
							</RadioButton>
						</div>
						<SettingsSubGroup title='One time import'>
							<div className={emailProviderClass} />
							<div className={css(styleSheet.secondaryMessage)}>
								Levitate can import contacts from your address book, as well as sent messages and meetings from the last
								year.
							</div>
							<button className={css(baseStyleSheet.ctaButton, styleSheet.importNowButton)} onClick={this.importClick}>
								Import Contacts
							</button>
						</SettingsSubGroup>
					</div>
				)}
			</div>
		);
	}

	private renderAutoAddNewContacts() {
		const { disableOneWayContactSync } = this.state;
		return (
			<SettingsSubGroup title='Automatically add new contacts'>
				<div className={css(styleSheet.secondaryMessage)}>
					{`Would you like for Levitate to automatically add any new contacts you add in your ${this.mEmailProviderName} Address Book?`}
				</div>
				<div className='settings-group-radio-button-group'>
					<RadioButton
						id='autoAddNewContactsTrue'
						name='autoAddNewContacts'
						value='true'
						onChange={this.updateFormFieldValue}
						checked={!disableOneWayContactSync}
					>
						Yes
					</RadioButton>
					<RadioButton
						id='autoAddNewContactsFalse'
						name='autoAddNewContacts'
						value='false'
						onChange={this.updateFormFieldValue}
						checked={!!disableOneWayContactSync}
					>
						No
					</RadioButton>
				</div>
			</SettingsSubGroup>
		);
	}

	private renderAutoAddFromSentEmail() {
		const { createContactsFromSentMessages } = this.state;
		return (
			<SettingsSubGroup title='Automatically add anyone I’ve sent an email to '>
				<div className={css(styleSheet.secondaryMessage)}>
					Would you like for Levitate to automatically add any contacts you have sent an email to?
				</div>
				<div className='settings-group-radio-button-group'>
					<RadioButton
						id='autoAddFromSendEmailTrue'
						name='autoAddNewContacts'
						value='true'
						onChange={this.updateFormFieldValue}
						checked={!!createContactsFromSentMessages}
					>
						Yes
					</RadioButton>
					<RadioButton
						id='autoAddFromSendEmailFalse'
						name='autoAddNewContacts'
						value='false'
						onChange={this.updateFormFieldValue}
						checked={!createContactsFromSentMessages}
					>
						No
					</RadioButton>
				</div>
			</SettingsSubGroup>
		);
	}

	private renderFooter() {
		return (
			<div className={css(styleSheet.footer)}>
				<button
					className={css(baseStyleSheet.ctaButton, styleSheet.importNowButton)}
					disabled={this.showLoading}
					onClick={this.onSaveClicked}
				>
					Save
				</button>
				<button
					className={css(baseStyleSheet.ctaButtonReverse, styleSheet.importNowButton)}
					disabled={this.showLoading}
					onClick={this.reset}
				>
					Cancel
				</button>
			</div>
		);
	}

	private get showLoading() {
		const { userViewModel } = this.props;
		const { showLoading } = this.state;
		return userViewModel.isBusy || !!showLoading;
	}

	private reset = () => {
		const { disableOneWayContactSync, createContactsFromSentMessages, defaultGroup } =
			this.props.userViewModel.preferences;
		this.setState({
			createContactsFromSentMessages,
			disableOneWayContactSync,
			showConfirmationContainer: false,
			showLoading: false,
			visibility: defaultGroup || 'all',
		});
	};

	private onSaveClicked = () => {
		const { userViewModel, errorMessages, logApiError, logInput, toaster, userSession } = this.props;
		const { disableOneWayContactSync, createContactsFromSentMessages, visibility } = this.state;
		const userPreferencesToSave: IUserPreferences = {
			createContactsFromSentMessages,
			defaultGroup: visibility,
			disableOneWayContactSync,
		};

		VmUtils.removeEmptyKvpFromObject(userPreferencesToSave);
		if (!userViewModel.isBusy && Object.keys(userPreferencesToSave).length > 0) {
			logInput('Save', 'Click', { ...userPreferencesToSave });
			const promise = userViewModel.updateUserPreferences(userPreferencesToSave);
			if (promise) {
				promise
					.then(prefs => {
						userSession.user.userPreferences = prefs;

						toaster.push({
							message: 'Changes were saved successfully!',
							type: 'successMessage',
						});
					})
					.catch((error: IOperationResultNoValue) => {
						errorMessages.pushApiError(error);

						logApiError('Save-Error', error);
					});
			}
		}
	};

	private importClick = () => {
		const { errorMessages, logInput } = this.props;
		const { hasImportedContacts } = this.state;
		// This is where we'll call the web service to import contacts
		this.setState({ showConfirmationContainer: false, showLoading: true });

		this.props.userSession.webServiceHelper.callWebServiceWithOperationResults<ISystemJob>(
			'contact/import?visibility=' + this.state.visibility,
			'POST',
			{},
			() => this.importStartSuccess(),
			result =>
				errorMessages.push({
					messages: [`Error starting contact import: ${result.systemMessage}`],
				})
		);

		logInput(hasImportedContacts ? 'Import-Again' : 'Import', 'Click');
	};

	private importStartSuccess = () => {
		// Show the confirmation text
		this.setState({ showConfirmationContainer: true, showLoading: false });
	};

	private updateFormFieldValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		const elementId = e.target.id;
		const nextState: IState = {};
		switch (elementId) {
			case 'visibilityAll':
			case 'visibilityPrivate': {
				nextState.visibility = e.target.value;
				break;
			}
			case 'autoAddNewContactsTrue':
			case 'autoAddNewContactsFalse': {
				nextState.disableOneWayContactSync = e.target.value === 'false';
				break;
			}
			case 'autoAddFromSendEmailTrue':
			case 'autoAddFromSendEmailFalse': {
				nextState.createContactsFromSentMessages = e.target.value === 'true';
				break;
			}
			default:
			// Do nothing
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	};
}

const ImportContactsSettingsAsObserver = observer(_ImportContactsSettings);
const ImportContactsSettingsWithContext = inject(
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey,
	ToasterViewModelKey
)(ImportContactsSettingsAsObserver);
export const ImportContactsSettings = withEventLogging(ImportContactsSettingsWithContext, 'ImportContactsSettings');
