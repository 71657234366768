import { IDashboardEmails, ITemplate, SocialMediaConnectionState, TemplatesViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { useHistory } from 'react-router';
import { ICreateSocialMediaPostRequest, ILocationState } from '../../../../models';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import VideoPreviewPlaceholserImageUrl from '../../../assets/video_preview.png';
import { brandSecondary } from '../../../styles/colors';
import { GhostHeading } from '../../GhostHeading';
import { GhostImage } from '../../GhostImage';
import { GhostCard } from '../../GhostsCard';
import { HeartAndShareIcon } from '../../svgs/icons/HeartAndShareIcon';
import { ImageMissingIcon } from '../../svgs/icons/ImageMissingIcon';
import { DashboardCard, DashboardCardHeaderLabel, DashboardCardStat, DashboardCardTag } from '../DashboardCard';
import { DashboardCarousel } from '../DashboardCarousel';
import { ConnectAccountsSection, RecentPostsSection } from './presentation';
import { styleSheet } from './styles';

export function DashboardSocialMediaCard({ data }: { data: IDashboardEmails }) {
	const userSession = useUserSession();
	const history = useHistory();
	const fullscreenModal = useFullscreenModal();

	if (data?.socialMedia == null) {
		return null;
	}

	const handleSocialMediaFeaturedTemplateClick = async (templateId: string) => {
		const templates = new TemplatesViewModel(userSession);
		const template = await templates.getByIdExpandedByLastUsedBy(templateId);

		const locationState: ILocationState<any, ICreateSocialMediaPostRequest<ITemplate>> = {
			model: {
				context: { ...template },
				type: 'Template',
			},
		};

		fullscreenModal?.history.push({
			pathname: `/social-media/post/create/from-template`,
			state: locationState,
		});
	};

	const renderImage = (post: ITemplate) => {
		const attachment = post.attachments?.length > 0 ? post.attachments[0] : null;
		const isVideo = attachment?.mimeType?.startsWith('video/');
		return attachment ? (
			<img
				className={css(styleSheet.suggestedTemplateImg, isVideo ? styleSheet.suggestedTemplateVideoImg : null)}
				src={isVideo ? VideoPreviewPlaceholserImageUrl : post.attachments[0]?.url}
			/>
		) : (
			<figure className={css(styleSheet.imageContainer)}>
				<ImageMissingIcon />
			</figure>
		);
	};

	return (
		<DashboardCard styleDeclaration={styleSheet.root}>
			<section>
				<header className={css(styleSheet.cardHeader)}>
					<button
						className={css(styleSheet.cardIconButton)}
						onClick={() => history.push(`/reporting/social-media-posts`)}
					>
						<HeartAndShareIcon />
					</button>
					<div className={css(styleSheet.headerTotalPosts)}>
						<DashboardCardHeaderLabel>Total Social Media Posts</DashboardCardHeaderLabel>
						<DashboardCardStat styleDeclaration={styleSheet.headerTotalPostsStat}>
							{data.socialMedia.totalPosts}
						</DashboardCardStat>
					</div>
				</header>
				{userSession?.user?.socialMediaConnectedAccounts?.every(
					x => x.state !== SocialMediaConnectionState.Connected
				) || (data.socialMedia.recentPosts?.length ?? 0) === 0 ? (
					<ConnectAccountsSection />
				) : (
					<RecentPostsSection recentPosts={data.socialMedia.recentPosts} />
				)}
			</section>
			{data.socialMedia.featuredTemplates != null && data.socialMedia.featuredTemplates.length > 0 ? (
				<section>
					<header className={css(styleSheet.suggestedTemplatesHeader)}>
						<DashboardCardTag styleDeclaration={styleSheet.suggestedTemplatesTag}>
							Suggested featured posts
						</DashboardCardTag>
					</header>
					<DashboardCarousel
						sliderLeftProps={{ fillColor: brandSecondary }}
						sliderRightProps={{ fillColor: brandSecondary }}
						buttonColumnStyleDeclaration={styleSheet.suggestedTemplateButtonColumn}
						visibleSlides={3}
					>
						{data.socialMedia.featuredTemplates.map((post, i) => (
							<div
								key={i}
								className={css(styleSheet.suggestedTemplate)}
								onClick={() => handleSocialMediaFeaturedTemplateClick(post.id)}
							>
								{renderImage(post)}
								<h4 className={css(styleSheet.suggestedTemplateTitle)}>{post.name}</h4>
							</div>
						))}
					</DashboardCarousel>
				</section>
			) : null}
		</DashboardCard>
	);
}

export function SocialMediaGhostCard() {
	return (
		<GhostCard style={{ display: 'flex', padding: '1rem 1.25rem', width: '100%' }}>
			<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
				<div style={{ alignItems: 'center', display: 'flex', marginBottom: '1.5rem' }}>
					<GhostImage style={{ height: 48, marginRight: '1rem', width: 48 }} />
					<GhostHeading style={{ height: 10, width: 100 }} />
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<GhostHeading style={{ height: 10, marginBottom: '2rem', width: 150 }} />
					<GhostHeading style={{ height: 14, marginBottom: '1rem', width: '70%' }} />
					<GhostHeading style={{ height: 14, marginBottom: '1rem', width: '70%' }} />
					<GhostHeading style={{ height: 14, marginBottom: '1rem', width: '70%' }} />
				</div>
			</div>
			<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
				<GhostHeading style={{ height: 10, marginBottom: '1.5rem', width: 100 }} />
				<div style={{ display: 'flex', flex: 1 }}>
					<GhostImage style={{ height: '100%', marginRight: '1rem', width: 150 }} />
					<GhostImage style={{ height: '100%', width: 150 }} />
				</div>
			</div>
		</GhostCard>
	);
}
