import { getDefaultDateStringValue } from '../../../../models/UiUtils';
import { ActivityListItem } from '../../ActivityListItem';
import { LoadingSpinner } from '../../LoadingSpinner';
import BoardItemActivityIconUrl from './boardItemActivityIcon.svg';
import { styleSheet } from './styles';
import { VmUtils, IOpportunity, IBoardItemActivity } from '@ViewModels';
import { css } from 'aphrodite';
import Waypoint from 'react-waypoint';
import { observer } from 'mobx-react';
import { useGetOpportunityActivitiesQuery } from '../../../../queries';

const _BoardItemActivityList = ({
	opportunity,
}: {
	opportunity: IOpportunity;
}) => {
	const getOpportunityActivitiesQuery = useGetOpportunityActivitiesQuery({
		opportunityId: opportunity.id,
	});
	const activityItems: IBoardItemActivity[] = getOpportunityActivitiesQuery.data?.pages?.flatMap(x => x.values) ?? [];

	const getNextBatchOfActivity = () => {
		getOpportunityActivitiesQuery.fetchNextPage();
	};

	if (!activityItems.length) {
		<LoadingSpinner className={css(styleSheet.loading)} type='large' />;
	}

	return (
		<div>
			{activityItems.length ? (
				<ul>
					{activityItems.map((x, i) => {
						const rightAccessory = (
							<span className={css(styleSheet.rightAccessory)}>{`by ${VmUtils.getDisplayName(x.creator, true)}`}</span>
						);
						return (
							<ActivityListItem
								date={getDefaultDateStringValue(x.lastModifiedDate)}
								iconSrc={BoardItemActivityIconUrl}
								key={x.id || i}
								rightAccessory={rightAccessory}
							>
								<span className={css(styleSheet.text)}>{x.text}</span>
							</ActivityListItem>
						);
					})}
					{getOpportunityActivitiesQuery.hasNextPage ? (
						<Waypoint bottomOffset='-200px' onEnter={getNextBatchOfActivity} />
					) : null}
				</ul>
			) : null}
		</div>
	);
};

export const BoardItemActivityList = observer(_BoardItemActivityList);
