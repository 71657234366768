import { IOperationResultNoValue, IUser } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { EventLogger } from '../../../../../models/Logging';
import { baseStyleSheet } from '../../../../styles/styles';
import { SettingsGroup } from '../../SettingsGroup';
import { SettingsGroupIcon } from '../../SettingsGroupIcon';
import { styleSheet } from './styles';

interface IProps extends IToasterComponentProps, IErrorMessageComponentProps, IUserSessionComponentProps {
	className?: string;
	expanded?: boolean;
}

interface IState {
	confirmNewPassword?: string;
	isLoading?: boolean;
	user?: IUser;
}

class _PasswordSettings extends React.Component<IProps, IState> {
	private mContainerRef = React.createRef<HTMLDivElement>();
	public state: IState = {
		isLoading: false,

		user: { id: this.props.userSession.user.id },
	};

	public componentDidMount() {
		if (this.props.expanded) {
			this.mContainerRef.current?.scrollIntoView();
		}
	}

	public render() {
		const { expanded } = this.props;
		if (!this.state.user) {
			return null;
		}

		const footer = (
			<div className={css(styleSheet.buttonContainer)}>
				<button className={css(baseStyleSheet.ctaButton)} onClick={this.saveClick}>
					Save
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={this.reset}>
					Cancel
				</button>
			</div>
		);

		return (
			<SettingsGroup
				description='Change your password.'
				expanded={expanded}
				footer={footer}
				icon={<SettingsGroupIcon type='Password' />}
				name='Password'
			>
				<div className={this.state.isLoading ? 'lev-loading-spinner' : 'hide'} />
				<div className={this.state.isLoading ? 'hide' : 'settings-formContainer'} ref={this.mContainerRef}>
					<div className={css(styleSheet.fieldContainerTwoColumn)}>
						<div className={css(styleSheet.passwordInstructionsLabel)}>Requirements</div>
						<div className={css(styleSheet.editUserPasswordInstructionsContainer)}>
							<div className={this.getPasswordRequirementsClassName('minEightCharacters')}>
								Needs to be at least 8 characters
							</div>
							<div className={this.getPasswordRequirementsClassName('minOneUppercase')}>
								Includes at least 1 uppercase letter
							</div>
							<div className={this.getPasswordRequirementsClassName('minOneLowercase')}>
								Includes at least 1 lowercase letter
							</div>
							<div className={this.getPasswordRequirementsClassName('minOneNumber')}>Includes at least 1 number</div>
						</div>
					</div>
					<div className={css(baseStyleSheet.mb2)}>
						<span className={css(styleSheet.formFieldLabel)}>Current Password</span>
						<input
							type='password'
							id='currentPassword'
							className={css(baseStyleSheet.textFieldStandard)}
							value={this.state.user.password || ''}
							onChange={this.updateFormFieldValue}
						/>
					</div>
					<div className={css(baseStyleSheet.mb2)}>
						<span className={css(styleSheet.formFieldLabel)}>New Password</span>
						<input
							type='password'
							id='newPassword'
							className={css(baseStyleSheet.textFieldStandard)}
							value={this.state.user.newPassword || ''}
							onChange={this.updateFormFieldValue}
						/>
					</div>
					<div className={css(baseStyleSheet.mb2)}>
						<span className={css(styleSheet.formFieldLabel)}>Confirm New Password</span>
						<input
							className={css(baseStyleSheet.textFieldStandard)}
							id='confirmNewPassword'
							onChange={this.updateFormFieldValue}
							onKeyDown={this.onConfirmPasswordKeyDown}
							type='password'
							value={this.state.confirmNewPassword || ''}
						/>
						<span className={this.getConfirmPasswordMatchClassName()} />
					</div>
				</div>
			</SettingsGroup>
		);
	}

	private reset = () => {
		this.setState({
			confirmNewPassword: null,
			isLoading: false,

			user: { id: this.props.userSession.user.id },
		});
	};

	private onConfirmPasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 13) {
			this.saveClick();
		}
	};

	private updateFormFieldValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		const elementId = e.target.id;
		const user = this.state.user;

		let confirmNewPassword: string = this.state.confirmNewPassword;

		switch (elementId) {
			case 'currentPassword':
				user.password = e.target.value;
				break;
			case 'newPassword':
				user.newPassword = e.target.value;
				break;
			case 'confirmNewPassword':
				confirmNewPassword = e.target.value;
				break;
			default:
			// Do nothing
		}
		this.setState({ confirmNewPassword, user });
	};

	private showToasterMessage = (message: string) => {
		const toasterMessage: string = message;
		if (this.props.toaster) {
			this.props.toaster.push({
				message: toasterMessage,
				type: 'successMessage',
			});
		}
	};

	private displayErrorMessage = (errorMsg: string) => {
		if (this.props.errorMessages) {
			this.props.errorMessages.push({
				messages: [errorMsg],
			});
		}
	};

	private saveClick = () => {
		// Validate password fields
		let isValid = true;
		let errorMessage = 'One or more required fields has not been completed';

		if (!this.state.user.password) {
			isValid = false;
			errorMessage = 'Current Password is required';
		} else if (!this.state.user.newPassword) {
			isValid = false;
			errorMessage = 'New Password is required';
		} else if (!this.state.confirmNewPassword) {
			isValid = false;
			errorMessage = 'Confirm New Password is required';
		} else if (this.state.user.newPassword !== this.state.confirmNewPassword) {
			isValid = false;
			errorMessage = 'New Password and Confirm New Password must match';
		} else if (!this.meetsPasswordRequirements('all')) {
			isValid = false;
			errorMessage = 'You did not meet one or more password requirements (see above)';
		}

		if (isValid) {
			this.setState({ isLoading: true });

			// Call a web service to get the first page of Contacts created by the current user

			this.props.userSession.webServiceHelper.callWebServiceWithOperationResults<IOperationResultNoValue>(
				'user/reset-password', // URL
				'POST', // Method
				this.state.user, // Body (null if it's a GET operation)
				this.saveSuccess,
				this.saveError
			);
		} else {
			// Display error message
			this.displayErrorMessage(errorMessage);
		}
	};

	private saveSuccess = () => {
		// clear fields and stop loading
		this.reset();

		this.showToasterMessage('Password was changed successfully!');

		EventLogger.logEvent({
			action: 'Password-Changed',
			category: 'Settings-Personal',
		});
	};

	private saveError = (error: IOperationResultNoValue) => {
		this.displayErrorMessage(error.systemMessage);
		this.setState({
			isLoading: false,
		});

		EventLogger.logEvent(
			{
				action: 'Password-Changed-Error',
				category: 'Settings-Personal',
			},
			{ ...error }
		);
	};

	private getPasswordRequirementsClassName(requirement: string) {
		const meetsRequirement = this.meetsPasswordRequirements(requirement);
		return `settingsPasswordRequirement-${meetsRequirement ? 'Pass' : 'Fail'}`;
	}

	private getConfirmPasswordMatchClassName() {
		const newPassword = this.state.user.newPassword || '';
		const confirmNewPassword = this.state.confirmNewPassword || '';
		const passwordsMatch = !!newPassword && !!confirmNewPassword && newPassword === confirmNewPassword;
		return `confirmPasswordMatch-${passwordsMatch ? 'Pass' : 'Fail'}`;
	}

	private meetsPasswordRequirements(requirement: string) {
		let minEightCharacters = false;
		let minOneUppercase = false;
		let minOneLowercase = false;
		let minOneNumber = false;

		const password = this.state.user.newPassword ? this.state.user.newPassword : '';
		// Check for min length
		if (password.length > 7) {
			minEightCharacters = true;
		}
		// Check for min one uppercase letter
		if (password.length > 0 && password.toLowerCase() !== password) {
			minOneUppercase = true;
		}
		// Check for min one lowercase letter
		if (password.length > 0 && password.toUpperCase() !== password) {
			minOneLowercase = true;
		}
		// Check for min one number
		if (/\d/.test(password)) {
			minOneNumber = true;
		}

		if (requirement === 'all') {
			return minEightCharacters && minOneUppercase && minOneLowercase && minOneNumber;
		} else if (requirement === 'minEightCharacters') {
			return minEightCharacters;
		} else if (requirement === 'minOneUppercase') {
			return minOneUppercase;
		} else if (requirement === 'minOneLowercase') {
			return minOneLowercase;
		} else if (requirement === 'minOneNumber') {
			return minOneNumber;
		}
	}
}

const PasswordSettingsAsObserver = observer(_PasswordSettings);
export const PasswordSettings = inject(
	ToasterViewModelKey,
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(PasswordSettingsAsObserver);
