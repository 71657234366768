import {
	ContactsViewModel,
	INote,
	IOperationResultNoValue,
	NoteComposerViewModel,
	NoteViewModel,
	RichContentComposerResult,
	createRichContentWithReferencedEntities,
} from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import { parse as getQueryStringParams } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as AppState from '../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../models/Logging';
import { AppAutoUpdaterRefreshBlocker } from '../../components/AppAutoUpdaterRefreshBlocker';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { QuickAddEntity } from '../../components/QuickAddEntity';
import { Toaster } from '../../components/Toaster';
import { NoteComposer } from '../../components/notes/NoteComposer';
import { INavigationItemProps } from '../MainContainer';
import { styleSheet } from './styles';

interface IProps
	extends RouteComponentProps<any>,
		AppState.IQuickAddEntityComponentProps,
		AppState.IToasterComponentProps,
		AppState.IErrorMessageComponentProps,
		INavigationItemProps,
		IEventLoggingComponentProps,
		AppState.IUserSessionComponentProps {
	className?: string;
}

interface IQueryString {
	contactIds?: string[];
	noteId?: string;
}

interface IState {
	isLoading?: boolean;
}

class _NoteEditorContainer extends React.Component<IProps, IState> {
	private noteComposer: NoteComposerViewModel;
	public state: IState = {
		isLoading: true,
	};

	public UNSAFE_componentWillMount() {
		this.noteComposer = new NoteComposerViewModel();

		this.noteComposer.setUserSession(this.props.userSession);

		const params = this.getQueryStringParamsWithProps(this.props);
		if (!!params.contactIds && params.contactIds.length > 0) {
			this.loadContactsWithIds(params.contactIds);
			return;
		}

		this.loadNote(params.noteId);
	}

	public render() {
		const { toaster, quickAddEntity } = this.props;
		const { isLoading } = this.state;
		return (
			<div className={`${css(styleSheet.container)} note-editor-container`}>
				{!!toaster && <Toaster className={css(styleSheet.toaster)} toasterViewModel={toaster} />}
				{!!isLoading && <LoadingSpinner className='absolute-center' type='large' />}
				{this.noteComposer.richContent ? (
					<NoteComposer
						className={css(styleSheet.composer)}
						noteComposer={this.noteComposer}
						onComplete={this.onComplete}
						toastOnSaveSucces={false}
					/>
				) : null}
				<Modal
					isOpen={quickAddEntity.isOpen}
					shouldCloseOnOverlayClick={true}
					onRequestClose={quickAddEntity.close}
					useDefaultHeader={true}
				>
					<QuickAddEntity quickAddEntityVm={quickAddEntity} />
				</Modal>
				<AppAutoUpdaterRefreshBlocker />
			</div>
		);
	}

	private getQueryStringParamsWithProps = (nextProps: IProps) => {
		const { location } = nextProps;
		const queryString = getQueryStringParams(location.search);
		const params: IQueryString = {
			contactIds: this.getContactIds(queryString.contactIds),
			noteId: queryString.noteId,
		};
		return params;
	};

	private getContactIds = (contactIdsStringValue: string) => {
		if (contactIdsStringValue) {
			const contactIds = contactIdsStringValue
				.split(',')
				.map(x => x.trim())
				.filter(x => !!x);
			return contactIds;
		}

		return [];
	};

	private loadContactsWithIds = (contactIds: string[]) => {
		const { userSession, logApiError, logEvent, errorMessages } = this.props;

		const promise = ContactsViewModel.getAllByIds(userSession, contactIds);

		logEvent('ContactsLoad', { contactIds });
		promise
			.then(contacts => {
				const noteModel = createRichContentWithReferencedEntities<INote>((contacts || []).map(x => x.toJs()));

				const note = new NoteViewModel(this.props.userSession, noteModel);
				this.noteComposer.show(note);
				this.setState({
					isLoading: false,
				});
			})
			.catch((error: IOperationResultNoValue) => {
				logApiError('ContactsLoad-Error', error);

				errorMessages.push({
					messages: [error.systemMessage],
				});
			});
	};

	private loadNote = (id: string) => {
		const { logEvent, errorMessages, userSession, logApiError } = this.props;
		const noteModel: INote = { id };

		const note = new NoteViewModel(userSession, noteModel);
		const promise = note.load();

		logEvent('NoteLoad', noteModel);

		promise
			.then(() => {
				this.noteComposer.show(note);
				this.setState({
					isLoading: false,
				});
			})
			.catch((error: IOperationResultNoValue) => {
				logApiError('NoteLoad-Error', error);

				errorMessages.push({
					messages: [error.systemMessage],
				});
			});
	};

	private onComplete = (_: NoteViewModel, result: RichContentComposerResult) => {
		if (result === RichContentComposerResult.Create || result === RichContentComposerResult.Edit) {
			const { toaster } = this.props;
			let delay = 0;
			if (toaster) {
				toaster.push({
					duration: 2000,
					linkTitle: 'Close',
					message: 'Your note was saved.  This window will now close.',
					onLinkClicked: () => window.close(),
					type: 'successMessage',
				});
				delay = 2000;
			}
			setTimeout(() => {
				window.close();
			}, delay);
		}
	};
}

const NoteContainerAsObserver = observer(_NoteEditorContainer);

// use context and mobx-react provider to inject toaster view model
// Return the HOC as a top-level object in order to preserve HMR
const vmKeys = [
	AppState.ErrorMessagesViewModelKey,
	AppState.QuickAddEntityViewModelKey,
	AppState.ToasterViewModelKey,
	AppState.UserSessionViewModelKey,
];
const NoteEditorContainerWithContext = inject(...vmKeys)(NoteContainerAsObserver);
export const NoteEditorContainer = withEventLogging(NoteEditorContainerWithContext, 'NoteEditorContainer');
