import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { CustomFormMetaData, FormFieldType, IFormField, ISatisfactionSurvey } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { CustomDateRange } from '../../components/CustomDateRange';
import { FormFieldWithOptions } from '../../components/FormfieldWithOptions';
import { Popover, PopoverContent, PopoverTrigger } from '../../components/Popover';
import { RadioButton } from '../../components/RadioButton';
import { ISelectBoxOption } from '../../components/SelectBox';
import { TextArea } from '../../components/TextArea';
import { TextInputFormField } from '../../components/TextInputFormField';
import { Toggle } from '../../components/Toggle';
import { StarRating } from '../../components/surveys/StarRating';
import { grayIconFill, noteTintColor, success } from '../../styles/colors';
import { baseStyleSheet, bs } from '../../styles/styles';
import { styleSheet } from './styles';

enum BasicInfoInputIds {
	CompanyName = 'edit-survey-company-name-input',
	IndefDateRange = 'edit-survey-indef-date-range-input',
	Intro = 'edit-survey-intro-input',
	Name = 'edit-survey-name-input',
	SpecificDateRange = 'edit-survey-specific-date-range-input',
}

const ErrorMessage = ({ error }: { error: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> }) => {
	if (!error) {
		return null;
	}
	return <p className={css(styleSheet.errors)}>{error}</p>;
};

export const BasicSurveyInfo: React.FC<{ survey: ISatisfactionSurvey }> = ({ survey }) => {
	const isArchived = !!survey?.archivedDate;
	const { control, watch, reset, formState } = useFormContext();
	const formValues = watch();

	// #region Date range modal
	const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = React.useState<boolean>(false);
	const onEditDateRangeClicked = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
		setDateRangeModalIsOpen(true);
		e.stopPropagation();
		e.preventDefault();
	}, []);
	// #endregion

	const handleCloseDateRangeModal = React.useCallback(() => {
		setDateRangeModalIsOpen(false);
	}, []);

	const hasDateRange = !!formValues.startDate && !!formValues?.expirationDate;

	return (
		<section className={css(styleSheet.section)}>
			<h3 className={css(styleSheet.sectionTitle)}>Basic Survey Info</h3>
			<div className={css(styleSheet.fieldGroup)}>
				<Controller
					name='name'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<TextInputFormField
							disabled={isArchived}
							id={BasicInfoInputIds.CompanyName}
							label={
								<>
									Survey Name <span className={css(baseStyleSheet.required)}>*</span>
								</>
							}
							labelStyles={[styleSheet.label]}
							type='text'
							{...fieldProps}
						/>
					)}
				/>
				<ErrorMessage error={formState.errors?.name?.message} />

				<Controller
					name='companyDisplayName'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<TextInputFormField
							disabled={isArchived}
							id={BasicInfoInputIds.CompanyName}
							label='Company Name'
							labelStyles={[styleSheet.label]}
							type='text'
							{...fieldProps}
						/>
					)}
				/>
			</div>
			<div className={css(styleSheet.fieldGroup)}>
				<div className={css(styleSheet.label)}>
					<span>
						Survey Intro <span className={css(baseStyleSheet.required)}>*</span>
					</span>
				</div>
				<Controller
					name='intro'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<TextArea
							disabled={isArchived}
							inputClassName={css(styleSheet.introTextArea)}
							inputId={BasicInfoInputIds.Intro}
							{...fieldProps}
						/>
					)}
				/>
				<ErrorMessage error={formState.errors?.intro?.message} />
			</div>
			<div className={css(styleSheet.fieldGroup)}>
				<div className={css(styleSheet.label)}>Survey Date Range</div>
				<Controller
					name='indefinitely'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<div className={css(styleSheet.radioButtons)}>
							<RadioButton
								checked={!hasDateRange}
								disabled={isArchived}
								id={BasicInfoInputIds.IndefDateRange}
								onChange={() => {
									fieldProps.onChange(true);
									reset(values => ({
										...values,
										expirationDate: undefined,
										startDate: new Date().toISOString(),
									}));
								}}
							>
								<span>Survey is available indefinitely into the future</span>
							</RadioButton>

							<Popover open={dateRangeModalIsOpen} onOpenChange={handleCloseDateRangeModal}>
								<PopoverTrigger>
									<RadioButton
										checked={hasDateRange}
										disabled={isArchived}
										id={BasicInfoInputIds.SpecificDateRange}
										onChange={() => {
											fieldProps.onChange(false);
											setDateRangeModalIsOpen(true);
										}}
									>
										<span>
											{`Survey is only available for a specific date range${hasDateRange ? ':' : ''}`}
											&nbsp;
										</span>
									</RadioButton>
								</PopoverTrigger>
								<PopoverContent side='right'>
									<CustomDateRange
										minDate={new Date()}
										from={formValues.startDate ? new Date(formValues.startDate) : undefined}
										to={formValues.expirationDate ? new Date(formValues.expirationDate) : undefined}
										resetDates={() => {
											reset(values => ({
												...values,
												expirationDate: undefined,
												startDate: new Date().toISOString(),
											}));
										}}
										endDateTextLabel='Expiration Date'
										endDateRequired={false}
										onChange={(from, to) => {
											reset(values => {
												const fromDateStr = moment(from).toISOString();
												return {
													...values,
													startDate: fromDateStr,
													expirationDate: to ? moment(to).toISOString() : fromDateStr,
												};
											});
										}}
									/>
								</PopoverContent>
							</Popover>
							{hasDateRange && (
								<button
									className={css(baseStyleSheet.brandLink)}
									disabled={isArchived}
									onClick={onEditDateRangeClicked}
								>
									<span>
										{`${moment(formValues.startDate).format('MM/DD/yyyy')} - ${moment(formValues.expirationDate).format(
											'MM/DD/yyyy'
										)}`}
									</span>
								</button>
							)}
						</div>
					)}
				/>
			</div>
		</section>
	);
};

enum ReviewRequestInputIds {
	Link = 'review-request-link-input',
	LinkType = 'review-request-link-type-input',
	Message = 'review-request-message-input',
}

const KnownReviewSites = {
	'Better Business Bureau': [/\.(bbb)\./gi],
	G2: [/\.?(g2)\./gi],
	Google: [/\.?(google)\./gi],
	Trustpilot: [/\.?(trustpilot)\./gi],
	Yelp: [/\.?(yelp)\./gi],
};

const LIMIT = 5;

export const ReviewRequestOptionsBase: React.FC<
	IImpersonationContextComponentProps & { survey: ISatisfactionSurvey }
> = ({ impersonationContext, survey }) => {
	const userSession = useUserSession();
	const { control, watch, reset, formState } = useFormContext<ISatisfactionSurvey>();
	const formValues = watch();

	const defaultReviewLink = (impersonationContext?.account?.features?.surveys || userSession.account.features?.surveys)
		?.defaultReviewLink;
	const isArchived = !!survey?.archivedDate;

	React.useEffect(() => {
		if (!survey?.id && !formValues.reviewRequestSettings.reviewUrl && !formState.isDirty) {
			reset(values => ({
				...values,
				reviewRequestSettings: {
					...values.reviewRequestSettings,
					reviewUrl: defaultReviewLink,
				},
			}));
		}
	}, [defaultReviewLink, reset, survey?.id, formValues.reviewRequestSettings.reviewUrl, formState.isDirty]);

	const onInputBlur = React.useCallback(() => {
		let reviewUrl = formValues.reviewRequestSettings.reviewUrl;
		let reviewType = formValues.reviewRequestSettings.reviewType;
		if (reviewUrl) {
			if (!/^http[s]?:\/\//gim.test(formValues.reviewRequestSettings.reviewUrl)) {
				reviewUrl = `https://${formValues.reviewRequestSettings.reviewUrl}`;
			}

			if (!reviewType) {
				const match = Object.entries(KnownReviewSites).find(entry =>
					entry[1].some(x => x.test(formValues.reviewRequestSettings.reviewUrl))
				);
				if (match) {
					reviewType = match[0];
				}
			}
		}
		reset(values => ({
			...values,
			reviewRequestSettings: {
				...values.reviewRequestSettings,
				reviewRequest: formValues.reviewRequestSettings.reviewRequest,
				reviewUrl,
				reviewType,
			},
		}));
	}, [formValues.reviewRequestSettings, reset]);

	const onStarRatingOptionCheckChanged = React.useCallback(
		(value: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const indexOfMatch = formValues?.reviewRequestSettings?.ratings?.findIndex((x: number) => x === value);

			if (indexOfMatch > -1 && !e.target.checked) {
				const nextRatings: number[] = (formValues.reviewRequestSettings.ratings || []).slice();
				nextRatings.splice(indexOfMatch, 1);
				nextRatings.sort((a, b) => a - b);

				reset(values => ({
					...values,
					reviewRequestSettings: {
						...values.reviewRequestSettings,
						ratings: nextRatings,
					},
				}));
				return;
			}
			if (e.target.checked) {
				const nextRatings: number[] = (formValues.reviewRequestSettings.ratings || []).slice();
				nextRatings.push(value);
				nextRatings.sort((a, b) => a - b);
				reset(values => ({
					...values,
					reviewRequestSettings: {
						...values.reviewRequestSettings,
						ratings: nextRatings,
					},
				}));
			}
		},
		[formValues.reviewRequestSettings.ratings, reset]
	);

	return (
		<div className={css(styleSheet.section)}>
			<div>
				<section className={css(styleSheet.layoutFlexRowSpaceBetween)}>
					<h3 className={css(styleSheet.sectionTitle)}>Ask for a Review</h3>
					<Controller
						name='reviewRequestSettings.isEnabled'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => (
							<Toggle
								checkedColor={success}
								disabled={isArchived}
								id='edit-survey-review-request-toggle'
								isOn={formValues.reviewRequestSettings.isEnabled}
								onToggleCheckChanged={fieldProps.onChange}
								text={formValues?.reviewRequestSettings?.isEnabled ? 'Enabled' : 'Disabled'}
								textStyles={[styleSheet.toggleText]}
								uncheckedColor={grayIconFill}
							/>
						)}
					/>
				</section>
			</div>
			<fieldset
				className={css(styleSheet.fieldset, styleSheet.fieldGroup)}
				disabled={isArchived || !formValues?.reviewRequestSettings?.isEnabled}
			>
				<Controller
					name='reviewRequestSettings.reviewUrl'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => {
						fieldProps.onBlur = onInputBlur;
						return (
							<TextInputFormField
								disabled={isArchived}
								inputId={ReviewRequestInputIds.Link}
								label='Link to the review page (Google, Yelp, etc.)'
								labelStyles={[styleSheet.label]}
								type='text'
								{...fieldProps}
							/>
						);
					}}
				/>
				<ErrorMessage error={formState.errors?.reviewRequestSettings?.reviewUrl?.message} />
				<Controller
					name='reviewRequestSettings.reviewType'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => {
						fieldProps.onBlur = onInputBlur;
						return (
							<TextInputFormField
								disabled={isArchived}
								inputId={ReviewRequestInputIds.LinkType}
								label='What type of review is it above?'
								labelStyles={[styleSheet.label]}
								{...fieldProps}
								styles={[styleSheet.reviewRequestLinkTypeInput]}
								type='text'
							/>
						);
					}}
				/>
				<ErrorMessage error={formState.errors?.reviewRequestSettings?.reviewType?.message} />
			</fieldset>
			<fieldset
				className={css(
					styleSheet.fieldset,
					baseStyleSheet.verticalStack,
					!formValues?.reviewRequestSettings?.isEnabled ? styleSheet.disabled : null
				)}
				disabled={isArchived || !formValues?.reviewRequestSettings?.isEnabled}
			>
				<div className={css(styleSheet.label)}>Ask to leave a review when someone gives you the following ratings:</div>
				<div className={css(styleSheet.fieldGroup)}>
					{Array.from({ length: LIMIT }).map((_, i) => {
						const count = LIMIT - i;
						return (
							<Controller
								key={`edit-survey-review-request-${count}stars`}
								name='reviewRequestSettings.ratings'
								control={control}
								render={({ field: { ref, ...fieldProps } }) => {
									fieldProps.onChange = onStarRatingOptionCheckChanged(count);
									const isChecked = formValues?.reviewRequestSettings?.ratings?.indexOf(count) > -1;
									return (
										// @ts-ignore - value is a number[]
										<Checkbox
											checked={isChecked}
											disabled={isArchived}
											id={`edit-survey-review-request-${count}stars`}
											key={`edit-survey-review-request-${count}stars`}
											{...fieldProps}
										>
											<div className={css(styleSheet.starRating)}>
												<span>{`${count}/${LIMIT}`}</span>
												<StarRating fillColor={noteTintColor} readonly total={LIMIT} value={count} />
											</div>
										</Checkbox>
									);
								}}
							/>
						);
					})}
					<ErrorMessage error={formState.errors?.reviewRequestSettings?.ratings?.message} />
				</div>
				<div className={css(styleSheet.divider)} />
				<div className={css(styleSheet.fieldGroup)}>
					<label className={css(styleSheet.label)} htmlFor={ReviewRequestInputIds.Message}>
						Request shown after a positive rating is left:
					</label>
					<Controller
						name='reviewRequestSettings.reviewRequest'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => {
							return (
								<TextArea
									disabled={isArchived}
									inputClassName={css(styleSheet.reviewRequestMessage)}
									inputId={ReviewRequestInputIds.Message}
									{...fieldProps}
								/>
							);
						}}
					/>
					<ErrorMessage error={formState.errors?.reviewRequestSettings?.reviewRequest?.message} />
				</div>
			</fieldset>
		</div>
	);
};

export const ReviewRequestOptions = inject(ImpersonationContextKey)(ReviewRequestOptionsBase);

const AdditionalQuestionsBase: React.FC<
	IImpersonationContextComponentProps & {
		isEdit?: boolean;
	}
> = ({ isEdit = false }) => {
	const { watch, reset, control } = useFormContext<ISatisfactionSurvey>();
	const formValues = watch();

	const onUpdateFields = React.useCallback(
		(fields: IFormField<string>[]) => {
			reset(values => ({
				...values,
				...formValues,
				customForm: {
					...values.customForm,
					fields,
				},
			}));
		},
		[formValues, reset]
	);

	const onUpdateField = React.useCallback(
		(field: IFormField<string>, index) => {
			const newFields = [...new Set([...formValues.customForm.fields, field])];
			newFields[index].options = field.options;
			onUpdateFields(newFields);
		},
		[formValues.customForm.fields, onUpdateFields]
	);

	const handleRemoveQuestion = React.useCallback(
		(index: number) => {
			const newFields = [...new Set([...formValues.customForm.fields])];
			const fieldToRemove = newFields[index];
			const fieldMetaDataIndex = formValues.customFormMetaData.findIndex(
				(metaData: CustomFormMetaData) => metaData.fieldId === fieldToRemove.id
			);
			if (fieldMetaDataIndex > -1) {
				const newMetaData = [...new Set([...formValues.customFormMetaData])];
				newMetaData.splice(fieldMetaDataIndex, 1);
				newFields.splice(index, 1);
				reset(values => ({
					...values,
					customForm: {
						...values.customForm,
						fields: newFields,
						metaData: newMetaData,
					},
				}));
				return;
			}
			newFields.splice(index, 1);
			reset(values => ({
				...values,
				customForm: {
					...values.customForm,
					fields: newFields,
				},
			}));
		},
		[formValues.customForm.fields, formValues.customFormMetaData, reset]
	);

	const onChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, index: number) => {
			const newFields = [...new Set([...formValues.customForm.fields])];
			newFields[index].label = e.target.value;
			onUpdateFields(newFields);
		},
		[formValues.customForm.fields, onUpdateFields]
	);

	const onToggleCheckChanged = React.useCallback(
		(index: number) => {
			const newFields = [...new Set([...formValues.customForm.fields])];
			newFields[index].isOptional = !newFields[index].isOptional;
			onUpdateFields(newFields);
		},
		[formValues.customForm.fields, onUpdateFields]
	);

	const handleFormFieldTypeChange = React.useCallback(
		(selectedType: ISelectBoxOption<FormFieldType>, questionIndex: number) => {
			const newFields = [...new Set([...formValues.customForm.fields])];
			newFields[questionIndex].fieldType = selectedType.value;
			onUpdateFields(newFields);
		},
		[formValues.customForm.fields, onUpdateFields]
	);

	const onRenderTriggerTitle = (selectedOption: ISelectBoxOption<FormFieldType>): React.ReactNode => {
		return <span>{selectedOption.title}</span>;
	};
	const renderField = React.useCallback(
		(field: IFormField<string>, questionIndex: number) => {
			const fieldMetaData = formValues?.customFormMetaData?.find(
				(metaData: CustomFormMetaData) => metaData.fieldId === field.id
			);
			return (
				<FormFieldWithOptions
					key={`${field.id}-question-${questionIndex}`}
					field={field}
					questionIndex={questionIndex}
					value={formValues.customForm.fields[questionIndex]?.label}
					onToggleCheckChanged={onToggleCheckChanged}
					onChange={onChange}
					onRenderTriggerTitle={onRenderTriggerTitle}
					handleFormFieldTypeChange={handleFormFieldTypeChange}
					handleRemoveQuestion={handleRemoveQuestion}
					showRemove
					onUpdateField={onUpdateField}
					isEdit={isEdit}
				>
					<>
						{!formValues.reviewRequestSettings.isEnabled ? (
							<Checkbox
								className={css(styleSheet.checkboxLabel)}
								checked={fieldMetaData?.isConditional || false}
								id={`edit-survey-ask-question-${questionIndex}-checkbox`}
								onChange={() => {
									// update checkbox value
									const customMetaData = [...formValues.customFormMetaData];
									const fieldMD = customMetaData.findIndex((x: CustomFormMetaData) => x.fieldId === field.id);

									if (fieldMD > -1) {
										customMetaData[fieldMD].isConditional = !fieldMetaData?.isConditional;
										reset(values => ({
											...values,
											customFormMetaData: customMetaData,
										}));
									} else {
										reset(values => ({
											...values,
											customFormMetaData: [
												...formValues.customFormMetaData,
												{
													fieldId: field.id,
													isConditional: true,
													ratings: [1, 2, 3],
												},
											],
										}));
									}
								}}
							>
								<div className={css(styleSheet.checkboxText)}>
									<div>Ask this question if the rating is </div>
									<TextInputFormField
										disabled={!fieldMetaData?.isConditional}
										value={fieldMetaData?.ratings?.[fieldMetaData?.ratings?.length - 1]}
										type='number'
										min={1}
										max={5}
										className={css(styleSheet.thresholdInput)}
										onKeyDown={e => {
											// prevent typing of decimal points
											if (e.key === '.' || e.key === '-' || e.key === '+') {
												e.preventDefault();
											}
										}}
										onChange={e => {
											// update threshold value
											const customMetaData = [...formValues.customFormMetaData];
											const customFormFieldMetaIndex = customMetaData.findIndex(
												(metaData: CustomFormMetaData) => metaData.fieldId === field.id
											);
											if (customFormFieldMetaIndex > -1) {
												const ratings = Array.from(
													{ length: Number(e.target.value) },
													(_, ratingIndex) => ratingIndex + 1
												);
												ratings.sort((a, b) => a - b);

												customMetaData[customFormFieldMetaIndex].ratings = ratings;
												reset(values => ({
													...values,
													customFormMetaData: customMetaData,
												}));
											}
										}}
									/>{' '}
									<div>
										star
										{fieldMetaData?.ratings?.length > 1 ? 's or below' : ''}
									</div>
								</div>
							</Checkbox>
						) : null}
					</>
				</FormFieldWithOptions>
			);
		},
		[
			formValues,
			handleFormFieldTypeChange,
			handleRemoveQuestion,
			isEdit,
			onChange,
			onToggleCheckChanged,
			onUpdateField,
			reset,
		]
	);

	return (
		<Controller
			name='customForm.fields'
			control={control}
			render={({ field: { ref, ...fieldProps } }) => {
				fieldProps.onChange = onUpdateFields;
				return (
					<>
						{formValues.customForm.fields.length ? (
							<section className={css(styleSheet.bottomSection)}>
								<div className={css(styleSheet.section, styleSheet.sectionFullWidth)}>
									<section className={css(bs.flex, bs.flexRow, bs.flexBetween)}>
										<h3 className={css(styleSheet.sectionTitle)}>Additional Questions</h3>
									</section>

									<div>
										{formValues.customForm.fields?.map((field: IFormField<string>, index: number) => {
											if (index !== 0) {
												return null;
											}
											return renderField(field, index);
										})}
									</div>
								</div>
							</section>
						) : null}
						{formValues.customForm.fields?.map((field: IFormField<string>, index: number) => {
							if (index === 0) {
								return null;
							}
							return (
								<section className={css(styleSheet.bottomSection)} key={`${field.id}-question-${index}`}>
									<div className={css(styleSheet.section, styleSheet.sectionFullWidth)}>
										{renderField(field, index)}
									</div>
								</section>
							);
						})}

						<div className={css(bs.flex, bs.justifyEnd)}>
							{formValues?.customForm?.fields?.length < LIMIT ? (
								<Button
									type='button'
									kind='custom'
									className={css(styleSheet.addQuestionButton)}
									onClick={() => {
										const fieldId = uuid();
										const field: IFormField<string> = {
											fieldType: FormFieldType.String,
											id: fieldId,
											label: '',
											isHidden: false,
											isOptional: true,
										};

										reset(values => ({
											...values,
											customForm: {
												...formValues.customForm,
												fields: [...formValues.customForm.fields, field],
											},
										}));
									}}
									label={`+ Add ${formValues.customForm.fields.length ? 'Another' : 'Custom'} Question`}
								/>
							) : null}
						</div>
					</>
				);
			}}
		/>
	);
};

export const AdditionalQuestions = inject(ImpersonationContextKey)(AdditionalQuestionsBase);
