import { INote, IOperationResultNoValue, RecentMeetingViewModel, RichContentComposerResult } from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	INoteComposerComponentProps,
	NoteComposerViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { FeedCard, IFeedCardCallbackProps } from '../FeedCard';
import { FeedCardAvatars } from '../FeedCardAvatars';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		INoteComposerComponentProps,
		IErrorMessageComponentProps,
		IFeedCardCallbackProps {
	className?: string;
	onNoteCreated?(recentMeeting: RecentMeetingViewModel, note: INote): void;
	recentMeeting: RecentMeetingViewModel;
}

class _RecentMeetingCard extends React.Component<IProps> {
	public render() {
		const { className, recentMeeting, onSuppressClicked } = this.props;
		const summary = `How was your meeting${recentMeeting.summary ? ` "${recentMeeting.summary}"` : ''}?`;
		return (
			<FeedCard
				className={`${css(styleSheet.container)} recent-meeting-card ${className || ''}`}
				indicatorColor={brandPrimary}
				showSuppressOption={true}
				onSuppressClicked={onSuppressClicked}
			>
				<div className={css(styleSheet.header)}>
					<FeedCardAvatars
						className={css(styleSheet.avatars)}
						entities={this.visibleAttendees}
						overflowEntities={this.overflowAttendees}
					/>
					<div
						className={css(
							baseStyleSheet.truncateText,
							this.visibleAttendees.length > 0 ? styleSheet.summaryWithAttendees : null
						)}
						title={summary}
					>
						{summary}
					</div>
				</div>
				<div className={css(styleSheet.body)}>
					{this.visibleAttendees.length > 0 && (
						<div className={css(styleSheet.bodyText)}>
							<span>{'Meeting with '}</span>
							{this.visibleAttendees.map((x, i) => {
								return (
									<React.Fragment key={x.id}>
										{i > 0 && (
											<span key={i}>
												{this.overflowAttendees.length === 0 && i === this.visibleAttendees.length - 1 ? ' and ' : ', '}
											</span>
										)}
										<span>{x.name}</span>
									</React.Fragment>
								);
							})}
							{this.overflowAttendees.length > 0 && (
								<>
									<span key={this.overflowAttendees.length}>&nbsp; and &nbsp;</span>
									<span>{`${this.overflowAttendees.length} other${this.overflowAttendees.length > 1 ? 's' : ''}`}</span>
								</>
							)}
						</div>
					)}
					<button
						className={css(baseStyleSheet.ctaButtonSmall, styleSheet.recordNoteButton)}
						onClick={this.onRecordNoteButtonClicked}
					>
						<span>Add a note</span>
					</button>
				</div>
			</FeedCard>
		);
	}

	@computed
	private get visibleAttendees() {
		const { recentMeeting } = this.props;
		return (recentMeeting.attendees || []).slice(0, 2).map(x => x.contact);
	}

	@computed
	private get overflowAttendees() {
		const { recentMeeting } = this.props;
		return (recentMeeting.attendees || []).slice(2).map(x => x.contact);
	}

	private onRecordNoteButtonClicked = () => {
		this.props.noteComposer.show(this.props.recentMeeting.note, (_, result?: RichContentComposerResult) => {
			if (result !== RichContentComposerResult.None) {
				this.suppress();
			}
		});
	};

	private suppress = () => {
		const { recentMeeting, onRequestRemove, errorMessages, logEvent } = this.props;
		const promise = recentMeeting.suppress();
		if (promise) {
			logEvent('Suppress');

			promise
				.then(() => {
					if (onRequestRemove) {
						onRequestRemove();
					}
				})
				.catch((error: IOperationResultNoValue) => {
					errorMessages.pushApiError(error);
				});
		}
	};
}

const RecentMeetingCardAsObserver = observer(_RecentMeetingCard);
const RecentMeetingCardWithContext = inject(
	NoteComposerViewModelKey,
	ErrorMessagesViewModelKey
)(RecentMeetingCardAsObserver);
export const RecentMeetingCard = withEventLogging(RecentMeetingCardWithContext, 'RecentMeetingCard');
