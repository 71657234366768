import { css, StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';
import { styleSheet } from './styles';

interface DataboardStatsProps {
	children: React.ReactNode;
	styles?: StyleDeclarationValue[];
}

interface DataboardStatProps {
	children: React.ReactNode;
	isTotal?: boolean;
	styles?: StyleDeclarationValue[];
}

interface DataboardStatLabelProps {
	children: React.ReactNode;
	styles?: StyleDeclarationValue[];
}

interface DataboardStatValueProps {
	children: React.ReactNode;
	isTotal?: boolean;
	styles?: StyleDeclarationValue[];
}

interface DataboardStatTitleProps {
	children: React.ReactNode;
	styles?: StyleDeclarationValue[];
}

export const DataboardStats: React.FC<DataboardStatsProps> = ({ children, styles = [] }) => {
	return (
		<div className={css(styleSheet.statsContainer, ...styles)}>
			{children}
			<div>&nbsp;</div>
		</div>
	);
};

export const DataboardStat: React.FC<DataboardStatProps> = ({ children, isTotal, styles = [] }) => {
	return <div className={css(isTotal ? styleSheet.totalValue : styleSheet.stat, ...styles)}>{children}</div>;
};

export const DataboardStatTitle: React.FC<DataboardStatTitleProps> = ({ children, styles = [] }) => {
	return <div className={css(styleSheet.statTitle, ...styles)}>{children}</div>;
};

export const DataboardStatLabel: React.FC<DataboardStatLabelProps> = ({ children, styles = [] }) => {
	return <h6 className={css(styleSheet.statLabel, ...styles)}>{children}</h6>;
};

export const DataboardStatValue: React.FC<DataboardStatValueProps> = ({ children, isTotal, styles = [] }) => {
	return <span className={css(isTotal ? styleSheet.totalValue : styleSheet.statValue, ...styles)}>{children}</span>;
};
