import { IPreviewCreateAutomationResult, VmUtils, isDevOrTestEnvironment } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { ContactProfilesGraphic } from '../../svgs/graphics/ContactProfilesGraphic';
import { ArrowIcon } from '../../svgs/icons/ArrowIcon';
import { styleSheet } from './styles';

interface IProps {
	allowStartAutomationsOnHold?: boolean;
	className?: string;
	isOnHold?: boolean;
	isOpen?: boolean;
	onChangedOnHold?(): void;
	onRequestClose?(result?: IConfirmationDialogOption<boolean>, canceled?: boolean): void;
	preview?: IPreviewCreateAutomationResult;
	styles?: StyleDeclarationValue[];
}

const BulkCreateAutomationConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		isDestructive: false,
		representedObject: true,
		title: 'Start Automations for Everyone',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

export const BulkAutomationsCreationConfirmation: React.FC<IProps> = ({
	allowStartAutomationsOnHold,
	className,
	isOnHold,
	isOpen,
	preview,
	onChangedOnHold,
	onRequestClose,
	styles,
}) => {
	const previewContacts = preview?.excludeContacts?.slice(0, 3);

	const previewContactsOverflowCount = previewContacts ? preview.excludeContacts.length - previewContacts.length : 0;
	const showDashboardCardOption = allowStartAutomationsOnHold && isDevOrTestEnvironment;
	return (
		<ConfirmationDialog
			icon={<ContactProfilesGraphic />}
			modalProps={{
				className: `${css(...(styles || []))} BulkAutomationsCreationConfirmation ${className || ''}`,

				isOpen,

				onRequestClose,
			}}
			options={BulkCreateAutomationConfirmationOptions}
			title={<div className={css(styleSheet.title)}>Start automation for everyone selected?</div>}
		>
			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.listItem)}>
					<ArrowIcon />
					<div>If the first step of an automation is an email, we will start sending in 30 minutes.</div>
				</div>

				{previewContacts?.length > 0 && (
					<div className={css(styleSheet.listItem)}>
						<ArrowIcon />
						<div>
							{previewContacts.map((x, i) => {
								return (
									<React.Fragment key={x.id}>
										<span className={css(baseStyleSheet.truncateText, styleSheet.previewContactToken)}>
											{VmUtils.getEntityDisplayName(x)}
										</span>

										{i < previewContacts.length - 1 && (
											<span>
												,&nbsp;
												{i + 1 === previewContacts.length - 1 && previewContactsOverflowCount < 1 && <>and&nbsp;</>}
											</span>
										)}
									</React.Fragment>
								);
							})}
							{previewContactsOverflowCount > 0 && (
								<span>
									&nbsp;
									{`and ${previewContactsOverflowCount} other${previewContactsOverflowCount === 1 ? '' : 's'}`}
								</span>
							)}
							<span>
								&nbsp;
								{`${
									previewContacts.length > 1 || previewContactsOverflowCount > 0 ? 'have' : 'has'
								} this automation in progress. We will skip them for now.`}
							</span>
						</div>
					</div>
				)}
				{showDashboardCardOption && (
					<div>
						<Checkbox id='is-on-hold-checkbox' onChange={onChangedOnHold} checked={isOnHold}>
							<div style={{ width: '100%' }}>Show on Pending Dashboard Card</div>
						</Checkbox>
					</div>
				)}
			</div>
		</ConfirmationDialog>
	);
};
