import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useTagSearchQuery({
	enabled,
	query,
	expandTagAlert = true,
}: {
	query: string;
	expandTagAlert?: boolean;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryKey: [`tag/search`, query, expandTagAlert],
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IAccountTag[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `tag/search`,
					queryParams: { query, expand: expandTagAlert ? 'TagAlert' : undefined },
				}),
				'GET'
			);
		},
		enabled,
	});
}
