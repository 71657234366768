import { borderColor, brandPrimary } from '@Styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	cardTitleNameLink: {
		textDecoration: 'none',
	},
	cardTitleDetails: {
		fontSize: 14,
	},
	board: {
		display: 'flex',
		gap: '1rem',
		padding: '1rem',
		height: '100%',
		minHeight: 0,
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		background: '#f4f5f7',
		borderRadius: '3px',
		minWidth: 300,
		maxWidth: 300,
		height: '100%',
		minHeight: 0,
	},
	columnHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 8,
	},
	columnTitle: {
		fontWeight: 'bold',
	},
	card: {
		background: 'white',
		borderRadius: 8,
		border: `1px solid ${borderColor}`,
	},
	cardContent: {
		padding: 16,
	},
	cardTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		color: brandPrimary,
	},
	cardTitleName: {
		fontWeight: 'bold',
		textDecoration: 'none',
	},
	columnFooter: {
		padding: 8,
	},
	columnFooterContent: {
		padding: 8,
	},
	columnFooterTitle: {
		fontWeight: 'bold',
	},
	cardPlaceholder: {
		background: '#e3e3e3',
		borderRadius: '3px',
		minHeight: '50px',
		margin: '0.5rem',
		transition: 'all 0.2s ease',
	},
	emptyColumnPlaceholder: {
		background: '#e3e3e3',
		borderRadius: '3px',
		minHeight: '100px',
		margin: '0.5rem',
		width: 'calc(100% - 1rem)',
		transition: 'all 0.2s ease',
		border: '2px dashed #ccc',
	},
	dragging: {
		opacity: 0.5,
		cursor: 'grabbing',
	},
	columnContent: {
		flex: 1,
		overflowY: 'auto',
		minHeight: 0,
		padding: '0.5rem',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		'::-webkit-scrollbar': {
			width: '8px',
		},
		'::-webkit-scrollbar-track': {
			background: 'transparent',
		},
		'::-webkit-scrollbar-thumb': {
			background: '#DFE1E6',
			borderRadius: '4px',
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: '#C1C7D0',
		},
	},
	columnPlaceholder: {
		background: '#e3e3e3',
		borderRadius: 3,
		width: 100,
		margin: '0 0.5rem',
		height: 50,
	},
});
