import * as Api from '@ViewModels';
import * as React from 'react';
import { useContextGuard } from '../../../../models/hooks/useContextGuard';

export function useTextMessageConversations() {
	const [recipients, setRecipients] = React.useState<(Api.TextRecipientViewModel | Api.IPhoneNumber)[]>([]);

	const [selectedConversation, setSelectedConversation] = React.useState<Api.ConversationViewModel>(null);

	const [selectedRecipient, setSelectedRecipient] = React.useState<Api.TextRecipientViewModel>(null);

	const [selectedRecipientIndex, setSelectedRecipientIndex] = React.useState<number>(null);
	const [showNewConversationSearch, setShowNewConversationSearch] = React.useState(false);

	const context = React.useMemo(
		() => ({
			recipients,
			selectedConversation,
			selectedRecipient,
			selectedRecipientIndex,
			setRecipients,
			setSelectedConversation,
			setSelectedRecipient,
			setSelectedRecipientIndex,
			setShowNewConversationSearch,
			showNewConversationSearch,
		}),
		[recipients, selectedConversation, selectedRecipient, selectedRecipientIndex, showNewConversationSearch]
	);

	return context;
}

export type ITextMessageConversationsContext = ReturnType<typeof useTextMessageConversations>;

export const TextMessageConversationsContext = React.createContext<ITextMessageConversationsContext>(null);

export const useTextMessageConversationsContext = () =>
	useContextGuard(TextMessageConversationsContext, 'TextMessageConversationsContext');

export function TextMessageConversationsContextProvider({ children }: React.PropsWithChildren<any>) {
	const context = useTextMessageConversations();
	return (
		<TextMessageConversationsContext.Provider value={context}>{children}</TextMessageConversationsContext.Provider>
	);
}
