import { StyleSheet } from 'aphrodite';
import { hexToRGBA } from '../../../models/UiUtils';
import {
	background,
	borderColor,
	brandPrimary,
	brandPrimaryText,
	charmGray,
	destructive,
	header,
	inputBorderColor,
	mention,
	mentionDark,
	midGray,
	nameCircles,
	titles,
	white,
} from '../../styles/colors';
import { CssSelectors, Layouts } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	timezonePicker: {
		background: white,
		borderRadius: 6,
		border: `1px solid ${midGray}`,
	},
	pickerPopoverContent: {
		boxSizing: 'border-box',
		padding: `24px 16px`,
	},
	addMediaContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 14,
	},
	dateRangePickerWrapper: {
		width: '100%',
	},
	dateRangePicker: {
		borderWidth: 0,
		boxShadow: 'none',
		borderRadius: 0,
	},
	calendarIcon: {
		marginRight: 10,
	},
	clearDeadline: {
		color: brandPrimaryText,
		textDecoration: 'none',
	},
	contentContainers: {
		color: '#a7abad',
		fontSize: 14,
	},
	deadlineDateInput: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		paddingTop: 8,
	},
	errorMessage: {
		color: destructive,
		fontSize: 12,
		left: 0,
		position: 'absolute',
		top: '100%',
		whiteSpace: 'nowrap',
		margin: 0,
	},
	errorMessageCustomForm: {
		top: 'auto',
		bottom: 50,
		left: 50,
	},
	eventDateButton: {
		minHeight: 20,
	},
	eventDateInput: {
		backgroundColor: background,
		border: `1px solid ${inputBorderColor}`,
		borderRadius: 3,
		display: 'inline-flex',
		padding: 10,
		position: 'relative',
		width: '100%',
		fontSize: '0.875rem',
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				display: 'inline-flex',
				width: `100%`,
			},
			'button'
		),
	},
	eventDateInputSmall: {
		width: 126,
	},
	eventEndTimeInput: {
		maxWidth: 75,
	},
	eventEndTimeInputInner: {
		width: '100%',
	},
	eventlabel: {
		color: header,
		display: 'block',
		fontSize: 14,
		marginBottom: 6,
	},
	eventStartTimeInput: {
		maxWidth: 75,
	},
	eventStartTimeInputInner: {
		width: '100%',
	},
	fieldset: {
		border: 0,
		margin: 0,
		padding: 0,
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '0 auto',
		padding: '22px 0',
		width: 922,
		...CssSelectors.allChildren(
			{
				marginRight: 20,
			},
			'button:not(:last-child)'
		),
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				borderRadius: 3,
				display: 'inline-flex',
				justifyContent: 'center',
			},
			'button'
		),
	},
	formControlWrap: {
		marginBottom: 16,
		position: 'relative',
	},
	formCustomControlWrap: {
		marginBottom: 16,
	},
	formControlWrapLastChild: {
		marginBottom: 69,
	},
	formStyles: {
		display: 'flex',
		flexDirection: 'column',
	},
	freeImage: {
		paddingLeft: 6,
		paddingRight: 6,
	},
	iconButtons: {
		background: '#f9f9f9',
		border: `1px solid ${mentionDark}`,
		color: '#0D0F11',
		display: 'flex',
		fontSize: 16,
		marginBottom: 4,
		minWidth: 142,
		padding: 8,
	},
	infoText: {
		color: header,
		fontSize: 14,
		marginBottom: 25,
	},
	linkIcon: {
		marginRight: 10,
	},
	logoImage: {
		height: 50,
		left: 300,
	},
	logoName: {
		maxWidth: 200,
	},
	logoRemove: {
		left: 'auto',
		marginRight: 12,
		position: 'absolute',
		right: 0,
		top: '50%',
		transform: 'translateY(-50%)',
	},
	logoWrap: {
		...Layouts.horizontalStack(12),
		border: `1px solid ${nameCircles}`,
		borderRadius: 3,
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		margin: '20px 0',
		position: 'relative',
	},
	mainContainer: {
		...Layouts.horizontalStack(18),
		alignItems: 'stretch',
		display: 'flex',
		justifyContent: 'center',
	},
	maxWidthContainer: {
		maxWidth: 926,
		width: '100%',
		margin: '16px auto',
	},
	maximumCapasityInput: {
		maxWidth: 70,
		paddingTop: 8,
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'input'
		),
	},
	mediaModalStyle: {
		minWidth: 500,
		width: 500,
	},
	noMargin: {
		margin: 0,
	},
	notEditableNotice: {
		color: destructive,
		fontSize: 16,
		textAlign: 'center',
	},
	ownImage: {
		color: '#046CB6',
		fontSize: 14,
		marginBottom: 13,
		marginTop: 12,
	},
	previewEvent: {
		color: 'transparent',
		fontSize: 0,
	},
	saveButton: {
		alignItems: 'center',
		boxSize: 'border-box',
		height: 40,
		width: '100%',
	},
	section: {
		border: `1px solid ${mentionDark}`,
		borderRadius: 3,
		boxShadow: `0px 0px 12px ${mention}`,
		boxSizing: 'border-box',
		padding: '21px 50px 62px',
		width: 452,
	},
	bottomSectionPadding: {
		padding: '21px 50px 0',
	},
	sectionTitle: {
		color: brandPrimaryText,
		fontSize: 18,
		margin: '0 0 8px 0',
	},
	selectDate: {
		boxSizing: 'border-box',
		height: 40,
		width: 152,
	},
	selectDateLabel: {
		display: 'block',
		fontSize: 12,
		marginBottom: 8,
	},
	selectedDate: {},
	selectTimeWrap: {
		display: 'flex',
		gap: 16,
		justifyContent: 'center',
		marginBottom: 24,
	},
	sourceMention: {
		color: ' #A7ABAD',
		fontSize: 12,
		fontStyle: 'italic',
		minWidth: 142,
		textAlign: 'center',
	},
	submitButton: {
		borderRadius: 3,
	},
	toggleText: {
		color: titles,
		cursor: 'pointer',
	},
	bottomSection: {
		width: '100%',
		boxSizing: 'border-box',
		position: 'relative',
	},
	sectionFullWidth: {
		width: '100%',
		boxSizing: 'border-box',
		marginBottom: 20,
	},
	questionWrap: {
		marginBottom: 18,
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				width: '100%',
			},
			'div'
		),
	},
	label: {
		fontSize: 14,
	},
	questionInput: {
		marginBottom: 8,
	},
	deleteButton: {
		fontSize: 0,
		color: 'transparent',
		position: 'absolute',
		top: 17,
		right: 15,
	},
	addQuestionButton: {
		padding: 12,
		width: '100%',
		textAlign: 'center',
		color: brandPrimary,
		background: hexToRGBA(brandPrimary, 0.1),
		border: `2px dashed ${brandPrimary}`,
		borderRadius: 8,
	},
	addQuestionFooter: {
		width: '100%',
	},
	dropdownMenu: {
		transform: 'translateY(-18px)',
		borderRadius: 8,
	},

	dropdownTrigger: {
		border: `1px solid ${borderColor}`,
		background: hexToRGBA(borderColor, 0.1),
		borderRadius: 8,
		transform: 'translateY(22px)',
	},
	dropdownTriggerDisabled: {
		background: `${hexToRGBA(charmGray, 0.5)} !important`,
	},
	blurb: {
		color: charmGray,
		fontSize: 12,
		margin: '0 0 21px 0',
	},
	optionsWrapper: {
		maxWidth: '70%',
		paddingTop: 8,
		...CssSelectors.allChildren(
			{
				marginBottom: 0,
				width: '100%',
				...CssSelectors.allDescendants(
					{
						width: '100%',
					},
					'.text-input-form-field'
				),
			},
			'div'
		),
	},
	optionsWrapperDropdown: {
		...CssSelectors.allChildren(
			{
				...CssSelectors.allDescendants(
					{
						marginBottom: 0,
					},
					'.text-input-form-field'
				),
			},
			'div'
		),
	},
	removeOptionButton: {
		transform: 'translateY(-2px)',
	},
	fakeCheckbox: {
		width: 14,
		height: 14,
		border: `1px solid ${charmGray}`,
		background: white,
		borderRadius: 2,
		transform: 'translateY(-2px)',
	},
	addOptionButton: {
		color: brandPrimary,
		marginTop: 8,
	},
	addOptionButtonLeftPadding: {
		paddingLeft: 24,
	},
	toggleLabel: {
		color: charmGray,
		fontSize: 14,
	},
	errorMessageInline: {
		color: destructive,
		fontSize: 12,
		margin: 0,
	},
});
