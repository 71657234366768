import * as Api from '@ViewModels';
import * as Tabs from '@radix-ui/react-tabs';
import { css } from 'aphrodite';
import * as React from 'react';
import {
	useEnvironment,
	useFullscreenModal,
	useSingleEmailComposer,
	useUserSession,
} from '../../../../../models/hooks/appStateHooks';
import { useContact } from '../../../../../queries';
import { DetailsFlyout } from '../../../DetailsFlyout';
import { styleSheet as flyoutStyleSheet } from '../../../DetailsFlyout/styles';
import { HandwrittenCardTemplateSearchModal } from '../../../HandwrittenCards/HandwrittenCardTemplateSearchModal';
import { CompactContactInfo } from '../../../contacts/CompactContactInfo';
import { CompactPoliciesInfo } from '../../../contacts/CompactPoliciesInfo';
import { CalendarButton } from '../../../contacts/ContactInfoHeader/presentation';
import { RelationshipHealthStatusBar } from '../../../contacts/RelationshipHealthStatusBar';
import { EmailIcon } from '../../../svgs/icons/EmailIcon';
import { PostageIcon } from '../../../svgs/icons/PostageIcon';
import { white } from '../../../../styles/colors';
import { PostcardComposerStep } from '../../../../containers/PostcardComposer';
import { styleSheet } from './styles';

enum TabValues {
	Policies = 'Policies',
	Profile = 'Profile',
}

export const PolicyHolderDetailsFlyout = ({
	isOpen,
	setIsOpen,
	partialContact,
	showEmailButton = true,
}: {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	partialContact: Api.IContact;
	showEmailButton?: boolean;
}) => {
	const userSession = useUserSession();
	const environment = useEnvironment();
	const singleEmailComposer = useSingleEmailComposer();
	const fullscreenModal = useFullscreenModal();

	const [isHwcTemplateModalOpen, setIsHwcTemplateModalOpen] = React.useState(false);

	const contactQuery = useContact({
		contactId: partialContact.id,
	});

	const contactDetails = contactQuery.data ?? partialContact;

	const showHwcButton = userSession.account.features.handwrittenCards?.enabled;

	const contactVm = new Api.ContactViewModel(userSession, contactDetails);

	const [selectedTab, setSelectedTab] = React.useState<keyof typeof TabValues>(TabValues.Policies);
	const onSelectedTabChanged = (tab: string) => {
		setSelectedTab(tab as keyof typeof TabValues);
	};

	const sendEmail = () => {
		singleEmailComposer.showForRecipients([contactVm]);
	};
	const sendHwc = () => {
		setIsHwcTemplateModalOpen(true);
	};
	const bookAppointment = (url: string) => {
		window.open(url, '_blank');
	};
	const closeHwcTemplateModal = () => {
		setIsHwcTemplateModalOpen(false);
	};

	return (
		<DetailsFlyout isOpen={isOpen} setIsOpen={setIsOpen} title='Policy Holder Details'>
			<div className={css(styleSheet.container)}>
				<section className={css(styleSheet.section)}>
					<h4 className={css(flyoutStyleSheet.subTitle)}>{contactDetails.handle}</h4>
					<small>{contactDetails?.primaryEmail?.value}</small>
				</section>

				<section className={css(styleSheet.quickActions)}>
					{showEmailButton ? (
						<a className={css(styleSheet.quickActionButton)} onClick={sendEmail}>
							<EmailIcon fillColor={white} width={16} height={14} />
						</a>
					) : null}

					{showHwcButton ? (
						<a className={css(styleSheet.quickActionButton)} onClick={sendHwc}>
							<PostageIcon fillColor={white} width={16} height={16} />
						</a>
					) : null}

					<CalendarButton contact={contactVm} onBookAppointmentBtnClicked={bookAppointment} />
				</section>

				<RelationshipHealthStatusBar contact={contactVm} environment={environment} />

				<Tabs.Root className='tab-view' value={selectedTab} onValueChange={onSelectedTabChanged}>
					<Tabs.List className={`tab-view-tabbar-tabs ${css(styleSheet.tabViewTabs)}`}>
						<Tabs.Trigger
							value={TabValues.Policies}
							className={`tab-view-tabbar-item ${
								selectedTab === TabValues.Policies ? `tabbar-item-selected` : ''
							} ${css(styleSheet.tabViewItem, selectedTab === TabValues.Policies && styleSheet.tabViewSelectedItem)}`}
						>
							<span>Policies</span>
						</Tabs.Trigger>

						<Tabs.Trigger
							value={TabValues.Profile}
							className={`tab-view-tabbar-item ${selectedTab === TabValues.Profile ? `tabbar-item-selected` : ''} ${css(
								styleSheet.tabViewItem,
								selectedTab === TabValues.Profile && styleSheet.tabViewSelectedItem
							)}`}
						>
							<span>Profile</span>
						</Tabs.Trigger>
					</Tabs.List>
					<Tabs.Content value={TabValues.Policies} className='tab-view-content'>
						<div className={css(styleSheet.section)}>
							<CompactPoliciesInfo contact={contactDetails} />
						</div>
					</Tabs.Content>
					<Tabs.Content value={TabValues.Profile} className='tab-view-content'>
						<div className={css(styleSheet.section)}>
							<CompactContactInfo contact={contactVm} className={css(styleSheet.tabViewContent)} showHwcActionButton />
						</div>

						<div className={css(flyoutStyleSheet.separator)} />
					</Tabs.Content>
				</Tabs.Root>
			</div>

			{isHwcTemplateModalOpen ? (
				<HandwrittenCardTemplateSearchModal
					isOpen
					onClose={closeHwcTemplateModal}
					onTemplateClicked={template => {
						closeHwcTemplateModal();

						fullscreenModal.history.push({
							pathname: `/postcard/${template.id}/${PostcardComposerStep.Preview}`,
							state: {
								contactId: contactDetails.id,
							},
						});
						setIsOpen(false);
					}}
				/>
			) : null}
		</DetailsFlyout>
	);
};
