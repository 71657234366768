import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useEventLogging } from '../../../../models/Logging';
import { getDisplayName } from '../../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { invalidateDailyUpdates, useDailyUpdates } from '../../../../queries';
import { baseStyleSheet } from '../../../styles/styles';
import { GhostHeading } from '../../GhostHeading';
import { GhostImage } from '../../GhostImage';
import { GhostCard } from '../../GhostsCard';
import { ActionItemsIcon } from '../../svgs/icons/ActionItemsIcon';
import { AssigneeIcon } from '../../svgs/icons/AssigneeIcon';
import { CalendarDateIcon } from '../../svgs/icons/CalendarDateIcon';
import { ClockIcon } from '../../svgs/icons/ClockIcon';
import { RefreshIcon } from '../../svgs/icons/RefreshIcon';
import { AnimatedNumber } from '../AnimatedNumber';
import { DashboardCard, DashboardCardHeaderLabel, DashboardCardStat } from '../DashboardCard';
import { DashboardDailyUpdateItem } from './presentation';
import { styleSheet } from './styles';

function getSummaryFromContactList(contacts: Api.IContact[]) {
	let list = '';
	if (!contacts.length) {
		return list;
	} else {
		for (let idx = 0; idx < contacts.length; idx++) {
			const contact = contacts[idx];
			if (contacts.length > 3 && idx === 3) {
				list = list.concat('and more...');
				break;
			} else if (idx === contacts.length - 1) {
				list = list.concat(`${getDisplayName(contact)}`);
			} else {
				list = list.concat(`${getDisplayName(contact)}, `);
			}
		}
		return list;
	}
}

function getSummaryFromDailyUpdateCollection<T>(
	collection: T[],
	contactsFromCollection: (item: T) => Api.IContact[] | Api.IContact | null
): string {
	if (!collection) {
		return null;
	}
	const contacts = collection.flatMap(contactsFromCollection).filter(contact => contact != null);

	return getSummaryFromContactList(contacts);
}

function getContactsFromActionItems(actionItems: Api.IActionItem[] | null) {
	return getSummaryFromDailyUpdateCollection(actionItems, actionItem => {
		if (!actionItem?.referencedEntities?.contacts) {
			return null;
		}
		return actionItem.referencedEntities.contacts.map(entity => entity.entity);
	});
}

function getContactsFromKeyDates(contactsWithKeyFacts: Api.IContactWithKeyFact[]) {
	return getSummaryFromDailyUpdateCollection(contactsWithKeyFacts, contactWithKeyFact => {
		return contactWithKeyFact?.contact;
	});
}

function getContactsFromRecentMeetings(meetings: Api.IMeeting[]) {
	return getSummaryFromDailyUpdateCollection(meetings, meeting => {
		if (!meeting.attendees) {
			return null;
		}
		return meeting.attendees.map(attendee => attendee?.contact);
	});
}

function getContactsFromContactsToClassify(contactsToClassify: Api.IClassifyContact[]) {
	return getSummaryFromDailyUpdateCollection(contactsToClassify, contactToClassify => {
		return contactToClassify?.contact;
	});
}

function getTotalDailyUpdates(updates: Api.IDashboardUpdates) {
	return (
		(updates?.actionItemsDue?.total ?? 0) +
		(updates?.contactsToClassify?.total ?? 0) +
		(updates?.genericKeyDates?.total ?? 0) +
		(updates?.reachOutInfo?.totalCount ?? 0)
	);
}

export function DashboardDailyUpdatesGroup() {
	const userSession = useUserSession();
	const fullScreenModal = useFullscreenModal();
	const { logApiError } = useEventLogging();
	const { status, data } = useDailyUpdates({
		handleError: err => {
			logApiError('LoadDashboardDailyUpdates-Error', err);
		},
		userSession,
	});
	const history = useHistory();
	if (!data && status !== 'success') {
		return (
			<GhostCard stylesDeclarations={[styleSheet.root]} style={{ padding: '1rem 1.25rem' }}>
				<>
					<div style={{ alignItems: 'flex-start', display: 'flex', marginBottom: 14 }}>
						<GhostImage style={{ marginRight: '1rem' }} />{' '}
						<div style={{ alignItems: 'center' }}>
							<GhostHeading style={{ height: 7, marginBottom: 10, marginTop: 5, width: 58 }} />
							<GhostImage style={{ height: 23, width: 30 }} />
						</div>
					</div>
					<div style={{ alignItems: 'flex-start', marginBottom: 14 }}>
						<hr />
					</div>
					<div style={{ alignItems: 'flex-start', display: 'flex', marginBottom: 14 }}>
						<GhostImage style={{ marginRight: '1rem' }} />{' '}
						<div style={{ alignItems: 'center' }}>
							<GhostHeading style={{ height: 14, marginBottom: 10, marginTop: 5, width: 200 }} />
							<GhostHeading style={{ height: 10, marginBottom: 10, width: 370 }} />
						</div>
					</div>
					<div style={{ alignItems: 'flex-start', display: 'flex', marginBottom: 14 }}>
						<GhostImage style={{ marginRight: '1rem' }} />{' '}
						<div style={{ alignItems: 'center' }}>
							<GhostHeading style={{ height: 14, marginBottom: 10, marginTop: 5, width: 170 }} />
							<GhostHeading style={{ height: 10, marginBottom: 10, width: 320 }} />
						</div>
					</div>
					<div style={{ alignItems: 'flex-start', display: 'flex' }}>
						<GhostImage style={{ marginRight: '1rem' }} />{' '}
						<div style={{ alignItems: 'center' }}>
							<GhostHeading style={{ height: 14, marginBottom: 10, marginTop: 5, width: 360 }} />
							<GhostHeading style={{ height: 10, marginBottom: 10, width: 370 }} />
						</div>
					</div>
				</>
			</GhostCard>
		);
	}

	const totalDailyUpdates = getTotalDailyUpdates(data);

	const kitCount = data.reachOutInfo.tagAlertCount + data.reachOutInfo.keepInTouchCount;

	const contactsFromActionItems = getContactsFromActionItems(data.actionItemsDue.actionItems);

	const contactsFromKeyDates = getContactsFromKeyDates(data.genericKeyDates.contacts);

	const contactsFromRecentMeetings = getContactsFromRecentMeetings(data?.recentMeetings?.recentMeetings);

	const contactsFromContactsToClassify = getContactsFromContactsToClassify(data.contactsToClassify?.contactsToClassify);
	const kits = [...(data?.reachOutInfo?.keepInTouchContacts ?? []), ...(data?.reachOutInfo?.tagAlertContacts ?? [])];
	const navigateToActionItems = () => {
		history.push('/notes-action-items?activeTab=action-items');
	};
	const onGenericKeyDatesClick = () => {
		history.push({
			pathname: 'dashboard/upcoming-key-dates',
		});
	};
	const onKITsClick = () => {
		history.push({ pathname: 'dashboard/keep-in-touches' });
	};
	const handleOnSendEmailToAllClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.stopPropagation();
		fullScreenModal?.history.push({
			pathname: '/email/campaigns/create/from-reach-out-info',
			state: {
				model: {
					context: data.reachOutInfo,
					type: 'ReachOutInfo',
				},
			},
		});
	};
	const handleOnRecentMeetingsClick = () => {
		history.push({ pathname: 'dashboard/recent-meetings' });
	};
	const handleOnClassifyContactsClick = () => {
		window.open('/#/people/tagging-game');
	};

	return (
		<DashboardCard styleDeclaration={styleSheet.root}>
			<header className={css(styleSheet.cardHeader)}>
				<button className={css(styleSheet.refreshButton)} onClick={() => invalidateDailyUpdates()}>
					<RefreshIcon fillColor='white' hoverColor='white' width={24} height={24} />
				</button>
				<div className={css(styleSheet.cardHGroup)}>
					<DashboardCardHeaderLabel>Daily Updates</DashboardCardHeaderLabel>
					<DashboardCardStat styleDeclaration={styleSheet.dailyUpdatesNumber}>
						<AnimatedNumber to={totalDailyUpdates} />
					</DashboardCardStat>
				</div>
			</header>

			{data.genericKeyDates.total > 0 ? (
				<DashboardDailyUpdateItem
					icon={
						<CalendarDateIcon
							fillColor='white'
							width={20}
							height={20}
							className={css(styleSheet.dailyUpdateItemIcon)}
						/>
					}
					subtext={contactsFromKeyDates}
					iconBgColor='#c75da6'
					onClick={onGenericKeyDatesClick}
				>
					<AnimatedNumber to={data.genericKeyDates.total} className={css(styleSheet.dailyUpdateItemNumber)} />

					{` key date${data.genericKeyDates.total > 1 ? 's' : ''} coming up`}
				</DashboardDailyUpdateItem>
			) : null}

			{data.actionItemsDue.total > 0 ? (
				<DashboardDailyUpdateItem
					icon={<ActionItemsIcon width={20} height={20} className={css(styleSheet.dailyUpdateItemIcon)} />}
					iconBgColor='#89C947'
					subtext={contactsFromActionItems}
					onClick={navigateToActionItems}
				>
					You have <AnimatedNumber className={css(styleSheet.dailyUpdateItemNumber)} to={data.actionItemsDue.total} />{' '}
					{` action item${data.actionItemsDue.total > 1 ? 's' : ''} due`}
				</DashboardDailyUpdateItem>
			) : null}
			{kitCount > 0 ? (
				<DashboardDailyUpdateItem
					icon={<ClockIcon width={20} height={20} fillColor='white' className={css(styleSheet.dailyUpdateItemIcon)} />}
					iconBgColor='#F89143'
					subtext={
						kits.length > 0 ? (
							<>
								<span>{getSummaryFromContactList(kits)}</span>
								<button
									className={css(baseStyleSheet.brandLink, styleSheet.dailyUpdateItemSendEmailLink)}
									onClick={handleOnSendEmailToAllClick}
								>
									Send email to all
								</button>
							</>
						) : null
					}
					onClick={onKITsClick}
				>
					You are falling out of touch with{' '}
					<AnimatedNumber className={css(styleSheet.dailyUpdateItemNumber)} to={kitCount} />{' '}
					{kitCount > 1 ? `people` : `person`}
				</DashboardDailyUpdateItem>
			) : null}

			{data.recentMeetings != null && data.recentMeetings.total > 0 ? (
				<DashboardDailyUpdateItem
					icon={
						<CalendarDateIcon
							width={20}
							height={20}
							fillColor='white'
							className={css(styleSheet.dailyUpdateItemIcon)}
						/>
					}
					iconBgColor='#00528C'
					subtext={contactsFromRecentMeetings}
					onClick={handleOnRecentMeetingsClick}
				>
					<AnimatedNumber className={css(styleSheet.dailyUpdateItemNumber)} to={data.recentMeetings.total} /> Recent
					meetings with...
				</DashboardDailyUpdateItem>
			) : null}

			{data.contactsToClassify != null && data.contactsToClassify.total > 0 ? (
				<DashboardDailyUpdateItem
					icon={
						<AssigneeIcon width={20} height={20} fillColor='white' className={css(styleSheet.dailyUpdateItemIcon)} />
					}
					iconBgColor='#00AAE8'
					subtext={contactsFromContactsToClassify}
					onClick={handleOnClassifyContactsClick}
				>
					You have
					<AnimatedNumber className={css(styleSheet.dailyUpdateItemNumber)} to={data.contactsToClassify.total} />
					{` new contact${data.contactsToClassify.total > 1 ? 's' : ''} to categorize`}
				</DashboardDailyUpdateItem>
			) : null}
		</DashboardCard>
	);
}
