import moment from 'moment';
import { DateRangeOption, IDayOption } from './models';

export const dayOptions: IDayOption[] = (Object.keys(DateRangeOption) as (keyof typeof DateRangeOption)[]).map(key => {
	const value = DateRangeOption[key];
	if (value === DateRangeOption.Custom) {
		return {
			key,
			label: value,
			value,
		};
	}
	const parts = value.split(' ');
	return {
		key,
		label: value,
		value: moment()
			.subtract(parseInt(parts[0], 10), parts[1].toLowerCase() as moment.unitOfTime.DurationConstructor)
			.format('YYYY-MM-DD'),
	};
});
