import * as Api from '@ViewModels';
import { Industry } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { emailScannerImageUrl, getLogoUrlForIntegrationProvider } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useGetGoogleBusinessMetadata } from '../../../../queries';
import AMS360IconUrl from '../../../assets/ams360.png';
import googleBusinessProfileLogo from '../../../assets/google_business_profile_logo.png';
import hawksoftLogo from '../../../assets/hawksoft_logo.png';
import adwerxLogo from '../../../assets/logo_adwerx.png';
import clioLogo from '../../../assets/logo_clio.png';
import donorPerfectLogo from '../../../assets/logo_donorperfect.png';
import mycaseLogo from '../../../assets/logo_mycase.svg';
import qqLogo from '../../../assets/logo_qq_catalyst.png';
import redtailLogo from '../../../assets/logo_redtail.png';
import wealthboxLogo from '../../../assets/logo_wealthbox.png';
import { IntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	onManageClicked?(integration: Api.IntegrationType | Api.ConfigurableIntegrationType): void;
	styles?: StyleDeclarationValue[];
}

interface IIntegrationCardData {
	canManage?: boolean;
	img?: string;
	name?: Api.ConfigurableIntegrationType;
}

const getIntegrationCardData = (account: Api.IAccount) => {
	const data: IIntegrationCardData[] = [
		{
			img: AMS360IconUrl,
			name: Api.ConfigurableIntegrationType.Ams360,
		},
		{
			img: hawksoftLogo,
			name: Api.ConfigurableIntegrationType.HawkSoft,
		},
		{
			canManage: true,
			img: redtailLogo,
			name: Api.ConfigurableIntegrationType.Redtail,
		},
		{
			canManage: true,
			img: clioLogo,
			name: Api.ConfigurableIntegrationType.Clio,
		},
		{
			canManage: true,
			img: mycaseLogo,
			name: Api.ConfigurableIntegrationType.MyCase,
		},
		{
			canManage: true,
			img: qqLogo,
			name: Api.ConfigurableIntegrationType.QQ,
		},
		{
			canManage: true,
			img: wealthboxLogo,
			name: Api.ConfigurableIntegrationType.Wealthbox,
		},
		{
			canManage: true,
			img: donorPerfectLogo,
			name: Api.ConfigurableIntegrationType.DonorPerfect,
		},
	];

	if (account?.integrations?.mergeAccounting?.enabled) {
		const mergeAccountingProvider = account.integrations.mergeAccounting.mergeAccountingProvider;
		data.push({
			canManage: true,

			img: getLogoUrlForIntegrationProvider(mergeAccountingProvider),
			name: Api.ConfigurableIntegrationType.MergeAccounting,
		});
	}
	if (account?.integrations?.mergeCrm?.enabled) {
		const mergeCrmProvider = account.integrations.mergeCrm.mergeCrmProvider;
		data.push({
			canManage: true,

			img: getLogoUrlForIntegrationProvider(mergeCrmProvider),
			name: Api.ConfigurableIntegrationType.MergeCrm,
		});
	}
	return data;
};

export const IntegrationCards = ({ className, styles, onManageClicked }: IProps) => {
	const userSession = useUserSession();

	const { data: googleBusinessMetadata } = useGetGoogleBusinessMetadata({});
	const isGoogleBusinessEnabled =
		googleBusinessMetadata?.connectionState === Api.GoogleBusinessConnectionState.Connected;

	const onManageClickedHandler = (integration: Api.IntegrationType) => () => {
		onManageClicked?.(integration);
	};

	const getIntegrationCards = () => {
		const accountModel = userSession.account.toJs();
		const isAdmin = userSession.userRole === 'admin' || userSession.userRole === 'superAdmin';
		const configuredIntegrationCardData = getIntegrationCardData(accountModel);

		/** Only return integration cards that account has access to, and that are configured. */
		const configuredIntegrations = Object.entries(accountModel?.integrations || {})
			.filter((entry: [string, Api.IConfigurableIntegration]) => entry[1].configured)
			.map((entry: [string, Api.IConfigurableIntegration]) => {
				const integration = configuredIntegrationCardData.filter(i => i.name === entry[0]);
				if (integration.length) {
					return integration[0].name === Api.ConfigurableIntegrationType.Wealthbox &&
						userSession?.account?.additionalInfo?.industry !== Industry.Financial ? null : (
						<IntegrationCard
							key={integration[0].name}
							comingSoon={false}
							icon={<img className={css(styleSheet.itemIcon)} src={integration[0].img} />}
							styles={[styleSheet.item]}
							canManage={integration[0].canManage}
							enabled={true}
							onManageClicked={onManageClickedHandler(integration[0].name)}
						/>
					);
				}
			});

		/** Only return email scanners that are enabled for account. */
		const scanners = Api.AllEmailScanners.filter(
			emailScanner =>
				!Api.EmailScannersBlockedFromConfiguration.has(emailScanner.id) &&
				accountModel?.features?.emailScan?.enabledScanners?.includes(emailScanner.id as Api.EmailScannerId)
		);

		const configuredEmailScanners = scanners.map(x => {
			return (
				<IntegrationCard
					canManage={isAdmin || Api.VmUtils.isIntegrationEnabledForAccount(x.id, accountModel)}
					enabled={Api.VmUtils.isIntegrationEnabledForUser(x.id, userSession.user, accountModel)}
					icon={<img className={css(styleSheet.itemIcon)} src={emailScannerImageUrl(x.id)} />}
					key={x.id}
					onManageClicked={onManageClickedHandler(x.id)}
					styles={[styleSheet.item]}
				/>
			);
		});

		const userConfiguredIntegrations = Object.entries(Api.UserConfigurableIntegrationType).map(
			(entry: [string, Api.UserConfigurableIntegrationType]) => {
				switch (entry[0]) {
					case Api.UserConfigurableIntegrationType.CRM:
						return (
							<IntegrationCard
								canManage={true}
								enabled={!!userSession?.user?.userPreferences?.bccEmail}
								icon={<div>CRM BCC Integration</div>}
								key='crm-bcc-integration'
								onManageClicked={onManageClickedHandler(Api.UserConfigurableIntegrationType.CRM)}
								styles={[styleSheet.item]}
							/>
						);
					case Api.UserConfigurableIntegrationType.AdWerx:
						return (
							<IntegrationCard
								canManage={true}
								enabled={!!userSession?.user?.userPreferences?.adwerxBccEmail}
								icon={<img className={css(styleSheet.itemIcon)} src={adwerxLogo} />}
								key='adwerx-integration'
								onManageClicked={onManageClickedHandler(Api.UserConfigurableIntegrationType.AdWerx)}
								styles={[styleSheet.item]}
							/>
						);
					case Api.UserConfigurableIntegrationType.GoogleBusiness:
						return (
							<IntegrationCard
								canManage={isAdmin}
								enabled={isGoogleBusinessEnabled}
								icon={<img className={css(styleSheet.itemIcon)} src={googleBusinessProfileLogo} />}
								key='google-business-integration'
								onManageClicked={onManageClickedHandler(Api.UserConfigurableIntegrationType.GoogleBusiness)}
								styles={[styleSheet.item, styleSheet.googleBusinessItem]}
							/>
						);
					case Api.UserConfigurableIntegrationType.PublicAPI: {
						if (!accountModel?.features?.publicApi?.enabled) {
							return null;
						}
						return (
							<IntegrationCard
								canManage={isAdmin}
								key='api-keys-integration-card'
								enabled={accountModel?.features?.publicApi?.enabled}
								icon={<div>API Keys</div>}
								manageLabel='Manage Keys'
								onManageClicked={onManageClickedHandler(Api.UserConfigurableIntegrationType.PublicAPI)}
								styles={[styleSheet.item]}
								status={
									<p className={css(styleSheet.statusMessage)}>
										Manage API Keys to integrate with third party apps (i.e. Zapier)
									</p>
								}
							/>
						);
					}
					default:
						return null;
				}
			}
		);

		return [...userConfiguredIntegrations, ...configuredIntegrations, ...configuredEmailScanners];
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} integration-cards ${className || ''}`}>
			<div className={css(styleSheet.items)}>{getIntegrationCards()}</div>
		</div>
	);
};
