import * as Api from '@ViewModels';
import { History } from 'history';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import {
	AppState,
	ITextMessagingComponentProps,
	IUserSessionComponentProps,
	TextMessagingViewModelKey,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { EventLogger } from '../../../models/Logging';
import { DecafMainContainer } from '../../coffee/containers/DecafMainContainer';
import { AppAutoUpdater } from '../../components/AppAutoUpdater';
import { PrivateRoute, RouteWithLoggedPageView } from '../../components/PrivateRoute';
import { ScrollRestoration } from '../../components/ScrollRestoration';
import { ErrorMessagesProvider } from '../../components/errorMessages/ErrorMessagesProvider';
import { ContactTaggingGame } from '../ContactTaggingGame';
import { ContactTaggingGamePowerUser } from '../ContactTaggingGame/ContactTaggingGamePowerUser';
import { CustomerSessionStart } from '../CustomerSessionStart';
import { LazyLoader } from '../LazyLoader';
import { Login } from '../Login';
import { OAuthLogin } from '../Login/OAuthLogin';
import { MainContainer } from '../MainContainer';
import { NoteEditorContainer } from '../NoteEditorContainer';
import { styleSheet } from './styles';

const LazyUserSetup = React.lazy(() => import(/* webpackChunkName: "user-setup" */ '../user/Setup'));

interface IProps extends IUserSessionComponentProps, ITextMessagingComponentProps {
	className?: string;
	history: History<any>;
}

class _AppRouter extends React.Component<IProps> {
	public render() {
		// Switch renders the first match.
		// '/' route path should push to '/login'... where login route should push to '/dashboard' after successful auth check
		// PrivateRoutes like '/dashboard' will push to '/login' if not authenticated
		// Use mobx-react Provider to push AppState context to all components that use @Inject(...)
		const { userSession, history } = this.props;
		return (
			<Router history={history}>
				{!!AppState.errorMessages && <ErrorMessagesProvider errorMessageViewModel={AppState.errorMessages} />}
				<Switch>
					<Redirect exact={true} from='/' to='/login' />
					<Redirect exact={true} from='/boards' to='/dashboard' />
					{!userSession?.account?.features?.automation?.enabled && (
						<Redirect exact={true} from='/automations' to='/dashboard' />
					)}
					<RouteWithLoggedPageView path='/customerSessionStart'>
						<CustomerSessionStart redirection='/dashboard' userSession={this.props.userSession} />
					</RouteWithLoggedPageView>
					<Route path='/login' render={this.onRenderLogin} />
					<Route path='/oauth/login' render={this.onRenderOAuthLogin} />
					<Route path='/user'>
						<LazyLoader loadingContainerStyles={[styleSheet.userSetupLoading]}>
							<LazyUserSetup />
						</LazyLoader>
					</Route>
					<PrivateRoute path='/notes/note-editor' render={this.onRenderNoteEditor} userSession={userSession} />
					<PrivateRoute path='/people/tagging-game' render={this.onRenderTaggingGame} userSession={userSession} exact />
					<PrivateRoute
						path='/people/tagging-game/power-user'
						render={this.onRenderPowerUserTaggingGame}
						userSession={userSession}
						exact
					/>
					<PrivateRoute userSession={userSession}>
						{userSession?.levitateUIStimulant === Api.LevitateUIStimulant.Decaf ? (
							<DecafMainContainer />
						) : (
							<MainContainer />
						)}
					</PrivateRoute>
				</Switch>
				<AppAutoUpdater />
				<ScrollRestoration />
			</Router>
		);
	}

	private onRenderTaggingGame = (props: RouteComponentProps<any>) => {
		EventLogger.logPageView('contact-tagging-game');
		return <ContactTaggingGame {...props} />;
	};

	private onRenderPowerUserTaggingGame = () => {
		EventLogger.logPageView('contact-tagging-game-ai-mode');
		return <ContactTaggingGamePowerUser />;
	};

	private onRenderNoteEditor = (props: RouteComponentProps<any>) => {
		EventLogger.logPageView('note-editor');
		return <NoteEditorContainer {...props} />;
	};

	private onRenderLogin = (props: RouteComponentProps<any>) => {
		// Log the page view in analytics
		EventLogger.logPageView('Login');
		return <Login {...props} loginMode='standard' userSession={this.props.userSession} />;
	};

	private onRenderOAuthLogin = (props: RouteComponentProps<any>) => {
		return <OAuthLogin {...props} userSession={this.props.userSession} />;
	};
}

const AppRouterAsObserver = observer(_AppRouter);
const AppRouterWithContext = inject(UserSessionViewModelKey, TextMessagingViewModelKey)(AppRouterAsObserver);

// eslint-disable-next-line import/no-default-export
export default AppRouterWithContext;
