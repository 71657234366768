import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, DropResult, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { IDictionary, IOperationResultNoValue } from '../../../../extViewmodels/sdk/models';
import {
	IBoardShowArchivedStorage,
	IModalContext,
	ModalChildComponentContextKey,
	PersistentStorageBoardShowArchivedKey,
} from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { EventLogger } from '../../../../models/Logging';
import { PersistentStorageManager } from '../../../../models/Storage';
import {
	BoardStageDefinitionViewModel,
	BoardStageViewModel,
	BoardViewModel,
	EditBoardViewModel,
} from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { AddButton } from '../../AddButton';
import { Button } from '../../Button';
import { Checkbox } from '../../Checkbox';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { asModalComponent } from '../../Modal';
import { TextInput } from '../../TextInput';
import { DeleteStageConfirmationModal } from '../DeleteStageConfirmation';
import { EditBoardColumnDefinition } from '../EditBoardColumnDefinition';
import { styleSheet } from './styles';

interface IProps extends IModalContext<BoardViewModel>, IUserSessionComponentProps, IErrorMessageComponentProps {
	board?: BoardViewModel;
	className?: string;
	initialColumns?: BoardStageDefinitionViewModel[];
	onCreateBoard?(name: string, columns: BoardStageDefinitionViewModel[]): Promise<BoardViewModel>;
	onEditBoard?(
		board: BoardViewModel,
		name: string,
		columns: BoardStageDefinitionViewModel[],
		showArchived: boolean
	): Promise<BoardViewModel>;
}
interface IEditBoardColumnsContext {
	createBoardVm?: EditBoardViewModel;
	provided?: DroppableProvided;
	snapshot?: DroppableStateSnapshot;
}

interface IEditBoardColumnsProps extends IEditBoardColumnsContext {
	className?: string;
	onRequestRemoveColumnDefinition?(columnDefinition: BoardStageDefinitionViewModel): void;
}

const EditBoardColumns: React.FC<IEditBoardColumnsProps> = ({
	createBoardVm,
	provided,
	onRequestRemoveColumnDefinition,
}) => {
	const bottomDivRef = useRef<HTMLDivElement>(null);

	const scrollToBottom = useCallback(() => {
		requestAnimationFrame(() => {
			if (bottomDivRef.current) {
				bottomDivRef.current.scrollIntoView();
			}
		});
	}, []);

	const onRequestAdd = useCallback(() => {
		EventLogger.logInput('EditBoard', 'AddStage', 'Click');
		createBoardVm.insertColumn(new BoardStageDefinitionViewModel(), createBoardVm.columns.length);
		scrollToBottom();
	}, [createBoardVm, scrollToBottom]);

	const onRequestRemove = useCallback(
		(index: number) => () => {
			const columnDefinition = createBoardVm.columns[index];
			EventLogger.logInput('EditBoard', 'RemoveStage', 'Click');
			if (onRequestRemoveColumnDefinition) {
				onRequestRemoveColumnDefinition(columnDefinition);
			} else {
				createBoardVm.removeColumnAtIndex(index);
			}
		},
		[createBoardVm, onRequestRemoveColumnDefinition]
	);

	const onNameChanged = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			createBoardVm.setName(e.target.value);
		},
		[createBoardVm]
	);

	return (
		<div className={css(styleSheet.columns)} ref={provided.innerRef} {...provided.droppableProps}>
			<div>
				<label htmlFor='create-board-name' className={css(styleSheet.nameLabel)}>
					Board Name
				</label>
				<TextInput
					className={css(styleSheet.nameInput)}
					inputId='create-board-name'
					onChange={onNameChanged}
					type='text'
					value={createBoardVm.name}
				/>
			</div>

			{createBoardVm.columns.map((x, i) => (
				<EditBoardColumnDefinition stageDefinition={x} index={i} key={x.uuid} onRequestRemove={onRequestRemove(i)} />
			))}
			<div className={css(styleSheet.columnsFooter)}>
				<AddButton title='Add Column' onClick={onRequestAdd} />
			</div>
			{provided.placeholder}
			<div ref={bottomDivRef} />
		</div>
	);
};

const EditKanbanBoardColumnsAsObserver = observer(EditBoardColumns);

const EditKanbanBoard: React.FC<IProps> = ({
	userSession,
	board,
	initialColumns,
	parentModal,
	onEditBoard,
	onCreateBoard,
	errorMessages,
	className = '',
}) => {
	const [showArchived, setShowArchived] = useState(false);
	const [editBoardVm, setEditBoardVm] = useState<EditBoardViewModel>();
	const [queuedItemMoves, setQueuedItemMoves] = useState<IDictionary<BoardStageViewModel>>({});
	const [saving, setSaving] = useState(false);
	const [stageToDelete, setStageToDelete] = useState<BoardStageViewModel>();

	useEffect(() => {
		const vm = new EditBoardViewModel(
			userSession,
			board ? board.name : 'Opportunities',
			board ? board.stages.map(x => x.toColumnDefinition()) : initialColumns
		);
		setEditBoardVm(vm);

		PersistentStorageManager.local
			.getObject<IBoardShowArchivedStorage>(PersistentStorageBoardShowArchivedKey)
			.then(storedValue => {
				setShowArchived(storedValue || false);
			});
	}, [userSession, board, initialColumns]);

	const onRequestClose = useCallback(
		(resultBoard?: BoardViewModel, canceled?: boolean) => {
			if (parentModal) {
				parentModal.onRequestClose(resultBoard, canceled);
			}
		},
		[parentModal]
	);

	const onCancel = useCallback(() => {
		onRequestClose(null, true);
	}, [onRequestClose]);

	const onToggleShowArchivedColumn = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setShowArchived(event.target.checked);
	}, []);

	const onColumnDeleteConfirmationRequestClose = useCallback(
		(toStage?: BoardStageViewModel, canceled?: boolean) => {
			if (!!toStage && !canceled && editBoardVm) {
				editBoardVm.removeColumnAtIndex(editBoardVm.columns.findIndex(x => x.stage === stageToDelete));

				setQueuedItemMoves(prev => {
					const next = { ...prev };
					next[stageToDelete.id] = toStage;

					Object.keys(next).forEach(key => {
						if (key !== stageToDelete.id && next[key] === stageToDelete) {
							next[key] = toStage;
						}
					});

					return next;
				});
			}
			setStageToDelete(null);
		},
		[editBoardVm, stageToDelete]
	);

	const onRequestRemoveColumnDefinition = useCallback(
		(columnDefinition: BoardStageDefinitionViewModel) => {
			if (!!columnDefinition.stage && !!columnDefinition.stage.items && columnDefinition.stage.items.length > 0) {
				setStageToDelete(columnDefinition.stage);
			} else {
				editBoardVm?.removeColumnAtIndex(editBoardVm.columns.indexOf(columnDefinition));
			}
		},
		[editBoardVm]
	);

	const save = useCallback(() => {
		let isEdit = true;
		let promise =
			!!board && !!onEditBoard ? onEditBoard(board, editBoardVm.name, editBoardVm.columns, showArchived) : null;

		if (!promise) {
			isEdit = false;
			promise = !board && !!onCreateBoard ? onCreateBoard(editBoardVm.name, editBoardVm.columns) : null;
		}

		if (promise) {
			setSaving(true);
			EventLogger.logEvent({
				action: `${isEdit ? 'Edit' : 'Create'}`,
				category: 'EditBoard',
			});

			promise
				.then(editedBoard => {
					setSaving(false);
					onRequestClose(editedBoard, false);
				})
				.catch((error: IOperationResultNoValue) => {
					setSaving(false);
					errorMessages.push({
						messages: [error.systemMessage],
					});

					EventLogger.logEvent(
						{
							action: `${isEdit ? 'Edit' : 'Create'}-Error`,
							category: 'EditBoard',
						},
						{ ...error }
					);
				});
		}

		return promise;
	}, [board, onEditBoard, onCreateBoard, editBoardVm, showArchived, errorMessages, onRequestClose]);

	const moveItems = useCallback(() => {
		if (!!queuedItemMoves && !!board && !!editBoardVm) {
			const idsOfDeletedStages = Object.keys(queuedItemMoves);
			if (idsOfDeletedStages.length > 0) {
				setSaving(true);

				const stagesToResetAndReload = new Set<BoardStageViewModel>();
				const stagesToReset = new Set<BoardStageViewModel>();
				const promises = idsOfDeletedStages.map(id => {
					const fromStage = board.stages.find(x => x.id === id);
					const toStage = queuedItemMoves[id];
					EventLogger.logEvent(
						{
							action: 'MoveItems',
							category: 'EditBoard',
						},
						{ fromStageId: fromStage.id, toStageId: toStage.id }
					);
					stagesToResetAndReload.add(toStage);
					stagesToReset.add(fromStage);
					return editBoardVm.moveStageItems(fromStage, toStage);
				});

				const promise = Promise.all(promises).then(() => {
					const savePromise = save();
					if (savePromise) {
						savePromise.then(() => {
							Array.from(stagesToResetAndReload).forEach(x => {
								x.reset();
								x.getItems();
							});

							Array.from(stagesToReset).forEach(x => {
								x.reset();
							});
						});
					}
				});

				promise.catch((error: IOperationResultNoValue) => {
					errorMessages.push({
						messages: [error.systemMessage],
					});

					EventLogger.logEvent(
						{
							action: 'MoveItems-Error',
							category: 'EditBoard',
						},
						{ ...error }
					);
				});
				return promise;
			}
		}

		return save();
	}, [queuedItemMoves, board, editBoardVm, errorMessages, save]);

	const onSaveClicked = useCallback(() => {
		PersistentStorageManager.local.setObject<IBoardShowArchivedStorage>(
			PersistentStorageBoardShowArchivedKey,
			showArchived
		);
		moveItems();
	}, [showArchived, moveItems]);

	const onDragEnd = useCallback(
		(result: DropResult) => {
			if (!!result.destination && result.source.index !== result.destination.index) {
				editBoardVm?.moveColumn(result.source.index, result.destination.index);
			}
		},
		[editBoardVm]
	);

	if (!editBoardVm) {
		return null;
	}

	const validMoveTargets = editBoardVm.columns
		.map(x => {
			if (!!x.stage && !!x.stage.id) {
				if (!stageToDelete || (!!stageToDelete && stageToDelete.id !== x.stage.id)) {
					return x.stage;
				}
			}
			return null;
		})
		.filter(x => !!x);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div className={`${css(styleSheet.board)} ${className}`}>
				<div className={css(styleSheet.boardHeader)}>
					<DeprecatedCloseButton onClick={onCancel} />
					<div className={css(styleSheet.boardHeaderTitles)}>
						<div className={css(styleSheet.boardHeaderTitle)}>{`${board ? 'Edit' : 'Create'} Board`}</div>
						<div className={css(styleSheet.boardHeaderSubheader)}>
							{!board && "We've generated a few columns as an example to keep track of your opportunities.\n\r"}
							You may rename, reorder, add, and delete columns.
						</div>
					</div>
				</div>
				<div className={css(styleSheet.body)}>
					<Droppable direction='vertical' droppableId={editBoardVm.id}>
						{(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => (
							<EditKanbanBoardColumnsAsObserver
								createBoardVm={editBoardVm}
								onRequestRemoveColumnDefinition={onRequestRemoveColumnDefinition}
								provided={droppableProvided}
								snapshot={droppableSnapshot}
							/>
						)}
					</Droppable>
					<div className={css(styleSheet.checkboxSection, styleSheet.archiveCheckbox)}>
						<Checkbox id='show-archived-checkbox' onChange={onToggleShowArchivedColumn} checked={showArchived}>
							<span>Show archived opportunities</span>
						</Checkbox>
					</div>
				</div>
				<div className={css(styleSheet.footer)}>
					<Button kind='primary' onClick={onSaveClicked}>
						Save
					</Button>
					<Button kind='reverse' onClick={onCancel}>
						Cancel
					</Button>
				</div>
				{!!saving && <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />}
				<DeleteStageConfirmationModal
					options={validMoveTargets}
					stage={stageToDelete}
					modalProps={{
						isOpen: !!stageToDelete,
						onRequestClose: onColumnDeleteConfirmationRequestClose,
						shouldCloseOnOverlayClick: false,
					}}
				/>
			</div>
		</DragDropContext>
	);
};

const EditBoardAsObserver = observer(EditKanbanBoard);
export const EditBoard = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ModalChildComponentContextKey
)(EditBoardAsObserver);
export const EditBoardModal = asModalComponent(EditBoard, {
	className: 'edit-kanban-board-modal',
	isOpen: false,
});
