import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../../../styles/styles';
import { DeprecatedCloseButton } from '../../../DeprecatedCloseButton';
import { DeprecatedSelect, ISelectOption } from '../../../DeprecatedSelect';
import { Modal } from '../../../Modal';
import { styleSheet } from './styles';

interface IProps {
	stages: Api.IBoardStage[];
	archivedStage?: Api.IBoardStage;
	isOpen: boolean;
	item: Api.IOpportunity;
	onRequestClose: () => void;
	onSave: (selectedItem: Api.IOpportunity, selectedStage: Api.IBoardStage) => void;
}

export const UpdateStageModal: React.FC<IProps> = ({
	stages = [],
	archivedStage,
	isOpen,
	item,
	onRequestClose,
	onSave,
}) => {
	const currentStage = stages.find(x => x.id === item?.boardStage?.id);
	const isArchived = Boolean(archivedStage);

	const [selectedStage, setSelectedStage] = React.useState<ISelectOption<string>>(
		currentStage
			? {
					dataContext: currentStage.id,
					id: currentStage.id,
					text: currentStage.name,
				}
			: null
	);

	const stageOptions: ISelectOption<string>[] = stages.map(stage => ({
		dataContext: stage.id,
		id: stage.id,
		text: stage.name,
	}));

	const handleSelectedStage = (option: ISelectOption<string>) => {
		setSelectedStage(option);
	};

	const handleSaveChangeStage = () => {
		onRequestClose();
		onSave(
			item,
			stages.find(x => x.id === selectedStage.id)
		);
	};

	return (
		<Modal useDefaultHeader={false} shouldCloseOnOverlayClick={false} isOpen={isOpen}>
			<header className={css(styleSheet.modalHeader)}>
				<h1 className={css(styleSheet.modalHeaderTitle)}>{isArchived ? 'Restore ' : 'Change '} Stage</h1>
				<DeprecatedCloseButton onClick={onRequestClose} />
			</header>

			<div>
				<DeprecatedSelect
					dropdownStyles={[styleSheet.select]}
					onOptionClick={handleSelectedStage}
					options={stageOptions}
					selectedOption={selectedStage}
					renderOption={(option, _, __, asTrigger) => {
						const isArchivedOption = isArchived && !option?.id;
						const isCurrent = currentStage?.id === option?.id || isArchivedOption;
						const optionText = isArchivedOption ? archivedStage.name : option?.text;
						return (
							<div className={css(!asTrigger ? styleSheet.selectOptionContainer : null)}>
								<span className={css(styleSheet.selectOption)}>{optionText}</span>
								{isCurrent ? <span className={css(styleSheet.selectOptionCurrent)}> (Current Stage)</span> : null}
							</div>
						);
					}}
				/>
				<small className={css(styleSheet.selectInfo)}>
					<em>Select stage to move opportunity to</em>
				</small>
			</div>

			<footer className={css(styleSheet.modalFooter)}>
				<button className={css(baseStyleSheet.ctaButton)} disabled={!selectedStage} onClick={handleSaveChangeStage}>
					Save
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onRequestClose}>
					Cancel
				</button>
			</footer>
		</Modal>
	);
};
