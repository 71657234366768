import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'board/company/companyId/opportunities';

export function useGetOpportunitiesByCompany({
	companyId,
	onError,
	impersonationContext,
	enabled = true,
	refetchOnWindowFocus = false,
}: {
	companyId: string;
	onError?: (err: Api.IOperationResultNoValue) => void;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: !!companyId && enabled,
		onError,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<Api.IOpportunity[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `company/${companyId}/opportunities`,
					impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, companyId, impersonationContext],
		refetchOnWindowFocus,
	});
}

export const invalidateGetOpportunitiesByCompany = ({
	companyId,
	impersonationContext,
}: {
	companyId: string;
	impersonationContext?: Api.IImpersonationContext;
}) => reactQueryClient.invalidateQueries([BASE_QUERY_KEY, companyId, impersonationContext]);

export const invalidateAllGetOpportunitiesByCompany = () => reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
