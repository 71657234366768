import { StyleSheet } from 'aphrodite';
import { background, black, borderColor, brandPrimary, header, mention, success, white } from '../../../styles/colors';

const borderStyle = `1px solid ${borderColor}`;

const cardHeight = 65;
const cardWidth = 350;

export const styleSheet = StyleSheet.create({
	boardLink: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: '12px 16px 12px 16px',
		gap: 8,
		textDecoration: 'none',
		color: black,
	},
	boardTitle: {
		margin: 0,
	},
	newBoardTitle: {
		margin: 0,
		color: brandPrimary,
	},
	boardTypeTitle: {
		marginTop: 12,
		marginBottom: 0,
	},
	boardRowHeader: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 0 20%',
		marginBottom: 16,
	},
	boardIconContainer: {
		height: 40,
		width: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
	},
	greenBackground: {
		backgroundColor: success,
	},
	heartIcon: {
		fill: white,
	},
	boardIcon: {
		height: 16,
		width: 21,
	},
	card: {
		position: 'relative',
		height: cardHeight,
		width: cardWidth,
		display: 'flex',
		textAlign: 'center',
		border: borderStyle,
		background: white,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
	},
	cardsContainer: {
		display: 'flex',
		flex: '1',
		gap: 12,
		flexWrap: 'wrap',
	},
	cardsRow: {
		display: 'flex',
		flexDirection: 'column',
		padding: 24,
	},
	container: {
		background,
		height: '100%',
	},
	description: {
		color: header,
		fontSize: 14,
	},
	text: {
		padding: 24,
	},
	moreMenuContainer: {
		position: 'absolute',
		right: 8,
		top: 8,
	},
	newBoardCard: {
		alignItems: 'center',
		background: mention,
		border: `2px dashed ${brandPrimary}`,
		borderRadius: '6px',
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		cursor: 'pointer',
		opacity: 1,
		textAlign: 'center',
		height: cardHeight,
		width: cardWidth,
	},
	separator: {
		borderBottom: `2px solid ${borderColor}`,
	},
});
