import {
	ContactViewModel,
	IOperationResultNoValue,
	IRichContentEditorState,
	ITemplate,
	TemplateType,
	TemplatesViewModel,
} from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IComposerContent, SingleEmailGuidedComposerStep } from '../../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	createRichContentEditorStateWithText,
	truncateTextToLength,
} from '../../../../../models/UiUtils';
import { titles } from '../../../../styles/colors';
import { baseStyleSheet, textFieldPadding } from '../../../../styles/styles';
import { DeprecatedCloseButton } from '../../../DeprecatedCloseButton';
import { DeprecatedPopover, PopoverType } from '../../../DeprecatedPopover';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { TextArea } from '../../../TextArea';
import { TextInput } from '../../../TextInput';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../../richContent/RichContentDocumentEditor';
import { BoatIcon } from '../../../svgs/icons/BoatIcon';
import { SvgIcon } from '../../../svgs/icons/SvgIcon';
import { styleSheet } from './styles';

const DefaultEditorConfig: IRichContentDocumentEditorConfig = {
	contentRawCss: `
		body {
			background: transparent;
			color: ${titles};
			font-family:"Sora",sans-serif;
			font-size: 14px;
			margin: 0;
			padding: ${textFieldPadding}px;
		}
	`,
	minHeight: 100,
	plugins: ['paste'],
	toolbar: false,
};

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps {
	className?: string;
	contact: ContactViewModel;
	onComplete?(content: IComposerContent): void;
	onSkipButtonClicked?(content: IComposerContent): void;
	onStepChanged?(step: SingleEmailGuidedComposerStep): void;
	step?: SingleEmailGuidedComposerStep;
}

interface IState {
	conclusion?: string;
	keyFacts?: string;
	loadingTemplate?: boolean;
	personalBusinessUpdate?: IRichContentEditorState;
	personalBusinessUpdateTemplate?: ITemplate;
	showPopover?: boolean;
	step?: SingleEmailGuidedComposerStep;
	subject?: string;
}

class _SingleEmailGuidedComposer extends React.Component<IProps, IState> {
	private static maxPreviewCharacterCount = 600;
	public static defaultProps: Partial<IProps> = {
		step: SingleEmailGuidedComposerStep.Subject,
	};

	private mTemplates: TemplatesViewModel;
	public readonly state: IState = {
		showPopover: this.props.step > SingleEmailGuidedComposerStep.KeyFacts,
	};

	public UNSAFE_componentWillMount() {
		this.mTemplates = new TemplatesViewModel(this.props.userSession);
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className } = this.props;
		const { step } = this.state;
		return (
			<div className={`${css(styleSheet.container)} single-email-guided-composer ${className || ''}`}>
				<div className={css(styleSheet.body)}>
					{this.renderSubjectInput()}
					{this.renderKeyFactsInput()}
					{this.renderPresonalBusinessUpdate()}
					{this.renderConclusion()}
				</div>
				<div className={css(styleSheet.footer)}>
					<button className={css(styleSheet.nextButton)} onClick={this.onNextButtonClicked}>
						<span>{`${step === SingleEmailGuidedComposerStep.Conclusion ? 'Done & Review' : 'Next'}`}</span>
					</button>
					{step !== SingleEmailGuidedComposerStep.Conclusion && (
						<button className={css(styleSheet.skipButton)} onClick={this.onSkipButtonClicked}>
							<span>Skip this guide</span>
						</button>
					)}
				</div>
			</div>
		);
	}

	private renderSubjectInput() {
		const { step, subject } = this.state;
		if (step !== SingleEmailGuidedComposerStep.Subject) {
			return null;
		}

		return (
			<div>
				<div className={css(styleSheet.stepTitle)}>Let&apos;s start by coming up with a great subject line.</div>
				<TextInput
					className={css(styleSheet.subjectField)}
					inputId='single-email-guided-composer-subject-input'
					onChange={this.onSubjectChanged}
					type='text'
					value={subject || ''}
					leftAccessory={
						<div className={css(styleSheet.subjectFieldLeftAccessory)}>
							<span>Subject:</span>
							<span>*</span>
						</div>
					}
				/>
				<div className={css(styleSheet.subjectDescription, styleSheet.popoverContent)}>
					<div>
						<div className={css(styleSheet.popoverMessageHeaderTitle)}>
							Subject: Think of something short and sweet, or maybe something attention-grabbing.
						</div>
						<div className={css(styleSheet.descriptionExamples)}>
							<div>Examples:</div>
							<div>&quot;Just checking in&quot;</div>
							<div>{'"How\'s it going?"'}</div>
							<div>{'"It\'s been a while..."'}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private renderKeyFactsInput() {
		const { step, keyFacts } = this.state;
		if (step !== SingleEmailGuidedComposerStep.KeyFacts) {
			return null;
		}

		return (
			<div>
				<div className={css(styleSheet.stepTitle)}>Ok, let&apos;s get to the email.</div>
				<div className={css(styleSheet.bodyText, styleSheet.firstLine)}>{this.greeting}</div>
				<div className={css(styleSheet.keyFactsDescription, styleSheet.popoverContent)}>
					<div className={css(styleSheet.popoverMessageHeader)}>
						<SvgIcon height={25} width={39}>
							<g fill='none'>
								<circle cx='3.184' cy='3.184' r='3.184' fill='#CAE0EC' />
								<circle cx='3.184' cy='12.351' r='3.184' fill='#9EC5DA' />
								<rect width='27.857' height='4.776' x='11.143' y='.796' fill='#CAE0EC' />
								<rect width='27.857' height='4.776' x='11.143' y='9.964' fill='#9EC5DA' />
								<circle cx='3.184' cy='21.107' r='3.184' fill='#CAE0EC' />
								<rect width='27.857' height='4.776' x='11.143' y='18.719' fill='#CAE0EC' />
							</g>
						</SvgIcon>
						<div className={css(styleSheet.popoverMessageHeaderTitle)}>
							Step 1: Ask about something that shows you remember key facts about them
						</div>
					</div>
					<div className={css(styleSheet.descriptionExamples, styleSheet.keyFactsExamples)}>
						How&apos;s their spouse or children? What&apos;s a common hobby?
					</div>
				</div>
				<TextArea
					inputClassName={css(styleSheet.keyFactsInput)}
					inputId='single-email-guided-composer-key-facts-input'
					onChange={this.onKeyFactsChanged}
					value={keyFacts || ''}
				/>
			</div>
		);
	}

	private renderPresonalBusinessUpdate() {
		const { step, keyFacts, personalBusinessUpdate, showPopover, loadingTemplate } = this.state;
		if (step !== SingleEmailGuidedComposerStep.PersonalBusinessUpdate) {
			return null;
		}

		let truncatedKeyFacts = keyFacts || '';
		if (truncatedKeyFacts.length > _SingleEmailGuidedComposer.maxPreviewCharacterCount) {
			truncatedKeyFacts = `${truncateTextToLength(
				truncatedKeyFacts,
				_SingleEmailGuidedComposer.maxPreviewCharacterCount,
				5
			)}...`;
		}

		const popoverAnchor = (
			<div className={css(styleSheet.personalUpdateInputContainer)}>
				<RichContentDocumentEditor
					className={css(baseStyleSheet.textField, styleSheet.personalUpdateInput)}
					config={DefaultEditorConfig}
					contentState={personalBusinessUpdate}
					disablePasteRichText={true}
					onContentStateChanged={this.onPersonalUpdateChanged}
				/>
				{!!loadingTemplate && <LoadingSpinner className={css(styleSheet.templateLoading)} type='small' />}
			</div>
		);

		return (
			<div>
				<div className={css(styleSheet.stepTitle)}>Add something personal...</div>
				<div className={css(styleSheet.bodyText, styleSheet.firstLine)}>{this.greeting}</div>
				<div className={css(styleSheet.bodyText, styleSheet.keyFactsPreview)}>{truncatedKeyFacts}</div>
				<DeprecatedPopover
					anchor={popoverAnchor}
					isOpen={!!showPopover}
					place='right'
					preferredPlacement='right'
					type={PopoverType.emailGuide}
				>
					<div className={css(styleSheet.popoverContent, styleSheet.popoverContent, styleSheet.floatingPopover)}>
						<div className={css(styleSheet.popoverHeader)}>
							<DeprecatedCloseButton
								className={css(styleSheet.popoverCloseButton)}
								fillColor='#fff'
								onClick={this.dismissPopover}
							/>
						</div>
						<div className={css(styleSheet.popoverMessageHeader)}>
							<BoatIcon />
							<div>
								<div className={css(styleSheet.popoverMessageHeaderTitle)}>
									Step 2: Provide a business / personal update
								</div>
								<div>(Bonus: reference a joint interest)</div>
							</div>
						</div>
						<div className={css(styleSheet.descriptionExamples, styleSheet.personalUpdatePopoverExamples)}>
							<div>What’s new, professionally or personally? Don’t over think it, make it short.</div>
							<div>
								{
									'Example: "I have been crazy busy lately, but I guess that\'s a good thing because that means the business is growing!"'
								}
							</div>
						</div>
					</div>
				</DeprecatedPopover>
			</div>
		);
	}

	private renderConclusion() {
		const { step, keyFacts, personalBusinessUpdate, conclusion, showPopover } = this.state;
		if (step !== SingleEmailGuidedComposerStep.Conclusion) {
			return null;
		}

		const truncatedKeyFacts = `${truncateTextToLength(
			keyFacts || '',
			_SingleEmailGuidedComposer.maxPreviewCharacterCount,
			5
		)}...`;
		const truncatedPersonalUpdate = `${truncateTextToLength(
			(personalBusinessUpdate || createRichContentEditorStateWithText('')).getPlainTextPreview(false) || '',
			_SingleEmailGuidedComposer.maxPreviewCharacterCount,
			5
		)}...`;

		const popoverAnchor = (
			<TextArea
				inputClassName={css(styleSheet.conclusionInput)}
				inputId='single-email-guided-composer-conclusion-input'
				onChange={this.onConclusionChanged}
				value={conclusion || ''}
			/>
		);

		return (
			<div>
				<div className={css(styleSheet.stepTitle)}>... and now let&apos;s wrap up</div>
				<div className={css(styleSheet.bodyText, styleSheet.firstLine)}>{this.greeting}</div>
				<div className={css(styleSheet.bodyText, styleSheet.keyFactsPreview)}>{truncatedKeyFacts}</div>
				<div className={css(styleSheet.bodyText, styleSheet.personalUpdatePreview)}>{truncatedPersonalUpdate}</div>
				<DeprecatedPopover
					anchor={popoverAnchor}
					isOpen={!!showPopover}
					place='right'
					preferredPlacement='right'
					type={PopoverType.emailGuide}
				>
					<div className={css(styleSheet.popoverContent, styleSheet.floatingPopover)}>
						<div className={css(styleSheet.popoverHeader)}>
							<DeprecatedCloseButton
								className={css(styleSheet.popoverCloseButton)}
								fillColor='#fff'
								onClick={this.dismissPopover}
							/>
						</div>
						<div className={css(styleSheet.popoverMessageHeader)}>
							<SvgIcon height={41} width={40}>
								<g fill='none' fillRule='evenodd'>
									<ellipse cx='20' cy='20.28' fill='#CAE0EC' rx='19.669' ry='19.944' />
									<ellipse cx='20' cy='20.28' fill='#FFF' rx='15.635' ry='15.854' />
									<polygon
										fill='#CAE0EC'
										points='19.339 15.72 31.825 15.72 31.825 16.84 19.339 16.84'
										transform='rotate(-36 25.582 16.28)'
									/>
									<polygon
										fill='#9EC5DA'
										points='10.549 23.127 19.444 23.178 19.45 24.296 10.555 24.245'
										transform='rotate(-36.36 15 23.711)'
									/>
									<ellipse cx='20' cy='20.28' fill='#9EC5DA' rx='1.657' ry='1.681' />
								</g>
							</SvgIcon>
							<div>
								<div className={css(styleSheet.popoverMessageHeaderTitle)}>
									Step 3: Wrap up, and don&apos;t ask for anything (including time).
								</div>
							</div>
						</div>
						<div className={css(styleSheet.descriptionExamples, styleSheet.personalUpdatePopoverExamples)}>
							<div>Example: &quot;Anyway I just wanted to say hi and hope things are going well!&quot;</div>
						</div>
					</div>
				</DeprecatedPopover>
			</div>
		);
	}

	@computed
	private get greeting() {
		const { contact } = this.props;
		const name = contact.firstName || contact.lastName || '';
		return `Hi${name ? ` ${name}` : ''},`;
	}

	private loadPersonalBusinessUpdateTemplate = () => {
		const { logApiError, logEvent } = this.props;
		this.mTemplates.reset();
		const promise = this.mTemplates.personalBusinessUpdateTemplates.getNext();
		if (promise) {
			logEvent('LoadPersonalBusinessUpdateTemplates');
			this.setState({
				loadingTemplate: true,
			});
			promise
				.then(() => {
					const template = this.mTemplates.personalBusinessUpdateTemplates.fetchResults.backingArray[0];
					const { step, personalBusinessUpdate } = this.state;
					const nextState: IState = {
						loadingTemplate: false,
					};
					if (step === SingleEmailGuidedComposerStep.PersonalBusinessUpdate) {
						if (template) {
							nextState.personalBusinessUpdate = personalBusinessUpdate
								? personalBusinessUpdate
								: template.content
									? convertRawRichTextContentStateToRichContentEditorState(template.content)
									: createRichContentEditorStateWithText();
							nextState.personalBusinessUpdateTemplate = template;
						} else {
							nextState.personalBusinessUpdate = createRichContentEditorStateWithText('');

							nextState.personalBusinessUpdateTemplate = null;
						}
					}
					this.setState(nextState);
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('LoadPersonalBusinessUpdateTemplates-Error', error);
					this.setState({
						loadingTemplate: false,
					});
				});
		}
	};

	private savePersonalBusinessUpdateTemplate = () => {
		const { logApiError, logEvent } = this.props;
		const { personalBusinessUpdate, personalBusinessUpdateTemplate } = this.state;
		if (personalBusinessUpdate) {
			const templateToSave: ITemplate = personalBusinessUpdateTemplate
				? { ...personalBusinessUpdateTemplate }
				: {
						templateType: TemplateType.PersonalBusinessUpdate,
					};
			templateToSave.content = personalBusinessUpdate.getRawRichTextContent();
			const promise = templateToSave.id
				? this.mTemplates.update(templateToSave)
				: this.mTemplates.create(templateToSave);
			if (promise) {
				const action = `${templateToSave.id ? 'Update' : 'Create'}PersonalBusinessUpdateTemplate`;

				logEvent(action);
				promise.catch((error: IOperationResultNoValue) => {
					logApiError(`${action}-Error`, error);
				});
			}
		}
	};

	private dismissPopover = () => {
		this.setState({
			showPopover: false,
		});
	};

	private onConclusionChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({
			conclusion: (e.target as HTMLTextAreaElement).value,
		});
	};

	private onPersonalUpdateChanged = (editorState: IRichContentEditorState) => {
		this.setState({
			personalBusinessUpdate: editorState,
		});
	};

	private onKeyFactsChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({
			keyFacts: (e.target as HTMLTextAreaElement).value,
		});
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		const nextState: IState = {};
		const { step } = this.state;

		if (nextProps.step !== step) {
			nextState.step = nextProps.step;

			if (nextState.step > SingleEmailGuidedComposerStep.KeyFacts) {
				nextState.showPopover = true;
			}

			// load template
			if (nextState.step === SingleEmailGuidedComposerStep.PersonalBusinessUpdate) {
				this.loadPersonalBusinessUpdateTemplate();
			}
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	};

	private onSubjectChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			subject: (e.target as HTMLInputElement).value,
		});
	};

	private onSkipButtonClicked = () => {
		const { onSkipButtonClicked } = this.props;
		if (onSkipButtonClicked) {
			onSkipButtonClicked(this.getContent());
		}
	};

	private onNextButtonClicked = () => {
		const { onStepChanged } = this.props;
		const { step, subject, keyFacts, conclusion } = this.state;
		const nextState: IState = {};
		switch (step) {
			case SingleEmailGuidedComposerStep.Subject: {
				const trimmedSubject = (subject || '').trim();
				if (trimmedSubject) {
					if (trimmedSubject !== subject) {
						nextState.subject = trimmedSubject;
					}

					if (onStepChanged) {
						onStepChanged(SingleEmailGuidedComposerStep.KeyFacts);
					} else {
						nextState.step = SingleEmailGuidedComposerStep.KeyFacts;
					}

					if (Object.keys(nextState).length > 0) {
						this.setState(nextState);
					}
				}
				break;
			}
			case SingleEmailGuidedComposerStep.KeyFacts: {
				const trimmedKeyFacts = (keyFacts || '').trim();
				if (trimmedKeyFacts !== keyFacts) {
					nextState.keyFacts = trimmedKeyFacts;
				}

				if (onStepChanged) {
					onStepChanged(SingleEmailGuidedComposerStep.PersonalBusinessUpdate);
				} else {
					nextState.step = SingleEmailGuidedComposerStep.PersonalBusinessUpdate;
					this.loadPersonalBusinessUpdateTemplate();
				}

				nextState.showPopover = true;
				this.setState(nextState);
				break;
			}
			case SingleEmailGuidedComposerStep.PersonalBusinessUpdate: {
				if (onStepChanged) {
					onStepChanged(SingleEmailGuidedComposerStep.Conclusion);
				} else {
					nextState.step = SingleEmailGuidedComposerStep.Conclusion;
				}

				nextState.showPopover = true;
				this.setState(nextState, () => {
					this.savePersonalBusinessUpdateTemplate();
				});
				break;
			}
			case SingleEmailGuidedComposerStep.Conclusion: {
				const trimmedConclusion = (conclusion || '').trim();
				if (trimmedConclusion !== conclusion) {
					nextState.conclusion = trimmedConclusion;
				}

				if (Object.keys(nextState).length > 0) {
					this.setState(nextState, this.finish);
				} else {
					this.finish();
				}
				break;
			}
			default: {
				break;
			}
		}
	};

	private getContent = () => {
		const { subject, keyFacts, personalBusinessUpdate, conclusion } = this.state;
		const content: IComposerContent = {
			conclusion,
			greeting: this.greeting,
			keyFacts,
			personalBusinessUpdate,
			subject,
		};
		return content;
	};

	private finish = () => {
		const { onComplete } = this.props;
		if (onComplete) {
			onComplete(this.getContent());
		}
	};
}

const SingleEmailGuidedComposerAsObserver = observer(_SingleEmailGuidedComposer);
const SingleEmailGuidedComposerWithContext = inject(UserSessionViewModelKey)(SingleEmailGuidedComposerAsObserver);
export const SingleEmailGuidedComposer = withEventLogging(
	SingleEmailGuidedComposerWithContext,
	'SingleEmailGuidedComposer'
);
