import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal/react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages, useToaster } from '../../../../../models/hooks/appStateHooks';
import { SavedSearchViewModel, SavedSearchesViewModel } from '../../../../../viewmodels/AppViewModels';
import { ContactsGutterTag } from '../../../../components/contacts/ContactsGutterTag';
import { SearchIcon } from '../../../../components/svgs/icons/SearchIcon';
import { brandPrimaryHover } from '../../../../styles/colors';
import { PeopleCollapsibleSection } from '../PeopleCollapsibleSection';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	savedSearchesVm: SavedSearchesViewModel;
	filter: Api.IBulkContactsRequest;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}

export const SavedSearches: React.FC<IProps> = observer(
	({ className, styles, savedSearchesVm, filter, onChangeFilter }) => {
		const toaster = useToaster();
		const { pushApiError } = useErrorMessages();
		const { logApiError } = useEventLogging();

		React.useEffect(() => {
			savedSearchesVm.loadAll()?.catch((err: Api.IOperationResultNoValue) => {
				pushApiError(err);
				logApiError('LoadSavedSearches-Error', err);
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [savedSearchesVm]);

		const onDeleteSearch = React.useCallback(
			(search: SavedSearchViewModel) => () => {
				savedSearchesVm

					.delete(search.id)
					?.then(() => {
						toaster.push({
							message: `Search "${search.name}" was deleted`,
							type: 'successMessage',
						});
					})
					?.catch((err: Api.IOperationResultNoValue) => {
						pushApiError(err);
						logApiError('DeleteSavedSearch-Error', err);
					});
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[savedSearchesVm]
		);

		const onSelectSaveSearch = (newFilter: SavedSearchViewModel) => () => {
			onChangeFilter({ filter: newFilter.filter, ownershipFilter: newFilter.ownershipFilter });
		};

		const isMatch = React.useCallback(
			(testFilter: Api.IContactFilterCriteria) => {
				return equal(filter, testFilter);
			},
			[filter]
		);

		if (!savedSearchesVm.searches.length) {
			return (
				<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
					<div className={css(styleSheet.emptyText)}>0 saved searches</div>
				</div>
			);
		}

		const renderSearches = () => {
			return savedSearchesVm.searches.map(search => {
				return (
					<ContactsGutterTag
						className={css(styleSheet.search)}
						key={`peopletable-savedsearches-tag-${search.id}`}
						isActive={isMatch(search.filter)}
						leftAccessory={
							<SearchIcon
								fillColor={isMatch(search.filter) ? '#fff' : brandPrimaryHover}
								viewBox='-1 -1 18 18'
								strokeWidth={0.5}
								className={css(styleSheet.searchIcon)}
							/>
						}
						onRemoveButtonClicked={onDeleteSearch(search)}
						showRemoveButton
						updateSelectedTagList={!isMatch(search.filter) ? onSelectSaveSearch(search) : Api.VmUtils.Noop}
						tag={search.name}
						hideCheckbox={true}
					/>
				);
			});
		};

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				<PeopleCollapsibleSection itemHeightPx={34} items={renderSearches()} showLoader={savedSearchesVm.isBusy} />
			</div>
		);
	}
);
