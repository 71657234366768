import * as React from 'react';
import * as Api from '@ViewModels';
import { AutoCompleteSearchField } from '../../../autocomplete/AutoCompleteSearchField';
import { ClearFieldIcon } from '../../../svgs/icons/ClearFieldIcon';
import { SearchIcon } from '../../../svgs/icons/SearchIcon';
import { styleSheet } from './styles';
import './styles.less';
import { AutoCompleteResultFilter, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';

export const EditOpportunitySearchField = ({
	className,
	disabled,
	filter,
	inputId,
	leftAccessory,
	onClearButtonClicked,
	onFocus,
	onInputRef,
	onItemSelected,
	placeholder,
	resultsLimit,
	showClearButton,
	showSearchLeftAccessory,
	hideResultsFooter,
	type,
	value,
}: {
	className?: string;
	disabled?: boolean;
	filter?: AutoCompleteResultFilter<any>;
	inputId: string;
	/**
	 * Set this to null to not render a left accessory, even if showSearchLeftAccessory === true. Setting this to
	 * undefined and showSearchLeftAccessory === true will render the default leftAccessory (search icon)
	 */
	leftAccessory?: React.ReactNode;
	onClearButtonClicked?(e: React.MouseEvent<HTMLElement>): void;
	onFocus?(): void;
	onInputRef?(ref: HTMLInputElement): void;
	onItemSelected?(item: Api.IContact): void;
	placeholder?: string;
	resultsLimit?: number;
	showClearButton?: boolean;
	showSearchLeftAccessory?: boolean;
	hideResultsFooter?: boolean;
	type: ResourceAutoCompleteViewModelType;
	value: string;
}) => {
	const computedLeftAccessory = React.useMemo(() => {
		if (!showSearchLeftAccessory) return null;

		if (leftAccessory === undefined) {
			return <SearchIcon className={css(styleSheet.leftAccessory)} />;
		} else if (leftAccessory !== null) {
			return <div className={css(styleSheet.leftAccessory)}>{leftAccessory}</div>;
		}
	}, [showSearchLeftAccessory, leftAccessory]);

	return (
		<AutoCompleteSearchField
			autoCompleteFilter={filter}
			dropdownClassName={css(styleSheet.dropdownContent)}
			anchorClassName={className}
			disableAutocomplete={!!disabled || !!value}
			hideResultsFooter={hideResultsFooter}
			inputId={inputId}
			inputProps={{
				disabled,
				onFocus,
				style: value ? { pointerEvents: 'none' } : undefined,
				value: value || '',
			}}
			leftAccessory={computedLeftAccessory}
			onInputRef={onInputRef}
			onItemSelected={onItemSelected}
			placeholder={placeholder}
			resultsLimit={resultsLimit}
			rightAccessory={
				!!showClearButton && !disabled ? (
					<button className='edit-opportunity-search-field-clear-button' onClick={onClearButtonClicked}>
						<ClearFieldIcon />
					</button>
				) : null
			}
			type={type}
		/>
	);
};
