import { GoogleBusinessConnectionState, IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateGoogleBusinessMetadata,
	useConnectGoogleBusiness,
	useDeleteConnectionMutation,
	useGetGoogleBusinessMetadata,
	useSyncGoogleBusiness,
} from '../../../../queries';
import googleLogo from '../../../assets/google_logo.png';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet as integrationsStyleSheet } from '../styles';
import { styleSheet } from './styles';

const GoogleBusinessProfile = () => {
	const history = useHistory();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('GoogleBusinessProfile');

	const { data: metadata, status, isLoading } = useGetGoogleBusinessMetadata({});

	const isLoaded = status === 'success';

	const onError = (operationName: string, error: IOperationResultNoValue) => {
		errorMessages.push({
			messages: [`Failed to ${operationName}`],
		});
		logApiError(operationName, error);
	};

	const { mutate: connectGoogleBusiness } = useConnectGoogleBusiness({
		onError: error => onError('Connect', error),
		onSuccess: value => {
			window.location.href = value.authUrl;
		},
	});

	const disconnectGoogleBusinessMutation = useDeleteConnectionMutation({
		onError: error => onError('Disconnect', error),
		onSuccess: () => {
			invalidateGoogleBusinessMetadata();
		},
	});

	const { mutate: syncGoogleBusinessProfile } = useSyncGoogleBusiness({
		onError: error => onError('Sync', error),
		onSuccess: () => {
			invalidateGoogleBusinessMetadata();
		},
	});

	useEffect(() => {
		const needsToSync = history.location.pathname.indexOf('success') > -1;
		if (!needsToSync || !isLoaded) {
			return;
		}

		const isConnected = metadata.connectionState === GoogleBusinessConnectionState.Connected;
		if (!isConnected) {
			return;
		}

		syncGoogleBusinessProfile();
		history.replace(history.location.pathname.replace('success', ''));
	}, [history, isLoaded, metadata, syncGoogleBusinessProfile]);

	const onConnect = () => {
		connectGoogleBusiness();
	};

	const onDisableClick = async () => {
		await disconnectGoogleBusinessMutation.mutateAsync();

		history.push('/integrations');
	};

	if (!userSession.isAdmin) {
		return <Redirect to='/integrations' />;
	}

	if (!metadata) {
		return null;
	}

	return (
		<div className={`${css(styleSheet.container)} component-class`}>
			<figure className={css(styleSheet.logoWrapper)}>
				<img className={css(styleSheet.logo)} src={googleLogo} />
				<span>Business Profile</span>
			</figure>

			<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
				Levitate can sync with Google Business Profile, and pull reviews, stats, star ratings and allow you respond
				directly from Levitate.
			</p>

			{!isLoaded ? <LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} /> : null}

			{isLoaded ? (
				<>
					{metadata.connectionState === GoogleBusinessConnectionState.Disconnected ? (
						<>
							<div className={css(integrationsStyleSheet.signInContainer, styleSheet.signInContainer)}>
								<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
									Your Google Business Profile is now disconnected.
								</p>

								<div className={css(styleSheet.center)}>
									<button
										className={css(baseStyleSheet.ctaButton, styleSheet.button)}
										onClick={onConnect}
										disabled={isLoading}
									>
										Reconnect
									</button>
								</div>
							</div>
						</>
					) : null}

					{metadata.connectionState === GoogleBusinessConnectionState.Connected ? (
						<button className={css(baseStyleSheet.ctaButton)} onClick={onDisableClick}>
							Disconnect Google Business
						</button>
					) : null}

					{metadata.connectionState === GoogleBusinessConnectionState.Unknown ? (
						<>
							<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
								To get started, please sign into your Google Business Profile account.
							</p>
							<div className={css(integrationsStyleSheet.signInContainer, styleSheet.signInContainer)}>
								<figure className={css(styleSheet.center, integrationsStyleSheet.logo)}>
									<img src={googleLogo} alt='Google Logo' />
								</figure>
								<div className={css(styleSheet.center)}>
									<button
										className={css(baseStyleSheet.ctaButton, styleSheet.button)}
										onClick={onConnect}
										disabled={isLoading}
									>
										Connect
									</button>
								</div>
							</div>
						</>
					) : null}
				</>
			) : null}
		</div>
	);
};

export { GoogleBusinessProfile };
