import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useModal } from '../../../../../models/hooks/useModal';
import { RadioButton } from '../../../../components/RadioButton';
import { UserSelectModal } from '../../../../components/UserSelectModal';
import { EOwnershipOption, useOwnershipFilters } from '../hooks/useOwnershipFilter';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	filter: Api.IBulkContactsRequest;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}

export const OwnershipFilters: React.FC<IProps> = observer(({ className, styles, filter, onChangeFilter }) => {
	const { selectedOwnership, changeOwnershipFilter } = useOwnershipFilters({
		filter,
		onChangeFilter,
	});

	const userSelectModal = useModal(
		false,

		(selectedUser: Api.IEntity) => {
			changeOwnershipFilter(EOwnershipOption.Owned, selectedUser);
		},
		[]
	);

	const changeFilter = (newOwnershipOption: EOwnershipOption) => {
		if (newOwnershipOption === EOwnershipOption.Owned) {
			userSelectModal.setIsOpen(true)();
		} else {
			changeOwnershipFilter(newOwnershipOption);
		}
	};

	const renderRadioButton = (selection: EOwnershipOption, text: string, isSelected: boolean) => {
		return (
			<RadioButton
				className={css(styleSheet.radio)}
				checked={isSelected}
				id={`ownership-filter-${selection}`}
				name={`ownership-filter-${selection}`}
				onClick={() => changeFilter(selection)}
				readOnly={true}
			>
				<div className={css(styleSheet.radioText)}>{text}</div>
			</RadioButton>
		);
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			{renderRadioButton(EOwnershipOption.All, 'All contacts', selectedOwnership === EOwnershipOption.All)}
			{renderRadioButton(EOwnershipOption.Me, 'Contacts I own', selectedOwnership === EOwnershipOption.Me)}
			{renderRadioButton(EOwnershipOption.Owned, 'Contacts owned by', selectedOwnership === EOwnershipOption.Owned)}
			{renderRadioButton(
				EOwnershipOption.Connections,
				'My connections',
				selectedOwnership === EOwnershipOption.Connections
			)}
			<UserSelectModal
				modalProps={{
					isOpen: userSelectModal.isOpen,
					onRequestClose: userSelectModal.onRequestClose,
				}}
				title='Contacts owned by'
				ctaText='Search'
			/>
		</div>
	);
});
