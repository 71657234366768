import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	ContactsViewModel,
	IContactsAssignOwnerRequest,
	IOperationResult,
	IOperationResultNoValue,
	ISystemJob,
	IUser,
	ResourceAutoCompleteViewModel,
	ResourceAutoCompleteViewModelType,
	SystemJobViewModel,
	VmUtils,
} from '@ViewModels';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { asModalComponent } from '@WebComponents/Modal';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '@WebComponents/autocomplete/SimpleAutoCompleteSearchField';
import { css } from 'aphrodite';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IModalContext<SystemJobViewModel>,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IToasterComponentProps {
	className?: string;
	contacts: ContactsViewModel;
}

interface IState {
	selectedUser?: IUser;
}

class _AssignContactOwner extends React.Component<IProps, IState> {
	@observable.ref private mSystemJob: SystemJobViewModel;

	@observable.ref private mSystemJobPromise: Promise<ISystemJob>;

	private mMounted: boolean;

	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	public componentDidMount() {
		this.mMounted = true;
	}

	public componentWillUnmount() {
		this.mMounted = false;
		if (this.mSystemJob) {
			this.mSystemJob.stopWatching();

			this.mSystemJob = null;
		}
	}

	public render() {
		const { className } = this.props;
		return (
			<div className={`${css(styleSheet.container)} assign-contact-owner ${className || ''}`}>
				<div className={css(styleSheet.header)}>Assign Contact Owner</div>
				<div className={css(styleSheet.body)}>
					<SimpleAutoCompleteSearchField
						disabled={!!this.isBusy}
						dropdownContentStyle={styleSheet.searchFieldDropDownContent}
						onBlur={this.onSearchFieldBlur}
						onClear={this.clearSelectedUser}
						onCreateAutoCompleteViewModel={this.onCreateAutoCompleteViewModel}
						// @ts-ignore
						onItemSelected={this.onUserSelected}
						onKeyDown={this.onSearchFieldKeyDown}
						pageSize={5}
						placeholder='Search co-workers or assign yourself'
						resultsLimit={5}
						type={ResourceAutoCompleteViewModelType.User}
					/>
				</div>
				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} disabled={!!this.isBusy} onClick={this.onDoneClicked}>
						<span>Done</span>
					</button>
					{!!this.isBusy && <LoadingSpinner type='small' />}
				</div>
			</div>
		);
	}

	@computed
	private get isBusy() {
		return (
			!!this.mSystemJobPromise ||
			(!!this.mSystemJob && this.mSystemJob.isWatching && this.mSystemJob.percentComplete < 100)
		);
	}

	private onDoneClicked = () => {
		const { userSession, logInput, logApiError, contacts } = this.props;
		const { selectedUser } = this.state;
		if (!this.mSystemJob && !!selectedUser) {
			const request: IContactsAssignOwnerRequest = {
				excludeContactIds: contacts.excludedContacts.map(x => x.id),
				filter: contacts.shouldIncludeFilterInRequest ? contacts.filterRequest.filter : null,
				ownershipFilter: contacts.shouldIncludeFilterInRequest ? contacts.filterRequest.ownershipFilter : null,
				includeContactIds: contacts.selectedContacts.map(x => x.id),
				newOwnerId: selectedUser.id,
			};
			const promise = contacts.assignOwner(userSession, request);
			if (promise) {
				logInput('Done', 'Click', {
					excludeContactIds: request.excludeContactIds.length,
					filter: request.filter,
					includeContactIds: request.includeContactIds.length,
					newOwnerId: selectedUser.id,
				});
				promise
					.then(systemJob => {
						if (this.mMounted) {
							this.mSystemJob = new SystemJobViewModel(userSession, systemJob);
							this.mSystemJob.startWatching(this.continueWatching);
						}
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError('BulkAssign-Error', error);
					});
				this.mSystemJobPromise = promise;
			}
		}
	};

	private clearSelectedUser = () => {
		if (this.state.selectedUser) {
			this.setState({
				selectedUser: null,
			});
		}
	};

	private onSearchFieldBlur = (e: ISimpleAutoCompleteSearchFieldEvent<React.FocusEvent<HTMLInputElement>>) => {
		if (!this.state.selectedUser && !!e.target) {
			e.target.clearInput();
		}
	};

	private onSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (!!e.sourceEvent && e.sourceEvent.keyCode !== 13) {
			this.clearSelectedUser();
		}
	};

	private onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<IUser>) => {
		if (e.target) {
			e.target.setSearchQuery(VmUtils.getDisplayName(e.selection));
		}
		this.setState({
			selectedUser: e.selection,
		});
	};

	private continueWatching = (opResult: IOperationResult<ISystemJob>) => {
		if (!opResult.success || !this.mMounted) {
			return false;
		}

		if (opResult.value.percentComplete >= 100) {
			const { parentModal, toaster } = this.props;
			if (parentModal) {
				parentModal.onRequestClose(this.mSystemJob, false);
			}

			toaster.push({
				message: 'Contact owner assigned successfully!',
				type: 'successMessage',
			});
			return false;
		}
		return true;
	};

	private onCreateAutoCompleteViewModel = (
		_: ResourceAutoCompleteViewModelType,
		suggestedViewModel: ResourceAutoCompleteViewModel
	) => {
		suggestedViewModel.addParam({ includeDeactivated: false });
		return suggestedViewModel;
	};
}

const AssignContactOwnerAsObserver = observer(_AssignContactOwner);
const AssignContactOwnerWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ToasterViewModelKey,
	ModalChildComponentContextKey
)(AssignContactOwnerAsObserver);
export const AssignContactOwner = withEventLogging(AssignContactOwnerWithContext, 'AssignContactOwner');

export const AssignContactOwnerModal = asModalComponent(AssignContactOwner, {
	className: 'assign-contact-owner-modal',
	useDefaultHeader: true,
});
