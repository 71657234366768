import * as Api from '@ViewModels';
import { ContactsAddTagModal } from '@WebComponents/contacts/ContactsAddTagModal';
import { TagIcon } from '@WebComponents/svgs/icons/TagIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { brandPrimaryHover, success } from '../../styles/colors';
import { HandwrittenCardTemplateSearchModal } from '../HandwrittenCards/HandwrittenCardTemplateSearchModal';
import { AutomationSelectorModal, IAutomationSelectorContactSelection } from '../automation/AutomationSelector';
import { AutomationsIcon } from '../svgs/icons/AutomationsIcon';
import { EmailIcon } from '../svgs/icons/EmailIcon';
import { ExportIcon } from '../svgs/icons/ExportIcon';
import { PostageIcon } from '../svgs/icons/PostageIcon';
import { TextingIcon } from '../svgs/icons/TextingIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	criteria?: Api.IFilterCriteria[];
	excludedContacts?: Api.IProjectedContact[];
	getMatchingContacts?: () => Promise<Api.IProjectedContact[]>;
	onDeselectAll?: () => void;
	onExport?: () => void;
	selectedContacts?: Api.IProjectedContact[];
	selectionState: Api.ESelectionState;
	styles?: StyleDeclarationValue[];
	actionButtonsDisabled?: boolean;
	filter?: Api.IContactFilterCriteria[];
}

/**
 * Either `getMatchingContacts` or `selectedContacts && excludedContacts` should be passed in.
 * This is to make integrating back with the people search easier.
 */
export const UsersActionBar: React.FC<IProps> = observer(
	({
		// contacts,
		className,
		styles,
		selectionState,
		criteria,
		excludedContacts,
		getMatchingContacts,
		onExport,
		actionButtonsDisabled = false,
	}) => {
		const userSession = useUserSession();
		const fullscreenModal = useFullscreenModal();

		const isActive = selectionState !== 'none' && !actionButtonsDisabled;
		const [contactSelectionForAutomation, setContactSelectionForAutomation] =
			React.useState<IAutomationSelectorContactSelection>(null);
		const [isAutomationOpen, setIsAutomationOpen] = React.useState(false);

		const canShowAutomations = userSession?.account?.features?.automation?.enabled;
		const canShowCards = userSession?.account?.features?.handwrittenCards?.enabled;

		const [isHwcTemplateChooserModalOpen, setIsHwcTemplateChooserModalOpen] = React.useState(false);
		const [routeAfterHwcTemplateSelection, setRouteAfterHwcTemplateSelection] = React.useState<'contacts' | 'filter'>(
			null
		);
		const [tagBulkRequest, setTagBulkRequest] = React.useState<Api.IBulkContactsRequest>(null);

		const canSendTextingCampaign =
			userSession?.account?.features?.texting?.enabled &&
			userSession.account.features.automation?.additionalAllowedTriggers?.includes(Api.AutomationTriggerType.Texting);

		const onSendEmailClicked = async () => {
			const matchingContacts = await getMatchingContacts();

			fullscreenModal.history?.push({
				pathname: '/email/campaigns/create/from-selection',
				state: {
					model: {
						context: {
							allowSenderSelection: true,
							bulkFilterRequestOptions: {
								readOnlySearchCriteria: true,
							},
							contacts: matchingContacts,
						},
						type: 'Contacts',
					},
				},
			});
		};

		const onSendCardClicked = async () => {
			const matchingContacts = await getMatchingContacts();

			if (selectionState === 'all' || selectionState === 'some') {
				setRouteAfterHwcTemplateSelection(matchingContacts.length === 0 ? 'filter' : 'contacts');
				setIsHwcTemplateChooserModalOpen(true);
			}
		};
		const onAddTagClicked = async () => {
			const matchingContacts = await getMatchingContacts();
			setTagBulkRequest({
				excludeContactIds: excludedContacts?.map(c => c.id) ?? [],
				includeContactIds: matchingContacts.map(c => c.id),
				filter: { criteria: [] },
			});
		};

		const onSendTextClicked = async () => {
			const matchingContacts = await getMatchingContacts();

			if (matchingContacts.length > 0) {
				fullscreenModal.history.push({
					pathname: `/texting-campaign/contacts`,
					state: {
						contactIds: matchingContacts.map(contact => contact.id),
					},
				});
			} else {
				fullscreenModal.history.push({
					pathname: `/texting-campaign/filter`,
					state: {
						initialContactIdsToOmit: excludedContacts?.map(contact => contact.id),
						initialFilterCriteriaList: criteria,
					},
				});
			}
		};

		const onHwcTemplateClicked = async (template: Api.IHandwrittenCardTemplate) => {
			const matchingContacts = await getMatchingContacts();
			if (routeAfterHwcTemplateSelection === 'contacts') {
				fullscreenModal.history.push({
					pathname: `/bulk-contact-selection-postcard/${template.id}`,
					state: {
						contactIds: matchingContacts.map(contact => contact.id),
					},
				});
			} else if (routeAfterHwcTemplateSelection === 'filter') {
				fullscreenModal.history.push({
					pathname: `/bulk-contact-filter-postcard/${template.id}`,
					state: {
						contactIdsToOmit: excludedContacts?.map(contact => contact.id),
						tagFilterCriterias: [],
					},
				});
			}
			setIsHwcTemplateChooserModalOpen(false);

			setRouteAfterHwcTemplateSelection(null);
		};

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				<button
					className={css(styleSheet.button, styleSheet.sendEmail, !isActive && styleSheet.disabled)}
					onClick={onSendEmailClicked}
					disabled={actionButtonsDisabled}
				>
					<span>
						<EmailIcon fillColor='#EA8160' />
						<span className={css(styleSheet.buttonText)}>Send Email</span>
					</span>
				</button>

				{canSendTextingCampaign ? (
					<button
						className={css(styleSheet.button, styleSheet.sendText, selectionState === 'none' && styleSheet.disabled)}
						onClick={onSendTextClicked}
						disabled={actionButtonsDisabled}
					>
						<span>
							<TextingIcon fill='#6E27A3' />
							<span className={css(styleSheet.buttonText)}>Send Text</span>
						</span>
					</button>
				) : null}

				{canShowCards ? (
					<button
						className={css(styleSheet.button, styleSheet.sendCard, !isActive && styleSheet.disabled)}
						onClick={() => onSendCardClicked()}
						disabled={actionButtonsDisabled}
					>
						<span>
							<PostageIcon fillColor='#00AAE8' />
							<span className={css(styleSheet.buttonText)}>Send Card</span>
						</span>
					</button>
				) : null}

				<button
					className={css(styleSheet.button, styleSheet.tag, !isActive && styleSheet.disabled)}
					onClick={onAddTagClicked}
				>
					<span>
						<TagIcon fillColor={brandPrimaryHover} />
						<span className={css(styleSheet.buttonText)}>Tag</span>
					</span>
				</button>

				{canShowAutomations ? (
					<button
						className={css(styleSheet.button, styleSheet.export, !isActive && styleSheet.disabled)}
						onClick={async () => {
							const resolveContacts = await getMatchingContacts();
							setContactSelectionForAutomation({
								includeContactIds: resolveContacts.map(c => c.id),
							});
							setIsAutomationOpen(true);
						}}
						disabled={actionButtonsDisabled}
					>
						<span>
							<AutomationsIcon fillColor={success} />
							<span className={css(styleSheet.buttonText)}>Start Automations</span>
						</span>
					</button>
				) : null}

				{onExport ? (
					<button
						className={css(styleSheet.button, styleSheet.export, !isActive && styleSheet.disabled)}
						onClick={onExport}
						disabled={actionButtonsDisabled}
					>
						<span>
							<ExportIcon fillColor={success} />
							<span className={css(styleSheet.buttonText)}>Export</span>
						</span>
					</button>
				) : null}

				{tagBulkRequest ? (
					<ContactsAddTagModal
						request={tagBulkRequest}
						modalProps={{
							isOpen: Boolean(tagBulkRequest),
							onRequestClose: () => setTagBulkRequest(null),
						}}
					/>
				) : null}

				{isAutomationOpen ? (
					<AutomationSelectorModal
						closeModal={() => setIsAutomationOpen(false)}
						startAutomationForSelectedContacts={true}
						contactSelection={contactSelectionForAutomation}
					/>
				) : null}

				{isHwcTemplateChooserModalOpen ? (
					<HandwrittenCardTemplateSearchModal
						isOpen={true}
						onClose={() => setIsHwcTemplateChooserModalOpen(false)}
						onTemplateClicked={onHwcTemplateClicked}
					/>
				) : null}
			</div>
		);
	}
);
