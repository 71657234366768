import { IRange } from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import { IModalContext, useModalContext } from '../../../models';
import { EventLogger } from '../../../models/Logging';
import { baseStyleSheet } from '../../styles/styles';
import { CustomDateRange } from '../CustomDateRange';
import { Modal } from '../Modal';
import { styleSheet } from './styles';

enum PreSetRanges {
	Yesterday = 'Yesterday',
	Today = 'Today',
	Tomorrow = 'Tomorrow',
	NextWeek = 'Next Week',
	LastWeek = 'Last Week',
	ThisMonth = 'This Month',
	LastMonth = 'Last Month',
}

const convertPreSetRange = (rangeToConvert: PreSetRanges) => {
	const today = moment().startOf('day');
	const endToday = moment().endOf('day');
	let result: IRange<Date>;

	switch (rangeToConvert) {
		case PreSetRanges.Yesterday:
			result = {
				start: today.subtract(1, 'day').toDate(),
				end: endToday.toDate(),
			};
			break;
		case PreSetRanges.Today:
			result = {
				start: today.toDate(),
				end: endToday.toDate(),
			};
			break;
		case PreSetRanges.Tomorrow:
			result = {
				start: today.add(1, 'day').toDate(),
				end: endToday.add(2, 'day').toDate(),
			};
			break;
		case PreSetRanges.NextWeek:
			result = {
				start: today.toDate(),
				end: endToday.add(1, 'week').toDate(),
			};
			break;
		case PreSetRanges.LastWeek:
			result = {
				start: today.subtract(1, 'week').toDate(),
				end: endToday.toDate(),
			};
			break;
		case PreSetRanges.ThisMonth:
			result = {
				start: today.toDate(),
				end: endToday.add(1, 'month').toDate(),
			};
			break;
		case PreSetRanges.LastMonth:
			result = {
				start: today.subtract(1, 'month').toDate(),
				end: endToday.toDate(),
			};
			break;
		default:
			result = {
				start: today.toDate(),
				end: endToday.toDate(),
			};
			break;
	}

	return result;
};

interface IProps {
	onRangeChange(range: IRange<Date | null>): void;
	range: IRange<Date | null>;
}

export const DateRangePickerSide = ({ onRangeChange, range }: IProps) => {
	const { parentModal } = useModalContext();

	const isRangeEqual = (presetRange: PreSetRanges): boolean => {
		const { start, end } = range;
		const presetRangeDates = convertPreSetRange(presetRange);

		// Compare start and end properties of the range and the preset range
		return (
			start?.getTime() === presetRangeDates?.start.getTime() && end?.getTime() === presetRangeDates?.end?.getTime()
		);
	};

	const handleRangeClick = (rangeValue: PreSetRanges) => {
		const newRange = convertPreSetRange(rangeValue);
		onRangeChange(newRange);
	};

	const onSubmit = () => {
		EventLogger.logInput('DateRangePicker', 'Okay', 'Click');
		if (parentModal) {
			parentModal.onRequestClose(false);
		}
	};

	const onCancel = () => {
		EventLogger.logInput('DateRangePicker', 'Close', 'Click');
		if (parentModal) {
			parentModal.onRequestClose(true);
		}
	};

	if (!range.start) {
		return null;
	}

	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.body)}>
				<div>
					{Object.values(PreSetRanges).map(rangeValue => (
						<button
							key={rangeValue}
							className={`${css(styleSheet.sideButton)} ${isRangeEqual(rangeValue) ? css(styleSheet.selected) : ''}`}
							onClick={() => handleRangeClick(rangeValue)}
						>
							{rangeValue}
						</button>
					))}
				</div>

				<div>
					<CustomDateRange
						additionalEndDateLabelText='(for multi-day events)'
						defaultOpen={false}
						key={`custom-date-range-${range.start}-${range.end}`}
						from={range.start}
						to={range.end}
						resetText='Clear dates'
						resetDates={() => {
							onCancel();
						}}
						onChange={(from, to) => {
							onRangeChange({
								start: from,
								end: to,
							});
						}}
						styles={[styleSheet.dateRangePicker]}
					/>
				</div>
			</div>
			<div className={css(styleSheet.footer)}>
				<button className={css(styleSheet.submitButton, baseStyleSheet.ctaButton)} onClick={onSubmit}>
					<span>Show Results</span>
				</button>
			</div>
		</div>
	);
};

interface IModalProps extends IModalContext {
	onRangeChange(range: IRange<Date | null>): void;
	range: IRange<Date | null>;
	isOpen: boolean;
	onRequestClose: () => void;
}

export const DateRangePickerSideModal = (props: IModalProps) => {
	return (
		<Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} useDefaultHeader={false} headerAccessory={<></>}>
			<DateRangePickerSide {...props} />
		</Modal>
	);
};
