import * as Api from '@ViewModels';
import microsoftDynamics365SalesLogo from '../../../../assets/logo_microsoft_dynamics_sales_sm.png';
import { baseStyleSheet } from '../../../../styles/styles';
import { DonorPerfectIntegrationGraphic } from '../../../svgs/graphics/DonorPerfectIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

export const DashboardMicrosoftDynamics365SalesIntegrationCard = () => {
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: 'MicrosoftDynamics365SalesIntegrationClosed',
			enableUrlPath: '/integrations/MicrosoftDynamics365Sales',
			integrationEnableEventName: 'MicrosoftDynamics365SalesIntegrationEnableClick',
			pendingAction: Api.PendingActions.ConnectMicrosoftDynamics365Sales,
			checkApiPendingAction: true,
		});

	if (!showCard) {
		return null;
	}

	return (
		<IntegrationCard
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={DonorPerfectIntegrationGraphic} // Do we want to reuse this one or make a duplicate for dynamics
			headerLabel='Microsoft Dynamics 365 Sales Integration'
			logoBgColor='#FFF'
			logoBorder='1px solid #40C4F5'
			logoImgProps={getLogoImgProps({ alt: 'Microsoft Dynamics 365 Sales Logo', src: microsoftDynamics365SalesLogo })}
			ctaButtonProps={getCtaButtonProps({
				children: 'Connect with Microsoft Dynamics 365 Sales',
				styleDeclaration: [baseStyleSheet.ctaButtonReverse, styleSheet.ctaButton],
			})}
			integrationTitleProps={getIntegrationTitleProps({
				styleDeclaration: styleSheet.integrationTitle,
			})}
		>
			<p>
				We are excited to announce that you can integrate your Microsoft Dynamics 365 Sales account with Levitate. With
				this integration, we will have the ability to pull client information, including names, email, phone number,
				birthdays, anniversaries, and more &mdash; making staying in touch even easier. We can also push our notes into
				Microsoft Dynamics 365 Sales.
			</p>
		</IntegrationCard>
	);
};
