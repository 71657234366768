import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { IAutomationInfo } from '../../../../models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../../models/LocalNotifications';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useAutomationContactPreviewMutation,
	useAutomationTemplatesByStatusQuery,
	useCancelAutomationMutation,
	useStartAutomationContactMutation,
	useStartAutomationForContactMutation,
	useStartAutomationMutation,
} from '../../../../queries';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { IConfirmationDialogOption } from '../../ConfirmationDialog';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Modal } from '../../Modal';
import { AutomationsIcon } from '../../svgs/icons/AutomationsIcon';
import { AutomationStepStatusEmailEditorModal } from '../AutomationStepStatusEmailEditor';
import { BulkAutomationsCreationConfirmation } from '../BulkAutomationsCreationConfirmation';
import { AutomationStepIcons } from './AutomationStepIcons';
import { AutomationStepTitleAndDescription } from './AutomationStepTitleAndDescription';
import { AutomationStepsBy } from './AutomationStepsBy';
import { styleSheet } from './styles';

enum AutomationSelectionStep {
	PreSelectedStep = 'PreSelectedStep',
	SelectedStep = 'SelectedStep',
}

export type IAutomationSelectorContactSelection = {
	includeContactIds?: string[];
	excludeContactIds?: string[];
};

export const AutomationSelectorModal = ({
	contact,
	contactSelection,
	className,
	createAutomationForContact,
	filterCriteria,
	ownershipFilter,
	onDeselectAll,
	startAutomationForSelectedContacts,
	styles,
	closeModal,
	onCancel,
	onSave,
}: {
	className?: string;
	contact?: Api.ContactViewModel;
	contactSelection?: IAutomationSelectorContactSelection;
	createAutomationForContact?: boolean;
	filterCriteria?: Api.IContactFilterCriteria;
	ownershipFilter?: Api.IOwnershipFilter;
	onDeselectAll?: () => void;
	startAutomationForSelectedContacts?: boolean;
	styles?: StyleDeclarationValue[];
	closeModal: () => void;
	onCancel?: () => void;
	onSave?: (automation?: Api.IAutomation<Api.IAutomationStep>) => void;
}) => {
	const toaster = useToaster();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { postNotification } = useLocalNotificationService();
	const { logEvent, logApiError } = useEventLogging('AutomationSelector');

	const [automationSelectionComesFrom, setAutomationSelectionComesFrom] = React.useState<Api.AutomationSelectionType>(
		Api.AutomationSelectionType.Myself
	);
	const [automationSelectionStep, setAutomationSelectionStep] = React.useState<AutomationSelectionStep>(
		AutomationSelectionStep.PreSelectedStep
	);
	const [createdAutomation, setCreatedAutomation] = React.useState<Api.IAutomation>(null);
	const [emailStepStatusToEdit, setEmailStepStatusToEdit] =
		React.useState<Api.IAutomationStepStatus<Api.IEmailAutomationStep>>(null);
	const [isOnHold, setIsOnHold] = React.useState(false);
	const [preview, setPreview] = React.useState<Api.IPreviewCreateAutomationResult>(null);
	const [selectedEmployee, setSelectedEmployee] = React.useState<Api.IUser>(null);
	const [selectedTemplate, setSelectedTemplate] = React.useState<Api.IAutomationTemplateReference>(null);
	const [showBulkCreateConfirmation, setShowBulkCreateConfirmation] = React.useState(false);
	const [sendToHousehold, setSendToHousehold] = React.useState(
		selectedTemplate?.sendToHousehold || userSession.account.features.households?.sendToHouseholdByDefault
	);

	const automationTemplatesQuery = useAutomationTemplatesByStatusQuery({
		status: Api.TemplateStatus.Published,
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
			logApiError('AutomationsTemplates-Error', error);
		},
	});

	const onSendToHouseholdChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSendToHousehold(e.target.checked);
	};
	const automationTemplates = React.useMemo<Api.IAutomationTemplateReference[]>(() => {
		return automationTemplatesQuery.data?.filter(template => !!template) || [];
	}, [automationTemplatesQuery.data]);

	const selectedVisibleSteps = (selectedTemplate && selectedTemplate?.publishedSteps?.slice(0, 5)) || [];

	const onSelectionChanged = (type: Api.AutomationSelectionType) => {
		setAutomationSelectionComesFrom(type);
	};

	const getDescription = (template: Api.IAutomationTemplateReference, fullDesc = false) => {
		const publishedSteps = template.publishedSteps ?? [];
		return `${publishedSteps.length} Step${publishedSteps.length > 1 ? 's' : ''} ${
			fullDesc ? `- ${template.runtimeDays}-day runtime` : ''
		}`;
	};

	const resetAllState = () => {
		setAutomationSelectionComesFrom(Api.AutomationSelectionType.Myself);
		setAutomationSelectionStep(AutomationSelectionStep.PreSelectedStep);
		setIsOnHold(false);
		setPreview(null);
		setSelectedEmployee(null);
		setSelectedTemplate(null);
		setShowBulkCreateConfirmation(false);
	};

	const onUserSelected = (selection: Api.IUser) => {
		setSelectedEmployee(selection);
	};

	const handleCancelAutomationSelection = () => {
		resetAllState();
	};

	const handleNextClick = () => {
		setShowBulkCreateConfirmation(true);
	};

	const onEditEmailStepStatusModalRequestClose = async (_: any, canceled?: boolean) => {
		if (canceled) {
			await cancelAutomation();
			onCancel?.();
		} else {
			await startAutomation();
			onFinishCreatingContactAutomation(createdAutomation);
		}

		setCreatedAutomation(null);
		setEmailStepStatusToEdit(null);
	};

	const onBulkCreateConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!!result?.isCta && !!result?.representedObject && !canceled) {
			createForContacts(selectedTemplate);
		}

		resetAllState();
	};

	const onChangedOnHold = () => {
		setIsOnHold(!isOnHold);
	};

	const close = () => {
		closeModal();
		onCancel?.();
	};

	const getUniqueValuesBasedOnIds = (contacts: Partial<Api.IProjectedContact>[]) => {
		const uniqueIds = new Set<string>();
		const vals = (contacts || []).filter(x => {
			if (!uniqueIds.has(x.id)) {
				uniqueIds.add(x.id);
				return true;
			}
			return false;
		});
		return vals;
	};

	const handleStartAutomationNotifications = ({
		createAutomationRequest,
		template,
	}: {
		createAutomationRequest: Api.ICreateAutomationRequest;
		template: Api.IAutomationTemplateReference;
	}) => {
		logEvent('BulkStartAutomation', { createAutomationRequest, templateId: template.id });
		toaster?.push({
			message: 'Automation started for selected contacts.',
			type: 'successMessage',
		});

		const info: IAutomationInfo = {
			createAutomationFilterRequest: createAutomationRequest,
		};
		postNotification?.({
			info,
			topic: Topics.CREATE_AUTOMATION,
		});
	};

	const startAutomationContactMutation = useStartAutomationContactMutation({
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
			logApiError('StartAutomationContact-Error', error);
		},
	});

	const startAutomationMutation = useStartAutomationMutation({
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			logEvent('StartAutomation', { id: createdAutomation.id });
			closeModal();
		},
	});

	const cancelAutomationContactMutation = useCancelAutomationMutation({
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			logEvent('CancelAutomation', { id: createdAutomation.id });
			postNotification?.({
				info: createdAutomation,
				topic: Topics.DELETE_AUTOMATION,
			});
		},
	});

	const automationContactPreviewMutation = useAutomationContactPreviewMutation({
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
		},
		onSuccess: (result: Api.IPreviewCreateAutomationResult) => {
			const isAdmin = userSession.account.isAdmin && userSession.account.features.automation.allowAdminToStartOnBehalf;

			setAutomationSelectionStep(
				isAdmin ? AutomationSelectionStep.SelectedStep : AutomationSelectionStep.PreSelectedStep
			);
			setShowBulkCreateConfirmation(!isAdmin);

			result.excludeContacts = getUniqueValuesBasedOnIds(result.excludeContacts || []);
			result.contactOwners = getUniqueValuesBasedOnIds(result.contactOwners || []);
			setPreview(result);
		},
	});

	const startAutomationForContactMutation = useStartAutomationForContactMutation({
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
		},
		onSuccess: async ({
			template,
			automation,
		}: {
			template: Api.IAutomationTemplateReference;
			automation: Api.IAutomation;
		}) => {
			const hasEmailAsFirstStep =
				template.publishedSteps?.length > 0 && template.publishedSteps[0]?._type === 'EmailAutomationStep';

			contact.addInProgressAutomations([{ automationId: automation.id, name: template.name }]);
			logEvent('CreateAutomation-Success', {
				automationId: automation.id,
				contactId: contact.id,
				templateId: template.id,
			});
			if (hasEmailAsFirstStep) {
				const firstStepStatus = automation.steps?.length > 0 ? automation.steps[0] : null;
				if (firstStepStatus?.step._type === 'EmailAutomationStep') {
					setCreatedAutomation(automation);
					setEmailStepStatusToEdit(firstStepStatus as Api.IAutomationStepStatus<Api.IEmailAutomationStep>);
					return;
				} else {
					await startAutomation();
					onFinishCreatingContactAutomation(automation);
					return;
				}
			}
			onFinishCreatingContactAutomation(automation);
			closeModal();
		},
	});

	const onTemplateSelected = async (template: Api.IAutomationTemplateReference) => {
		if (!!createAutomationForContact && !!contact?.id) {
			const hasEmailAsFirstStep =
				template.publishedSteps?.length > 0 && template.publishedSteps[0]?._type === 'EmailAutomationStep';

			await startAutomationForContactMutation.mutateAsync({
				template,
				contactId: contact.id,
				start: !hasEmailAsFirstStep,
			});

			return;
		} else if (!!startAutomationForSelectedContacts && !!contactSelection) {
			const request = getCreateAutomationRequestForContacts();
			await automationContactPreviewMutation.mutateAsync({
				id: template.id,
				createAutomationRequest: request,
			});

			setSelectedTemplate(template);
			setSendToHousehold(template.sendToHousehold || userSession.account.features.households?.sendToHouseholdByDefault);
		}
		onSave?.();
	};

	const cancelAutomation = async () => {
		const automationId = createdAutomation.id;
		return cancelAutomationContactMutation.mutateAsync({ automationId });
	};

	const startAutomation = async () => {
		const automationId = createdAutomation.id;
		return startAutomationMutation.mutateAsync({ automationId });
	};

	const onFinishCreatingContactAutomation = (automation: Api.IAutomation<Api.IAutomationStep>) => {
		const info: IAutomationInfo = {
			automation,
			contact: contact?.toJs(),
		};

		postNotification?.({
			info,
			topic: Topics.CREATE_AUTOMATION,
		});
		toaster?.push({
			message: `Automation started for ${contact.name}.`,
			type: 'successMessage',
		});
		onSave?.(automation);
	};

	const createForContacts = async (template: Api.IAutomationTemplateReference) => {
		if (!!template && !!startAutomationForSelectedContacts && !!contactSelection) {
			const request = getCreateAutomationRequestForContacts();

			// This query will fail throw a bugsnag error if the error is not swallowed.
			//   The reason is that valid errors to be displayed are intended to come back in the error messages.
			try {
				await startAutomationContactMutation.mutateAsync({
					id: template.id,
					createAutomationRequest: request,
				});
				handleStartAutomationNotifications({ createAutomationRequest: request, template });
				onDeselectAll?.();

				onSave?.();
				closeModal();
			} catch {
				// Swallow the error to allow the error modal to display why automation failed
			}
		}
	};

	const getCreateAutomationRequestForContacts = () => {
		const isAdmin = userSession.account.isAdmin && userSession.account.features.automation.allowAdminToStartOnBehalf;

		if (contactSelection) {
			const request: Api.ICreateAutomationRequest = {
				autoStart: true,
				excludeContactIds: contactSelection.excludeContactIds,
				filter: !contactSelection.includeContactIds?.length ? filterCriteria : undefined,
				ownershipFilter: !contactSelection.includeContactIds?.length ? ownershipFilter : undefined,
				includeContactIds: contactSelection.includeContactIds,
				putOnHold: isOnHold,
				groupByHousehold: sendToHousehold,
			};

			if (isAdmin) {
				request.sendFromOptions = {
					mode:
						automationSelectionComesFrom === Api.AutomationSelectionType.ContactOwners
							? Api.SendEmailFrom.ContactOwner
							: Api.SendEmailFrom.CurrentUser,
				};
				if (automationSelectionComesFrom === Api.AutomationSelectionType.Employee) {
					request.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
					request.sendFromOptions.selectedUser = `${selectedEmployee.id}`;
				}
			}
			return request;
		}
	};

	const isLoading = automationTemplatesQuery.isLoading;

	return (
		<Modal isOpen useDefaultHeader={true} onRequestClose={close}>
			<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				<h2 className={css(styleSheet.header)}>
					<AutomationsIcon fillColor={brandPrimary} />
					{automationSelectionStep === AutomationSelectionStep.PreSelectedStep && ' Select an Automation Flow'}
					{automationSelectionStep === AutomationSelectionStep.SelectedStep && ' Selected Automation Flow'}
				</h2>

				<article
					className={css(
						styleSheet.body,
						automationSelectionStep === AutomationSelectionStep.SelectedStep && styleSheet.bodyOverflowVisible
					)}
				>
					{isLoading ? (
						<LoadingSpinner type='large' />
					) : (
						<>
							{automationSelectionStep === AutomationSelectionStep.PreSelectedStep && (
								<>
									<ul className={css(baseStyleSheet.verticalStack)}>
										{automationTemplates.map(automationTemplate => {
											const publishedStepReferences = automationTemplate.publishedSteps;
											const visibleSteps = publishedStepReferences?.slice(0, 5);
											const overflowSteps = publishedStepReferences?.length > 5 ? publishedStepReferences.slice(5) : [];

											return (
												<li className={css(styleSheet.automationCard)} key={automationTemplate.id}>
													<AutomationStepTitleAndDescription
														title={automationTemplate.name}
														description={getDescription(automationTemplate, true)}
													/>
													<div className={css(styleSheet.automationCardSteps)}>
														<div className={css(baseStyleSheet.horizontalStack)}>
															<AutomationStepIcons visibleSteps={visibleSteps} />
															{overflowSteps?.length > 0 && (
																<span className={css(baseStyleSheet.tableColumnHeader, styleSheet.automationCardLabel)}>
																	{`... ${overflowSteps.length} more`}
																</span>
															)}
														</div>
														<button
															className={css(
																baseStyleSheet.ctaButtonReverseSmall,
																styleSheet.automationCardSelectButton
															)}
															disabled={isLoading}
															onClick={() => onTemplateSelected(automationTemplate)}
														>
															<span>Select</span>
														</button>
													</div>
													{isLoading && <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />}
												</li>
											);
										})}
									</ul>
								</>
							)}
						</>
					)}

					{automationSelectionStep === AutomationSelectionStep.SelectedStep && selectedTemplate && (
						<div>
							<article className={css(styleSheet.selectedAutomation)}>
								<AutomationStepTitleAndDescription
									title={selectedTemplate.name}
									description={`${getDescription(selectedTemplate)}`}
								/>
								<div className={css(styleSheet.automationCardSteps)}>
									<div className={css(baseStyleSheet.horizontalStack)}>
										<AutomationStepIcons visibleSteps={selectedVisibleSteps} />
									</div>
								</div>
							</article>
							<article>
								<h4 className={css(baseStyleSheet.truncateText, styleSheet.automationCardSubTitle)}>
									Automation steps by:
								</h4>
								<AutomationStepsBy
									preview={preview}
									isBusy={isLoading}
									userSession={userSession}
									onAutomationSelectionComesFrom={onSelectionChanged}
									onSelectedEmployee={onUserSelected}
								/>
								{userSession.account.features.households?.enabled ? (
									<>
										<Checkbox
											className={css(styleSheet.sendToHoseholdCheckbox)}
											checked={sendToHousehold}
											type='large'
											onChange={onSendToHouseholdChanged}
										>
											<div>
												<p className={css(styleSheet.checkboxText)}>Send to households?</p>
											</div>
										</Checkbox>
										<p className={css(styleSheet.sendToHouseholdMessage)}>
											When this setting is turned on, if a contact in the automation is part of a household, the other
											household member will be included on any emails and handwritten cards sent as part of this
											automation.
										</p>
									</>
								) : null}
								<footer>
									<div className={css(styleSheet.buttonContainer)}>
										<button
											className={css(baseStyleSheet.ctaButton, styleSheet.ctaButton)}
											disabled={
												automationSelectionComesFrom === Api.AutomationSelectionType.Employee && !selectedEmployee
											}
											onClick={handleNextClick}
										>
											Next
										</button>
										<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={handleCancelAutomationSelection}>
											Cancel
										</button>
									</div>
								</footer>
							</article>
						</div>
					)}
				</article>

				<BulkAutomationsCreationConfirmation
					allowStartAutomationsOnHold={userSession.account?.features?.automation?.allowStartAutomationsOnHold}
					isOnHold={isOnHold}
					isOpen={showBulkCreateConfirmation}
					onChangedOnHold={onChangedOnHold}
					onRequestClose={onBulkCreateConfirmationRequestClose}
					preview={preview}
				/>

				<AutomationStepStatusEmailEditorModal
					automation={new Api.AutomationViewModel(userSession, createdAutomation)}
					contact={contact}
					emailStepStatus={emailStepStatusToEdit}
					modalProps={{
						isOpen: !!emailStepStatusToEdit,
						onRequestClose: onEditEmailStepStatusModalRequestClose,
					}}
					saveCtaTitle='Send'
				/>
			</div>
		</Modal>
	);
};
