import { css } from 'aphrodite';
import * as React from 'react';
import { styleSheet } from './styles';

interface DataboardProps {
	children: React.ReactNode;
	styles?: any[];
}

export const Databoard: React.FC<DataboardProps> = ({ children, styles = [] }) => {
	return <div className={css(styleSheet.container, ...styles)}>{children}</div>;
};

export const DataboardFilters: React.FC<DataboardProps> = ({ children, styles = [] }) => {
	return <header className={css(styleSheet.filtersContainer, ...styles)}>{children}</header>;
};

export const DataboardActions: React.FC<DataboardProps> = ({ children, styles = [] }) => {
	return <header className={css(styleSheet.actionsContainer, ...styles)}>{children}</header>;
};

export const DataboardBody: React.FC<DataboardProps> = ({ children, styles = [] }) => {
	return <div className={css(styleSheet.bodyContainer, ...styles)}>{children}</div>;
};

export const DataboardSummary: React.FC<DataboardProps> = ({ children, styles = [] }) => {
	return <div className={css(styleSheet.summaryContainer, ...styles)}>{children}</div>;
};
