import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	ContactsViewModel,
	IContactsAddConnectionRequest,
	IOperationResult,
	IOperationResultNoValue,
	ISystemJob,
	IUser,
	ResourceAutoCompleteViewModelType,
	SystemJobViewModel,
	VmUtils,
} from '@ViewModels';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { asModalComponent } from '@WebComponents/Modal';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '@WebComponents/autocomplete/SimpleAutoCompleteSearchField';
import { css } from 'aphrodite';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IModalContext<SystemJobViewModel>,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IToasterComponentProps {
	className?: string;
	contacts: ContactsViewModel;
}

interface IState {
	selectedUser?: IUser;
}

class _AddContactConnection extends React.Component<IProps, IState> {
	@observable.ref private mSystemJob: SystemJobViewModel;
	@observable.ref private isBusy = false;

	private mMounted: boolean;
	public readonly state: IState = {};

	public componentDidMount() {
		this.mMounted = true;
	}

	public componentWillUnmount() {
		this.mMounted = false;
		if (this.mSystemJob) {
			this.mSystemJob.stopWatching();

			this.mSystemJob = null;
		}
	}

	public render() {
		const { className } = this.props;
		return (
			<div className={`${css(styleSheet.container)} ${className || ''}`}>
				<div className={css(styleSheet.header)}>Add Connection</div>
				<div className={css(styleSheet.description)}>
					Adding as a connection will share this contact with the selected co-worker.
				</div>
				<div className={css(styleSheet.body)}>
					<SimpleAutoCompleteSearchField
						disabled={this.busy}
						onBlur={this.onSearchFieldBlur}
						onClear={this.clearSelectedUser}
						// @ts-ignore
						onItemSelected={this.onUserSelected}
						onKeyDown={this.onSearchFieldKeyDown}
						pageSize={5}
						placeholder='Search co-workers or add yourself'
						resultsLimit={5}
						type={ResourceAutoCompleteViewModelType.User}
					/>
				</div>
				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} disabled={this.busy} onClick={this.onSaveClicked}>
						<span>Save</span>
					</button>
					{this.busy && <LoadingSpinner type='small' />}
				</div>
			</div>
		);
	}

	@computed
	private get busy() {
		return this.isBusy || (this.mSystemJob && this.mSystemJob.isWatching && this.mSystemJob.percentComplete < 100);
	}

	private onSaveClicked = () => {
		const { userSession, logInput, logApiError, contacts } = this.props;
		const { selectedUser } = this.state;
		if (!this.mSystemJob && selectedUser) {
			const request: IContactsAddConnectionRequest = {
				excludeContactIds: contacts.excludedContacts.map(x => x.id),
				filter: contacts.shouldIncludeFilterInRequest ? contacts.filterRequest.filter : null,
				ownershipFilter: contacts.shouldIncludeFilterInRequest ? contacts.filterRequest.ownershipFilter : null,
				includeContactIds: contacts.selectedContacts.map(x => x.id),
				newConnectionId: selectedUser.id,
			};
			logInput('Done', 'Click', {
				excludeContactIds: request.excludeContactIds.length,
				filter: request.filter,
				ownershipFilter: request.ownershipFilter,
				includeContactIds: request.includeContactIds.length,
				newConnectionId: selectedUser.id,
			});
			this.isBusy = true;

			ContactsViewModel.addConnection(userSession, request)
				?.then(systemJob => {
					if (this.mMounted) {
						this.mSystemJob = new SystemJobViewModel(userSession, systemJob);
						this.mSystemJob.startWatching(this.continueWatching);
					}
				})
				?.catch((error: IOperationResultNoValue) => {
					logApiError('BulkAssign-Error', error);
				});
		}
	};

	private clearSelectedUser = () => this.setState({ selectedUser: null });

	private onSearchFieldBlur = (e: ISimpleAutoCompleteSearchFieldEvent<React.FocusEvent<HTMLInputElement>>) => {
		if (!this.state.selectedUser && e.target) {
			e.target.clearInput();
		}
	};

	private onSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (e.sourceEvent && e.sourceEvent.key !== 'Enter') {
			this.clearSelectedUser();
		}
	};

	private onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<IUser>) => {
		if (e.target) {
			e.target.setSearchQuery(VmUtils.getDisplayName(e.selection));
		}
		this.setState({ selectedUser: e.selection });
	};

	private continueWatching = (opResult: IOperationResult<ISystemJob>) => {
		if (!opResult.success || !this.mMounted) {
			return false;
		}

		if (opResult.value.percentComplete >= 100) {
			const { parentModal, toaster } = this.props;
			if (parentModal) {
				parentModal.onRequestClose(this.mSystemJob, false);
			}

			toaster.push({
				message: 'Connection added successfully!',
				type: 'successMessage',
			});
			return false;
		}

		return true;
	};
}

const AddContactConnectionAsObserver = observer(_AddContactConnection);
const AddContactConnectionWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ToasterViewModelKey,
	ModalChildComponentContextKey
)(AddContactConnectionAsObserver);
export const AddContactConnection = withEventLogging(AddContactConnectionWithContext, 'AddContactConnection');

export const AddContactConnectionModal = asModalComponent(AddContactConnection, {
	className: 'assign-contact-owner-modal',
	useDefaultHeader: true,
});
