import moment from 'moment';
import momentTz from 'moment-timezone';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { scheduleSendOverlapsWithExistingWorkload } from '../../../../models/UiUtils';
import { useAutorun } from '../../../../models/hooks/mobxHooks';
import { ComposeEmailViewModel } from '../../../../viewmodels/AppViewModels';

export const useEmailWorkloadEstimateErrors = (emailComposer: ComposeEmailViewModel) => {
	const { logApiError } = useEventLogging('useEmailWorkloadEstimates');
	const emailWorkloadVm = emailComposer?.emailWorkload;
	const startDateStringValue = emailComposer?.emailMessage?.options?.scheduledSend?.startDate
		? emailComposer?.emailMessage?.options?.scheduledSend?.startDate
		: null;

	const [estimatedSchduleSendOverlapsWithExistingWorkload, setEstimatedSchduleSendOverlapsWithExistingWorkload] =
		React.useState<boolean>(false);
	const [datePassed, setDatePassed] = React.useState<boolean>(
		startDateStringValue ? moment(startDateStringValue).isBefore(moment()) : false
	);

	useAutorun(async () => {
		try {
			const contactOwners = await emailComposer.getContactOwners();
			let emailCount = 1;
			if (contactOwners?.length) {
				const owner = contactOwners.reduce(
					(prev, curr) => (prev?.contactsCount < curr?.contactsCount ? curr : prev),
					contactOwners[0]
				);
				emailCount = owner.contactsCount;
			}

			const estimateOpResult = await emailWorkloadVm.getScheduledSendEstimate(
				emailComposer.emailMessage.options.scheduledSend,
				emailCount,
				momentTz.tz.guess()
			);
			const estimate = estimateOpResult.success ? estimateOpResult.value : null;
			setEstimatedSchduleSendOverlapsWithExistingWorkload(
				scheduleSendOverlapsWithExistingWorkload(estimate, new Date(startDateStringValue), emailCount)
			);
		} catch (err) {
			logApiError('LoadSendOnBehalfError', err);
		}
	}, [emailComposer, startDateStringValue]);

	useAutorun(() => {
		const passed = startDateStringValue ? moment(startDateStringValue).isBefore(moment()) : false;
		if (passed !== datePassed) {
			setDatePassed(passed);
		}
	}, [datePassed, startDateStringValue]);

	return [estimatedSchduleSendOverlapsWithExistingWorkload, datePassed] as const;
};
