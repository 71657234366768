import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface Variables {
	request: Api.IContactsAddTagsRequest;
}

export function useContactAddTagsMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (job: Api.ISystemJob, variables: Variables) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ request }: Variables) => {
			return userSession.webServiceHelper.callAsync<Api.ISystemJob>(`contact/tags/add`, 'POST', request);
		},
		onSuccess,
		onError,
	});
}
