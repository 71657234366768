import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import ReactModal from 'react-modal';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../../models/LocalNotifications';
import { useEventLogging } from '../../../../models/Logging';
import { FirstNamePlaceholder, Token } from '../../../../models/Token';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	createRichContentEditorStateWithText,
	getDefaultEmailSignature,
	isValidEmail,
} from '../../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useEmailUserWorkloadQuery } from '../../../../queries';
import { IAppToastMessage } from '../../../../viewmodels/AppViewModels';
import EmailAutomationGraphic from '../../../assets/email_automation_graphic.svg';
import { AIAssistantButton } from '../../../containers/AIAssistantButton';
import { baseStyleSheet } from '../../../styles/styles';
import { CompoundButton, CompoundButtonType } from '../../CompoundButton';
import { DeleteConfirmation } from '../../DeleteConfirmation';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Modal } from '../../Modal';
import { ISelectBoxOption } from '../../SelectBox';
import { EmailSuccessToast } from '../../Toaster/EmailSuccessToast';
import { AIContentGenerationWizard } from '../../ai/AIContentGenerationWizard';
import { AIContentGenerationStatusContext } from '../../ai/AIContentGenerationWizard/context';
import {
	AIContentGenerationWizardAssistantEmailMessages,
	AIContentGenerationWizardType,
} from '../../ai/AIContentGenerationWizard/models';
import { useEmailAiContentWizard } from '../../ai/AIContentGenerationWizard/useEmailAiContentWizard';
import { ScheduledEmailErrorModal } from '../../errorMessages/ScheduledEmailErrorModal';
import { ContactContextTabView } from '../ContactContextTabView';
import { EmailComposer } from '../EmailComposer';
import { EmailComposerOptions } from '../EmailComposerOptions';
import { EmailPreview } from '../EmailPreview';
import { RecipientsField } from '../RecipientsField';
import { SendSchedulerOverlay } from '../SendScheduler';
import { SendSchedulerCompleteOverlay } from '../SendSchedulerCompleteOverlay';
import { styleSheet } from './styles';

function _SendMessageComposerDialog({
	hidePreviewButton,
	onSend,
	canScheduleSend,
	onRequestClose,
	emailMessage: emailMessageProp,
	hideCcOption,
	onRequestRemoveSavedEmailAttachment,
	sendCtaTitle,
	onWillSend,
	toastOnSendSuccess,
	onRenderAdditionalFooterItems,
	shouldStartWithAIPrompt,
	emailType,
	impersonationContext,
}: {
	hidePreviewButton?: boolean;
	onSend?(sendPromise: Promise<Api.IOperationResultNoValue>): void;
	toastOnSendSuccess?: boolean;
	onWillSend?(emailMessage: Api.EmailMessageViewModel): boolean;
	sendCtaTitle?: string;
	canScheduleSend?: boolean;
	hideCcOption?: boolean;
	onRequestClose: (
		emailMessage?: Api.EmailMessageViewModel<File, Api.ISendEmailResponse, Api.IEmailMessageFollowUpOptions>,
		canceled?: boolean
	) => void;
	emailMessage: Api.EmailMessageViewModel<File>;
	onRenderAdditionalFooterItems?(): React.ReactNode;
	onRequestRemoveSavedEmailAttachment?(savedAttachment: Api.IFileAttachment): Promise<any>;
	shouldStartWithAIPrompt?: boolean;
	emailType?: Api.EmailType;
} & IImpersonationContextComponentProps) {
	const { logInput, logEventWithLabel, logApiError, logEvent } = useEventLogging('SendMessageComposerDialog');
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { postNotification } = useLocalNotificationService();
	const toaster = useToaster();
	const [emailMessage] = React.useState<Api.EmailMessageViewModel<File>>(emailMessageProp);
	const [emailBodyEditorState, setEmailBodyEditorState] = React.useState(
		convertRawRichTextContentStateToRichContentEditorState(emailMessage?.content ?? null)
	);
	const [showingSendScheduler, setShowingSendScheduler] = React.useState(false);
	const [emailSendEstimateForSentMessage, setEmailSendEstimateForSentMessage] =
		React.useState<Api.IEmailSendEstimate>(null);
	const [selectedRecipient, setSelectedRecipient] = React.useState(
		emailMessage?.contactsToAdd && emailMessage.contactsToAdd.length > 0
			? emailMessage.contactsToAdd.getByIndex(0)
			: null
	);
	const [showingCcField, setShowingCcField] = React.useState(false);

	const [recipientToDelete, setRecipientToDelete] = React.useState<Api.ContactViewModel>(null);
	const [createFollowupReminder, setCreateFollowupReminder] = React.useState(false);

	const [messageFieldError, setMessageFieldError] = React.useState<string>(null);

	const [subjectFieldError, setSubjectFieldError] = React.useState<string>(null);

	const [scheduleError, setScheduleError] = React.useState<Api.IOperationResultNoValue>(null);
	const [templates] = React.useState(
		new Api.TemplatesViewModel(userSession, {
			// cannot use impersonationContext and `forUserId` at the same time
			forUserId: !impersonationContext?.isValid ? userSession.user.id : undefined,
		}).impersonate(impersonationContext)
	);
	const [showingPreviewEmail, setShowingPreviewEmail] = React.useState(false);
	const emailWorkloadQuery = useEmailUserWorkloadQuery();
	const { aiContentGenContext, onGenerate, onUndoClicked, setShowingAiContentGenWizard, showingAiContentGenWizard } =
		useEmailAiContentWizard({
			onEmailGenerated: email => {
				setEmailBodyEditorState(convertRawRichTextContentStateToRichContentEditorState(email.content));
				if (email.subject) {
					emailMessage.subject = email.subject;
				}
			},
			onUndo: undoContent => {
				if (undoContent) {
					setEmailBodyEditorState(convertRawRichTextContentStateToRichContentEditorState(undoContent.content));
					emailMessage.subject = undoContent.subject ?? '';
				}
			},
		});
	const onGenerateClicked = (options: Api.IAIContentGenerationOptions) => {
		const request: Api.IEmailContentGenerationRequest = {
			emailType,
			...options,
		};
		return onGenerate(request);
	};

	React.useEffect(() => {
		if (shouldStartWithAIPrompt) {
			setShowingAiContentGenWizard(true);
			onGenerate({
				emailType,
				toIds: emailMessage.contactsToAdd.map(contact => contact.id),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAddCcButtonClicked = () => {
		logInput('AddCcField', 'Click');
		setShowingCcField(true);
	};
	const onBccToCrmCheckedChange = (checked: boolean) => {
		const bccEmail = userSession.user.userPreferences.bccEmail;
		if (bccEmail) {
			const bcc = [...(emailMessage.bcc || [])];
			const index = bcc.findIndex(x => x.email === bccEmail);
			if (checked) {
				if (index < 0) {
					bcc.push({
						email: bccEmail,
					});
				}
			} else {
				bcc.splice(index, 1);
			}
			emailMessage.bcc = bcc;
		}
	};
	const onCreateFollowUpReminderCheckedChanged = (checked: boolean) => {
		setCreateFollowupReminder(checked);

		if (emailMessage?.options) {
			emailMessage.options.followUpIfNoResponseInDays = checked ? 7 : null;
		}
		logInput(`CreateFollowUpReminder:${checked ? 'Checked' : 'Unchecked'}`, 'Click');
	};

	const onFollowUpReminderDaysChanged = (days: number) => {
		const computedDays = Math.max(1, days);
		if (emailMessage?.options) {
			emailMessage.options.followUpIfNoResponseInDays = computedDays;
		}
	};
	const onNotePrivacyToggleChange = (checked: boolean) => {
		emailMessage.options.noteVisibility = checked ? 'all' : 'admin';
	};
	const richTextEditorDidChangeFocus = () => {
		setMessageFieldError(null);

		setSubjectFieldError(null);
	};
	const onMessageEditorStateChanged = (editorState: Api.IRichContentEditorState) => {
		setEmailBodyEditorState(editorState);

		setMessageFieldError(null);

		setSubjectFieldError(null);
	};
	const onBodyScroll = () => {
		if (!!messageFieldError || !!subjectFieldError) {
			setMessageFieldError(null);

			setSubjectFieldError(null);
		}
	};
	const onEmailMessageTemplateChanged = (template: Api.ITemplate) => {
		if (template) {
			setEmailBodyEditorState(convertRawRichTextContentStateToRichContentEditorState(template.content));
			emailMessage.subject = template.subject || emailMessage.subject;

			if (template.attachments?.length > 0) {
				emailMessage.setSavedAttachments(template.attachments);
			}
		}
	};
	const onSubjectChanged = (subject: string) => {
		emailMessage.subject = subject;

		setMessageFieldError(null);

		setSubjectFieldError(null);
	};
	const send = () => {
		const promise = emailMessage.send();
		if (promise) {
			promise
				.then(opResult => {
					if (opResult.value.schedule.criteria === Api.ScheduleCriteria.Immediately) {
						// tell listeners an email was sent
						postNotification({
							info: emailMessage,
							topic: Topics.SEND_EMAIL,
						});
						if (toastOnSendSuccess) {
							const toastMessage: IAppToastMessage = {
								message: `Message sent successfully.`,
							};

							toaster.push({
								customContent: <EmailSuccessToast toastMessage={toastMessage} />,
								type: 'custom',
							});
						}
						logEventWithLabel('Send-WithSaveAsNote', emailMessage.options.saveAsNote ? 'true' : 'false');
						logEventWithLabel(
							'Send-WithNoteVisibility',
							emailMessage.options.noteVisibility === 'all' ? 'public' : 'private'
						);
						onRequestClose?.(emailMessage, false);
					} else {
						setEmailSendEstimateForSentMessage({
							emailWorkload: emailWorkloadQuery.data,

							estimate: opResult.value.schedule,
						});
						setShowingSendScheduler(false);
					}
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('Send-Error', error);
					const sendNow = emailMessage.options?.scheduledSend?.criteria === Api.ScheduleCriteria.Immediately;
					if (!sendNow && error?.systemCode === -1) {
						// connection error, show special error message
						setScheduleError(error);
						return;
					}

					errorMessages.push({
						messages: [error.systemMessage],
					});
				});

			if (onSend) {
				onSend(promise);
			}
		}
		return promise;
	};
	const onSendButtonClicked =
		(showScheduler = false) =>
		() => {
			if (emailMessage.contactsToAdd.length === 0) {
				return;
			}

			if (!emailMessage.subject) {
				setMessageFieldError(null);
				setSubjectFieldError('Please enter a subject before sending.');
				return;
			}

			if (!emailBodyEditorState || (!!emailBodyEditorState && !emailBodyEditorState.hasContent())) {
				setMessageFieldError('Please enter a message before sending.');

				setSubjectFieldError(null);
				return;
			}

			const content = emailBodyEditorState.getRawRichTextContent();
			if (!Token.isFirstNameTokenFormattedCorrectlyLoose(content)) {
				errorMessages.push({
					messages: [
						`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
					],
				});
				return;
			}

			emailMessage.content = emailBodyEditorState.getRawRichTextContent();

			let canSend = true;
			if (onWillSend) {
				canSend = onWillSend(emailMessage);
			}

			if (!canSend) {
				return;
			}

			if (!!canScheduleSend && !!showScheduler) {
				setShowingSendScheduler(true);
			} else {
				send();
			}
		};
	const onSchedulerSendClicked = (schedule: Api.IScheduledSend) => {
		emailMessage.options.scheduledSend = schedule;
		const sendNow = schedule.criteria === Api.ScheduleCriteria.Immediately;
		logInput(`Send${sendNow ? 'Immediately' : 'WithSchedule'}`, 'Click', {
			count: 1,
			schedule,
		});

		if (!!showingSendScheduler && !!sendNow) {
			setShowingSendScheduler(false);
		}
		return send();
	};
	const onCloseWindowButtonClicked = () => {
		onRequestClose(emailMessage, false);
	};
	const onRemoveRecipient = (recipient: Api.ContactViewModel) => {
		emailMessage.setPreferredEmailAddressForContact(recipient, null);
		emailMessage.contactsToAdd.removeItems([recipient]);
		setSelectedRecipient(emailMessage.contactsToAdd.getByIndex(0));
	};
	const onDeleteConfirmationFinished = (shouldDelete: boolean) => {
		if (shouldDelete) {
			logEvent('RemoveContact', {
				id: recipientToDelete.id,
			});
			onRemoveRecipient(recipientToDelete);
		}

		setRecipientToDelete(null);

		// close the window if no more recipients remain
		if (emailMessage.contactsToAdd.toArray().length < 1) {
			onRequestClose(emailMessage, false);
		}
	};
	const onEmailSelectorSelectionChanged = React.useCallback(
		(contact: Api.ContactViewModel) => (selection: ISelectBoxOption<Api.EmailAddress>) => {
			logInput('EmailSelector', 'Click');
			emailMessage.setPreferredEmailAddressForContact(contact, selection.value);

			setMessageFieldError(null);

			setSubjectFieldError(null);
		},
		[emailMessage, logInput]
	);
	React.useEffect(() => {
		if (emailMessage) {
			if (emailMessage.contactsToAdd.length > 0) {
				const firstContact = emailMessage.contactsToAdd.getByIndex(0);
				onEmailSelectorSelectionChanged(firstContact)({
					value: firstContact.primaryEmail || (firstContact.emailAddresses || [])[0],
				});
			}
		}
	}, [emailMessage, onEmailSelectorSelectionChanged]);
	React.useEffect(() => {
		if (emailMessage) {
			if (emailMessage.options) {
				emailMessage.options.followUpIfNoResponseInDays = null;
			}
			emailMessage.contactsToAdd.forEach(x => x.load());
			if (!emailMessage.attachments) {
				emailMessage.setAttachments(
					new Api.AttachmentsToBeUploadedViewModel<File>(
						null,
						Api.VmUtils.getMaxAttachmentByteCountForEmailConfigurations(
							userSession?.account?.emailProviderConfiguration
						)
					)
				);
			}

			const crmEmail = userSession.user.userPreferences.bccEmail;
			if (crmEmail) {
				// auto-add bcc email
				emailMessage.bcc = [...(emailMessage.bcc || []), { email: crmEmail }];
			}
		}
	}, [emailMessage, userSession]);
	React.useEffect(() => {
		// try to set a default message using a recipient name... if there's only one recipient
		if (!emailBodyEditorState || (!!emailBodyEditorState && !emailBodyEditorState.hasContent())) {
			let name = emailMessage.contactsToAdd.length === 1 ? emailMessage.contactsToAdd.getByIndex(0).firstName : null;
			if (!name && !!emailMessage.contactsToAdd && emailMessage.contactsToAdd.length === 1) {
				name = emailMessage.contactsToAdd.getByIndex(0).firstName || '';
			}
			if (!!name && !isValidEmail(name)) {
				setEmailBodyEditorState(createRichContentEditorStateWithText(`Hi ${name},`));
			}
		}
	}, [emailMessage, emailBodyEditorState, setEmailBodyEditorState]);

	React.useEffect(() => {
		templates?.signatureTemplates.reset();
		templates?.signatureTemplates?.getNext()?.then(() => {
			emailMessage.signatureTemplate = getDefaultEmailSignature(templates);
		});
	}, [templates, emailMessage]);

	function renderEmailComposerOptions() {
		const crmEmail = userSession.user.userPreferences.bccEmail;
		return (
			<EmailComposerOptions
				bccToCrm={!!crmEmail && !!emailMessage.bcc.find(x => x.email === crmEmail)}
				createFollowUpReminder={createFollowupReminder || false}
				followUpReminderDays={emailMessage?.options?.followUpIfNoResponseInDays || 7}
				hideCcEmployee={true}
				noteVisibility={emailMessage?.options?.noteVisibility}
				onBccToCrmCheckedChange={onBccToCrmCheckedChange}
				onCreateFollowUpReminderCheckedChanged={onCreateFollowUpReminderCheckedChanged}
				onFollowUpReminderDaysChanged={onFollowUpReminderDaysChanged}
				onNotePrivacyToggleChange={onNotePrivacyToggleChange}
				onSaveAsNoteCheckedChange={checked => {
					emailMessage.options.saveAsNote = checked;
				}}
				saveAsNote={emailMessage?.options?.saveAsNote}
				showBccToCrmOption={!!crmEmail}
			/>
		);
	}

	const showAddCcFieldOption = !showingCcField && !hideCcOption;

	return (
		<ReactModal
			isOpen={true}
			className={css(styleSheet.modalContent)}
			portalClassName={css(styleSheet.modalRoot)}
			overlayClassName={css(styleSheet.modalOverlay)}
			shouldCloseOnOverlayClick={false}
		>
			<div className={css(styleSheet.modalHeader)}>
				<h2 className={css(styleSheet.modalHeaderTitle)}>Send Email</h2>

				<DeprecatedCloseButton onClick={() => onRequestClose(null, true)} fillColor='white' />
			</div>
			<div className={css(styleSheet.modalBody)}>
				<div
					className={css(
						styleSheet.composerContainer,
						(showingSendScheduler || !!emailSendEstimateForSentMessage) && styleSheet.composerHidden
					)}
				>
					<div className={css(styleSheet.leftContainer)}>
						<h3 className={css(styleSheet.header)}>New Email</h3>
						<div className={css(styleSheet.body)}>
							<div className={css(styleSheet.bodyInputs)}>
								<RecipientsField
									emailMessage={emailMessage}
									onAddCcButtonClicked={onAddCcButtonClicked}
									onRemoveRecipient={recipient => setRecipientToDelete(recipient)}
									onSelectedRecipientChanged={_selectedRecipient => setSelectedRecipient(_selectedRecipient)}
									selectedRecipient={selectedRecipient}
									showAddCcFieldButton={showAddCcFieldOption}
								/>
								<EmailComposer
									advancedSettingsPopoverContent={renderEmailComposerOptions()}
									attachments={emailMessage.attachments}
									bodyEditorState={emailBodyEditorState}
									bodyError={messageFieldError}
									ccRecipients={emailMessage.cc}
									className={css(styleSheet.bodyEditor)}
									individualRecipient={selectedRecipient}
									onBodyEditorFocusChanged={richTextEditorDidChangeFocus}
									onBodyEditorStateChanged={onMessageEditorStateChanged}
									onBodyFieldScroll={onBodyScroll}
									onBodyTemplateChanged={onEmailMessageTemplateChanged}
									onCcRecipientsChanged={(recipients: Api.IRecipient[]) => (emailMessage.cc = recipients)}
									onRequestRemoveSavedEmailAttachment={onRequestRemoveSavedEmailAttachment}
									onSignatureTemplateChanged={(signatureTemplate: Api.ITemplate) => {
										emailMessage.signatureTemplate = signatureTemplate;
									}}
									onSubjectChanged={onSubjectChanged}
									preBodyContent={
										userSession.account?.features?.contentGeneration?.enabled ? (
											<AIAssistantButton onClick={() => setShowingAiContentGenWizard(true)}>
												Create this email with AI
											</AIAssistantButton>
										) : null
									}
									savedAttachments={emailMessage.savedAttachments}
									showCcField={showingCcField && !hideCcOption}
									subject={emailMessage.subject}
									subjectError={subjectFieldError}
									templates={templates}
									signatureTemplate={emailMessage.signatureTemplate}
								/>
								{!!emailMessage.isSending && <LoadingSpinner className='absolute-center' type='large' />}
							</div>
							<div className={css(baseStyleSheet.horizontalStack, styleSheet.footer)}>
								{canScheduleSend ? (
									<CompoundButton
										buttonTitle={<span>{sendCtaTitle || 'Send Email'}</span>}
										disabled={templates.signatureTemplates?.isFetching || !!emailMessage.isSending}
										onClick={onSendButtonClicked(false)}
										optionsTriggerHoverText='Send Options'
										kind={CompoundButtonType.CtaPrimary}
										styleDeclaration={styleSheet.sendButton}
									>
										<button
											className={css(baseStyleSheet.ctaButtonReverse)}
											disabled={!!emailMessage.isSending || !emailWorkloadQuery.data}
											onClick={onSendButtonClicked(true)}
											style={{ width: '100%' }}
										>
											<span>Schedule Send</span>
										</button>
									</CompoundButton>
								) : (
									<button
										className={css(baseStyleSheet.ctaButton)}
										disabled={templates.signatureTemplates?.isFetching || !!emailMessage.isSending}
										onClick={onSendButtonClicked(false)}
									>
										<span>{sendCtaTitle || 'Send Email'}</span>
									</button>
								)}
								{!hidePreviewButton ? (
									<button
										className={css(baseStyleSheet.ctaButtonReverse)}
										disabled={emailMessage.isSending}
										onClick={() => {
											setShowingPreviewEmail(true);
										}}
									>
										<span>Preview</span>
									</button>
								) : null}
								{!!onRenderAdditionalFooterItems && onRenderAdditionalFooterItems()}
							</div>
						</div>
					</div>
					<div className={css(styleSheet.rightContainer)}>
						{showingAiContentGenWizard ? (
							<AIContentGenerationStatusContext.Provider value={aiContentGenContext}>
								<AIContentGenerationWizard<Api.IEmailMessageCompose, Api.IAIGeneratedContent<Api.IEmailMessageCompose>>
									cautionMessage='Please review content created with AI before sending.'
									onCloseClicked={() => setShowingAiContentGenWizard(false)}
									onGenerateClicked={onGenerateClicked}
									onRetryClicked={onUndoClicked}
									type={AIContentGenerationWizardType.Email}
									assistantMessageMap={AIContentGenerationWizardAssistantEmailMessages}
									requireInstructions
								/>
							</AIContentGenerationStatusContext.Provider>
						) : null}
						{selectedRecipient && !showingAiContentGenWizard ? (
							<ContactContextTabView className={css(styleSheet.bodyContextTabView)} contact={selectedRecipient} />
						) : null}
						{!selectedRecipient && !showingAiContentGenWizard ? (
							<div className={css(styleSheet.rightContainerPlaceholder)}>
								<img src={EmailAutomationGraphic} className={css(styleSheet.emailAutomationGraphic)} />
								<p className={css(styleSheet.rightInfoText)}>
									For any email that is part of an automation, the default signature of the sender will be automatically
									used.
								</p>
							</div>
						) : null}
					</div>
				</div>
				{!!showingSendScheduler && (
					<SendSchedulerOverlay
						estimatedEmailTotal={1}
						hideHeader={true}
						hideSendNow={true}
						isBusy={!!emailMessage.isSending}
						onBackButtonClicked={() => setShowingSendScheduler(false)}
						onSend={onSchedulerSendClicked}
						styles={styleSheet.scheduleSendContainer}
					/>
				)}
				{!!emailSendEstimateForSentMessage && (
					<SendSchedulerCompleteOverlay
						emailCount={1}
						estimate={emailSendEstimateForSentMessage}
						onCloseButtonClicked={onCloseWindowButtonClicked}
						onNavigateToReportingGroupEmail={onCloseWindowButtonClicked}
						styles={[styleSheet.scheduleSendContainer]}
					/>
				)}
				{!!recipientToDelete && (
					<DeleteConfirmation
						bodyText={
							`Are you sure you'd like to remove ${recipientToDelete.name}?` +
							(emailMessage.contactsToAdd.toArray().length === 1
								? ' Removing the last recipient will close the email composer.'
								: '')
						}
						destructiveButtonText='Remove Contact'
						isOpen={!!recipientToDelete}
						onFinish={onDeleteConfirmationFinished}
					/>
				)}
				{showingPreviewEmail ? (
					<Modal
						className='bulk-email-composer-email-preview-modal'
						isOpen={true}
						onRequestClose={() => setShowingPreviewEmail(false)}
					>
						<EmailPreview
							emailContent={emailBodyEditorState.getRawRichTextContent()}
							title={`Preview for ${
								emailMessage.contactsToAdd.length === 1
									? emailMessage.contactsToAdd.getByIndex(0).name || 'contact'
									: 'all contacts'
							}`}
							signatureTemplateId={emailMessage.signatureTemplate?.id}
						/>
					</Modal>
				) : null}
				<ScheduledEmailErrorModal
					onNavigateToReportingGroupEmail={onCloseWindowButtonClicked}
					onRequestClose={() => setScheduleError(null)}
					scheduleError={scheduleError}
				/>
			</div>
		</ReactModal>
	);
}

export const SendMessageComposerDialog = inject(ImpersonationContextKey)(observer(_SendMessageComposerDialog));
