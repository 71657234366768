import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useGetSystemJobQuery } from '../../../../../queries';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { ContactsImportProcessingIcon } from '../../../svgs/icons/ContactsImportProcessingIcon';
import { SuccessCheckIcon } from '../../../svgs/icons/SuccessCheckIcon';
import { styleSheet } from './styles';

export const ProcessStep = ({
	systemJob,
	setSystemJob,
	singularEntity,
	pluralEntity,
}: {
	systemJob: Api.ISystemJob;
	setSystemJob: React.Dispatch<React.SetStateAction<Api.ISystemJob>>;
	singularEntity: string;
	pluralEntity: string;
}) => {
	const isJobComplete = systemJob.percentComplete >= 100;
	const isCompleteAndFailed = systemJob.percentComplete === -1;
	const isInProgress = !isJobComplete && !isCompleteAndFailed;
	const bySupportUser = false;

	const getSystemJobQuery = useGetSystemJobQuery({
		enabled: !!systemJob,
		jobId: systemJob.id,
		refetchInterval: data => {
			if (!systemJob.id) {
				return false;
			}
			return !(data?.percentComplete >= 100) ? 3000 : false;
		},
		refetchOnWindowFocus: false,
	});

	React.useEffect(() => {
		if (!getSystemJobQuery.data) {
			return;
		}
		setSystemJob(getSystemJobQuery.data);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSystemJobQuery.data]);

	const processed = getSystemJobQuery.data?.recordsProcessed || 0;

	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.icon)}>
				<ContactsImportProcessingIcon />
				{!!isJobComplete && <SuccessCheckIcon className={css(styleSheet.iconSpinner, styleSheet.iconSuccess)} />}
				{isInProgress && <LoadingSpinner className={css(styleSheet.iconSpinner)} type='small' />}
			</div>
			<div className={css(styleSheet.message)}>
				{isJobComplete
					? 'All done!'
					: !isCompleteAndFailed
						? `${systemJob ? `Processed ${processed} ${processed > 1 || processed === 0 ? pluralEntity : singularEntity}` : ''}`
						: 'Import failed.'}
			</div>
			<div className={css(styleSheet.detail)}>
				{isJobComplete
					? `We finished importing ${bySupportUser ? 'the' : 'your'} ${pluralEntity}.`
					: !isCompleteAndFailed
						? 'We will email you when we are done. You can close this window now.'
						: `Failed to import all ${pluralEntity}: ${systemJob.status}`}
			</div>
		</div>
	);
};
