import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	FabViewModelKey,
	FullScreenModalViewModelKey,
	IFabComponentProps,
	IFullscreenModalComponentProps,
} from '../../../../models/AppState';
import { CampaignsApprovalViewModel } from '../../../../viewmodels/AppViewModels';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { EmailCancelledGraphic } from '../../../components/svgs/graphics/EmailCancelledGraphic';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends IFullscreenModalComponentProps, IFabComponentProps {
	campaign?: CampaignsApprovalViewModel;
	className?: string;
	styles?: StyleDeclarationValue[];
}

class _BulkEmailCancelled extends React.Component<IProps> {
	private mFabContextDisposer: () => void;

	public componentDidMount() {
		const { fab } = this.props;

		const disposer = fab.registerContext({
			appearance: {
				hidden: true,
			},
		});

		this.mFabContextDisposer = () => {
			disposer();

			this.mFabContextDisposer = null;
		};
	}

	public componentWillUnmount() {
		if (this.mFabContextDisposer) {
			this.mFabContextDisposer();
		}
	}

	public render() {
		const { className, styles } = this.props;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} bulk-email-cancelled ${className || ''}`}>
				<MultiContainerHeader fullscreenHeader='Campaign Cancelled' />
				<div className={css(styleSheet.content, baseStyleSheet.flexCenter)}>
					<div>
						<EmailCancelledGraphic className={css(styleSheet.graphic)} />
						<div className={css(styleSheet.title)}>Nevermind, we cancelled that campaign.</div>
						<div className={css(styleSheet.subtitle)}>
							Your success manager decided not to send that campaign after all.
						</div>
						<button className={css(styleSheet.button, baseStyleSheet.ctaButton)} onClick={this.closeModal}>
							<span>Back to Dash</span>
						</button>
					</div>
				</div>
			</div>
		);
	}

	private closeModal = () => {
		this.props.fullscreenModal.dismissModal();
	};
}

const BulkEmailCancelledAsObserver = observer(_BulkEmailCancelled);
export const BulkEmailCancelled = inject(FullScreenModalViewModelKey, FabViewModelKey)(BulkEmailCancelledAsObserver);
