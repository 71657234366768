import { ILocationState } from '@AppModels/.';
import { ISingleEmailComposerComponentProps, SingleEmailComposerKey } from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { getDefaultDateStringValue } from '@AppModels/UiUtils';
import { ContactViewModel, EmailActivityViewModel, IContact } from '@ViewModels';
import { Avatar } from '@WebComponents/Avatar';
import { FeedCard, IFeedCardCallbackProps } from '@WebComponents/cards/FeedCard';
import { FeedCardNoteContent } from '@WebComponents/cards/FeedCardNoteContent';
import { FullscreenModalNavLink } from '@WebComponents/fullscreen/FullscreenModalNavLink';
import { CheckmarkIcon } from '@WebComponents/svgs/icons/CheckmarkIcon';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { emailActivityTintColor, success } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends IFeedCardCallbackProps, IEventLoggingComponentProps, ISingleEmailComposerComponentProps {
	className?: string;
	emailActivity: EmailActivityViewModel;
}

interface IState {
	showingPreview?: boolean;
}

class _EmailActivityCard extends React.Component<IProps, IState> {
	public readonly state: IState = {
		showingPreview: true,
	};

	public render() {
		const { className, emailActivity, onSuppressClicked } = this.props;
		const locationState: ILocationState<ContactViewModel, IContact> = {
			viewModel: emailActivity.contact,
		};
		return (
			<FeedCard
				className={`${css(styleSheet.container)} email-activity-card ${className || ''}`}
				indicatorColor={emailActivityTintColor}
				onSuppressClicked={onSuppressClicked}
				showSuppressOption={true}
				footer={this.renderFooter()}
			>
				<div className={css(styleSheet.header)}>
					<Avatar className={css(styleSheet.avatar)} entityVm={emailActivity.contact} />
					<div className={css(styleSheet.headerDescription)}>
						<div className={css(baseStyleSheet.truncateText, styleSheet.contactNameContainer)}>
							<FullscreenModalNavLink
								className={css(styleSheet.contactName)}
								title={emailActivity.contact?.name}
								to={{
									pathname: `/people/${emailActivity.contact?.id}`,
									state: locationState,
								}}
							>
								{emailActivity.contact?.name}
							</FullscreenModalNavLink>
						</div>
						{this.renderTitleDescription()}
					</div>
				</div>
				{this.renderEmailMessageHeader()}
				{!!emailActivity.sentContent && (
					<div className={css(styleSheet.body)}>
						<FeedCardNoteContent note={emailActivity.sentContent} />
					</div>
				)}
			</FeedCard>
		);
	}

	private renderFooter() {
		const { emailActivity } = this.props;
		if (!this.noReplyInAWeek || !emailActivity.contact) {
			return null;
		}

		return (
			<div className={css(styleSheet.footer)}>
				<span />
				<button
					className={css(baseStyleSheet.ctaButtonSmall, styleSheet.sendButton)}
					onClick={this.onSendMessageClicked}
				>
					<span>Send Email</span>
				</button>
			</div>
		);
	}

	private renderEmailMessageHeader() {
		const { emailActivity } = this.props;
		if (
			!!emailActivity &&
			!!emailActivity.status &&
			(emailActivity.status === 'Replied' || emailActivity.status === 'Opened')
		) {
			return (
				<div className={css(styleSheet.viewHeader)}>
					<CheckmarkIcon fillColor={success} />
					<div>
						<span>{emailActivity.status === 'Opened' ? 'Message viewed on' : 'Message replied to on'}</span>
						&nbsp;
						<span>
							{getDefaultDateStringValue(
								emailActivity.status === 'Opened' ? emailActivity.openDate : emailActivity.repliedDate
							)}
						</span>
					</div>
				</div>
			);
		}
	}

	private renderTitleDescription() {
		const { emailActivity } = this.props;
		let description: React.ReactNode = null;
		if (emailActivity.status) {
			switch (emailActivity.status) {
				case 'Opened': {
					description = this.noReplyInAWeek ? 'has not replied in a week' : 'opened your email';
					break;
				}
				case 'Replied': {
					description = 'replied to your email';
					break;
				}
				case 'Sent': {
					description = `has not replied${this.noReplyInAWeek ? ' in a week' : ''}`;
					break;
				}
				default: {
					break;
				}
			}
		}
		return description ? (
			<div>
				&nbsp;
				{description}
			</div>
		) : null;
	}

	@computed
	private get noReplyInAWeek() {
		const { emailActivity } = this.props;
		return (
			!!emailActivity.sentDate &&
			moment().diff(moment(emailActivity.sentDate), 'days') >= 7 &&
			(!emailActivity.repliedDate || emailActivity.status !== 'Replied')
		);
	}

	private onSendMessageClicked = () => {
		const { logInput, singleEmailComposer, emailActivity } = this.props;

		logInput('SendMessage', 'Click');

		singleEmailComposer.showForRecipients([emailActivity.contact], this.onEmailModalFinished);
	};

	private onEmailModalFinished = (didSend: boolean) => {
		const { onSuppressClicked } = this.props;
		if (!!didSend && !!onSuppressClicked) {
			onSuppressClicked();
		}
	};
}

const EmailActivityCardAsObserver = observer(_EmailActivityCard);
const EmailActivityCardWithContext = inject(SingleEmailComposerKey)(EmailActivityCardAsObserver);
export const EmailActivityCard = withEventLogging(EmailActivityCardWithContext, 'EmailActivityCard');
