import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInfiniteBoardsQuery } from '../../../../../queries';
import {
	invalidateGetOpportunitiesByCompany,
	useGetOpportunitiesByCompany,
} from '../../../../../queries/Boards/useGetOpportunitiesByCompany';
import {
	invalidateGetOpportunitiesByContact,
	useGetOpportunitiesByContact,
} from '../../../../../queries/Boards/useGetOpportunitiesByContact';
import { baseStyleSheet } from '../../../../styles/styles';
import { AddBoardItemButton } from '../../../boards/AddBoardItemButton';
import { DataboardsIcon } from '../../../svgs/icons/DataboardsIcon';
import { EditOpportunityModal } from '../EditOpportunity';
import { OpportunityCard } from '../OpportunityCard';
import { styleSheet } from './styles';

const _EntityOpportunities = ({
	className,
	entity,
	styles,
	isContactMode,
}: {
	className?: string;
	entity: Api.IContact | Api.ICompany;
	styles?: StyleDeclarationValue[];
	isContactMode: boolean;
}) => {
	const [opportunityToAdd, setOpportunityToAdd] = React.useState<Api.IOpportunity>(null);
	const boardsQuery = useInfiniteBoardsQuery({
		type: 'Opportunity',
	});
	const boards = boardsQuery.data?.pages.map(page => page.values).flat() ?? [];

	const opportunitiesByContactQuery = useGetOpportunitiesByContact({
		enabled: isContactMode,
		contactId: entity.id,
	});
	const opportunitiesByCompanyQuery = useGetOpportunitiesByCompany({
		enabled: !isContactMode,
		companyId: entity.id,
	});

	const onAddItemClicked = () => {
		if (isContactMode) {
			const primaryContact = entity as Api.IContact;
			setOpportunityToAdd({
				primaryContact,
				name: primaryContact.firstName + ' ' + primaryContact.lastName,
			});
		} else {
			setOpportunityToAdd({
				company: entity,
				name: entity.companyName,
			});
		}
	};

	const onOpportunitySave = (opportunity: Api.IOpportunity) => {
		setOpportunityToAdd(null);
		invalidateGetOpportunitiesByContact({ contactId: entity.id });
		if (opportunity.company?.id) {
			invalidateGetOpportunitiesByCompany({ companyId: opportunity.company.id });
		}
	};

	const opportunities = React.useMemo<Api.IOpportunity[]>(() => {
		if (isContactMode) return (opportunitiesByContactQuery.data?.values ?? []) as Api.IOpportunity[];
		return (opportunitiesByCompanyQuery.data?.values ?? []) as Api.IOpportunity[];
	}, [opportunitiesByContactQuery.data, opportunitiesByCompanyQuery.data, isContactMode]);

	return (
		<div className={`${css(...(styles || []))} ${className || ''}`}>
			<div className={css(styleSheet.contentSection)}>
				<header className={css(styleSheet.contentHeader)}>
					<figure className={css(styleSheet.boardIconContainer, baseStyleSheet.bgGreen)}>
						<DataboardsIcon height={16} width={21} className={css(styleSheet.opportuntitiesIcon)} />
					</figure>
					<h6 className={css(styleSheet.contentListTitle)}>Current Opportunities</h6>
					<AddBoardItemButton className={css(styleSheet.contentAddButton)} onClick={onAddItemClicked}>
						Add Opportunity
					</AddBoardItemButton>
				</header>
				<div className={css(styleSheet.cardList)}>
					{opportunities.length ? (
						<>
							{opportunities.map(x => {
								return (
									// TODO: Update this to use the new opportunity card
									<OpportunityCard
										className={css(styleSheet.item)}
										key={x.id}
										opportunity={x}
										showStageIndicator={true}
									/>
								);
							})}
						</>
					) : null}

					{!opportunitiesByContactQuery.isFetching &&
					!opportunitiesByCompanyQuery.isFetching &&
					!opportunities.length ? (
						<p className={css(styleSheet.emptyText)}>No opportunities exist for this contact.</p>
					) : null}

					{opportunityToAdd ? (
						<EditOpportunityModal
							opportunity={opportunityToAdd}
							onSave={onOpportunitySave}
							onCancel={() => setOpportunityToAdd(null)}
							boards={boards}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export const EntityOpportunities = observer(_EntityOpportunities);
