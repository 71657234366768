import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import clsx from 'clsx';
import * as React from 'react';
import { useHistory } from 'react-router';
import { animated, useSpring } from 'react-spring-legacy';
import { config } from 'react-spring-legacy/renderprops';
import { useEventLogging } from '../../../../../models/Logging';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { brandPrimary, grayIconFill } from '../../../../styles/colors';
import { DeprecatedCloseButton, ICloseButtonProps } from '../../../DeprecatedCloseButton';
import { DashboardCard, DashboardCardHeaderLabel } from '../../DashboardCard';
import { styleSheet } from './styles';

type withStyleDeclaration<T> = T & { styleDeclaration?: StyleDeclarationValue | StyleDeclarationValue[] };

export const useIntegrationCard = ({
	pendingAction,
	integrationEnableEventName,
	closeIntegrationEventName,
	enableUrlPath,
	checkApiPendingAction = true,
}: {
	pendingAction: Api.PendingActions;
	integrationEnableEventName: string;
	enableUrlPath: string;
	closeIntegrationEventName: string;
	checkApiPendingAction?: boolean;
}) => {
	const userSession = useUserSession();
	const logger = useEventLogging();

	const initialShowCard = () => {
		const hasApiPendingAction = userSession.pendingActions.includes(pendingAction);
		if (checkApiPendingAction && !hasApiPendingAction) {
			return false;
		}

		return !(window.localStorage.getItem(pendingAction) === 'true');
	};

	const [showCard, setShowCard] = React.useState(initialShowCard);
	const history = useHistory();

	const enableIntegration = () => {
		logger.logEvent(integrationEnableEventName);
		history.push(enableUrlPath);
	};

	const onClose = () => {
		logger.logEvent(closeIntegrationEventName);
		window.localStorage.setItem(pendingAction, 'true');
		setShowCard(false);
	};

	const getCloseButtonProps = (props?: Partial<ICloseButtonProps>): ICloseButtonProps => {
		return {
			fillColor: grayIconFill,
			...props,
			onClick: ev => {
				onClose();
				props?.onClick?.(ev);
			},
		};
	};
	const getLogoImgProps = (
		props: Partial<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>>
	): React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> => {
		return {
			...props,
			className: clsx(css(styleSheet.logo), props.className),
		};
	};
	const getCtaButtonProps = (
		props: withStyleDeclaration<
			React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
		>
	): React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> => {
		const { styleDeclaration, ...rest } = props;
		const styleDeclarations = Array.isArray(styleDeclaration) ? styleDeclaration : [styleDeclaration];
		return {
			...rest,
			className: clsx(css(...styleDeclarations), props.className),
			onClick: ev => {
				enableIntegration();
				props?.onClick?.(ev);
			},
		};
	};
	const getIntegrationTitleProps = (
		props: withStyleDeclaration<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>>
	): React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> => {
		const { styleDeclaration, ...rest } = props;
		const styleDeclarations = Array.isArray(styleDeclaration) ? styleDeclaration : [styleDeclaration];
		return {
			children: 'Integration Available!',
			...rest,
			className: clsx(css(styleSheet.integrationTitle, ...styleDeclarations), props.className),
		};
	};
	return {
		getCloseButtonProps,
		getCtaButtonProps,
		getIntegrationTitleProps,
		getLogoImgProps,
		showCard,
	};
};

const AnimatedDashboardCard = animated(DashboardCard);

interface IProps {
	headerLabel?: string;
	IntegrationGraphic: React.FC | React.ReactChild;
	closeButtonProps: ICloseButtonProps;
	logoImgProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
	children: React.ReactNode;
	ctaButtonProps: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
	integrationTitleProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
	logoBgColor?: string;
	logoBorder?: string;
}

export function IntegrationCard({
	closeButtonProps,
	logoImgProps,
	logoBgColor,
	headerLabel = '',
	IntegrationGraphic,
	children,
	ctaButtonProps,
	integrationTitleProps,
	logoBorder,
}: IProps) {
	const springStyles = useSpring({
		config: config.gentle,
		delay: 100,
		from: { opacity: 0, transform: 'translate3d(0,10px,0)' },
		to: { opacity: 1, transform: 'translate3d(0,0,0)' },
	});
	return (
		<AnimatedDashboardCard style={springStyles} styleDeclaration={styleSheet.root}>
			<div className={css(styleSheet.close)}>
				<DeprecatedCloseButton {...closeButtonProps} />
			</div>
			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.leftCol)}>
					<figure className={css(styleSheet.logoHeader)}>
						<picture
							className={css(styleSheet.logoWrapper)}
							style={{ background: logoBgColor ?? brandPrimary, border: logoBorder ?? 'none' }}
						>
							<img height='24px' width='24px' {...logoImgProps} />
						</picture>
						<DashboardCardHeaderLabel>{headerLabel}</DashboardCardHeaderLabel>
					</figure>
					<figure>{typeof IntegrationGraphic === 'function' ? <IntegrationGraphic /> : IntegrationGraphic}</figure>
				</div>
				<section className={css(styleSheet.rightCol)}>
					<h2 {...integrationTitleProps} />
					<div className={css(styleSheet.integrationMessage)}>{children}</div>
					<button {...ctaButtonProps} />
				</section>
			</div>
		</AnimatedDashboardCard>
	);
}
