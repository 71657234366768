import { IPrincipal } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { getDisplayName, getPrincipalInitials } from '../../../models/UiUtils';
import { Avatar2 } from '../Avatar2';
import { styleSheet } from './styles';

interface IProps {
	children?: React.ReactNode;
	className?: string;
	contentClassName?: string;
	infoClassName?: string;
	onClick?(): void;
	principal?: IPrincipal;
	rightAccessory?: React.ReactNode;
	truncate?: boolean;
}

export const PrincipalListItem: React.FC<IProps> = ({
	children,
	className = '',
	contentClassName = '',
	infoClassName = '',
	onClick,
	principal,
	rightAccessory,
	truncate = true,
}) => {
	return (
		<li className={`${css(styleSheet.container)} principal-item-list-item ${className}`} onClick={onClick}>
			<Avatar2
				styleDeclaration={[styleSheet.avatar]}
				fallbackText={getPrincipalInitials(principal)}
				imgSrc={principal?.profilePic}
			/>
			<div
				className={`${css(styleSheet.content)} ${infoClassName} principal-item-list-item-info ${
					truncate ? 'truncate-text' : ''
				} ${contentClassName}`}
			>
				<div className={`${css(styleSheet.title)} truncate-text`}>
					<div className={`${css(styleSheet.userName)} truncate-text`}>{getDisplayName(principal)}</div>
					{rightAccessory}
				</div>
				{children}
			</div>
		</li>
	);
};
