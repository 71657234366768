import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { withEventLogging } from '../../../../models/Logging';
import { DarkMainContainerBackground } from '../../../components/MainContainerBackground';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { CardGroup } from '../../../components/dashboard/CardGroup';
import { EmailInCircleGradiantGraphic } from '../../../components/svgs/graphics/EmailInCircleGradiantGraphic';
import { EmailSignatureGraphic } from '../../../components/svgs/graphics/EmailSignatureGraphic';
import { PeopleInWindowCircleGradiantGraphic } from '../../../components/svgs/graphics/PeopleInWindowCircleGradiantGraphic';
import { INavigationItemProps } from '../../../containers/MainContainer';
import { CoffeeCard } from '../../components/dashboard/CoffeeCard';
import { QuickAction } from './presentation';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps, RouteComponentProps, INavigationItemProps {}
class _CoffeeDashboard extends Component<IProps> {
	public render() {
		const { userSession, routeContainerClassName = '' } = this.props;
		const isCoffeeEnabled = userSession?.account?.features?.aida?.enabled;
		return (
			<div className={`${routeContainerClassName} ${css(styleSheet.cardGroupContainer)}`}>
				<MultiContainerHeader appBarHeader='Dashboard' />
				<DarkMainContainerBackground />
				{isCoffeeEnabled ? (
					<div className={css(styleSheet.cardGroupCoffee)}>
						<CardGroup headerClassName={css(styleSheet.cardGroupHeader)} title='Launch Coffee'>
							<CoffeeCard onClick={this.navToCoffee} />
						</CardGroup>
					</div>
				) : null}
				<div className={css(styleSheet.quickActionsColumn)}>
					<CardGroup headerClassName={css(styleSheet.cardGroupHeader)} title='Quick Actions'>
						<div className={css(styleSheet.quickActions)}>
							{userSession.account.isAdmin
								? [this.renderManageEmailTemplatesCard(), this.renderManageUsersCard()]
								: this.renderManageEmailSignatureCard()}
						</div>
					</CardGroup>
				</div>
			</div>
		);
	}

	private renderManageEmailTemplatesCard() {
		return (
			<QuickAction
				description='Manage Email Templates'
				graphic={<EmailInCircleGradiantGraphic />}
				key='manage-email-templates'
				onClick={this.navToTemplates}
			/>
		);
	}

	private renderManageUsersCard() {
		return (
			<QuickAction
				description='Manage Users'
				graphic={<PeopleInWindowCircleGradiantGraphic />}
				key='manage-users'
				onClick={this.navToManageUsers}
			/>
		);
	}

	private renderManageEmailSignatureCard() {
		return (
			<QuickAction
				description='Manage Email Signature'
				graphic={<EmailSignatureGraphic />}
				key='manage-email-signature'
				onClick={this.navToMangeEmailSignature}
			/>
		);
	}

	private navToCoffee = () => {
		window.location.href = 'https://secure.coffee.inc';
	};

	private navToTemplates = () => {
		this.props.history.push(`/templates`);
	};

	private navToManageUsers = () => {
		this.props.history.push(`/settings/admin/manage-users`);
	};

	private navToMangeEmailSignature = () => {
		this.props.history.push(`/settings/email-signature`);
	};
}

const CoffeeDashboarddAsObserver = observer(_CoffeeDashboard);
const CoffeeDashboardWithContext = inject(UserSessionViewModelKey)(CoffeeDashboarddAsObserver);
export const CoffeeDashboard = withRouter(withEventLogging(CoffeeDashboardWithContext, 'CoffeeDashboard'));
