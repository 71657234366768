import { StyleSheet } from 'aphrodite';
import { titles } from '../../../styles/colors';
import { cardStyle } from '../../../styles/styles';

export const HtmlNewsletterTemplateCardHeight = 230;
export const HtmlNewsletterTemplateCardWidth = 350;

export const styleSheet = StyleSheet.create({
	editNameLabel: {
		color: titles,
		fontSize: 14,
	},
	templateCard: {
		...cardStyle,
		boxSizing: 'border-box',
		display: 'inline-flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		fontSize: 14,
		height: '100%',
		minHeight: HtmlNewsletterTemplateCardHeight,
		padding: 18,
		position: 'relative',
		width: '100%',
		minWidth: HtmlNewsletterTemplateCardWidth,
	},
	templateCardFooter: {
		gap: 6,
	},
	templateCardHeader: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		marginBottom: 18,
	},
	templateCardMoreMenu: {
		alignSelf: 'flex-end',
	},
});
