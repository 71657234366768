import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useFinishImportDonationsMutation, useImportDonationsMutation } from '../../../../../queries';
import { baseStyleSheet } from '../../../../styles/styles';
import { MultiContainerHeader } from '../../../MultiContainerHeader';
import { MapStep } from '../../Steps/Map';
import { ProcessStep } from '../../Steps/Process';
import { UploadStep } from '../../Steps/Upload';
import { styleSheet } from './styles';

enum Step {
	Uploading,
	Mapping,
	Processing,
}

export const DonationsImport = ({ onCancel }: { onCancel(): void }) => {
	const [file, setFile] = React.useState(null);
	const [currentStep, setCurrentStep] = React.useState(Step.Uploading);
	const [systemJob, setSystemJob] = React.useState<Api.ISystemJob>(null);
	const [fields, setFields] = React.useState<Api.IImportDonationFieldConfig[]>(null);
	const [headers, setHeaders] = React.useState<string[]>(null);
	const [samples, setSamples] = React.useState<string[][]>(null);
	const [mappings, setMappings] = React.useState<Api.IDictionary<string>>({});
	const [mappingErrors, setMappingErrors] = React.useState<string[]>([]);

	const importDonationsMutation = useImportDonationsMutation({
		onSuccess: (result: Api.IImportDonationPreview) => {
			setSystemJob({ id: result.id });
			setFields(result.fields);
			setHeaders(result.header);
			setSamples(result.sample);
			setCurrentStep(currentStep + 1);
		},
	});

	const finishImportDonationsMutation = useFinishImportDonationsMutation({
		onSuccess: (newSystemJob: Api.ISystemJob) => {
			setSystemJob(newSystemJob);
			setCurrentStep(currentStep + 1);
		},
	});

	const cancel = () => {
		onCancel();
		setCurrentStep(Step.Uploading);
	};

	const next = async () => {
		switch (currentStep) {
			case Step.Uploading:
				importDonationsMutation.mutate({ file });
				break;
			case Step.Mapping:
				finishImportDonationsMutation.mutate({
					systemJobId: systemJob.id,
					options: { fieldMapping: mappings },
				});
				break;
			default:
				throw new Error('Impossible scenario');
		}
	};

	const getHeader = () => {
		switch (currentStep) {
			case Step.Uploading:
				return 'Import Donations from Spreadsheet';
			case Step.Mapping:
				return 'Please map the correct columns using the drop downs';
			case Step.Processing:
				return 'Importing...';
			default:
				throw new Error('Impossible scenario');
		}
	};

	const isUploading = currentStep === Step.Uploading;
	const isMapping = currentStep === Step.Mapping;
	const isProcessing = currentStep === Step.Processing;

	const canGoNext = React.useMemo(() => {
		if (isUploading) {
			return !!file;
		} else if (isMapping) {
			return mappingErrors.length === 0;
		} else {
			return false;
		}
	}, [isUploading, isMapping, file, mappingErrors]);

	const importError = finishImportDonationsMutation.error?.systemMessage;

	return (
		<div className={css(styleSheet.container)}>
			<MultiContainerHeader fullscreenHeader={getHeader()} />

			{isUploading ? <UploadStep isLoading={importDonationsMutation.isLoading} file={file} setFile={setFile} /> : null}

			{isMapping ? (
				<MapStep<Api.IImportDonationFieldConfig>
					isLoading={finishImportDonationsMutation.isLoading}
					headers={headers}
					samples={samples}
					fields={fields}
					mappings={mappings}
					setMappings={setMappings}
					importError={importError}
					mappingErrors={mappingErrors}
					setMappingErrors={setMappingErrors}
				/>
			) : null}

			{isProcessing && !!systemJob ? (
				<ProcessStep
					systemJob={systemJob}
					setSystemJob={setSystemJob}
					singularEntity='donation'
					pluralEntity='donations'
				/>
			) : null}

			{!isProcessing ? (
				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={next} disabled={!canGoNext}>
						<span>Next</span>
					</button>
					<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={cancel}>
						<span>Cancel</span>
					</button>
				</div>
			) : null}

			{isProcessing ? (
				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={cancel}>
						<span>Close Window</span>
					</button>
				</div>
			) : null}
		</div>
	);
};
