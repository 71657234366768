import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateBoardsQuery,
	useCreateBoardMutation,
	useDeleteBoardMutation,
	useInfiniteBoardsQuery,
	useUpdateBoardMutation,
} from '../../../../queries';
import { BoardStageDefinitionViewModel } from '../../../../viewmodels/AppViewModels';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../../components/MoreMenu';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { EditBoardModal } from '../../../components/boards/EditBoard';
import { DataboardsIcon } from '../../../components/svgs/icons/DataboardsIcon';
import { HeartIcon } from '../../../components/svgs/icons/HeartIcon';
import { PolicyIcon } from '../../../components/svgs/icons/PolicyIcon';
import { white } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

const getInitialBoardColumns = (userSession: Api.UserSessionContext) => {
	const industry = userSession.account.toJs().additionalInfo?.industry;
	switch (industry) {
		case Api.Industry.RealEstate: {
			return ['Leads', 'Appointment Booked', 'Offer Submitted', 'Under Contract', 'Closed'];
		}
		default: {
			return ['Leads', 'Rescheduled', 'Demo Booked', 'Committed', 'Closing', 'Won', 'Lost'];
		}
	}
};

export function DataBoardsHome({ routeContainerClassName = '' }: { routeContainerClassName?: string }) {
	const userSession = useUserSession();

	const { policiesEnabled, donationsEnabled, opportunitiesEnabled } = userSession.account.features?.boards ?? {};

	const showDonations = donationsEnabled;
	const showPolicies = policiesEnabled;
	const showOpportunities = opportunitiesEnabled;

	const [isEditBoardOpen, setIsEditBoardOpen] = React.useState(false);
	const [boardToEdit, setBoardToEdit] = React.useState<Api.BoardViewModel>(null);
	const [boardToDelete, setBoardToDelete] = React.useState<Api.IBoard>(null);
	const errorMessages = useErrorMessages();

	const boardsQuery = useInfiniteBoardsQuery({
		type: 'Opportunity',
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});

	const createBoardMutation = useCreateBoardMutation({
		onSuccess: () => invalidateBoardsQuery(),
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});
	const updateBoardMutation = useUpdateBoardMutation({
		onSuccess: () => invalidateBoardsQuery(),
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});
	const deleteBoardMutation = useDeleteBoardMutation({
		onSuccess: () => invalidateBoardsQuery(),
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});

	const boards = boardsQuery.data?.pages.map(page => page.values).flat() ?? [];

	const editBoard = (board: Api.IBoard) => {
		setBoardToEdit(new Api.OpportunitiesBoardViewModel(userSession, board) as unknown as Api.BoardViewModel);
		setIsEditBoardOpen(true);
	};

	const deleteBoard = (board: Api.IBoard) => {
		setBoardToDelete(board);
	};

	const createBoard = () => {
		setIsEditBoardOpen(true);
	};

	const onCreateBoard = async (name: string, columns: BoardStageDefinitionViewModel[]) => {
		const newBoard: Api.IBoard = {
			name,
			stages: (columns || []).map(column => ({
				...column.toJs(),
				templateType: Api.BoardTemplateType.Opportunity,
			})),
			template: {
				type: Api.BoardTemplateType.Opportunity,
			},
		};

		const result = await createBoardMutation.mutateAsync(newBoard);

		setIsEditBoardOpen(false);
		return new Api.OpportunitiesBoardViewModel(userSession, result) as unknown as Api.BoardViewModel;
	};

	const onEditBoard = async (board: Api.BoardViewModel, name: string, columns: BoardStageDefinitionViewModel[]) => {
		const updatedBoard: Api.IBoard = {
			...board.toJs(),
			name,
			stages: columns.map(x => x.toJs()),
		};
		const result = await updateBoardMutation.mutateAsync(updatedBoard);

		setIsEditBoardOpen(false);
		return new Api.OpportunitiesBoardViewModel(userSession, result) as unknown as Api.BoardViewModel;
	};

	const onDeleteBoard = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			await deleteBoardMutation.mutateAsync(boardToDelete.id);
		}

		setBoardToDelete(null);
	};

	const initialColumns: BoardStageDefinitionViewModel[] = getInitialBoardColumns(userSession).map(columnTitle => {
		const column = new BoardStageDefinitionViewModel();
		column.name = columnTitle;
		column.enableStageIndicator = ['Lost', 'Closed'].indexOf(columnTitle) > -1;
		return column;
	});

	const isLoadingOpportunityBoards = boardsQuery.isLoading;

	return (
		<div className={`${css(styleSheet.container)} ${routeContainerClassName}`}>
			<MultiContainerHeader appBarHeader={<h1 className={css(baseStyleSheet.breadcrumbTitle)}>Data Boards</h1>} />

			{showDonations ? (
				<div className={css(styleSheet.cardsRow)}>
					<section className={css(styleSheet.boardRowHeader)}>
						<h2 className={css(styleSheet.boardTypeTitle)}>Donations Board</h2>

						<p className={css(styleSheet.description)}>Keep track of individual donations and donation amounts.</p>
					</section>

					<div className={css(styleSheet.cardsContainer)}>
						<div className={css(styleSheet.card)}>
							<Link to='/dataBoards/donations' className={css(styleSheet.boardLink)}>
								<div className={css(styleSheet.boardIconContainer, baseStyleSheet.bgPink)}>
									<HeartIcon height={24} width={24} className={css(styleSheet.heartIcon)} />
								</div>

								<h6 className={css(styleSheet.boardTitle)}>Donations</h6>
							</Link>
						</div>
					</div>
				</div>
			) : null}

			<div className={css(styleSheet.separator)} />

			{showPolicies ? (
				<>
					<div className={css(styleSheet.cardsRow)}>
						<section className={css(styleSheet.boardRowHeader)}>
							<h2 className={css(styleSheet.boardTypeTitle)}>Policies Board</h2>

							<p className={css(styleSheet.description)}>Keep track of policies.</p>
						</section>

						<div className={css(styleSheet.cardsContainer)}>
							<div className={css(styleSheet.card)}>
								<Link to='/dataBoards/policies' className={css(styleSheet.boardLink)}>
									<div className={css(styleSheet.boardIconContainer, baseStyleSheet.bgBrightBlue)}>
										<PolicyIcon height={24} width={24} className={css(styleSheet.heartIcon)} />
									</div>

									<h6 className={css(styleSheet.boardTitle)}>Policies</h6>
								</Link>
							</div>
						</div>
					</div>

					<div className={css(styleSheet.separator)} />
				</>
			) : null}

			{showOpportunities ? (
				<div className={css(styleSheet.cardsRow)}>
					<section className={css(styleSheet.boardRowHeader)}>
						<h2 className={css(styleSheet.boardTypeTitle)}>Opportunity Boards</h2>

						<p className={css(styleSheet.description)}>Track your opportunities from cold leads to raving clients.</p>
					</section>

					<div className={css(styleSheet.cardsContainer)}>
						{isLoadingOpportunityBoards ? <LoadingSpinner type='large' /> : null}

						{boards.map(board => {
							return (
								<div className={css(styleSheet.card)} key={`board-card-${board.id}`}>
									<div className={css(styleSheet.moreMenuContainer)}>
										<MoreMenu>
											<MoreMenuItem onClick={() => editBoard(board)}>Edit</MoreMenuItem>
											<MoreMenuItem onClick={() => deleteBoard(board)}>Delete</MoreMenuItem>
										</MoreMenu>
									</div>

									<Link to={`/dataBoards/opportunities/${board.id}`} className={css(styleSheet.boardLink)}>
										<div className={css(styleSheet.boardIconContainer, styleSheet.greenBackground)}>
											<DataboardsIcon fill={white} className={css(styleSheet.boardIcon)} />
										</div>

										<h6 className={css(styleSheet.boardTitle)}>{board.name}</h6>
									</Link>
								</div>
							);
						})}

						<button className={css(styleSheet.newBoardCard)} onClick={createBoard}>
							<h6 className={css(styleSheet.newBoardTitle)}>+ Add Opportunity Board</h6>
						</button>
					</div>
				</div>
			) : null}

			<div className={css(styleSheet.separator)} />

			<EditBoardModal
				board={boardToEdit}
				initialColumns={initialColumns}
				modalProps={{
					isOpen: isEditBoardOpen,
					onRequestClose: () => setIsEditBoardOpen(false),
				}}
				onEditBoard={onEditBoard}
				onCreateBoard={onCreateBoard}
			/>

			{boardToDelete ? (
				<DeleteConfirmation
					bodyText={`Are you sure you want to delete the ${boardToDelete.name} board?`}
					isOpen
					onFinish={onDeleteBoard}
				/>
			) : null}
		</div>
	);
}
