import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { TextingAutomationStepReference } from '../../../../extViewmodels';
import { useEventLogging } from '../../../../models/Logging';
import { getPlainTextPreviewForRawRichTextContent } from '../../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import {
	invalidateAutomationTemplateFilter,
	useAutomationTemplateFilterInfiniteQuery,
	useDeleteAutomationTemplateMutation,
} from '../../../../queries';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../../components/MoreMenu';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { OutboundTextMessageBubble } from '../../../components/texting/MessageBubble/OutboundTextMessageBubble';
import { brandPrimary } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { styleSheet } from './styles';

export function TextingCampaignBrowserCards() {
	const { logApiError } = useEventLogging('TextingCampaignBrowserCards');
	const errorMessages = useErrorMessages();
	const fullscreenModal = useFullscreenModal();
	const [campaignToDelete, setCampaignToDelete] = React.useState<Api.IAutomationTemplateReference>(null);
	const automationTemplateFilterInfiniteQuery = useAutomationTemplateFilterInfiniteQuery({
		filter: {
			criteria: [
				{
					property: Api.AutomationTemplateFilterProperty.SingleStep,
					value: true,
				},
				{
					property: Api.AutomationTemplateFilterProperty.TriggerType,
					value: 'TextingCampaignAutomationTrigger',
				},
				{
					property: Api.AutomationTemplateFilterProperty.Status,
					value: 'Archived',
					op: Api.FilterOperator.Not,
				},
			],
			op: Api.FilterOperator.And,
		},
	});
	const deleteAutomationTemplateMutation = useDeleteAutomationTemplateMutation({
		onError: error => {
			logApiError('DeleteTextingCampaign-Error', error);
			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			invalidateAutomationTemplateFilter();
			setCampaignToDelete(null);
		},
	});

	const deleteCampaign = (template: Api.IAutomationTemplateReference) => {
		setCampaignToDelete(template);
	};
	const onDeleteConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
		if (result?.isDestructive && !cancel) {
			deleteAutomationTemplateMutation.mutate({ id: campaignToDelete.id });
		} else {
			setCampaignToDelete(null);
		}
	};

	if (automationTemplateFilterInfiniteQuery.status !== 'success') {
		return (
			<div className={css(bs.flex, bs.justifyCenter, bs.wFull, bs.pt2)}>
				<LoadingSpinner type='small' />
			</div>
		);
	}
	return (
		<div className={css(styleSheet.cardList)}>
			{automationTemplateFilterInfiniteQuery.data.pages.map(page => {
				return page.values
					.filter(
						template =>
							template &&
							template.publishedSteps.length > 0 &&
							template.publishedSteps[0]._type === 'TextingAutomationStep'
					)
					.map(template => {
						const step = template.publishedSteps[0] as TextingAutomationStepReference;
						const { text } = getPlainTextPreviewForRawRichTextContent(step.content, { maxCharCount: 100 });
						return (
							<div key={step.id} className={css(styleSheet.card, bs.shadow)}>
								<span title={template.name} className={css(styleSheet.cardName)}>
									{template.name}
								</span>

								<div className={css(styleSheet.moreMenu)}>
									<MoreMenu>
										<MoreMenuItem onClick={() => deleteCampaign(template)}>Delete</MoreMenuItem>
									</MoreMenu>
								</div>

								<OutboundTextMessageBubble
									backgroundColor={brandPrimary}
									styleDeclarations={[bs.flex1, bs.wFull, bs.boxBorder]}
								>
									{text}
								</OutboundTextMessageBubble>
								<button
									className={css(bs.ctaButtonReverseSmall, bs.bgTransparent)}
									onClick={() => fullscreenModal.history.push(`/texting-campaign/template-search/${template.id}`)}
								>
									View Text Campaign
								</button>
							</div>
						);
					});
			})}
			{automationTemplateFilterInfiniteQuery.isFetchingNextPage ? (
				<div className={css(bs.wFull, bs.flex, bs.justifyCenter)}>
					<LoadingSpinner className={css()} type='small' />
				</div>
			) : null}
			<Waypoint bottomOffset='-200px' onEnter={() => automationTemplateFilterInfiniteQuery.fetchNextPage()} />

			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: !!campaignToDelete,
					onRequestClose: onDeleteConfirmationRequestClose,
				}}
				options={[
					{
						isDestructive: true,
						representedObject: true,
						title: 'Delete Template',
					},
					{
						isCancel: true,
						representedObject: false,
						title: 'Cancel',
					},
				]}
				title={<span>{`Are you sure you want to delete the "${campaignToDelete?.name || ''}" texting campaign?`}</span>}
			/>
		</div>
	);
}
