import { css } from 'aphrodite';
import * as React from 'react';
import { Redirect, Switch } from 'react-router';
import { useTextMessaging, useUserSession } from '../../../../models/hooks/appStateHooks';
import { CoffeeAppShell } from '../../../components/AppShell';
import { useIsCoffeeNavCompact } from '../../../components/AppShell/hooks/useIsCompactNav';
import { GlobalToaster } from '../../../components/GlobalToaster';
import { PrivateRoute } from '../../../components/PrivateRoute';
import { SingleEmailComposerModal } from '../../../components/email/SingleEmailComposerModal';
import { WebFullscreenModal } from '../../../components/fullscreen/WebFullscreenModal';
import { Automations } from '../../../containers/Automations';
import { BuildInfo } from '../../../containers/BuildInfo';
import { Company } from '../../../containers/Company';
import { Contact } from '../../../containers/Contact';
import { EditAutomationTemplate } from '../../../containers/EditAutomationTemplate';
import { LazyLoader } from '../../../containers/LazyLoader';
import { Logout } from '../../../containers/Logout';
import { PeopleV2 } from '../../../containers/PeopleV2';
import { ScheduleMeetingSettingsPage } from '../../../containers/ScheduleMeeting/ScheduleMeetingSettingsPage';
import { ManageUsers } from '../../../containers/Settings/ManageUsers';
import { CoffeeDashboard } from '../CoffeeDashboard';
import { CoffeeMyTemplates } from '../CoffeeMyTemplates';
import { CoffeeSettings } from '../Settings';
import { styleSheet } from './styles';

const LazyReportingRoute = React.lazy(
	() => import(/* webpackChunkName: "reporting" */ '../../../containers/reporting')
);

const Page = ({ children }: { children: React.ReactNode }) => {
	const showingCompactNav = useIsCoffeeNavCompact();
	const routeContainerClassName = showingCompactNav
		? css(styleSheet.contentContainerChildCompact)
		: css(styleSheet.contentContainerChild);

	return (
		<CoffeeAppShell>
			{React.Children.map(children, child => {
				return React.cloneElement(child as React.ReactElement<any>, { routeContainerClassName }, null);
			})}
		</CoffeeAppShell>
	);
};

export function DecafMainContainer() {
	const userSession = useUserSession();
	const textMessaging = useTextMessaging();
	const isCoffeeEnabled = userSession?.account?.features?.aida?.enabled;
	console.log('isCoffeeEnabled', isCoffeeEnabled);
	return (
		<>
			<Switch>
				{isCoffeeEnabled ? (
					<Redirect from='/' to='/dashboard/' exact={true} />
				) : (
					<Redirect from='/' to='/settings/' exact={true} />
				)}
				<PrivateRoute path='/logout' userSession={userSession}>
					<Logout userSession={userSession} textMessaging={textMessaging} />
				</PrivateRoute>
				<PrivateRoute path='/build-info' userSession={userSession}>
					<Page>
						<BuildInfo />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/dashboard/' userSession={userSession}>
					<Page>
						<CoffeeDashboard />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/templates' userSession={userSession}>
					<Page>
						<CoffeeMyTemplates />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/people/:id' userSession={userSession} pageViewLogName='contact/view'>
					<Page>
						<Contact />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/people' userSession={userSession}>
					<Page>
						<PeopleV2 />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/companies/:id' userSession={userSession} pageViewLogName='company/view'>
					<Page>
						<Company />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/companies' userSession={userSession}>
					<Page>
						<PeopleV2 />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/automations/:id' userSession={userSession}>
					<Page>
						<EditAutomationTemplate />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/automations' userSession={userSession}>
					<Page>
						<Automations />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/settings/admin/manage-users' userSession={userSession}>
					<Page>
						<ManageUsers />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/settings/:category?/:action?' userSession={userSession}>
					<Page>
						<CoffeeSettings />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/schedule-meeting' userSession={userSession}>
					<Page>
						<ScheduleMeetingSettingsPage />
					</Page>
				</PrivateRoute>
				<PrivateRoute path='/reporting' userSession={userSession}>
					<LazyLoader>
						<Page>
							<LazyReportingRoute />
						</Page>
					</LazyLoader>
				</PrivateRoute>
			</Switch>
			<WebFullscreenModal />
			<SingleEmailComposerModal />
			<GlobalToaster />
		</>
	);
}
