import { StyleSheet } from 'aphrodite';
import { black, brandPrimary, brandPrimaryHover, titles } from '../../../../styles/colors';

export const boardHeaderHeight = 60;
export const boardHeaderCompactHeight = 2 * boardHeaderHeight;
export const mediaQueryMaxWidth = 1200;

export const styleSheet = StyleSheet.create({
	clearSearch: {
		':hover': {
			color: brandPrimaryHover,
			textDecoration: 'underline',
		},
		alignItems: 'center',
		color: brandPrimary,
		display: 'flex',
		fontSize: 14,
		letterSpacing: 0,
		minWidth: 90,
	},
	editBoardInfo: {
		background: '#f4f4f4',
		borderRadius: 6,
		boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
		color: titles,
		fontSize: 11,
		maxWidth: 150,
		padding: '5px 10px',
	},
	exportOption: {
		':hover': {
			background: '#f4f4f4',
		},
		color: titles,
		cursor: 'pointer',
		fontSize: 14,
		padding: '10px 14px',
	},
	searchFieldAnchor: {
		borderLeft: 'none',
		borderBottomLeftRadius: '0 !important',
		borderTopLeftRadius: '0 !important',
		height: 42,
		gap: 4,
	},
	searchPropertySelector: {
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		minWidth: 180,
	},
	selectContainer: {
		minWidth: 135,
		color: black,
		maxWidth: 200,
	},
	searchFieldDropDownContent: {
		minWidth: 309,
	},
	container: {
		padding: 16,
	},
	searchContainer: {
		display: 'flex',
		gap: 8,
	},
	actionButtonContainer: {
		display: 'flex',
		gap: 8,
		marginLeft: 'auto',
	},
	dealsizeDropdown: {},
	boardButton: {
		alignItems: 'center',
		color: '#858585',
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		letterSpacing: 0,
		marginLeft: 30,
		gap: 4,
	},
	boardButtonContainer: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
		height: '100%',
	},
});
