import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	content: {
		alignItems: 'center',
		background: '#fff',
		display: 'flex',
		padding: '10px 12px',
	},
	dealSizeOptionStyles: {
		height: 'auto',
	},
	dropdownContainer: {
		':focus': { background: 'rgb(255, 255, 255)' },
		':hover': { background: 'rgb(255, 255, 255)' },
		alignItems: 'center',
		background: 'rgb(255, 255, 255)',
		border: '1px solid rgb(216, 216, 216)',
		borderRadius: 6,
		color: 'rgb(118, 118, 118)',
		display: 'flex',
		fontSize: 14,
		height: 42,
		justifyContent: 'space-between',
		maxWidth: 300,
		minWidth: 135,
		overflow: 'hidden',
		padding: '0px 15px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: '100%',
	},
	input: {
		width: 80,
	},
	inputField: {
		':first-of-type': {
			marginRight: 8,
		},
		':not(:first-of-type)': {
			marginLeft: 8,
		},
	},
});
