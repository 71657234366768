import {
	DefaultFabMenuItemActions,
	IContactSelection,
	ICreateCampaignRequest,
	IFabMenuItem,
	ILocationState,
	IPushNotificationMessage,
} from '@AppModels/.';
import { EventLogger, useEventLogging } from '@AppModels/Logging';
import {
	canSendHtmlNewsletters,
	canViewContentCalendarAndCampaigns,
	getMentionEntitiesFromLocationState,
} from '@AppModels/UiUtils';
import * as Api from '@ViewModels';
import { Fab } from '@WebComponents/Fab';
import { Modal } from '@WebComponents/Modal';
import { PushNotifications } from '@WebComponents/PushNotifications';
import { QuickAddEntity } from '@WebComponents/QuickAddEntity';
import { EmailReadOrOpenedToast } from '@WebComponents/Toaster/EmailReadOrOpenedToast';
import { ActionItemComposerModal } from '@WebComponents/actionItems/ActionItemComposer';
import { SingleEmailComposerModal } from '@WebComponents/email/SingleEmailComposerModal';
import { NoteComposerModal } from '@WebComponents/notes/NoteComposer';
import { CallIcon } from '@WebComponents/svgs/icons/CallIcon';
import { EmailIcon } from '@WebComponents/svgs/icons/EmailIcon';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import {
	useActionItemComposer,
	useEnvironment,
	useFullscreenModal,
	useNoteComposer,
	usePushNotifications,
	useQuickAddEntity,
	useSingleEmailComposer,
	useTextMessaging,
	useToaster,
	useUserSession,
} from '../../../models/hooks/appStateHooks';
import {
	ActionItemViewModel,
	ContactViewModel,
	EntityViewModel,
	KnownCategories,
	NoteViewModel,
	OpportunityViewModel,
	RichContentComposerResult,
	UserSessionContext,
	VmUtils,
} from '../../../viewmodels/AppViewModels';
import { BrowserPushNotificationsViewModel } from '../../../viewmodels/PushNotifications';
import { HappinessAppShell, LevitateAppShell, MainContainerPageShell } from '../../components/AppShell';
import { EnablePushNotificationsModal } from '../../components/EnablePushNotificationsModal';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { GlobalToaster } from '../../components/GlobalToaster';
import { IRouterLocation, PrivateRoute } from '../../components/PrivateRoute';
import { WebFullscreenModal } from '../../components/fullscreen/WebFullscreenModal';
import { ActionItemsIcon } from '../../components/svgs/icons/ActionItemsIcon';
import { NewContactIcon } from '../../components/svgs/icons/NewContactIcon';
import { bs } from '../../styles/styles';
import { AppLinks } from '../AppLinks';
import { Automations } from '../Automations';
import { BuildInfo } from '../BuildInfo';
import { Campaigns } from '../Campaigns';
import { Company } from '../Company';
import { Contact } from '../Contact';
import { ContentCalendar } from '../ContentCalendar';
import { CreateAutomationTemplatePage } from '../CreateAutomationTemplate';
import { EditAutomationTemplate } from '../EditAutomationTemplate';
import { EditHtmlNewsletterTemplate } from '../EditHtmlNewsletterTemplate';
import { EventRegistrationSurvey } from '../EventRegistrationSurvey';
import { GenericKeyDates } from '../GenericKeyDates';
import { Dashboard } from '../Happiness/Dashboard';
import { KeepInTouches } from '../KeepInTouches';
import { LazyLoader } from '../LazyLoader';
import { Logout } from '../Logout';
import { NotFoundContainer } from '../NotFoundContainer';
import { NoteActionItemsContainer } from '../NoteActionItemsContainer';
import { PeopleV2 } from '../PeopleV2';
import { RecentMeetings } from '../RecentMeetings';
import { RecentlyViewedEmails } from '../RecentlyViewedEmails';
import { SatisfactionSurvey } from '../SatisfactionSurvey';
import { ScheduleMeetingSettingsPage } from '../ScheduleMeeting/ScheduleMeetingSettingsPage';
import { Settings } from '../Settings';
import { ManageUsers } from '../Settings/ManageUsers';
import { LazySurveysRoute } from '../Surveys/lazy';
import { Texting } from '../Texting';
import { TextingRegistrationFormPage } from '../TextingRegistration/TextingRegistrationForm';
import { TextingRegistrationOptIn } from '../TextingRegistration/TextingRegistrationOptIn';
import { DataBoardsRoute } from '../dataBoards';
import { Integrations } from '../integrations/Integrations';
import './styles.less';

const LazyRegistrationRoute = React.lazy(() => import(/* webpackChunkName: "registration" */ '../TextingRegistration'));
const LazyReportingRoute = React.lazy(() => import(/* webpackChunkName: "reporting" */ '../reporting'));

export interface INavigationItemProps {
	routeContainerClassName?: string;
	showingCompactNav?: boolean;
}

export interface IMainContainerProps {
	children?: React.ReactElement<INavigationItemProps> | React.ReactElement<INavigationItemProps>[];
	className?: string;
	containerMode?: 'standard' | 'admin';
}

function _MainContainer({ containerMode = 'standard' }: IMainContainerProps) {
	const userSession = useUserSession();
	const loc = useLocation();
	const { logApiError } = useEventLogging('MainContainer');
	const fullscreenModal = useFullscreenModal();
	const environment = useEnvironment();
	const noteComposer = useNoteComposer();
	const actionItemComposer = useActionItemComposer();
	const singleEmailComposer = useSingleEmailComposer();
	const quickAddEntity = useQuickAddEntity();
	const pushNotifications = usePushNotifications();
	const toaster = useToaster();
	const textMessaging = useTextMessaging();

	const onEnablePushNotificationsModalRequestClose = async (enablePushNotifications?: boolean, cancel?: boolean) => {
		const logError = (error: any) => {
			const err = Api.asApiError(error);
			logApiError(`${enablePushNotifications ? 'Enable' : 'Disable'}BrowserPush-Error`, err);
		};
		if (cancel) {
			return pushNotifications.denyPushNotifications();
		}
		const resolvedValue = !!enablePushNotifications;
		await pushNotifications.toggleNotifications(resolvedValue).catch(logError);
	};

	const onStartComposingNote = (context?: Api.IRichContentContext) => () => {
		const locationState: ILocationState<EntityViewModel<Api.IEntity> | OpportunityViewModel, Api.IEntity> =
			fullscreenModal.hasValidLocation ? fullscreenModal.history.location.state : loc.state;
		const entities = getMentionEntitiesFromLocationState(locationState);
		const note: Api.INote = Api.createRichContentWithReferencedEntities(entities || []);
		note.context = context || null;

		// add "Phone call with..." to content, if needed
		if (
			!!note.context &&
			note.context.source === Api.RichContentContextSource.PhoneCall &&
			!!entities &&
			entities.length > 0
		) {
			const entityListStringValue = entities.reduce<string>((result, entityModel, i) => {
				const name = (VmUtils.getDisplayName(entityModel) || '').trim();
				if (name) {
					return `${result}${result ? (i === entities.length - 1 ? ' and ' : ', ') : ''}${name}`;
				}
				return result;
			}, '');
			if (entityListStringValue) {
				note.content = Api.createRawRichTextContentStateWithText(`Phone call with ${entityListStringValue}.`);
			}
		}

		EventLogger.logInput('Fab', 'CreateNote', 'Click');
		const noteVm = new NoteViewModel(userSession, note);
		noteComposer.show(noteVm);
	};

	const onEditActionItemCompleted = (actionItem?: ActionItemViewModel, result?: RichContentComposerResult) => {
		if (!!actionItem && result !== RichContentComposerResult.Delete && result !== RichContentComposerResult.None) {
			// action item was created... toast
			toaster.push({
				linkTitle: 'See Action Item',
				message: 'Your action item was saved.',
				onLinkClicked: routerProps => {
					routerProps.history.push(`/notes-action-items?activeTab=action-items`);
				},
				type: 'successMessage',
			});
		}
	};

	const onFabMenuItemClicked = (menuItem: IFabMenuItem) => {
		if (menuItem.representedObject === DefaultFabMenuItemActions.AddPhoneCall) {
			EventLogger.logInput('Fab', 'AddPhoneCall', 'Click');
			onStartComposingNote({
				source: Api.RichContentContextSource.PhoneCall,
			})();
		} else if (menuItem.representedObject === DefaultFabMenuItemActions.CreateActionItem) {
			EventLogger.logInput('Fab', 'CreateActionItem', 'Click');
			const locationState: ILocationState<EntityViewModel<Api.IEntity> | OpportunityViewModel, Api.IEntity> =
				fullscreenModal.hasValidLocation ? fullscreenModal.history.location.state : loc.state;
			const entities = getMentionEntitiesFromLocationState(locationState);
			const actionItemModel: Api.IActionItem = Api.createRichContentWithReferencedEntities(entities || []);
			const actionItem = new ActionItemViewModel(userSession, actionItemModel);
			actionItemComposer.show(actionItem, onEditActionItemCompleted);
		} else if (menuItem.representedObject === DefaultFabMenuItemActions.SendMessage) {
			// try to get the contact vm from the current location state.
			const locationState: ILocationState<ContactViewModel, Api.IEntity> = fullscreenModal.hasValidLocation
				? fullscreenModal.history.location.state
				: loc.state;
			let contacts: ContactViewModel[] = (getMentionEntitiesFromLocationState(locationState) || [])
				.filter(x => Api.getTypeForEntity(x) !== 'company')
				.map(x => new ContactViewModel(userSession, x));
			if (
				(!contacts || contacts.length === 0) &&
				!!locationState &&
				!!locationState.model &&
				Api.getTypeForEntity(locationState.model) !== 'company'
			) {
				contacts = [new ContactViewModel(userSession, locationState.model)];
			}

			EventLogger.logInput(
				'Fab',
				'EmailComposer',
				'Click',
				contacts
					? {
							contacts: contacts.map(x => {
								return { id: x.id };
							}),
						}
					: undefined
			);
			if (!!contacts && contacts.length > 0) {
				singleEmailComposer.showForRecipients(contacts);
			} else {
				const selectionLocationState: ILocationState<any, ICreateCampaignRequest<IContactSelection>> = {
					model: {
						context: {
							contacts: contacts || [],
						},
						type: 'Contacts',
					},
				};
				fullscreenModal?.history?.push({
					pathname: '/email/campaigns/create/from-scratch',
					state: selectionLocationState,
				});
			}
		} else if (menuItem.representedObject === DefaultFabMenuItemActions.NewContact) {
			quickAddEntity.show({ type: 'contact' });
		}
	};

	const onRenderModals = () => {
		const showPushNotificationsPrompt = !!pushNotifications?.shouldShowPromptDialog;
		const fabMenuItems: IFabMenuItem<string>[] = [
			{
				icon: <EmailIcon />,
				representedObject: DefaultFabMenuItemActions.SendMessage,
				tooltip: 'Send an email',
			},
			{
				icon: <ActionItemsIcon />,
				representedObject: DefaultFabMenuItemActions.CreateActionItem,
				tooltip: 'Add Action Item',
			},
			{
				icon: <CallIcon />,
				representedObject: DefaultFabMenuItemActions.AddPhoneCall,
				tooltip: 'Add Phone Call',
			},
			{
				icon: <NewContactIcon />, // placeholder
				representedObject: DefaultFabMenuItemActions.NewContact,
				tooltip: 'New Contact',
			},
		];
		return (
			<React.Fragment key='main-container-modals'>
				<WebFullscreenModal />
				{containerMode !== 'admin' && (
					<Fab
						menuItems={fabMenuItems}
						onClick={onStartComposingNote()}
						onMenuItemClick={onFabMenuItemClicked}
						tooltip='Add Note'
					/>
				)}
				<ActionItemComposerModal />
				<NoteComposerModal />
				{environment.appType !== 'admin' && <SingleEmailComposerModal />}
				<Modal
					className='quick-add-entity-modal'
					isOpen={quickAddEntity.isOpen}
					onRequestClose={quickAddEntity.close}
					shouldCloseOnOverlayClick={true}
					useDefaultHeader={true}
				>
					<QuickAddEntity quickAddEntityVm={quickAddEntity} />
				</Modal>
				{!!BrowserPushNotificationsViewModel.isSupported && (
					<EnablePushNotificationsModal
						modalProps={{
							isOpen: !!showPushNotificationsPrompt,
							onRequestClose: onEnablePushNotificationsModalRequestClose,
						}}
					/>
				)}
			</React.Fragment>
		);
	};

	const onPushNotificationMessageReceived = (e: MessageEvent) => {
		const pushNotificationMessage: IPushNotificationMessage = e.data;
		if (!!pushNotificationMessage && !!pushNotificationMessage.message && !!pushNotificationMessage.title) {
			toaster.push({
				customContent: <EmailReadOrOpenedToast pushMessage={pushNotificationMessage} />,
				duration: 'long',
				type: 'custom',
			});
		}
	};

	/** Enforce permissions, handle special cases, etc. */
	const onRouteLoad = (routerLocation: IRouterLocation, _userSession: UserSessionContext) => {
		const lowerPathName = routerLocation.pathname?.toLocaleLowerCase();
		if (_userSession?.userRole === 'limitedUser') {
			switch (lowerPathName) {
				case '/companies': {
					return '/dashboard';
				}
				case '/automations': {
					return '/dashboard';
				}
				default: {
					break;
				}
			}
		}
	};

	return (
		<ErrorBoundary>
			<Switch>
				<PrivateRoute
					path='/launch'
					pageViewLogName={location.pathname}
					render={props => (
						<LevitateAppShell>
							<AppLinks {...props} />
						</LevitateAppShell>
					)}
					userSession={userSession}
				/>
				<PrivateRoute path='/build-info' pageViewLogName='build-info' userSession={userSession}>
					<LevitateAppShell>
						<BuildInfo routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute
					path='/logout'
					pageViewLogName='logout'
					render={props => (
						<LevitateAppShell>
							<Logout {...props} userSession={userSession} textMessaging={textMessaging} />
						</LevitateAppShell>
					)}
					userSession={userSession}
				/>
				<PrivateRoute
					path='/dashboard/recently-viewed-emails'
					pageViewLogName='dashboard/recently-viewed-emails'
					userSession={userSession}
				>
					<LevitateAppShell>
						<RecentlyViewedEmails />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute
					path='/dashboard/recent-meetings'
					pageViewLogName='dashboard/recent-meetings'
					userSession={userSession}
				>
					<LevitateAppShell>
						<RecentMeetings />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute
					path='/dashboard/keep-in-touches'
					pageViewLogName='dashboard/keep-in-touches'
					userSession={userSession}
				>
					<LevitateAppShell>
						<KeepInTouches />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute
					path='/dashboard/upcoming-key-dates'
					pageViewLogName='dashboard/upcoming-key-dates'
					userSession={userSession}
				>
					<LevitateAppShell>
						<GenericKeyDates />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/dashboard' pageViewLogName='dashboard' userSession={userSession}>
					<HappinessAppShell>
						<Dashboard />
					</HappinessAppShell>
				</PrivateRoute>
				<PrivateRoute path='/content-calendar' pageViewLogName='content-calendar' userSession={userSession}>
					<LevitateAppShell>
						<ContentCalendar styles={[bs.px5]} />
					</LevitateAppShell>
				</PrivateRoute>
				{userSession?.account?.features?.aida?.enabled && (
					<PrivateRoute path='/templates' userSession={userSession}>
						<LevitateAppShell>
							<Redirect
								exact={true}
								from='/templates'
								to={`/campaigns/email?category=${encodeURIComponent(KnownCategories.MyTemplates)}`}
							/>
						</LevitateAppShell>
					</PrivateRoute>
				)}
				{canSendHtmlNewsletters(userSession) ? (
					<PrivateRoute path='/campaigns/htmlnewsletter/:id' userSession={userSession}>
						<LevitateAppShell>
							<EditHtmlNewsletterTemplate routeContainerClassName='main-container-child' />
						</LevitateAppShell>
					</PrivateRoute>
				) : null}
				{canSendHtmlNewsletters(userSession) ? (
					<Redirect
						exact={true}
						from='/campaigns/htmlnewsletter'
						to={`/campaigns/email?category=${encodeURIComponent(KnownCategories.HtmlNewsletters)}`}
					/>
				) : null}
				{canViewContentCalendarAndCampaigns(userSession) && (
					<PrivateRoute path='/campaigns/:tab?' userSession={userSession}>
						<LevitateAppShell>
							<Campaigns routeContainerClassName='main-container-child' />
						</LevitateAppShell>
					</PrivateRoute>
				)}
				<PrivateRoute path='/people/:id' pageViewLogName='contact/view' userSession={userSession}>
					<LevitateAppShell>
						<Contact routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/people' pageViewLogName='people' userSession={userSession}>
					<LevitateAppShell>
						<PeopleV2 />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/notes-action-items/:id?' pageViewLogName='notes-action-items' userSession={userSession}>
					<LevitateAppShell>
						<NoteActionItemsContainer routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/notes/:id?' pageViewLogName='notes-action-items' userSession={userSession}>
					<LevitateAppShell>
						<NoteActionItemsContainer routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/companies/:id' pageViewLogName='company/view' userSession={userSession}>
					<LevitateAppShell>
						<Company routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/companies' pageViewLogName='companies' userSession={userSession} onLoad={onRouteLoad}>
					<LevitateAppShell>
						<PeopleV2 />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute
					path='/texting/registration/form'
					pageViewLogName='campaign-registration-form'
					userSession={userSession}
					onLoad={onRouteLoad}
				>
					<LevitateAppShell>
						<TextingRegistrationFormPage />
					</LevitateAppShell>
				</PrivateRoute>
				{userSession?.account?.features?.texting?.enabled ? (
					<PrivateRoute
						path='/texting/registration/opt-in'
						pageViewLogName='campaign-registration-opt-in'
						userSession={userSession}
						onLoad={onRouteLoad}
					>
						<LevitateAppShell>
							<TextingRegistrationOptIn />
						</LevitateAppShell>
					</PrivateRoute>
				) : null}
				<PrivateRoute path='/texting/registration' pageViewLogName='campaign-registration' userSession={userSession}>
					<LevitateAppShell>
						<LazyLoader>
							<LazyRegistrationRoute />
						</LazyLoader>
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/reporting' userSession={userSession}>
					<LevitateAppShell>
						<LazyLoader>
							<LazyReportingRoute />
						</LazyLoader>
					</LevitateAppShell>
				</PrivateRoute>
				<Redirect from='/settings/schedule-meeting' to='/schedule-meeting' />
				<PrivateRoute path='/schedule-meeting' pageViewLogName='settings/schedule-meeting' userSession={userSession}>
					<LevitateAppShell>
						<ScheduleMeetingSettingsPage />
					</LevitateAppShell>
				</PrivateRoute>
				<Redirect from='/settings/manage-users' to='/settings/admin/manage-users' />
				<PrivateRoute path='/settings/admin/manage-users' userSession={userSession}>
					<LevitateAppShell>
						<ManageUsers routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/settings/:category?/:action?' pageViewLogName='settings' userSession={userSession}>
					<LevitateAppShell>
						<MainContainerPageShell>
							<Settings />
						</MainContainerPageShell>
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/action-items/:id?' pageViewLogName='notes-action-items' userSession={userSession}>
					<LevitateAppShell>
						<NoteActionItemsContainer routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>

				<Redirect exact={true} from='/boards/opportunities' to='/dataBoards/opportunities' />
				<PrivateRoute path='/dataBoards' userSession={userSession}>
					<LevitateAppShell>
						<LazyLoader>
							<DataBoardsRoute />
						</LazyLoader>
					</LevitateAppShell>
				</PrivateRoute>

				<PrivateRoute path='/integrations' pageViewLogName='integrations' userSession={userSession}>
					<LevitateAppShell>
						<Integrations />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/automations/new' pageViewLogName='create-automation-template' userSession={userSession}>
					<LevitateAppShell>
						<CreateAutomationTemplatePage routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/automations/:id' pageViewLogName='edit-automation-template' userSession={userSession}>
					<LevitateAppShell>
						<EditAutomationTemplate routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/automations' pageViewLogName='automations' userSession={userSession}>
					<LevitateAppShell>
						<Automations routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/survey/event/edit/:id?' userSession={userSession}>
					<LevitateAppShell>
						<EventRegistrationSurvey />
					</LevitateAppShell>
				</PrivateRoute>
				<PrivateRoute path='/survey/satisfaction/edit/:id?' userSession={userSession}>
					<LevitateAppShell>
						<SatisfactionSurvey />
					</LevitateAppShell>
				</PrivateRoute>
				{userSession.account.isAdmin ? (
					<PrivateRoute path='/surveys' pageViewLogName='surveys' userSession={userSession}>
						<LevitateAppShell>
							<LazySurveysRoute />
						</LevitateAppShell>
					</PrivateRoute>
				) : (
					<Redirect from='/surveys' to='/dashboard' />
				)}
				{VmUtils.canViewTexting(userSession) ? (
					<PrivateRoute path='/texting' pageViewLogName='texting' exact={true} userSession={userSession}>
						<LevitateAppShell>
							<Texting />
						</LevitateAppShell>
					</PrivateRoute>
				) : null}
				{VmUtils.canViewTexting(userSession) ? (
					<PrivateRoute
						path='/texting/:phoneNumberId/:conversationId?'
						pageViewLogName='texting'
						userSession={userSession}
					>
						<LevitateAppShell>
							<Texting />
						</LevitateAppShell>
					</PrivateRoute>
				) : null}
				<PrivateRoute userSession={userSession}>
					<LevitateAppShell>
						<NotFoundContainer routeContainerClassName='main-container-child' />
					</LevitateAppShell>
				</PrivateRoute>
			</Switch>
			<GlobalToaster />
			{onRenderModals()}
			<PushNotifications onMessage={onPushNotificationMessageReceived} />
		</ErrorBoundary>
	);
}

export const MainContainer = observer(_MainContainer);
