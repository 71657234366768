import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { EmailScannerId } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import {
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { RadioButton } from '../../RadioButton';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps, IToasterComponentProps {
	className?: string;
	enabled?: boolean;
	image?: React.ReactNode;
	message?: React.ReactNode;
	onChange?(enabled: boolean): Promise<any>;
	styles?: StyleDeclarationValue[];
	type: EmailScannerId;
}

interface IState {
	enabled?: boolean;
	pendingChange?: boolean;
	saving?: boolean;
}

class _EmailScannerToggle extends React.Component<IProps, IState> {
	private mUuid: string;
	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};
		if (props.enabled !== state.enabled) {
			nextState.enabled = props.enabled;
		}
		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IProps) {
		super(props);
		this.state = {
			enabled: props.enabled,
		};
		this.mUuid = uuidgen();
	}

	public render() {
		const { className, styles, type, image, message } = this.props;
		const { pendingChange, saving, enabled } = this.state;
		const hasPendingChange = pendingChange !== null && pendingChange !== undefined;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} email-scanner-toggle ${className || ''}`}>
				{!!image && <div className={css(styleSheet.image)}>{image}</div>}
				{!!message && <div className={css(styleSheet.message)}>{message}</div>}
				<div className={css(styleSheet.body, message ? styleSheet.bodyWithMessage : null)}>
					<RadioButton
						checked={hasPendingChange ? pendingChange : !!enabled}
						disabled={!!saving}
						id={`${this.mUuid}-${type}-toggle-enable`}
						name={`${this.mUuid}-${type}-toggle`}
						onChange={this.onRadioButtonChanged(true)}
					>
						<span>Enabled</span>
					</RadioButton>
					<RadioButton
						checked={hasPendingChange ? !pendingChange : !enabled}
						disabled={!!saving}
						id={`${this.mUuid}-${type}-toggle-disable`}
						name={`${this.mUuid}-${type}-toggle`}
						onChange={this.onRadioButtonChanged(false)}
					>
						<span>Disabled</span>
					</RadioButton>
				</div>
				<div className={css(styleSheet.actions)}>
					<button className={css(baseStyleSheet.ctaButton)} disabled={!!saving} onClick={this.onSaveClicked}>
						<span>Save</span>
					</button>
					<button className={css(baseStyleSheet.ctaButtonReverse)} disabled={!!saving} onClick={this.onCancelClicked}>
						<span>Cancel</span>
					</button>
					{!!saving && <LoadingSpinner type='small' />}
				</div>
			</div>
		);
	}

	private onRadioButtonChanged = (enableRadioButton: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			this.setState({
				pendingChange: enableRadioButton,
			});
		}
	};

	private onCancelClicked = () => {
		this.setState({
			pendingChange: null,
		});
	};

	private onSaveClicked = () => {
		const { onChange, toaster } = this.props;
		const { pendingChange, enabled } = this.state;
		if (pendingChange === null || pendingChange === undefined || enabled === pendingChange) {
			this.setState({
				pendingChange: null,
			});
			return;
		}

		const promise = onChange ? onChange(pendingChange) : null;
		if (promise) {
			this.setState({
				saving: true,
			});
			const onFinish = () => {
				this.setState({
					pendingChange: null,
					saving: false,
				});
			};
			promise
				.then(() => {
					onFinish();

					toaster.push({
						message: 'Setting saved',
						type: 'successMessage',
					});
				})
				.catch(onFinish);
		}
	};
}

// @ts-ignore
const EmailScannerToggleAsObserver = observer(_EmailScannerToggle);
const EmailScannerToggleWithContext = inject(
	UserSessionViewModelKey,
	ToasterViewModelKey
)(EmailScannerToggleAsObserver);
export const EmailScannerToggle = withEventLogging(EmailScannerToggleWithContext, 'EmailScannerToggle');
