import * as Api from '@ViewModels';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidgen } from 'uuid';
import { useEventLogging } from '../../../../../models/Logging';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import * as AppViewModels from '../../../../../viewmodels/AppViewModels';

export function useLaunchKitActionItems({
	queryCommand,
	eventLoggingCategory,
}: {
	queryCommand: Api.IQueryStringCommand;
	eventLoggingCategory: string;
}) {
	const isCommandKit = queryCommand?.command === 'kit';

	const [selectedKitActionItem, setSelectedKitActionItem] = React.useState<AppViewModels.ActionItemViewModel>(null);
	const userSession = useUserSession();
	const history = useHistory();
	const { logEvent, logApiError } = useEventLogging(eventLoggingCategory);
	React.useEffect(() => {
		if (!isCommandKit) {
			setSelectedKitActionItem(null);
			return;
		}
		const keepInTouchCommand = queryCommand as Api.IKeepInTouchCommand;
		// create an action item from the query command
		const actionItem: Api.IActionItem = {
			id: keepInTouchCommand.actionItemId || `${keepInTouchCommand.isSuggestion ? 'suggested-' : ''}${uuidgen()}`,
			keepInTouchReference: {
				contact: { id: keepInTouchCommand.contactId },
			},
		};
		const selectedKeepInTouchActionItem = new AppViewModels.ActionItemViewModel(userSession, actionItem);
		logEvent('AppLink-KeepInTouchActionItem');
		// load the action item or the kit ref contact (if it's suggested)
		const promise = keepInTouchCommand.isSuggestion
			? selectedKeepInTouchActionItem.keepInTouchReference.contact.load()
			: selectedKeepInTouchActionItem.load();
		if (promise) {
			(promise as Promise<Api.IOperationResultNoValue>).catch((error: Api.IOperationResultNoValue) => {
				logApiError('LoadKeepInTouchActionItem-Error', error);
				history.push('/dashboard');
			});
		}
		setSelectedKitActionItem(selectedKeepInTouchActionItem);
	}, [isCommandKit, logEvent, history, logApiError, queryCommand, userSession]);
	return {
		selectedKitActionItem: isCommandKit ? selectedKitActionItem : null,
	};
}
