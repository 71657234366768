import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'board/contact/contactId/opportunities';

export function useGetOpportunitiesByContact({
	enabled = true,
	contactId,
	onError,
	impersonationContext,
	refetchOnWindowFocus = false,
}: {
	enabled?: boolean;
	contactId: string;
	onError?: (err: Api.IOperationResultNoValue) => void;
	impersonationContext?: Api.IImpersonationContext;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: !!contactId && enabled,
		onError,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<Api.IOpportunity[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `contact/${contactId}/opportunities`,
					impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, contactId],
		refetchOnWindowFocus,
	});
}

export const invalidateGetOpportunitiesByContact = ({
	contactId,
	impersonationContext,
}: {
	contactId: string;
	impersonationContext?: Api.IImpersonationContext;
}) => reactQueryClient.invalidateQueries([BASE_QUERY_KEY, contactId, impersonationContext]);

export const invalidateAllGetOpportunitiesByContact = () => reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
