import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultDateStringValue,
	getDisplayName,
	numberToCurrencyStringValue,
} from '../../../../../models/UiUtils';
import { CompanyViewModel, ContactViewModel } from '../../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../../styles/styles';
import { StageIndicator } from '../../../boards/StageIndicator';
import { EntityChip } from '../../../chips/EntityChip';
import { MultiLineAddress } from '../../../entities/MultiLineAddress';
import { SocialProfiles } from '../../../entities/SocialProfiles';
import { RichContentDocumentEditor } from '../../../richContent/RichContentDocumentEditor';
import { styleSheet } from './styles';

const OpportunityProfileSection: React.FC<{
	title?: string;
	smallTitle?: boolean;
}> = observer(({ children, title, smallTitle }) => {
	return (
		<div>
			<div className={css(styleSheet.sectionTitle, smallTitle ? styleSheet.sectionSmallTitle : null)}>{title}</div>
			{children}
		</div>
	);
});

export const OpportunityProfile = ({
	opportunity,
}: {
	opportunity: Api.IOpportunity;
}) => {
	const userSession = useUserSession();
	const closeDate = React.useMemo(() => {
		if (!opportunity.closeDate) {
			return '-';
		}
		return getDefaultDateStringValue(opportunity.closeDate);
	}, [opportunity]);

	const readOnlyEditorState = React.useMemo(() => {
		if (!opportunity?.details) {
			return null;
		}
		return convertRawRichTextContentStateToRichContentEditorState(opportunity.details);
	}, [opportunity]);

	const socialProfiles = opportunity.company?.socialProfiles;
	const opportunityOwner = opportunity.assignees[0];

	const companyVm = React.useMemo(() => {
		if (!opportunity.company) return null;
		return new CompanyViewModel(userSession, opportunity.company);
	}, [userSession, opportunity]);

	const primaryContactVm = React.useMemo(() => {
		if (!opportunity.primaryContact) return null;
		return new ContactViewModel(userSession, opportunity.primaryContact);
	}, [userSession, opportunity]);

	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.name, baseStyleSheet.truncateText)}>{opportunity.name}</div>

			{!!opportunity.boardStage &&
			!!opportunity.boardStage.config &&
			!!opportunity.boardStage.config.enableStageIndicator ? (
				<div className={css(styleSheet.stageIndicator)}>
					<div className={css(styleSheet.stageIndicatorTitle)}>
						<span className={css(styleSheet.sectionTitle)}>Stage: </span>
						<span>{opportunity.boardStage.name}</span>
					</div>
					<StageIndicator
						currentIndex={opportunity.boardStage.boardIndex}
						hideFutureStages
						total={opportunity.boardStage.boardTotalStageCount}
					/>
				</div>
			) : null}

			{!!opportunity.company && (
				<OpportunityProfileSection title='Company:'>
					<EntityChip entity={companyVm} readOnly />
				</OpportunityProfileSection>
			)}

			{!!opportunity.primaryContact && (
				<OpportunityProfileSection title='Primary Contact:'>
					<EntityChip entity={primaryContactVm} readOnly />
				</OpportunityProfileSection>
			)}

			<OpportunityProfileSection title='Amount:'>
				<div className={css(styleSheet.amount)}>
					{`$${opportunity.dealSize ? numberToCurrencyStringValue(opportunity.dealSize) : 0}`}
				</div>
			</OpportunityProfileSection>

			<OpportunityProfileSection title='Close date:'>
				<div className={css(styleSheet.dueDate)}>{closeDate}</div>
			</OpportunityProfileSection>

			<OpportunityProfileSection title='Owner:'>
				<div className={css(styleSheet.details)}>{opportunityOwner ? getDisplayName(opportunityOwner, true) : ''}</div>
			</OpportunityProfileSection>

			{!!opportunity.details && !!opportunity.details.document && (
				<OpportunityProfileSection title='Details:'>
					<RichContentDocumentEditor className={css(styleSheet.details)} contentState={readOnlyEditorState} readOnly />
				</OpportunityProfileSection>
			)}

			{opportunity.company ? (
				<>
					<div className={css(styleSheet.divider)} />

					{!!opportunity.company.address && (
						<OpportunityProfileSection smallTitle title='Address:'>
							<div className={css(styleSheet.details)}>
								<MultiLineAddress address={opportunity.company.address} />
							</div>
						</OpportunityProfileSection>
					)}

					{!!opportunity.company.emailDomain && (
						<OpportunityProfileSection smallTitle title='Domain:'>
							<div className={css(styleSheet.details)}>{opportunity.company.emailDomain}</div>
						</OpportunityProfileSection>
					)}
					{socialProfiles?.length > 0 && <SocialProfiles socialProfiles={socialProfiles} />}
				</>
			) : null}
		</div>
	);
};
