import * as Api from '@ViewModels';
import { KeyDateKind } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IContactSelection, ICreateCampaignRequest, ILocationState } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import { getDisplayName } from '../../../../../models/UiUtils';
import {
	useErrorMessages,
	useFullscreenModal,
	useToaster,
	useUserSession,
} from '../../../../../models/hooks/appStateHooks';
import { useModal } from '../../../../../models/hooks/useModal';
import { useToggle } from '../../../../../models/hooks/useToggle';
import { useExportContactsMutation, useExportContactsPolicyReportMutation } from '../../../../../queries';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../../components/ConfirmationDialog';
import { DeleteConfirmation } from '../../../../components/DeleteConfirmation';
import { ExportConfirmationModalV2 } from '../../../../components/ExportConfirmation';
import { HandwrittenCardTemplateSearchModal } from '../../../../components/HandwrittenCards/HandwrittenCardTemplateSearchModal';
import { PopoverType, TinyPopover } from '../../../../components/TinyPopover';
import { AutomationSelectorModal } from '../../../../components/automation/AutomationSelector';
import { AddContactConnectionModal } from '../../../../components/contacts/AddContactConnection';
import { AssignContactOwnerModal } from '../../../../components/contacts/AssignContactOwner';
import { ContactsAddTagModal } from '../../../../components/contacts/ContactsAddTagModal';
import { KeepInTouchDropdown } from '../../../../components/contacts/KeepInTouchDropdown';
import { MergeContactsWizard } from '../../../../components/contacts/MergeContactsWizard';
import { AddUserIcon } from '../../../../components/svgs/icons/AddUserIcon';
import { ArchiveIcon } from '../../../../components/svgs/icons/ArchiveIcon';
import { AssigneeIcon } from '../../../../components/svgs/icons/AssigneeIcon';
import { AutomationsIcon } from '../../../../components/svgs/icons/AutomationsIcon';
import { ClockIcon } from '../../../../components/svgs/icons/ClockIcon';
import { EmailIcon } from '../../../../components/svgs/icons/EmailIcon';
import { ExportIcon } from '../../../../components/svgs/icons/ExportIcon';
import { KeyDateIcon } from '../../../../components/svgs/icons/KeyDateIcon';
import { MergeIcon } from '../../../../components/svgs/icons/MergeIcon';
import { MoreIcon } from '../../../../components/svgs/icons/MoreIcon';
import { PostageIcon } from '../../../../components/svgs/icons/PostageIcon';
import { PrivateVisibilityIcon } from '../../../../components/svgs/icons/PrivateVisibilityIcon';
import { PublicVisibilityIcon } from '../../../../components/svgs/icons/PublicVisibilityIcon';
import { TagIcon } from '../../../../components/svgs/icons/TagIcon';
import { TargetIcon } from '../../../../components/svgs/icons/TargetIcon';
import { TextingIcon } from '../../../../components/svgs/icons/TextingIcon';
import { TrashIcon } from '../../../../components/svgs/icons/TrashIcon';
import { WarningIcon } from '../../../../components/svgs/icons/WarningIcon';
import {
	brandPrimaryHover,
	brandSecondary,
	contentCalendarTintColor,
	header,
	success,
	toggleOn,
} from '../../../../styles/colors';
import { useStatusFilters } from '../hooks/useStatusFilter';
import { useActionBarStatus } from './hooks';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	contactsVM: Api.ContactsViewModel;
	filter: Api.IBulkContactsRequest;
	sort: Api.ISortDescriptor<string>;
	onReload: () => void;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}
const RestoreConfirmationOptions: IConfirmationDialogOption[] = [
	{
		isCta: true,
		title: 'Restore',
	},
	{
		isCancel: true,
		title: 'Cancel',
	},
];

const ConfirmVisibilityChange: IConfirmationDialogOption[] = [
	{
		isCta: true,
		representedObject: true,
		title: 'Confirm',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	contactsVM: Api.ContactsViewModel;
	filter: Api.IBulkContactsRequest;
	sort: Api.ISortDescriptor<string>;
	onReload: () => void;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}

export const PeopleActionsBar: React.FC<IProps> = observer(
	({ className, styles, contactsVM, filter, sort, onReload, onChangeFilter }) => {
		const userSession = useUserSession();
		const fullscreenModal = useFullscreenModal();
		const { pushApiError } = useErrorMessages();
		const { logEvent, logInput } = useEventLogging();
		const toaster = useToaster();
		const { isActive, isSelectingAll, canKeepInTouch, canMerge, canRestore, canFocus } = useActionBarStatus({
			contactsVM,
		});
		const [isAutomationOpen, setIsAutomationOpen] = React.useState(false);
		const [isMoreMenuOpen, , setMoreMenuOpen] = useToggle(false);
		const { isArchived } = useStatusFilters({ filter, onChangeFilter });
		const exportContactsMutation = useExportContactsMutation();
		const exportContactsPolicyReportMutation = useExportContactsPolicyReportMutation();
		const canShowAutomations = React.useRef(userSession?.account?.features?.automation?.enabled).current;
		const canShowCards = React.useRef(userSession?.account?.features?.handwrittenCards?.enabled).current;
		const [visibilityJob, setVisibilityJob] = React.useState<Api.SystemJobViewModel>(null);
		const [visibilityChangeType, setVisibilityChangeType] = React.useState<'admin' | 'all'>(null);
		const [isHwcTemplateChooserModalOpen, setIsHwcTemplateChooserModalOpen] = React.useState(false);
		const [routeAfterHwcTemplateSelection, setRouteAfterHwcTemplateSelection] = React.useState<'contacts' | 'filter'>(
			null
		);
		const canSendTextingCampaign =
			userSession?.account?.features?.texting?.enabled &&
			userSession.account.features.automation?.additionalAllowedTriggers?.includes(Api.AutomationTriggerType.Texting);
		const duplicateContactsFeatureFlag = !!userSession.account.preferences.showDuplicateContacts;
		const onKeepInTouch = React.useCallback(
			(frequency: number) => {
				if (frequency > -1) {
					const selectedContacts = contactsVM.selectedContacts.toArray();
					logInput('SetKeepInTouch', 'Click', {
						count: contactsVM.selectedContacts.length,
						frequency,
					});
					contactsVM
						.setKeepInTouch({ frequency }, selectedContacts)
						?.then(() => {
							contactsVM.selectedContacts.clear();
						})
						?.catch((error: Api.IOperationResultNoValue) => {
							logEvent('SetKeepInTouch-Error', {
								error: { ...error },
								frequency,
							});
						});
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[contactsVM]
		);

		const onSendMessage = () => {
			if (contactsVM.selectionState === 'all' || contactsVM.selectionState === 'some') {
				if (contactsVM.selectedContacts.length === 0) {
					if (filter.includeContactIds?.length) {
						let flatContacts: Api.ContactViewModel[] = [];
						for (const contact of contactsVM.fetchResults.toArray()) {
							flatContacts.push(contact);
							if (contact.duplicateContacts?.length) {
								flatContacts = [...flatContacts, ...contact.duplicateContacts];
							}
						}
						fullscreenModal.history?.push({
							pathname: '/email/campaigns/create/from-selection',
							state: {
								model: {
									context: {
										allowSenderSelection: true,
										bulkFilterRequestOptions: {
											readOnlySearchCriteria: true,
										},
										contacts: flatContacts.filter(c => !contactsVM.excludedContacts.has(c)),
									},
									type: 'Contacts',
								},
							},
						});
						return;
					}

					// select all, but check exclusions
					const bulkFilterRequest: Api.IEmailMessageContactsFilterRequest = {
						contactFilterRequest: { criteria: filter.filter.criteria },
						groupByDuplicate: duplicateContactsFeatureFlag,
						ownershipFilter: filter.ownershipFilter,
					};

					// apply sort
					if (sort && sort.sortBy) {
						bulkFilterRequest.sortAscending = sort.sort === 'asc';
						bulkFilterRequest.sortProperty = sort.sortBy as keyof Api.IContact;
					}

					logInput('ShowSendMessage', 'Click', {
						excludedCount: contactsVM.excludedContacts.length,
						selectAll: true,
					});

					const locationState: ILocationState<any, ICreateCampaignRequest<IContactSelection>> = {
						model: {
							context: {
								allowSenderSelection: true,
								bulkFilterRequest,
								contactsToOmit: contactsVM.excludedContacts.toArray(),
								contacts: filter.includeContactIds?.length
									? contactsVM.fetchResults.toArray().filter(x => filter.includeContactIds.includes(x.id))
									: undefined,
							},
							type: 'Contacts',
						},
					};
					fullscreenModal.history?.push({
						pathname: '/email/campaigns/create/from-filter',
						state: locationState,
					});
				} else {
					// normal selection
					logInput('ShowSendMessage', 'Click', {
						selectAll: false,
						selectedCount: contactsVM.selectedContacts.length,
					});
					const locationState: ILocationState<any, ICreateCampaignRequest<IContactSelection>> = {
						model: {
							context: {
								allowSenderSelection: true,
								bulkFilterRequestOptions: {
									readOnlySearchCriteria: true,
								},
								contacts: contactsVM.selectedContacts.toArray(),
							},
							type: 'Contacts',
						},
					};
					fullscreenModal.history?.push({
						pathname: '/email/campaigns/create/from-selection',
						state: locationState,
					});
				}
			}
		};

		const onSendCard = () => {
			if (contactsVM.selectionState === 'all' || contactsVM.selectionState === 'some') {
				if (contactsVM.selectedContacts.length === 0) {
					setRouteAfterHwcTemplateSelection('filter');
					setIsHwcTemplateChooserModalOpen(true);
				} else {
					setRouteAfterHwcTemplateSelection('contacts');
					setIsHwcTemplateChooserModalOpen(true);
				}
			}
		};

		const onFocusContacts = () => {
			if (contactsVM.selectionState === 'all' || contactsVM.selectionState === 'some') {
				fullscreenModal.history?.push({
					pathname: '/people/focused/',
					state: {
						initialContactIds: contactsVM.selectedContacts.map(contact => contact.id),
						initialContactIdsToOmit: contactsVM.excludedContacts.map(contact => contact.id),
						initialFilterCriteriaList: filter.filter.criteria,
						ownershipFilter: filter.ownershipFilter,
						mode: 'contact',
					},
				});
			}
		};

		const onSendTextClicked = () => {
			if (contactsVM.selectedContacts.length > 0) {
				fullscreenModal.history.push({
					pathname: `/texting-campaign/contacts`,
					state: {
						contactIds: contactsVM.selectedContacts.map(contact => contact.id),
					},
				});
			} else {
				fullscreenModal.history.push({
					pathname: `/texting-campaign/filter`,
					state: {
						initialContactIdsToOmit: contactsVM.excludedContacts.map(contact => contact.id),
						initialFilterCriteriaList: filter.filter.criteria,
						initialOwnershipFilter: filter.ownershipFilter,
					},
				});
			}
		};
		const onHwcTemplateClicked = (template: Api.IHandwrittenCardTemplate) => {
			if (routeAfterHwcTemplateSelection === 'contacts') {
				fullscreenModal.history.push({
					pathname: `/bulk-contact-selection-postcard/${template.id}`,
					state: {
						contactIds: contactsVM.selectedContacts.toArray().map(contact => contact.id),
					},
				});
			} else if (routeAfterHwcTemplateSelection === 'filter') {
				fullscreenModal.history.push({
					pathname: `/bulk-contact-filter-postcard/${template.id}`,
					state: {
						contactIdsToOmit: contactsVM.excludedContacts.map(contact => contact.id),
						tagFilterCriterias: filter.filter.criteria,
						ownershipFilter: filter.ownershipFilter,
					},
				});
			}
			setIsHwcTemplateChooserModalOpen(false);

			setRouteAfterHwcTemplateSelection(null);
		};
		const restoreModal = useModal(
			false,

			(result: IConfirmationDialogOption) => {
				if (result.isCta) {
					contactsVM.unarchiveContacts()?.then((restoredCount: number) => {
						toaster.push({
							message:
								restoredCount > 1
									? `${restoredCount} contacts were restored`
									: contactsVM.selectedContacts.length
										? getDisplayName(contactsVM.selectedContacts.toArray()[0]) + ' was restored'
										: `${restoredCount} contact restored`,
							type: 'successMessage',
						});

						contactsVM.deselectAll();
						contactsVM.reset();
						contactsVM.getContacts(filter, sort);
					});
				}
			},
			[contactsVM]
		);

		const keepInTouchModal = useModal(
			false,

			(frequency: number) => {
				onKeepInTouch(frequency);
			},
			[contactsVM]
		);

		const mergeModal = useModal(
			false,
			() => {
				contactsVM.selectedContacts.clear();
			},
			[contactsVM]
		);

		const addTagModal = useModal<string>(
			false,
			selectedTag => {
				// apply tag to local contacts
				const taggedContacts =
					contactsVM.selectionState === 'all' ||
					(contactsVM.selectionState === 'some' && contactsVM.selectedContacts.length === 0)
						? contactsVM.excludedContacts.length > 0
							? contactsVM.fetchResults.toArray().filter(x => !contactsVM.excludedContacts.has(x))
							: contactsVM.fetchResults.toArray()
						: contactsVM.selectedContacts.toArray();

				// update the contact vms that match
				runInAction(() => {
					taggedContacts.forEach(x => {
						const tags = x.tags || [];

						const lowerSelectedTag = selectedTag.toLocaleLowerCase();
						if (tags.findIndex(tag => tag.toLocaleLowerCase() === lowerSelectedTag) < 0) {
							tags.push(selectedTag);
							const contactModel: Api.IContact = { ...x.toJs(), tags };
							x.setEntity(contactModel);
						}
					});
					contactsVM.deselectAll();
					contactsVM.reset();
					contactsVM.getContacts(filter, sort);
				});
			},
			[contactsVM, filter, sort]
		);

		const contactOwnerModal = useModal(
			false,
			() => {
				logEvent('BulkAssignContactOwnerComplete');
				contactsVM.reset();
				contactsVM.getContacts(filter, sort);
			},
			[contactsVM, filter, sort]
		);

		const openContactOwnerModal = React.useCallback(() => {
			contactOwnerModal.setIsOpen(true)();
			setMoreMenuOpen(false)();
		}, [contactOwnerModal, setMoreMenuOpen]);

		const contactConnectionModal = useModal(
			false,
			() => {
				logEvent('BulkAssignContactConnectionComplete');
				contactsVM.reset();
				contactsVM.getContacts(filter, sort);
			},
			[contactsVM, filter, sort]
		);

		const openContactConnectionModal = React.useCallback(() => {
			contactConnectionModal.setIsOpen(true)();
			setMoreMenuOpen(false)();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [contactOwnerModal, setMoreMenuOpen]);

		const onExportContactsButtonClicked = () => {
			if (exportContactsMutation.isIdle) {
				logInput('Export', 'Click', contactsVM.filterRequest);
				setMoreMenuOpen(false)();
				exportContactsMutation.mutate({ exportRequest: contactsVM.getBulkContactRequest() });
			}
		};

		const onExportContactsClose = () => {
			exportContactsMutation.reset();
		};

		const onExportContactsPolicyReportButtonClicked = () => {
			if (exportContactsPolicyReportMutation.isIdle) {
				logInput('ExportPolicyReport', 'Click', contactsVM.filterRequest);
				setMoreMenuOpen(false)();
				exportContactsPolicyReportMutation.mutate({ exportRequest: contactsVM.getBulkContactRequest() });
			}
		};

		const onExportContactsPolicyReportModalClose = () => {
			exportContactsPolicyReportMutation.reset();
		};

		const deleteModal = useModal(
			false,

			(shouldDelete: boolean) => {
				if (!shouldDelete) {
					return;
				}

				if (isSelectingAll) {
					contactsVM
						.deleteMany()
						?.then(result => {
							toaster.push({
								message: `${result.value} contacts deleted`,
								type: 'successMessage',
							});
							contactsVM.reset();
							onReload();
						})
						?.catch((err: Api.IOperationResultNoValue) => {
							pushApiError(err);
							logEvent('DeleteManyContacts-Error', {
								error: { ...err },
								filter: contactsVM.filterRequest,
							});
						});
				} else {
					const selectedContacts = contactsVM.selectedContacts.toArray();
					contactsVM
						.delete(selectedContacts)
						?.then(() => {
							logInput('DeleteContacts', 'Click', {
								count: selectedContacts.length,
							});
							contactsVM.selectedContacts.clear();
						})
						?.catch((err: Api.IOperationResultNoValue) => {
							pushApiError(err);
							logEvent('DeleteContacts-Error', {
								count: selectedContacts.length,
								error: { ...err },
							});
						});
				}
			},
			[contactsVM, isSelectingAll]
		);

		const onDeleteClicked = React.useCallback(() => {
			deleteModal.setIsOpen(true)();
			setMoreMenuOpen(false)();
		}, [deleteModal, setMoreMenuOpen]);

		const continueWatchingSetVisibility = React.useCallback(
			(opResult: Api.IOperationResult<Api.ISystemJob>) => {
				if (!opResult.success) {
					return false;
				}

				if (opResult.value.percentComplete >= 100) {
					// reload
					contactsVM.reset();
					contactsVM.getContacts(filter, sort);
					return false;
				}
				return true;
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[contactsVM, filter, sort, visibilityJob]
		);

		React.useEffect(() => {
			visibilityJob?.startWatching?.(continueWatchingSetVisibility);
		}, [visibilityJob, continueWatchingSetVisibility]);

		const confirmationDialogModal = useModal(
			false,

			(result: IConfirmationDialogOption) => {
				if (result) {
					onVisibilityChanged(visibilityChangeType);
				}

				setVisibilityChangeType(null);
			},
			[visibilityChangeType]
		);

		const openConfirmationDialogModal = React.useCallback(
			(visibility: 'admin' | 'all') => () => {
				setVisibilityChangeType(visibility);
				confirmationDialogModal.setIsOpen(true)();
				setMoreMenuOpen(false)();
			},
			[confirmationDialogModal, setMoreMenuOpen]
		);

		const onVisibilityChanged = React.useCallback(
			(visibility: 'admin' | 'all') => {
				const request: Api.IContactsSetVisibilityRequest = {
					excludeContactIds: contactsVM.excludedContacts.map(x => x.id),
					filter: contactsVM.shouldIncludeFilterInRequest ? contactsVM.filterRequest?.filter : null,
					ownershipFilter: contactsVM.shouldIncludeFilterInRequest ? contactsVM.filterRequest?.ownershipFilter : null,
					includeContactIds: contactsVM.selectedContacts.map(x => x.id),
					visibility,
				};
				logInput('SetVisibility', 'Click', {
					count: contactsVM.selectedContacts.length,
				});
				contactsVM
					.setVisibility(request)
					?.then(systemJob => {
						setVisibilityJob(new Api.SystemJobViewModel(userSession, systemJob));
					})
					?.catch((error: Api.IOperationResultNoValue) => {
						logEvent('SetVisibility-Error', {
							error: { ...error },
							visibility,
						});
						pushApiError(error);
					});
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[contactsVM, visibilityJob, setVisibilityJob]
		);

		const visibilityModalContent = () => {
			return (
				<div>
					<div className={css(styleSheet.visibilityModalTitle)}>Change visibility</div>
					<div className={css(styleSheet.visibilityModalTextContainer)}>
						Are you sure you want to change the visibility of selected contacts so
						<span className={css(styleSheet.visibilityModalTextBold)}>
							{visibilityChangeType === 'admin'
								? ' only you can see them'
								: ' all Levitate users on your account can see them'}
							?
						</span>
					</div>
				</div>
			);
		};

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				{userSession.userRole === 'superAdmin' && isArchived() ? (
					<>
						<button
							className={css(styleSheet.button, styleSheet.keepInTouch, !canRestore && styleSheet.disabled)}
							onClick={restoreModal.setIsOpen(true)}
						>
							<span>
								<ArchiveIcon />
								<span className={css(styleSheet.buttonText)}>Restore selected contacts</span>
							</span>
						</button>
						<ConfirmationDialog
							modalProps={{
								isOpen: restoreModal.isOpen,
								onRequestClose: restoreModal.onRequestClose,
							}}
							options={RestoreConfirmationOptions}
							title='Restore selected contacts'
						>
							<div className={css(styleSheet.restoreContactText)}>Would you like to restore the selected contacts?</div>
						</ConfirmationDialog>
					</>
				) : (
					<>
						<TinyPopover
							anchor={
								<button
									className={css(styleSheet.button, styleSheet.keepInTouch, !canKeepInTouch && styleSheet.disabled)}
									onClick={keepInTouchModal.setIsOpen(true)}
								>
									<span>
										<ClockIcon fillColor={brandSecondary} />
										<span className={css(styleSheet.buttonText)}>Keep in touch</span>
									</span>
								</button>
							}
							anchorStyles={[styleSheet.anchor]}
							isOpen={keepInTouchModal.isOpen}
							onRequestClose={keepInTouchModal.setIsOpen(false)}
							dismissOnOutsideAction={true}
						>
							<KeepInTouchDropdown onFinish={keepInTouchModal.onRequestClose} isOpen={keepInTouchModal.isOpen} />
						</TinyPopover>
						<button
							className={css(styleSheet.button, styleSheet.sendEmail, !isActive && styleSheet.disabled)}
							onClick={onSendMessage}
						>
							<span>
								<EmailIcon fillColor='#EA8160' />
								<span className={css(styleSheet.buttonText)}>Send Email</span>
							</span>
						</button>
						{canSendTextingCampaign ? (
							<button
								className={css(
									styleSheet.button,
									styleSheet.sendText,
									contactsVM.selectionState === 'none' && styleSheet.disabled
								)}
								onClick={onSendTextClicked}
							>
								<span>
									<TextingIcon fill='#6E27A3' />
									<span className={css(styleSheet.buttonText)}>Send Text</span>
								</span>
							</button>
						) : null}
						{canShowCards ? (
							<button
								className={css(styleSheet.button, styleSheet.sendCard, !isActive && styleSheet.disabled)}
								onClick={onSendCard}
							>
								<span>
									<PostageIcon fillColor='#00AAE8' />
									<span className={css(styleSheet.buttonText)}>Send Card</span>
								</span>
							</button>
						) : null}
						<button
							className={css(styleSheet.button, styleSheet.tag, !isActive && styleSheet.disabled)}
							onClick={addTagModal.setIsOpen(true)}
						>
							<span>
								<TagIcon fillColor={brandPrimaryHover} />
								<span className={css(styleSheet.buttonText)}>Tag</span>
							</span>
						</button>
						<button
							className={css(styleSheet.button, styleSheet.merge, !canMerge && styleSheet.disabled)}
							onClick={mergeModal.setIsOpen(true)}
						>
							<span>
								<MergeIcon />
								<span className={css(styleSheet.buttonText)}>Merge</span>
							</span>
						</button>

						<button
							className={css(styleSheet.button, styleSheet.focus, !canFocus && styleSheet.disabled)}
							onClick={onFocusContacts}
						>
							<span>
								<TargetIcon />
								<span className={css(styleSheet.buttonText)}>Focused View</span>
							</span>
						</button>

						{canShowAutomations ? (
							<button
								className={css(styleSheet.button, styleSheet.export, !isActive && styleSheet.disabled)}
								onClick={() => setIsAutomationOpen(true)}
							>
								<span>
									<AutomationsIcon fillColor={success} />
									<span className={css(styleSheet.buttonText)}>Start Automations</span>
								</span>
							</button>
						) : (
							<button
								className={css(styleSheet.button, styleSheet.export, !isActive && styleSheet.disabled)}
								onClick={onExportContactsButtonClicked}
							>
								<span>
									<ExportIcon fillColor={success} />
									<span className={css(styleSheet.buttonText)}>Export</span>
								</span>
							</button>
						)}
						<TinyPopover
							anchor={
								<button
									className={css(styleSheet.button, styleSheet.more, !isActive && styleSheet.disabled)}
									onClick={setMoreMenuOpen(true)}
								>
									<span>
										<MoreIcon />
										<span className={css(styleSheet.buttonText)}>More</span>
									</span>
								</button>
							}
							anchorStyles={[styleSheet.anchor]}
							isOpen={isMoreMenuOpen}
							onRequestClose={setMoreMenuOpen(false)}
							dismissOnOutsideAction={true}
						>
							<ul className={css(styleSheet.moreDropdown)}>
								{canShowAutomations && (
									<li>
										<button className={css(styleSheet.moreDropdownItem)} onClick={onExportContactsButtonClicked}>
											<ExportIcon fillColor={toggleOn} />
											<span>Export</span>
										</button>
									</li>
								)}
								<li>
									<button
										className={css(styleSheet.moreDropdownItem)}
										onClick={onExportContactsPolicyReportButtonClicked}
									>
										<KeyDateIcon kind={KeyDateKind.Renewal} fillColor={contentCalendarTintColor} />
										<span>Export Policies</span>
									</button>
								</li>
								<li>
									<button className={css(styleSheet.moreDropdownItem)} onClick={openConfirmationDialogModal('all')}>
										<PublicVisibilityIcon />
										<span>Visibility: all employees</span>
									</button>
								</li>
								<TinyPopover
									align='center'
									anchor={
										<li>
											<button
												className={css(styleSheet.moreDropdownItem)}
												onClick={openConfirmationDialogModal('admin')}
											>
												<PrivateVisibilityIcon />
												<span>Visibility: private</span>
											</button>
										</li>
									}
									autoCloseOtherPopoversOnClick={false}
									placement={['right']}
									toggleOnHover={true}
									type={PopoverType.lightBlue}
								>
									<div className={css(styleSheet.visibilityPrivatePopover)}>
										Only contact owners and Super Admin will be able to view this contact.
									</div>
								</TinyPopover>
								<li>
									<button className={css(styleSheet.moreDropdownItem)} onClick={openContactOwnerModal}>
										<AssigneeIcon fillColor={brandSecondary} />
										<span>Assign Contact Owner</span>
									</button>
								</li>
								<li>
									<button className={css(styleSheet.moreDropdownItem)} onClick={openContactConnectionModal}>
										<AddUserIcon fillColor={brandSecondary} />
										<span>Add connection</span>
									</button>
								</li>
								<li>
									<button className={css(styleSheet.moreDropdownItem)} onClick={onDeleteClicked}>
										<TrashIcon fillColor={header} />
										<span>Delete</span>
									</button>
								</li>
							</ul>
						</TinyPopover>
						<ContactsAddTagModal
							request={{
								excludeContactIds: contactsVM.excludedContacts.map(c => c.id),
								includeContactIds: contactsVM.selectedContacts.map(c => c.id),
								filter: contactsVM.shouldIncludeFilterInRequest
									? contactsVM.nextRequest?.filter || contactsVM.filterRequest?.filter
									: undefined,
								ownershipFilter: contactsVM.shouldIncludeFilterInRequest
									? contactsVM.nextRequest?.ownershipFilter || contactsVM.filterRequest?.ownershipFilter
									: undefined,
							}}
							modalProps={{
								isOpen: addTagModal.isOpen,
								onRequestClose: addTagModal.onRequestClose,
							}}
						/>
						<AddContactConnectionModal
							contacts={contactsVM}
							modalProps={{
								isOpen: contactConnectionModal.isOpen,
								onRequestClose: contactConnectionModal.onRequestClose,
							}}
						/>
						<AssignContactOwnerModal
							contacts={contactsVM}
							modalProps={{
								isOpen: contactOwnerModal.isOpen,
								onRequestClose: contactOwnerModal.onRequestClose,
							}}
						/>
						{isAutomationOpen ? (
							<AutomationSelectorModal
								contactSelection={{
									includeContactIds: contactsVM.selectedContacts?.map(x => x.id),
									excludeContactIds: contactsVM.excludedContacts?.map(x => x.id),
								}}
								filterCriteria={contactsVM.filterRequest?.filter}
								ownershipFilter={contactsVM.filterRequest?.ownershipFilter}
								onDeselectAll={() => contactsVM.deselectAll()}
								closeModal={() => setIsAutomationOpen(false)}
								startAutomationForSelectedContacts={true}
							/>
						) : null}
						<ConfirmationDialog
							className={css(styleSheet.visibilityModalContainer)}
							icon={<WarningIcon />}
							modalProps={{
								isOpen: confirmationDialogModal.isOpen,
								onRequestClose: confirmationDialogModal.onRequestClose,
							}}
							options={ConfirmVisibilityChange}
						>
							{visibilityModalContent()}
						</ConfirmationDialog>
						{mergeModal.isOpen && <MergeContactsWizard contacts={contactsVM} onFinish={mergeModal.onRequestClose} />}
						<ExportConfirmationModalV2
							mutationResult={exportContactsMutation}
							modalProps={{
								isOpen: !exportContactsMutation.isIdle,
								onRequestClose: onExportContactsClose,
							}}
							subTitle={
								<>
									<p className={css(styleSheet.noMargin)}>
										The fields we&apos;ll export include: Name, Company, Job Title,
									</p>
									<p className={css(styleSheet.noMargin)}>Address, Phone Numbers, Email Address, OwnerID, and tags.</p>
								</>
							}
						/>
						<ExportConfirmationModalV2
							mutationResult={exportContactsPolicyReportMutation}
							modalProps={{
								isOpen: !exportContactsPolicyReportMutation.isIdle,
								onRequestClose: onExportContactsPolicyReportModalClose,
							}}
							subTitle={
								<>
									<p className={css(styleSheet.noMargin)}>
										The fields we&apos;ll export include: Name, First Name, Email,
									</p>
									<p className={css(styleSheet.noMargin)}>Owner, Line of Business, Carrier, and Renewal Date.</p>
								</>
							}
						/>
						<DeleteConfirmation
							bodyText='Are you sure you want to delete these contacts?'
							isOpen={deleteModal.isOpen}
							onFinish={deleteModal.onRequestClose}
						/>
						{isHwcTemplateChooserModalOpen ? (
							<HandwrittenCardTemplateSearchModal
								isOpen={true}
								onClose={() => setIsHwcTemplateChooserModalOpen(false)}
								onTemplateClicked={onHwcTemplateClicked}
							/>
						) : null}
					</>
				)}
			</div>
		);
	}
);
