import * as Api from '@ViewModels';
import {
	autoScrollForElements,
	autoScrollWindowForElements,
} from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
	monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { css } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { IMoveBoardItem, MoveBoardItemPosition } from '../../../extViewmodels/sdk/models';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

// Base interface for common props
interface BaseProps {
	children: React.ReactNode;
	className?: string;
}

// Component-specific interfaces
interface KanbanBoardProps extends BaseProps {
	children: React.ReactNode;
	className?: string;
}
interface KanbanBoardColumnProps extends BaseProps {
	id: string;
	index: number;
	enableDragging?: boolean;
	onColumnDrop?: (sourceIndex: number, targetIndex: number) => void;
	data: any;
}
type KanbanBoardColumnHeaderProps = BaseProps;
type KanbanBoardColumnTitleProps = BaseProps;
interface KanbanBoardCardProps extends BaseProps {
	id: string;
	index: number;
	data: any;
	onCardDrop?: (opportunity: Api.IOpportunity, moveBoardItem: IMoveBoardItem) => void;
}
type KanbanBoardCardContentProps = BaseProps;
interface KanbanBoardCardTitleProps extends BaseProps {
	name: string;
	link?: string;
}
type KanbanBoardColumnFooterProps = BaseProps;
type KanbanBoardColumnFooterContentProps = BaseProps;
type KanbanBoardColumnFooterTitleProps = BaseProps;

// Update interfaces to extend Record<string, unknown>
interface ColumnDropData extends Record<string, unknown> {
	type: 'column';
	id: string;
	index: number;
	data: Api.IBoardStage;
}

interface CardDropData extends Record<string, unknown> {
	type: 'card';
	id: string;
	index: number;
	data: Api.IOpportunity;
	overrideStageId?: string;
}

export const KanbanBoard: React.FC<KanbanBoardProps> = ({ children, className }) => {
	React.useEffect(() => {
		const cleanup = autoScrollWindowForElements();
		return cleanup;
	}, []);

	return <div className={`${css(styleSheet.board)} ${className || ''}`}>{children}</div>;
};

export const KanbanBoardColumn: React.FC<KanbanBoardColumnProps> = ({
	children,
	className,
	id,
	index,
	enableDragging = false,
	onColumnDrop,
	data,
}) => {
	const columnRef = React.useRef<HTMLDivElement>(null);
	const contentRef = React.useRef<HTMLDivElement>(null);
	const [isDragging, setIsDragging] = React.useState(false);
	const placeholderRef = React.useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const element = columnRef.current;
		const scrollContainer = contentRef.current;
		if (!element || !scrollContainer) return;

		// Only set up dragging if enabled
		const dragConfig = enableDragging
			? [
					draggable({
						element,
						dragHandle: element,
						getInitialData: (): ColumnDropData => ({
							type: 'column',
							id,
							index,
							data,
						}),
					}),
					dropTargetForElements({
						element,
						getIsSticky: () => true,
						getData: args => {
							return attachClosestEdge(
								{ type: 'column', id, index, data },
								{
									element,
									input: args.input,
									allowedEdges: ['left', 'right'],
								}
							);
						},
					}),
				]
			: [];

		const cleanup = combine(
			...dragConfig,
			autoScrollForElements({
				element: scrollContainer,
				canScroll: ({ source }) => source.data.type === 'card',
			}),
			// Add drop target for the column content to handle empty columns
			dropTargetForElements({
				element: scrollContainer,
				getIsSticky: () => true,
				getData: args => {
					return {
						type: 'card',
						id: args.source.data.id,
						index: 0,
						data: args.source.data,
						overrideStageId: id, // Add the column ID for empty column drops
					};
				},
			})
		);

		const unsubscribe = enableDragging
			? monitorForElements({
					onDragStart: ({ source }) => {
						if (source.element === element) {
							setIsDragging(true);
							const placeholder = document.createElement('div');
							placeholder.className = css(styleSheet.columnPlaceholder);
							const boardHeight = element.parentElement?.clientHeight || 0;
							placeholder.style.height = `${boardHeight}px`;
							placeholderRef.current = placeholder;
						}
					},
					onDrag: ({ source, location }) => {
						if (source.element !== element) return;
						const placeholder = placeholderRef.current;
						if (!placeholder) return;
						placeholder.remove();
						const dropTarget = location.current.dropTargets[0];
						if (!dropTarget) return;
						const closestEdge = extractClosestEdge(dropTarget.data);
						if (!closestEdge) return;
						const target = dropTarget.element;
						if (closestEdge === 'left') {
							target.parentElement?.insertBefore(placeholder, target);
						} else if (closestEdge === 'right') {
							target.parentElement?.insertBefore(placeholder, target.nextSibling);
						}

						// Handle empty column case
						if (dropTarget.data.overrideStageId) {
							const placeholderValue = placeholderRef.current;
							if (!placeholderValue) {
								const newPlaceholder = document.createElement('div');
								newPlaceholder.className = css(styleSheet.emptyColumnPlaceholder);
								placeholderRef.current = newPlaceholder;
							}
							placeholderValue.remove();
							contentRef.current.appendChild(placeholderValue);
						}
					},
					onDrop: ({ source, location }) => {
						if (source.element === element) {
							setIsDragging(false);
							placeholderRef.current?.remove();
							placeholderRef.current = null;

							const dropTarget = location.current.dropTargets[0];
							if (dropTarget && onColumnDrop) {
								const targetData = dropTarget.data;
								onColumnDrop(index, targetData.index as number);
							}
						}
					},
				})
			: () => {
					/* noop */
				};

		return () => {
			cleanup();
			unsubscribe();
			placeholderRef.current?.remove();
		};
	}, [id, index, enableDragging, onColumnDrop, data]);

	// Split children into header, content, and footer
	const childArray = React.Children.toArray(children);
	const header = childArray.find(child => React.isValidElement(child) && child.type === KanbanBoardColumnHeader);
	const footer = childArray.find(child => React.isValidElement(child) && child.type === KanbanBoardColumnFooter);
	const content = childArray.filter(
		child =>
			React.isValidElement(child) && child.type !== KanbanBoardColumnHeader && child.type !== KanbanBoardColumnFooter
	);

	return (
		<div
			ref={columnRef}
			data-column-id={id}
			className={`${css(styleSheet.column)} ${isDragging ? css(styleSheet.dragging) : ''} ${className || ''}`}
		>
			{header}
			{/* Pragmatic Dnd warns in console when Aphrodite adds the 'overflow: auto' async */}
			<div ref={contentRef} className={css(styleSheet.columnContent)} style={{ overflowY: 'auto' }}>
				{content}
			</div>
			{footer}
		</div>
	);
};

export const KanbanBoardColumnHeader: React.FC<KanbanBoardColumnHeaderProps> = ({ children, className }) => (
	<div className={`${css(styleSheet.columnHeader)} ${className || ''}`}>{children}</div>
);

export const KanbanBoardColumnTitle: React.FC<KanbanBoardColumnTitleProps> = ({ children, className }) => (
	<div className={`${css(styleSheet.columnTitle)} ${className || ''}`}>{children}</div>
);

export const KanbanBoardCard: React.FC<KanbanBoardCardProps> = ({
	children,
	className,
	id,
	index,
	onCardDrop,
	data,
}) => {
	const cardRef = React.useRef<HTMLDivElement>(null);
	const [isDragging, setIsDragging] = React.useState(false);
	const placeholderRef = React.useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const element = cardRef.current;
		if (!element) return;

		const cleanup = combine(
			// When dragging a card, this is what the onDragStart event uses to find which card it was dragged from
			draggable({
				element,
				dragHandle: element,
				getInitialData: (): CardDropData => ({
					type: 'card',
					id,
					data,
					index,
				}),
			}),
			// When dropping a card, this is what the onDrop event uses to find which card it was dropped on
			dropTargetForElements({
				element,
				getIsSticky: () => true,
				getData: args => {
					return attachClosestEdge(
						{
							type: 'card',
							id,
							index,
							data,
						},
						{
							element,
							input: args.input,
							allowedEdges: ['top', 'bottom'],
						}
					);
				},
			})
		);

		const unsubscribe = monitorForElements({
			onDragStart: ({ source }) => {
				if (source.element === element) {
					setIsDragging(true);
					// Wait for next frame to ensure content is rendered
					requestAnimationFrame(() => {
						const placeholder = document.createElement('div');
						placeholder.className = css(styleSheet.cardPlaceholder);
						const computedStyle = window.getComputedStyle(element);
						const height = Math.max(
							element.clientHeight - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom),
							50 // matching minHeight from styles
						);
						placeholder.style.height = `${height}px`;
						placeholderRef.current = placeholder;
					});
				}
			},
			onDrag: ({ source, location }) => {
				if (source.element !== element) return;

				const placeholder = placeholderRef.current;
				if (!placeholder) return;

				placeholder.remove();

				const dropTarget = location.current.dropTargets[0];
				if (!dropTarget) return;

				const closestEdge = extractClosestEdge(dropTarget.data);
				if (!closestEdge) return;

				const target = dropTarget.element;
				if (closestEdge === 'top') {
					target.parentElement?.insertBefore(placeholder, target);
				} else if (closestEdge === 'bottom') {
					target.parentElement?.insertBefore(placeholder, target.nextSibling);
				}
			},
			onDrop: ({ source, location }) => {
				if (source.element === element) {
					setIsDragging(false);
					placeholderRef.current?.remove();
					placeholderRef.current = null;

					const dropTarget = location.current.dropTargets[0];
					if (dropTarget && onCardDrop) {
						const targetData = dropTarget.data as unknown as CardDropData;
						const closestEdge = extractClosestEdge(dropTarget.data);
						const targetColumnId = targetData.overrideStageId || (targetData.data.boardStage?.id as string);
						const targetIndex = (targetData.index as number) + 1;
						const position = closestEdge === 'top' ? MoveBoardItemPosition.Before : MoveBoardItemPosition.After;
						const relativeToItemId = targetData.data.id;
						const fallbackIndex = targetIndex;
						const moveBoardItem: IMoveBoardItem = {
							stageId: targetColumnId,
							position,
							relativeToItemId,
							fallbackIndex: relativeToItemId ? undefined : fallbackIndex,
						};
						onCardDrop(data, moveBoardItem);
					}
				}
			},
		});

		return () => {
			cleanup();
			unsubscribe();
			placeholderRef.current?.remove();
		};
	}, [id, index, onCardDrop, data]);

	return (
		<div
			ref={cardRef}
			className={`${css(styleSheet.card)} ${isDragging ? css(styleSheet.dragging) : ''} ${className || ''}`}
		>
			{children}
		</div>
	);
};

export const KanbanBoardCardContent: React.FC<KanbanBoardCardContentProps> = ({ children, className }) => (
	<div className={`${css(styleSheet.cardContent)} ${className || ''}`}>{children}</div>
);

export const KanbanBoardCardTitle: React.FC<KanbanBoardCardTitleProps> = ({ children, className, name, link }) => (
	<div className={`${css(styleSheet.cardTitle)} ${className || ''}`}>
		{link ? (
			<>
				<div className={css(styleSheet.cardTitleName, baseStyleSheet.truncateText)}>
					<Link to={link} className={css(styleSheet.cardTitleNameLink)}>
						{name}
					</Link>
				</div>
				<div className={css(styleSheet.cardTitleDetails)}>{children}</div>
			</>
		) : (
			<>
				<div className={css(styleSheet.cardTitleName, baseStyleSheet.truncateText)}>{name}</div>
				<div className={css(styleSheet.cardTitleDetails)}>{children}</div>
			</>
		)}
	</div>
);

export const KanbanBoardColumnFooter: React.FC<KanbanBoardColumnFooterProps> = ({ children, className }) => (
	<div className={`${css(styleSheet.columnFooter)} ${className || ''}`}>{children}</div>
);

export const KanbanBoardColumnFooterContent: React.FC<KanbanBoardColumnFooterContentProps> = ({
	children,
	className,
}) => <div className={`${css(styleSheet.columnFooterContent)} ${className || ''}`}>{children}</div>;

export const KanbanBoardColumnFooterTitle: React.FC<KanbanBoardColumnFooterTitleProps> = ({ children, className }) => (
	<div className={`${css(styleSheet.columnFooterTitle)} ${className || ''}`}>{children}</div>
);
