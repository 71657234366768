import * as Api from '@ViewModels';
import * as React from 'react';
import { ContactSortKey } from '../../../../models';
import { HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey } from '../../../../models/Storage';
import { shouldDisableOwnershipDropdown } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContactResourceSelectorCardCountQuery, useContactResourceSelectorFilterQuery } from '../../../../queries';
import { IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { ISelectOption } from '../../../components/DeprecatedSelect';
import { ISelectBoxOption } from '../../../components/SelectBox';
import {
	BulkDeliveryMethodOptions,
	bulkDeliveryMethodFromFilterCriteria,
} from '../../../components/email/TagEmailRecipientsList';

const resourceSortOptions = [
	{
		dataContext: ContactSortKey.Handle,
		id: 'sort-option-first-name',
		text: 'Sort by first name A - Z',
		type: 'default',
	},
	{
		dataContext: ContactSortKey.HandleReverse,
		id: 'sort-option-last-name',
		text: 'Sort by last name A - Z',
		type: 'default',
	},
	{
		dataContext: ContactSortKey.KeyDate,
		id: 'sort-option-type',
		text: `Sort by birthday`,
		type: 'default',
	},
] as ISelectOption<ContactSortKey>[];

export const useHwcBirthdayRecipients = ({
	onSendFromChanged,
	onTagListSearchChange,
}: {
	onSendFromChanged?: (option: Api.ISendFromOptions) => void;
	onTagListSearchChange?: () => void;
} = {}) => {
	const userSession = useUserSession();
	const [selectedSortByOption, setSelectedSortByOption] = React.useState<ISelectOption<ContactSortKey>>(
		resourceSortOptions[0]
	);
	const [filterCriterias, setFilterCriterias] = React.useState<Api.IContactFilterCriteria[]>([
		{
			property: Api.ContactFilterCriteriaProperty.All,
		},
		{
			criteria: [
				{
					property: Api.ContactFilterCriteriaProperty.CapableOf,
					value: 'ReceiveEmail',
				},
				{
					property: Api.ContactFilterCriteriaProperty.CapableOf,
					value: 'ReceiveCard',
				},
				{
					property: Api.ContactFilterCriteriaProperty.CapableOf,
					value: 'ReceiveText',
				},
			],
			op: Api.FilterOperator.Or,
		},
	]);
	const [tagSearchCriterias, setTagSearchCriterias] = React.useState<Api.IContactFilterCriteria[]>([]);
	const allCriterias = React.useMemo(() => {
		return [...filterCriterias, ...tagSearchCriterias];
	}, [tagSearchCriterias, filterCriterias]);
	const [contactIdsToOmit, setContactIdsToOmit] = React.useState<string[]>([]);

	const [sendEmailFrom, setSendEmailFrom] = React.useState<Api.ISendOnBehalfPermission>(
		userSession.defaultSendOnBehalfPermissions.find(x => x.sender.mode === Api.SendEmailFrom.ContactOwner) ??
			userSession.defaultSendOnBehalfPermissions.find(x => x.sender.mode === Api.SendEmailFrom.CurrentUser)
	);
	const [disableShowingDropdown, setDisableShowingDropdown] = React.useState(
		shouldDisableOwnershipDropdown(!userSession.canSendOnBehalf, sendEmailFrom)
	);

	React.useEffect(() => {
		setDisableShowingDropdown(shouldDisableOwnershipDropdown(!userSession.canSendOnBehalf, sendEmailFrom));
	}, [userSession.canSendOnBehalf, sendEmailFrom]);

	const [sendEmailFromUser, setSendEmailFromUser] = React.useState<Api.IUser>(null);
	const getInitialOwnershipFilter = (overridenFilter: Api.IOwnershipFilter | null) => {
		if (overridenFilter) {
			return overridenFilter;
		}
		const firstFilter = userSession.user.ownershipFilters?.length > 0 ? userSession.user.ownershipFilters[0] : null;
		return firstFilter;
	};

	const [selectedOwnershipFilter, setSelectedOwnershipFilter] = React.useState<Api.IOwnershipFilter | null>(
		getInitialOwnershipFilter(null)
	);

	const contactResourceSelectorCountQuery = useContactResourceSelectorCardCountQuery({
		filterRequest: {
			excludeIds: contactIdsToOmit,
			filter: {
				criteria: allCriterias,
			},
			ownershipFilter: selectedOwnershipFilter,
		},
		resourceSelectorId: Api.ResourceSelectorId.HappyBirthday,
	});
	const contactResourceSelectorFilterQuery = useContactResourceSelectorFilterQuery({
		filterRequest: {
			filter: {
				criteria: allCriterias,
			},
			ownershipFilter: selectedOwnershipFilter,
		},
		resourceSelectorId: Api.ResourceSelectorId.HappyBirthday,
		sortBy: selectedSortByOption.dataContext,
	});

	const [showingAdvancedFiltersFlyout, setShowingAdvancedFiltersFlyout] = React.useState(false);
	const [isSuppressionIntroModalOpen, setIsSuppressionIntroModalOpen] = React.useState(false);
	const [contactToRemoveAfterSuppressionIntro, setContactToRemoveAfterSuppressionIntro] =
		React.useState<Api.IContact>();
	const selectedDeliveryMethodOption = bulkDeliveryMethodFromFilterCriteria(filterCriterias);
	const onSendOnBehalf = (option: Api.ISendOnBehalfPermission, user?: Api.IUser) => {
		setSendEmailFrom(option);
		setSendEmailFromUser(user);
		setSelectedOwnershipFilter(getInitialOwnershipFilter(option?.requiredFilter));

		onSendFromChanged?.(option?.sender);
	};
	const onTagListSearchesChanged = (searches: Api.IContactFilterCriteria[]) => {
		// Tag list is confined to filters
		setTagSearchCriterias(searches);
		onTagListSearchChange?.();
	};
	const onAdvancedFiltersFlyoutSave = (
		newFilterCriteria: Api.IContactFilterCriteria[],
		_?: boolean,
		ownershipFilter?: Api.IOwnershipFilter
	) => {
		if (!!newFilterCriteria && newFilterCriteria.length > 0) {
			setTagSearchCriterias(newFilterCriteria);
			onTagListSearchChange?.();
		}
		setSelectedOwnershipFilter(ownershipFilter);
		setShowingAdvancedFiltersFlyout(false);
	};
	const onAdvancedFiltersFlyoutCancel = () => {
		setShowingAdvancedFiltersFlyout(false);
	};
	const onDeliveryMethodOptionChanged = (selectedOption: ISelectBoxOption<BulkDeliveryMethodOptions>) => {
		const filterCriteriaToBeAdded: Record<string, Api.IContactFilterCriteria> = {
			all: {
				criteria: [
					{
						property: Api.ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveEmail',
					},
					{
						property: Api.ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveCard',
					},
					{
						property: Api.ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveText',
					},
				],
				op: Api.FilterOperator.Or,
			},
			withEmails: {
				property: Api.ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveEmail',
			},
			withTexts: {
				property: Api.ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveText',
			},
			withAddress: {
				property: Api.ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveCard',
			},
		};
		setFilterCriterias(
			Api.VmUtils.concatContactFilterCriteria(
				filterCriterias.filter(
					x =>
						!Api.VmUtils.areAllCriteriaOfProperty({
							criteria: x,
							property: Api.ContactFilterCriteriaProperty.CapableOf,
						})
				),

				[filterCriteriaToBeAdded[selectedOption.value]].filter(Boolean)
			)
		);
	};
	const onSelectedSortByOptionChanged = (_selectedResourceSortOption: ISelectOption<ContactSortKey>) => {
		setSelectedSortByOption(_selectedResourceSortOption);
	};
	const onRemoveRecipient = (contact: Api.IContact) => {
		const hasBeenNotifiedAboutSnooze = Boolean(
			localStorage.getItem(HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey)
		);
		if (!hasBeenNotifiedAboutSnooze) {
			setContactToRemoveAfterSuppressionIntro(contact);
			setIsSuppressionIntroModalOpen(true);
			return;
		}

		setContactIdsToOmit([...contactIdsToOmit, contact.id]);
	};
	const onSuppressionIntroModalClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!!result && !canceled && !!result.representedObject) {
			localStorage.setItem(HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey, 'true');

			setContactIdsToOmit([...contactIdsToOmit, contactToRemoveAfterSuppressionIntro.id]);
		}

		setContactToRemoveAfterSuppressionIntro(null);
		setIsSuppressionIntroModalOpen(false);
	};
	return {
		allFilterCriterias: allCriterias,
		contactIdsToOmit,
		contactResourceSelectorCountQuery,
		contactResourceSelectorFilterQuery,
		isSuppressionIntroModalOpen,
		disableShowingDropdown,
		selectedOwnershipFilter,
		setSelectedOwnershipFilter,
		onAdvancedFiltersFlyoutSave,
		onAdvancedFiltersFlyoutCancel,
		onDeliveryMethodOptionChanged,
		onRemoveRecipient,
		onSelectedSortByOptionChanged,
		onSendOnBehalf,
		onSuppressionIntroModalClose,
		onTagListSearchesChanged,
		selectedDeliveryMethodOption,
		selectedSortByOption,
		sendEmailFrom,
		sendEmailFromUser,
		sendFromDropdownDisabled: !userSession.canSendOnBehalf,
		setShowingAdvancedFiltersFlyout,
		showingAdvancedFiltersFlyout,
		tagSearchCriterias,
	};
};
