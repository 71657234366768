import { css } from 'aphrodite';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import { useGetBoard } from '../../../../queries';
import { AppBarNavigationItemPortalDestinationId } from '../../../components/AppBar';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from '../../../components/Breadcrumb';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { SmallGraph } from '../../../components/svgs/graphics/SmallGraphGraphic';
import { DoubleArrowIcon } from '../../../components/svgs/icons/DoubleArrowIcon';
import { brandSecondary } from '../../../styles/colors';
import { INavigationItemProps } from '../../MainContainer';
import { OpportunitiesContent } from './OpportunitiesContent';
import { styleSheet } from './styles';

interface IProps extends INavigationItemProps {
	className?: string;
}

export function OpportunitiesBoard({ className, routeContainerClassName }: IProps) {
	const fullscreenModal = useFullscreenModal();
	const match = useRouteMatch<{
		id: string;
	}>();

	const [locationName, setLocationName] = React.useState('Opportunities Board');

	const { id: boardId } = match.params;
	const getBoardQuery = useGetBoard({
		boardId,
	});

	return (
		<div className={`${css(styleSheet.container)} ${routeContainerClassName} ${className}`}>
			<MultiContainerHeader
				appBarHeader={
					<div className={css(styleSheet.breadcrumbsBar)}>
						<Breadcrumb>
							<BreadcrumbList>
								<BreadcrumbItem>
									<BreadcrumbLink asChild>
										<NavLink to='/dataBoards'>Data Boards</NavLink>
									</BreadcrumbLink>
								</BreadcrumbItem>
								<BreadcrumbSeparator />
								<BreadcrumbItem>
									<BreadcrumbPage>{getBoardQuery.data?.name ?? 'Opportunities Board'}</BreadcrumbPage>
								</BreadcrumbItem>
							</BreadcrumbList>
						</Breadcrumb>
						<div className={css(styleSheet.banner)}>
							<SmallGraph />
							<a
								href='#'
								className={css(styleSheet.bannerLink)}
								onClick={ev => {
									ev.preventDefault();
									fullscreenModal.history.push({
										pathname: `/reporting/advanced-opportunity-reporting/${boardId}`,
									});
								}}
							>
								Check our advanced reporting{' '}
								<DoubleArrowIcon fillColor={brandSecondary} style={{ marginLeft: '0.5rem' }} />
							</a>
						</div>
					</div>
				}
				appBarPortalDestinationId={AppBarNavigationItemPortalDestinationId}
				fullscreenHeader={locationName}
			/>

			<OpportunitiesContent setBreadcrumbLocationName={setLocationName} boardId={boardId} />
		</div>
	);
}
