import * as React from 'react';
import { navigation } from '../../../../styles/colors';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const TableLayoutIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	const width = 16;
	const height = 17;
	return (
		<svg
			className={className}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			width={width}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g fill={fillColor || navigation} fillRule='evenodd'>
				<polygon points='16 2 0 2 0 0 16 0' />
				<polygon points='16 6.999 0 6.999 0 4.999 16 4.999' />
				<polygon points='16 12 0 12 0 10 16 10' />
				<polygon points='16 17 0 17 0 15 16 15' />
			</g>
		</svg>
	);
};
