import {
	AutomationTemplateViewModel,
	IOperationResultNoValue,
	MeetingConfigViewModel,
	UserSessionContext,
} from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useEventLogging } from '../../../../../../models/Logging';
import { MeetingReminders } from '../MeetingReminders';
import { styleSheet } from '../styles';

interface IProps {
	meeting: MeetingConfigViewModel;
}

const DAYS_TO_ADD = 180;

export const AutomaticReminders: React.FC<IProps> = observer(({ meeting }) => {
	const logger = useEventLogging();

	const [selectedAutomation, setSelectedAutomation] = useState<AutomationTemplateViewModel<UserSessionContext>>(null);

	useEffect(() => {
		meeting.setAsEditing()?.catch((err: IOperationResultNoValue) => {
			logger.logApiError('MeetingConfigSetAsEditing-Error', err);
			// do not need to show user that this failed...
		});
		meeting.getAutomationChoices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (meeting?.automationChoicesList?.length && meeting?.meetingReminderTemplateId) {
			const automation = meeting?.automationChoicesList?.find(x => x.id === meeting?.meetingReminderTemplateId);

			setSelectedAutomation(automation);
		}
	}, [meeting, meeting?.automationChoicesList, meeting?.meetingReminderTemplateId]);

	const maxDate = new Date();
	maxDate.setDate(maxDate.getDate() + DAYS_TO_ADD);

	const handleMeetingReminderSelection = (item: AutomationTemplateViewModel<UserSessionContext>) => {
		setSelectedAutomation(item);

		meeting.meetingReminderTemplateId = item?.id;
	};

	return meeting?.automationChoicesList?.length > 0 ? (
		<div className={css(styleSheet.cardBottomContainer)}>
			<div className={css(styleSheet.card)}>
				<section className={css(styleSheet.sectionWrapper)}>
					<h3 className={css(styleSheet.sectionTitle)}>Automatic Reminders</h3>
					<p className={css(styleSheet.label)}>Please select an existing Meeting Reminder automation</p>
					<MeetingReminders
						automationChoices={meeting?.automationChoicesList}
						selectedAutomation={selectedAutomation}
						onSetTemplate={handleMeetingReminderSelection}
					/>
				</section>
			</div>
		</div>
	) : null;
});
