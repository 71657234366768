import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { v4 as uuid } from 'uuid';
import { useEventLogging } from '../../../models/Logging';
import { useActionItemComposer, useNoteComposer, useUserSession } from '../../../models/hooks/appStateHooks';
import { ActionItemViewModel, ActionItemsViewModel, NoteViewModel } from '../../../viewmodels/AppViewModels';
import ActionItemsPlaceholderIconUrl from '../../assets/actionItemsListPlaceholderIcon.svg';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { FabCalloutPlaceholder } from '../../components/FabCalloutPlaceholder';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Portal, PortalDestination } from '../../components/Portal';
import { ITabViewChild, TabView } from '../../components/TabView';
import { ActionItemsFilterValue } from '../../components/actionItems/ActionItemsFilter';
import { ActionItemsList } from '../../components/actionItems/ActionItemsList';
import { NotesList } from '../../components/notes/NotesList';
import { baseStyleSheet } from '../../styles/styles';
import { INavigationItemProps } from '../MainContainer';
import { styleSheet } from './styles';

export enum NotesActionItemsSelectedTabOption {
	Notes = 'notes',
	ActionItems = 'action-items',
	Completed = 'completed',
}

interface IProps extends INavigationItemProps {
	className?: string;
}

const NOTES_ACTION_ITEMS_PORTAL_ID = 'notes-action-items-tab-view-header';

function NotesActionItems({ className, routeContainerClassName }: IProps) {
	const scrollToBottomPortalId = React.useRef(uuid());
	const location = useLocation<any>();
	const params = useParams<{ id?: string }>();
	const history = useHistory();
	const userSession = useUserSession();
	const noteComposer = useNoteComposer();
	const actionItemComposer = useActionItemComposer();
	const { logEvent, logApiError } = useEventLogging('NotesActionItems');
	const [actionItems] = React.useState(new ActionItemsViewModel(userSession));
	const searchParams = parse(location.search);
	const activeTab =
		(searchParams.activeTab as NotesActionItemsSelectedTabOption | null) ?? NotesActionItemsSelectedTabOption.Notes;
	const actionItemId = searchParams.actionItemId;
	const noteId = searchParams.noteId;
	// When the tab changes, keep all the rest of the query params intact and just change the activeTab
	const redirectToTab = React.useCallback(
		(tab: NotesActionItemsSelectedTabOption) => {
			if (!history) {
				return;
			}
			const url = `/notes-action-items?${stringify({ ...searchParams, activeTab: tab })}`;
			history.replace(url);
		},
		[history, searchParams]
	);
	const closeModal = (sParamKey: string) => () => {
		if (!history || searchParams[sParamKey] == null) {
			return;
		}
		const sParams = { ...searchParams };
		delete sParams[sParamKey];
		history.replace(`/notes-action-items?${stringify(sParams)}`);
	};
	const closeNoteModal = closeModal('noteId');
	const closeActionItemModal = closeModal('actionItemId');
	React.useEffect(() => {
		if (!history) {
			return;
		}
		// Redirect to /notes-action-items if the user is on /action-items
		if (location.pathname.startsWith('/action-items')) {
			history.replace(
				`/notes-action-items?activeTab=${NotesActionItemsSelectedTabOption.ActionItems}${
					params.id ? `&actionItemId=${params.id}` : ''
				}`
			);
			return;
		}
		// Redirect to /notes-action-items if the user is on /notes
		// Match /notes and /notes/*. This is to handle the case where the user is on /notes and not on /notes-action-items
		else if (location.pathname.match(/^\/notes(\/.*)?$/) != null) {
			history.replace(
				`/notes-action-items?activeTab=${NotesActionItemsSelectedTabOption.Notes}${
					params.id ? `&noteId=${params.id}` : ''
				}`
			);
			return;
		}
	}, [activeTab, history, location, redirectToTab, params]);

	React.useEffect(() => {
		const load = async () => {
			const noteVm = new NoteViewModel(userSession, { id: noteId });
			logEvent('LoadNote', noteVm);
			try {
				await noteVm.load();

				noteComposer.show(noteVm, closeNoteModal);
			} catch (error) {
				logApiError('LoadNote', error);
			}
		};
		if (noteId) {
			load();
		}
		return () => {
			if (noteId == null) {
				noteComposer.reset();
			}
		};
	}, [noteId, noteComposer, actionItemComposer, logEvent, logApiError, userSession, closeNoteModal]);
	React.useEffect(() => {
		const load = async () => {
			const actionItemVm = new ActionItemViewModel(userSession, { id: actionItemId });
			logEvent('LoadActionItem', actionItemVm);
			try {
				await actionItemVm.load();

				actionItemComposer.show(actionItemVm, closeActionItemModal);
			} catch (error) {
				logApiError('LoadActionItem', error);
			}
		};
		if (actionItemId) {
			load();
		}
		return () => {
			if (actionItemId == null) {
				actionItemComposer.reset();
			}
		};
	}, [actionItemId, noteComposer, actionItemComposer, logEvent, logApiError, userSession, closeActionItemModal]);
	const onRenderActionItemsListPlaceholder = (inProgress: boolean) =>
		function ActionItemsListPlaceholder(isEmpty: boolean, isLoading: boolean) {
			if (isLoading) {
				return <LoadingSpinner className='action-items-container-loading' type='large' />;
			}
			if (isEmpty) {
				return (
					<FabCalloutPlaceholder
						iconUrl={ActionItemsPlaceholderIconUrl}
						showFabPointer={inProgress}
						subtitle={inProgress ? 'Add a reminder from the blue round button on the bottom right.' : undefined}
						title={inProgress ? 'You don’t have any reminders.' : 'You don’t have any completed reminders.'}
					/>
				);
			}
			return null;
		};
	const handleEditNote = (id: string) => {
		const newSearchParams = { ...searchParams, noteId: id };
		history.replace(`/notes-action-items?${stringify(newSearchParams)}`);
	};
	const getTabViewContent = (): ITabViewChild[] => {
		return [
			{
				content: <NotesList scrollToBottomPortalId={scrollToBottomPortalId.current} onEditNote={handleEditNote} />,
				tabbarItem: {
					content: 'Notes',
					onSelect: ev => {
						ev.preventDefault();
						redirectToTab(NotesActionItemsSelectedTabOption.Notes);
					},
				},
			},
			{
				content: (
					<ActionItemsList
						actionItemsVm={actionItems}
						headerPortalDestination={NOTES_ACTION_ITEMS_PORTAL_ID}
						initialSelectedFilterValue={ActionItemsFilterValue.CurrentUser}
						onRenderPlaceholder={onRenderActionItemsListPlaceholder(true)}
						scrollToBottomPortalId={scrollToBottomPortalId.current}
						showSearchBar={true}
						userSession={userSession}
					/>
				),
				tabbarItem: {
					content: 'Action Items',
					onSelect: ev => {
						ev.preventDefault();
						redirectToTab(NotesActionItemsSelectedTabOption.ActionItems);
					},
				},
			},
			{
				content: (
					<ActionItemsList
						actionItemsVm={actionItems}
						headerPortalDestination={NOTES_ACTION_ITEMS_PORTAL_ID}
						initialSelectedFilterValue={ActionItemsFilterValue.CurrentUser}
						onRenderPlaceholder={onRenderActionItemsListPlaceholder(false)}
						scrollToBottomPortalId={scrollToBottomPortalId.current}
						showCompletedItems={true}
						showSearchBar={true}
						userSession={userSession}
					/>
				),
				tabbarItem: {
					content: 'Completed',
					onSelect: ev => {
						ev.preventDefault();
						redirectToTab(NotesActionItemsSelectedTabOption.Completed);
					},
				},
			},
		];
	};

	return (
		<div className={`${routeContainerClassName || ''} ${css(styleSheet.container)} notes-container ${className || ''}`}>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<h1 className={css(baseStyleSheet.breadcrumbTitle)}>Notes & Actions</h1>
			</Portal>
			<TabView
				className='notes-action-items-tab-view'
				hasFixedHeaders={true}
				scrollViewContentClassName={routeContainerClassName}
				headerPortalDestinationId={NOTES_ACTION_ITEMS_PORTAL_ID}
				selectedTabIndex={
					{
						[NotesActionItemsSelectedTabOption.Notes]: 0,
						[NotesActionItemsSelectedTabOption.ActionItems]: 1,
						[NotesActionItemsSelectedTabOption.Completed]: 2,
					}[activeTab]
				}
			>
				{getTabViewContent}
			</TabView>
			<PortalDestination id={scrollToBottomPortalId.current} />
		</div>
	);
}

export const NoteActionItemsContainer = observer(NotesActionItems);
