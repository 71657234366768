import { brandPrimary, brandPrimaryActive, brandPrimaryHover, header } from '@Styles/colors';
import { CssSelectors } from '@Styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	connectCta: {
		marginTop: 20,
		width: '100%',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		maxWidth: 600,
		alignItems: 'center',
		paddingLeft: 200,
		paddingRight: 200,
	},
	tabView: {
		width: '100%',
	},
	tabViewTabs: {
		display: 'flex',
		borderBottom: '1px solid #e2e8f0',
	},
	tabViewItem: {
		color: header,
		display: 'inline-flex',
		flexBasis: 'auto',
		fontSize: 14,
		height: 44,
		padding: '0 36px',
		textAlign: 'center',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		gap: 8,
	},
	tabItemSelected: {
		borderBottom: `1px solid #0466FF`,
	},
	editingSignInFormField: {
		background: '#fff',
		border: `#aaa 1px solid`,
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		alignItems: 'center',
		padding: 16,
	},
	inProgressIconContainer: {
		...CssSelectors.allChildren({
			marginTop: 35,
			opacity: 0.148717,
		}),
		background: '#f9f9f9',
		borderRadius: '50%',
		height: 180,
		margin: '30px auto 40px',
		textAlign: 'center',
		width: 180,
	},
	inProgressText: {
		margin: '0 auto',
		textAlign: 'center',
		width: 350,
	},
	inputWrapper: {},
	link: {
		':active': {
			color: brandPrimaryActive,
		},
		':hover': {
			color: brandPrimary,
			cursor: 'pointer',
		},
		color: brandPrimaryHover,
		textDecoration: 'none',
	},
	logoCenter: {
		...CssSelectors.allChildren({
			margin: '0 auto',
		}),
	},
	logo: {
		...CssSelectors.allChildren({
			display: 'block',
			width: 350,
		}),
	},
	signInFormError: {
		color: '#B34755',
		fontSize: 14,
		marginTop: 20,
		minHeight: 50,
		textAlign: 'center',
	},
	signInFormField: {
		display: 'flex',
		margin: 0,
		width: 300,
	},
	textInput: {
		width: '100%',
	},
	signInFormFieldLabel: {
		color: '#4a4a4a',
		fontSize: 14,
		lineHeight: '22px',
	},
	synced: {
		...CssSelectors.allChildren({
			fontSize: 14,
			marginBottom: 30,
		}),
	},
	signInContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		alignItems: 'center',
	},
	signInForm: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
	tooltip: {
		marginLeft: 6,
	},
	tooltipContent: {
		display: 'block',
		overflowWrap: 'break-word',
		whiteSpace: 'normal',
		width: '350px',
	},
});
