import { numberToCurrencyStringValue } from '../../../../../models/UiUtils';
import { Dropdown } from '../../../Dropdown';
import { TextInput } from '../../../TextInput';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { IRange } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

export const DealSizeDropdown = ({
	disabled,
	onRangeChange,
	range,
}: {
	disabled: boolean;
	onRangeChange(range: IRange<number>): void;
	range: IRange<number>;
}) => {
	const [dropdownState, setDropdownState] = React.useState(false);

	const toggleDropdownState = () => {
		const isOpen = !dropdownState;
		setDropdownState(isOpen);
		if (range?.end === null || (range?.end === undefined && !!range)) {
			onRangeChange(null);
		}
	};

	const rangeAsString = (
		<>
			${numberToCurrencyStringValue(range?.start || 0)} &mdash; ${numberToCurrencyStringValue(range?.end || 0)}
		</>
	);

	const renderAnchor = (
		<button className={`${css(styleSheet.dropdownContainer)} select-box-trigger`} onClick={() => toggleDropdownState()}>
			<div className='select-box-trigger-content'>{range?.end ? rangeAsString : 'Amount'}</div>
			<div style={{ flexGrow: 1 }} />
			<div className='select-box-trigger-right-accessory'>
				<DisclosureIcon className='select-box-trigger-caret' />
			</div>
		</button>
	);

	const valueMask = (v: string) => (v ? Number(v.replace(/[^0-9]/g, '')) : null);

	const handleChangeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
		onRangeChange({
			...range,
			start: valueMask(event.target.value),
		});
	};

	const handleChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
		onRangeChange({
			end: valueMask(event.target.value),
			start: range?.start || 0,
		});
	};

	return (
		<Dropdown anchor={renderAnchor} isOpen={dropdownState} onOpenChanged={toggleDropdownState}>
			<div className={css(styleSheet.content)}>
				<TextInput
					className={css(styleSheet.inputField)}
					disabled={disabled}
					inputClassName={css(styleSheet.input)}
					inputId='deal-size-dropdown-from-input'
					leftAccessory={<span>$</span>}
					onChange={handleChangeStart}
					placeholder='0'
					type='text'
					value={range?.start}
				/>
				&mdash;
				<TextInput
					className={css(styleSheet.inputField)}
					disabled={disabled}
					inputClassName={css(styleSheet.input)}
					inputId='deal-size-dropdown-to-input'
					leftAccessory={<span>$</span>}
					onChange={handleChangeEnd}
					placeholder='0'
					type='text'
					value={range?.end}
				/>
			</div>
		</Dropdown>
	);
};
