import { brandSecondary, darkGrayFontColor, label, midGrey } from '../../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	itemChanges: {
		display: 'block',
		marginBottom: 5,
		...CssSelectors.allDescendants(
			{
				...baseStyleSheet.fontBold,
			},
			'strong'
		),
	},
	modalFooter: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 30,
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
			},
			':first-child'
		),
	},
	modalHeader: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 20,
		marginTop: 10,
	},
	modalHeaderTitle: {
		color: brandSecondary,
		fontSize: 18,
		lineHeight: 0.8,
		margin: 0,
		marginTop: 2,
		padding: 0,
	},
	select: {
		maxWidth: 'initial',
	},
	selectInfo: {
		color: darkGrayFontColor,
		display: 'block',
		fontSize: 14,
		marginTop: 7,
	},
	selectOption: {
		color: label,
	},
	selectOptionContainer: {
		':hover': {
			background: '#f0f0f0',
			cursor: 'pointer',
		},
		cursor: 'pointer',
		padding: '10px 15px',
	},
	selectOptionCurrent: {
		color: midGrey,
	},
});
