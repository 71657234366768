import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { useInsurancePolicyLineOfBusinessAutocompleteQuery } from '../../../../../queries';
import { Checkbox } from '../../../Checkbox';
import { Dropdown } from '../../../Dropdown';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet as dropdownStyles } from '../../DropdownComponent/styles';
import { styleSheet } from './styles';

export const PolicyLineOfBusinessFilterDropdown = ({
	selectedPolicyLineOfBusinessIds,
	setSelectedPolicyLineOfBusinessIds,
}: {
	selectedPolicyLineOfBusinessIds: string[];
	setSelectedPolicyLineOfBusinessIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const policyLineOfBusinessAutocompleteQuery = useInsurancePolicyLineOfBusinessAutocompleteQuery({
		enabled: true,
		pageSize: 25,
		fragment: '',
	});

	const policyLineOfBusinesses = React.useMemo(() => {
		return policyLineOfBusinessAutocompleteQuery.data?.pages.map(page => page.values).flat() ?? [];
	}, [policyLineOfBusinessAutocompleteQuery.data]);

	const dropdownMessage = React.useMemo(() => {
		if (selectedPolicyLineOfBusinessIds.length === 0) {
			return 'Line of Business';
		}
		const firstType = policyLineOfBusinesses.find(
			policyLineOfBusiness => policyLineOfBusiness.id === selectedPolicyLineOfBusinessIds[0]
		);
		return selectedPolicyLineOfBusinessIds.length === 1
			? `Has ${firstType.name} Policy`
			: `${firstType.name} or ${selectedPolicyLineOfBusinessIds.length - 1}`;
	}, [selectedPolicyLineOfBusinessIds, policyLineOfBusinesses]);

	const resetFilter = () => {
		setSelectedPolicyLineOfBusinessIds([]);
	};

	const onTogglePolicyLineOfBusiness = (policyLineOfBusiness: Api.IInsurancePolicyLineOfBusiness) => {
		const nextSelectedTypeIds = [...selectedPolicyLineOfBusinessIds];
		const currentIndex = selectedPolicyLineOfBusinessIds.indexOf(policyLineOfBusiness.id);

		if (currentIndex === -1) {
			nextSelectedTypeIds.push(policyLineOfBusiness.id);
		} else {
			nextSelectedTypeIds.splice(currentIndex, 1);
		}
		setSelectedPolicyLineOfBusinessIds(nextSelectedTypeIds);
	};

	return (
		<Dropdown
			anchor={
				<div className={css(dropdownStyles.dropdownAnchor)}>
					<span>{dropdownMessage}</span>
					<DisclosureIcon className={css(dropdownStyles.dropdownIcon)} />
				</div>
			}
			disabled={false}
			className={css(dropdownStyles.dropdownInline, isOpen ? dropdownStyles.dropdownInlineOpen : null)}
			contentClassName={css(dropdownStyles.dropdownMenu)}
			contentPositionY='bottom'
			contentInnerClassName={css(styleSheet.dropdownContentInnerOverrides)}
			isOpen={isOpen}
			onOpenChanged={() => setIsOpen(!isOpen)}
			openOnClick
		>
			<div className={css(dropdownStyles.dropdown)}>
				<div>
					{policyLineOfBusinesses.map(policyLineOfBusiness => {
						return (
							<div className={css(styleSheet.dropdownRow)} key={`policy-type-select-${policyLineOfBusiness.id}`}>
								<Checkbox
									type='large'
									checked={selectedPolicyLineOfBusinessIds.indexOf(policyLineOfBusiness.id) > -1}
									id={policyLineOfBusiness.id}
									onChange={() => onTogglePolicyLineOfBusiness(policyLineOfBusiness)}
								/>

								<span>{policyLineOfBusiness.name}</span>
							</div>
						);
					})}
				</div>

				{policyLineOfBusinessAutocompleteQuery.hasNextPage && (
					<Waypoint onEnter={() => policyLineOfBusinessAutocompleteQuery.fetchNextPage()} />
				)}
				<div className={css(dropdownStyles.dropdownSeparator)} />

				<button onClick={resetFilter}>Reset Filter</button>
			</div>
		</Dropdown>
	);
};
