import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { css } from 'aphrodite';
import * as React from 'react';
import { useParams } from 'react-router';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useBoardAdvancedReportingUrlQuery } from '../../../queries';
import { LoadingSpinner } from '../LoadingSpinner';
import { MultiContainerHeader } from '../MultiContainerHeader';
import { styleSheet } from './styles';

const iframeContainerId = 'quicksightAdvancedOpportunityReportingDashboard';

export const AdvancedOpportunityReporting = () => {
	const userSession = useUserSession();

	const params = useParams<{ boardId: string }>();
	const urlQuery = useBoardAdvancedReportingUrlQuery({
		boardId: params.boardId,
		cacheTime: 0,
		enabled: params?.boardId != null,
	});
	React.useEffect(() => {
		if (urlQuery.data) {
			const parameters = {
				AccountID: userSession.account.id,
				BoardID: params.boardId,
			};
			embedDashboard({
				container: document.getElementById(iframeContainerId),
				locale: 'en-US',
				parameters,
				url: urlQuery.data,
			});
		}
	}, [urlQuery.data, userSession, params.boardId]);
	return (
		<>
			<MultiContainerHeader fullscreenHeader='Advanced Reporting' />
			{urlQuery.data == null ? <LoadingSpinner type='large' /> : null}
			{urlQuery.data != null ? <div id={iframeContainerId} className={css(styleSheet.iframeContainer)} /> : null}
		</>
	);
};
