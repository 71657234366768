import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { Row } from '../../../../admin/components/Layout';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { invalidateGetNewsletterTemplatesByKeyword } from '../../../../queries';
import {
	invalidateTemplateKeywords,
	useTemplateKeywords,
} from '../../../../queries/Templates/useEmailTemplateKeywords';
import { invalidateGetTemplatesByIndustryQuery } from '../../../../queries/Templates/useGetTemplatesByIndustry';
import { useUpdateEmailTemplateKeywords } from '../../../../queries/Templates/useUpdateEmailTemplateKeywords';
import { charmGray } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { Pill } from '../../Pill/Pill';
import { PillMultiSelect } from '../../PillMultiSelect/PillMultiSelect';
import { KeywordIcon } from '../../svgs/icons/KeywordIcon';
import { styleSheet } from './styles';

export interface IProps {
	keywords: string[];
	templateId: string;
	creatorId: string;
	industry: Api.Industry;
	invalidateTemplates: () => void;
}

export const TemplateKeywords: React.FC<IProps> = ({
	creatorId,
	industry,
	keywords,
	templateId,
	invalidateTemplates,
}) => {
	const userSession = useUserSession();

	const keywordsQuery = useTemplateKeywords({
		industry,
	});

	const keywordsMutation = useUpdateEmailTemplateKeywords({
		onSuccess: () => {
			invalidateTemplates();
			invalidateTemplateKeywords({ industry });
		},
	});

	const cardKeywords = React.useMemo(() => keywords || [], [keywords]);

	const handleSelectItem = (selected: string) => {
		if (selected && !cardKeywords.find(keyword => keyword === selected)) {
			const updated = [...cardKeywords.filter(keyword => !!keyword), selected];
			keywordsMutation.mutate({
				keywords: updated,
				templateId,
			});
		}
	};

	const isMyOwnTemplate = userSession?.user?.id === creatorId;

	const handleRemove = (selected: string) => {
		const updated = cardKeywords.filter(k => k !== selected);

		keywordsMutation.mutate({
			keywords: updated,
			templateId,
		});

		invalidateGetNewsletterTemplatesByKeyword({
			impersonationContext: userSession.impersonationContext,
			industry,
			templateType: Api.TemplateType.HtmlNewsletter,
		});
		invalidateGetTemplatesByIndustryQuery({
			impersonationContext: userSession.impersonationContext,
			industry,
			types: [Api.TemplateType.HtmlNewsletter],
		});
	};

	return (
		<div className={css(styleSheet.keywordsContainer)}>
			{cardKeywords.length > 0 && !isMyOwnTemplate && (
				<Row className={css(bs.gap2, bs.maxHFit, bs.flexWrap)}>
					<KeywordIcon fillColor={charmGray} className={css(bs.pr1)} />
					{cardKeywords.map((keyword, i) => (
						<Pill label='label' variant='keyword' key={`${keyword}-${i}`} />
					))}
				</Row>
			)}
			{isMyOwnTemplate && (
				<PillMultiSelect
					variant='keyword'
					accessory={<KeywordIcon fillColor={charmGray} />}
					addButtonLabel='Add Keyword'
					options={keywordsQuery.data}
					selectedOptions={cardKeywords}
					onEditOptions={handleSelectItem}
					onRemove={handleRemove}
					onOptionClick={handleSelectItem}
					disabled={keywordsMutation.isLoading || keywordsQuery.isLoading}
				/>
			)}
		</div>
	);
};
