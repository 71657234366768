import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { invalidateBoardQuery, invalidateBoardsQuery, useUpdateBoardMutation } from '../../../../../queries';
import { BoardStageDefinitionViewModel, BoardViewModel, VmUtils } from '../../../../../viewmodels/AppViewModels';
import { useOpportunitiesContext } from '../../../../containers/dataBoards/OpportunitiesBoard/context';
import { styleSheet as dbStyleSheet } from '../../../../containers/dataBoards/styles';
import { brandSecondary, navigation } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { DateRangePickerModal } from '../../../DateRangePicker';
import { DeprecatedSelect, ISelectOption } from '../../../DeprecatedSelect';
import { PopoverType, TinyPopover } from '../../../TinyPopover';
import {
	AutoCompleteSearchField,
	IAutoCompleteSearchFieldComponent,
} from '../../../autocomplete/AutoCompleteSearchField';
import { EditBoardModal } from '../../../boards/EditBoard';
import { CardLayoutIcon } from '../../../svgs/icons/CardLayoutIcon';
import { ExportIcon } from '../../../svgs/icons/ExportIcon';
import { getSettingsIcon } from '../../../svgs/icons/NavIcon';
import { SearchIcon } from '../../../svgs/icons/SearchIcon';
import { TableLayoutIcon } from '../../../svgs/icons/TableLayoutIcon';
import { DealSizeDropdown } from '../DealSizeDropdown';
import { styleSheet } from './styles';

export type OpportunitiesBoardLayoutType = 'cards' | 'table';
type OpportunityPropertyOption = ISelectOption<{
	value: Api.OpportunitySearchProperty;
	autoCompleteType: Api.ResourceAutoCompleteViewModelType;
}>;

const ClosingDateRanges: ISelectOption<number>[] = [
	{
		dataContext: 30,
		id: 'closing-date-option-30',
		text: 'Next 30 days',
	},
	{
		dataContext: 60,
		id: 'closing-date-option-60',
		text: 'Next 60 days',
	},
	{
		dataContext: 90,
		id: 'closing-date-option-90',
		text: 'Next 90 days',
	},
	{
		dataContext: 0,
		id: 'closing-date-option-date-range',
		text: 'Date Range',
	},
];

const OpportunityPropertyOptions: OpportunityPropertyOption[] = [
	{
		dataContext: {
			autoCompleteType: null,
			value: Api.OpportunitySearchProperty.Name,
		},
		id: 'opportunity-property-opportunity-name',
		text: 'Opportunity Name',
	},
	{
		dataContext: {
			autoCompleteType: Api.ResourceAutoCompleteViewModelType.Company,
			value: Api.OpportunitySearchProperty.Company,
		},
		id: 'opportunity-property-company',
		text: 'Company',
	},
	{
		dataContext: {
			autoCompleteType: Api.ResourceAutoCompleteViewModelType.Contact,
			value: Api.OpportunitySearchProperty.PrimaryContact,
		},
		id: 'opportunity-property-primary-contact',
		text: 'Primary Contact',
	},
	{
		dataContext: {
			autoCompleteType: Api.ResourceAutoCompleteViewModelType.User,
			value: Api.OpportunitySearchProperty.Owner,
		},
		id: 'opportunity-property-owner',
		text: 'Owner',
	},
];

export const OpportunitiesBoardHeader = ({
	board,
	onLayoutTypeChanged,
	onSearchRequestChanged,
	onExportButtonClicked,
	onImportButtonClicked,
	isSearching,
}: {
	board: Api.IBoard;
	onLayoutTypeChanged?(layoutType: OpportunitiesBoardLayoutType): void;
	onSearchRequestChanged?(searchRequest: Api.IOpportunitySearchRequest): void;
	onExportButtonClicked?(searchRequest: Api.IOpportunitySearchRequest, includeActivity: boolean): void;
	onImportButtonClicked?(): void;
	isSearching: boolean;
}) => {
	const userSession = useUserSession();
	const { setShowArchived, selectedStage, setSelectedStage, searchRequest, setSearchRequest } =
		useOpportunitiesContext();
	const [layoutType, setLayoutType] = React.useState('cards');
	const [selectedOpportunityPropertyOption, setSelectedOpportunityPropertyOption] = React.useState(
		OpportunityPropertyOptions[0]
	);
	const [showEditBoardPopover, setShowEditBoardPopover] = React.useState(false);
	const [showExportPopover, setShowExportPopover] = React.useState(false);

	const [searchFieldValue, setSearchFieldValue] = React.useState('');
	const [selectedDateRangeOption, setSelectedDateRangeOption] = React.useState(null);
	const [showingDateRangePicker, setShowingDateRangePicker] = React.useState(null);

	const [showingEditBoardModal, setShowingEditBoardModal] = React.useState(false);

	const searchFieldInnerRef = React.useRef<IAutoCompleteSearchFieldComponent>(null);

	React.useEffect(() => {
		executeSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchRequest]);

	const disabled = !board;

	const dealSize = searchRequest ? searchRequest.dealSize : null;
	const resultsCount =
		!!selectedOpportunityPropertyOption && selectedOpportunityPropertyOption.dataContext.autoCompleteType === 'tag'
			? 10
			: 5;

	const stageOptions: ISelectOption<Api.IBoardStage>[] = board?.stages.map(stage => ({
		dataContext: stage,
		id: stage.id,
		text: stage.name,
	}));

	const renderClosingDateTrigger = () => {
		const dateRange =
			!!searchRequest && !!searchRequest.closeDate ? (
				<span>
					<span>{`${moment(searchRequest.closeDate.start).format('MM/DD')} `}</span>
					&mdash;
					<span>{` ${moment(searchRequest.closeDate.end).format('MM/DD')}`}</span>
				</span>
			) : null;
		return <div>{dateRange || 'Closing Date'}</div>;
	};

	const onLayoutButtonClicked = (newLayoutType: OpportunitiesBoardLayoutType) => () => {
		setLayoutType(newLayoutType);
		onLayoutTypeChanged(newLayoutType);
	};

	const onDealSizeChanged = (newDealSize: Api.IRange<number>) => {
		setSearchRequest({
			...(searchRequest || {}),
			dealSize: newDealSize,
		});
	};

	const onClearSearchClicked = () => {
		if (searchFieldInnerRef.current) {
			searchFieldInnerRef.current.clearInput();
		}

		setSearchFieldValue('');
		setSearchRequest(null);
		setSelectedDateRangeOption(null);
		setSelectedOpportunityPropertyOption(OpportunityPropertyOptions[0]);
		setSelectedStage(null);
	};

	const onSearchFieldInnerRef = (ref?: IAutoCompleteSearchFieldComponent) => {
		searchFieldInnerRef.current = ref;
	};

	const onDateRangePickerRequestClose = (range?: Api.IRange<Date>, cancel?: boolean) => {
		setShowingDateRangePicker(false);

		const rangeSelected = !!range && !cancel;
		if (rangeSelected) {
			setSearchRequest({
				...(searchRequest || {}),
				closeDate: {
					end: moment(range.end).toISOString(),
					start: moment(range.start).toISOString(),
				},
			});
			setSelectedDateRangeOption(null);
		}
	};

	const onClosingDateSelected = (option: ISelectOption<number>) => {
		if (option.dataContext > 0) {
			setSearchRequest({
				...(searchRequest || {}),
				closeDate: {
					end: moment().startOf('day').add(option.dataContext, 'days').toISOString(),
					start: moment().startOf('day').toISOString(),
				},
			});
			setSelectedDateRangeOption(option);
		} else {
			setShowingDateRangePicker(true);
		}
	};

	const onStageSelected = (stageOption: ISelectOption<Api.IBoardStage>) => {
		setSearchRequest({
			...(searchRequest || {}),
			stageId: stageOption.id,
		});
		setSelectedStage(stageOption.dataContext);
	};

	const onSearchFieldAutocompleteItemSelected = (
		item: Api.IEntity | string | Api.IUser,
		type: Api.ResourceAutoCompleteViewModelType
	) => {
		if (item) {
			switch (type) {
				case Api.ResourceAutoCompleteViewModelType.Company: {
					const company = item as Api.ICompany;
					setSearchRequest({ ...searchRequest, searchValue: company.id });
					setSearchFieldValue(company.companyName);
					break;
				}
				case Api.ResourceAutoCompleteViewModelType.Contact: {
					const contact = item as Api.IContact;
					setSearchRequest({ ...searchRequest, searchValue: contact.id });
					setSearchFieldValue(contact.handle);
					break;
				}
				case Api.ResourceAutoCompleteViewModelType.User: {
					const user = item as Api.IUser;
					setSearchRequest({ ...searchRequest, searchValue: user.id });
					setSearchFieldValue(VmUtils.getDisplayName(user));
					break;
				}
				default: {
					setSearchRequest({ ...searchRequest, searchValue: null });
					setSearchFieldValue('');
					break;
				}
			}
		}
	};

	const onSearchFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 13) {
			if (
				!!selectedOpportunityPropertyOption &&
				selectedOpportunityPropertyOption.dataContext.value === Api.OpportunitySearchProperty.Name
			) {
				setSearchRequest({
					...(searchRequest || {
						searchProperty: selectedOpportunityPropertyOption.dataContext.value,
					}),
					searchValue: searchFieldValue,
				});
			}
		}
	};

	const onOpportunityPropertyOptionChanged = (option: OpportunityPropertyOption) => {
		if (searchFieldInnerRef.current) {
			searchFieldInnerRef.current.clearInput();
		}

		setSearchFieldValue('');

		setSearchRequest({
			...(searchRequest || {}),
			searchProperty: option.dataContext.value || Api.OpportunitySearchProperty.Name,
			searchValue: null,
		});
		setSelectedOpportunityPropertyOption(option);
	};

	const updateBoardMutation = useUpdateBoardMutation({
		onSuccess: () => {
			invalidateBoardQuery(board.id);
			invalidateBoardsQuery();
		},
	});

	const onEditBoard = async (
		updatedBoardVm: BoardViewModel,
		name: string,
		stageDefinitions: BoardStageDefinitionViewModel[],
		newShowArchivedValue: boolean
	): Promise<BoardViewModel> => {
		await updateBoardMutation.mutateAsync({
			...updatedBoardVm.toJs(),
			name,
			stages: stageDefinitions.map(x => x.toJs()),
		});
		setShowArchived(newShowArchivedValue);
		return Promise.resolve(updatedBoardVm);
	};

	const executeSearch = () => {
		onSearchRequestChanged(searchRequest);
	};

	const exportOpportunities = (includeActivity: boolean) => {
		setShowExportPopover(false);
		onExportButtonClicked(searchRequest, includeActivity);
	};

	const boardVm = React.useMemo<BoardViewModel | null>(() => {
		if (!board) return null;
		const boardWithoutInfiniteLoop: Api.IBoard = {
			...board,
			stages: board.stages.map(stage => ({ ...stage, board: undefined }) as Api.IBoardStage),
		};
		return new BoardViewModel(userSession, boardWithoutInfiniteLoop);
	}, [userSession, board]);

	return (
		<div className={css(styleSheet.container)}>
			{!!board && (
				<>
					<header className={css(dbStyleSheet.header)}>
						<div className={css(dbStyleSheet.propertySearch)}>
							<DeprecatedSelect
								styles={[dbStyleSheet.searchPropertySelector]}
								onOptionClick={onOpportunityPropertyOptionChanged}
								options={OpportunityPropertyOptions}
								selectedOption={selectedOpportunityPropertyOption}
							/>
							<AutoCompleteSearchField
								anchorClassName={css(styleSheet.searchFieldAnchor)}
								disableAutocomplete={
									selectedOpportunityPropertyOption
										? !selectedOpportunityPropertyOption.dataContext.autoCompleteType
										: true
								}
								contentStyles={[styleSheet.searchFieldDropDownContent]}
								hideResultsFooter={true}
								inputId='opportunities-search-field-input'
								inputProps={{
									disabled,
									onChange: e => setSearchFieldValue(e.target.value || ''),
									onKeyDown: onSearchFieldKeyDown,
									placeholder: 'Search',
									value: searchFieldValue,
								}}
								leftAccessory={<SearchIcon />}
								onInnerRef={onSearchFieldInnerRef}
								onItemSelected={onSearchFieldAutocompleteItemSelected}
								pageSize={resultsCount}
								resultsLimit={resultsCount}
								type={
									(!!selectedOpportunityPropertyOption &&
										selectedOpportunityPropertyOption.dataContext.autoCompleteType) ||
									Api.ResourceAutoCompleteViewModelType.Contact
								}
							/>
						</div>

						<DeprecatedSelect
							onOptionClick={onStageSelected}
							options={stageOptions}
							selectedOption={stageOptions.find(x => x.id === selectedStage?.id)}
							selectedOptionTitle={selectedStage?.name || 'Stage'}
							styles={[styleSheet.selectContainer]}
						/>

						<div className={css(styleSheet.selectContainer)}>
							<DealSizeDropdown onRangeChange={onDealSizeChanged} range={dealSize} disabled={disabled} />
						</div>

						<DeprecatedSelect
							onOptionClick={onClosingDateSelected}
							options={ClosingDateRanges}
							selectedOption={selectedDateRangeOption}
							selectedOptionTitle={renderClosingDateTrigger}
							styles={[styleSheet.selectContainer]}
						/>
						{!!isSearching && (
							<button className={css(styleSheet.clearSearch)} disabled={disabled} onClick={onClearSearchClicked}>
								<span>Clear Search</span>
							</button>
						)}

						<div className={css(styleSheet.actionButtonContainer)}>
							<button
								className={css(baseStyleSheet.ctaButtonReverse, dbStyleSheet.importButton)}
								onClick={onImportButtonClicked}
								style={{ marginRight: 10 }}
							>
								Import from CSV
							</button>
							<button
								disabled={disabled}
								className='opportunities-board-header-layout-button'
								onClick={onLayoutButtonClicked('cards')}
							>
								<CardLayoutIcon fillColor={layoutType === 'cards' ? brandSecondary : undefined} />
							</button>
							<button
								disabled={disabled}
								className='opportunities-board-header-layout-button'
								onClick={onLayoutButtonClicked('table')}
							>
								<TableLayoutIcon fillColor={layoutType === 'table' ? brandSecondary : undefined} />
							</button>
							<TinyPopover
								align='start'
								anchor={
									<div className={css(styleSheet.boardButtonContainer)}>
										<button className={css(styleSheet.boardButton)} onClick={() => setShowExportPopover(true)}>
											<ExportIcon fillColor='#A7ABAD' />
											<span>Export</span>
										</button>
									</div>
								}
								dismissOnOutsideAction
								isOpen={showExportPopover}
								onRequestClose={() => setShowExportPopover(false)}
								placement={['bottom']}
								type={PopoverType.white}
							>
								<div onClick={() => exportOpportunities(false)} className={css(styleSheet.exportOption)}>
									All Opportunities
								</div>
								<div onClick={() => exportOpportunities(true)} className={css(styleSheet.exportOption)}>
									Full Activity
								</div>
							</TinyPopover>
							<button
								disabled={disabled}
								className={css(styleSheet.boardButton)}
								onClick={() => setShowingEditBoardModal(true)}
							>
								{getSettingsIcon(navigation)}
								<TinyPopover
									align='center'
									anchor={<div onMouseOut={() => setShowEditBoardPopover(false)}>Edit Board</div>}
									dismissOnOutsideAction={true}
									isOpen={showEditBoardPopover}
									onRequestClose={() => setShowEditBoardPopover(false)}
									placement={['bottom', 'left', 'right']}
								>
									<div className={css(styleSheet.editBoardInfo)}>Click Edit Board to show/hide the Archived column</div>
								</TinyPopover>
							</button>
						</div>
					</header>

					<EditBoardModal
						board={boardVm}
						modalProps={{
							isOpen: showingEditBoardModal,
							onRequestClose: () => setShowingEditBoardModal(false),
							shouldCloseOnOverlayClick: false,
						}}
						onEditBoard={onEditBoard}
					/>
					<DateRangePickerModal
						allowPastDates={true}
						modalProps={{
							isOpen: !!showingDateRangePicker,
							onRequestClose: onDateRangePickerRequestClose,
						}}
					/>
				</>
			)}
		</div>
	);
};
