import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IUser } from '../../../extViewmodels';
import { IModalContext } from '../../../models';
import { getDisplayName, getPrincipalInitials, isValidEmail } from '../../../models/UiUtils';
import { ActivationStatus, IUserPreferences } from '../../../viewmodels/AppViewModels';
import { Avatar2 } from '../../components/Avatar2';
import { DeprecatedMoreMenu } from '../../components/DeprecatedMoreMenu';
import { HelpTooltip } from '../../components/HelpTooltip';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { asModalComponent } from '../../components/Modal';
import { ISelectBoxOption, SelectBox } from '../../components/SelectBox';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { EmailSignatureSettings } from '../../components/settings/PersonalSettings/EmailSignatureSettings';
import { HandwrittenCardSettings } from '../../components/settings/PersonalSettings/HandwrittenCardSettings';
import { SuccessCheckIcon } from '../../components/svgs/icons/SuccessCheckIcon';
import { baseStyleSheet } from '../../styles/styles';
import { ScheduleMeeting } from '../ScheduleMeeting';
import { useScheduleMeetingSettings } from '../ScheduleMeeting/useScheduleMeetingSettings';
import { styleSheet } from './styles';

const ManageUsersLicenseInfo = ({
	consumedUsers,
	isLoading,
	totalLicenses,
}: {
	consumedUsers: number;
	isLoading: boolean;
	totalLicenses: number;
}) => {
	if (!isLoading) {
		return (
			<p className={css(styleSheet.licenseCount)}>
				You have used <strong className={css(styleSheet.licenseCountStrong)}>{consumedUsers?.toString()}</strong> of{' '}
				{totalLicenses?.toString()} licenses. Please{' '}
				<a className='settings-contactSupportLink' href='mailto:support@levitateapp.com'>
					contact support
				</a>{' '}
				if you want to change your plan.
			</p>
		);
	}
	return <div>Loading user license information...</div>;
};

export const ManageUsersListViewHeader = ({
	consumedUsers,
	loading,
	totalLicenses,
	inviteClick,
}: {
	consumedUsers: number;
	loading: boolean;
	totalLicenses: number;
	inviteClick: () => void;
}) => {
	return (
		<div className='scroll-view-header-wrap'>
			<div>
				<ManageUsersLicenseInfo isLoading={loading} consumedUsers={consumedUsers} totalLicenses={totalLicenses} />
			</div>
			<div className={css(styleSheet.managedUsersActionBarContainer)}>
				<button className={css(baseStyleSheet.ctaButton)} onClick={inviteClick}>
					<span>Invite Employees</span>
				</button>
			</div>
			<div className={css(styleSheet.managedUsersEmployeeListHeader)}>
				<div className={css(styleSheet.headerLabel, styleSheet.headerAvatar)} />
				<div className={css(styleSheet.headerLabel, styleSheet.headerName)}>Employee</div>
				<div className={css(styleSheet.headerLabel, styleSheet.headerType)}>
					<div>Type</div>
					<HelpTooltip className={css(styleSheet.helpTooltip)}>
						<p className={css(styleSheet.headerTypeTooltipContent)}>
							Administrators have the ability to manage employees and groups, run reports, and see all contacts and
							notes in the system (including private notes).
						</p>
					</HelpTooltip>
				</div>
				<div className={css(styleSheet.headerLabel, styleSheet.headerStatus)}>Status</div>
				<div className={css(styleSheet.headerLabel, styleSheet.headerSignature)}>Signature</div>
				<div className={css(styleSheet.headerLabel, styleSheet.headerActions)}>Actions</div>
			</div>
		</div>
	);
};

enum EManageUsersMoreMenuActions {
	Deactivate = 'deactivate',
	EditCardSettings = 'editCardSettings',
	EditMeetingLinks = 'editMeetingLinks',
	EditUserEmail = 'editUserEmail',
	EditSignature = 'EditSignature',
}

interface IManageUsersMoreMenuActions {
	name: string;
	representedObject: EManageUsersMoreMenuActions;
}

const ManageUsersActionLink = ({
	deactivateClick,
	deleteClick,
	editCardSettingsClick,
	editMeetingLinksClick,
	editSignatureClick,
	editUserEmailClick,
	reactivateClick,
	user,
	userSession,
}: {
	deactivateClick: () => void;
	deleteClick: () => void;
	editCardSettingsClick: () => void;
	editMeetingLinksClick: () => void;
	editSignatureClick: () => void;
	editUserEmailClick: () => void;
	reactivateClick: () => void;
	user: IUser;
	userSession: Api.UserSessionContext;
}): JSX.Element => {
	const menuOptions: IManageUsersMoreMenuActions[] = [
		user?.userMilestones?.hasSignatureTemplate && {
			name: 'Edit Signature',
			representedObject: EManageUsersMoreMenuActions.EditSignature,
		},
		{
			name: 'Edit Card Settings',
			representedObject: EManageUsersMoreMenuActions.EditCardSettings,
		},
		{
			name: 'Edit Meeting Links',
			representedObject: EManageUsersMoreMenuActions.EditMeetingLinks,
		},
		userSession.account?.preferences?.allowChangeUserEmail && {
			name: 'Edit User Email',
			representedObject: EManageUsersMoreMenuActions.EditUserEmail,
		},
		{
			name: 'Deactivate User',
			representedObject: EManageUsersMoreMenuActions.Deactivate,
		},
	];

	const userActivationStatus = user.activationStatus;
	const userId = `action-${user.id}`;
	const onMoreMenuItemClicked = (item: IManageUsersMoreMenuActions) => {
		switch (item.representedObject) {
			case EManageUsersMoreMenuActions.Deactivate:
				deactivateClick();
				break;
			case EManageUsersMoreMenuActions.EditCardSettings:
				editCardSettingsClick();
				break;
			case EManageUsersMoreMenuActions.EditMeetingLinks:
				editMeetingLinksClick();
				break;
			case EManageUsersMoreMenuActions.EditUserEmail:
				editUserEmailClick();
				break;
			case EManageUsersMoreMenuActions.EditSignature:
				editSignatureClick();
				break;
			default:
				break;
		}
	};
	// Based on the user's activation status, formulate the right action link
	switch (userActivationStatus) {
		case ActivationStatus.ACTIVE:
			return (
				<div className={css(styleSheet.moreMenuWrap)}>
					<DeprecatedMoreMenu menuItems={menuOptions} onMenuItemClicked={onMoreMenuItemClicked} />
				</div>
			);
		case ActivationStatus.DEACTIVATED:
			return (
				<button
					type='button'
					id={userId}
					onClick={reactivateClick}
					className={css(styleSheet.actionLink, styleSheet.actionLinkActivate)}
				>
					Reactivate User
				</button>
			);
		case ActivationStatus.INIT:
			return (
				<button
					type='button'
					id={userId}
					onClick={deleteClick}
					className={css(styleSheet.actionLink, styleSheet.actionLinkDeactivate)}
				>
					Delete User
				</button>
			);
		default:
			return null;
	}
};

const UserTypeOptions: ISelectBoxOption<string>[] = [
	{
		title: 'Admin',
		value: 'admin',
	},
	{
		title: 'Employee',
		value: 'employee',
	},
];

export const ManageUsersFormattedUser = ({
	deactivateClick,
	deleteClick,
	isInUpdatingList,
	onEditCardSettings,
	onEditEmailSignature,
	onEditMeetingLinks,
	onEditSignatureClick,
	onEditUserEmailClick,
	onUserRoleChanged,
	reactivateClick,
	user,
	userSession,
}: {
	deactivateClick: () => void;
	deleteClick: () => void;
	isInUpdatingList: boolean;
	onEditCardSettings: () => void;
	onEditEmailSignature: () => void;
	onEditMeetingLinks: () => void;
	onEditSignatureClick: () => void;
	onEditUserEmailClick: () => void;
	onUserRoleChanged: (role: ISelectBoxOption<string>) => void;
	reactivateClick: () => void;
	user: IUser;
	userSession: Api.UserSessionContext;
}): JSX.Element => {
	// Based on the user's activation status, formulate the right action link
	const activationStatus = user.activationStatus === ActivationStatus.INIT ? 'CREATED' : user.activationStatus;

	return (
		<div
			key={user.id}
			className={css(styleSheet.listItem, isInUpdatingList ? styleSheet.listItemLoading : null)}
			style={{ position: 'relative' }}
		>
			{isInUpdatingList ? (
				<div className={css(styleSheet.overlayContainer)}>
					<LoadingSpinner type='small' />
				</div>
			) : null}
			<>
				<div>
					<Avatar2
						styleDeclaration={styleSheet.avatar}
						fallbackText={getPrincipalInitials(user)}
						imgSrc={user?.profilePic}
					/>
				</div>
				<div className={css(styleSheet.employeeListItemName)}>
					<div>{getDisplayName(user)}</div>
					{!!user.primaryEmail && !!user.primaryEmail.value && (
						<div className={css(styleSheet.employeeListItemEmail)}>{user.primaryEmail.value}</div>
					)}
				</div>
				<div className={css(styleSheet.employeeListItemType)}>
					<SelectBox
						triggerStyles={[styleSheet.selectBoxTrigger]}
						disabled={isInUpdatingList}
						onSelectionChanged={(role: ISelectBoxOption<string>) => onUserRoleChanged(role)}
						options={UserTypeOptions}
						selectedOption={user.groups.findIndex(x => x === 'admin') >= 0 ? UserTypeOptions[0] : UserTypeOptions[1]}
					/>
				</div>
				<div className={css(styleSheet.employeeListItemStatus)}>{activationStatus}</div>
				<div className={css(styleSheet.employeeListItemSignature)}>
					{activationStatus !== ActivationStatus.DEACTIVATED ? (
						<div>
							{user?.userMilestones?.hasSignatureTemplate ? (
								<figure>
									<SuccessCheckIcon fillColor='#89C947' />
								</figure>
							) : (
								<>
									<button
										type='button'
										className={css(styleSheet.employeeListItemSignatureAddButton)}
										onClick={onEditEmailSignature}
										tabIndex={0}
									>
										<div tabIndex={-1}>Add</div>
									</button>
								</>
							)}
						</div>
					) : (
						<div>
							{' '}
							<div>&mdash;</div>{' '}
						</div>
					)}
				</div>
				<div className={css(styleSheet.employeeListItemActionItems)}>
					<ManageUsersActionLink
						deactivateClick={deactivateClick}
						deleteClick={deleteClick}
						editCardSettingsClick={onEditCardSettings}
						editMeetingLinksClick={onEditMeetingLinks}
						editSignatureClick={onEditSignatureClick}
						editUserEmailClick={onEditUserEmailClick}
						reactivateClick={reactivateClick}
						user={user}
						userSession={userSession}
					/>
				</div>
			</>
		</div>
	);
};

interface IManageUsersManagementModalProps extends IModalContext<IUser> {
	forUser: IUser;
	onRequestClose?: (result?: IUserPreferences, canceled?: boolean) => void;
}

const _SignatureSettings: React.FC<IManageUsersManagementModalProps> = ({ forUser }) => {
	return (
		<div className={css(styleSheet.manageUsersModalContainer, styleSheet.manageUsersModalContainerSignature)}>
			<EmailSignatureSettings className={css(styleSheet.removeBorder)} expanded={true} forUser={forUser} />
		</div>
	);
};

export const _MeetingLinksSettings = ({
	forUser,
	onEditMeetingClicked,
}: {
	forUser: Api.IUser;
	onEditMeetingClicked: (meeting: Api.MeetingConfigViewModel) => void;
}) => {
	const { isLoadingMeetingLinks, meetingConfigs, onAddMeeting, onDeleteMeeting, onEditMeeting } =
		useScheduleMeetingSettings({ onEditMeetingClicked, user: forUser });
	return (
		<div className={css(styleSheet.manageUsersModalContainer)}>
			<ScheduleMeeting
				isLoadingMeetingLinks={isLoadingMeetingLinks}
				meetingConfigs={meetingConfigs}
				onAddMeeting={onAddMeeting}
				onDeleteMeeting={onDeleteMeeting}
				onEditMeeting={onEditMeeting}
			/>
		</div>
	);
};

const _ManageUsersEditCardSettings = ({ forUser, onRequestClose }: IManageUsersManagementModalProps) => {
	return <HandwrittenCardSettings expanded={true} forUser={forUser} handleCloseSettings={onRequestClose} />;
};

const _ManageUsersEditEmail = ({
	onRequestClose,
}: {
	onRequestClose?: (newEmail?: string, reason?: string, canceled?: boolean) => void;
}) => {
	const [newEmail, setNewEmail] = React.useState('');
	const [reason, setReason] = React.useState('');

	const onSave = () => {
		onRequestClose(newEmail, reason, false);
	};

	return (
		<div className={css(styleSheet.container)}>
			<label htmlFor='edit-user-email' className={css(styleSheet.label)}>
				New Email:&nbsp;
				<span className={css(baseStyleSheet.required)}>*</span>
			</label>
			<TextInput
				className={css(styleSheet.inputs)}
				inputId='edit-user-email'
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewEmail(e.target.value)}
				type='text'
				value={newEmail}
			/>
			{!!newEmail && !isValidEmail(newEmail) ? (
				<span className={css(styleSheet.errorMessage)}>Please enter a valid email.</span>
			) : null}

			<label htmlFor='edit-user-email-reason' className={css(styleSheet.label)}>
				Reason for Change:&nbsp;
				<span className={css(baseStyleSheet.required)}>*</span>
			</label>
			<TextArea
				autoComplete='off'
				className={css(styleSheet.inputs)}
				inputId='edit-user-email-reason'
				onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
				value={reason}
			/>
			{!!newEmail && !reason ? (
				<span className={css(styleSheet.errorMessage)}>Please enter a reason for the change.</span>
			) : null}

			<div className={css(styleSheet.modalButtons)}>
				<button
					className={css(baseStyleSheet.ctaButton)}
					onClick={onSave}
					disabled={!isValidEmail(newEmail) || !reason}
				>
					<span>Change</span>
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={() => onRequestClose(null, null, true)}>
					<span>Cancel</span>
				</button>
			</div>
		</div>
	);
};

export const ManageUsersEditCardSettings = asModalComponent(_ManageUsersEditCardSettings);

export const ManageUsersEditSignature = asModalComponent(_SignatureSettings);

export const ManageUsersEditMeetingLinks = observer(_MeetingLinksSettings);

export const ManageUsersEditEmail = asModalComponent(_ManageUsersEditEmail);
