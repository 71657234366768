import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	ActivationStatus,
	ConfigurableIntegrationUserViewModel,
	ConfigurableIntegrationViewModel,
	IUser,
	ResourceAutoCompleteViewModel,
	ResourceAutoCompleteViewModelType,
	UserViewModel,
	VmUtils,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import {
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../../../web/components/autocomplete/SimpleAutoCompleteSearchField';
import { SuccessCheckIcon } from '../../../../web/components/svgs/icons/SuccessCheckIcon';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps {
	className?: string;
	integration: ConfigurableIntegrationViewModel;
	onAssignUser?(user: ConfigurableIntegrationUserViewModel, levitateUser?: UserViewModel): void;
	showAddEmployeedModal?(): void;
	styles?: StyleDeclarationValue[];
	user: ConfigurableIntegrationUserViewModel;
}

interface IState {
	isEditingLevitateUser?: boolean;
	selectedUser?: UserViewModel;
}

class _ClientIntegrationsManageUsersTableRow extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	public async componentDidMount() {
		const { user } = this.props;
		if (!!user.levitateUser && !user.levitateUser.isLoaded) {
			try {
				await user.levitateUser.load();
			} catch {
				// don't handle
			}
		}
	}

	public render() {
		const { className, styles, user } = this.props;
		return (
			<tr
				className={`${css(styleSheet.container, ...(styles || []))} integrations-manage-users-table-row ${
					className || ''
				}`}
			>
				<td className={css(styleSheet.cell, styleSheet.initialsColumnCell)}>{user.id}</td>
				<td className={css(styleSheet.cell, styleSheet.emailColumnCell)}>{this.renderEmailCell()}</td>
				<td className={css(styleSheet.cell, styleSheet.statusIndicatorColumnCell)}>
					{(user.status === ActivationStatus.ACTIVE || user.status === ActivationStatus.INIT) && (
						<SuccessCheckIcon
							className={css(user.status !== ActivationStatus.ACTIVE ? styleSheet.statusIndicatorInactive : null)}
						/>
					)}
				</td>
				<td className={css(styleSheet.cell, styleSheet.statusColumnCell)}>
					<div
						className={css(
							styleSheet.status,
							user.status === ActivationStatus.ACTIVE ? styleSheet.statusConnect : null
						)}
					>
						{user.statusDescription}
					</div>
				</td>
			</tr>
		);
	}

	private renderEmailCell() {
		const { user } = this.props;
		const { isEditingLevitateUser } = this.state;
		const disabled = !!user.levitateUser && !!user.levitateUser.isLoading;
		return (
			<div className={css(styleSheet.emailColumnInputs)}>
				{!!user.levitateUser && !isEditingLevitateUser ? (
					<div className={css(styleSheet.emailInput, baseStyleSheet.truncateText)}>{user.levitateUser.name}</div>
				) : (
					<>
						<SimpleAutoCompleteSearchField
							disabled={disabled}
							dropdownContentItemsStyles={[styleSheet.autoCompleteDropdownItems]}
							dropdownContentStyle={styleSheet.autoCompleteDropdownContent}
							onCreateAutoCompleteViewModel={this.onCreateAutoCompleteViewModel}
							// @ts-ignore
							onItemSelected={this.onUserSelected}
							resultsLimit={3}
							pageSize={3}
							placeholder='Search name'
							style={styleSheet.emailInput}
							type={ResourceAutoCompleteViewModelType.User}
						/>
						<button className={css(baseStyleSheet.ctaButtonSmall)} disabled={disabled} onClick={this.onAssign}>
							<span>Assign</span>
						</button>
						{!!isEditingLevitateUser && (
							<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={this.onCancelEditButtonClicked}>
								<span>Cancel</span>
							</button>
						)}
					</>
				)}
			</div>
		);
	}

	private onCancelEditButtonClicked = () => {
		this.setState({
			isEditingLevitateUser: false,
		});
	};

	private onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<IUser>) => {
		const { userSession } = this.props;
		if (e.target) {
			e.target.setSearchQuery(VmUtils.getDisplayName(e.selection));
		}
		this.setState({
			selectedUser: new UserViewModel(userSession, e.selection),
		});
	};

	private onAssign = () => {
		const { user, onAssignUser } = this.props;
		const { selectedUser, isEditingLevitateUser } = this.state;

		onAssignUser?.(user, selectedUser);

		if (isEditingLevitateUser) {
			this.setState({ isEditingLevitateUser: false });
		}
	};

	private onCreateAutoCompleteViewModel = (
		_: ResourceAutoCompleteViewModelType,
		suggestedViewModel: ResourceAutoCompleteViewModel
	) => {
		suggestedViewModel.addParam({ includeDeactivated: false });
		return suggestedViewModel;
	};
}

const ClientIntegrationsManageUsersTableRowAsObserver = observer(_ClientIntegrationsManageUsersTableRow);
const ClientIntegrationsManageUsersTableRowWithContext = inject(UserSessionViewModelKey)(
	ClientIntegrationsManageUsersTableRowAsObserver
);
export const ClientIntegrationsManageUsersTableRow = withEventLogging(
	ClientIntegrationsManageUsersTableRowWithContext,
	'ClientIntegrationsManageUsersTableRow'
);
