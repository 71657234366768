import { css, StyleSheet } from 'aphrodite';
import { FC, useMemo, useRef, useState } from 'react';
import { LabeledInput } from '../../../admin/components/LabeledInput/LabeledInput';
import { Column, Row } from '../../../admin/components/Layout';
import { aceGray, brandPrimary, brandPrimaryActive, brandPrimaryHover, charmGray } from '../../styles/colors';
import { bs } from '../../styles/styles';
import { Button } from '../Button';
import { Pill } from '../Pill/Pill';
import { Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from '../Popover';
import { PlusIcon } from '../svgs/icons/PlusIcon';

export interface PillMultiSelectProps {
	accessory: React.ReactNode;
	addButtonLabel: string;
	disabled?: boolean;
	onEditOptions?: (options: string) => void;
	onOptionClick?: (option: string) => void;
	onRemove?: (option: string) => void;
	options: string[];
	selectedOptions?: string[];
	variant?: 'tag' | 'label' | 'keyword';
}

export const PillMultiSelect: FC<PillMultiSelectProps> = ({
	accessory,
	addButtonLabel = 'Add',
	disabled,
	onEditOptions,
	onOptionClick,
	onRemove,
	options,
	selectedOptions,
	variant,
}) => {
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const inputRef = useRef<HTMLInputElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = (event?: any) => {
		if (!inputRef.current?.contains(event?.target) && !buttonRef.current?.contains(event?.target)) {
			setInputValue('');
			setOpen(false);
		}
	};

	const handleAdd = (value: string) => {
		onOptionClick?.(value);
	};
	const handleRemove = (value: string) => {
		onRemove?.(value);
	};
	const handleEdit = () => {
		onEditOptions?.(inputValue);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const filteredOptions = useMemo(() => {
		if (options) {
			const filtered = options.filter(
				(option: string) => !selectedOptions.find(selectedOption => selectedOption === option)
			);
			if (!inputValue) {
				return filtered;
			}
			return filtered.filter((keyword: string) => {
				return !inputValue || keyword.toLowerCase().includes(inputValue.toLowerCase());
			});
		}
		return [];
	}, [selectedOptions, inputValue, options]);

	return (
		<Row className={css(styleSheet.container)}>
			<span className={css(bs.pr2)}>{accessory}</span>
			<Column className={css(bs.gap2)}>
				{selectedOptions.length > 0 && (
					<Row className={css(bs.gap2, bs.flexWrap)}>
						{selectedOptions?.map((selectedOption, i) => (
							<Pill variant={variant} label={selectedOption} key={`${i}-${selectedOption}`} onRemove={handleRemove} />
						))}
					</Row>
				)}
				<Popover open={open}>
					{!open ? (
						<PopoverTrigger className={css(styleSheet.linkButton)} onClick={handleOpen} disabled={disabled}>
							<PlusIcon />
							<span className={css(styleSheet.buttonLabel)}>{addButtonLabel}</span>
						</PopoverTrigger>
					) : (
						<PopoverAnchor className={css(styleSheet.inputRow)}>
							<LabeledInput
								variant='small'
								value={inputValue}
								onChange={handleInputChange}
								placeholder='Filter or add new'
								ref={inputRef}
							/>
							{onEditOptions && (
								<Button disabled={disabled} size='small' onClick={handleEdit} ref={buttonRef}>
									Save
								</Button>
							)}
						</PopoverAnchor>
					)}
					<PopoverContent
						className={css(styleSheet.popoverContent)}
						onEscapeKeyDown={handleClose}
						onPointerDownOutside={handleClose}
					>
						<Row className={css(bs.flexWrap, bs.gap2)}>
							{filteredOptions.length > 0 &&
								filteredOptions.map((option, i) => (
									<Pill variant={variant} label={option} key={`${i}-${option}`} onClick={handleAdd} />
								))}
						</Row>
					</PopoverContent>
				</Popover>
			</Column>
		</Row>
	);
};

const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'flex-start',
		display: 'flex',
		gap: 4,
	},
	iconButton: {
		padding: '4px 8px',
		backgroundColor: brandPrimary,
		maxHeight: 'fit-content',
		borderRadius: 4,
		':hover': {
			backgroundColor: brandPrimaryHover,
		},
		':active': {
			backgroundColor: brandPrimaryActive,
		},
	},
	linkButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: 'fit-content',
		padding: '4px 0px',
		gap: 4,
	},
	buttonLabel: {
		color: brandPrimary,
	},
	popoverContent: {
		maxWidth: 450,
		maxHeight: '50vh',
		overflowY: 'scroll',
		borderRadius: 8,
		'::-webkit-scrollbar': {
			background: 'transparent',
			width: 9,
		},
		'::-webkit-scrollbar-thumb': {
			borderRadius: 8,
			backgroundColor: aceGray,
			boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
		},
	},
	inputRow: {
		display: 'flex',
		flexWrap: 'nowrap',
		flexDirection: 'row',
		gap: 4,
	},
	infoText: {
		color: charmGray,
		fontSize: 14,
	},
});
