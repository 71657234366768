import { css } from 'aphrodite';
import * as React from 'react';
import { DefaultTextingBulkSendExcludedTags, TextCampaignReportView, VmUtils } from '../../../extViewmodels';
import { AdvancedTagFilterCollection } from '../../../models';
import { getCampaignCalendarDateRangeStringValue } from '../../../models/UiUtils';
import { TextingIcon } from '../../components/svgs/icons/TextingIcon';
import { bs } from '../../styles/styles';
import { styleSheet } from './styles';

export function TextCampaignPendingListItem({ report, index }: { report: TextCampaignReportView; index?: number }) {
	let tags: string[] | undefined;
	let sendingToText = '';
	if (!!report && !!report.contactFilter && !!report.contactFilter.criteria) {
		const sorted = VmUtils.sortContactFilterCriteria(report.contactFilter.criteria);
		if (sorted.searches.length > 0 && sorted.searches.every(x => VmUtils.isTagSearchContactFilterCriteria(x))) {
			const filters = AdvancedTagFilterCollection.instanceWithCriteria(sorted.searches);
			filters.sort();
			tags = filters.filters.map(x => x.tag);
		}
	}
	tags = tags?.filter(x => !DefaultTextingBulkSendExcludedTags.has(x));
	if (tags && tags.length > 0) {
		sendingToText = `Sending to contacts with ${tags.length > 1 ? `${tags.length} tags` : `"${tags[0]}" tag`}`;
	}
	sendingToText = `Sending to ${report.totalContacts} contact${report.totalContacts > 1 ? 's' : ''}`;
	return (
		<div
			className={css(
				bs.bgAliceBlue,
				bs.borderSolid,
				bs.border,
				bs.borderBrilliantAzure,
				bs.textBrandPrimary,
				bs.rounded,
				bs.textXs,
				bs.p2,
				bs.textLeft,
				index ? styleSheet.plannedCampaignListItem : undefined
			)}
			style={{ '--stagger-anim-order': index } as React.CSSProperties}
		>
			<div className={css(bs.flex, bs.itemsCenter)}>
				<div
					className={css(
						bs.itemsCenter,
						bs.roundedFull,
						bs.flex,
						bs.bgBrilliantAzure,
						bs.flexShrink0,
						bs.w10,
						bs.h10,
						bs.justifyCenter,
						bs.mr3,
						bs.bgBrilliantAzure
					)}
				>
					<TextingIcon fill='white' />
				</div>
				<div className={css(bs.flex1)}>
					<div>{getCampaignCalendarDateRangeStringValue(report.dueDate, report.dueDate)}</div>
					<div className={css(bs.fontBold)}>{report.campaignName}</div>
				</div>
			</div>
			<div>{sendingToText}</div>
		</div>
	);
}
