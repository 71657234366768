import * as Api from '@ViewModels';
import * as Tabs from '@radix-ui/react-tabs';
import { css } from 'aphrodite';
import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateIntegrationQuery,
	useCallbackAuthorizeUrlMutation,
	useGetExternalIntegrationQuery,
} from '../../../../queries';
import { useDisconnectUserFromIntegrationMutation } from '../../../../queries/Integrations/useDisconnectUserFromIntegrationMutation';
import { useGetExternalIntegrationOAuthClientDisplay } from '../../../../queries/Integrations/useGetExternalIntegrationOAuthClientDisplay';
import { usePostExternalIntegrationOAuthClientMutation } from '../../../../queries/Integrations/usePostExternalIntegrationOAuthClient';
import { AdminUserSessionContext } from '../../../../viewmodels/AdminViewModels';
import practicePantherLogo from '../../../assets/practice-panther-logo.svg';
import { Button } from '../../../components/Button';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { TextInput } from '../../../components/TextInput';
import { DisconnectSyncUserModal } from '../../../components/integrations/DisconnectSyncUserModal';
import { DangerIcon } from '../../../components/svgs/icons/DangerIcon';
import { InProgressIcon } from '../../../components/svgs/icons/InProgressIcon';
import { SuccessCheckIcon } from '../../../components/svgs/icons/SuccessCheckIcon';
import { nameCircles } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { styleSheet as integrationsStyleSheet } from '../styles';
import { styleSheet } from './styles';

enum TabValues {
	Configure = 'configure',
	Connect = 'connect',
	Manage = 'manage',
}

enum IntegrationState {
	NotConfigured = 'not-configured',
	NotConnected = 'not-connected',
	Connected = 'connected',
}

const secretPlaceholder = '***************';

export function PracticePanther() {
	const [clientId, setClientId] = React.useState('');
	const [clientSecret, setClientSecret] = React.useState('');
	const [isDisableModalOpen, setIsDisableModalOpen] = React.useState(false);
	const toaster = useToaster();
	const history = useHistory();
	const userSession = useUserSession();
	const adminUserSession = userSession as AdminUserSessionContext;
	const params = useParams<{ tab: TabValues }>();
	const [isEditing, setIsEditing] = React.useState(false);

	const oauthClientMutation = usePostExternalIntegrationOAuthClientMutation({
		onError: error => {
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			invalidateIntegrationQuery({ provider: Api.IntegrationProvider.PracticePanther });
		},
	});
	const disableIntegrationMutation = useDisconnectUserFromIntegrationMutation({
		onError: error => {
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			toaster.push({
				message: 'Integration disabled successfully',
				type: 'successMessage',
			});
			invalidateIntegrationQuery({ provider: Api.IntegrationProvider.PracticePanther });
			history.push('/integrations');
		},
	});
	const callbackAuthorizeUrlMutation = useCallbackAuthorizeUrlMutation({
		onError: error => {
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess: url => {
			window.location.replace(url);
		},
	});

	const integrationQuery = useGetExternalIntegrationQuery({
		provider: Api.IntegrationProvider.PracticePanther,
	});

	const externalIntegrationOAuthClientDisplayQuery = useGetExternalIntegrationOAuthClientDisplay({
		integrationProvider: Api.IntegrationProvider.PracticePanther,
	});

	const changeTab = React.useCallback(
		(value: string) => {
			history.replace({
				pathname: `/integrations/${Api.IntegrationProvider.PracticePanther}/${value}`,
			});
		},
		[history]
	);

	const integrationState = React.useMemo(() => {
		if (!integrationQuery.data?.configuration?.encryptedClientKeyId) {
			return IntegrationState.NotConfigured;
		} else {
			const userConnections = integrationQuery.data?.configuration?.userConnections || [];
			const userConnection = userConnections.find(
				connection => connection.userId === userSession.user.id && connection.isConnected
			);

			return userConnection?.isConnected ? IntegrationState.Connected : IntegrationState.NotConnected;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [integrationQuery.data, userSession.user.id, externalIntegrationOAuthClientDisplayQuery.data]);

	React.useEffect(() => {
		if (integrationQuery.data?.configuration?.encryptedClientKeyId) {
			setClientId(externalIntegrationOAuthClientDisplayQuery?.data?.clientId);
			setClientSecret(secretPlaceholder);
		}
	}, [
		integrationQuery.data?.configuration?.encryptedClientKeyId,
		externalIntegrationOAuthClientDisplayQuery?.data?.clientId,
	]);

	React.useEffect(() => {
		if (!integrationQuery.data) {
			return;
		}

		if (integrationState === IntegrationState.NotConfigured) {
			if (adminUserSession.isAdmin) {
				changeTab(TabValues.Configure);
			} else {
				changeTab(TabValues.Connect);
			}
		} else if (integrationState === IntegrationState.Connected) {
			changeTab(TabValues.Manage);
		} else {
			changeTab(TabValues.Connect);
		}
	}, [integrationState, adminUserSession.isAdmin, changeTab, integrationQuery.data]);

	const configureCredentials = async () => {
		if (clientId !== externalIntegrationOAuthClientDisplayQuery?.data?.clientId || clientSecret !== secretPlaceholder) {
			await oauthClientMutation.mutateAsync({
				integrationProvider: Api.IntegrationProvider.PracticePanther,
				clientId,
				clientSecret,
			});
		}

		if (clientSecret) {
			setClientSecret(secretPlaceholder);
		}

		setIsEditing(false);
	};

	const onEdit = () => {
		setIsEditing(true);
	};

	const onCancelEdit = () => {
		setIsEditing(false);
	};

	const connect = async () => {
		callbackAuthorizeUrlMutation.mutate({
			provider: Api.IntegrationProvider.PracticePanther,
		});
	};

	const onDisable = () => {
		disableIntegrationMutation.mutate(Api.ExternalIntegrationType.PracticePanther);
	};

	if (integrationQuery.isLoading || oauthClientMutation.isLoading) {
		return <LoadingSpinner type='large' className={css(bs.absoluteCenter)} />;
	}

	const hasSigninError = oauthClientMutation.error;

	const isNotConfigured = integrationState === IntegrationState.NotConfigured;
	const isConnected = integrationState === IntegrationState.Connected;

	return (
		<div className={css(styleSheet.container)}>
			<figure className={css(integrationsStyleSheet.logo, integrationsStyleSheet.headerLogo)}>
				<img src={practicePantherLogo} alt='PracticePanther Logo' />
			</figure>
			<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
				Levitate can sync with PracticePanther, and pull client information, including names, email, phone number,
				birthdays, anniversaries, and more. We can also push our notes into PracticePanther.
			</p>

			<Tabs.Root className={`tab-view ${css(styleSheet.tabView)}`} value={params.tab} onValueChange={changeTab}>
				<Tabs.List className={`tab-view-tabbar-tabs ${css(styleSheet.tabViewTabs)}`}>
					{adminUserSession.isAdmin ? (
						<Tabs.Trigger
							value={TabValues.Configure}
							className={`tab-view-tabbar-item ${
								params.tab === TabValues.Configure ? `tabbar-item-selected ` + css(styleSheet.tabItemSelected) : ''
							} ${css(styleSheet.tabViewItem)}`}
						>
							<SuccessCheckIcon
								fillColor={isNotConfigured ? nameCircles : undefined}
								ringColor={isNotConfigured ? '#F9F9F9' : undefined}
							/>
							<span>Configure</span>
						</Tabs.Trigger>
					) : null}

					<Tabs.Trigger
						value={TabValues.Connect}
						className={`tab-view-tabbar-item ${
							params.tab === TabValues.Connect ? `tabbar-item-selected ` + css(styleSheet.tabItemSelected) : ''
						} ${css(styleSheet.tabViewItem)}`}
					>
						<SuccessCheckIcon fillColor={isConnected ? undefined : nameCircles} ringColor='#F9F9F9' />
						<span>Connect</span>
					</Tabs.Trigger>

					<Tabs.Trigger
						value={TabValues.Manage}
						className={`tab-view-tabbar-item ${
							params.tab === TabValues.Manage ? `tabbar-item-selected ` + css(styleSheet.tabItemSelected) : ''
						} ${css(styleSheet.tabViewItem)}`}
					>
						<span>Manage</span>
					</Tabs.Trigger>
				</Tabs.List>

				<Tabs.Content value={TabValues.Configure} className='tab-view-content'>
					<div className={css(styleSheet.section)}>
						{isNotConfigured ? (
							<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
								To get started, please sign into your PracticePanther account.
							</p>
						) : (
							<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
								Your PracticePanther account is configured to sync with Levitate. You can connect your user to the
								account.
							</p>
						)}

						{isNotConfigured || isEditing ? (
							<div>
								<div className={css(styleSheet.signInContainer)}>
									<div className={css(styleSheet.signInForm)}>
										<div className={css(styleSheet.inputWrapper)}>
											<span className={css(styleSheet.signInFormFieldLabel)}>Client ID</span>
											<div className={css(styleSheet.signInFormField)}>
												<TextInput
													autoComplete='off'
													autoFocus={true}
													id='login-client-id'
													onChange={ev => setClientId(ev.target.value)}
													type='text'
													value={clientId}
													className={css(styleSheet.textInput)}
												/>
												{hasSigninError && <DangerIcon backgroundColor='#fff' />}
											</div>
										</div>

										<div className={css(styleSheet.inputWrapper)}>
											<span className={css(styleSheet.signInFormFieldLabel)}>Client Secret</span>
											<div className={css(styleSheet.signInFormField)}>
												<TextInput
													id='login-client-secret'
													onChange={ev => setClientSecret(ev.target.value)}
													type='password'
													value={clientSecret}
													className={css(styleSheet.textInput)}
												/>
												{hasSigninError && <DangerIcon backgroundColor='#fff' />}
											</div>
										</div>
									</div>
									<div className={css(bs.horizontalStack)}>
										<Button onClick={configureCredentials} kind='primary'>
											Save
										</Button>

										{isEditing ? (
											<Button onClick={onCancelEdit} kind='primary'>
												Cancel
											</Button>
										) : null}
									</div>
								</div>
							</div>
						) : (
							<div>
								<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
									<b>Client ID:</b> {clientId}
								</p>
								<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
									<b>Client Secret:</b> {clientSecret}
								</p>

								<Button onClick={onEdit} kind='primary'>
									Edit
								</Button>
							</div>
						)}
					</div>
				</Tabs.Content>

				<Tabs.Content value={TabValues.Connect} className='tab-view-content'>
					{isConnected ? (
						<div className={css(styleSheet.section)}>
							<p className={css(integrationsStyleSheet.bodyText)}>
								If you wish to disable this integration for you user, click Disconnect.
							</p>
							<Button
								kind='primary'
								onClick={ev => {
									ev.preventDefault();
									if (integrationQuery.data?.configuration?.syncUserId === userSession.user.id) {
										setIsDisableModalOpen(true);
									} else {
										onDisable();
									}
								}}
							>
								Disconnect
							</Button>
						</div>
					) : isNotConfigured ? (
						adminUserSession.isAdmin ? (
							<div className={css(styleSheet.section)}>
								<p className={css(integrationsStyleSheet.bodyText)}>
									To connect your user to the account, provide the missing fields in Configure.
								</p>
							</div>
						) : (
							<div className={css(styleSheet.section)}>
								<p className={css(integrationsStyleSheet.bodyText)}>
									To connect your user to your PracticePanther account, please contact your administrator to configure
									your account.
								</p>
							</div>
						)
					) : (
						<div className={css(styleSheet.section)}>
							<div className={css(styleSheet.inProgressIconContainer)}>
								<InProgressIcon />
							</div>
							<p className={css(integrationsStyleSheet.bodyText)}>
								To connect your user to the account, click Connect.
							</p>
							<div>
								<Button kind='primary' onClick={() => connect()}>
									Connect
								</Button>
							</div>
						</div>
					)}
				</Tabs.Content>

				<Tabs.Content value={TabValues.Manage} className='tab-view-content'>
					<div className={css(styleSheet.section)}>
						<p className={css(integrationsStyleSheet.bodyText)}>
							Once you are connected, your PracticePanther data will show up in the contacts tab.
						</p>
						<Link to='/people' className={css(bs.ctaButton)}>
							Contacts
						</Link>
					</div>
				</Tabs.Content>
			</Tabs.Root>

			<DisconnectSyncUserModal
				integrationDisplayName='PracticePanther'
				isDisableModalOpen={isDisableModalOpen}
				onDisable={onDisable}
				setIsDisableModalOpen={setIsDisableModalOpen}
			/>
		</div>
	);
}
