import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IInterval, daysOfWeekStrings } from '../../../../../extViewmodels';
import { getTimeOptions } from '../../../../../models/UiUtils';
import { Checkbox } from '../../../Checkbox';
import { DeprecatedSelect, ISelectOption } from '../../../DeprecatedSelect';
import { RoundAddIcon } from '../../../svgs/icons/RoundAddIcon';
import { RoundRemoveIcon } from '../../../svgs/icons/RoundRemoveIcon';
import { styleSheet } from './styles';

interface IProps {
	dayIndex: number;
	increment: number;
	intervals?: IInterval[];
	onAddBlock(): void;
	onChange(blockIndex: number, property: 'startMinutes' | 'endMinutes', value: number): void;
	onRemoveBlock(): void;
	onToggleAvailable(): void;
}

export const MeetingConfigAvailabilityDay: React.FC<IProps> = observer(props => {
	const { dayIndex, increment, intervals, onAddBlock, onChange, onRemoveBlock, onToggleAvailable } = props;

	const onTimeChange =
		(blockIndex: number, property: 'startMinutes' | 'endMinutes') => (option: ISelectOption<number>) =>
			onChange(blockIndex, property, option.dataContext);

	const renderDropdowns = React.useCallback(
		(interval: IInterval, index: number) => {
			const selectedStartTime = interval?.startMinutes || 5 * 60;
			let selectedEndTime = interval?.endMinutes || 17 * 60;

			if (selectedEndTime <= selectedStartTime) {
				selectedEndTime = selectedStartTime + increment;
			}

			const isLastBlock = index + 1 === intervals?.length;

			const previousInterval = index > 0 ? intervals[index - 1] : null;

			const nextInterval = intervals?.length > index + 1 ? intervals[index + 1] : null;

			const minStartTime = previousInterval?.endMinutes ? previousInterval.endMinutes : 1;
			const maxStartTime = nextInterval?.startMinutes ? selectedEndTime : 1439 - increment;
			const startTimeOptions = getTimeOptions('start-time', increment, minStartTime, maxStartTime);

			const minEndTime = selectedStartTime;
			const maxEndTime = nextInterval?.startMinutes ? nextInterval.startMinutes : 1439;
			const endTimeOptions = getTimeOptions('end-time', increment, minEndTime, maxEndTime);

			const showAddBlock = isLastBlock && selectedEndTime < 1440 - increment * 2;
			const showRemoveBlock = isLastBlock && index > 0;

			return (
				<div
					className={css(
						styleSheet.availabilityTimeBlockContainer,
						!intervals && styleSheet.availabilityDisabled,
						index > 0 && styleSheet.availabilitySubblock
					)}
				>
					<DeprecatedSelect
						disabled={!intervals}
						onOptionClick={onTimeChange(index, 'startMinutes')}
						options={startTimeOptions}
						selectedOption={startTimeOptions.find(o => o.dataContext === selectedStartTime)}
						styles={[styleSheet.timeDropdown]}
					/>
					<span>to</span>
					<DeprecatedSelect
						disabled={!intervals}
						onOptionClick={onTimeChange(index, 'endMinutes')}
						options={endTimeOptions}
						selectedOption={endTimeOptions.find(o => o.dataContext === selectedEndTime)}
						styles={[styleSheet.timeDropdown]}
					/>
					{showAddBlock && (
						<button className={css(styleSheet.button)} onClick={onAddBlock}>
							<RoundAddIcon />
						</button>
					)}
					{showRemoveBlock && (
						<button className={css(styleSheet.button)} onClick={onRemoveBlock}>
							<RoundRemoveIcon />
						</button>
					)}
				</div>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[intervals, increment, onAddBlock, onRemoveBlock, onChange]
	);

	function renderCheckbox() {
		return (
			<div className={css(styleSheet.checkboxContainer)}>
				<Checkbox checked={!!intervals} id={daysOfWeekStrings[dayIndex].full} onChange={onToggleAvailable}>
					<span className={css(styleSheet.checkboxText)}>{daysOfWeekStrings[dayIndex].abbr}</span>
				</Checkbox>
			</div>
		);
	}

	if (!intervals && !(dayIndex === 0 || dayIndex === 6)) {
		return (
			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.row)}>
					{renderCheckbox()}

					{renderDropdowns(null, 0)}
				</div>
			</div>
		);
	}

	return (
		<div className={css(styleSheet.container)}>
			{intervals?.map((interval, index) => {
				return (
					<div key={`day-${dayIndex}-block-${index}`} className={css(styleSheet.row)}>
						{index === 0 && renderCheckbox()}
						{renderDropdowns(interval, index)}
					</div>
				);
			})}
		</div>
	);
});
