import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { NotFound } from '@WebComponents/NotFound';
import { SplitView } from '@WebComponents/SplitView';
import { TabView } from '@WebComponents/TabView';
import { BoardItemActivityList } from '@WebComponents/boards/BoardItemActivityList';
import { CompanyContactsList } from '@WebComponents/companies/CompanyContactsList';
import { EditOpportunityModal } from '@WebComponents/dataBoards/opportunities/EditOpportunity';
import { OpportunityProfile } from '@WebComponents/dataBoards/opportunities/OpportunityProfile';
import { EntityRichContent } from '@WebComponents/entities/EntityRichContent';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ILocationState } from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useGetBoard, useGetOpportunity, useInfiniteBoardsQuery } from '../../../../queries';
import { CompanyViewModel, ContactViewModel, IOpportunity } from '../../../../viewmodels/AppViewModels';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from '../../../components/Breadcrumb';

import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { styleSheet } from './styles';

const _Opportunity = ({
	className,
	routeContainerClassName,
	styles,
}: {
	className?: string;
	routeContainerClassName?: string;
	styles?: StyleDeclarationValue[];
}) => {
	const userSession = useUserSession();
	const [showingEditModal, setShowingEditModal] = React.useState(false);
	const match = useRouteMatch<{
		id: string;
		boardId: string;
	}>();
	const loc = useLocation();
	const { id: opportunityId, boardId } = match.params;

	const boardsQuery = useInfiniteBoardsQuery({
		type: 'Opportunity',
	});
	const opportunityQuery = useGetOpportunity({
		opportunityId,
	});
	const opportunity = opportunityQuery.data;

	const getBoardQuery = useGetBoard({
		boardId,
	});

	const onEditClicked = () => {
		setShowingEditModal(true);
	};

	const companyVm = React.useMemo(() => {
		if (!opportunity?.company) return null;
		return new CompanyViewModel(userSession, opportunity.company);
	}, [opportunity, userSession]);

	const primaryContactVm = React.useMemo(() => {
		if (!opportunity?.primaryContact) return null;
		return new ContactViewModel(userSession, opportunity.primaryContact);
	}, [opportunity, userSession]);

	const showNotFound = !opportunity && !opportunityQuery.isLoading;

	if (showNotFound) return <NotFound />;
	if (!opportunity || opportunityQuery.isLoading) {
		return <LoadingSpinner className={css(styleSheet.loading)} type='large' />;
	}

	const tabViewOptions = () => {
		const entity = companyVm || primaryContactVm;
		const options = [
			{
				content: <BoardItemActivityList opportunity={opportunity} />,
				tabbarItem: {
					content: 'Activity',
				},
			},
			{
				content: <EntityRichContent entity={entity} />,
				tabbarItem: {
					content: 'Notes',
				},
			},
		];

		if (opportunity.company) {
			options.push({
				content: <CompanyContactsList company={companyVm} />,
				tabbarItem: {
					content: 'Contacts',
				},
			});
		}
		return options;
	};

	const onOpportunitySaved = (_opportunity: IOpportunity) => {
		setShowingEditModal(false);
	};

	const boards = boardsQuery.data?.pages.map(page => page.values).flat() ?? [];
	return (
		<div className={`${css(...(styles || [])) || ''} ${routeContainerClassName || ''} ${className || ''}`}>
			{/** Adds opportunity to location state. Needed so Floating action button can pick up the primary contact from opportunity. */}
			{opportunity && !loc.state ? (
				<Redirect
					to={{
						...loc,
						state: {
							model: opportunity,
						} as ILocationState<undefined, IOpportunity>,
					}}
					push={false}
				/>
			) : null}
			<MultiContainerHeader
				appBarHeader={
					<Breadcrumb>
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbLink asChild>
									<NavLink to='/dataBoards'>Data Boards</NavLink>
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator />
							<BreadcrumbItem>
								<BreadcrumbLink asChild>
									<NavLink to={`/dataBoards/opportunities/${boardId}`}>
										{getBoardQuery.data?.name ?? 'Opportunities Board'}
									</NavLink>
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator />
							<BreadcrumbItem>
								<BreadcrumbPage>{opportunityQuery.data?.name}</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				}
				fullscreenHeader='Opportunity'
			/>
			<SplitView
				className={css(styleSheet.splitView)}
				detail={
					<div>
						<TabView>{tabViewOptions}</TabView>
					</div>
				}
				master={
					<div className={css(styleSheet.profile)}>
						{!opportunityQuery.isLoading ? (
							<>
								{!opportunity?.isArchived ? (
									<div className={css(styleSheet.profileHeader)}>
										<button className={css(styleSheet.editButton)} onClick={onEditClicked}>
											Edit
										</button>
									</div>
								) : null}
								<OpportunityProfile opportunity={opportunity} />
							</>
						) : null}
					</div>
				}
			/>
			{!!opportunity && !!showingEditModal ? (
				<EditOpportunityModal
					opportunity={opportunity}
					onSave={onOpportunitySaved}
					onCancel={() => setShowingEditModal(false)}
					boards={boards}
				/>
			) : null}
		</div>
	);
};

export const Opportunity = observer(_Opportunity);
