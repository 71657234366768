import { borderColor, brandPrimaryHover, header, success, titles } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	name: {
		color: titles,
		fontSize: 24,
		letterSpacing: 0,
		lineHeight: '30px',
	},
	stageIndicator: {
		margin: '24px 0 14px 0',
	},
	stageIndicatorTitle: {
		marginBottom: 4,
	},
	sectionTitle: {
		color: header,
		fontSize: 14,
		lineHeight: '22px',
		marginBottom: 4,
	},
	sectionSmallTitle: {
		fontSize: 12,
	},
	amount: {
		color: success,
		fontSize: 18,
		letterSpacing: 0,
		lineHeight: '22px',
	},
	dueDate: {
		color: brandPrimaryHover,
		fontSize: 14,
		lineHeight: '20px',
	},
	details: {
		// details.rich-content-document-editor,
		color: titles,
		fontSize: 14,
		lineHeight: '20px',
	},
	divider: {
		background: borderColor,
		height: 1,
		margin: '14px 0',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 14,
	},
});
