import { useExportOpportunitiesMutation, useOpportunityContactsMutation } from '@Queries/index';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { numberToCurrencyStringValue } from '../../../../../models/UiUtils';
import { IBoard, VmUtils } from '../../../../../viewmodels/AppViewModels';
import { useOpportunitiesContext } from '../../../../containers/dataBoards/OpportunitiesBoard/context';
import { styleSheet as dbStyleSheet } from '../../../../containers/dataBoards/styles';
import { baseStyleSheet } from '../../../../styles/styles';
import { Checkbox } from '../../../Checkbox';
import { DataboardActions } from '../../../Databoard/Databoard';
import {
	DataGrid,
	DataGridColumn,
	DataGridColumns,
	DataGridRow,
	DataGridRowItem,
	DataGridRows,
} from '../../../DataGrid';
import { FullscreenModalNavLink } from '../../../fullscreen/FullscreenModalNavLink';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { UsersActionBar } from '../../../UsersActionBar';
import { styleSheet } from './styles';

const _OpportunitiesTable = ({
	board,
	opportunityFilterCriteria,
}: {
	board: IBoard;
	opportunityFilterCriteria: Api.IOpportunitySearchRequest;
}) => {
	const opportunityContactsMutation = useOpportunityContactsMutation();
	const { opportunities, isLoading } = useOpportunitiesContext();
	const [actionButtonsDisabled, setActionButtonsDisabled] = React.useState(false);
	const [selectionState, setSelectionState] = React.useState<Api.ESelectionState>(Api.ESelectionState.None);
	const [selectedOpportunityRows, setSelectedOpportunityRows] = React.useState<string[]>([]);
	const [excludedContacts, setExcludedContacts] = React.useState<Api.IContact[]>([]);
	const exportOpportunitiesMutation = useExportOpportunitiesMutation();

	const onToggleOpportunityRow = (opportunityRow: Api.IOpportunity) => {
		const currentIndex = selectedOpportunityRows.indexOf(opportunityRow.id);
		const nextSelectedRows = [...selectedOpportunityRows];

		if (selectionState === Api.ESelectionState.All) {
			const nextExcludedContacts = [...excludedContacts];
			if (currentIndex > -1) {
				nextExcludedContacts.push(opportunityRow.primaryContact);
			} else {
				nextExcludedContacts.splice(currentIndex, 1);
			}
			setExcludedContacts(nextExcludedContacts);
		} else {
			const isLastSelectedContact = currentIndex > -1 && selectedOpportunityRows.length === 1;
			if (isLastSelectedContact) {
				setSelectionState(Api.ESelectionState.None);
			} else if (nextSelectedRows) {
				setSelectionState(Api.ESelectionState.Some);
			}
		}

		if (currentIndex > -1) {
			nextSelectedRows.splice(currentIndex, 1);
		} else {
			nextSelectedRows.push(opportunityRow.id);
		}
		setSelectedOpportunityRows(nextSelectedRows);
	};

	const onToggleSelectAll = () => {
		if (selectedOpportunityRows.length > 0) {
			setSelectionState(Api.ESelectionState.None);
			setSelectedOpportunityRows([]);
		} else {
			setSelectionState(Api.ESelectionState.All);
			setSelectedOpportunityRows(opportunities.map(opportunity => opportunity.id));
		}
	};

	const onExport = () => {
		exportOpportunitiesMutation.mutate({
			filterRequest: opportunityFilterCriteria,
			includeActivity: false,
			boardId: board.id,
		});
	};

	const getMatchingContacts = async () => {
		setActionButtonsDisabled(true);
		let results: Api.IProjectedContact[] = [];
		if (selectionState === Api.ESelectionState.Some) {
			results = opportunities
				.filter(opportunity => selectedOpportunityRows.indexOf(opportunity.id) > -1)
				.map(opportunity => opportunity.primaryContact as Api.IProjectedContact);
		} else {
			const apiResults = await opportunityContactsMutation.mutateAsync({
				filterRequest: opportunityFilterCriteria,
				boardId: board.id,
			});

			const excludedContactIdsMap = new Set(excludedContacts.map(contact => contact.id));
			results = apiResults.filter(contact => !excludedContactIdsMap.has(contact.id));
		}

		setActionButtonsDisabled(false);
		return results;
	};

	return (
		<div className={css(styleSheet.container)}>
			<DataboardActions>
				<div className={css(dbStyleSheet.leftHeader)}>
					<UsersActionBar
						getMatchingContacts={getMatchingContacts}
						onExport={onExport}
						selectionState={selectionState}
						actionButtonsDisabled={actionButtonsDisabled}
					/>
				</div>
			</DataboardActions>
			<div className={css(styleSheet.content)}>
				<DataGrid styles={[styleSheet.tableOverrides]}>
					<DataGridColumns>
						<DataGridColumn sticky>
							<Checkbox
								checked={selectionState === Api.ESelectionState.All}
								id='select-all-opportunities'
								onChange={onToggleSelectAll}
								partial={selectionState === Api.ESelectionState.Some}
								disabled={opportunities.length === 0}
							/>
						</DataGridColumn>
						<DataGridColumn sticky>Opportunity Name</DataGridColumn>
						<DataGridColumn sticky>Stage</DataGridColumn>
						<DataGridColumn sticky>Amount</DataGridColumn>
						<DataGridColumn sticky>Owner</DataGridColumn>
						<DataGridColumn sticky>Close Date</DataGridColumn>
						<DataGridColumn sticky>Company</DataGridColumn>
					</DataGridColumns>

					<DataGridRows>
						{!isLoading
							? opportunities.map(opportunity => {
									const closeMoment = moment(opportunity.closeDate);
									const owner = opportunity.assignees[0] ?? null;
									return (
										<DataGridRow key={`opportunity-${opportunity.id}`}>
											<DataGridRowItem>
												<Checkbox
													type='large'
													checked={selectedOpportunityRows.indexOf(opportunity.id) > -1}
													id={opportunity.id}
													onChange={() => onToggleOpportunityRow(opportunity)}
												/>
											</DataGridRowItem>
											<DataGridRowItem styles={[styleSheet.nameColumn]}>
												<FullscreenModalNavLink
													title={opportunity.name}
													to={{
														pathname: `/dataBoards/opportunities/${board.id}/${opportunity.id}`,
													}}
												>
													<h6 className={css(styleSheet.name, baseStyleSheet.truncateText, baseStyleSheet.fontBold)}>
														{opportunity.name}
													</h6>
												</FullscreenModalNavLink>
											</DataGridRowItem>
											<DataGridRowItem>{opportunity.boardStage ? opportunity.boardStage.name : ''}</DataGridRowItem>
											<DataGridRowItem>${numberToCurrencyStringValue(opportunity.dealSize)}</DataGridRowItem>
											<DataGridRowItem>{owner ? VmUtils.getDisplayName(owner, true) : ''}</DataGridRowItem>
											<DataGridRowItem>
												{closeMoment.isValid() ? closeMoment.format('MM/DD/YYYY') : 'No Date'}
											</DataGridRowItem>
											<DataGridRowItem>{opportunity.company ? opportunity.company.companyName : null}</DataGridRowItem>
										</DataGridRow>
									);
								})
							: null}

						{isLoading && (
							<DataGridRow>
								<DataGridRowItem fullColSpan>
									<LoadingSpinner className={css(styleSheet.loading, baseStyleSheet.absoluteCenter)} type='large' />
								</DataGridRowItem>
							</DataGridRow>
						)}
					</DataGridRows>
				</DataGrid>
			</div>
		</div>
	);
};

export const OpportunitiesTable = observer(_OpportunitiesTable);
