import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useToaster } from '../../../models/hooks/appStateHooks';
import { aceGray, charmGray } from '../../styles/colors';
import { bs } from '../../styles/styles';
import { Button } from '../Button';
import { ISelectBoxOption, SelectBox } from '../SelectBox';
import { XIcon } from '../svgs/icons/XIcon';
import { TextInputFormField } from '../TextInputFormField';
import { Toggle } from '../Toggle';
import { styleSheet } from './styles';

export const FormFieldOptions = [
	Api.FormFieldType.String,
	Api.FormFieldType.MultipleOptions,
	Api.FormFieldType.Option,
].map(fieldType => {
	let title = '';
	if (fieldType === Api.FormFieldType.String) {
		title = 'Short Answer';
	}
	if (fieldType === Api.FormFieldType.MultipleOptions) {
		title = 'Checkboxes';
	}
	if (fieldType === Api.FormFieldType.Option) {
		title = 'Dropdown';
	}

	return {
		title,
		value: fieldType,
	};
});

interface Props {
	field: Api.IFormField<string>;
	questionIndex: number;
	value: string;
	onToggleCheckChanged?: (index: number) => void;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
	onRenderTriggerTitle?: (selectedOption: ISelectBoxOption<Api.FormFieldType>) => React.ReactNode;
	handleFormFieldTypeChange?: (selectedType: ISelectBoxOption<Api.FormFieldType>, questionIndex: number) => void;
	handleRemoveQuestion?: (index: number) => void;
	showRemove?: boolean;
	onUpdateField: (field: Api.IFormField<string>, index: number) => void;
	isEdit: boolean;
}

export const FormFieldWithOptions = ({
	field,
	questionIndex,
	value,
	onToggleCheckChanged,
	onChange,
	onRenderTriggerTitle,
	handleFormFieldTypeChange,
	handleRemoveQuestion,
	showRemove,
	onUpdateField,
	isEdit,
	children,
}: React.PropsWithChildren<Props>) => {
	const isRequired = !field.isOptional;
	const toaster = useToaster();
	const { clearErrors, formState } = useFormContext<Api.IEventRegistrationSurvey | Api.ISatisfactionSurvey>();

	React.useEffect(() => {
		// if fieldType is multiple options or Option
		if (
			(field.fieldType === Api.FormFieldType.MultipleOptions || field.fieldType === Api.FormFieldType.Option) &&
			field?.options?.length === 0
		) {
			// Add two empty options with a label and the field id
			field.options = [
				{
					label: '',
					fieldId: field.id,
				},
				{
					label: '',
					fieldId: field.id,
				},
			];
			onUpdateField(field, questionIndex);
		}
		if (field.fieldType === Api.FormFieldType.String && field?.options?.length !== 0) {
			field.options = [];
			clearErrors(`customForm.fields.${questionIndex}.options`);
			onUpdateField(field, questionIndex);
		}
	}, [clearErrors, field, onUpdateField, questionIndex]);
	const handleOptionChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, index: number) => {
			const newOptions = [...field.options];
			newOptions[index].label = e.target.value;
			field.options = newOptions;
			// check if the first two options are empty to reset the field error
			if (
				field.options.every(x => Boolean(x.label)) &&
				field.options.length >= 2 &&
				(field.fieldType === Api.FormFieldType.Option || field.fieldType === Api.FormFieldType.MultipleOptions)
			) {
				field.options.filter(x => Boolean(x.label));
				clearErrors(`customForm.fields.${questionIndex}.options`);
			}

			onUpdateField(field, questionIndex);
		},
		[clearErrors, field, onUpdateField, questionIndex]
	);

	const handleRemoveOption = React.useCallback(
		(index: number) => {
			const newOptions = [...field.options];
			newOptions.splice(index, 1);

			field.options = newOptions;
			onUpdateField(field, questionIndex);
		},
		[field, onUpdateField, questionIndex]
	);

	const handleAddOption = React.useCallback(() => {
		const newOptions = [...field.options];
		newOptions.push({ fieldId: field.id, label: '' });

		field.options = newOptions;
		onUpdateField(field, questionIndex);
	}, [field, onUpdateField, questionIndex]);

	const hasBeenSaved = !!field._type;

	const errorMessage =
		formState.errors?.customForm?.fields?.[questionIndex]?.options?.message ||
		formState.errors?.customForm?.fields?.[questionIndex]?.label?.message ||
		('customFormMetaData' in formState.errors
			? formState.errors?.customFormMetaData?.[questionIndex]?.isConditional?.message
			: undefined);

	return (
		<>
			<div className={css(bs.flex, bs.justifyEnd, bs.itemsCenter, bs.gap2)}>
				<Toggle
					id={`toggle-${field.id}-question-${questionIndex}`}
					isOn={isRequired}
					onToggleCheckChanged={() => onToggleCheckChanged(questionIndex)}
					uncheckedColor={aceGray}
				/>
				<span className={css(styleSheet.toggleLabel)}>Required</span>
			</div>
			<div className={css(bs.flex, bs.flexBetween, bs.gap9, bs.itemsStart)}>
				<div className={css(styleSheet.questionWrap)} key={`${field.id}-question-${questionIndex}`}>
					<TextInputFormField
						inputId={`edit-custom-${field.id}-question-${questionIndex}-input`}
						label={`Question ${questionIndex + 1}`}
						labelStyles={[styleSheet.label]}
						placeholder='Untitled Question'
						onChange={e => onChange(e, questionIndex)}
						type='text'
						styles={[styleSheet.questionInput]}
						id={`edit-custom-${field.id}-question-${questionIndex}-input`}
						value={value}
					/>
					{errorMessage ? <p className={css(styleSheet.errorMessageInline)}>{errorMessage}</p> : null}
					<div
						className={css(
							styleSheet.optionsWrapper,
							field.fieldType === Api.FormFieldType.Option ? styleSheet.optionsWrapperDropdown : undefined
						)}
					>
						{field?.options?.map((fieldOption, optionIndex) => {
							return (
								<div
									className={css(bs.flex, bs.itemsCenter, bs.gap2)}
									key={`edit-custom-${fieldOption.fieldId}-question-${questionIndex}-input-${optionIndex}`}
								>
									{field.fieldType === Api.FormFieldType.MultipleOptions ? (
										<div className={css(styleSheet.fakeCheckbox)} />
									) : null}
									<TextInputFormField
										key={`edit-custom-${fieldOption.fieldId}-question-${questionIndex}-input-${optionIndex}`}
										inputId={`edit-custom-${fieldOption.fieldId}-question-${questionIndex}-input-${optionIndex}`}
										label=''
										labelStyles={[styleSheet.label]}
										placeholder={
											field.fieldType === Api.FormFieldType.Option
												? `Option ${optionIndex + 1}`
												: `Response ${optionIndex + 1}`
										}
										onChange={e => handleOptionChange(e, optionIndex)}
										onKeyDown={e => {
											if (e.key === ',') {
												e.preventDefault();
												toaster.push({
													message: 'Commas are not allowed in option labels',
													type: 'errorMessage',
												});
											}
										}}
										type='text'
										styles={[styleSheet.questionInput]}
										id={`edit-custom-${fieldOption.fieldId}-question-${questionIndex}-input-${optionIndex}`}
										value={field.options[optionIndex].label}
									/>
									{field?.options?.length > 2 ? (
										<div>
											<Button
												kind='custom'
												type='button'
												className={css(styleSheet.removeOptionButton)}
												label={<XIcon width={14} height={14} />}
												onClick={() => handleRemoveOption(optionIndex)}
											/>
										</div>
									) : null}
								</div>
							);
						})}
						{field?.options?.length ? (
							<Button
								kind='custom'
								type='button'
								label='+ Add Option'
								className={css(
									styleSheet.addOptionButton,
									field.fieldType === Api.FormFieldType.MultipleOptions ? styleSheet.addOptionButtonLeftPadding : null
								)}
								onClick={() => handleAddOption()}
							/>
						) : null}
					</div>
				</div>
				<SelectBox
					disabled={isEdit && hasBeenSaved}
					menuClassName={`${css(styleSheet.dropdownMenu)}`}
					onRenderSelectedOptionTitle={onRenderTriggerTitle}
					onSelectionChanged={selectedType => handleFormFieldTypeChange(selectedType, questionIndex)}
					options={FormFieldOptions}
					selectedOption={FormFieldOptions.find(x => x.value === field.fieldType)}
					triggerClassName={css(
						styleSheet.dropdownTrigger,
						isEdit && hasBeenSaved ? styleSheet.dropdownTriggerDisabled : undefined
					)}
				/>
			</div>
			{children}

			{showRemove ? (
				<Button
					type='button'
					kind='custom'
					className={css(styleSheet.deleteButton)}
					onClick={() => handleRemoveQuestion(questionIndex)}
					label={
						<>
							Remove additional question
							<XIcon width={14} height={14} fillColor={charmGray} />
						</>
					}
				/>
			) : null}
		</>
	);
};
