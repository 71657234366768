import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { UserViewModel } from '../../../../extViewmodels';
import { canViewTexting } from '../../../../extViewmodels/Utils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { EmailAndCalendarSettings } from './EmailAndCalendarSettings';
import { EmailSignatureSettings } from './EmailSignatureSettings';
import { HandwrittenCardSettings } from './HandwrittenCardSettings';
import { ImportContactsSettings } from './ImportContactsSettings';
import { NotificationsSettings } from './NotificationSettings';
import { PasswordSettings } from './PasswordSettings';
import { ProfileSettings } from './ProfileSettings';
import { SocialMediaSettings } from './SocialMediaSettings';
import { TextingSettings } from './TextingSettings';
import { styleSheet } from './styles';

interface IProps {
	sectionToExpand?: string;
}

function _PersonalSettings({ sectionToExpand }: IProps) {
	const userSession = useUserSession();

	const [userViewModel] = React.useState(new UserViewModel(userSession, userSession.user));
	return (
		<div className={css(styleSheet.root)}>
			<ProfileSettings />
			<EmailAndCalendarSettings userViewModel={userViewModel} />
			<PasswordSettings expanded={sectionToExpand === 'password'} />

			{userSession.account.features.socialMedia?.enabled ? (
				<SocialMediaSettings expanded={sectionToExpand === 'social-media'} />
			) : null}

			{userSession.account.features.handwrittenCards?.enabled ? (
				<HandwrittenCardSettings expanded={sectionToExpand === 'handwritten-cards'} />
			) : null}
			<EmailSignatureSettings expanded={sectionToExpand === 'email-signature'} />
			<ImportContactsSettings userViewModel={userViewModel} />
			{canViewTexting(userSession) ? <TextingSettings expanded={sectionToExpand === 'texting'} /> : null}
			<NotificationsSettings userViewModel={userViewModel} />
		</div>
	);
}

export const PersonalSettings = observer(_PersonalSettings);
