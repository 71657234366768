import { ILocationState } from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { ICompany, IOperationResultNoValue } from '@ViewModels';
import { MultiContainerHeader } from '@WebComponents/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '@WebComponents/NavigationBreadcrumbsBar';
import { NotFound } from '@WebComponents/NotFound';
import { PortalDestination } from '@WebComponents/Portal';
import { SplitView } from '@WebComponents/SplitView';
import { CompanyActivity } from '@WebComponents/companies/CompanyActivity';
import { CompanyInfo } from '@WebComponents/companies/CompanyInfo';
import { EditCompanyInfo } from '@WebComponents/companies/EditCompanyInfo';
import { StyleDeclarationValue, css } from 'aphrodite';
import { action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuidgen } from 'uuid';
import { CompanyViewModel } from '../../../viewmodels/AppViewModels';
import { INavigationItemProps } from '../MainContainer';
import { styleSheet } from './styles';
import './styles.less';

export interface ICompanyProps
	extends RouteComponentProps<any>,
		INavigationItemProps,
		IErrorMessageComponentProps,
		IUserSessionComponentProps,
		IEventLoggingComponentProps {
	className?: string;
	company?: CompanyViewModel;
	companyId?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	company?: CompanyViewModel;
	isEditing?: boolean;
	loadError?: IOperationResultNoValue;
	scrollToBottomWaypointPortalId?: string;
	showNotFound?: boolean;
}

class _Company extends React.Component<ICompanyProps, IState> {
	private mMounted: boolean;

	private mScrollToTopAnchorRef: HTMLSpanElement;
	public readonly state: IState = {
		scrollToBottomWaypointPortalId: uuidgen(),
	};

	public static getDerivedStateFromProps(props: ICompanyProps, state: IState) {
		const nextState: IState = {};
		if (!!props.company && props.company !== state.company) {
			nextState.company = props.company;
		} else {
			const nextCompanyId = props.match.params?.id || props.companyId;
			const currentCompanyId = state.company ? state.company.id : null;
			const locationState: ILocationState<CompanyViewModel, ICompany> =
				(props.history.location ? props.history.location.state : null) || {};
			if (!!locationState && !!locationState.viewModel) {
				if (state.company !== locationState.viewModel) {
					nextState.company = locationState.viewModel;
				}
			} else if (nextCompanyId && currentCompanyId !== nextCompanyId) {
				nextState.company = new CompanyViewModel(props.userSession, {
					id: nextCompanyId,
				});
			}
		}

		if (nextState.company) {
			// clear the error
			nextState.showNotFound = false;

			nextState.loadError = null;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: ICompanyProps) {
		super(props);
		const initialState: IState = {
			scrollToBottomWaypointPortalId: uuidgen(),
		};
		this.state = {
			...initialState,
			...(_Company.getDerivedStateFromProps(props, initialState) || {}),
		};
	}

	@action
	public componentDidMount() {
		this.mMounted = true;
		this.loadCompany();
	}

	public componentDidUpdate() {
		const { company, showNotFound, loadError } = this.state;

		if (!this.isLoading && !company.isLoaded && !showNotFound && !loadError) {
			this.loadCompany();
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { company, showNotFound } = this.state;
		const { routeContainerClassName, className, styles } = this.props;
		return (
			<div
				className={`${css(...(styles || [])) || ''} company-container ${routeContainerClassName || ''} ${
					className || ''
				}`}
			>
				<MultiContainerHeader
					appBarHeader={!!company && !!company.name && <NavigationBreadcrumbsBar currentLocationName={company.name} />}
					fullscreenHeader='Company Profile'
				/>
				{showNotFound ? (
					<NotFound
						className={css(styleSheet.notFound)}
						message='The company you are looking for magically vanished.'
					/>
				) : (
					<SplitView
						className='company-container-split-view'
						detail={this.onRenderSplitViewDetail()}
						master={this.onRenderSplitViewMaster()}
					/>
				)}
			</div>
		);
	}

	private onRenderSplitViewMaster() {
		const { company, isEditing } = this.state;
		return (
			<React.Fragment>
				<span style={{ fontSize: 0, height: 0 }} ref={this.onScrollToTopAnchorRef} />
				{!!isEditing && !this.isLoading ? (
					<EditCompanyInfo company={company} onFinish={this.onEditingFinished} />
				) : (
					<>
						<CompanyInfo company={company} onEditButtonClick={this.onEditButtonClicked} />
					</>
				)}
			</React.Fragment>
		);
	}

	private onRenderSplitViewDetail() {
		const { company, scrollToBottomWaypointPortalId } = this.state;
		const { userSession } = this.props;
		return (
			<div>
				<CompanyActivity
					company={company}
					scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
					userSession={userSession}
				/>
				{!!scrollToBottomWaypointPortalId && <PortalDestination id={scrollToBottomWaypointPortalId} />}
			</div>
		);
	}

	@computed
	private get isLoading() {
		const { company } = this.state;
		return !company || (!!company && !!company.isLoading) || !this.mMounted;
	}

	private onEditButtonClicked = () => {
		this.setState({
			isEditing: true,
		});
	};

	private onScrollToTopAnchorRef = (ref?: HTMLSpanElement) => {
		this.mScrollToTopAnchorRef = ref;
	};

	private loadCompany = (company: CompanyViewModel = this.state.company) => {
		if (company) {
			const { logEvent, logApiError, history } = this.props;
			const promise = company.load();
			if (promise) {
				logEvent('Load', { id: this.props.companyId });
				promise
					.then(() => {
						// set the resolved company back in location state
						if (!!history && !!this.mMounted) {
							const locationState: ILocationState<CompanyViewModel, ICompany> = {
								model: company.toJs(),
								viewModel: company,
							};
							history.location.state = locationState;
						}
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError('Load-Error', error);
						if (this.mMounted) {
							const stateWithError: IState = { loadError: error };
							if (error.systemCode === 404) {
								stateWithError.showNotFound = true;
							}
							this.setState(stateWithError);
						}
					});
			}
		}
	};

	private onEditingFinished = () => {
		this.setState(
			{
				isEditing: false,
			},
			() => {
				if (!!this.mMounted && !!this.mScrollToTopAnchorRef) {
					this.mScrollToTopAnchorRef.scrollIntoView();
				}
			}
		);
	};
}

// @ts-ignore
const CompanyObserver = observer(_Company);
const CompanyWithContext = inject(ErrorMessagesViewModelKey, UserSessionViewModelKey)(CompanyObserver);
export const Company = withRouter(withEventLogging(CompanyWithContext, 'Company'));
