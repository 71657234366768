import * as Api from '@ViewModels';
import { getLogoUrlForIntegrationProvider, getMergeLinkProviderDisplayName } from '../../../../../models/UiUtils';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../../styles/styles';
import { SalesforceIntegrationGraphic } from '../../../svgs/graphics/SalesforceIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { ctaButtonStyles, ctaStyleSheet, getLogoBgColor, styleSheet, titleStyleSheet } from './styles';

interface IProps {
	integrationType: Api.MergeLinkConfigurableIntegrationType;
}

const getPendingActionForIntegrationType = (integrationType: Api.MergeLinkConfigurableIntegrationType) => {
	switch (integrationType) {
		case Api.ConfigurableIntegrationType.MergeAccounting:
			return Api.PendingActions.ConnectMergeAccounting;
		case Api.ConfigurableIntegrationType.MergeCrm:
			return Api.PendingActions.ConnectMergeCrm;
		default:
			return null;
	}
};

const getProviderName = (integrations?: Api.IAccountIntegrations) => {
	if (integrations?.mergeAccounting) {
		return integrations.mergeAccounting.mergeAccountingProvider;
	}

	if (integrations?.mergeCrm) {
		return integrations.mergeCrm.mergeCrmProvider;
	}
	return null;
};

export function DashboardMergeLinkIntegrationCard({ integrationType }: IProps) {
	const userSession = useUserSession();
	const integrations = userSession?.account?.integrations;
	const providerName = getProviderName(integrations);

	const providerDisplayName = getMergeLinkProviderDisplayName(integrations);
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: `${integrationType}IntegrationClosed`,
			enableUrlPath: `/integrations/${integrationType}`,
			integrationEnableEventName: `${integrationType}IntegrationEnableClick`,

			pendingAction: getPendingActionForIntegrationType(integrationType),
		});

	if (!showCard) {
		return null;
	}

	const tintColor = ctaButtonStyles[providerName].background;

	return (
		<IntegrationCard
			logoBgColor={getLogoBgColor(integrations)}
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={
				<SalesforceIntegrationGraphic
					tintColor={`${tintColor}${tintColor?.replace('#', '')?.length === 6 ? 26 : ''}`}
				/>
			}
			headerLabel={`${providerDisplayName} Integration`}
			logoImgProps={getLogoImgProps({
				alt: `${providerDisplayName} Logo`,

				src: getLogoUrlForIntegrationProvider(providerName),
			})}
			ctaButtonProps={getCtaButtonProps({
				children: `Connect with ${providerDisplayName}`,
				styleDeclaration: [
					baseStyleSheet.ctaButton,
					styleSheet.ctaButton,

					ctaStyleSheet[providerName] || ctaStyleSheet.default,
				],
			})}
			integrationTitleProps={getIntegrationTitleProps({ styleDeclaration: [titleStyleSheet[providerName]] })}
		>
			<p>
				{`We are excited to announce that you can integrate your ${providerDisplayName} account with Levitate. With this
	integration, we will have the ability to pull client information, including names, email, phone number,
	birthdays, anniversaries, and more `}
				&mdash;{` making staying in touch even easier.`}
			</p>
		</IntegrationCard>
	);
}
