import { useEventLogging } from '@AppModels/Logging';
import { useErrorMessages, useUserSession } from '@AppModels/hooks/appStateHooks';
import { IModalContext, ModalChildComponentContextKey } from '@AppModels/index';
import { useStartAutomationContactMutation } from '@Queries/Automation';
import { useAutomationContactPreviewQuery } from '@Queries/Automation/useAutomationContactPreviewQuery';
import { useAutomationTemplateIdQuery } from '@Queries/AutomationTemplate';
import { useContactAddTagsMutation } from '@Queries/Contact/useContactAddTagsMutation';
import { useGetSystemJobQuery } from '@Queries/SystemJob/useGetSystemJobQuery';
import { useTagSearchQuery } from '@Queries/Tag/useTagSearchQuery';
import { baseStyleSheet } from '@Styles/styles';
import * as Api from '@ViewModels';
import { asModalComponent } from '@WebComponents/Modal';
import { AutomationStepsBy } from '@WebComponents/automation/AutomationSelector/AutomationStepsBy';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../autocomplete/SimpleAutoCompleteSearchField';
import { AutomationConfirmationHeader, AutomationStartedNotification } from './presentation';
import { styleSheet } from './styles';

interface Props extends IModalContext<string> {
	onAddTagButtonClicked?(tagValue: string, e: React.MouseEvent<HTMLElement>): void;
	request?: Api.IBulkContactsRequest;
}

enum TagModalStep {
	ShowingTagSearch,
	ShowingAutomationOption,
	ShowingAutomationConfirmation,
}

const getUniqueValuesBasedOnIds = (contacts: Partial<Api.IProjectedContact>[]) => {
	const uniqueIds = new Set<string>();
	const vals = (contacts || []).filter(x => {
		if (!uniqueIds.has(x.id)) {
			uniqueIds.add(x.id);
			return true;
		}
		return false;
	});
	return vals;
};

export function _ContactsAddTag({ request, onAddTagButtonClicked, parentModal }: Props) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logInput, logApiError } = useEventLogging('ContactsAddTag');
	const [tagModalStep, setTagModalStep] = React.useState(TagModalStep.ShowingTagSearch);
	const [selectedTag, setSelectedTag] = React.useState<string | null>(null);
	const [selectedEmployee, setSelectedEmployee] = React.useState<Api.IUser | null>(null);
	const [selectedFrom, setSelectedFrom] = React.useState<Api.AutomationSelectionType>(
		Api.AutomationSelectionType.Myself
	);
	const [selectedAutomationTemplateId, setSelectedAutomationTemplateId] = React.useState<string | null>(null);
	const isAdmin = userSession.account.isAdmin && userSession.account.features.automation.allowAdminToStartOnBehalf;
	const contactTagsMutation = useContactAddTagsMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('ContactAddTagsMutation', error);
		},
	});
	const systemJobQuery = useGetSystemJobQuery({
		enabled: contactTagsMutation.isSuccess,
		jobId: contactTagsMutation.data?.id,
		refetchInterval: data => {
			if (!data) {
				return false;
			}
			if (data.percentComplete < 100) {
				return 2000;
			}
			return false;
		},
	});
	const isSystemJobComplete =
		systemJobQuery.data?.percentComplete != null && systemJobQuery.data?.percentComplete >= 100;
	const tagSearchQuery = useTagSearchQuery({
		enabled: isSystemJobComplete,
		query: selectedTag,
	});
	React.useEffect(() => {
		if (!isSystemJobComplete || !tagSearchQuery.data) {
			return;
		}
		const accountTag = tagSearchQuery.data.find(
			t => t.tag?.toLocaleLowerCase() === selectedTag.toLocaleLowerCase() || (t.id && selectedTag === t.id)
		);
		if (!(accountTag?.automationTemplateId && userSession?.account?.features?.automation?.enabled)) {
			onConfirmationClose();
			return;
		}
		setSelectedAutomationTemplateId(accountTag.automationTemplateId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSystemJobComplete, tagSearchQuery.data, userSession]);
	const automationTemplateQuery = useAutomationTemplateIdQuery({
		id: selectedAutomationTemplateId,
		enabled: selectedAutomationTemplateId !== null,
	});
	React.useEffect(() => {
		if (!automationTemplateQuery.data) {
			return;
		}
		setTagModalStep(TagModalStep.ShowingAutomationOption);
	}, [automationTemplateQuery.data]);
	const createAutomationRequest: Api.ICreateAutomationRequest = {
		...request,
		autoStart: true,
		putOnHold: false,
	};
	if (isAdmin) {
		createAutomationRequest.sendFromOptions = {
			mode:
				selectedFrom === Api.AutomationSelectionType.ContactOwners
					? Api.SendEmailFrom.ContactOwner
					: Api.SendEmailFrom.CurrentUser,
		};
		if (selectedFrom === Api.AutomationSelectionType.Myself) {
			createAutomationRequest.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
			createAutomationRequest.sendFromOptions.selectedUser = userSession.user?.id;
		}
		if (selectedFrom === Api.AutomationSelectionType.Employee) {
			createAutomationRequest.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
			createAutomationRequest.sendFromOptions.selectedUser = `${selectedEmployee?.id}`;
		}
	}
	const automationContactPreviewQuery = useAutomationContactPreviewQuery({
		id: selectedAutomationTemplateId,
		createAutomationRequest,
		enabled: selectedAutomationTemplateId != null,
	});
	const previewResult: Api.IPreviewCreateAutomationResult | undefined = automationContactPreviewQuery.data
		? {
				...automationContactPreviewQuery.data,
				excludeContacts: getUniqueValuesBasedOnIds(automationContactPreviewQuery.data.excludeContacts ?? []),
				contactOwners: getUniqueValuesBasedOnIds(automationContactPreviewQuery.data.contactOwners ?? []),
			}
		: undefined;
	const onCloseClicked = () => {
		if (parentModal) {
			parentModal.onRequestClose(null, true);
		}
	};
	const onSearchFieldChanged = (e: ISimpleAutoCompleteSearchFieldEvent<React.ChangeEvent<HTMLInputElement>>) => {
		if (e.sourceEvent) {
			const inputElement = e.sourceEvent.target as HTMLInputElement;
			setSelectedTag(inputElement.value || '');
		}
	};
	const onClearButtonClicked = () => {
		setSelectedTag(null);
	};
	const onTagSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<Api.IAccountTag>) => {
		if (e.target) {
			e.target.setSearchQuery(e.selection?.tag);
		}
		setSelectedTag(e.selection?.tag);
	};
	const onSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (e.sourceEvent && e.sourceEvent.keyCode === 13) {
			const inputElement = e.sourceEvent.target as HTMLInputElement;
			setSelectedTag(inputElement.value || '');
			inputElement.blur();
		}
	};
	const onConfirmationClose = () => {
		parentModal?.onRequestClose(selectedTag, false);
	};
	const onSubmitClicked = async (e: React.MouseEvent<HTMLElement>) => {
		const tag = selectedTag?.trim();
		if (!tag) {
			return;
		}
		if (onAddTagButtonClicked) {
			onAddTagButtonClicked(tag, e);
		}
		if (e.defaultPrevented || contactTagsMutation.isLoading) {
			return;
		}
		logInput('Add', 'Click', { tagLength: tag.length });
		contactTagsMutation.mutateAsync({
			request: {
				...request,
				tags: [tag],
			},
		});
	};
	const startAutomationContactMutation = useStartAutomationContactMutation({
		onError: error => {
			logApiError('StartAutomationBulkTag', error);
			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			setTagModalStep(TagModalStep.ShowingAutomationConfirmation);
		},
	});
	const onAutomationConfirmationRequest = (result?: Api.ICreateAutomationRequest, cancel?: boolean) => {
		if (!result && cancel) {
			setTagModalStep(TagModalStep.ShowingTagSearch);
			parentModal?.onRequestClose(selectedTag, false);
		} else {
			startAutomationContactMutation.mutate({
				id: selectedAutomationTemplateId,
				createAutomationRequest: result,
			});
		}
	};
	const onStartAutomationClicked = () => {
		if (!selectedEmployee && selectedFrom === Api.AutomationSelectionType.Employee) {
			return;
		}
		onAutomationConfirmationRequest(createAutomationRequest, false);
	};
	return (
		<div className={`${css(styleSheet.container)} contacts-add-tag`}>
			<div className={css(styleSheet.header)}>
				<DeprecatedCloseButton
					disabled={contactTagsMutation.isLoading || (contactTagsMutation.isSuccess && !isSystemJobComplete)}
					onClick={onCloseClicked}
				/>
			</div>
			{tagModalStep !== TagModalStep.ShowingAutomationOption &&
				tagModalStep !== TagModalStep.ShowingAutomationConfirmation && (
					<div className={css(styleSheet.title)}>Add Tag</div>
				)}
			{contactTagsMutation.isSuccess && !isSystemJobComplete ? (
				<>
					<div className={css(styleSheet.taggingMessage)}>
						<div>Tagging contacts...</div>
						<LoadingSpinner type='small' />
					</div>
				</>
			) : (
				<>
					{tagModalStep === TagModalStep.ShowingTagSearch && (
						<div>
							<SimpleAutoCompleteSearchField
								onChange={onSearchFieldChanged}
								onClear={onClearButtonClicked}
								// @ts-ignore
								onItemSelected={onTagSelected}
								onKeyDown={onSearchFieldKeyDown}
								pageSize={10}
								placeholder='Search existing or enter new tag'
								renderNoResultsItem={false}
								resultsLimit={10}
								style={styleSheet.searchField}
								type={Api.ResourceAutoCompleteViewModelType.AccountTag}
							/>
							<button className={css(baseStyleSheet.ctaButton, styleSheet.submitButton)} onClick={onSubmitClicked}>
								<span>Add</span>
							</button>
						</div>
					)}
					{tagModalStep === TagModalStep.ShowingAutomationOption && (
						<div className={`${css(styleSheet.automationConfirmContainer)} automation-confirmation`}>
							<AutomationConfirmationHeader automationTemplate={automationTemplateQuery.data} />
							{isAdmin ? (
								<article>
									<h4 className={css(baseStyleSheet.truncateText, styleSheet.automationCardSubTitle)}>
										Automation steps by:
									</h4>
									<AutomationStepsBy
										userSession={userSession}
										preview={previewResult}
										isBusy={automationContactPreviewQuery.isLoading}
										onAutomationSelectionComesFrom={setSelectedFrom}
										onSelectedEmployee={setSelectedEmployee}
									/>
								</article>
							) : null}
							<footer className={css(styleSheet.buttonContainer)}>
								<button
									className={css(baseStyleSheet.ctaButton)}
									onClick={onStartAutomationClicked}
									disabled={!selectedEmployee && selectedFrom === Api.AutomationSelectionType.Employee}
								>
									<span>Start Automations for Tagged Contacts</span>
								</button>
								<button
									className={css(baseStyleSheet.ctaButtonReverse)}
									onClick={() => onAutomationConfirmationRequest(null, true)}
								>
									<span>Skip</span>
								</button>
							</footer>
						</div>
					)}
					{tagModalStep === TagModalStep.ShowingAutomationConfirmation && (
						<AutomationStartedNotification onAutomationConfirmClosed={onConfirmationClose} />
					)}
				</>
			)}
		</div>
	);
}

const ContactsAddTagAsObserver = observer(_ContactsAddTag);
export const ContactsAddTag = inject(ModalChildComponentContextKey)(ContactsAddTagAsObserver);

export const ContactsAddTagModal = asModalComponent(ContactsAddTag, {
	className: 'contacts-add-tag-modal',
	shouldCloseOnOverlayClick: false,
});
