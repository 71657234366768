import { StyleSheet } from 'aphrodite';
import {
	HtmlNewsletterTemplateCardHeight,
	HtmlNewsletterTemplateCardWidth,
} from '../../components/templateCards/HtmlNewsletterTemplateCard/styles';
import { brandPrimaryHover, titles } from '../../styles/colors';
import { Layouts } from '../../styles/styles';

/// / tslint:disable:object-literal-key-quotes
const infoGraphicSize = 60;

export const styleSheet = StyleSheet.create({
	container: {},
	createTemplateCard: {
		background: '#E8F3F9',
		border: `1px dashed ${brandPrimaryHover}`,
		color: '#3F3F3F',
		...Layouts.verticalStack(12),
	},
	header: {
		color: titles,
		fontSize: 18,
		marginBottom: 26,
		paddingLeft: 30,
	},
	infoHeader: {
		background: '#FFEDF9',
		borderLeft: '3px solid #78DBFF',
		borderRadius: 3,
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',
		fontSize: 14,
		marginBottom: 20,
		padding: '20px 20px 20px 40px',
	},
	infoHeaderContent: {
		width: `calc(100% - ${infoGraphicSize + 20}px)`,
	},
	infoHeaderContentBold: {
		fontSize: 18,
		paddingBottom: 5,
	},
	infoHeaderGraphic: {
		height: infoGraphicSize,
		marginRight: 20,
		width: infoGraphicSize,
	},
	loadingContainer: {
		alignItems: 'center',
		display: 'flex',
		height: HtmlNewsletterTemplateCardHeight,
		justifyContent: 'center',
		width: HtmlNewsletterTemplateCardWidth,
	},
	templates: {
		display: 'grid',
		gridGap: '8px',
		gridTemplateColumns: `repeat(auto-fill, minmax(${HtmlNewsletterTemplateCardWidth}px,1fr))`,
		gridAutoRows: 'auto',
	},
	templatesContainer: {
		boxSizing: 'border-box',
		overflow: 'hidden',
		padding: '0px 30px 10px 30px',
	},
});
