import * as Api from '@ViewModels';
import * as React from 'react';
import { useMemo } from 'react';
import { IMoveBoardItem } from '../../../../extViewmodels/sdk/models';
import { IBoardShowArchivedStorage, PersistentStorageBoardShowArchivedKey } from '../../../../models';
import { useContextGuard } from '../../../../models/hooks/useContextGuard';
import { PersistentStorageManager } from '../../../../models/Storage';
import {
	useBoardStageItemsMultiple,
	useBoardStageItemsSearchMultiple,
	useGetBoard,
	useMoveItemToStageMutation,
} from '../../../../queries';

export interface IOpportunitiesContext {
	searchRequest?: Api.IOpportunitySearchRequest;
	setSearchRequest?: React.Dispatch<React.SetStateAction<Api.IOpportunitySearchRequest>>;
	sortDescriptor?: Api.ISortDescriptor;
	setSortDescriptor?: React.Dispatch<React.SetStateAction<Api.ISortDescriptor>>;
	onSortChanged?: (value: string) => void;
	board: Api.IBoard;
	moveItemToStage: ({
		opportunityId,
		moveBoardItem,
	}: {
		opportunityId: string;
		moveBoardItem: IMoveBoardItem;
	}) => Promise<void>;
	setBoardId: React.Dispatch<React.SetStateAction<string>>;
	opportunityToMove: Api.IOpportunity;
	setOpportunityToMove: React.Dispatch<React.SetStateAction<Api.IOpportunity>>;
	opportunityToEdit: Api.IOpportunity;
	setOpportunityToEdit: React.Dispatch<React.SetStateAction<Api.IOpportunity>>;
	isSearching: boolean;
	showArchived: boolean;
	setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
	opportunities: Api.IOpportunity[];
	isLoading: boolean;
	isColumnDraggingEnabled: boolean;
	setIsColumnDraggingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	isPollingEnabled: boolean;
	setIsPollingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	selectedStage: Api.IBoardStage;
	setSelectedStage: React.Dispatch<React.SetStateAction<Api.IBoardStage>>;
}

export const OpportunitiesContext = React.createContext<IOpportunitiesContext>(null);

export const OpportunitiesContextProvider: React.FC = ({ children }) => {
	const [searchRequest, setSearchRequest] = React.useState<Api.IOpportunitySearchRequest>(null);
	const [boardId, setBoardId] = React.useState<string>(null);
	const [opportunityToMove, setOpportunityToMove] = React.useState(null);
	const [opportunityToEdit, setOpportunityToEdit] = React.useState(null);
	const [showArchived, setShowArchived] = React.useState(false);
	const [selectedStage, setSelectedStage] = React.useState<Api.IBoardStage>(null);
	const [isColumnDraggingEnabled, setIsColumnDraggingEnabled] = React.useState(false);
	const [isPollingEnabled, setIsPollingEnabled] = React.useState(false);

	const defaultSortDescriptor: Api.ISortDescriptor = {
		sortBy: 'Name',
		sort: 'asc',
	};
	const [sortDescriptor, setSortDescriptor] = React.useState<Api.ISortDescriptor>(defaultSortDescriptor);

	React.useEffect(() => {
		/** On load check if stored value to show archived column exists use stored value or false. */
		PersistentStorageManager.local
			.getObject<IBoardShowArchivedStorage>(PersistentStorageBoardShowArchivedKey)
			.then(storedValue => {
				setShowArchived(storedValue || false);
			});
	}, []);

	const onSortChanged = (sortBy: string) => {
		const isCurrentDescriptor = !!sortDescriptor && sortDescriptor.sortBy === sortBy;
		const newSort = isCurrentDescriptor ? (sortDescriptor.sort === 'asc' ? 'desc' : 'asc') : 'asc';

		setSortDescriptor({
			sort: newSort,
			sortBy,
		});
	};

	const getBoardQuery = useGetBoard({
		boardId,
	});

	const isSearching = !!(
		searchRequest?.dealSize ||
		searchRequest?.closeDate ||
		searchRequest?.stageId ||
		searchRequest?.searchValue
	);

	const stageIds = useMemo(() => {
		return getBoardQuery.data?.stages?.map(stage => stage.id) || [];
	}, [getBoardQuery.data?.stages]);

	const stageItemsQueries = useBoardStageItemsMultiple(stageIds, !isSearching, 5000);

	const stageItemsSearchQueries = useBoardStageItemsSearchMultiple(stageIds, searchRequest, isSearching, 5000);

	const stageQueries = useMemo(() => {
		return isSearching ? stageItemsSearchQueries : stageItemsQueries;
	}, [isSearching, stageItemsQueries, stageItemsSearchQueries]);

	const opportunities = useMemo(() => {
		return isSearching
			? stageItemsSearchQueries.map(query => query.data?.values || []).flat()
			: stageItemsQueries.map(query => query.data?.values || []).flat();
	}, [stageItemsQueries, stageItemsSearchQueries, isSearching]);

	const isLoading = useMemo(() => {
		if (!getBoardQuery.data?.stages) return true;

		return stageQueries.some(query => query.isLoading);
	}, [getBoardQuery.data?.stages, stageQueries]);

	const moveItemToStageMutation = useMoveItemToStageMutation({});

	const moveItemToStage = async ({
		opportunityId,
		moveBoardItem,
	}: {
		opportunityId: string;
		moveBoardItem: IMoveBoardItem;
	}) => {
		await moveItemToStageMutation.mutateAsync({
			opportunityId,
			moveBoardItem,
		});
	};

	const board: Api.IBoard = React.useMemo(() => getBoardQuery.data ?? null, [getBoardQuery.data]);

	const contextValue: IOpportunitiesContext = {
		searchRequest,
		setSearchRequest,
		sortDescriptor,
		setSortDescriptor,
		onSortChanged,
		board,
		moveItemToStage,
		setBoardId,
		opportunityToMove,
		setOpportunityToMove,
		opportunityToEdit,
		setOpportunityToEdit,
		isSearching,
		showArchived,
		setShowArchived,
		opportunities,
		isLoading,
		isColumnDraggingEnabled,
		setIsColumnDraggingEnabled,
		isPollingEnabled,
		setIsPollingEnabled,
		selectedStage,
		setSelectedStage,
	};

	return <OpportunitiesContext.Provider value={contextValue}>{children}</OpportunitiesContext.Provider>;
};

export const useOpportunitiesContext = () => useContextGuard(OpportunitiesContext, 'OpportunitiesContext');
