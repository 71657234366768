import { DashboardFeedViewModel, IOperationResultNoValue } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { CampaignsWithApprovalRequests } from '../../../components/dashboard/CampaignsWithApprovalRequests';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps, IErrorMessageComponentProps, IEventLoggingComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

class _SelectEmailCampaign extends React.Component<IProps> {
	@observable.ref private mFeedViewModel: DashboardFeedViewModel;
	constructor(props: IProps) {
		super(props);

		this.mFeedViewModel = new DashboardFeedViewModel(props.userSession);
	}

	public componentDidMount() {
		this.mFeedViewModel.getCampaignsWithApprovalRequests()?.catch((err: IOperationResultNoValue) => {
			const { errorMessages, logApiError } = this.props;

			errorMessages.pushApiError(err);

			logApiError('LoadCampaignsWithApprovalRequests-Error', err);
		});
	}
	public render() {
		const { className, styles } = this.props;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} select-email-campaign ${className || ''}`}>
				<MultiContainerHeader fullscreenHeader='Approve Campaigns' />
				<FabContext appearance={{ hidden: true }} />
				{this.mFeedViewModel?.isFetchingCampaignsWithApprovalRequests ? (
					<LoadingSpinner type='large' />
				) : (
					<CampaignsWithApprovalRequests dashboardFeed={this.mFeedViewModel} />
				)}
			</div>
		);
	}
}

const SelectEmailCampaignAsObserver = observer(_SelectEmailCampaign);
const SelectEmailCampaignInjected = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey
)(SelectEmailCampaignAsObserver);
export const SelectEmailCampaign = withEventLogging(SelectEmailCampaignInjected, 'SelectEmailCampaign');
